import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import "./index.css";
import assigntag from "../../../../assets/GeneralIcons/assign_tag.svg";
import dataLoading from "../../../../assets/LoaderIcons/Loader.gif";
import editIcon from "../../../../assets/EditIcons/edit_white.svg";
import editDetails from "../../../../assets/GeneralIcons/detail_page_edit detail.svg";
import mapRole from "../../../../assets/GeneralIcons/edit_roles.svg";
import autoPassword from "../../../../assets/PasswordIcons/auto_generate_password.svg";
import setPassword from "../../../../assets/PasswordIcons/change-password – 1.svg";

import {
  AddUserTag,
  getSpecificUser,
  getUsers,
  RemoveUserTag,
} from "../../../../redux/features/Settings/Management/Users";
import formatDate from "../../../../utils/formatdate";
import { getUserDetailsStateAction } from "../../../../redux/features/users/userDetails";
import { checkUsertoken } from "../../../../redux/features/login/login";
import notFoundImage from "../../../../assets/No transaction found.svg";
import UserTag from "../../../../components/UserTag";
import { toast } from "react-toastify";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import Loader from "../../../../components/Loader/Index";

const UserDetails = () => {
  const navigate = useNavigate();
  const [ownerDetailData, setOwnerdetaildata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTagOpenPop, setSelectedTagOpenPop] = useState(false);
  const [newTags, setNewTags] = useState([]);
  const [existingTags, setExistingTags] = useState([]);
  const [AllTags, setAllTags] = useState([]);
  const getUserDetailsState = useSelector((state) => state?.getUserDetailsinfo);
  const { removeUserTagsLoading } = useSelector((state) => state.users);
  const { id } = useParams();
  const services = useSelector((state) => state.refreshToken.services);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("Details");

  const { specificUserData, specificUserLoading, specificUserError } =
    useSelector((state) => state?.users);

  let userDetailsData =
    specificUserData?.status === true
      ? specificUserData?.employees?.[0]
      : getUserDetailsState?.status &&
        id == getUserDetailsState?.userInfo?.[0]?.id
      ? getUserDetailsState?.userInfo?.[0]
      : undefined;

  useEffect(() => {
    let checkUserId = getUserDetailsState?.userInfo?.[0]?.id;

    if (
      userDetailsData == undefined &&
      id != checkUserId &&
      getUserDetailsState?.status
    ) {
      navigate("*");
    } else if (specificUserData?.employees?.[0]?.account_tags) {
      const data = specificUserData?.employees?.[0]?.account_tags?.map(
        (res) => {
          return { selected: true, value: res, type: "ownerTags" };
        }
      );

      setNewTags(data);
    }
  }, [userDetailsData, specificUserData, getUserDetailsState]);

  useEffect(() => {
    const getUser = async () => {
      try {
        await dispatch(getSpecificUser(id));
      } catch (error) {
        console.error(error);
      }
    };

    getUser();
  }, []);

  useEffect(() => {
    let getUserDetailsStateStorage = JSON.parse(
      sessionStorage.getItem("userDetailsInfostorage")
    );
    dispatch(checkUsertoken());
    dispatch(getUserDetailsStateAction(getUserDetailsStateStorage));
  }, []);

  const handleTabClick = (e) => {
    setSelectedTab(e.target.innerHTML);
  };

  // const servicesMap = new Map();

  // specificUserData?.user_services?.forEach((service) => {
  //   if (!servicesMap.has(service.service_header)) {
  //     servicesMap.set(service.service_header, []);
  //   }
  //   servicesMap.get(service.service_header).push(service.service_name);
  // });

  // const featuresMap = new Map();

  // specificUserData?.user_features?.forEach((feature) => {
  //   if (!featuresMap.has(feature.feature_description)) {
  //     featuresMap.set(feature.feature_description, []);
  //   }
  //   featuresMap.get(feature.feature_description).push(feature.feature_name);
  // });

  //same as above by reduce method :P
  const servicesMap = specificUserData?.user_services?.reduce((acc, curr) => {
    if (!acc[curr?.service_header]) {
      acc[curr?.service_header] = [];
    }
    acc[curr?.service_header].push(curr?.service_name);

    return acc;
  }, {});

  const featuresMap = specificUserData?.user_features?.reduce((acc, curr) => {
    if (!acc[curr?.feature_description]) {
      acc[curr?.feature_description] = [];
    }
    acc[curr?.feature_description].push(curr?.feature_name);

    return acc;
  }, {});

  const rolesMap = specificUserData?.user_roles?.reduce((acc, curr) => {
    if (!acc[curr?.role_desc]) {
      acc[curr?.role_desc] = [];
    }
    acc[curr?.role_desc].push(curr?.role_name);

    return acc;
  }, {});

  const handleCloseModal = () => {
    setSelectedTagOpenPop(false);
  };

  const handleuserAll = async () => {
    setLoading(true);

    try {
      dispatch(checkUsertoken());
      let response = await dispatch(getUsers({ page_no: 1, page_size: 50 }));
      if (response?.payload?.status === true) {
        const data = response?.payload?.available_tags?.map((res) => {
          return { selected: true, value: res, type: "Available" };
        });

        setAllTags(data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response?.payload?.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAddTags = async (item) => {
    try {
      dispatch(checkUsertoken());
      setLoading(true);
      const selectedTags = newTags
        .filter((tag) => tag.selected === false)
        .map((tag) => tag.value);

      let response = await dispatch(
        AddUserTag({
          user_id: [id],
          tags: item?.type === "newAss" ? item?.data1 : [item],
        })
      );

      if (response?.payload?.status === true) {
        getOwnerDetaildata();
        setNewTags((prev) => [...prev, response?.meta?.arg?.tags]);
        handleuserAll();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRemoveTags = async (item) => {
    if (removeUserTagsLoading) {
      setLoading(true);
    }

    try {
      dispatch(checkUsertoken());

      setLoading(true);
      const response = await dispatch(
        RemoveUserTag({ user_id: [id], tags: item })
      );

      if (response?.payload?.status === true) {
        getOwnerDetaildata();
        setNewTags(response?.payload?.tags?.value || []);
        handleuserAll();
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error removing tag:", error);
    }
  };

  const getOwnerDetaildata = async () => {
    setLoading(true);
    try {
      dispatch(checkUsertoken());
      const response = await dispatch(getSpecificUser(id));

      setOwnerdetaildata(response?.payload?.employees?.[0]);

      if (
        response?.payload?.employees?.[0]?.tags &&
        response?.payload?.employees?.[0]?.tags.length > 0
      ) {
        const data = response?.payload?.employees?.[0].tags?.map((res) => {
          return { selected: true, value: res, type: "ownerTags" };
        });

        setNewTags(data);
        setLoading(true);
      }

      setLoading(false);
      if (response?.error?.message == "Request failed with status code 404") {
        navigate("*");
      }

      if (response?.payload === undefined) {
        navigate("/feature_not_assigned");
      } else {
        console.log("No user unauthorized error.");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="user-main-div">
      {selectedTagOpenPop && (
        <div
          className="fixed top-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-[9999]"
          onClick={handleCloseModal}
        >
          {
            <UserTag
              onClose={handleCloseModal}
              handleAddTags={handleAddTags}
              existingTags={existingTags}
              setExistingTags={setExistingTags}
              handleRemoveTags={handleRemoveTags}
              newTags={newTags}
              setNewTags={setNewTags}
              ownerExistingTag={specificUserData?.employees?.[0]?.account_tags}
              AllTags={AllTags}
              setAllTags={setAllTags}
              loading={loading}
              setLoading={setLoading}
              getOwnerDetaildata={getOwnerDetaildata}
              id={id}
              users={[specificUserData?.employees?.[0]?.first_name]}
              type={"ownerDetails"}
            />
          }
        </div>
      )}
      {!userDetailsData && !specificUserLoading ? (
        <div className="loader-container m-auto justify-center items-center flex">
          <Loader />
        </div>
      ) : (
        <>
          <div className="user-div-fixed">
            <div className="user-container" id="container">
              <div className="user-acc-section">
                <div className="user-major-div">
                  <div className="user-individual-person-detail">
                    <div className="user-individual-name">
                      <div className="user-account-name">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: (theme) => theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={
                            userDetailsData?.first_name +
                            " " +
                            userDetailsData?.last_name
                          }
                          placement="bottom-start"
                          PopperProps={{
                            style: {
                              maxWidth: "none",
                            },
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              display: "block",
                            }}
                          >
                            {userDetailsData?.first_name +
                              " " +
                              userDetailsData?.last_name}
                          </div>
                        </Tooltip>
                      </div>
                      <div className="user-acc-name-label">User name</div>
                    </div>
                    <div className="user-acc-detail">
                      <div className="user-acc-content">
                        <div className={`user-account-status-div`}>
                          {userDetailsData?.user_id}
                        </div>
                        <div className="user-acc-name-label">User ID</div>
                      </div>
                    </div>
                  </div>
                  <div className="user-account-holder-details">
                    <div className="user-individual-name">
                      <span className="text-Primary_white capitalize text-[16px]">
                        {userDetailsData?.grade}
                      </span>
                      <span className="user-acc-number-label text-xs">
                        Grade
                      </span>
                    </div>
                    <div className="user-bank-code">
                      <div className="user-acc-content">
                        <div className="user-account-name w-full">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={userDetailsData?.alias_name || ""}
                            placement="bottom-start"
                          >
                            <div
                              style={{
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                display: "block",
                              }}
                            >
                              {userDetailsData?.alias_name || "N/A"}
                            </div>
                          </Tooltip>
                        </div>
                        <span className="user-acc-number-label text-xs">
                          Alias
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="user-bank-detail">
                    <div className={`user-individual-name`}>
                      <span
                        className={`text-Primary_white text-[16px]  ${
                          userDetailsData?.active_status == "Y"
                            ? `user-active-status`
                            : `user-inactive-status`
                        }`}
                      >
                        {userDetailsData?.active_status == "Y"
                          ? "Active"
                          : "Inactive"}
                      </span>
                      <span className="user-acc-number-label text-xs">
                        Status
                      </span>
                    </div>
                    <div className="user-acc-holder-name"></div>
                  </div>
                </div>

                <div className="user-minor-div">
                  <div className="user-recipient flex flex-row bg-Blue_7">
                    <div
                      style={{ border: "none" }}
                      className="usr-recipient-box2 flex-grow  "
                    >
                      <Box className=" opacity-50  text-center h-full flex flex-col justify-center gap-1 p-2">
                        <div className="recipient-image-div flex justify-center  ">
                          <Box className="flex justify-center w-10">
                            <img
                              src={setPassword}
                              alt="document"
                              className="recipient-img"
                            />
                          </Box>
                        </div>
                        <div className="flex justify-center px-2">
                          <Box className="image-label h-8/12 self-center antialiased truncate...">
                            Set new password
                          </Box>
                        </div>
                      </Box>
                    </div>
                    <div
                      style={{ height: "60px", marginTop: "15px" }}
                      className="ownerdetail-border-div"
                    ></div>
                    <div
                      style={{ opacity: "0.5" }}
                      className="usr-recipient-box3 flex-grow"
                    >
                      <Box className="text-center   h-full flex flex-col justify-center gap-1 p-2">
                        <div className="recipient-image-div flex justify-center">
                          <Box className="flex justify-center w-10">
                            <img
                              src={autoPassword}
                              alt="document"
                              className="recipient-img"
                            />
                          </Box>
                        </div>
                        <div className="flex justify-center px-1">
                          <Box className="image-label h-8/12 self-center antialiased truncate...">
                            Auto generate password
                          </Box>
                        </div>
                      </Box>
                    </div>
                  </div>
                  <div className="usr-statement flex flex-row bg-Blue_7 ">
                    <div
                      aria-tag={
                        !services?.includes("CTag") &&
                        !services?.includes("DTag")
                          ? "inactive"
                          : specificUserData?.employees?.[0]?.account_tags
                          ? "active"
                          : "inactive"
                      }
                      style={{
                        opacity:
                          !services?.includes("CTag") &&
                          !services?.includes("DTag")
                            ? "0.5"
                            : specificUserData?.employees?.[0]?.account_tags
                            ? "1"
                            : "0.5",
                        cursor:
                          !services?.includes("CTag") &&
                          !services?.includes("DTag")
                            ? "default"
                            : specificUserData?.employees?.[0]?.account_tags
                            ? "pointer"
                            : "default",
                      }}
                      className="usr-recipient-box5  flex-grow"
                    >
                      <Box
                        className="text-center h-full flex flex-col justify-center gap-1 p-2"
                        onClick={() => {
                          if (
                            !services?.includes("CTag") &&
                            !services?.includes("DTag")
                          ) {
                            navigate("/feature_not_assigned");
                          } else if (
                            specificUserData?.employees?.[0]?.account_tags
                          ) {
                            setSelectedTagOpenPop(true);
                          }
                        }}
                      >
                        <div className="recipient-image-div  flex justify-center">
                          <Box className="flex justify-center w-10">
                            <img
                              src={assigntag}
                              alt="document"
                              className="recipient-img"
                            />
                          </Box>
                        </div>
                        <div className="flex justify-center px-2  ">
                          <Box>
                            <div className=" image-label h-8/12 self-center antialiased truncate... w-6">
                              {" "}
                              Assign tag{" "}
                            </div>
                          </Box>
                        </div>
                      </Box>
                    </div>
                    <div
                      style={{ height: "60px", marginTop: "15px" }}
                      className="ownerdetail-border-div"
                    ></div>{" "}
                    <div
                      style={{ opacity: "0.5" }}
                      className="usr-recipient-box6 flex-grow "
                    >
                      <Box className="text-center h-full flex flex-col justify-center gap-1 p-2">
                        <div className="recipient-image-div  flex justify-center">
                          <Box className="flex justify-center w-10">
                            <img
                              src={mapRole}
                              alt="document"
                              className="recipient-img"
                            />
                          </Box>
                        </div>
                        <div className="flex justify-center px-2  ">
                          <Box>
                            <div className=" image-label h-8/12 self-center antialiased truncate... w-6">
                              Map roles
                            </div>
                          </Box>
                        </div>
                      </Box>
                    </div>
                  </div>
                  <div
                    style={{ border: "none" }}
                    className="usr-statement flex flex-row bg-Blue_7 "
                  >
                    <div className="w-[50%] bg-Blue_6"></div>
                    <div
                      style={{ opacity: "0.5" }}
                      className="usr-recipient-box6 flex-grow"
                    >
                      <Box className="text-center h-full flex flex-col justify-center gap-1 p-2">
                        <div className="recipient-image-div  flex justify-center">
                          <Box className="flex justify-center w-10">
                            <img
                              src={editDetails}
                              alt="document"
                              className="recipient-img"
                            />
                          </Box>
                        </div>
                        <div className="flex justify-center px-2  ">
                          <Box>
                            <div className=" image-label h-8/12 self-center antialiased truncate... w-6">
                              Edit details
                            </div>
                          </Box>
                        </div>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="user-div-auto">
            <div className="user-detail-section">
              <div className="user-multi-button">
                <div>
                  <button
                    className={`user-detail-button ${
                      selectedTab === "Details" && "active"
                    }`}
                    onClick={handleTabClick}
                  >
                    Details
                  </button>
                </div>

                <div
                  style={{
                    display: specificUserData?.status ? "flex" : "none",
                  }}
                >
                  <div>
                    <button
                      className={`user-detail-button  ${"hover:bg-Detail_chip_hover"} ${
                        selectedTab === "Roles" && "active"
                      }`}
                      onClick={handleTabClick}
                    >
                      Roles
                    </button>
                  </div>
                  <div className="splitline-div "></div>
                  <div>
                    <button
                      className={`user-detail-button  ${"hover:bg-Detail_chip_hover"} ${
                        selectedTab === "Features" && "active"
                      }`}
                      onClick={handleTabClick}
                    >
                      Features
                    </button>
                  </div>
                  <div>
                    <button
                      className={`user-detail-button  ${"hover:bg-Detail_chip_hover"} ${
                        selectedTab === "Services" && "active"
                      }`}
                      onClick={handleTabClick}
                    >
                      Services
                    </button>
                  </div>
                  <div className="splitline-div"></div>
                  <div>
                    <button
                      className={`user-detail-button  ${"hover:bg-Detail_chip_hover"} ${
                        selectedTab === "Assigned Tags" && "active"
                      }`}
                      onClick={handleTabClick}
                    >
                      Assigned Tags
                    </button>
                  </div>
                </div>
              </div>
              {selectedTab === "Details" && (
                <div className="user-service-card-row grid grid-cols-4 gap-10">
                  <div className="user-contact-detail-card w-1/4">
                    <div>
                      <h4 className="user-title">Contact Details</h4>
                      <div>
                        <div>
                          <div className="font-sans text-sm text-Primary_white">
                            +91 {userDetailsData?.mob_no}
                          </div>
                        </div>
                        <div className="user-phone">Contact number</div>
                      </div>
                    </div>
                    <div className="user-email-section">
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              [`& .${tooltipClasses.arrow}`]: {
                                color: (theme) => theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={userDetailsData?.email_id}
                        arrow
                        placement="bottom"
                        PopperProps={{
                          style: {
                            maxWidth: "none",
                          },
                        }}
                      >
                        <div
                          id="email-id-firstLetter"
                          className="user-email-id-div"
                        >
                          {userDetailsData?.email_id}
                        </div>
                      </Tooltip>
                      <div className="user-email">Email ID</div>
                    </div>
                  </div>
                  <div className="user-contact-detail-card w-1/4">
                    <div>
                      <h4 className="user-title">Origin Timeline</h4>
                      <div>
                        <div>
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={userDetailsData?.created_by.toLowerCase()}
                            arrow
                            placement="bottom"
                            PopperProps={{
                              style: {
                                maxWidth: "none",
                              },
                            }}
                          >
                            <div
                              className="font-sans overflow-hidden
                              whitespace-nowrap
                              overflow-ellipsis text-sm text-Primary_white crt-by-text"
                            >
                              {userDetailsData?.created_by.toLowerCase()}
                            </div>
                          </Tooltip>
                        </div>
                        <div className="user-phone">Created by</div>
                      </div>
                    </div>
                    <div className="user-email-section">
                      <div className="user-email-id-div">
                        {formatDate(userDetailsData?.created_on, true)}
                      </div>
                      <div className="user-email">Created on</div>
                    </div>
                  </div>
                </div>
              )}

              {selectedTab === "Services" && (
                <div className="services-div">
                  {Object.entries(servicesMap).length > 0 ? (
                    Object.entries(servicesMap).map(([header, values]) => (
                      <div key={header}>
                        <div className="services-tab-header">
                          <h5>{header}</h5>
                        </div>
                        <div className="services-tab-div">
                          {values.map((service) => (
                            <div className="services-tab-card" key={service}>
                              {service}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="not-found-container">
                      <img
                        src={notFoundImage}
                        alt="Not Found"
                        className="not-found-image"
                      />
                      <p>No services assigned to this user </p>
                    </div>
                  )}
                </div>
              )}

              {selectedTab === "Roles" && (
                <div className="roles-tab-div">
                  {Object.entries(rolesMap).length > 0 ? (
                    Object.entries(rolesMap).map(([header, values]) => (
                      <div>
                        {values.map((roles) => (
                          <div className="roles-tab-card" key={roles}>
                            {roles}
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="not-found-container">
                      <img
                        src={notFoundImage}
                        alt="Not Found"
                        className="not-found-image"
                      />
                      <p>No roles assigned to this user </p>
                    </div>
                  )}
                </div>
              )}
              {selectedTab === "Features" && (
                <div className="features-div">
                  {Object.entries(featuresMap).length > 0 ? (
                    Object.entries(featuresMap).map(([header, values]) => (
                      <div key={header}>
                        <div className="features-tab-header">
                          <h5>{header}</h5>
                        </div>
                        <div className="features-tab-div">
                          {values.map((feature) => (
                            <div className="features-tab-card" key={feature}>
                              {feature}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="not-found-container">
                      <img
                        src={notFoundImage}
                        alt="Not Found"
                        className="not-found-image"
                      />
                      <p>No feature assigned to this user </p>
                    </div>
                  )}
                </div>
              )}
              {selectedTab === "Assigned Tags" && (
                <div className="tagsdiv">
                  <div
                    aria-tag={
                      !services?.includes("CTag") && !services?.includes("DTag")
                        ? "inactive"
                        : "active"
                    }
                    style={{
                      opacity:
                        !services?.includes("CTag") &&
                        !services?.includes("DTag")
                          ? "0.5"
                          : "1",
                      cursor:
                        !services?.includes("CTag") &&
                        !services?.includes("DTag")
                          ? "default"
                          : "pointer",
                    }}
                    onClick={() => {
                      if (
                        !services?.includes("CTag") &&
                        !services?.includes("DTag")
                      ) {
                        navigate("/feature_not_assigned");
                      } else {
                        setSelectedTagOpenPop(true);
                      }
                    }}
                    className="editicon"
                  >
                    <img src={editIcon} alt="" />
                  </div>
                  {specificUserData?.employees?.[0]?.account_tags?.length >
                  0 ? (
                    <div className="tagchipdiv">
                      {specificUserData?.employees?.[0]?.account_tags?.map(
                        (tags, i) => (
                          <div className="tagchip">{tags}</div>
                        )
                      )}
                    </div>
                  ) : (
                    <p className="noaccfoundtext">
                      No tags assigned to this account{" "}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserDetails;
