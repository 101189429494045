import React, { useEffect, useState } from "react";
import "./index.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkUsertoken } from "../../../redux/features/login/login";
import { Avatar, Box, Button, Grid, Typography, Skeleton } from "@mui/material";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import CloseBtn from "../../../assets/CloseIcons/close.svg";
import PropTypes from "prop-types";
import SecurityCenter from "../../../assets/Graphics/security_center.svg";
import TOTP from "../../../assets/GeneralIcons/TOTP.svg";
import VerificationArrow from "../../../assets/ArrowsIcons/gst_verification_arrow.svg";
import { useMediaQuery } from "react-responsive";

function SkeletonChildrenDemo(props) {
  const { loading = false } = props;

  return (
    <div>
      <Box sx={{ width: "50%" }} style={{ marginLeft: "20px" }}>
        {loading && (
          <Skeleton width="100%">
            <Typography>.</Typography>
          </Skeleton>
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ margin: 1 }}>
          {loading && (
            <Skeleton variant="circular">
              <Avatar />
            </Skeleton>
          )}
        </Box>
        <Box sx={{ width: "100%" }}>
          {loading && (
            <Skeleton width="100%">
              <Typography>.</Typography>
            </Skeleton>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ margin: 1 }}>
          {loading && (
            <Skeleton variant="circular">
              <Avatar />
            </Skeleton>
          )}
        </Box>
        <Box sx={{ width: "100%" }}>
          {loading && (
            <Skeleton width="100%">
              <Typography>.</Typography>
            </Skeleton>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ margin: 1 }}>
          {loading && (
            <Skeleton variant="circular">
              <Avatar />
            </Skeleton>
          )}
        </Box>
        <Box sx={{ width: "100%" }}>
          {loading && (
            <Skeleton width="100%">
              <Typography>.</Typography>
            </Skeleton>
          )}
        </Box>
      </Box>
    </div>
  );
}

SkeletonChildrenDemo.propTypes = {
  loading: PropTypes.bool,
};

const SettingsSecurityCenter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const services = useSelector((state) => state.refreshToken.services);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  const buttonConfigSecurity = [
    {
      title: "SECURITY CENTER",
      items: [
        {
          label: "Set TOTP (Recommended)",
          icon: TOTP,
          route: null,
          requiredService: "PLogin",
        },
      ],
    },
  ];

  const isMobile = useMediaQuery({ maxWidth: 800 });

  const handleNavigate = (type, requiredService, route) => {
    if (isMobile) {
      toast.info("coming soon!", toast_position);
      return;
    }
    if (!services?.includes(requiredService)) {
      dispatch(checkUsertoken());
      navigate("/feature_not_assigned");
    } else {
      dispatch(checkUsertoken());
      navigate(route);
    }
  };

  const renderSecurityButtons = (buttons) =>
    buttons.map((button, buttonIndex) => {
      const { label, icon, route, requiredService, comingSoon } = button;
      const isLastButton = buttonIndex === buttons.length - 1;
      const hasService = services?.includes(requiredService);

      return (
        <Button
          key={label}
          className="cardSecurityCenter relative"
          style={{
            opacity: hasService || services?.includes("PLogin") ? "1" : "0.5",
            borderBottom: isLastButton ? "none" : "1px solid #DADADA",
            cursor:
              hasService || services?.includes("PLogin")
                ? "pointer"
                : "not-allowed",
          }}
          onClick={() => {
            // if (comingSoon) {
            toast.info("Coming Soon", toast_position);
            // } else {
            //   handleNavigate(label, requiredService, route);
            // }
          }}
        >
          <div className="contentDataSecurity">
            <img alt={label} src={icon} />
            <h6 className="text-[#F9F9F9]">{label}</h6>
          </div>
          <img
            alt="arrowright"
            src={VerificationArrow}
            className="w-16 md:w-32 lg:w-48"
          />
        </Button>
      );
    });

  return (
    <div className="min-h-screen max-w-[1024px] text-white flex gap-4 mx-auto pb-5">
      <div
        className="lg:hidden fixed w-full top-0 bg-[#3A5686] h-[56px] z-50 flex items-center cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <img src={CloseBtn} alt="Close" className="w-4 h-4 m-5" />
        <h1 className="text-base flex-1 text-[#F9F9F9]">Security center</h1>
      </div>

      <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 sm:grid-cols-1 max-w-screen-lg mx-auto mt-[80px] lg:mt-[146px] sm:mt-[85px] md:mt-[140px] px-[15px] h-44">
        {loading ? (
          <>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <SkeletonChildrenDemo loading />
              </Grid>
              <Grid item xs={12}>
                <SkeletonChildrenDemo />
              </Grid>
            </Grid>
          </>
        ) : (
          buttonConfigSecurity.map((data, index) => (
            <div key={index} className="pb-9">
              <h2 className="text-custom-pink text-base font-semibold mb-[20px] pl-[10px]">
                {data.title}
              </h2>
              <div className="flex flex-col gap-2">
                {renderSecurityButtons(data.items)}
              </div>
            </div>
          ))
        )}
      </div>

      <div className="hidden sm:block sm:w-[40%] lg:w-[35%] w-1/3 max-w-screen-lg mx-auto mt-[195px] lg:mt-[146px] sm:mt-[85px] md:mt-[140px] pr-4">
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height={400} />
        ) : (
          <img src={SecurityCenter} alt="Settings" className="w-full" />
        )}
      </div>
    </div>
  );
};

export default SettingsSecurityCenter;
