import React, { useState, useEffect } from "react";
import Footer from "../../../../components/LandingPage/Footer";
import BackToTop from "../../../../assets/landingPage/TopArrow.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import termsimage from "../../../../assets/Landing/terms.png";
import { useSelector } from "react-redux";

const PrivacyPolicy = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPercent, setScrollPercent] = useState(0);

  const { mobilestatus, webstatus } = useSelector(
    (state) => state.landingpagestate
  );

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20 || document.documentElement.scrollTop > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scrollTop = window.scrollY;
      let docHeight = document.documentElement.scrollHeight;
      let winHeight = window.innerHeight;
      let scrollPercentt = scrollTop / (docHeight - winHeight);
      let scrollPercentRounded = Math.round(scrollPercentt * 100);
      setScrollPercent(scrollPercentRounded);
    });
  }, [scrollPercent]);

  useEffect(() => {
    window.onload = function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 50);
      setScrollPercent(0);
    };
  }, []);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          size="59px"
          sx={{ color: "#31E5E5" }}
          variant="determinate"
          {...props}
        />
        <Box
          sx={{
            zIndex: 999,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: "text.secondary" }}
          >
            <img
              src={BackToTop}
              alt="back to top"
              width="50"
              style={{ right: "45px", position: "fixed", bottom: "36px" }}
            />
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      <div>
        {isVisible && (
          <div
            onClick={scrollToTop}
            className="fixed bottom-[25px] right-[40px] cursor-pointer z-20"
          >
            <CircularProgressWithLabel value={scrollPercent} />
          </div>
        )}
        <>
          <div
            style={{
              overflow: webstatus ? "hidden" : "",
              overflowY: mobilestatus && "hidden",
              height: "100vh",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              WebkitOverflowScrolling: "touch",
            }}
          >
            <div className="flex flex-col justify-center items-center relative pt-[88px] bg-[#1D3A6D]">
              <div className="text-center">
                <h2 className="text-[28px] pb-2 text-[#F9F9F9] font-semibold">
                  Privacy <span className="text-[#31E5E5]">Policy</span>
                </h2>
              </div>

              <div className="h-full max-w-[1024px] w-screen flex justify-center mx-auto relative">
                <div className="w-full flex flex-col items-center py-5 px-5">
                  <div className="text-[#DADADA]">
                    This Privacy Policy covers our treatment of personally
                    identifiable information collected from our users who use or
                    may want to use our payment services, as well as consumer
                    information that we acquire in the course of our business.
                    This Policy also covers the treatment of personally
                    identifiable information that our back end technology
                    providers / partners may share with it.
                    <br />
                    <br />
                    We respect your privacy and want to promote trust and
                    confidence on the internet. As such, we feel it is necessary
                    to disclose to you what information this site collects and
                    tracks and what we do with the information that we collect.
                    <br />
                    <br />
                    Information security is critical to our business. We work to
                    protect the security of your information during transmission
                    by using Secure Sockets Layer (SSL) software, which encrypts
                    information you input. We store information gathered on
                    secure computers. The number of employees involved in the
                    management of the data centre that have physical access to
                    these computers is limited. We use advanced security
                    technology to prevent our computers from being accessed by
                    unauthorized persons. It is important for you (the user) to
                    protect against unauthorized access to your Login
                    ID/password and to your computer. Be sure to protect the
                    password used to access our services.
                    <br />
                    <br />
                    Currently, we collect personal information of our users,
                    through the user registration process, the service agreement
                    that we execute and various submitted documents of the
                    user's creditability as required by us from time to time. We
                    may ask users to enter personal data when placing an
                    request, such as name, address, phone number, credit card/
                    banking details and E-mail address. We use this data to
                    protect the user and ourselves against risk. Some of our web
                    pages use "cookies" so that we can better serve you with
                    customized information and in some cases protect ourselves
                    and the sub-merchant from the risk of fraud. Cookies are
                    identifiers which a web site can send to your browser to
                    keep on your computer to facilitate your next visit to our
                    site. You can set your browser to notify you when you are
                    sent a cookie, giving you the option to decide whether or
                    not to accept it. The information we collect and analyze is
                    used to improve our service to you.
                    <br />
                    <br />
                    We also track information like the domain name from which
                    you contact us, the pages you request, the products you buy,
                    the referring site, and the time that you spend on the site.
                    For everyone who visits our site, we log IP addresses, the
                    type of operating system that your computer uses, and the
                    type of browser software used by you. From the IP address,
                    we can determine the Internet Service Provider and the
                    geographic location of your point of connectivity. This is
                    done to protect ourselves and the user from fraud risk.
                    <br />
                    <br />
                    If you are our user then the personal information you
                    provide - name, address, etc.-allows us to inform you about
                    updates, or changes to the service and to notify you of
                    products and services of Xpentra, its backend service
                    providers and affiliates that we believe may be of interest
                    to you.
                    <br />
                    <br />
                    We use reasonable precautions to keep the personal
                    information disclosed to us secure and do not disclose this
                    information to other individuals or organizations unless
                    required by law. However we will exchange information with
                    other companies and organizations for the sole purposes of
                    fraud protection and risk reduction. However, in the case of
                    abuse or breach of security, we are not responsible for any
                    breach of security or for any actions of any third parties
                    which receive the information illegally. We will not
                    distribute customer information to be used in mailing lists,
                    surveys, or any other purpose other than what is required to
                    perform our services. However we will send personally
                    identifiable information about you (the user) to other
                    companies or people when:
                    <br />
                    <ol style={{ listStyle: "conic-gradient" }}>
                      <li style={{ margin: "0px 15px" }}>
                        We have your consent to share the information;
                      </li>
                      <li style={{ margin: "0px 15px" }}>
                        We need to share your information to provide the product
                        or service you have requested;
                      </li>
                      <li style={{ margin: "0px 15px" }}>
                        We need to send the information to companies who work on
                        behalf of us to provide a product or service to you
                        (unless we tell you differently these companies do not
                        have any right to use the personally identifiable
                        information we provide them beyond what is necessary to
                        assist us).
                      </li>
                    </ol>
                    <br />
                    Personally identifiable consumer information is also shared
                    with third parties (such as our Acquiring banks and credit
                    card processors) to the extent necessary for us to deliver
                    payment processing services. We are committed to
                    safeguarding the privacy of our customers' personal data.
                    <br />
                    <br />
                    If you (our user) would like to view the information we have
                    collected from you and your customers or you want to correct
                    your information or the details of your customers or their
                    IP addresses, please go to Merchant Login page and log into
                    your account to view the above details and to review your
                    personal information.
                    <br />
                    <br />
                    We respond to government and/ or legal demands/orders
                    regarding information that is contained within our access
                    logs concerning user(s). We reserve the right to share such
                    information concerning to any user who we believe are in
                    violation of our content guidelines, even without a
                    government and/ or legal demands/orders.
                    <br />
                    <br />
                    We also reserve the right to report any illegal activity by
                    our users or their vendors to law enforcement for
                    prosecution. Please be aware that we do not control the acts
                    of our users, customers or visitors.
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
