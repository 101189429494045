import React, { useState, useEffect } from "react";
import "./index.css";
import merchant from "../../../assets/CategoryIcons/merchant.svg";
import FormatAmount from "../../../components/_utils/FormatAmount/FormatAmount";
import { formatAccountNumber } from "../../_utils/FormatAccountNumber";
import { Tooltip, tooltipClasses } from "@mui/material";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import FileFormatMapper from "../BeneTxnAmount/FileFormatMapper";
import ConfidentailImage from "../../../assets/GeneralIcons/confidential_transaction.svg";

const BeneTxnConfirm = (props) => {
  const renderAdditionalDetails = () => {
    if (props.txnPayLoad?.mergeCategory) {
      return props.txnPayLoad?.mergeCategory.map((field, index) => (
        <div key={index}>
          <Tooltip
            sx={{ zIndex: "2147483647 !important" }}
            componentsProps={{
              popper: {
                sx: {
                  [`& .${tooltipClasses.tooltip}`]: {
                    backgroundColor: (theme) => theme.palette.common.black,
                  },
                  zIndex: "2147483647 !important",
                },
              },
            }}
            title={
              field?.data_type === "upload" && field?.value !== "Not Given"
                ? props?.files?.name
                : field?.data_type === "time"
                ? field?.value
                  ? field?.value
                  : "Not Given"
                : field?.data_type === "hyperlink"
                ? field?.value?.length === 0
                  ? "Not Given"
                  : field?.value
                : typeof field?.value === "object" &&
                  field?.data_type === "from_year_to_year"
                ? field?.value.length === 0
                  ? "Not Given"
                  : `${field?.value?.from} - ${field?.value?.to}`
                : field?.data_type === "month_year"
                ? field?.value === "Not Given"
                  ? "Not Given"
                  : field?.value && convertToMonthYear(field?.value)
                : field?.data_type === "multi_select_list"
                ? field?.value?.length === 0
                  ? "Not Given"
                  : field?.value?.join(", ")
                : field?.data_type === "date_range"
                ? field?.value === "Not Given"
                  ? "Not Given"
                  : `${field?.value?.start_date} - ${field?.value?.end_date}`
                : typeof field?.value === "boolean" &&
                  field?.data_type === "toggle"
                ? field?.value === true
                  ? "Yes"
                  : "No"
                : field?.value
            }
          >
            <p
              className="text-sm text-[#F9F9F9] font-sans mb-2 mt-2"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {field?.data_type === "upload" && field?.value !== "Not Given" ? (
                <div className="main_details_upload w-full">
                  <div className="top_details_upload w-[80%]">
                    <p className="top_details_upload_p1 truncate w-[90%]">
                      {props?.files?.name}
                    </p>
                    <p className="text-xs text-[#DADADA] font-sans mt-1">
                      {field?.name}
                    </p>
                  </div>
                  <div className="botton_details_upload w-[20%]">
                    <img
                      src={
                        props?.files?.type?.split("/").pop().toUpperCase() ===
                          "PNG" ||
                        props?.files?.type?.split("/").pop().toUpperCase() ===
                          "BMP" ||
                        props?.files?.type?.split("/").pop().toUpperCase() ===
                          "JPEG" ||
                        props?.files?.type?.split("/").pop().toUpperCase() ===
                          "JPG"
                          ? props?.showImages
                          : FileFormatMapper(
                              props?.files?.type?.split("/").pop().toUpperCase()
                            )
                      }
                      alt="file"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </div>
                </div>
              ) : field?.data_type === "time" ? (
                field?.value ? (
                  field?.value
                ) : (
                  "Not Given"
                )
              ) : field?.data_type === "hyperlink" ? (
                field?.value?.length === 0 ? (
                  "Not Given"
                ) : (
                  field?.value
                )
              ) : typeof field?.value === "object" &&
                field?.data_type === "from_year_to_year" ? (
                field?.value.length === 0 ? (
                  "Not Given"
                ) : (
                  `${field?.value?.from} - ${field?.value?.to}`
                )
              ) : field?.data_type === "month_year" ? (
                field?.value === "Not Given" ? (
                  "Not Given"
                ) : (
                  field?.value && convertToMonthYear(field?.value)
                )
              ) : field?.data_type === "date_range" ? (
                field?.value === "Not Given" ? (
                  "Not Given"
                ) : (
                  `${field?.value?.start_date} - ${field?.value?.end_date}`
                )
              ) : field?.data_type === "multi_select_list" ? (
                field?.value?.length === 0 ? (
                  "Not Given"
                ) : (
                  field?.value?.join(", ")
                )
              ) : typeof field?.value === "boolean" &&
                field?.data_type === "toggle" ? (
                field?.value === true ? (
                  "Yes"
                ) : (
                  "No"
                )
              ) : (
                field?.value
              )}
            </p>
          </Tooltip>

          <div
            className="text-xs text-[#DADADA] font-sans mb-3"
            style={{
              display:
                field?.data_type === "upload" && field?.value !== "Not Given"
                  ? "none"
                  : "flex",
            }}
          >
            {typeof field?.value === "object" &&
            field?.data_type === "from_year_to_year"
              ? "From year - To year"
              : field?.name}
          </div>
          {index !== props.txnPayLoad?.mergeCategory.length - 1 && (
            <hr style={{ width: "100%", border: "1px solid #1D3A6D" }} />
          )}
        </div>
      ));
    }
    return null;
  };

  const convertToMonthYear = (input) => {
    const [month, year] = input?.split("/");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthName = monthNames[parseInt(month, 10) - 1];
    return `${monthName}/${year}`;
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <div className="beneTxnConfirm_Main">
      <div
        className="divideTxnconfirm"
        style={{
          width: isMobile ? `${windowWidth}px` : "",
        }}
      >
        <div className="w-1/2 h-full confirmright">
          <div className="right_side_select_ownerBene_div_top_resConfirm">
            <div className="right_side_select_owner_div_top_img_res rounded-tr-[10px] rounded-bl-[10px]">
              <img src={ConfidentailImage} alt="confidential" />
            </div>
            <div className="flex flex-row items-center w-[100%] justify-center">
              <div className="right_side_select_owner_div_top_para">
                <h3
                  style={{
                    width: "auto",
                    height: "auto",
                    paddingBottom: "5px",
                    color: "#DADADA",
                  }}
                >
                  Create As Confidential Transaction
                </h3>
                <p
                  style={{
                    width: "auto",
                    height: "auto",
                  }}
                >
                  {props?.isConfidential
                    ? `This is a confidential transaction, this transaction will be visible to only those users who have the feature to view confidential transactions.`
                    : `This is not a confidential transaction, this transaction
will be visible to all users who have the feature to view transactions.`}
                </p>
              </div>
              <div
                className="right_side_select_owner_div_top_button"
                style={{
                  backgroundColor: props?.isConfidential
                    ? "#69B76F"
                    : "#707070",
                  cursor: "default",
                  opacity: "0.4",
                }}
              >
                <button
                  className="right_side_select_owner_div_top_button_inner"
                  style={{
                    transform: props?.isConfidential
                      ? "translateX(18px)"
                      : "translateX(0px)",
                    cursor: "default",
                  }}
                ></button>
              </div>
            </div>
          </div>

          <div className="bene_detail relative h-[fit-content] my-4">
            <div className="bene_inside">
              <p className="h-auto">Beneficiary Account Details</p>
              <div className="bene_name items-center font-bold capitalize px-5 cursor-default">
                <Tooltip
                  title={props?.txnPayLoad?.bankAccountName}
                  arrow
                  placement="bottom"
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: "black",
                        },
                        [`& .${tooltipClasses.arrow}`]: {
                          color: "black",
                        },
                      },
                    },
                  }}
                >
                  <span className="overflow-hidden">
                    {props?.txnPayLoad?.bankAccountName?.toLowerCase()}
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className="pt-0 p-5">
              <div className="ass_num text-sm font-sans text-[#F9F9F9]">
                {formatAccountNumber(props.txnPayLoad?.bankAccountNo)}
              </div>
              <div className="ass_name text-xs font-sans text-[#DADADA]">
                Account number
              </div>
              <hr
                style={{
                  width: "100%",
                  marginTop: "10px",
                  border: "1px solid #1D3A6D",
                }}
              />
              <div className="ifsc_num text-sm font-sans text-[#F9F9F9]">
                {props?.txnPayLoad?.bankAccountIFSC}
              </div>
              <div className="ifsc_name">IFSC code</div>
              <div className="flex items-center justify-between w-[110px] h-[37px] absolute right-0 bottom-0">
                <div className="bg-[#ffffff] rounded-tl-[30px] rounded-br-[30px] flex items-center justify-around w-full h-full">
                  {
                    <img
                      src={require("../../../assets/IfscLogo/" +
                        IfscCodeMapper(props?.txnPayLoad?.bankAccountIFSC))}
                      alt="bank"
                      className="w-[58px] h-[14.5px]"
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="additional_detail w-full h-auto my-4 p-5 rounded-[20px] bg-[#314C7F]">
            <span className="additional_detail">Additional Details</span>
            <div className="detail_info">
              {props.txnPayLoad?.mergeCategory ? (
                renderAdditionalDetails()
              ) : (
                <div className="detail_info">
                  {props.txnPayLoad?.mergeCategory ? (
                    renderAdditionalDetails()
                  ) : (
                    <div
                      className="text-left text-white text-base antialiased"
                      style={{
                        marginTop: props.txnPayLoad?.mergeCategory
                          ? "0"
                          : "20px",
                      }}
                    >
                      Additional details are not supported for the selected
                      category.
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="divideconfirm"
        style={{ width: isMobile ? `${windowWidth}px` : "" }}
      >
        <div className="w-[472px] h-fit confirmleft">
          <div className="amount items-center mb-5 flex flex-col">
            <div className="amount-div">
              <div className="amount-sym">₹</div>
              <div className="amount-number">
                <FormatAmount price={props.txnPayLoad?.amount} />
              </div>
            </div>

            <hr
              style={{
                width: "85%",
                marginTop: "6px",
                border: "1px solid #1D3A6D",
              }}
            />

            <div
              className="text-sm confirmleft px-8 pt-5"
              style={{ textAlign: "left" }}
            >
              <div className="text-[#f9f9f9]  capitalize">
                {props.txnPayLoad.amountWords}
              </div>
              <span className="text-[#DADADA] text-xs mt-[5px]">In words</span>
            </div>
          </div>

          <div className="settle_method my-6 h-auto bg-[#314C7F] rounded-[30px] relative">
            <p className="settle_method_p">Source Account Details</p>

            <div className="selected_set_show flex-col">
              <div className="division">
                <div className="accNumber w-1/2 border-r-2 border-solid border-[#102C5D]">
                  <p className="text-sm font-sans  text-[#F9F9F9] ">
                    {formatAccountNumber(props?.txnPayLoad?.ownerBankAccountNo)}
                  </p>
                  <span className="text-xs font-sans text-[#DADADA]">
                    Account number
                  </span>
                </div>
                <div className="ifsc w-1/2">
                  <div className="ml-5">
                    <p className="text-sm font-sans  text-[#F9F9F9] ">
                      {props?.txnPayLoad?.ownerBankIFSC}
                    </p>
                    <span className="text-xs font-sans text-[#DADADA]">
                      IFSC code
                    </span>
                  </div>
                </div>
              </div>
              <hr className="w-full mt-2.5 border border-solid border-[#1D3A6D]" />
              <div className="division mt-2.5">
                <div className="balance w-1/2 border-r-2 border-solid border-[#102C5D]">
                  <p className="text-sm font-sans font-semibold ">
                    <span className="amount-color">₹ </span>
                    {
                      <FormatAmount
                        price={props?.txnPayLoad?.ownerBankBalance}
                      />
                    }
                  </p>
                  <span className="text-xs font-sans">
                    {`Balance updated- ${props?.txnPayLoad?.ownerLastUpdated}`}
                  </span>
                </div>
                <div className="payment_method w-1/2">
                  <div className="ml-5">
                    <p className="text-sm font-sans text-[#F9F9F9] ">
                      {props?.txnPayLoad?.settleType?.toUpperCase()}
                    </p>
                    <span className="text-xs font-sans text-[#DADADA]">
                      Payment method
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between w-[110px] h-[37px] absolute right-0 bottom-0">
              <div className="bg-[#ffffff] rounded-tl-[30px] rounded-br-[30px] flex items-center justify-around w-full h-full">
                {
                  <img
                    src={require("../../../assets/IfscLogo/" +
                      IfscCodeMapper(props?.txnPayLoad?.ownerBankIFSC))}
                    alt="bank"
                    className="w-[58px] h-[14.5px]"
                  />
                }
              </div>
            </div>
          </div>
          <div className="select_cat ">
            <p className="select_cat_p "> Transaction Category</p>
            <div className="selected_cat_show flex items-center">
              <img src={merchant} alt="Merchant Logo" />
              <Tooltip
                title={props?.selectedCategory?.name}
                arrow
                placement="bottom"
                PopperProps={{
                  style: {
                    maxWidth: "none",
                  },
                }}
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                      [`& .${tooltipClasses.arrow}`]: {
                        color: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
              >
                <p className="truncate" style={{ width: "325px" }}>
                  {props?.selectedCategory?.name}
                </p>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneTxnConfirm;
