import { LineChart } from "@mui/x-charts";
import { alpha, useTheme } from "@mui/material";
import formatTotalBalance from "../../../../utils/formatTotalBalance";
import "./index.css";

const OwnerStatisticsGraph = ({
  missedIncomeGraphData,
  actualIncomeGraphData,
  amountRangeValue,
  pastSevenDays,
}) => {
  const theme = useTheme();

  const formattedMissedIncomeData = missedIncomeGraphData.map((value) => value);
  const formattedActualIncomeData = actualIncomeGraphData.map((value) => value);

  // Calculate min and max values dynamically based on the data
  const allData = [...formattedMissedIncomeData, ...formattedActualIncomeData];

  const dataMin = Math.min(...allData);
  const dataMax = Math.max(...allData);

  const yMin = dataMin - (dataMax - dataMin) * 0.2; // 20% padding below min value
  const yMax = dataMax + (dataMax - dataMin);

  return (
    <div>
      <LineChart
        tooltip={{ trigger: "item" }}
        series={[
          {
            id: "missed",
            data: formattedMissedIncomeData,
            area: true,
            color: "#FBC02D",
            curve: "linear",
            showMark: true,
            baseline: "min",
            valueFormatter: (value) => {
              return "₹ " + formatTotalBalance(value, "amount").amount;
            },
          },
          {
            id: "actual",
            data: formattedActualIncomeData,
            area: true,
            color: "#CE362A",
            curve: "linear",
            showMark: true,
            baseline: "min",
            valueFormatter: (value) => {
              return "₹ " + formatTotalBalance(value, "amount").amount;
            },
          },
        ]}
        height={325}
        width={amountRangeValue === "" && dataMax >= 1000000000 ? 690 : 720}
        className={`linechart ${
          amountRangeValue === "" && dataMax >= 1000000000
            ? "ml-[2px]"
            : "ml-[-30px]"
        }`}
        xAxis={[
          {
            valueFormatter: (value) => pastSevenDays[value],
            data: [0, 1, 2, 3, 4, 5, 6],
            tickMinStep: 1,
          },
        ]}
        yAxis={[
          {
            min: yMin,
          },
        ]}
        sx={{
          "& .MuiAreaElement-series-missed": {
            fill: "var(--Queued)",
            fillOpacity: 0.1,
          },
          "& .MuiAreaElement-series-actual": {
            fill: "var(--Verification_failed)",
            fillOpacity: 0.1,
          },

          "& .MuiChartsAxisHighlight-root": {
            strokeDasharray: 0,
            strokeWidth: 1,
            stroke: "var(--Primary_white)",
          },
          "& .MuiChartsAxis-root line": {
            stroke: "var(--Primary_white)",
            strokeWidth: 1,
          },
          "& .MuiChartsAxis-tick text": {
            fill: "var(--Primary_white)",
            fontSize: "14px",
          },
          "& .MuiChartsAxis-tickLabel": {
            fill: "var(--Primary_white) !important",
          },
        }}
      />
    </div>
  );
};

export default OwnerStatisticsGraph;
