export function formatTime(timestamp) {
  const unixTimestamp = timestamp * 1000;

  const date = new Date(unixTimestamp);

  let hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const amPM = hours >= 12 ? "pm" : "am";
  hours = hours % 12 || 12;
  const formattedDateTime = `${hours}:${minutes}:${seconds} ${amPM}`;

  return formattedDateTime;
}
