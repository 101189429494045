import DeclinedImage from "../../../assets/Approve transactions/DeclinedTxn.png";
import "./index.css";
import createTransaction from "../../../assets/create_transaction.png";
import dashboard from "../../../assets/Dashboard.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, tooltipClasses } from "@mui/material";

const InternalTxnErrorPage = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const internalError = JSON.parse(sessionStorage.getItem("internalRejected"));
  const handleNavigate = () => {
    navigate("/transaction/internal_transaction/dashboard");
  };

  return (
    <>
      {!isMobileView && (
        <div className="min-h-screen bg-[#754545]">
          <div className="flex justify-center">
            <div className="flex w-[1024px] justify-center">
              <div className="flex-1 mt-[66px] pt-[112px]">
                <div className="flex min-w-screen justify-center items-center bg-[#754545]">
                  <div className="p-2 flex flex-col items-center text-white text-2xl">
                    <div>
                      <img
                        src={DeclinedImage}
                        alt="Transaction failed"
                        width={"90%"}
                        height={"100%s"}
                      />
                    </div>
                    <div className="pt-7">
                      <p className="text-xl font-[#F9F9F9] font-semibold">
                        Transaction failed
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-[66px] py-[35px]">
                <div className="w-[472px] flex-row relative justify-end h-[553px] bg-white rounded-tr-[20px] rounded-tl-[20px] rounded-br-[25px] rounded-bl-[25px] opacity-100">
                  <div className="w-full rounded-tr-[20px] rounded-tl-[20px]">
                    {typeof internalError === "string" ? (
                      <div className="pt-[30px] w-full pl-[30px] pr-[30px]">
                        <p className="font-normal text-[16px] leading-[22px] whitespace-wrap tracking-normal text-black opacity-100">
                          {internalError}
                        </p>
                        <span className="w-[81px] h-[17px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-[#828282]">
                          Error message
                        </span>
                      </div>
                    ) : (
                      Object.entries(internalError)?.map(([key, arr]) => {
                        return (
                          <div
                            className="pt-[30px] w-full pl-[30px] pr-[30px]"
                            key={key}
                          >
                            <p className="font-normal text-[16px] leading-[22px] whitespace-wrap tracking-normal text-black opacity-100">
                              {arr}
                            </p>
                            <span className="w-[81px] h-[17px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-[#828282]">
                              Error message
                            </span>
                          </div>
                        );
                      })
                    )}
                  </div>
                  <div className="w-full rounded-[20px] absolute bottom-0">
                    <div
                      className="w-full cursor-pointer text-white rounded-[20px] bg-[#985555] flex justify-evenly items-center p-4"
                      onClick={handleNavigate}
                    >
                      <div className="flex flex-col space-y-1 w-2/6 h-3/4 items-center justify-center text-center">
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"60%"}
                            height={"60%"}
                            src={dashboard}
                            alt="Go to Dashboard"
                          />
                        </div>
                        <p className="text-xs">
                          Go to
                          <br />
                          dashboard
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isMobileView && (
        <div className="bg-[#754545]">
          <div className="flex flex-col justify-center items-center p-[30px] h-auto lg:h-[281px]">
            <div className="h-[200px] w-[300px]">
              <img src={DeclinedImage} alt="Transaction failed" />
            </div>
            <p className="text-xl pt-6 text-white">Transaction failed</p>
          </div>
          <div className="flex-row relative justify-end h-[520px] bg-white shadow-[0_3px_6px_rgba(0,0,0,0.29)] opacity-100">
            <div className="w-full">
              <div className="pt-[30px] w-full pl-[30px] pr-[30px]">
                <Tooltip
                  title={Object.entries(internalError)?.map(([key, arr]) => {
                    return (
                      <div
                        className="pt-[30px] w-full pl-[30px] pr-[30px]"
                        key={key}
                      >
                        <p className="font-normal text-[16px] leading-[22px] whitespace-wrap tracking-normal text-black opacity-100">
                          {arr}
                        </p>
                        <span className="w-[81px] h-[17px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-[#828282]">
                          Error message
                        </span>
                      </div>
                    );
                  })}
                  arrow
                  placement="bottom"
                  PopperProps={{
                    modifiers: [
                      {
                        name: "preventOverflow",
                        options: {
                          boundary: "viewport",
                        },
                      },
                    ],
                    style: {
                      maxWidth: "none",
                    },
                  }}
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                          color: "#FFFFFF",
                          fontSize: "14px",
                          padding: "8px",
                          borderRadius: "4px",
                        },
                        [`& .${tooltipClasses.arrow}`]: {
                          color: (theme) => theme.palette.common.black,
                        },
                      },
                    },
                  }}
                  enterTouchDelay={0}
                >
                  {Object.entries(internalError)?.map(([key, arr]) => {
                    return (
                      <div
                        className="pt-[30px] w-full pl-[30px] pr-[30px]"
                        key={key}
                      >
                        <p className="font-normal text-[16px] leading-[22px] whitespace-wrap tracking-normal text-black opacity-100">
                          {arr}
                        </p>
                        <span className="w-[81px] h-[17px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-[#828282]">
                          Error message
                        </span>
                      </div>
                    );
                  })}
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="w-full fixed bottom-0">
            <div
              className="rounded-t-xl w-full cursor-pointer text-white bg-[#985555] flex justify-evenly items-center p-4"
              onClick={handleNavigate}
            >
              <div className="flex flex-col space-y-1 w-2/6 h-3/4 items-center justify-center text-center">
                <div className="w-3/6 flex justify-center">
                  <img
                    width={"60%"}
                    height={"60%"}
                    src={dashboard}
                    alt="Go to Dashboard"
                  />
                </div>
                <p className="text-xs">
                  Go to
                  <br />
                  dashboard
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InternalTxnErrorPage;
