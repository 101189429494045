import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Tooltip, tooltipClasses } from "@mui/material";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber";
import internalAccountIcon from "../../../../assets/Beneficiary Icons/internal_with_white_circle without fill.svg";
import noaccount from "../../../../assets/Graphics/No transaction found.svg";
import { useDispatch } from "react-redux";
import { ownerListViewAllAcc } from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
const BeneAccListView = ({
  data,
  isSidebarOpen,
  isPopoverOpen,
  typepop,
  handleButtonClick,
  pageSize,
  internalAccount,
  associateAccount,
  setOpenInternalAssociate,
  associateaccountNumber,
  balanceType,
  associatepanNumber,
  showSearchBar,
  beneAccountId,
  txnPayLoad,
  setTxnPayLoad,
  setIsNeft,
  setIsRtgs,
  setIsImps,
  onClosepop,
  flow,
  accountType,
  setOpenBeneAssociate,
  associateTxn,
}) => {
  const [columns, setColumns] = useState(3);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addSpacesToNumber = (number) => {
    return number.replace(/(.{4})/g, "$1 ").trim();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#4ddd37";
      case "rejected":
      case "blocked":
        return "#ff5860";
      case "archived":
        return "#a3a3a3";
      case "rejected":
        return "#ff5860";
      case "hold":
        return "#FBC02D";
      case "queued":
        return "#FBC02D";
      case "all":
        return "#ffff";
      case "settled":
        return "#4ddd37";
      default:
        return "#ffff";
    }
  };

  const listdata = data || [];

  const IntBneAccount = async (item) => {
    let data1 = await dispatch(
      ownerListViewAllAcc({
        bank_account_no: item?.bank_acc_no,
        pan: item?.pan_no,
        ifsc: item?.ifsc_code,
      })
    );
    setTxnPayLoad({
      ...txnPayLoad,
      bankAccountIFSC: item?.ifsc_code,
      bankAccountName: item?.name,
      bankAccountNo: item?.bank_acc_no,
      beneAccountId: item?.id,
      isDestinationOwnerActive: item?.internal_account,
      destinationAccountBalance:
        data1?.payload?.owner_accounts[0]?.owner_account_balance,
      destinationAcountOwnerId: data1?.payload?.owner_accounts[0]?.id,
      destinationAcountOwnerLastUpdated:
        data1?.payload?.owner_accounts[0]
          ?.owner_account_balance_last_fetched_at_in_words,
    });

    setIsNeft(true);
    setIsRtgs(false);
    setIsImps(false);
  };

  const displayedDAata = () => {
    if (associateAccount) {
      if (balanceType === "Pan") {
        return listdata.filter(
          (item) => item.bank_acc_no !== associateaccountNumber
        );
      } else if (balanceType === "Bank") {
        return listdata.filter(
          (item) =>
            item.bank_acc_no !== associateaccountNumber &&
            item.pan_no !== associatepanNumber
        );
      }
    } else {
      return listdata;
    }
  };
  useEffect(() => {
    const updateLayout = () => {
      const width = window.innerWidth;
      let newColumns;
      let newCardWidth;

      if (width >= 3840) {
        newColumns = 5;
        newCardWidth = "720px";
      } else if (width >= 2560) {
        newColumns = 4;
        newCardWidth = "600px";
      } else if (width >= 1920) {
        newColumns = 3;
        newCardWidth = "425px";
      } else {
        newColumns = 3;
        newCardWidth = "425px";
      }

      setColumns(newColumns);
    };

    updateLayout();

    window.addEventListener("resize", updateLayout);

    return () => window.removeEventListener("resize", updateLayout);
  }, []);

  return (
    <>
      <div id="webviewd">
        <div
          className="bene-txn-list-container_acc"
          style={{
            marginBottom:
              typepop === "popup" && pageSize == 102
                ? "120px"
                : typepop === "popup"
                ? "120px"
                : "50px",
            display:
              listdata.filter((item) => item.id !== beneAccountId).length === 0
                ? "flex"
                : "grid",
            gridTemplateColumns:
              typepop === "popup" && pageSize == 102
                ? `repeat(${3}, 1fr)`
                : typepop === "popup" && pageSize == 192
                ? `repeat(${4}, 1fr)`
                : `repeat(${columns}, 1fr)`,
            columnGap: "30px",
            // opacity: isSidebarOpen || isPopoverOpen ? "0.8" : "1",
            // background: isSidebarOpen || isPopoverOpen ? "#000" : "",
          }}
        >
          {/* {listdata.map((item) => (
            <div
              key={item.id}
              style={{
                height: "49px",
                cursor: associateAccount
                  ? "pointer"
                  : typepop === "popup" &&
                    (item?.approval_status === "blocked" ||
                      item?.approval_status === "archived" ||
                      item?.approval_status === "rejected")
                  ? "default"
                  : isSidebarOpen || isPopoverOpen
                  ? "default"
                  : "pointer",
                pointerEvents: isSidebarOpen || isPopoverOpen ? "none" : "",
                opacity: associateAccount
                  ? "1"
                  : typepop === "popup" &&
                    (item?.approval_status === "blocked" ||
                      item?.approval_status === "archived" ||
                      item?.approval_status === "rejected")
                  ? "0.4"
                  : "1",
                // background: isSidebarOpen || isPopoverOpen ? "#000" : "",
                marginBottom: "10px",
              }}
            >
              <div
                onClick={async () => {
                  if (
                    typepop === "popup" &&
                    (item?.approval_status === "blocked" ||
                      item?.approval_status === "archived" ||
                      item?.approval_status === "rejected") &&
                    !associateAccount
                  ) {
                    return;
                  }
                  if (typepop === "popup" && !associateAccount) {
                    handleButtonClick(item);
                  } else {
                    let selection = window.getSelection();
                    if (selection.toString().length === 0) {
                      if (!isSidebarOpen) {
                        const redirectUrl =
                          internalAccount === "internalAccount" ||
                          associateAccount
                            ? `/accounts/internal_account/details/${item.id}`
                            : `/accounts/beneficiary_account/details/${item.id}`;
                        if (associateAccount) {
                          setOpenInternalAssociate(false);
                        }
                        navigate(redirectUrl);
                      }
                    }
                  }
                }}
                style={{
                  opacity: isSidebarOpen || isPopoverOpen ? "0.8" : "1",
                  background: isSidebarOpen || isPopoverOpen ? "#000" : "",
                  cursor: associateAccount
                    ? "pointer"
                    : typepop === "popup" &&
                      (item?.approval_status === "blocked" ||
                        item?.approval_status === "archived" ||
                        item?.approval_status === "rejected")
                    ? "default"
                    : isSidebarOpen || isPopoverOpen
                    ? "default"
                    : "pointer",
                  pointerEvents: isSidebarOpen || isPopoverOpen ? "none" : "",
                }}
                className="bene_txn_list_section_acc"
              >
                <div className="bene_txn_listview_bank_div_img_acc">
                  <img
                    src={require("../../../../assets/IfscLogo/" +
                      IfscCodeMapper(item?.ifsc_code))}
                    alt="bank"
                  />
                </div>
                <div className="bene_acc_list_accounts_div_acc font-normal text-[#F9F9F9]">
                  <p
                    className="bene_txn_list_name_acc"
                    style={{
                      font: "normal normal normal 12px/17px Open Sans",
                      marginBottom: "2px",
                    }}
                  >
                    {" "}
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={
                        <span style={{ textTransform: "capitalize" }}>
                          {item?.name.toLowerCase()}
                        </span>
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            width:
                              internalAccount === "internalAccount"
                                ? "160px"
                                : "",
                            height:
                              internalAccount === "internalAccount"
                                ? "16px"
                                : "",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {item.name
                            .toLowerCase()
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </span>
                        {internalAccount === "internalAccount" && (
                          <img
                            className="bene_viewall_payee"
                            src={internalAccountIcon}
                            alt=""
                          />
                        )}
                      </div>
                    </Tooltip>
                  </p>

                  <p
                    className="font-normal text-[12px] leading-19 text-[#DADADA] opacity-100"
                    style={{
                      font: "normal normal normal 12px/17px Open Sans;",
                      // width: "185px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      margin: " 0px 10px",
                    }}
                  >
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={
                        <span>{formatAccountNumber(item?.bank_acc_no)}</span>
                      }
                    >
                      {formatAccountNumber(item?.bank_acc_no)}
                    </Tooltip>
                  </p>
                </div>

                <div className="bene_txn_list_amount_div_acc">
                  <p
                    className="overflow-hidden whitespace-nowrap overflow-ellipsis text-[#4DE659] capitalize"
                    style={{
                      font: "normal normal normal 12px/17px Open Sans",
                      color: getStatusColor(item?.approval_status),
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        fontSize: "13px",
                      }}
                    >
                      {item?.approval_status}
                    </span>
                  </p>
                  <p
                    className="font-normal pt-1 text-[10px] leading-19 text-[#DADADA] opacity-100"
                    style={{
                      font: "normal normal normal 10px/14px Open Sans",
                    }}
                  >
                    <span>Account status</span>
                  </p>
                </div>
              </div>
            </div>
          ))} */}
          {listdata?.filter((item) => item.id !== beneAccountId).length > 0 ? (
            listdata
              ?.filter((item) => item.id !== beneAccountId)
              .map((item) => (
                <div
                  key={item.id}
                  style={{
                    height: "49px",
                    cursor: associateAccount
                      ? "pointer"
                      : typepop === "popup" &&
                        (item?.approval_status === "blocked" ||
                          item?.approval_status === "archived" ||
                          item?.approval_status === "rejected")
                      ? "default"
                      : isSidebarOpen || isPopoverOpen
                      ? "default"
                      : "pointer",
                    pointerEvents: isSidebarOpen || isPopoverOpen ? "none" : "",
                    opacity: associateAccount
                      ? "1"
                      : typepop === "popup" &&
                        (item?.approval_status === "blocked" ||
                          item?.approval_status === "archived" ||
                          item?.approval_status === "rejected")
                      ? "0.4"
                      : "1",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    onClick={async () => {
                      if (
                        typepop === "popup" &&
                        (item?.approval_status === "blocked" ||
                          item?.approval_status === "archived" ||
                          item?.approval_status === "rejected") &&
                        !associateAccount
                      ) {
                        return;
                      } else if (typepop === "popup" && !associateAccount) {
                        handleButtonClick(item);
                      } else {
                        let selection = window.getSelection();
                        if (selection.toString().length === 0) {
                          if (!isSidebarOpen) {
                            const redirectUrl =
                              internalAccount === "internalAccount" ||
                              accountType === "internal"
                                ? `/accounts/internal_account/details/${item.id}`
                                : `/accounts/beneficiary_account/details/${item.id}`;
                            if (associateAccount) {
                              setOpenInternalAssociate(false);
                              setOpenBeneAssociate(false);
                            }
                            navigate(redirectUrl);
                          }
                        }
                      }
                    }}
                    style={{
                      opacity: isSidebarOpen || isPopoverOpen ? "0.8" : "1",
                      background: isSidebarOpen || isPopoverOpen ? "#000" : "",
                      cursor: associateAccount
                        ? "pointer"
                        : typepop === "popup" &&
                          (item?.approval_status === "blocked" ||
                            item?.approval_status === "archived" ||
                            item?.approval_status === "rejected")
                        ? "default"
                        : isSidebarOpen || isPopoverOpen
                        ? "default"
                        : "pointer",
                      pointerEvents:
                        isSidebarOpen || isPopoverOpen ? "none" : "",
                    }}
                    className="bene_txn_list_section_acc"
                  >
                    <div className="bene_txn_listview_bank_div_img_acc">
                      <img
                        src={require("../../../../assets/IfscLogo/" +
                          IfscCodeMapper(item?.ifsc_code))}
                        alt="bank"
                      />
                    </div>
                    <div className="bene_acc_list_accounts_div_acc font-normal text-[#F9F9F9]">
                      <p
                        className="bene_txn_list_name_acc"
                        style={{
                          font: "normal normal normal 12px/17px Open Sans",
                          marginBottom: "2px",
                        }}
                      >
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={
                            <span style={{ textTransform: "capitalize" }}>
                              {item?.name.toLowerCase()}
                            </span>
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              style={{
                                width:
                                  internalAccount === "internalAccount"
                                    ? "160px"
                                    : "",
                                height:
                                  internalAccount === "internalAccount"
                                    ? "16px"
                                    : "",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              {item.name
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                            </span>
                            {internalAccount === "internalAccount" ||
                            accountType === "internal" ||
                            associateTxn === "associateTxn" ||
                            flow === "internalTxn" ? (
                              <img
                                className="bene_viewall_payee"
                                src={internalAccountIcon}
                                alt=""
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </Tooltip>
                      </p>

                      <p
                        className="font-normal text-[12px] leading-19 text-[#DADADA] opacity-100"
                        style={{
                          font: "normal normal normal 12px/17px Open Sans;",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          margin: " 0px 10px",
                        }}
                      >
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={
                            <span>
                              {formatAccountNumber(item?.bank_acc_no)}
                            </span>
                          }
                        >
                          {formatAccountNumber(item?.bank_acc_no)}
                        </Tooltip>
                      </p>
                    </div>

                    <div className="bene_txn_list_amount_div_acc">
                      <p
                        className="overflow-hidden whitespace-nowrap overflow-ellipsis text-[#4DE659] capitalize"
                        style={{
                          font: "normal normal normal 12px/17px Open Sans",
                          color: getStatusColor(item?.approval_status),
                        }}
                      >
                        <span
                          style={{
                            display: "inline-block",
                            fontSize: "13px",
                            paddingRight: "5px",
                          }}
                        >
                          {item?.approval_status}
                        </span>
                      </p>
                      <p
                        className="font-normal pt-1 text-[10px] leading-19 text-[#DADADA] opacity-100"
                        style={{
                          font: "normal normal normal 10px/14px Open Sans",
                        }}
                      >
                        <span>Account status</span>
                      </p>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <div
              className="flex flex-col items-center justify-center m-auto w-full h-full"
              style={{
                marginTop:
                  typepop === "popup" && !showSearchBar
                    ? "-6vh"
                    : typepop === "popup" && showSearchBar
                    ? "-9vh"
                    : "",
                height:
                  typepop === "popup" && !showSearchBar
                    ? ""
                    : typepop === "popup" && showSearchBar
                    ? "100%"
                    : "",
                overflowY: "scroll",
                paddingBottom:
                  associateAccount || associateTxn === "associateTxn"
                    ? "1vh"
                    : "12vh",
                paddingTop:
                  associateAccount || associateTxn === "associateTxn"
                    ? "12vh"
                    : "10vh",
                scrollbarWidth: "none",
              }}
            >
              <img
                src={noaccount}
                alt="No accounts found"
                className="bene-acc-no-acc-image"
                style={{
                  height:
                    typepop === "popup" && showSearchBar ? "220px" : "230px",
                }}
              />
              <p className="bene-acc-no-beneaccount-text">No accounts found.</p>
            </div>
          )}
        </div>
      </div>
      <div id="mobileviewd">
        <div
          className="bene-txn-list-container_accm"
          style={{
            paddingBottom: "20px",
            opacity: isSidebarOpen || isPopoverOpen ? "0.4" : "1",
            background: isSidebarOpen || isPopoverOpen ? "#000" : "",
          }}
        >
          {listdata?.map((item) => (
            <div
              key={item.id}
              style={{
                height: "49px",
                cursor: isSidebarOpen || isPopoverOpen ? "default" : "pointer",
                pointerEvents: isSidebarOpen || isPopoverOpen ? "none" : "",
                opacity: isSidebarOpen || isPopoverOpen ? "0.8" : "1",
                background: isSidebarOpen || isPopoverOpen ? "#000" : "",
                marginBottom: "10px",
              }}
            >
              <div
                onClick={async () => {
                  let selection = window.getSelection();
                  if (selection.toString().length === 0) {
                    if (!isSidebarOpen) {
                      const redirectUrl =
                        internalAccount === "internalAccount"
                          ? `/accounts/internal_account/details/${item.id}`
                          : `/accounts/beneficiary_account/details/${item.id}`;

                      navigate(redirectUrl);
                    }
                  }
                }}
                style={{
                  opacity: isSidebarOpen || isPopoverOpen ? "0.8" : "1",
                  background: isSidebarOpen || isPopoverOpen ? "#000" : "",
                  cursor:
                    isSidebarOpen || isPopoverOpen ? "default" : "pointer",
                  pointerEvents: isSidebarOpen || isPopoverOpen ? "none" : "",
                }}
                className="bene_txn_list_section_accm"
              >
                <div className="bene_txn_listview_bank_div_img_acc">
                  <img
                    src={require("../../../../assets/IfscLogo/" +
                      IfscCodeMapper(item?.ifsc_code))}
                    alt="bank"
                  />
                </div>
                <div className="bene_acc_list_accounts_div_acc font-normal w-[180px]  text-[#F9F9F9]">
                  <p
                    className="bene_txn_list_name_acc overflow-hidden whitespace-nowrap overflow-ellipsis"
                    style={{
                      font: "normal normal normal 12px/17px Open Sans",
                      marginBottom: "2px",
                    }}
                  >
                    {" "}
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={
                        <span style={{ textTransform: "capitalize" }}>
                          {item?.name.toLowerCase()}
                        </span>
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            width:
                              internalAccount === "internalAccount"
                                ? "160px"
                                : "",
                            height:
                              internalAccount === "internalAccount"
                                ? "16px"
                                : "",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {item.name
                            .toLowerCase()
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </span>
                        {internalAccount === "internalAccount" ||
                          (accountType === "internal" && (
                            <img
                              className="bene_viewall_payee"
                              src={internalAccountIcon}
                              alt=""
                            />
                          ))}
                      </div>
                    </Tooltip>
                  </p>

                  <p
                    className="font-normal text-[12px] leading-19 text-[#DADADA] opacity-100"
                    style={{
                      font: "normal normal normal 12px/17px Open Sans;",
                      // width: "119px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      margin: "0px 10px",
                    }}
                  >
                    {" "}
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={
                        <span>{formatAccountNumber(item?.bank_acc_no)}</span>
                      }
                    >
                      {formatAccountNumber(item?.bank_acc_no)}
                    </Tooltip>
                  </p>
                </div>

                <div className="bene_txn_list_amount_div_acc">
                  <p
                    className="overflow-hidden whitespace-nowrap overflow-ellipsis text-[#4DE659] capitalize"
                    style={{
                      font: "normal normal normal 12px/17px Open Sans",
                      color: getStatusColor(item?.approval_status),
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        fontSize: "13px",
                        paddingRight: "5px",
                      }}
                    >
                      {item?.approval_status}
                    </span>
                  </p>
                  <p
                    className="font-normal pt-1 text-[10px] leading-19 text-[#DADADA] opacity-100"
                    style={{
                      font: "normal normal normal 10px/14px Open Sans",
                    }}
                  >
                    <span>Account status</span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BeneAccListView;
