const formatTotalBalance = (num, curr) => {
  if (num == "xxxx.xx" || isNaN(num) || num === null || num === undefined) {
    let word = { amount: "Redacted", prefix: "" };
    return word;
  }
  let words = { amount: "", prefix: "" };
  const formattedPrice = Intl.NumberFormat(
    curr == "Billions" || curr == "Millions" ? "en-US" : "en-IN",
    {
      currency: curr == "Billions" || curr == "Millions" ? "USD" : "INR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  ).format(Number(num));

  const [integerPart, decimalPart] = formattedPrice.split(".");
  const paddedIntegerPart = integerPart.padStart(2, "");

  const crore = Number(num) / 10000000;
  const billions = Number(num) / 1000000000;
  const millions = Number(num) / 1000000;
  const lakh = Number(num) / 100000;
  const amount = Number(num);

  if (curr == "Billions") {
    let val = String(billions).slice(1, billions.length);
    if (String(num).substring(0, 1) == "-" && Math.floor(Number(val)) > 0) {
      words.amount = -(Math.floor(val * 100) / 100).toFixed(2);
      words.prefix = "billions";
    } else if (billions > 0) {
      words.amount = (Math.floor(billions * 100) / 100).toFixed(2);
      words.prefix = "billions";
    } else {
      words.amount = (Math.floor(billions * 100) / 100).toFixed(2);
      words.prefix = "billions";
    }
  } else if (curr == "Millions") {
    let val = String(millions).slice(1, millions.length);
    if (String(num).substring(0, 1) == "-" && Math.floor(Number(val)) > 0) {
      words.amount = -(Math.floor(val * 100) / 100).toFixed(2);
      words.prefix = "millions";
    } else if (millions > 0) {
      words.amount = (Math.floor(millions * 100) / 100).toFixed(2);
      words.prefix = "millions";
    } else {
      words.amount = (Math.floor(billions * 100) / 100).toFixed(2);
      words.prefix = "millions";
    }
  } else if (curr == "Crores") {
    let val = String(crore).slice(1, crore.length);
    if (String(num).substring(0, 1) == "-" && Math.floor(Number(val)) > 0) {
      words.amount = -(Math.floor(val * 100) / 100).toFixed(2);
      words.prefix = "crores";
    } else if (crore > 0) {
      words.amount = (Math.floor(crore * 100) / 100).toFixed(2);
      words.prefix = "crores";
    } else {
      words.amount = (Math.floor(billions * 100) / 100).toFixed(2);
      words.prefix = "crores";
    }
  } else if (curr == "Lakhs") {
    let val = String(lakh).slice(1, lakh.length);
    if (String(num).substring(0, 1) == "-" && Math.floor(Number(val)) > 0) {
      words.amount = -(Math.floor(val * 100) / 100).toFixed(2);
      words.prefix = "lakhs";
    } else if (lakh > 0) {
      words.amount = (Math.floor(lakh * 100) / 100).toFixed(2);
      words.prefix = "lakhs";
    } else {
      words.amount = (Math.floor(billions * 100) / 100).toFixed(2);
      words.prefix = "lakhs";
    }
  } else if (curr == "amount") {
    words.amount = `${paddedIntegerPart}.${decimalPart}`;
    words.prefix = "amount";
  } else {
    let val = String(crore).slice(1, crore.length);
    if (String(num).substring(0, 1) == "-" && Math.floor(Number(val)) > 0) {
      words.amount = -(Math.floor(val * 100) / 100).toFixed(2);
      words.prefix = "crores";
    } else if (Math.floor(crore) > 0) {
      words.amount = (Math.floor(crore * 100) / 100).toFixed(2);
      words.prefix = "crores";
    } else {
      words.amount = `${paddedIntegerPart}.${decimalPart}`;
      words.prefix = "";
    }
  }

  return words;
};

export default formatTotalBalance;
