import React from "react";
import { tooltipClasses, Tooltip } from "@mui/material";
import formatHolidayDate from "../../../utils/holidayDateFormatter";
import "./index.css";

function HolidayCard({ date, occasion, isNewMonth }) {
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const holidayDate = new Date(date);

  return (
    <div>
      {isNewMonth && (
        <div
          className="month-label"
          style={{ fontWeight: "bold", marginBottom: "5px" }}
        >
          {holidayDate.toLocaleString("default", { month: "long" })}
        </div>
      )}

      <div className="calendarOuter">
        <div className="calendarInner">
          <div className="flex flex-col items-center justify-center text-Primary_white">
            <span className="text-2xl">{holidayDate.getDate()}</span>
            <span className="text-sm">{weekdays[holidayDate.getDay()]}</span>
          </div>
        </div>

        <div className="occasionName">
          <span className="text-Primary_white text-xs font-semibold">
            <Tooltip
              componentsProps={{
                popper: {
                  sx: {
                    zIndex: 99999,
                    [`& .${tooltipClasses.tooltip}`]: {
                      backgroundColor: (theme) => theme.palette.common.black,
                    },
                  },
                },
              }}
              title={occasion}
              placement="bottom-start"
            >
              <span>{occasion}</span>
            </Tooltip>
          </span>
          <span className="text-Primary_grey text-[10px]">
            {formatHolidayDate(date).diff}
          </span>
        </div>
      </div>
    </div>
  );
}

export default HolidayCard;
