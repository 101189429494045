import React from "react";
import "./index.css";
import GreenArrow from "../../../.../../../assets/ArrowsIcons/arrow up Green.svg";
import RedArrrow from "../../../.../../../assets/ArrowsIcons/arrow down Red.svg";

const AnimationUpAndDown = ({
  loader,
  currentId,
  previousId,
  currentBalance,
  previousBalance,
  refreshType,
  allPrevData,
  classType,
}) => {
  return (
    <>
      {refreshType === "single" ? (
        <>
          <div
            className="arrow_red"
            style={{
              display:
                !loader &&
                currentId === previousId &&
                Number(currentBalance) < Number(previousBalance)
                  ? "block"
                  : "none",
              top:
                classType === "beneOwner"
                  ? "12%"
                  : classType === "owner_details"
                  ? "23%"
                  : classType === "RequiredApproval"
                  ? "-5%"
                  : classType === "MultiApproval"
                  ? "-10%"
                  : classType === "sideDrawer"
                  ? "0%"
                  : "12%",

              left:
                classType === "beneOwner"
                  ? "82%"
                  : classType === "owner_connected"
                  ? "82%"
                  : classType === "owner_details"
                  ? "23%"
                  : classType === "MultiApproval"
                  ? "18%"
                  : "72%",
            }}
          >
            <img src={RedArrrow} alt="red" />
          </div>
          <div
            className="arrow_green"
            style={{
              display:
                !loader &&
                currentId === previousId &&
                Number(currentBalance) > Number(previousBalance)
                  ? "block"
                  : "none",
              top:
                classType === "beneOwner"
                  ? "12%"
                  : classType === "owner_details"
                  ? "23%"
                  : classType === "RequiredApproval"
                  ? "-5%"
                  : classType === "MultiApproval"
                  ? "-5%"
                  : classType === "sideDrawer"
                  ? "-3%"
                  : "12%",
              left:
                classType === "beneOwner"
                  ? "82%"
                  : classType === "owner_connected"
                  ? "82%"
                  : classType === "owner_details"
                  ? "23%"
                  : classType === "MultiApproval"
                  ? "17%"
                  : "72%",
            }}
          >
            <img src={GreenArrow} alt="green" />
          </div>
        </>
      ) : (
        <>
          <div
            className="arrow_red"
            style={{
              display:
                !loader &&
                previousId &&
                Number(currentBalance) < Number(allPrevData)
                  ? "block"
                  : "none",
              top: classType === "beneOwner" ? "12%" : "12%",
              left: classType === "beneOwner" ? "82%" : "72%",
            }}
          >
            <img src={RedArrrow} alt="red" />
          </div>
          <div
            className="arrow_green"
            style={{
              display:
                !loader &&
                previousId &&
                Number(currentBalance) > Number(allPrevData)
                  ? "block"
                  : "none",
              top: classType === "beneOwner" ? "12%" : "12%",
              left: classType === "beneOwner" ? "82%" : "72%",
            }}
          >
            <img src={GreenArrow} alt="green" />
          </div>
        </>
      )}
    </>
  );
};

export default AnimationUpAndDown;
