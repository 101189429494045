import React, { useState, useEffect, useRef, useCallback } from "react";
import { InputAdornment, TextField, Button } from "@mui/material";
import "./index.css";
import AssociateInfo from "../../../assets/InfoIcons/info_filled.svg";
import { useSelector, useDispatch } from "react-redux";
import ViewDetails from "../../../assets/GeneralIcons/detail_page_edit detail.svg";
import Validation from "../../../utils/validation";
import { NumericFormat } from "react-number-format";
import AmountToWords from "../../_utils/AmountToWords";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import CloseIcon from "../../../assets/CloseIcons/close.svg";
import brower_file from "../../../../src/assets/FileIcons/browse_file.svg";
import arrow_up from "../../../assets/Beneficiary Icons/Increment.svg";
import arrow_down from "../../../assets/Beneficiary Icons/Decrement.svg";
import arrow_down_filled from "../../../assets/Beneficiary Icons/Decrement_disable.svg";
import DatePickerComponent from "../../_utils/Calendar";
import arrowforward from "../../../assets/ArrowsIcons/arrow_right_white – 1.svg";
import ActionDrpDown from "../../BeneTransaction/BeneAdditional";
import TimePicker from "../../BeneTransaction/BeneAdditionalTimepicker";
import BeneMultiDropDrown from "../../BeneTransaction/BeneMultiDropDown";
import BeneMonthYearSelect from "../../BeneTransaction/BeneMonthYearSelect";
import { formatAccountNumber } from "../../_utils/FormatAccountNumber";
import arrow_right_blue from "../../../assets/ArrowsIcons/arrow_right_blue.svg";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import RefreshBalanceToast from "../../_utils/RefreshBalanceToast";
import { checkUsertoken } from "../../../redux/features/login/login";
import {
  getCreateTransactionBalance,
  resetOwnerBalance,
} from "../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import WhiteReload from "../../../assets/RefreshIcons/reload-refresh_white.svg";
import BalanceLoader from "../../_utils/BalanceLoader";
import { Tooltip, tooltipClasses } from "@mui/material";
import FormatAmount from "../../_utils/FormatAmount/FormatAmount";
import CircleCancel from "../../../assets/CloseIcons/close_with_white_circle.svg";
import FileFormatMapper from "../../BeneTransaction/BeneTxnAmount/FileFormatMapper";
import { useNavigate } from "react-router-dom";
const InternalTxnAmount = (props) => {
  const dropdownRefs = useRef(null);
  const [showInvoiceInfo, setShowInvoiceInfo] = useState(false);
  const dispatch = useDispatch();

  const [dropdownOpen, setdropdownOpen] = useState(false);
  const [dropdowns, setDropdowns] = useState({});
  const { beneLookData } = useSelector((state) => state.verify);
  const [hour, setHour] = useState("HH");
  const [minute, setMinute] = useState("MM");
  const [meridiem, setMeridiem] = useState("AM");
  const [hourShow, setHourShow] = useState("HH");
  const [minuteShow, setMinuteShow] = useState("MM");
  const [meridiemShow, setMeridiemShow] = useState("AM");
  const [dateRangeEnabled, setDateRangeEnabled] = useState("");
  const [isIncMouseDown, setIsIncMouseDown] = useState(false);
  const [isDecMouseDown, setIsDecMouseDown] = useState(false);
  const [selectAmountType, setSelectAmountType] = useState("Custom amount");
  const [refreshId, setRefreshId] = useState("");
  const [reloadType, setReloadType] = useState("");
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const services = useSelector((state) => state.refreshToken.services);
  const navigate = useNavigate();

  const handleRatioChange = (e) => {
    if (
      (props?.txnPayLoad?.ownerBankBalance?.charAt(0) === "0" &&
        e.target.value === "Source amount") ||
      (props?.txnPayLoad?.destinationAccountBalance?.charAt(0) === "0" &&
        e.target.value === "destination amount")
    ) {
      toast.error("Balance is not available ", toast_position);
      return;
    }
    setSelectAmountType(e.target.value);

    if (e.target.value === "Source amount") {
      const amountWords = AmountToWords(props?.txnPayLoad?.ownerBankBalance);

      props.setTxnPayLoad((prevState) => ({
        ...prevState,
        amount: props?.txnPayLoad?.ownerBankBalance,
        amountWords: amountWords,
      }));

      props?.setShowRupeeSymbol(
        props?.txnPayLoad?.ownerBankBalance.trim() !== ""
      );
      props?.setAmountInWords(amountWords);
    } else if (e.target.value === "destination amount") {
      const amountWords = AmountToWords(
        Math.abs(props?.txnPayLoad?.destinationAccountBalance)
      );

      props.setTxnPayLoad((prevState) => ({
        ...prevState,
        amount: Math.abs(props?.txnPayLoad?.destinationAccountBalance),
        amountWords: amountWords,
      }));

      props?.setShowRupeeSymbol(
        props?.txnPayLoad?.destinationAccountBalance.trim() !== ""
      );
      props?.setAmountInWords(amountWords);
    }
  };

  const {
    createTransactionBankRefreshLoading,
    createTransactionBankRefreshError,
    createTransactionBankRefreshData,
  } = useSelector((state) => state.ownerAllVerification);

  const handleRefresh = (e, id, type) => {
    if (services?.includes("VAccountBalance") || services?.includes("Admin")) {
      e.stopPropagation();
      setRefreshId(id);
      setReloadType(type);
      dispatch(checkUsertoken());
      dispatch(getCreateTransactionBalance(id));
    } else {
      e.stopPropagation();
      navigate("/feature_not_assigned", {
        state: { unavailableService: "VAccountBalance,Admin" },
      });
    }
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    const formatted = parseFloat((bytes / Math.pow(k, i)).toFixed(decimals));
    return `${formatted} ${sizes[i]}`;
  }

  useEffect(() => {
    if (createTransactionBankRefreshLoading) {
      toast.dismiss();
      setTimeout(() => {
        RefreshBalanceToast();
      }, 200);
    } else if (createTransactionBankRefreshData?.status) {
      toast.dismiss();
      setTimeout(() => {
        toast.success("Balance refreshed successfully", toast_position);
      }, 500);
    } else if (
      createTransactionBankRefreshError &&
      !createTransactionBankRefreshLoading
    ) {
      toast.dismiss();
      setTimeout(() => {
        toast.error(createTransactionBankRefreshError, toast_position);
      }, 100);
    }
    if (createTransactionBankRefreshData) {
      if (reloadType === "owner") {
        props?.setTxnPayLoad({
          ...props?.txnPayLoad,
          ownerBankBalance:
            createTransactionBankRefreshData?.owner_accounts[0]
              ?.owner_account_balance,
          ownerLastUpdated:
            createTransactionBankRefreshData?.owner_accounts[0]
              ?.owner_account_balance_last_fetched_at_in_words,
        });
      } else {
        props?.setTxnPayLoad({
          ...props?.txnPayLoad,
          destinationAccountBalance:
            createTransactionBankRefreshData?.owner_accounts[0]
              ?.owner_account_balance,
          destinationAcountOwnerLastUpdated:
            createTransactionBankRefreshData?.owner_accounts[0]
              ?.owner_account_balance_last_fetched_at_in_words,
        });
      }
    }
    return () => {
      dispatch(resetOwnerBalance());
    };
  }, [
    createTransactionBankRefreshData,
    createTransactionBankRefreshLoading,
    createTransactionBankRefreshError,
  ]);

  const increment = (data_type, fieldname) => {
    props?.setQuantity(Number(props?.quantity) + 1);
    handleInputChange({
      target: {
        name: fieldname,
        value: Number(props?.quantity) + 1,
        data_typeChan: data_type,
      },
    });
  };
  const decrement = (data_type, fieldname) => {
    if (props?.quantity > 0) {
      props?.setQuantity(Number(props?.quantity) - 1);
      handleInputChange({
        target: {
          name: fieldname,
          value: Number(props?.quantity) - 1,
          data_typeChan: data_type,
        },
      });
    }
  };

  const formatDate = (date) => {
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })}, ${date.getFullYear()}`;
  };
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    props?.setStartDate(start);
    props?.setEndDate(end);
  };

  const handleDateChangeCal = (dates) => {
    props?.setStartDateCal(dates);
  };

  const handleCancel = (fieldname, data_type) => {
    handleInputChange({
      target: {
        name: fieldname,
        value: "",
        data_typeChan: data_type,
      },
    });
    props?.setDateRangeEnabled(false);
    props?.setShowDatePicker(false);
    props?.setStartDate(null);
    props?.setEndDate(null);
  };

  const handleConfirm = (fieldname, data_type) => {
    handleInputChange({
      target: {
        name: fieldname,
        value: {
          start_date: convertDate(props?.startDate),
          end_date: convertDate(props?.endDate),
        },
        data_typeChan: data_type,
      },
    });
    props?.setDateRangeEnabled(true);
    props?.setShowDatePicker(false);
  };

  const handleCancelCal = (fieldname, data_type) => {
    handleInputChange({
      target: {
        name: fieldname,
        value: "",
        data_typeChan: data_type,
      },
    });
    props?.setShowDatePickerCal(false);
    props?.setStartDateCal("");
    props?.setIsSingleDateSelected(false);
  };

  const handleConfirmCal = (fieldname, data_type) => {
    handleInputChange({
      target: {
        name: fieldname,
        value: formatDate(props?.startDateCal),
        data_typeChan: data_type,
      },
    });
    props?.setIsSingleDateSelected(true);
    props?.setShowDatePickerCal(false);
  };

  const isValidHttpUrl = (url) => {
    const httpRegex =
      /^(https?:\/\/)([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/.*)?$/;
    return httpRegex.test(url);
  };

  const allowedValue = (value) => {
    return Validation.allowedAmount(value);
  };

  const yearDropdownRef = useRef(null);
  const dateRef = useRef(null);
  const yearDropdownRefCal = useRef(null);

  const handleAmountChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setSelectAmountType("Custom amount");

    if (value[0] != 0) {
      const numericValue = value.replace(/[^0-9.]/g, "");

      if (name === "amount") {
        if (allowedValue(numericValue) || numericValue.length === 0) {
          const amountNumber = parseFloat(numericValue);
          const amountWords =
            numericValue.trim() === "" ? "" : AmountToWords(amountNumber);
          const adjustedAmountWords =
            amountNumber >= 1 && amountNumber <= 9
              ? amountWords.replace("rupees", "rupee")
              : amountWords;
          props.setTxnPayLoad((prevState) => ({
            ...prevState,
            amount: numericValue,
            amountWords: adjustedAmountWords,
          }));

          props?.setShowRupeeSymbol(numericValue.trim() !== "");
          props?.setAmountInWords(adjustedAmountWords);
        }
      }
    }
  };

  const handleInputChange = (e, data_type) => {
    if (data_type === "numeric" && e.target.value.length > 15) {
      return;
    }
    if (e.preventDefault) e.preventDefault();
    const { name, value, data_typeChan } = e.target || {
      name: e.name,
      value: e.value,
    };

    const field =
      props?.txnPayLoad?.selectedCategory?.configuration?.data?.find(
        (field) => field.name === name
      );

    let updatedData = { ...props.additionalData };

    const trimmedValue =
      data_typeChan === "date" ||
      data_typeChan === "year" ||
      data_typeChan === "toggle" ||
      data_typeChan === "time" ||
      data_typeChan === "quantity" ||
      data_typeChan === "from_year_to_year" ||
      data_typeChan === "month_year" ||
      data_typeChan === "date_range" ||
      data_typeChan === "multi_select_list" ||
      data_typeChan === "upload"
        ? value
        : value.replace(/^\s+/, "");

    if (field?.data_type === "numeric") {
      if (trimmedValue === "" || /^\d+$/.test(trimmedValue)) {
        updatedData[name] = trimmedValue;
        props.setAdditionalData(updatedData);
      }
    } else {
      updatedData[name] = trimmedValue;
      props.setAdditionalData(updatedData);
    }

    if (field?.data_type === "list") {
      setDropdowns((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          selectedOption: trimmedValue,
        },
      }));
    }

    if (name === "invoiceNumber" && trimmedValue.length > 0) {
      setShowInvoiceInfo(true);
    } else {
      setShowInvoiceInfo(false);
    }
  };

  const closeDropdowns = () => {
    setDropdowns((prev) => {
      const newDropdowns = Object.keys(prev).reduce((acc, key) => {
        acc[key] = { ...prev[key], isOpen: false };
        return acc;
      }, {});
      return newDropdowns;
    });
  };

  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const handleClickOutside2 = (event) => {
      if (
        dropdownRefs.current &&
        !dropdownRefs.current.contains(event.target)
      ) {
        let obj = {
          ...dropdowns,
          isOpen: false,
        };
        setDropdowns(obj);
      }
    };

    document.addEventListener("mousedown", handleClickOutside2);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dateRef.current && !dateRef.current.contains(event.target)) {
        props?.setShowDatePickerCal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleQuantityChange = (e, fieldname, data_type) => {
    if (e.target.value.length > 15 || e.target.value < 0) {
      return;
    }
    props?.setQuantity(e.target.value);

    handleInputChange({
      target: {
        name: fieldname,
        value: Number(e.target.value),
        data_typeChan: data_type,
      },
    });
  };

  const handleTimeConfirm = (fieldname, data_type) => {
    if (hourShow === "hh") {
      setHour("01");
    } else {
      setHour(hourShow);
    }

    if (minuteShow === "mm") {
      setMinute("00");
    } else {
      setMinute(minuteShow);
    }
    setMeridiem(meridiemShow);
    handleInputChange({
      target: {
        name: fieldname,
        value: `${hourShow === "hh" ? "01" : hourShow}:${
          minuteShow === "mm" ? "00" : minuteShow
        } ${
          meridiemShow === "AM" || meridiemShow === "am" ? "am" : meridiemShow
        } `,
        data_typeChan: data_type,
      },
    });
  };

  useEffect(() => {
    props.BenEnableNextButton(true);
  }, []);

  useEffect(() => {
    if (!props.additionalData) return;

    const storeData =
      props.txnPayLoad.selectedCategory?.configuration?.data?.map((val) => {
        const dataValue = props.additionalData[val?.name];

        if (dataValue !== undefined && dataValue !== "") {
          if (val?.data_type === "numeric") {
            const integerValue = parseInt(dataValue, 10);
            if (!isNaN(integerValue)) {
              return { ...val, value: integerValue };
            } else {
              return val;
            }
          } else {
            return { ...val, value: dataValue };
          }
        } else if (val?.data_type === "multi_select_list") {
          return { ...val, value: [] };
        } else if (val?.data_type === "toggle") {
          return { ...val, value: false };
        } else if (val?.data_type === "hyperlink") {
          return { ...val, value: "" };
        } else if (val?.data_type === "time") {
        } else if (val?.mandatory !== "Y") {
          return { ...val, value: "Not Given" };
        }

        return val;
      });

    props.setTxnPayLoad({
      ...props.txnPayLoad,
      mergeCategory: storeData,
    });

    closeDropdowns();
  }, [props.additionalData, props.txnPayLoad.selectedCategory]);

  let haveApproved = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "approved";
  });

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1975 + 1 },
    (_, i) => 1975 + i
  );

  const handleClick = (name) => {
    const { [name]: _, ...updatedData } = props?.additionalData;
    props?.setHyperLink("");
    props?.setAdditionalData(updatedData);
    props?.setTxnPayLoad((prevState) => ({
      ...prevState,
      mergeCategory: prevState.mergeCategory?.map((item) =>
        item.data_type === "hyperlink" ? { ...item, value: "" } : item
      ),
    }));
  };

  const handleClearTime = (name) => {
    const { [name]: _, ...updatedData } = props?.additionalData;
    props?.setAdditionalData(updatedData);
    props?.setTxnPayLoad((prevState) => ({
      ...prevState,
      mergeCategory: prevState.mergeCategory?.map((item) =>
        item.data_type === "time" ? { ...item, value: "" } : item
      ),
    }));
    setHourShow("HH");
    setMinuteShow("MM");
    setMeridiemShow("AM");
    setHour("HH");
    setMinute("MM");
    setMeridiem("AM");
  };

  const handleToggle = (fieldName, data_type, value, name) => {
    let newStates = value || false;
    newStates = !newStates;

    handleInputChange({
      target: {
        name: fieldName,
        value: newStates,
        data_typeChan: data_type,
      },
    });
    if (newStates === false) {
      const { [name]: _, ...updatedData } = props?.additionalData;
      props?.setAdditionalData(updatedData);
    }
  };

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault(); // Prevent default behavior to allow drop
  };

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const [files, setFiles] = useState([]);
  const isTimeSelectedShow = hourShow !== "HH" && minuteShow !== "MM";

  const renderInputFields = () => {
    if (
      !props?.txnPayLoad?.selectedCategory ||
      !props?.txnPayLoad?.selectedCategory?.configuration ||
      !props?.txnPayLoad?.selectedCategory?.configuration.data ||
      props?.txnPayLoad?.selectedCategory?.configuration?.data?.length === 0
    ) {
      return (
        <div className="text-left text-white text-base antialiased">
          Additional details are not supported for the selected category.
        </div>
      );
    }

    return props.txnPayLoad?.mergeCategory?.map((field, index) => {
      const isFieldRequired = field.mandatory === "Y";
      const optionalLabel = isFieldRequired ? "" : " (optional)";
      const fieldValue = props.additionalData[field.name] || "";
      const isMultiline = fieldValue.length > 30;
      switch (field.data_type) {
        case "string":
        case "numeric":
          return (
            <TextField
              key={index}
              className="w-full antialiased text-xl"
              size="small"
              variant="outlined"
              label={`${field.name}${optionalLabel}`}
              name={field.name}
              autoComplete="off"
              value={fieldValue}
              onChange={(e) => handleInputChange(e, field?.data_type)}
              required={isFieldRequired}
              multiline
              minRows={1}
              maxRows={10}
              sx={{
                "& fieldset": {
                  borderRadius: "10px !important",
                  borderColor: fieldValue
                    ? "#4DDD37 !important"
                    : "#dadada !important",
                  borderWidth: "2px",
                },
                "& label": {
                  color: fieldValue
                    ? "#00FF00 !important"
                    : "#dadada !important",
                  background: "#314C7F",
                  padding: "0px",
                  fontSize: "16px !important",
                  textTransform: "capitalize !important",
                },
                "& input": {
                  color: "#f9f9f9 !important",
                },
                "& label.Mui-focused": {
                  color: "#4DDD37 !important",
                  fontSize: "12px !important",
                  fontWeight: "400 !important",
                  lineHeight: "16.34px !important",
                  borderRadius: "10px !important",
                },
                "& .MuiInputBase-input": {
                  color: "#dadada !important",
                  borderRadius: "10px !important",
                },
                "& .MuiInputBase-inputMultiline": {
                  color: "#f9f9f9 !important",
                },
                "& .Mui-focused": {
                  "& fieldset": {
                    borderColor: "#4DDD37 !important",
                    fontSize: "12px !important",
                  },
                },
                borderRadius: "10px",
              }}
            />
          );
        case "list":
          return (
            <ActionDrpDown
              ddlValue={field?.data}
              mandatory={field?.mandatory}
              optionalLabel={optionalLabel}
              isFieldRequired={isFieldRequired}
              fieldname={field?.name}
              handleInputChange={handleInputChange}
              data_type={field?.data_type}
              setShowDatePicker={props?.setShowDatePicker}
              value={field?.value || "Select"}
            />
          );

        case "hyperlink":
          return (
            <article hyperlink className="mt-[10px]">
              <label
                id="hyperlinkLBl"
                htmlFor="hyperLinkid"
                style={{
                  color: isValidHttpUrl(props?.hyperLink)
                    ? "#F9F9F9"
                    : "#F9F9F9",
                }}
              >
                {`${field.name === "Hyperlink" ? "URL" : field.name}${
                  isFieldRequired ? "*" : ""
                }${optionalLabel}`}
              </label>
              <div
                className="relative"
                style={{
                  backgroundColor: "#667CA3",
                  borderRadius: "10px",
                  marginTop: "4px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="text"
                  value={props?.hyperLink}
                  onChange={(e) => {
                    props?.setHyperLink(e.target.value);
                    if (isValidHttpUrl(e.target.value)) {
                      handleInputChange({
                        target: {
                          name: field?.name,
                          value: e.target.value,
                          data_typeChan: field?.data_type,
                        },
                      });
                    }
                  }}
                  className="input_Box_url truncate"
                  placeholder="Enter URL"
                  spellCheck={false}
                  style={{
                    width: isMobileView ? "86%" : "360px",
                    border: "none",
                    color: "#F9F9F9",
                  }}
                  id="hyperLinkid"
                  autoComplete="off"
                />
                <div className="absolute right-[40px] border-l-[2px] border-[#304b7d] h-[20px]"></div>
                <button
                  className="absolute right-[15px] h-[14px] pl-[5px] cursor-default"
                  disabled={props?.hyperLink.length <= 0}
                >
                  <img
                    src={CloseIcon}
                    alt=""
                    className="w-[14px] h-[14px] ml-[3px]"
                    onClick={() => handleClick(field?.name)}
                    style={{
                      opacity: props?.hyperLink?.length <= 0 ? "0.4" : "1",
                      cursor:
                        props?.hyperLink?.length <= 0 ? "default" : "pointer",
                    }}
                  />
                </button>
              </div>
            </article>
          );
        case "time":
          return (
            <div className="w-[100%] mt-[5px]">
              <h1
                className="font-normal text-[12px] capitalize"
                id="uploadId"
                style={{
                  color: field?.value ? "#f9f9f9" : "#f9f9f9",
                  textTransform: "capitalize",
                }}
              >
                {`${field.name}${isFieldRequired ? "*" : ""}${optionalLabel}`}
              </h1>
              <TimePicker
                handleTimeConfirm={handleTimeConfirm}
                hourShow={hourShow}
                minuteShow={minuteShow}
                meridiemShow={meridiemShow}
                setHourShow={setHourShow}
                setMinuteShow={setMinuteShow}
                setMeridiemShow={setMeridiemShow}
                setHour={setHour}
                setMinute={setMinute}
                setMeridiem={setMeridiem}
                isTimeSelected={isTimeSelectedShow}
                dropdownOpen={dropdownOpen}
                setdropdownOpen={setdropdownOpen}
                data_type={field?.data_type}
                fieldname={field.name}
                setShowDatePicker={props?.setShowDatePicker}
                value={field?.value || "Set time"}
                handleClearTime={() => handleClearTime(field?.name)}
                setShowDatePickerCal={props?.setShowDatePickerCal}
              />
            </div>
          );
        case "day":
          return (
            <ActionDrpDown
              ddlValue={days}
              mandatory={field?.mandatory}
              optionalLabel={optionalLabel}
              isFieldRequired={isFieldRequired}
              fieldname={field.name}
              handleInputChange={handleInputChange}
              data_type={field?.data_type}
              setShowDatePicker={props?.setShowDatePicker}
              value={field?.value || "Select"}
            />
          );
        case "toggle":
          return (
            <article className="mt-[10px]">
              <h3
                className="capitalize font-normal text-[12px]"
                id="uploadId capitalize"
                style={{
                  textTransform: "capitalize !important",
                  marginBottom: "5px",
                  color:
                    (field?.value || false) == false ? "#f9f9f9" : "#f9f9f9",
                }}
              >
                {`${field.name}${isFieldRequired ? "*" : ""}${optionalLabel}`}
              </h3>
              <div
                className={`switch-section ${
                  field?.value || false ? "" : "darker"
                }`}
                style={{ width: "100%" }}
              >
                <p>
                  <h4
                    className={`${
                      field?.value || false ? "toggletruetxt" : "darkTxt"
                    } text-[16px] capitalize`}
                  >
                    {field?.name}
                  </h4>
                  <h5
                    className={`text-[#f9f9f9] ${
                      field?.value || false ? "" : ""
                    }`}
                  >
                    {field?.description}
                  </h5>
                </p>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={field?.value || false}
                    onClick={() =>
                      handleToggle(
                        field?.name,
                        field?.data_type,
                        field?.value,
                        field?.name
                      )
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </article>
          );
        case "month":
          return (
            <ActionDrpDown
              ddlValue={months}
              mandatory={field?.mandatory}
              optionalLabel={optionalLabel}
              isFieldRequired={isFieldRequired}
              fieldname={field.name}
              handleInputChange={handleInputChange}
              data_type={field?.data_type}
              setShowDatePicker={props?.setShowDatePicker}
              value={field?.value || "Select"}
            />
          );
        case "upload":
          return (
            <article className="mt-[10px]">
              <label
                id="uploadId"
                style={{
                  color: props?.files?.length === 0 ? "#f9f9f9" : "#f9f9f9",
                }}
              >
                {`${field.name === "File" ? "Upload File" : field.name}${
                  isFieldRequired ? "*" : ""
                }${optionalLabel}`}
              </label>
              <article
                className="flex gap-[15px] items-center main"
                onDrop={(event) => {
                  event.preventDefault();
                  const selectedFiles = event.dataTransfer.files[0];
                  const maxSize = 5 * 1024 * 1024;
                  if (selectedFiles.size < maxSize) {
                    props?.setFiles(selectedFiles);
                    if (selectedFiles) {
                      const reader = new FileReader();
                      reader.onload = () => {
                        props?.setShowImages(reader.result);
                      };
                      reader.readAsDataURL(selectedFiles);
                    }
                    handleInputChange({
                      target: {
                        name: field?.name,
                        value: event?.dataTransfer?.files[0],
                        data_typeChan: field?.data_type,
                      },
                    });
                  } else {
                    toast.error("Maximum file size is 5mb", toast_position);
                  }
                }}
                onDragOver={handleDragOver}
                style={{
                  border: "2px dashed #fff",
                  borderRadius: "5px",
                  padding: isMobileView ? "10px" : "10px 20px",
                  margin: "5px 0 0px 0px",
                  position: "relative",
                }}
              >
                <img
                  src={
                    props?.files?.type?.split("/").pop().toUpperCase() ===
                      "PNG" ||
                    props?.files?.type?.split("/").pop().toUpperCase() ===
                      "BMP" ||
                    props?.files?.type?.split("/").pop().toUpperCase() ===
                      "JPEG" ||
                    props?.files?.type?.split("/").pop().toUpperCase() === "JPG"
                      ? props?.showImages
                      : props?.files?.length === 0
                      ? brower_file
                      : FileFormatMapper(
                          props?.files?.type?.split("/").pop().toUpperCase()
                        )
                  }
                  alt="file"
                  style={{ width: "50px", height: "50px" }}
                />
                {props?.files?.length === 0 ? (
                  <div className="flex flex-col gap-[10px]">
                    <label
                      htmlFor="file-input"
                      style={{
                        color: "#F9F9F9",
                        display: "block",
                        fontSize: "14px",
                      }}
                    >
                      Drag and Drop your file here OR{" "}
                      <span
                        style={{
                          color: "#FF7F33",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Browse file
                      </span>
                    </label>
                    <span className="text-[#f9f9f9] font-normal text-[12px]">
                      File size limit : Up to 12 MB
                    </span>
                  </div>
                ) : (
                  <div style={{ color: "#FF7F33" }} className="top_div_upload">
                    <div className="upload_img_summary">
                      <p className="upload_img_summary_p1 w-[200px] truncate">
                        {props?.files?.name}
                      </p>
                      <p className="upload_img_summary_p2">
                        {" "}
                        Uploaded file size : {formatBytes(props?.files?.size)}
                      </p>
                    </div>
                    <img
                      src={CircleCancel}
                      className="upload_cancel"
                      alt="cancel"
                      onClick={() => {
                        props?.setFiles([]);
                        props?.setShowImages("");
                        const { [field?.name]: _, ...updatedData } =
                          props?.additionalData;
                        props?.setAdditionalData(updatedData);
                        props?.setTxnPayLoad((prevState) => ({
                          ...prevState,
                          mergeCategory: prevState.mergeCategory?.map((item) =>
                            item.data_type === "upload"
                              ? { ...item, value: "" }
                              : item
                          ),
                        }));
                      }}
                    />
                  </div>
                )}
                <input
                  type="file"
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  onChange={(e) => {
                    const selectedFiles = e.target.files[0];
                    const maxSize = 5 * 1024 * 1024;
                    if (selectedFiles.size < maxSize) {
                      props?.setFiles(selectedFiles);
                      if (selectedFiles) {
                        const reader = new FileReader();
                        reader.onload = () => {
                          props?.setShowImages(reader.result);
                        };
                        reader.readAsDataURL(selectedFiles);
                      }
                      handleInputChange({
                        target: {
                          name: field?.name,
                          value: e.target.files[0],
                          data_typeChan: field?.data_type,
                        },
                      });
                    } else {
                      toast.error("Maximum file size is 5mb", toast_position);
                    }
                  }}
                  style={{ display: "none" }}
                  id="file-input"
                />
              </article>
            </article>
          );
        case "date":
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                marginTop: "10px",
              }}
            >
              <span
                id="uploadId"
                style={{
                  color: props?.isSingleDateSelected ? "#F9F9F9" : "#F9F9F9",
                }}
              >
                {`${field.name}${isFieldRequired ? "*" : ""}${optionalLabel}`}
              </span>
              <div
                className="bene_acc_Calendar_main_div relative flex flex-col cursor-pointer"
                style={{
                  // width: "305px",
                  height: props?.showDatePickerCal ? "" : "42px",
                  border: props?.showDatePickerCal
                    ? "1px solid #ff5860"
                    : "none",
                }}
              >
                <div
                  className="flex flex-row items-center justify-between rounded-[10px] cursor-pointer"
                  style={{
                    width: "388px",
                    height: props?.showDatePickerCal ? "35px" : "42px",
                  }}
                  onClick={(e) => {
                    props?.setShowDatePickerCal((prevState) => !prevState);
                    setdropdownOpen(false);
                    e.stopPropagation();
                  }}
                >
                  <div
                    style={{
                      width: "400px !important",
                      height: "42px !important",
                    }}
                    className="interAcc_date_heading_div cursor-pointer"
                  >
                    <div
                      // ref={dateRef}
                      style={{
                        color:
                          props?.startDateCal || "" ? "#f9f9f9" : "#dadada",
                      }}
                      className="internal_date_heading_leftm_txn"
                    >
                      {" "}
                      {props?.startDateCal
                        ? formatDate(props?.startDateCal)
                        : "Select date"}
                    </div>
                  </div>
                </div>
                {props?.showDatePickerCal && (
                  <DatePickerComponent
                    startDate={props?.startDateCal}
                    handleDateChange={handleDateChangeCal}
                    handleCancel={() =>
                      handleCancelCal(field?.name, field?.data_type)
                    }
                    handleConfirm={() =>
                      handleConfirmCal(field?.name, field?.data_type)
                    }
                    showDatePicker={props?.showDatePickerCal}
                    yearDropdownRef={yearDropdownRefCal}
                    setShowDatePicker={props?.setShowDatePickerCal}
                    type={"single"}
                  />
                )}
              </div>
            </div>
          );
        case "multi_select_list":
          return (
            <ActionDrpDown
              type={"checkbox"}
              ddlValue={field?.data}
              mandatory={field?.mandatory}
              optionalLabel={optionalLabel}
              isFieldRequired={isFieldRequired}
              fieldname={field?.name}
              handleInputChange={handleInputChange}
              data_type={field?.data_type}
              setShowDatePicker={props?.setShowDatePicker}
              value={field?.value || "Select"}
            />
          );
        case "year":
          return (
            <ActionDrpDown
              ddlValue={years}
              mandatory={field?.mandatory}
              optionalLabel={optionalLabel}
              isFieldRequired={isFieldRequired}
              handleInputChange={handleInputChange}
              data_type={field?.data_type}
              fieldname={field?.name}
              setShowDatePicker={props?.setShowDatePicker}
              value={field?.value || "Select"}
            />
          );

        case "quantity":
          return (
            <div className="w-[100%] mt-[10px]">
              <div
                id="uploadId"
                style={{ color: props?.quantity <= 0 ? "#f9f9f9" : "#f9f9f9" }}
              >
                {" "}
                {`${field.name}${isFieldRequired ? "*" : ""}${optionalLabel}`}
              </div>
              <div className="relative mt-[5px] flex w-[100%] rounded-[10px] bg-[#667CA3] flex-row justify-between h-[40px]">
                <div
                  className="decrementBtn flex justify-start w-[221px] pl-[10px]"
                  onClick={() => {
                    if (props?.quantity !== "0" && props?.quantity !== 0) {
                      decrement(field?.data_type, field.name);
                    }
                  }}
                  onMouseDown={() => setIsDecMouseDown(true)}
                  onMouseUp={() => setIsDecMouseDown(false)}
                >
                  <img
                    src={props?.quantity === 0 ? arrow_down_filled : arrow_down}
                    alt="arrow-down"
                    className={`w-[20px] ${
                      props?.quantity === "0" ||
                      props?.quantity === 0 ||
                      props?.quantity === ""
                        ? "opacity-40 cursor-default"
                        : "cursor-pointer"
                    }`}
                  />
                </div>
                <div className="text-[#f9f9f9] flex justify-center text-center">
                  <input
                    type="number"
                    className="quantityInput w-[100%]"
                    value={props?.quantity}
                    placeholder="0"
                    min="0"
                    step="1"
                    onChange={(e) =>
                      handleQuantityChange(e, field?.name, field?.data_type)
                    }
                  />
                </div>
                <div
                  className="flex incrementBtn justify-end pr-[10px]"
                  // style={{
                  //   boxShadow: isIncMouseDown ? "2px 2px #868686 inset" : "",
                  // }}
                  onClick={() => increment(field?.data_type, field.name)}
                  onMouseDown={() => setIsIncMouseDown(true)}
                  onMouseUp={() => setIsIncMouseDown(false)}
                >
                  <img
                    src={arrow_up}
                    alt="arrow-up"
                    className="w-[20px] cursor-pointer"
                  />
                </div>
              </div>
            </div>
          );

        case "from_year_to_year":
          return (
            <div
              style={{
                display: "flex",
                flexDirection: isMobileView ? "column" : "row",
                gap: "20px",
              }}
            >
              <BeneMultiDropDrown
                title={`From Year`}
                ddlValue={years}
                mandatory={field?.mandatory}
                optionalLabel={optionalLabel}
                isFieldRequired={isFieldRequired}
                handleInputChange={handleInputChange}
                data_type={field?.data_type}
                fieldname={field?.name}
                selectedValue={props?.selectedValue}
                setSelectedValue={props?.setSelectedValue}
                setShowDatePicker={props?.setShowDatePicker}
                setAdditionalData={props.setAdditionalData}
                setTxnPayLoad={props.setTxnPayLoad}
                additionalData={props.additionalData}
              />
              <BeneMultiDropDrown
                title={`To Year`}
                ddlValue={years}
                mandatory={field?.mandatory}
                optionalLabel={optionalLabel}
                isFieldRequired={isFieldRequired}
                handleInputChange={handleInputChange}
                data_type={field?.data_type}
                fieldname={field?.name}
                selectedValue={props?.selectedValue}
                setSelectedValue={props?.setSelectedValue}
                setShowDatePicker={props?.setShowDatePicker}
                setAdditionalData={props.setAdditionalData}
                setTxnPayLoad={props.setTxnPayLoad}
                additionalData={props.additionalData}
              />
            </div>
          );

        case "month_year":
          return (
            <div
              style={{
                display: "flex",
                flexDirection: isMobileView ? "column" : "row",
                gap: "20px",
                marginTop: "10px",
              }}
            >
              <BeneMonthYearSelect
                title={`Month`}
                ddlValue={months}
                mandatory={field?.mandatory}
                optionalLabel={optionalLabel}
                isFieldRequired={isFieldRequired}
                handleInputChange={handleInputChange}
                data_type={field?.data_type}
                fieldname={field?.name}
                selectedValue={props?.selectedValueMonth}
                setSelectedValue={props?.setSelectedValueMonth}
                setShowDatePicker={props?.setShowDatePicker}
              />
              <BeneMonthYearSelect
                title={`Year`}
                ddlValue={years}
                mandatory={field?.mandatory}
                optionalLabel={optionalLabel}
                isFieldRequired={isFieldRequired}
                handleInputChange={handleInputChange}
                data_type={field?.data_type}
                fieldname={field?.name}
                selectedValue={props?.selectedValueMonth}
                setSelectedValue={props?.setSelectedValueMonth}
                setShowDatePicker={props?.setShowDatePicker}
              />
            </div>
          );

        case "date_range":
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <span
                id="uploadId"
                style={{
                  color: props?.dateRangeEnabled ? "#f9f9f9" : "#f9f9f9",
                }}
              >
                {`${field.name}${isFieldRequired ? "*" : ""}${optionalLabel}`}
              </span>
              <div
                className="bene_acc_Calendar_main_div relative flex flex-col items-center cursor-pointer"
                style={{
                  // width: "305px",
                  height: props?.showDatePicker ? "" : "35px",
                  border: props?.showDatePicker ? "1px solid #ff5860" : "none",
                }}
              >
                <div
                  className="flex flex-row items-center justify-between rounded-[10px] bg-[#667CA3] cursor-pointer"
                  style={{
                    width: "100%",
                    height: props?.showDatePicker ? "" : "35px",

                    // borderRadius: showDatePicker ? "10px" : "auto",
                  }}
                  onClick={(e) => {
                    props?.setShowDatePicker((prevState) => !prevState);
                    setdropdownOpen(false);
                    e.stopPropagation();
                  }}
                  // style={{ opacity: dropdownOpen ? 0 : 1 }}
                >
                  <div
                    className="beneAcc_date_heading_div cursor-pointer"
                    style={{
                      width: "400px !important",
                      borderTopLeftRadius: props?.showDatePicker
                        ? "7px"
                        : "none",
                      borderLeft: props?.showDatePicker
                        ? "1px solid #ff5860"
                        : "none",
                    }}
                    // onClick={() => setShowDatePicker((prev) => !prev)}
                  >
                    <div
                      className="beneAcc_date_heading_leftm_txn"
                      style={{
                        color: props?.startDate || "" ? "#f9f9f9" : "#dadada",
                        margin: isMobileView ? "auto" : "0px 20px 0px 10px",
                        fontSize: isMobileView ? "14px" : "16px",
                      }}
                    >
                      {" "}
                      {props?.startDate
                        ? formatDate(props?.startDate)
                        : "Select from date"}
                    </div>
                  </div>
                  <img
                    src={arrowforward}
                    className="bene_acc_viewall_calendar_icon"
                  />
                  <div
                    className="beneAcc_date_select_div cursor-pointer"
                    style={{
                      borderTopRightRadius: props?.showDatePicker
                        ? "7px"
                        : "none",
                      borderRight: props?.showDatePicker
                        ? "1px solid #ff5860"
                        : "none",
                    }}
                  >
                    <div
                      className="beneAcc_date_heading_rightm_txn"
                      style={{
                        color: props?.endDate || "" ? "#f9f9f9" : "#dadada",
                        margin: isMobileView ? "auto" : "0px 7px 0px 47px",
                        fontSize: isMobileView ? "14px" : "16px",
                      }}
                    >
                      {props?.endDate
                        ? formatDate(props?.endDate)
                        : "Select to date"}
                    </div>
                  </div>
                </div>
                {props?.showDatePicker && (
                  <DatePickerComponent
                    startDate={props?.startDate}
                    endDate={props?.endDate}
                    handleDateChange={handleDateChange}
                    handleCancel={() =>
                      handleCancel(field?.name, field?.data_type)
                    }
                    handleConfirm={() =>
                      handleConfirm(field?.name, field?.data_type)
                    }
                    showDatePicker={props?.showDatePicker}
                    yearDropdownRef={yearDropdownRef}
                    setShowDatePicker={props?.setShowDatePicker}
                    isOpen={props?.showDatePicker}
                  />
                )}
              </div>
            </div>
          );
        default:
          return null;
      }
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 800);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      {!isMobileView && (
        <div
          className={`beneContact_Main_internal ${
            props?.direction === "next"
              ? "container-righttoleft-animate-intrtxn"
              : props?.direction === "previous"
              ? "container-lefttoright-animate-intrtxn"
              : ""
          }`}
        >
          <div
            className="divideAmount_internal"
            style={{ marginRight: "10px" }}
          >
            <div className="w-1/2 h-fit contactright second_side_div">
              <div
                className="bg-[#29487E] flex flex-col relative rounded-[20px]"
                style={{ width: "inherit", height: "inherit" }}
              >
                <div className=" px-[20px] py-5 h-full  flex flex-col rounded-[20px] bg-[#3A5686] ">
                  <div
                    className="w-full flex flex-col"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className=" items-center justify-between"
                      style={{ width: "fit-content" }}
                    >
                      <div className="text-[#DADADA] font-semibold text-base">
                        {"Source Account Details"}
                      </div>
                      <div className="w-[432px]  h-[59px] bg-[#314D7E] my-[16px] flex rounded-[10px] px-[20px] py-[10px]">
                        <div className=" w-1/2 border-r h-[39px] border-[#031F4F]">
                          <p className="w-[164px] font-sans text-[#f9f9f9] text-[14px] font-semibold">
                            {formatAccountNumber(
                              props?.txnPayLoad?.ownerBankAccountNo
                            )}
                          </p>
                          <p className=" font-sans text-[#dadada] text-[12px] font-normal">
                            Account Number
                          </p>
                        </div>
                        <div className=" pl-[20px]">
                          <div className="source-account-latest-balance">
                            <p
                              className="font-sans  text-[14px] font-semibold owner-Fetch-Balance"
                              style={{
                                color:
                                  Number(props?.txnPayLoad?.ownerBankBalance) >=
                                  0
                                    ? "#4DDD37"
                                    : "#FF5860",
                              }}
                            >
                              ₹{" "}
                              {
                                <FormatAmount
                                  price={props?.txnPayLoad?.ownerBankBalance}
                                />
                              }
                            </p>
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title="Refresh Balance"
                            >
                              <button
                                onClick={(e) =>
                                  handleRefresh(
                                    e,
                                    props?.txnPayLoad?.ownerId,
                                    "owner"
                                  )
                                }
                                className={`internal_amount_button ${
                                  services?.includes("VAccountBalance") ||
                                  services?.includes("Admin")
                                    ? "cursor-pointer"
                                    : "cursor-not-allowed opacity-50"
                                }`}
                              >
                                {createTransactionBankRefreshLoading &&
                                refreshId === props?.txnPayLoad?.ownerId ? (
                                  <BalanceLoader
                                    loader={createTransactionBankRefreshLoading}
                                    type={"internalTxn"}
                                  />
                                ) : !createTransactionBankRefreshLoading &&
                                  refreshId === props?.txnPayLoad?.ownerId ? (
                                  <BalanceLoader
                                    loader={createTransactionBankRefreshLoading}
                                    setAccId={setRefreshId}
                                    type={"internalTxn"}
                                  />
                                ) : (
                                  <>
                                    {" "}
                                    <img
                                      className="internal_reload"
                                      src={WhiteReload}
                                      alt="Img"
                                    />
                                  </>
                                )}
                              </button>
                            </Tooltip>
                          </div>

                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={props?.txnPayLoad?.ownerLastUpdated}
                          >
                            <p className="internal_new_balance">
                              Balance - {props?.txnPayLoad?.ownerLastUpdated}
                            </p>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="Inter_settle_main_div_container_img_2"
                style={{
                  display:
                    props?.txnPayLoad?.ownerBankAccountNo === null
                      ? "none"
                      : "flex",
                }}
              >
                <img
                  src={require("../../../assets/IfscLogo/" +
                    IfscCodeMapper(props?.txnPayLoad?.ownerBankIFSC))}
                  alt="bank"
                />
              </div>
            </div>
            {props?.isRtgs && (
              <div className="internal_isRtgs_seleted bg-Highlight_orange">
                <p className=" font-open-sans text-Primary_white text-Font_14  font-normal font-sans ">
                  Note - The transaction amount must be at least 2 lakhs since
                  you have chosen RTGS as the payment method.
                </p>
              </div>
            )}
            <div className="w-1/2 h-fit contactright">
              <div className="internal_amount_div">
                <div className="internal_amount_div_child">
                  <div className="internal_amount_div_child_top">
                    <NumericFormat
                      name="amount"
                      customInput={TextField}
                      thousandSeparator={true}
                      thousandsGroupStyle="lakh"
                      onChange={handleAmountChange}
                      value={props.txnPayLoad.amount}
                      decimalScale={2}
                      isAllowed={(values) => {
                        if (!values.value) return true;
                        const { floatValue, value } = values;
                        if (value[0] == 0) {
                          return false;
                        }
                        return allowedValue(String(floatValue));
                      }}
                      inputProps={{
                        placeholder: "Enter amount",
                        style: {
                          padding: "4px",
                          fontSize: "24px",
                          outline: "none",
                          font: "normal normal normal 24px/43px Open Sans",
                          color: "#FFFFFF",
                        },
                        className: "text-center ",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: props?.showRupeeSymbol && (
                          <InputAdornment position="start">
                            <span
                              style={{
                                fontSize: "24px",
                                color: "#ffffff",
                                marginLeft: "-10px",
                              }}
                            >
                              &#8377;
                            </span>
                          </InputAdornment>
                        ),
                        style: {
                          outline: "none",
                          boxShadow: "none",
                          borderBottom: "2px solid #DADADA",
                          borderRadius: "0px",
                        },
                      }}
                      sx={{
                        border: "none",
                        outline: "none",
                        mx: "auto",
                        width: "100%",
                        "& input::placeholder": {
                          fontFamily: "Open Sans !important",
                          fontSize: "24px !important",
                          fontWeight: "600 !important",
                          lineHeight: "24px !important",
                          textAlign: "center !important",
                          color: "#F9F9F9 !important",
                          opacity: "1",
                        },
                        "& input": {
                          height: "auto",
                          color: "white",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                      }}
                    />
                    {props?.amountInWords && (
                      <>
                        <div className="capitalize internal_amount_word">
                          {props?.amountInWords}
                          <br />
                        </div>
                        <span className="text-[#DADADA] text-xs mt-[5px]">
                          In words
                        </span>
                      </>
                    )}
                  </div>
                  <div
                    className="internal_amount_div_child_bottom"
                    style={{
                      display:
                        (Number(props?.txnPayLoad?.ownerBankBalance) == 0 &&
                          Number(
                            props?.txnPayLoad?.destinationAccountBalance
                          ) == 0) ||
                        (Number(props?.txnPayLoad?.ownerBankBalance) <= 0 &&
                          Number(props?.txnPayLoad?.destinationAccountBalance) >
                            0) ||
                        Number(props?.txnPayLoad?.ownerBankBalance) == 0 ||
                        props?.txnPayLoad?.ownerBankBalance === "xxxx.xx"
                          ? "none"
                          : "flex",
                    }}
                  >
                    <div className="internal_amount_div_child_bottom_first">
                      <label
                        htmlFor="radio-button"
                        className="internal_radio_1"
                      >
                        Custom amount
                      </label>
                      <input
                        type="radio"
                        id="radio-button"
                        value={"Custom amount"}
                        checked={selectAmountType === "Custom amount"}
                        onClick={handleRatioChange}
                        className="internal_circle_radio"
                        style={{
                          transform: "scale(1.2)",
                          cursor: "pointer",
                        }}
                      />
                    </div>

                    <h4 className="internal_amount_div_child_bottom_para">
                      Or select below to autofill the suggested amount
                    </h4>
                    {Number(props?.txnPayLoad?.ownerBankBalance) > 0 &&
                    Number(props?.txnPayLoad?.destinationAccountBalance) >=
                      0 ? (
                      <div className="internal_amount_div_child_bottom_first">
                        <label
                          htmlFor="radio-button_2"
                          className="internal_radio_1"
                        >
                          <div className="internal_radio_amount">
                            <h4>
                              {" "}
                              ₹{" "}
                              {
                                <FormatAmount
                                  price={props?.txnPayLoad?.ownerBankBalance}
                                />
                              }
                            </h4>
                            <h6>Autofill source balance</h6>
                          </div>
                        </label>
                        <input
                          type="radio"
                          id="radio-button_2"
                          value={"Source amount"}
                          checked={selectAmountType === "Source amount"}
                          onClick={handleRatioChange}
                          className="internal_circle_radio"
                          style={{
                            transform: "scale(1.2)",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ) : Number(props?.txnPayLoad?.ownerBankBalance) > 0 &&
                      Number(props?.txnPayLoad?.destinationAccountBalance) <
                        0 ? (
                      <div className="internal_amount_div_child_bottom_first_part">
                        <div className="internal_amount_div_child_bottom_first_part_1">
                          <label
                            htmlFor="radio-button_2"
                            className="internal_radio_1"
                          >
                            <div className="internal_radio_amount">
                              <h4>
                                {" "}
                                ₹{" "}
                                {
                                  <FormatAmount
                                    price={props?.txnPayLoad?.ownerBankBalance}
                                  />
                                }
                              </h4>
                              <h6>Autofill source balance</h6>
                            </div>
                          </label>
                          <input
                            type="radio"
                            id="radio-button_2"
                            value={"Source amount"}
                            checked={selectAmountType === "Source amount"}
                            onClick={handleRatioChange}
                            className="internal_circle_radio"
                            style={{
                              transform: "scale(1.2)",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                        <div className="internal_amount_div_child_bottom_first_part_1">
                          <label
                            htmlFor="radio-button_2"
                            className="internal_radio_1"
                          >
                            <div className="internal_radio_amount">
                              <h4>
                                {" "}
                                ₹{" "}
                                {
                                  <FormatAmount
                                    price={Math.abs(
                                      props?.txnPayLoad
                                        ?.destinationAccountBalance
                                    )}
                                  />
                                }
                              </h4>
                              <h6>Autofill destination balance</h6>
                            </div>
                          </label>
                          <input
                            type="radio"
                            id="radio-button_2"
                            value={"destination amount"}
                            checked={selectAmountType === "destination amount"}
                            onClick={handleRatioChange}
                            className="internal_circle_radio"
                            style={{
                              transform: "scale(1.2)",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    ) : Number(props?.txnPayLoad?.ownerBankBalance) < 0 &&
                      Number(props?.txnPayLoad?.destinationAccountBalance) <
                        0 ? (
                      <div className="internal_amount_div_child_bottom_first">
                        <label
                          htmlFor="radio-button_2"
                          className="internal_radio_1"
                        >
                          <div className="internal_radio_amount">
                            <h4>
                              {" "}
                              ₹{" "}
                              {
                                <FormatAmount
                                  price={Math.abs(
                                    props?.txnPayLoad?.destinationAccountBalance
                                  )}
                                />
                              }
                            </h4>
                            <h6>Autofill destination balance</h6>
                          </div>
                        </label>
                        <input
                          type="radio"
                          id="radio-button_2"
                          value={"destination amount"}
                          checked={selectAmountType === "destination amount"}
                          onClick={handleRatioChange}
                          className="internal_circle_radio"
                          style={{
                            transform: "scale(1.2)",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            {showInvoiceInfo && (
              <div className="w-1/2 contactright">
                <div className="flex flex-col">
                  <div className="bg-[#314C7F] text-[#DADADA] font-semibold text-base flex flex-row justify-between py-4 pb-0 mt-4 px-8 rounded-t-4xl 4xl:rounded-t-large items-center">
                    <div className="text-[#DADADA] font-semibold text-base flex flex-row items-center gap-2">
                      <img src={AssociateInfo} alt="" className="w-4 h-4" />
                      <p>Invoice number in use</p>
                    </div>
                  </div>
                  <p className="py-2 pb-0 px-8 bg-[#314C7F] text-[#DADADA] font-normal text-xs">
                    Looks like this invoice number is already in use for some
                    other transaction/s.
                  </p>
                </div>
                <div className="rounded-b-4xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl">
                  <div
                    className="bg-[#314C7F] py-5 h-full flex flex-col rounded-b-4xl"
                    style={{ height: "240px" }}
                  >
                    <div className="w-full flex flex-col px-8 gap-4 overflow-auto h-72 custom-scroll-container">
                      <div className="text-justify flex flex-col gap-6">
                        {haveApproved ? (
                          <>
                            {haveApproved && (
                              <div className="">
                                {beneLookData?.beneficiary_accounts.map(
                                  (val, i) => {
                                    if (val?.approval_status === "approved") {
                                      return (
                                        <Button
                                          style={{
                                            backgroundColor: "#1D3A6D",
                                          }}
                                          className="child_scroll"
                                          key={i}
                                        >
                                          <div className="bank_name">
                                            <img
                                              src={require("../../../assets/IfscLogo/" +
                                                IfscCodeMapper(val?.ifsc_code))}
                                              alt="bank"
                                            />
                                          </div>
                                          <div className="client_name">
                                            <p
                                              className="name_client2"
                                              style={{
                                                color: "#4DDD37",
                                              }}
                                            >
                                              hello
                                            </p>
                                            <p className="bank_client2">
                                              Bank account
                                              <span className="bank_no">
                                                - Bank account number
                                              </span>
                                            </p>
                                          </div>
                                          <div className="viewProfile">
                                            <button
                                            // onClick={(e) => {
                                            //   e.stopPropagation();
                                            //   navigate(
                                            //     `/accounts/beneficiary_account/details/${val?.id}`
                                            //   );
                                            // }}
                                            >
                                              <img
                                                src={ViewDetails}
                                                alt="viewdetails"
                                              />
                                            </button>
                                            <p>View details</p>
                                          </div>
                                        </Button>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className=" mt-[70px]">
            <img src={arrow_right_blue} className=" w-[20px] h-[20px]" />
          </div>
          <div
            className="divideAmount_internal hide-scroll-bar"
            style={{ marginLeft: "10px" }}
          >
            <div className="w-1/2 h-fit contactright second_side_div">
              <div
                className="bg-[#29487E] flex flex-col relative rounded-[20px]"
                style={{ width: "inherit", height: "inherit" }}
              >
                <div className="  py-5 h-full  flex flex-col rounded-[20px] bg-[#3A5686] ">
                  <div className="w-full flex flex-col px-5">
                    <div className=" items-center justify-between">
                      <div className="text-[#DADADA] font-semibold text-base">
                        {"Destination Account Details"}
                      </div>
                      <div className=" w-[432px] h-[59px] bg-[#314D7E] my-[16px] flex rounded-[10px] px-[20px] py-[10px]">
                        <div className=" w-1/2 border-r h-[39px] border-[#031F4F]">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={formatAccountNumber(
                              props?.txnPayLoad?.bankAccountNo
                            )}
                          >
                            <p className="internal_bene_name">
                              {formatAccountNumber(
                                props?.txnPayLoad?.bankAccountNo
                              )}
                            </p>
                          </Tooltip>

                          <p className=" font-sans text-[#dadada] text-[12px] font-normal">
                            Account number
                          </p>
                        </div>
                        {props?.txnPayLoad?.isDestinationOwnerActive === "Y" &&
                        props?.txnPayLoad?.destinationAccountBalance !==
                          undefined ? (
                          <div className=" pl-[20px]">
                            <div className="source-account-latest-balance">
                              <p
                                className="font-sans  text-[14px] font-semibold owner-Fetch-Balance"
                                style={{
                                  color:
                                    Number(
                                      props?.txnPayLoad
                                        ?.destinationAccountBalance
                                    ) >= 0
                                      ? "#4DDD37"
                                      : "#FF5860",
                                }}
                              >
                                ₹{" "}
                                {
                                  <FormatAmount
                                    price={
                                      props?.txnPayLoad
                                        ?.destinationAccountBalance
                                    }
                                  />
                                }
                              </p>
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title="Refresh Balance"
                              >
                                <button
                                  onClick={(e) =>
                                    handleRefresh(
                                      e,
                                      props?.txnPayLoad
                                        ?.destinationAcountOwnerId,
                                      "destination"
                                    )
                                  }
                                  className={`internal_amount_button ${
                                    services?.includes("VAccountBalance") ||
                                    services?.includes("Admin")
                                      ? "cursor-pointer"
                                      : "cursor-not-allowed opacity-50"
                                  }`}
                                >
                                  {createTransactionBankRefreshLoading &&
                                  refreshId ===
                                    props?.txnPayLoad
                                      ?.destinationAcountOwnerId ? (
                                    <BalanceLoader
                                      loader={
                                        createTransactionBankRefreshLoading
                                      }
                                      type={"internalTxn"}
                                    />
                                  ) : !createTransactionBankRefreshLoading &&
                                    refreshId ===
                                      props?.txnPayLoad
                                        ?.destinationAcountOwnerId ? (
                                    <BalanceLoader
                                      loader={
                                        createTransactionBankRefreshLoading
                                      }
                                      setAccId={setRefreshId}
                                      type={"internalTxn"}
                                    />
                                  ) : (
                                    <>
                                      {" "}
                                      <img
                                        className="internal_reload"
                                        src={WhiteReload}
                                        alt="Img"
                                      />
                                    </>
                                  )}
                                </button>
                              </Tooltip>
                            </div>

                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={
                                props?.txnPayLoad
                                  ?.destinationAcountOwnerLastUpdated
                              }
                            >
                              <p className="internal_new_balance">
                                Balance -{" "}
                                {
                                  props?.txnPayLoad
                                    ?.destinationAcountOwnerLastUpdated
                                }
                              </p>
                            </Tooltip>
                          </div>
                        ) : (
                          <div className=" pl-[20px]">
                            <p className=" w-[164px] font-sans text-[#f9f9f9] text-[14px] font-semibold">
                              Redacted
                            </p>
                            <p className=" font-sans text-[#dadada] text-[12px] font-normal">
                              Balance
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="Inter_settle_main_div_container_img_2"
                style={{
                  display:
                    props?.txnPayLoad?.bankAccountNo === null ? "none" : "flex",
                }}
              >
                <img
                  src={require("../../../assets/IfscLogo/" +
                    IfscCodeMapper(props?.txnPayLoad?.bankAccountIFSC))}
                  alt="bank"
                />
              </div>
            </div>
            <div className="rounded-[20px]">
              <div className="bg-[#314C7F] h-full flex flex-col p-[20px] rounded-[20px] items-start mb-5">
                <div className="text-[#DADADA] font-semibold text-base flex flex-row justify-between">
                  <p className="text-center pb-[20px]">Additional Details</p>
                </div>

                <div
                  className=" w-full flex flex-col gap-5"
                  // style={{ overflowY: "auto" }}
                >
                  {renderInputFields()}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobileView && (
        <div
          className={`beneContact_Main_internal_res ${
            props?.direction === "next"
              ? "container-righttoleft-animate-intrtxn"
              : props?.direction === "previous"
              ? "container-lefttoright-animate-intrtxn"
              : ""
          }`}
          style={{ width: `${windowWidth}px` }}
        >
          <div
            className="divideAmount_internal_res"
            style={{ padding: "10px 10px 0px 10px" }}
          >
            <div className="w-1/2 h-fit contactright mb-[20px]">
              <div className="internal_amount_div_res">
                <div className="internal_amount_div_child">
                  <div className="internal_amount_div_child_top">
                    <NumericFormat
                      name="amount"
                      customInput={TextField}
                      thousandSeparator={true}
                      thousandsGroupStyle="lakh"
                      onChange={handleAmountChange}
                      value={props.txnPayLoad.amount}
                      decimalScale={2}
                      isAllowed={(values) => {
                        if (!values.value) return true;
                        const { floatValue, value } = values;
                        if (value[0] == 0) {
                          return false;
                        }
                        return allowedValue(String(floatValue));
                      }}
                      inputProps={{
                        placeholder: "Enter amount",
                        style: {
                          padding: "4px",
                          fontSize: "24px",
                          outline: "none",
                          font: "normal normal normal 24px/43px Open Sans",
                          color: "#FFFFFF",
                        },
                        className: "text-center ",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: props?.showRupeeSymbol && (
                          <InputAdornment position="start">
                            <span
                              style={{
                                fontSize: "24px",
                                color: "#ffffff",
                                marginLeft: "-10px",
                              }}
                            >
                              &#8377;
                            </span>
                          </InputAdornment>
                        ),
                        style: {
                          outline: "none",
                          boxShadow: "none",
                          borderBottom: "2px solid #DADADA",
                          borderRadius: "0px",
                        },
                      }}
                      sx={{
                        border: "none",
                        outline: "none",
                        mx: "auto",
                        width: "100%",
                        "& input::placeholder": {
                          fontFamily: "Open Sans !important",
                          fontSize: "24px !important",
                          fontWeight: "600 !important",
                          lineHeight: "24px !important",
                          textAlign: "center !important",
                          color: "#F9F9F9 !important",
                          opacity: "1",
                        },
                        "& input": {
                          height: "auto",
                          color: "white",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                      }}
                    />
                    {props?.amountInWords && (
                      <>
                        <div className="capitalize internal_amount_word">
                          {props?.amountInWords}
                          <br />
                        </div>
                        <span className="text-[#DADADA] text-xs mt-[5px]">
                          In Words
                        </span>
                      </>
                    )}
                  </div>
                  <div
                    className="internal_amount_div_child_bottom"
                    style={{
                      display:
                        (Number(props?.txnPayLoad?.ownerBankBalance) == 0 &&
                          Number(
                            props?.txnPayLoad?.destinationAccountBalance
                          ) == 0) ||
                        (Number(props?.txnPayLoad?.ownerBankBalance) <= 0 &&
                          Number(props?.txnPayLoad?.destinationAccountBalance) >
                            0) ||
                        Number(props?.txnPayLoad?.ownerBankBalance) == 0 ||
                        props?.txnPayLoad?.ownerBankBalance === "xxxx.xx"
                          ? "none"
                          : "flex",
                    }}
                  >
                    <div className="internal_amount_div_child_bottom_first">
                      <label
                        htmlFor="radio-button"
                        className="internal_radio_1"
                      >
                        Custom amount
                      </label>
                      <input
                        type="radio"
                        id="radio-button"
                        value={"Custom amount"}
                        checked={selectAmountType === "Custom amount"}
                        onClick={handleRatioChange}
                        className="internal_circle_radio"
                        style={{
                          transform: "scale(1.2)",
                          cursor: "pointer",
                        }}
                      />
                    </div>

                    <h4 className="internal_amount_div_child_bottom_para">
                      Or select below to autofill the suggested amount
                    </h4>
                    {Number(props?.txnPayLoad?.ownerBankBalance) > 0 &&
                    Number(props?.txnPayLoad?.destinationAccountBalance) >=
                      0 ? (
                      <div className="internal_amount_div_child_bottom_first">
                        <label
                          htmlFor="radio-button_2"
                          className="internal_radio_1"
                        >
                          <div className="internal_radio_amount">
                            <h4>
                              {" "}
                              ₹{" "}
                              {
                                <FormatAmount
                                  price={props?.txnPayLoad?.ownerBankBalance}
                                />
                              }
                            </h4>
                            <h6>Autofill source balance</h6>
                          </div>
                        </label>
                        <input
                          type="radio"
                          id="radio-button_2"
                          value={"Source amount"}
                          checked={selectAmountType === "Source amount"}
                          onClick={handleRatioChange}
                          className="internal_circle_radio"
                          style={{
                            transform: "scale(1.2)",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ) : Number(props?.txnPayLoad?.ownerBankBalance) > 0 &&
                      Number(props?.txnPayLoad?.destinationAccountBalance) <
                        0 ? (
                      <div className="internal_amount_div_child_bottom_first_part">
                        <div className="internal_amount_div_child_bottom_first_part_1">
                          <label
                            htmlFor="radio-button_2"
                            className="internal_radio_1"
                          >
                            <div className="internal_radio_amount">
                              <h4>
                                {" "}
                                ₹{" "}
                                {
                                  <FormatAmount
                                    price={props?.txnPayLoad?.ownerBankBalance}
                                  />
                                }
                              </h4>
                              <h6>Autofill source balance</h6>
                            </div>
                          </label>
                          <input
                            type="radio"
                            id="radio-button_2"
                            value={"Source amount"}
                            checked={selectAmountType === "Source amount"}
                            onClick={handleRatioChange}
                            className="internal_circle_radio"
                            style={{
                              transform: "scale(1.2)",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                        <div className="internal_amount_div_child_bottom_first_part_1">
                          <label
                            htmlFor="radio-button_2"
                            className="internal_radio_1"
                          >
                            <div className="internal_radio_amount">
                              <h4>
                                {" "}
                                ₹{" "}
                                {
                                  <FormatAmount
                                    price={Math.abs(
                                      props?.txnPayLoad
                                        ?.destinationAccountBalance
                                    )}
                                  />
                                }
                              </h4>
                              <h6>Autofill destination balance</h6>
                            </div>
                          </label>
                          <input
                            type="radio"
                            id="radio-button_2"
                            value={"destination amount"}
                            checked={selectAmountType === "destination amount"}
                            onClick={handleRatioChange}
                            className="internal_circle_radio"
                            style={{
                              transform: "scale(1.2)",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    ) : Number(props?.txnPayLoad?.ownerBankBalance) < 0 &&
                      Number(props?.txnPayLoad?.destinationAccountBalance) <
                        0 ? (
                      <div className="internal_amount_div_child_bottom_first">
                        <label
                          htmlFor="radio-button_2"
                          className="internal_radio_1"
                        >
                          <div className="internal_radio_amount">
                            <h4>
                              {" "}
                              ₹{" "}
                              {
                                <FormatAmount
                                  price={Math.abs(
                                    props?.txnPayLoad?.destinationAccountBalance
                                  )}
                                />
                              }
                            </h4>
                            <h6>Autofill destination balance</h6>
                          </div>
                        </label>
                        <input
                          type="radio"
                          id="radio-button_2"
                          value={"destination amount"}
                          checked={selectAmountType === "destination amount"}
                          onClick={handleRatioChange}
                          className="internal_circle_radio"
                          style={{
                            transform: "scale(1.2)",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/2 h-fit contactright second_side_div_res ">
              <div
                className="bg-[#29487E] flex flex-col relative rounded-[30px]"
                style={{ height: "inherit" }}
              >
                <div className="  py-5 h-full  flex flex-col rounded-[10px] bg-[#3A5686] ">
                  <div
                    className="w-full flex flex-col"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className=" items-center justify-between"
                      style={{ width: "100%", padding: "0px 20px" }}
                    >
                      <div className="text-[#DADADA] font-semibold text-base">
                        {"Source Account Details"}
                      </div>
                      <div className=" h-[59px] bg-[#314D7E] my-[16px] flex rounded-[10px] px-[20px] py-[10px]">
                        <div className=" w-1/2 border-r h-[39px] border-[#031F4F]">
                          <p className=" font-sans text-[#f9f9f9] text-[14px] font-[600px]">
                            {props?.txnPayLoad?.ownerBankAccountNo.substring(
                              0,
                              4
                            ) +
                              " •••• " +
                              props?.txnPayLoad?.ownerBankAccountNo.substring(
                                props?.txnPayLoad?.ownerBankAccountNo.length - 4
                              )}
                          </p>
                          <p className=" font-sans text-[#dadada] text-[12px] font-normal">
                            Account Number
                          </p>
                        </div>
                        <div className=" pl-[20px]">
                          <div className="source-account-latest-balance">
                            <p
                              className="font-sans  text-[14px] font-[600px] owner-Fetch-Balance"
                              style={{
                                color:
                                  Number(props?.txnPayLoad?.ownerBankBalance) >=
                                  0
                                    ? "#4DDD37"
                                    : "#FF5860",
                              }}
                            >
                              ₹{" "}
                              {
                                <FormatAmount
                                  price={props?.txnPayLoad?.ownerBankBalance}
                                />
                              }
                            </p>
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title="Refresh Balance"
                            >
                              <button
                                onClick={(e) =>
                                  handleRefresh(
                                    e,
                                    props?.txnPayLoad?.ownerId,
                                    "owner"
                                  )
                                }
                                className={`internal_amount_button ${
                                  services?.includes("VAccountBalance") ||
                                  services?.includes("Admin")
                                    ? "cursor-pointer"
                                    : "cursor-not-allowed opacity-50"
                                }`}
                              >
                                {createTransactionBankRefreshLoading &&
                                refreshId === props?.txnPayLoad?.ownerId ? (
                                  <BalanceLoader
                                    loader={createTransactionBankRefreshLoading}
                                    type={"internalTxn"}
                                  />
                                ) : !createTransactionBankRefreshLoading &&
                                  refreshId === props?.txnPayLoad?.ownerId ? (
                                  <BalanceLoader
                                    loader={createTransactionBankRefreshLoading}
                                    setAccId={setRefreshId}
                                    type={"internalTxn"}
                                  />
                                ) : (
                                  <>
                                    {" "}
                                    <img
                                      className="internal_reload"
                                      src={WhiteReload}
                                      alt="Img"
                                    />
                                    {/* <p className="Refresh">Refresh</p> */}
                                  </>
                                )}
                              </button>
                            </Tooltip>
                          </div>

                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={props?.txnPayLoad?.ownerLastUpdated}
                          >
                            <p className="internal_new_balance">
                              Balance - {props?.txnPayLoad?.ownerLastUpdated}
                            </p>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="Inter_settle_main_div_container_img_2"
                style={{
                  display:
                    props?.txnPayLoad?.ownerBankAccountNo === null
                      ? "none"
                      : "flex",
                  borderRadius: "10px 0px",
                }}
              >
                <img
                  src={require("../../../assets/IfscLogo/" +
                    IfscCodeMapper(props?.txnPayLoad?.ownerBankIFSC))}
                  alt="bank"
                />
              </div>
            </div>
            {/* <div className="w-1/2 h-fit contactright">
              <div className="internal_amount_div_res">
                <div className="internal_amount_div_child">
                  <div className="internal_amount_div_child_top">
                    <NumericFormat
                      name="amount"
                      customInput={TextField}
                      thousandSeparator={true}
                      thousandsGroupStyle="lakh"
                      onChange={handleAmountChange}
                      value={props.txnPayLoad.amount}
                      decimalScale={2}
                      isAllowed={(values) => {
                        if (!values.value) return true;
                        const { floatValue, value } = values;
                        if (value[0] == 0) {
                          return false;
                        }
                        return allowedValue(String(floatValue));
                      }}
                      inputProps={{
                        placeholder: "Enter amount",
                        style: {
                          padding: "4px",
                          fontSize: "24px",
                          outline: "none",
                          font: "normal normal normal 24px/43px Open Sans",
                          color: "#FFFFFF",
                        },
                        className: "text-center ",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: props?.showRupeeSymbol && (
                          <InputAdornment position="start">
                            <span
                              style={{
                                fontSize: "24px",
                                color: "#ffffff",
                                marginLeft: "-10px",
                              }}
                            >
                              &#8377;
                            </span>
                          </InputAdornment>
                        ),
                        style: {
                          outline: "none",
                          boxShadow: "none",
                          borderBottom: "2px solid #DADADA",
                          borderRadius: "0px",
                        },
                      }}
                      sx={{
                        border: "none",
                        outline: "none",
                        mx: "auto",
                        width: "100%",
                        "& input::placeholder": {
                          fontFamily: "Open Sans !important",
                          fontSize: "24px !important",
                          fontWeight: "600 !important",
                          lineHeight: "24px !important",
                          textAlign: "center !important",
                          color: "#F9F9F9 !important",
                          opacity: "1",
                        },
                        "& input": {
                          height: "auto",
                          color: "white",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                      }}
                    />
                    {props?.amountInWords && (
                      <>
                        <div className="capitalize internal_amount_word">
                          {props?.amountInWords}
                          <br />
                        </div>
                        <span className="text-[#DADADA] text-xs mt-[5px]">
                          In Words
                        </span>
                      </>
                    )}
                  </div>
                  <div
                    className="internal_amount_div_child_bottom"
                    style={{
                      display:
                        (Number(props?.txnPayLoad?.ownerBankBalance) == 0 &&
                          Number(
                            props?.txnPayLoad?.destinationAccountBalance
                          ) == 0) ||
                        (Number(props?.txnPayLoad?.ownerBankBalance) <= 0 &&
                          Number(props?.txnPayLoad?.destinationAccountBalance) >
                            0) ||
                        Number(props?.txnPayLoad?.ownerBankBalance) == 0
                          ? "none"
                          : "flex",
                    }}
                  >
                    <div className="internal_amount_div_child_bottom_first">
                      <label
                        htmlFor="radio-button"
                        className="internal_radio_1"
                      >
                        Custom amount
                      </label>
                      <input
                        type="radio"
                        id="radio-button"
                        value={"Custom amount"}
                        checked={selectAmountType === "Custom amount"}
                        onClick={handleRatioChange}
                        className="internal_circle_radio"
                        style={{
                          transform: "scale(1.2)",
                          cursor: "pointer",
                        }}
                      />
                    </div>

                    <h4 className="internal_amount_div_child_bottom_para">
                      Or select below to autofill the suggested amount
                    </h4>
                    {Number(props?.txnPayLoad?.ownerBankBalance) > 0 &&
                    Number(props?.txnPayLoad?.destinationAccountBalance) >=
                      0 ? (
                      <div className="internal_amount_div_child_bottom_first">
                        <label
                          htmlFor="radio-button_2"
                          className="internal_radio_1"
                        >
                          <div className="internal_radio_amount">
                            <h4>
                              {" "}
                              ₹{" "}
                              {
                                <FormatAmount
                                  price={props?.txnPayLoad?.ownerBankBalance}
                                />
                              }
                            </h4>
                            <h6>Autofill source balance</h6>
                          </div>
                        </label>
                        <input
                          type="radio"
                          id="radio-button_2"
                          value={"Source amount"}
                          checked={selectAmountType === "Source amount"}
                          onClick={handleRatioChange}
                          className="internal_circle_radio"
                          style={{
                            transform: "scale(1.2)",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ) : Number(props?.txnPayLoad?.ownerBankBalance) > 0 &&
                      Number(props?.txnPayLoad?.destinationAccountBalance) <
                        0 ? (
                      <div className="internal_amount_div_child_bottom_first_part">
                        <div className="internal_amount_div_child_bottom_first_part_1">
                          <label
                            htmlFor="radio-button_2"
                            className="internal_radio_1"
                          >
                            <div className="internal_radio_amount">
                              <h4>
                                {" "}
                                ₹{" "}
                                {
                                  <FormatAmount
                                    price={props?.txnPayLoad?.ownerBankBalance}
                                  />
                                }
                              </h4>
                              <h6>Autofill source balance</h6>
                            </div>
                          </label>
                          <input
                            type="radio"
                            id="radio-button_2"
                            value={"Source amount"}
                            checked={selectAmountType === "Source amount"}
                            onClick={handleRatioChange}
                            className="internal_circle_radio"
                            style={{
                              transform: "scale(1.2)",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                        <div className="internal_amount_div_child_bottom_first_part_1">
                          <label
                            htmlFor="radio-button_2"
                            className="internal_radio_1"
                          >
                            <div className="internal_radio_amount">
                              <h4>
                                {" "}
                                ₹{" "}
                                {
                                  <FormatAmount
                                    price={Math.abs(
                                      props?.txnPayLoad
                                        ?.destinationAccountBalance
                                    )}
                                  />
                                }
                              </h4>
                              <h6>Autofill destination balance</h6>
                            </div>
                          </label>
                          <input
                            type="radio"
                            id="radio-button_2"
                            value={"destination amount"}
                            checked={selectAmountType === "destination amount"}
                            onClick={handleRatioChange}
                            className="internal_circle_radio"
                            style={{
                              transform: "scale(1.2)",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </div>
                    ) : Number(props?.txnPayLoad?.ownerBankBalance) < 0 &&
                      Number(props?.txnPayLoad?.destinationAccountBalance) <
                        0 ? (
                      <div className="internal_amount_div_child_bottom_first">
                        <label
                          htmlFor="radio-button_2"
                          className="internal_radio_1"
                        >
                          <div className="internal_radio_amount">
                            <h4>
                              {" "}
                              ₹{" "}
                              {
                                <FormatAmount
                                  price={Math.abs(
                                    props?.txnPayLoad?.destinationAccountBalance
                                  )}
                                />
                              }
                            </h4>
                            <h6>Autofill destination balance</h6>
                          </div>
                        </label>
                        <input
                          type="radio"
                          id="radio-button_2"
                          value={"destination amount"}
                          checked={selectAmountType === "destination amount"}
                          onClick={handleRatioChange}
                          className="internal_circle_radio"
                          style={{
                            transform: "scale(1.2)",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div> */}
            {showInvoiceInfo && (
              <div className="w-1/2 contactright">
                <div className="flex flex-col">
                  <div className="bg-[#314C7F] text-[#DADADA] font-semibold text-base flex flex-row justify-between py-4 pb-0 mt-4 px-8 rounded-t-4xl 4xl:rounded-t-large items-center">
                    <div className="text-[#DADADA] font-semibold text-base flex flex-row items-center gap-2">
                      <img src={AssociateInfo} alt="" className="w-4 h-4" />
                      <p>Invoice number in use</p>
                    </div>
                  </div>
                  <p className="py-2 pb-0 px-8 bg-[#314C7F] text-[#DADADA] font-normal text-xs">
                    Looks like this invoice number is already in use for some
                    other transaction/s.
                  </p>
                </div>
                <div className="rounded-b-4xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl">
                  <div
                    className="bg-[#314C7F] py-5 h-full flex flex-col rounded-b-4xl"
                    style={{ height: "240px" }}
                  >
                    <div className="w-full flex flex-col px-8 gap-4 overflow-auto h-72 custom-scroll-container">
                      <div className="text-justify flex flex-col gap-6">
                        {haveApproved ? (
                          <>
                            {haveApproved && (
                              <div className="">
                                {beneLookData?.beneficiary_accounts.map(
                                  (val, i) => {
                                    if (val?.approval_status === "approved") {
                                      return (
                                        <Button
                                          style={{
                                            backgroundColor: "#1D3A6D",
                                          }}
                                          className="child_scroll"
                                          key={i}
                                        >
                                          <div className="bank_name">
                                            <img
                                              src={require("../../../assets/IfscLogo/" +
                                                IfscCodeMapper(val?.ifsc_code))}
                                              alt="bank"
                                            />
                                          </div>
                                          <div className="client_name">
                                            <p
                                              className="name_client2"
                                              style={{
                                                color: "#4DDD37",
                                              }}
                                            >
                                              hello
                                            </p>
                                            <p className="bank_client2">
                                              Bank account
                                              <span className="bank_no">
                                                - Bank account number
                                              </span>
                                            </p>
                                          </div>
                                          <div className="viewProfile">
                                            <button
                                            // onClick={(e) => {
                                            //   e.stopPropagation();
                                            //   navigate(
                                            //     `/accounts/beneficiary_account/details/${val?.id}`
                                            //   );
                                            // }}
                                            >
                                              <img
                                                src={ViewDetails}
                                                alt="viewdetails"
                                              />
                                            </button>
                                            <p>View details</p>
                                          </div>
                                        </Button>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-center my-6 rotate-90">
            <img src={arrow_right_blue} className=" w-[20px] h-[20px]" />
          </div>
          <div
            className="divideAmount_internal_res hide-scroll-bar"
            style={{ padding: "0px 10px" }}
          >
            <div className="w-1/2 h-fit contactright second_side_div_res mb-[20px]">
              <div
                className="bg-[#29487E] flex flex-col relative rounded-[10px]"
                style={{ width: "inherit", height: "inherit" }}
              >
                <div className="  py-5 h-full  flex flex-col rounded-[10px] bg-[#3A5686] ">
                  <div
                    className="w-full flex flex-col"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className=" items-center justify-between"
                      style={{ width: "100%", padding: "0px 20px" }}
                    >
                      <div className="text-[#DADADA] font-semibold text-base">
                        {"Destination Account Details"}
                      </div>
                      <div className="h-[59px] bg-[#314D7E] my-[16px] flex rounded-[10px] px-[20px] py-[10px]">
                        <div className=" w-1/2 border-r h-[39px] border-[#031F4F]">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={formatAccountNumber(
                              props?.txnPayLoad?.bankAccountNo
                            )}
                          >
                            <p
                              className="internal_bene_name"
                              style={{ width: "auto", paddingRight: "12px" }}
                            >
                              {props?.txnPayLoad?.bankAccountNo.substring(
                                0,
                                4
                              ) +
                                " •••• " +
                                props?.txnPayLoad?.bankAccountNo.substring(
                                  props?.txnPayLoad?.bankAccountNo.length - 4
                                )}
                            </p>
                          </Tooltip>

                          <p className=" font-sans text-[#dadada] text-[12px] font-normal">
                            Account number
                          </p>
                        </div>
                        {props?.txnPayLoad?.isDestinationOwnerActive === "Y" &&
                        props?.txnPayLoad?.destinationAccountBalance !==
                          undefined ? (
                          <div className=" pl-[20px]">
                            <div className="source-account-latest-balance">
                              <p
                                className="font-sans  text-[14px] font-semibold owner-Fetch-Balance"
                                style={{
                                  color:
                                    Number(
                                      props?.txnPayLoad
                                        ?.destinationAccountBalance
                                    ) >= 0
                                      ? "#4DDD37"
                                      : "#FF5860",
                                }}
                              >
                                ₹{" "}
                                {
                                  <FormatAmount
                                    price={
                                      props?.txnPayLoad
                                        ?.destinationAccountBalance
                                    }
                                  />
                                }
                              </p>
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title="Refresh Balance"
                              >
                                <button
                                  onClick={(e) =>
                                    handleRefresh(
                                      e,
                                      props?.txnPayLoad
                                        ?.destinationAcountOwnerId,
                                      "destination"
                                    )
                                  }
                                  className={`internal_amount_button ${
                                    services?.includes("VAccountBalance") ||
                                    services?.includes("Admin")
                                      ? "cursor-pointer"
                                      : "cursor-not-allowed opacity-50"
                                  }`}
                                >
                                  {createTransactionBankRefreshLoading &&
                                  refreshId ===
                                    props?.txnPayLoad
                                      ?.destinationAcountOwnerId ? (
                                    <BalanceLoader
                                      loader={
                                        createTransactionBankRefreshLoading
                                      }
                                      type={"internalTxn"}
                                    />
                                  ) : !createTransactionBankRefreshLoading &&
                                    refreshId ===
                                      props?.txnPayLoad
                                        ?.destinationAcountOwnerId ? (
                                    <BalanceLoader
                                      loader={
                                        createTransactionBankRefreshLoading
                                      }
                                      setAccId={setRefreshId}
                                      type={"internalTxn"}
                                    />
                                  ) : (
                                    <>
                                      {" "}
                                      <img
                                        className="internal_reload"
                                        src={WhiteReload}
                                        alt="Img"
                                      />
                                      <p className="Refresh">Refresh</p>
                                    </>
                                  )}
                                </button>
                              </Tooltip>
                            </div>

                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={
                                props?.txnPayLoad
                                  ?.destinationAcountOwnerLastUpdated
                              }
                            >
                              <p className="internal_new_balance">
                                Balance -{" "}
                                {
                                  props?.txnPayLoad
                                    ?.destinationAcountOwnerLastUpdated
                                }
                              </p>
                            </Tooltip>
                          </div>
                        ) : (
                          <div className=" pl-[20px]">
                            <p className=" w-[110px] font-sans text-[#f9f9f9] text-[14px] font-[600px]">
                              Redacted
                            </p>
                            <p className=" font-sans text-[#dadada] text-[12px] font-normal">
                              Balance
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="Inter_settle_main_div_container_img_2"
                style={{
                  display:
                    props?.txnPayLoad?.bankAccountNo === null ? "none" : "flex",
                  borderRadius: "10px 0px",
                }}
              >
                <img
                  src={require("../../../assets/IfscLogo/" +
                    IfscCodeMapper(props?.txnPayLoad?.bankAccountIFSC))}
                  alt="bank"
                />
              </div>
            </div>
            <div>
              <div
                className="bg-[#314C7F] py-7 h-full flex flex-col px-5 items-start"
                style={{
                  borderRadius: "10px",
                }}
              >
                <div className="text-[#DADADA] font-semibold text-base flex flex-row justify-between">
                  <p className="text-center pb-5 px-4">Additional Details</p>
                </div>

                <div
                  className=" w-full flex flex-col gap-5 px-4 pb-2 pt-1"
                  // style={{ overflowY: "auto" }}
                >
                  {renderInputFields()}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InternalTxnAmount;
