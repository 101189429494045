import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { resetInEdit } from "../../../redux/features/verifyPAN/verifyPAN";
import { useNavigate, useParams } from "react-router-dom";
import Disc from "../../../assets/Beneficiary Icons/discEdit.png";
import { resetEditBankBeneficiary } from "../../../redux/features/accounts/BeneficiaryAccount/createAccount";
import {
  getRefreshTokenNew,
  resetServices,
} from "../../../redux/features/refreshToken/refreshToken";
import error_with_white_circle from "../../../assets/ErrorIcons/error_with_white_circle.svg";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import "./index.css";

const useStyles = makeStyles({
  button: {
    backgroundColor: "#506994",
    color: "#F9F9F9",
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "17px 17px 17px 25px",
    textTransform: "none !important",
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "16px",
    lineHeight: "25px",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: "#667CA3",
      color: "#fff",
    },
  },
  activeButton: {
    backgroundColor: "white",
    color: "#153064",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "white",
      color: "#153064",
    },
  },
});

const BeneEditModal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = useState(null);
  const [category, setCategory] = useState("");
  const [panNumber, setPanNumber] = useState({
    pan_no: "",
    gstin: "",
  });
  const [bankDetails, setBankDetails] = useState({
    name: "",
    bankAccountNumber: "",
    ifscCode: "",
  });
  const [previousContactDetails, setPreviousContactDetails] = useState({
    legalName: "",
    contact: "",
    email: "",
    gstin: "",
    category: null,
  });
  const { id } = useParams();
  const navigate = useNavigate();

  const { viewAccountDetailData } = useSelector((state) => state.verify);

  useEffect(() => {
    if (
      viewAccountDetailData &&
      viewAccountDetailData.beneficiary_accounts &&
      viewAccountDetailData.beneficiary_accounts.length > 0
    ) {
      const { pan_legal_name, contact_no, email_id } =
        viewAccountDetailData.beneficiary_accounts[0];
      setPreviousContactDetails({
        legalName: pan_legal_name,
        contact: contact_no,
        email: email_id,

        category: category || null,
      });
    }
  }, [viewAccountDetailData]);

  useEffect(() => {
    if (
      viewAccountDetailData &&
      viewAccountDetailData.beneficiary_accounts &&
      viewAccountDetailData.beneficiary_accounts.length > 0
    ) {
      const { category } = viewAccountDetailData.beneficiary_accounts[0];
      setCategory(category);
    }
  }, [viewAccountDetailData]);

  useEffect(() => {
    if (
      viewAccountDetailData &&
      viewAccountDetailData.beneficiary_accounts &&
      viewAccountDetailData.beneficiary_accounts.length > 0
    ) {
      const { pan_no, gstin } = viewAccountDetailData.beneficiary_accounts[0];
      setPanNumber({ pan_no, gstin: gstin });
    }
  }, [viewAccountDetailData]);

  useEffect(() => {
    if (
      viewAccountDetailData &&
      viewAccountDetailData.beneficiary_accounts &&
      viewAccountDetailData.beneficiary_accounts.length > 0
    ) {
      const { bank_acc_no, name, ifsc_code } =
        viewAccountDetailData.beneficiary_accounts[0];
      setBankDetails({
        bankHolderName: name,
        bankAccountNumber: bank_acc_no,
        ifscCode: ifsc_code,
      });
    }
  }, [viewAccountDetailData]);

  const handleClose = () => {
    props.onClose();
  };

  const handleButtonClick = (buttonName) => {
    if (
      props?.internalAccount === "internalAccount" &&
      buttonName !== "Account name, Contact number, Email"
    ) {
      {
        toast.error(`Cannot been edited since this is an internal account`, {
          position: "bottom-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: { marginBottom: props?.mobileScreen ? "120px" : "" },
        });
      }
    } else {
      setActiveButton(buttonName);
    }
  };

  const handleEditClick = () => {
    dispatch(resetServices());
    dispatch(getRefreshTokenNew());
    switch (activeButton) {
      case "IFSC Code":
        break;
      case "Account name, Contact number, Email":
        navigate(
          `/accounts/${
            props?.internalAccount === "internalAccount" &&
            props?.flow === "internalAccount"
              ? "internal_account"
              : "beneficiary_account"
          }/edit/Contact_Details/${id}`,
          {
            state: previousContactDetails,
          }
        );
        break;
      case "Assigned categories":
        navigate(`/accounts/beneficiary_account/edit/categories/${id}`, {
          state: previousContactDetails,
        });
        break;
      case "PAN Number, GST":
        dispatch(resetInEdit());
        navigate(`/accounts/beneficiary_account/edit/PAN_Details/${id}`);
        break;
      case "Bank account number":
        navigate(`/accounts/beneficiary_account/edit/Bank_Details/${id}`, {
          state: bankDetails,
        });
        dispatch(resetEditBankBeneficiary());
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="fixed flex justify-center items-center top-0 left-0 w-screen h-screen bg-[#000000CC] z-[9998] mobile-view-edit-modal-bgblack">
        <div className="z-[9999] mobile-view-edit-modal-container">
          <div
            className="w-60% flex flex-col bg-[#102C5D] rounded-2xl pb-8 mobile-view-edit-modal-card"
            style={{
              width: window.innerWidth <= 768 ? "95vw" : "50vw",
              maxWidth: "600px",
              padding: "20px 30px",
            }}
          >
            <div className="text-left mobile-view-edit-modal-card-div">
              <div className="flex flex-col mb-5">
                <h6 className="text-custom-orange md:font-bold text-[18px] ml-1">
                  Select the option you would like to edit:
                </h6>
                <div
                  className="mobile-view-edit-modal-hide"
                  style={{
                    backgroundColor: "#CE720F",
                    width: "auto",
                    height: "auto",
                    display:
                      props?.internalAccount === "internalAccount"
                        ? "flex"
                        : "none",
                    gap: "21px",
                    borderRadius: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "20px",
                    paddingTop: "12px",
                    paddingBottom: "12px",
                  }}
                >
                  <img
                    src={error_with_white_circle}
                    alt="erroricon"
                    className="w-[23.06px] h-[23.06px]"
                  />
                  <span
                    style={{
                      width: "auto",
                      height: "auto",
                      color: "#F9F9F9",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Since this is an internal account other features have been
                    disabled
                  </span>
                </div>
              </div>

              <div className="w-full flex flex-col mb-3">
                <div className=" flex flex-col gap-4">
                  <Button
                    className={`${classes.button} ${
                      activeButton === "IFSC Code" ? classes.activeButton : ""
                    } mobile-view-edit-modal-options`}
                    size="medium"
                    onClick={() => handleButtonClick("IFSC Code")}
                    style={{
                      color:
                        props?.internalAccount === "internalAccount"
                          ? "#F9F9F9"
                          : "",
                      cursor:
                        props?.internalAccount === "internalAccount"
                          ? "default"
                          : "",
                    }}
                  >
                    <span
                      style={{
                        opacity:
                          props?.internalAccount === "internalAccount"
                            ? "50%"
                            : "100%",
                      }}
                    >
                      IFSC code
                    </span>
                  </Button>
                  <Button
                    className={`${classes.button} ${
                      activeButton === "Account name, Contact number, Email"
                        ? classes.activeButton
                        : ""
                    } mobile-view-edit-modal-options`}
                    size="medium"
                    onClick={() =>
                      handleButtonClick("Account name, Contact number, Email")
                    }
                  >
                    Account name, Contact number, Email ID
                  </Button>
                  <Button
                    className={`${classes.button} ${
                      activeButton === "Assigned categories"
                        ? classes.activeButton
                        : ""
                    } mobile-view-edit-modal-options`}
                    size="medium"
                    onClick={() => handleButtonClick("Assigned categories")}
                    style={{
                      color:
                        props?.internalAccount === "internalAccount"
                          ? "#F9F9F9"
                          : "",
                      cursor:
                        props?.internalAccount === "internalAccount"
                          ? "default"
                          : "",
                    }}
                  >
                    <span
                      style={{
                        opacity:
                          props?.internalAccount === "internalAccount"
                            ? "50%"
                            : "100%",
                        cursor:
                          props?.internalAccount === "internalAccount"
                            ? "default"
                            : "",
                      }}
                    >
                      Assigned categories
                    </span>
                  </Button>
                  <Button
                    className={`${classes.button} ${
                      activeButton === "PAN Number, GST"
                        ? classes.activeButton
                        : ""
                    } mobile-view-edit-modal-options`}
                    size="medium"
                    onClick={() => handleButtonClick("PAN Number, GST")}
                    style={{
                      color:
                        props?.internalAccount === "internalAccount"
                          ? "#F9F9F9"
                          : "",
                      cursor:
                        props?.internalAccount === "internalAccount"
                          ? "default"
                          : "",
                    }}
                  >
                    <span
                      style={{
                        opacity:
                          props?.internalAccount === "internalAccount"
                            ? "50%"
                            : "100%",
                        cursor:
                          props?.internalAccount === "internalAccount"
                            ? "default"
                            : "",
                      }}
                    >
                      PAN number, GST
                    </span>
                  </Button>
                  <Button
                    className={`${classes.button} ${
                      activeButton === "Bank account number"
                        ? classes.activeButton
                        : ""
                    } mobile-view-edit-modal-options`}
                    size="medium"
                    onClick={() => handleButtonClick("Bank account number")}
                    style={{
                      color:
                        props?.internalAccount === "internalAccount"
                          ? "#F9F9F9"
                          : "",
                      cursor:
                        props?.internalAccount === "internalAccount"
                          ? "default"
                          : "",
                    }}
                  >
                    <span
                      style={{
                        opacity:
                          props?.internalAccount === "internalAccount"
                            ? "50%"
                            : "100%",
                      }}
                    >
                      Bank account number
                    </span>
                  </Button>
                </div>
                {activeButton && (
                  <div className="mt-5">
                    <ul
                      style={{
                        columns: activeButton !== "IFSC Code" ? "2" : "1",
                        listStylePosition: "inside",
                        color: "#C9C9C9",
                        fontSize: "12px",
                      }}
                    >
                      {activeButton === "IFSC Code" && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={Disc}
                            alt="editCategoryMode"
                            className="w-2 h-2"
                          />
                          <li
                            style={{
                              listStyle: "none",
                              marginLeft: "15px",
                              fontSize: "14px",
                            }}
                          >
                            Please contact an Xpentra administrator to change
                            the IFSC detail.
                          </li>
                        </div>
                      )}
                      {activeButton ===
                        "Account name, Contact number, Email" && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={Disc}
                            alt="editCategoryMode"
                            className="w-2 h-2"
                          />
                          <li
                            style={{
                              listStyle: "none",
                              marginLeft: "8px",
                              fontSize: "14px",
                            }}
                          >
                            Require approval
                          </li>
                        </div>
                      )}
                      {activeButton === "Assigned categories" && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={Disc}
                            alt="editCategoryMode"
                            className="w-2 h-2"
                          />
                          <li
                            style={{
                              listStyle: "none",
                              marginLeft: "8px",
                              fontSize: "14px",
                            }}
                          >
                            Require approval
                          </li>
                        </div>
                      )}
                      {activeButton === "PAN Number, GST" && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={Disc}
                            alt="editCategoryMode"
                            className="w-2 h-2"
                          />
                          <li
                            style={{
                              listStyle: "none",
                              marginLeft: "8px",
                              fontSize: "14px",
                            }}
                          >
                            Require approval
                          </li>
                        </div>
                      )}
                      {activeButton === "Bank account number" && (
                        <>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={Disc}
                              alt="editCategoryMode"
                              className="w-2 h-2"
                            />
                            <li
                              style={{
                                listStyle: "none",
                                marginLeft: "8px",
                                fontSize: "14px",
                              }}
                            >
                              Require approval
                            </li>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={Disc}
                              alt="editCategoryMode"
                              className="w-2 h-2"
                            />
                            <li
                              style={{
                                listStyle: "none",
                                marginLeft: "8px",
                                fontSize: "14px",
                              }}
                            >
                              Create a new account
                            </li>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={Disc}
                              alt="editCategoryMode"
                              className="w-2 h-2"
                            />
                            <li
                              style={{
                                listStyle: "none",
                                marginLeft: "8px",
                                fontSize: "14px",
                              }}
                            >
                              Archive the old account
                            </li>
                          </div>
                        </>
                      )}
                    </ul>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "6%",
                    margin: "0px 50px",
                    width: "100%",
                  }}
                  className="px-4 pt-10 mt-4 mobile-view-edit-modal-hide"
                >
                  <Button
                    sx={{
                      borderColor: "none",
                      color: "#fff",
                      textTransform: "capitalize",
                      textDecoration: "underline",
                      fontFamily: "'Open Sans', sans-serif",
                      fontSize: "16px",
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="font-semibold"
                    sx={{
                      borderRadius: "20px",
                      color: "#fff",
                      boxShadow: "0px 3px 6px #00000033",
                      textTransform: "capitalize",
                      fontFamily: "'Open Sans', sans-serif",
                      fontSize: "16px",
                      backgroundColor:
                        activeButton === null || activeButton === "IFSC Code"
                          ? "#707070"
                          : "#69B76F",
                      border: "1px solid transparent",
                      marginRight: "40px",
                      width: "110px",
                      pointerEvents:
                        activeButton === "IFSC Code" ? "none" : "auto",
                      "&:hover": {
                        backgroundColor:
                          activeButton === "IFSC Code" ? "#707070" : "#69B76F",
                      },
                      "&.Mui-disabled": {
                        backgroundColor: "#707070",
                        color: "#FFFFFF",
                      },
                    }}
                    onClick={handleEditClick}
                    disabled={!activeButton || activeButton === "IFSC Code"}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </div>
            <div className="mobile-view-edit-modal-nav">
              <div className="mobile-view-edit-modal-alert">
                <img
                  src={error_with_white_circle}
                  alt="error"
                  className="w-[25px] h-[25px]  mobile-view-edit-alert-img"
                />
                <span className="mobile-view-edit-modal-text">
                  Since this is an internal account other features have been
                  disabled
                </span>
              </div>
              <div className="mobile-view-edit-modal-btn">
                <Button
                  className="font-semibold"
                  sx={{
                    borderRadius: "43px",
                    color: "#DADADA",
                    textTransform: "capitalize",
                    fontFamily: "'Open Sans', sans-serif",
                    fontSize: "14px",
                    backgroundColor: "none",
                    border: "1px solid #DADADA",
                    width: "110px",
                    height: "30px",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className="font-semibold"
                  sx={{
                    borderRadius: "43px",
                    color: "#DADADA",
                    textTransform: "capitalize",
                    fontFamily: "'Open Sans', sans-serif",
                    fontSize: "14px",
                    backgroundColor:
                      activeButton === null || activeButton === "IFSC Code"
                        ? "#707070"
                        : "#69B76F",
                    border: "none",
                    width: "110px",
                    height: "30px",
                    pointerEvents:
                      activeButton === "IFSC Code" ? "none" : "auto",
                    "&:hover": {
                      backgroundColor:
                        activeButton === "IFSC Code" ? "#707070" : "#69B76F",
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "#707070",
                      color: "#FFFFFF",
                    },
                  }}
                  onClick={handleEditClick}
                  disabled={!activeButton || activeButton === "IFSC Code"}
                >
                  Edit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneEditModal;
