import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { env } from "../../../../env";
import { toast } from "react-toastify";
import { toast_position } from "../../Settings/Authorization";

const baseUrl = env.REACT_APP_BASE_URL;

export const beneTxnViewAllAcc = createAsyncThunk(
  "beneTxnViewAccData/beneTxnViewAllAcc",
  async (values) => {
    const {
      page_no,
      lifecycle_status,
      category,
      start_date,
      end_date,
      page_item_count,
      search_string,
      payment_method,
      beneficiary_account_id,
      distribution_lifecycle_status,
      amount,
      owner_account_id,
      confidential_transaction,
      category_value,
      category_name,
    } = values;
    try {
      const response = await axios.get(`${baseUrl}/transactions/beneficiary`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page_no,
          page_size: page_item_count,
          lifecycle_status: lifecycle_status,
          distribution_lifecycle_status: distribution_lifecycle_status,
          category: category,
          start_date,
          end_date: start_date,
          end_date,
          search_string: search_string,
          payment_method: payment_method,
          beneficiary_account_id: beneficiary_account_id,
          amount: amount,
          owner_account_id: owner_account_id,
          confidential_transaction:
            confidential_transaction === "Show only confidential"
              ? "Y"
              : confidential_transaction === "Hide confidential"
              ? "N"
              : undefined,
          category_name: category_name,
          category_value: category_value,
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error, toast_position);
      throw error;
    }
  }
);

export const BeneTxnRequireApprovalPage = createAsyncThunk(
  "BeneTxnRequireApproval",
  async (values) => {
    const { page_no, page_size, category, entityId } = values;
    try {
      const response = await axios.get(
        `${baseUrl}/transactions/beneficiary/actionable`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
          params: {
            page_no: page_no ? page_no : undefined,
            page_size: page_size ? page_size : undefined,
            category: category !== undefined ? category : undefined,
            entity_id: entityId ? entityId : undefined,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
export const BeneTxnDashBoard = createAsyncThunk(
  "BeneTxnDashBoard",
  async (values) => {
    const { page } = values;
    try {
      const response = await axios.get(`${baseUrl}/transactions/beneficiary`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page: page,
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error, toast_position);
      console.error(error);
      throw error;
    }
  }
);

export const beneTxnAccountDetail = createAsyncThunk(
  "beneTxnAccountDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseUrl}/transactions/beneficiary/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  }
);
export const beneTxnWaitingApproval = createAsyncThunk(
  "beneTxnWaitingApproval",
  async (id) => {
    const data = {
      txn_id: [id],
    };
    const requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify(data),
    };

    return await fetch(
      `${baseUrl}/transactions/beneficiary/approve?`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.log("error", error);
      });
  }
);
export const beneTransactionMultiApproval = createAsyncThunk(
  "beneTransactionMultiApproval",
  async (id, { rejectWithValue }) => {
    const data = {
      txn_id: id,
    };

    try {
      const response = await axios.put(
        `${baseUrl}/transactions/beneficiary/approve`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const beneTxnDeniedApproval = createAsyncThunk(
  "beneTxnDeniedApproval",
  async ({ id, AccountIdData }) => {
    const data = {
      txn_id: [id],
      reason: AccountIdData,
    };
    const requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },

      body: JSON.stringify(data),
    };

    return await fetch(
      `${baseUrl}/transactions/beneficiary/reject`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.log("error", error);
      });
  }
);
export const beneTxnMultiDeniedApproval = createAsyncThunk(
  "beneTxnMultiDeniedApproval",
  async ({ id, AccountIdData }, { rejectWithValue }) => {
    const data = {
      txn_id: id,
      reason: AccountIdData,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    try {
      const response = await axios.put(
        `${baseUrl}/transactions/beneficiary/reject`,
        data,
        requestOptions
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error?.response?.data);
      }
    }
  }
);

const beneTxnViewAccDataSlice = createSlice({
  name: "beneTxnViewAccData",
  initialState: {
    isApprovalStatusSelected: false,
    storedPage: 1,
    toggle: false,

    beneTxnViewAllAccData: null,
    beneTxnViewAllAccLoading: null,
    beneTxnViewAllAccError: null,

    BeneTxnRequireApprovalData: null,
    BeneTxnRequireApprovalLoading: null,
    BeneTxnRequireApprovalError: null,

    BeneTxnDashBoardData: null,
    BeneTxnDashBoardLoading: null,
    BeneTxnDashBoardError: null,

    beneTxnAccountLoading: null,
    beneTxnAccountData: null,
    beneTxnAccountError: null,

    BeneTxnWaitingApprovalData: null,
    BeneTxnWaitingApprovalLoading: null,
    BeneTxnWaitingApprovalError: null,

    BeneTxnMultiApprovalData: null,
    BeneTxnMultiApprovalLoading: null,
    BeneTxnMultiApprovalError: null,

    BeneTxnDeniedApprovalData: null,
    BeneTxnDeniedApprovalLoading: null,
    BeneTxnDeniedApprovalError: null,

    BeneTxnMultiDeniedApprovalData: null,
    BeneTxnMultiDeniedApprovalLoading: null,
    BeneTxnMultiDeniedApprovalError: null,

    selectedApproval: null,
    selectedApprovalId: null,
  },
  reducers: {
    setToggle: (state, action) => {
      state.toggle = action.payload;
    },
    resetToggle: (state) => {
      state.toggle = false;
    },
    resetBeneTxnMultiApprovalError: (state) => {
      state.BeneTxnMultiApprovalError = false;
    },
    resetBeneTxnMultiDeniedApprovalLoading: (state) => {
      state.BeneTxnMultiDeniedApprovalLoading = null;
    },
    resetBeneTxnMultiDeniedApprovalData: (state) => {
      state.BeneTxnMultiDeniedApprovalError = false;
    },
    setIsApprovalStatusSelected: (state, action) => {
      state.isApprovalStatusSelected = action.payload;
    },

    resetpage: (state) => {
      state.storedPage = 1;
    },
    setStoredPage: (state, action) => {
      state.storedPage = action.payload;
    },
    selectedApprovalFire(state, action) {
      state.selectedApproval = action.payload?.selectedArray;
      state.selectedApprovalId = action.payload?.selectedId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(beneTxnViewAllAcc.pending, (state, action) => {
      state.beneTxnViewAllAccLoading = true;
    });
    builder.addCase(beneTxnViewAllAcc.fulfilled, (state, action) => {
      state.beneTxnViewAllAccLoading = false;
      state.beneTxnViewAllAccData = action.payload;
    });
    builder.addCase(beneTxnViewAllAcc.rejected, (state, action) => {
      state.beneTxnViewAllAccError = true;
      state.beneTxnViewAllAccData = null;
    });
    builder.addCase(BeneTxnRequireApprovalPage.pending, (state, action) => {
      state.BeneTxnRequireApprovalLoading = true;
    });
    builder.addCase(BeneTxnRequireApprovalPage.fulfilled, (state, action) => {
      state.BeneTxnRequireApprovalLoading = false;
      state.BeneTxnRequireApprovalData = action.payload;
    });
    builder.addCase(BeneTxnRequireApprovalPage.rejected, (state, action) => {
      state.BeneTxnRequireApprovalError = true;
      state.BeneTxnRequireApprovalData = null;
    });
    builder.addCase(BeneTxnDashBoard.pending, (state, action) => {
      state.BeneTxnDashBoardLoading = true;
    });
    builder.addCase(BeneTxnDashBoard.fulfilled, (state, action) => {
      state.BeneTxnDashBoardLoading = false;
      state.BeneTxnDashBoardData = action.payload;
    });
    builder.addCase(BeneTxnDashBoard.rejected, (state, action) => {
      state.BeneTxnDashBoardError = true;
      state.BeneTxnDashBoardLoading = false;
      state.BeneTxnDashBoardData = null;
    });

    builder.addCase(beneTxnAccountDetail.pending, (state, action) => {
      state.beneTxnAccountLoading = true;
    });
    builder.addCase(beneTxnAccountDetail.fulfilled, (state, action) => {
      state.beneTxnAccountLoading = false;
      state.beneTxnAccountData = action.payload;
    });
    builder.addCase(beneTxnAccountDetail.rejected, (state, action) => {
      state.beneTxnAccountError = true;
    });
    builder.addCase(beneTxnWaitingApproval.pending, (state, action) => {
      state.BeneTxnWaitingApprovalLoading = true;
    });
    builder.addCase(beneTxnWaitingApproval.fulfilled, (state, action) => {
      state.BeneTxnDashBoardLoading = false;
      state.BeneTxnWaitingApprovalData = 500;
    });
    builder.addCase(beneTxnWaitingApproval.rejected, (state, action) => {
      state.BeneTxnWaitingApprovalError = true;
      state.BeneTxnWaitingApprovalData = null;
    });
    builder.addCase(beneTransactionMultiApproval.pending, (state, action) => {
      state.BeneTxnMultiApprovalLoading = true;
    });
    builder.addCase(beneTransactionMultiApproval.fulfilled, (state, action) => {
      state.BeneTxnMultiApprovalLoading = false;
      state.BeneTxnMultiApprovalData = action.payload;
    });
    builder.addCase(beneTransactionMultiApproval.rejected, (state, action) => {
      sessionStorage.setItem(
        "selectedDataError",
        JSON.stringify(action.payload?.errors?.base)
      );
      state.BeneTxnMultiApprovalError = true;
      state.BeneTxnMultiApprovalData = null;
    });
    builder.addCase(beneTxnDeniedApproval.pending, (state, action) => {
      state.BeneTxnDeniedApprovalLoading = true;
    });
    builder.addCase(beneTxnDeniedApproval.fulfilled, (state, action) => {
      state.BeneTxnDeniedApprovalLoading = false;
      state.BeneTxnDeniedApprovalData = action.payload;
    });
    builder.addCase(beneTxnDeniedApproval.rejected, (state, action) => {
      state.BeneTxnDeniedApprovalError = true;
      state.BeneTxnDeniedApprovalData = null;
      state.BeneTxnDeniedApprovalLoading = false;
    });
    builder.addCase(beneTxnMultiDeniedApproval.pending, (state, action) => {
      state.BeneTxnMultiDeniedApprovalLoading = true;
    });
    builder.addCase(beneTxnMultiDeniedApproval.fulfilled, (state, action) => {
      state.BeneTxnMultiDeniedApprovalLoading = false;
      state.BeneTxnMultiDeniedApprovalData = action.payload;
    });
    builder.addCase(beneTxnMultiDeniedApproval.rejected, (state, action) => {
      sessionStorage.setItem(
        "selectedDataError",
        JSON.stringify(action.payload?.errors?.base)
      );
      state.BeneTxnMultiDeniedApprovalLoading = null;
      state.BeneTxnMultiDeniedApprovalError = true;
      state.BeneTxnMultiDeniedApprovalData = null;
    });
  },
});

export const {
  selectedApprovalFire,
  setToggle,
  resetpage,
  resetBeneTxnMultiApprovalError,
  resetBeneTxnMultiDeniedApprovalLoading,
  resetBeneTxnMultiDeniedApprovalData,
  setStoredPage,
  setIsApprovalStatusSelected,
  resetToggle,
} = beneTxnViewAccDataSlice.actions;
export default beneTxnViewAccDataSlice.reducer;
