import React, { useEffect, useState } from "react";
import "./index.css";
import merchant from "../../../assets/CategoryIcons/merchant.svg";
import FormatAmount from "../../../components/_utils/FormatAmount/FormatAmount";
import { formatAccountNumber } from "../../_utils/FormatAccountNumber";
import { Tooltip, tooltipClasses } from "@mui/material";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import BalanceLoader from "../../_utils/BalanceLoader";
import { useDispatch, useSelector } from "react-redux";
import refresh_balance from "../../../assets/RefreshIcons/reload-refresh_white.svg";
import confidential_transaction from "../../../assets/GeneralIcons/confidential_transaction.svg";
import arrow_right_blue from "../../../assets/ArrowsIcons/arrow_right_blue.svg";
import { checkUsertoken } from "../../../redux/features/login/login";
import {
  getCreateTransactionBalance,
  resetOwnerBalance,
} from "../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import WhiteReload from "../../../assets/RefreshIcons/reload-refresh_white.svg";
import RefreshBalanceToast from "../../_utils/RefreshBalanceToast";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import ConfidentailImage from "../../../assets/GeneralIcons/confidential_transaction.svg";
import FileFormatMapper from "../../BeneTransaction/BeneTxnAmount/FileFormatMapper";
import { useNavigate } from "react-router-dom";

const BeneTxnConfirm = (props) => {
  const [isToggled, setIsToggled] = useState(false);
  const [balanceRefreshDisabled, setBalanceRefreshDisabled] = useState(false);
  const [refreshAnimationId, setRefreshAnimationId] = useState(null);
  const [previousRefreshData, setPreviousRefreshData] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [balanceRefresh, setBalanceRefresh] = useState(false);
  const [refreshType, setRefreshType] = useState(false);
  const dispatch = useDispatch();

  const {
    createTransactionBankRefreshLoading,
    createTransactionBankRefreshData,
    createTransactionBankRefreshError,
  } = useSelector((state) => state.ownerAllVerification);

  const services = useSelector((state) => state.refreshToken.services);

  const navigate = useNavigate();

  const handleRefresh = (e, id, prevBalance) => {
    if (services?.includes("VAccountBalance") || services?.includes("Admin")) {
      setBalanceRefreshDisabled(true);
      setPreviousRefreshData(prevBalance);
      setRefreshType("single");
      e.stopPropagation();
      props?.setRefreshId(id);
      setRefreshAnimationId(id);
      dispatch(checkUsertoken());
      dispatch(getCreateTransactionBalance(id));
    } else {
      e.stopPropagation();
      navigate("/feature_not_assigned", {
        state: { unavailableService: "VAccountBalance,Admin" },
      });
    }
  };

  useEffect(() => {
    if (createTransactionBankRefreshLoading) {
      toast.dismiss();
      RefreshBalanceToast();
    } else if (createTransactionBankRefreshData?.status) {
      setBalanceRefresh(true);
      toast.dismiss();
      toast.success("Balance refreshed successfully", toast_position);
      setTimeout(() => {
        setBalanceRefresh(false);
        setBalanceRefreshDisabled(false);
      }, 1000);
    } else if (
      createTransactionBankRefreshError &&
      !createTransactionBankRefreshLoading
    ) {
      toast.dismiss();
      toast.error(createTransactionBankRefreshError, toast_position);
    }
    if (createTransactionBankRefreshData) {
      // let data = props?.transactionFilterData.map((val) => {
      //   if (
      //     val.id ===
      //     createTransactionBankRefreshData?.owner_accounts[0]?.owner_account_id
      //   ) {
      //     return {
      //       ...val,
      //       owner_account_balance:
      //         createTransactionBankRefreshData?.owner_accounts[0]
      //           ?.owner_account_balance,
      //       owner_account_balance_last_fetched_at_in_words:
      //         createTransactionBankRefreshData?.owner_accounts[0]
      //           ?.owner_account_balance_last_fetched_at_in_words,
      //     };
      //   }
      //   return val;
      // });

      props?.setTxnPayLoad({
        ...props?.txnPayLoad,
        ownerBankBalance:
          createTransactionBankRefreshData?.owner_accounts[0]
            ?.owner_account_balance,
        ownerLastUpdated:
          createTransactionBankRefreshData?.owner_accounts[0]
            ?.owner_account_balance_last_fetched_at_in_words,
      });
    }
    return () => {
      dispatch(resetOwnerBalance());
    };
  }, [
    createTransactionBankRefreshData,
    createTransactionBankRefreshLoading,
    createTransactionBankRefreshError,
  ]);

  const renderAdditionalDetails = () => {
    if (props.txnPayLoad?.mergeCategory) {
      return props.txnPayLoad?.mergeCategory.map((field, index) => (
        <div key={index}>
          <Tooltip
            placement="bottom-start"
            sx={{ zIndex: "2147483647 !important" }}
            componentsProps={{
              popper: {
                sx: {
                  [`& .${tooltipClasses.tooltip}`]: {
                    backgroundColor: (theme) => theme.palette.common.black,
                  },
                  zIndex: "2147483647 !important",
                },
              },
            }}
            title={
              field?.data_type === "upload" && field?.value !== "Not Given"
                ? props?.files?.name
                : field?.data_type === "time"
                ? field?.value
                  ? field?.value
                  : "Not Given"
                : field?.data_type === "hyperlink"
                ? field?.value?.length === 0
                  ? "Not Given"
                  : field?.value
                : typeof field?.value === "object" &&
                  field?.data_type === "from_year_to_year"
                ? field?.value.length === 0
                  ? "Not Given"
                  : `${field?.value?.from} - ${field?.value?.to}`
                : field?.data_type === "month_year"
                ? field?.value === "Not Given"
                  ? "Not Given"
                  : field?.value && convertToMonthYear(field?.value)
                : field?.data_type === "multi_select_list"
                ? field?.value?.length === 0
                  ? "Not Given"
                  : field?.value?.join(", ")
                : field?.data_type === "date_range"
                ? field?.value === "Not Given"
                  ? "Not Given"
                  : `${field?.value?.start_date} - ${field?.value?.end_date}`
                : typeof field?.value === "boolean" &&
                  field?.data_type === "toggle"
                ? field?.value === true
                  ? "Yes"
                  : "No"
                : field?.value
            }
            followCursor
          >
            <p
              className="text-sm text-[#FFFFFF] font-sans mt-[10px] mb-[4px]"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "fit-content",
              }}
            >
              {field?.data_type === "upload" && field?.value !== "Not Given" ? (
                <div className="main_details_upload w-full">
                  <div className="top_details_upload w-[80%]">
                    <p className="top_details_upload_p1 truncate w-[90%]">
                      {props?.files?.name}
                    </p>
                    <p className="text-xs text-[#C9C9C9] font-sans mt-1">
                      {field?.name}
                    </p>
                  </div>
                  <div className="botton_details_upload w-[20%]">
                    <img
                      src={
                        props?.files?.type?.split("/").pop().toUpperCase() ===
                          "PNG" ||
                        props?.files?.type?.split("/").pop().toUpperCase() ===
                          "BMP" ||
                        props?.files?.type?.split("/").pop().toUpperCase() ===
                          "JPEG" ||
                        props?.files?.type?.split("/").pop().toUpperCase() ===
                          "JPG"
                          ? props?.showImages
                          : FileFormatMapper(
                              props?.files?.type?.split("/").pop().toUpperCase()
                            )
                      }
                      alt="file"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </div>
                </div>
              ) : field?.data_type === "time" ? (
                field?.value ? (
                  field?.value
                ) : (
                  "Not Given"
                )
              ) : field?.data_type === "hyperlink" ? (
                field?.value?.length === 0 ? (
                  "Not Given"
                ) : (
                  field?.value
                )
              ) : typeof field?.value === "object" &&
                field?.data_type === "from_year_to_year" ? (
                field?.value.length === 0 ? (
                  "Not Given"
                ) : (
                  `${field?.value?.from} - ${field?.value?.to}`
                )
              ) : field?.data_type === "month_year" ? (
                field?.value === "Not Given" ? (
                  "Not Given"
                ) : (
                  field?.value && convertToMonthYear(field?.value)
                )
              ) : field?.data_type === "date_range" ? (
                field?.value === "Not Given" ? (
                  "Not Given"
                ) : (
                  `${field?.value?.start_date} - ${field?.value?.end_date}`
                )
              ) : field?.data_type === "multi_select_list" ? (
                field?.value?.length === 0 ? (
                  "Not Given"
                ) : (
                  field?.value?.join(", ")
                )
              ) : typeof field?.value === "boolean" &&
                field?.data_type === "toggle" ? (
                field?.value === true ? (
                  "Yes"
                ) : (
                  "No"
                )
              ) : (
                field?.value
              )}
            </p>
          </Tooltip>

          <div
            className="text-xs text-[#C9C9C9] font-sans mb-[10px]"
            style={{
              display:
                field?.data_type === "upload" && field?.value !== "Not Given"
                  ? "none"
                  : "flex",
            }}
          >
            {typeof field?.value === "object" &&
            field?.data_type === "from_year_to_year"
              ? "From year - To year"
              : field?.name}
          </div>
          {index !== props.txnPayLoad?.mergeCategory.length - 1 && (
            <hr style={{ width: "100%", border: "1px solid #1D3A6D" }} />
          )}
        </div>
      ));
    }
    return null;
  };

  const convertToMonthYear = (input) => {
    const [month, year] = input?.split("/");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthName = monthNames[parseInt(month, 10) - 1];
    return `${monthName}/${year}`;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 800);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      {!isMobileView && (
        <div
          className={`beneTxnConfirm_Main ${
            props?.direction === "next"
              ? "container-righttoleft-animate-intrtxn"
              : props?.direction === "previous"
              ? "container-lefttoright-animate-intrtxn"
              : ""
          }`}
        >
          <div className="Internal_divideTxnconfirm">
            <div className="p-[20px] relative bg-[#3A5686] rounded-[20px]">
              <div className=" items-center justify-between">
                <div className="text-[#DADADA] font-semibold text-base">
                  Source Account Details
                </div>
                <div className=" w-[432px] h-[59px]  my-[16px] bg-[#314D7E] flex rounded-[10px] px-[20px] py-[10px]">
                  <div className=" w-1/2 border-r h-[39px] border-[#031F4F]">
                    <p className="w-[164px] font-sans text-[#f9f9f9] text-[14px] font-semibold">
                      {formatAccountNumber(
                        props?.txnPayLoad?.ownerBankAccountNo
                      )}
                    </p>
                    <p className=" font-sans text-[#dadada] text-[12px] font-normal">
                      Account Number
                    </p>
                  </div>
                  <div className=" pl-[20px] ">
                    <div className="source-account-latest-balance">
                      <p
                        className="font-sans  text-[14px] font-semibold owner-Fetch-Balance"
                        style={{
                          color:
                            Number(props?.txnPayLoad?.ownerBankBalance) >= 0
                              ? "#4DDD37"
                              : "#FF5860",
                        }}
                      >
                        ₹{" "}
                        <FormatAmount
                          price={props?.txnPayLoad?.ownerBankBalance}
                        />
                      </p>
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title="Refresh Balance"
                      >
                        <button
                          onClick={(e) =>
                            handleRefresh(e, props?.txnPayLoad?.ownerId)
                          }
                          className={`internal_amount_button ${
                            services?.includes("VAccountBalance") ||
                            services?.includes("Admin")
                              ? "cursor-pointer"
                              : "cursor-not-allowed opacity-50"
                          }`}
                        >
                          {createTransactionBankRefreshLoading &&
                          props?.refreshId === props?.txnPayLoad?.ownerId ? (
                            <BalanceLoader
                              loader={createTransactionBankRefreshLoading}
                              type={"internalTxn"}
                            />
                          ) : !createTransactionBankRefreshLoading &&
                            props?.refreshId === props?.txnPayLoad?.ownerId ? (
                            <BalanceLoader
                              loader={createTransactionBankRefreshLoading}
                              setAccId={props?.setRefreshId}
                              type={"internalTxn"}
                            />
                          ) : (
                            <>
                              {" "}
                              <img
                                className="internal_reload"
                                src={WhiteReload}
                                alt="Img"
                              />
                            </>
                          )}
                        </button>
                      </Tooltip>
                    </div>
                    <div className="internal_new_balance_New">
                      <p className=" text-[12px] text-[#dadada] font-sans font-[400px]">
                        Balance - {props?.txnPayLoad?.ownerLastUpdated}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Inter_settle_main_div_container_img">
                <img
                  src={require("../../../assets/IfscLogo/" +
                    IfscCodeMapper(props?.txnPayLoad.ownerBankLogo))}
                  alt="bank"
                />
              </div>
            </div>

            <div
              className="right_side_select_owner_div_top "
              style={{ marginTop: "20px" }}
            >
              <div className="right_side_select_owner_div_top_img">
                <img src={ConfidentailImage} alt="confidential" />
              </div>
              <div className="right_side_select_owner_div_top_para">
                <h3>Create As Confidential Transaction</h3>
                <p>
                  {props?.isConfidential
                    ? `This is a confidential transaction, this transaction will be visible to only those users who have the feature to view confidential transactions.`
                    : `This is not a confidential transaction, this transaction
will be visible to all users who have the feature to view transactions.`}
                </p>
              </div>
              <div
                className="right_side_select_owner_div_top_button"
                style={{
                  backgroundColor: props?.isConfidential
                    ? "#69B76F"
                    : "#707070",
                  cursor: "default",
                  opacity: "0.4",
                }}
              >
                <button
                  className="right_side_select_owner_div_top_button_inner"
                  style={{
                    transform: props?.isConfidential
                      ? "translateX(18px)"
                      : "translateX(0px)",
                    cursor: "default",
                  }}
                ></button>
              </div>
            </div>

            <div className="Inter_amount items-center mb-5 p-[20px] mt-[20px]  flex flex-col">
              <div className=" flex">
                <span className=" absolute left-8"> ₹</span>

                <FormatAmount price={props.txnPayLoad?.amount} />
              </div>

              <hr
                style={{
                  width: "100%",
                  marginTop: "10px",
                  border: "1px solid #1D3A6D",
                }}
              />
              <div
                className="text-sm confirmleft  pt-2 lowercase first-line:capitalize"
                style={{ textAlign: "left" }}
              >
                {props.txnPayLoad.amountWords}
                <br />
                <span className="text-[#DADADA] text-xs mt-[5px]">
                  In Words
                </span>
              </div>
            </div>

            <div className=" w-[472px] h-auto bg-[#3A5686] rounded-[20px] px-[20px] py-[20px] my-[20px]">
              <div className=" font-sans text-[#dadada] text-[16px] font-semibold">
                Payment Details
              </div>

              <div className=" mt-[20px]">
                <p className="text-sm font-sans text-[#FFFFFF] ">
                  {props?.txnPayLoad?.settleType?.toUpperCase()}
                </p>
                <span className="text-xs font-sans text-[#DADADA]">
                  Payment method
                </span>
              </div>
            </div>
          </div>

          <div className=" mt-[65px] ">
            <img src={arrow_right_blue} className=" w-[20px] h-[20px] " />
          </div>

          <div className="Internal_divideconfirm">
            <div className="w-1/2 h-fit contactright second_side_div_Internal">
              <div
                className="bg-[#29487E] flex flex-col relative rounded-[20px]"
                style={{ width: "inherit", height: "inherit" }}
              >
                <div className="  py-5 h-full  flex flex-col rounded-[20px] bg-[#3A5686] ">
                  <div
                    className="w-full flex flex-col"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className=" items-center justify-between"
                      style={{ width: "fit-content" }}
                    >
                      <div className="text-[#DADADA] font-semibold text-base">
                        {"Destination Account Details"}
                      </div>
                      <div className=" w-[432px] h-[59px] bg-[#314D7E] my-[16px] flex rounded-[10px] px-[20px] py-[10px]">
                        <div className=" w-1/2 border-r h-[39px] border-[#031F4F]">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={formatAccountNumber(
                              props?.txnPayLoad?.bankAccountNo
                            )}
                          >
                            <p className="internal_bene_name">
                              {formatAccountNumber(
                                props?.txnPayLoad?.bankAccountNo
                              )}
                            </p>
                          </Tooltip>

                          <p className=" font-sans text-[#dadada] text-[12px] font-normal">
                            Account number
                          </p>
                        </div>
                        {props?.txnPayLoad?.isDestinationOwnerActive === "Y" &&
                        props?.txnPayLoad?.destinationAccountBalance !==
                          undefined ? (
                          <div className=" pl-[20px]">
                            <div className="source-account-latest-balance">
                              <p
                                className="font-sans  text-[14px] font-[600px] owner-Fetch-Balance"
                                style={{
                                  color:
                                    Number(
                                      props?.txnPayLoad
                                        ?.destinationAccountBalance
                                    ) >= 0
                                      ? "#4DDD37"
                                      : "#FF5860",
                                }}
                              >
                                ₹{" "}
                                {
                                  <FormatAmount
                                    price={
                                      props?.txnPayLoad
                                        ?.destinationAccountBalance
                                    }
                                  />
                                }
                              </p>
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title="Refresh Balance"
                              >
                                <button
                                  onClick={(e) =>
                                    handleRefresh(
                                      e,
                                      props?.txnPayLoad
                                        ?.destinationAcountOwnerId,
                                      "destination"
                                    )
                                  }
                                  className={`internal_amount_button ${
                                    services?.includes("VAccountBalance") ||
                                    services?.includes("Admin")
                                      ? "cursor-pointer"
                                      : "cursor-not-allowed opacity-50"
                                  }`}
                                >
                                  {createTransactionBankRefreshLoading &&
                                  props?.refreshId ===
                                    props?.txnPayLoad
                                      ?.destinationAcountOwnerId ? (
                                    <BalanceLoader
                                      loader={
                                        createTransactionBankRefreshLoading
                                      }
                                      type={"internalTxn"}
                                    />
                                  ) : !createTransactionBankRefreshLoading &&
                                    props?.refreshId ===
                                      props?.txnPayLoad
                                        ?.destinationAcountOwnerId ? (
                                    <BalanceLoader
                                      loader={
                                        createTransactionBankRefreshLoading
                                      }
                                      setAccId={props?.setRefreshId}
                                      type={"internalTxn"}
                                    />
                                  ) : (
                                    <>
                                      {" "}
                                      <img
                                        className="internal_reload"
                                        src={WhiteReload}
                                        alt="Img"
                                      />
                                    </>
                                  )}
                                </button>
                              </Tooltip>
                            </div>

                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={
                                props?.txnPayLoad
                                  ?.destinationAcountOwnerLastUpdated
                              }
                            >
                              <p className="internal_new_balance">
                                Balance -{" "}
                                {
                                  props?.txnPayLoad
                                    ?.destinationAcountOwnerLastUpdated
                                }
                              </p>
                            </Tooltip>
                          </div>
                        ) : (
                          <div className=" pl-[20px]">
                            <p className=" w-[164px] font-sans text-[#f9f9f9] text-[14px] font-[600px]">
                              Redacted
                            </p>
                            <p className=" font-sans text-[#dadada] text-[12px] font-normal">
                              Balance
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="Inter_settle_main_div_container_img_2_New"
                style={{
                  display:
                    props?.txnPayLoad?.bankAccountNo === null ? "none" : "flex",
                }}
              >
                <img
                  src={require("../../../assets/IfscLogo/" +
                    IfscCodeMapper(props?.txnPayLoad?.bankAccountIFSC))}
                  alt="bank"
                />
              </div>
            </div>

            <div className="additional_detail flex flex-col gap-[10px] w-full h-auto my-4 px-5 py-5 rounded-[20px] bg-[#314C7F]">
              <span className="additional_detail">Additional Details</span>
              <div className="detail_info">
                {props.txnPayLoad?.mergeCategory ? (
                  renderAdditionalDetails()
                ) : (
                  <div className="detail_info">
                    {props.txnPayLoad?.mergeCategory ? (
                      renderAdditionalDetails()
                    ) : (
                      <div
                        className="text-left text-white text-base antialiased"
                        style={{
                          marginTop: props.txnPayLoad?.mergeCategory
                            ? "0"
                            : "20px",
                        }}
                      >
                        Additional details are not supported for the selected
                        category.
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* <p className=" font-sans font-[600px] text-[16px] text-[#dadada]">
            Additional Details
          </p>
          <div className=" mt-[20px]">
            <p className="font-sans font-[400px] text-[16px] text-[#f9f9f9]">
              83482384
            </p>
            <p className="font-sans font-[400px] text-[12px] text-[#dadada]">
              Invoice number
            </p>
            <div className=" border-b-[1px] mt-[10px] border-[#102C5D]"></div>
          </div> */}

            {/* <div className="w-1/2 h-fit confirmleft">
          <div className="amount items-center mb-5 flex flex-col">
            ₹ <FormatAmount price={props.txnPayLoad?.amount} />
            <hr
              style={{
                width: "85%",
                marginTop: "10px",
                border: "1px solid #1D3A6D",
              }}
            />
            <div
              className="text-sm confirmleft px-8 pt-2 lowercase first-line:capitalize"
              style={{ textAlign: "left" }}
            >
              {props.txnPayLoad.amountWords}
              <br />
              <span className="text-[#DADADA] text-xs mt-[5px]">In Words</span>
            </div>
          </div>
          <div className="settle_method my-6 h-auto bg-[#314C7F] rounded-[30px]">
            <p className="settle_method_p">Source Account Details</p>

            <div className="selected_set_show flex-col">
              <div className="division">
                <div className="accNumber w-1/2 border-r-2 border-solid border-[#102C5D]">
                  <p className="text-sm font-sans  text-[#FFFFFF] ">
                    {formatAccountNumber(props?.txnPayLoad?.ownerBankAccountNo)}
                  </p>
                  <span className="text-xs font-sans text-[#DADADA]">
                    Account number
                  </span>
                </div>
                <div className="ifsc w-1/2">
                  <div className="ml-5">
                    <p className="text-sm font-sans  text-[#FFFFFF] ">
                      {props?.txnPayLoad?.ownerBankIFSC}
                    </p>
                    <span className="text-xs font-sans text-[#DADADA]">
                      IFSC code
                    </span>
                  </div>
                </div>
              </div>
              <hr className="w-full mt-2.5 border border-solid border-[#1D3A6D]" />
              <div className="division mt-2.5">
                <div className="balance w-1/2 border-r-2 border-solid border-[#102C5D]">
                  <p className="text-sm font-sans font-semibold ">
                    <span className="amount-color">₹ </span>
                    {Number(props?.txnPayLoad?.ownerBankBalance).toFixed(2)}
                  </p>
                  <span className="text-xs font-sans">
                    {`Balance updated ${props?.txnPayLoad?.ownerLastUpdated}`}
                  </span>
                </div>
                <div className="payment_method w-1/2">
                  <div className="ml-5">
                    <p className="text-sm font-sans text-[#FFFFFF] ">
                      {props?.txnPayLoad?.settleType?.toUpperCase()}
                    </p>
                    <span className="text-xs font-sans text-[#DADADA]">
                      Payment method
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between ml-[314px] w-32 h-[41px] ">
              <div className="bg-[#ffffff] rounded-tl-[30px] rounded-br-[30px] flex items-center justify-around w-full h-full mt-9">
                {
                  <img
                    src={require("../../../assets/IfscLogo/" +
                      IfscCodeMapper(props?.txnPayLoad?.ownerBankIFSC))}
                    alt="bank"
                    className="w-[70px] h-[18px]"
                  />
                }
              </div>
            </div>
          </div>
        </div> */}
          </div>
        </div>
      )}
      {isMobileView && (
        <div
          className={`beneTxnConfirm_Main ${
            props?.direction === "next"
              ? "container-righttoleft-animate-intrtxn"
              : props?.direction === "previous"
              ? "container-lefttoright-animate-intrtxn"
              : ""
          }`}
          style={{ padding: "0px 10px" }}
        >
          <div className="Internal_divideTxnconfirm_res">
            <div
              className="right_side_select_owner_div_top flex-col"
              style={{
                marginTop: isMobileView ? "10px" : "20px",
                width: "100%",
                position: "relative",
                height: "auto",
                borderRadius: isMobileView ? "10px" : "20px",
              }}
            >
              <div className="right_side_select_owner_div_top_img_res rounded-tr-[10px] rounded-bl-[10px]">
                <img src={ConfidentailImage} alt="confidential" />
              </div>
              <div className="flex flex-row items-center w-[100%] justify-center">
                <div className="right_side_select_owner_div_top_para">
                  <h3
                    style={{
                      width: "auto",
                      height: "auto",
                      paddingBottom: "5px",
                    }}
                  >
                    Create As Confidential Transaction
                  </h3>
                  <p
                    style={{
                      width: "auto",
                      height: "auto",
                    }}
                  >
                    {props?.isConfidential
                      ? `This is a confidential transaction, this transaction will be visible to only those users who have the feature to view confidential transactions.`
                      : `This is not a confidential transaction, this transaction
will be visible to all users who have the feature to view transactions.`}
                  </p>
                </div>
                <div
                  className="right_side_select_owner_div_top_button"
                  style={{
                    backgroundColor: props?.isConfidential
                      ? "#69B76F"
                      : "#707070",
                    cursor: "default",
                    opacity: "0.4",
                  }}
                >
                  <button
                    className="right_side_select_owner_div_top_button_inner"
                    style={{
                      transform: props?.isConfidential
                        ? "translateX(18px)"
                        : "translateX(0px)",
                      cursor: "default",
                    }}
                  ></button>
                </div>
              </div>
            </div>
            <div
              className="select_owner_box p-[20px] relative"
              style={{ width: "100%", borderRadius: "10px" }}
            >
              <div className=" items-center justify-between">
                <div className="text-[#DADADA] font-semibold text-base">
                  Source Account Details
                </div>
                <div className=" w-[432px] h-[59px]  my-[16px] bg-[#314D7E] flex rounded-[10px] px-[20px] py-[10px]">
                  <div className=" w-1/2 border-r h-[39px] border-[#031F4F]">
                    <p className="w-[164px] font-sans text-[#f9f9f9] text-[14px] font-[600px]">
                      {props?.txnPayLoad?.ownerBankAccountNo.substring(0, 4) +
                        " •••• " +
                        props?.txnPayLoad?.ownerBankAccountNo.substring(
                          props?.txnPayLoad?.ownerBankAccountNo.length - 4
                        )}
                    </p>
                    <p className=" font-sans text-[#dadada] text-[12px] font-normal">
                      Account Number
                    </p>
                  </div>
                  <div className=" pl-[10px] ">
                    <div className="source-account-latest-balance">
                      <p
                        className="font-sans  text-[14px] font-semibold owner-Fetch-Balance"
                        style={{
                          color:
                            Number(props?.txnPayLoad?.ownerBankBalance) >= 0
                              ? "#4DDD37"
                              : "#FF5860",
                        }}
                      >
                        ₹{" "}
                        <FormatAmount
                          price={props?.txnPayLoad?.ownerBankBalance}
                        />
                      </p>
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title="Refresh Balance"
                      >
                        <button
                          onClick={(e) =>
                            handleRefresh(e, props?.txnPayLoad?.ownerId)
                          }
                          className={`internal_amount_button ${
                            services?.includes("VAccountBalance") ||
                            services?.includes("Admin")
                              ? "cursor-pointer"
                              : "cursor-not-allowed opacity-50"
                          }`}
                        >
                          {createTransactionBankRefreshLoading &&
                          props?.refreshId === props?.txnPayLoad?.ownerId ? (
                            <BalanceLoader
                              loader={createTransactionBankRefreshLoading}
                              type={"internalTxn"}
                            />
                          ) : !createTransactionBankRefreshLoading &&
                            props?.refreshId === props?.txnPayLoad?.ownerId ? (
                            <BalanceLoader
                              loader={createTransactionBankRefreshLoading}
                              setAccId={props?.setRefreshId}
                              type={"internalTxn"}
                            />
                          ) : (
                            <>
                              {" "}
                              <img
                                className="internal_reload"
                                src={WhiteReload}
                                alt="Img"
                              />
                              {/* <p className="Refresh">Refresh</p> */}
                            </>
                          )}
                        </button>
                      </Tooltip>
                    </div>
                    {/* <div className="internal_new_balance_New">
                      <p className=" text-[12px] text-[#dadada] font-sans font-[400px]">
                        Balance - {props?.txnPayLoad?.ownerLastUpdated}
                      </p>
                    </div> */}
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={props?.txnPayLoad?.ownerLastUpdated}
                    >
                      <p className="internal_new_balance">
                        Balance - {props?.txnPayLoad?.ownerLastUpdated}
                      </p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div
                className="Inter_settle_main_div_container_img"
                style={{ borderRadius: "10px 0px" }}
              >
                <img
                  src={require("../../../assets/IfscLogo/" +
                    IfscCodeMapper(props?.txnPayLoad.ownerBankLogo))}
                  alt="bank"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-center rotate-90 w-[100%] my-6">
            <img src={arrow_right_blue} className=" w-[20px] h-[20px] " />
          </div>

          <div className="Internal_divideconfirm_res">
            <div
              className="w-1/2 h-fit contactright second_side_div_Internal"
              style={{ width: "100%" }}
            >
              <div
                className="bg-[#29487E] flex flex-col relative rounded-[20px]"
                style={{ width: "inherit", height: "inherit" }}
              >
                <div className=" w-[432px] py-5 h-full  flex flex-col rounded-[10px] bg-[#3A5686] ">
                  <div
                    className="w-full flex flex-col"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="w-[100%] px-[20px]">
                      <div className="text-[#DADADA] font-semibold text-base">
                        {"Destination Account Details"}
                      </div>
                      <div className="  bg-[#314D7E] my-[16px] flex rounded-[10px] px-[20px] py-[10px]">
                        <div className=" w-1/2 border-r h-[39px] border-[#031F4F]">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={formatAccountNumber(
                              props?.txnPayLoad?.bankAccountNo
                            )}
                          >
                            <p className="internal_bene_name">
                              {props?.txnPayLoad?.bankAccountNo.substring(
                                0,
                                4
                              ) +
                                " •••• " +
                                props?.txnPayLoad?.bankAccountNo.substring(
                                  props?.txnPayLoad?.bankAccountNo.length - 4
                                )}
                            </p>
                          </Tooltip>

                          <p className=" font-sans text-[#dadada] text-[12px] font-normal">
                            Account number
                          </p>
                        </div>
                        {props?.txnPayLoad?.isDestinationOwnerActive === "Y" &&
                        props?.txnPayLoad?.destinationAccountBalance !==
                          undefined ? (
                          <div className=" pl-[20px]">
                            <div className="source-account-latest-balance">
                              <p
                                className="font-sans  text-[14px] font-semibold owner-Fetch-Balance"
                                style={{
                                  color:
                                    Number(
                                      props?.txnPayLoad
                                        ?.destinationAccountBalance
                                    ) >= 0
                                      ? "#4DDD37"
                                      : "#FF5860",
                                }}
                              >
                                ₹{" "}
                                {
                                  <FormatAmount
                                    price={
                                      props?.txnPayLoad
                                        ?.destinationAccountBalance
                                    }
                                  />
                                }
                              </p>
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title="Refresh Balance"
                              >
                                <button
                                  onClick={(e) =>
                                    handleRefresh(
                                      e,
                                      props?.txnPayLoad
                                        ?.destinationAcountOwnerId,
                                      "destination"
                                    )
                                  }
                                  className={`internal_amount_button ${
                                    services?.includes("VAccountBalance") ||
                                    services?.includes("Admin")
                                      ? "cursor-pointer"
                                      : "cursor-not-allowed opacity-50"
                                  }`}
                                >
                                  {createTransactionBankRefreshLoading &&
                                  props?.refreshId ===
                                    props?.txnPayLoad
                                      ?.destinationAcountOwnerId ? (
                                    <BalanceLoader
                                      loader={
                                        createTransactionBankRefreshLoading
                                      }
                                      type={"internalTxn"}
                                    />
                                  ) : !createTransactionBankRefreshLoading &&
                                    props?.refreshId ===
                                      props?.txnPayLoad
                                        ?.destinationAcountOwnerId ? (
                                    <BalanceLoader
                                      loader={
                                        createTransactionBankRefreshLoading
                                      }
                                      setAccId={props?.setRefreshId}
                                      type={"internalTxn"}
                                    />
                                  ) : (
                                    <>
                                      {" "}
                                      <img
                                        className="internal_reload"
                                        src={WhiteReload}
                                        alt="Img"
                                      />
                                    </>
                                  )}
                                </button>
                              </Tooltip>
                            </div>

                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={
                                props?.txnPayLoad
                                  ?.destinationAcountOwnerLastUpdated
                              }
                            >
                              <p className="internal_new_balance">
                                Balance -{" "}
                                {
                                  props?.txnPayLoad
                                    ?.destinationAcountOwnerLastUpdated
                                }
                              </p>
                            </Tooltip>
                          </div>
                        ) : (
                          <div className=" pl-[20px]">
                            <p className=" w-[110px] font-sans text-[#f9f9f9] text-[14px] font-semibold">
                              Redacted
                            </p>
                            <p className=" font-sans text-[#dadada] text-[12px] font-normal">
                              Balance
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="Inter_settle_main_div_container_img_2_New"
                style={{
                  display:
                    props?.txnPayLoad?.bankAccountNo === null ? "none" : "flex",
                  borderRadius: "10px 0px",
                }}
              >
                <img
                  src={require("../../../assets/IfscLogo/" +
                    IfscCodeMapper(props?.txnPayLoad?.bankAccountIFSC))}
                  alt="bank"
                />
              </div>
            </div>

            <div
              className="Inter_amount items-center mb-5 p-[20px] mt-[20px]  flex flex-col"
              style={{ borderRadius: "10px" }}
            >
              <div className=" flex">
                <span className=" absolute left-8"> ₹</span>

                <FormatAmount price={props.txnPayLoad?.amount} />
              </div>

              <hr
                style={{
                  width: "100%",
                  marginTop: "10px",
                  border: "1px solid #1D3A6D",
                }}
              />
              <div
                className="text-sm confirmleft  pt-2 lowercase first-line:capitalize"
                style={{ textAlign: "left" }}
              >
                {props.txnPayLoad.amountWords}
                <br />
                <span className="text-[#DADADA] text-xs mt-[5px]">
                  In Words
                </span>
              </div>
            </div>

            <div className="h-auto bg-[#3A5686] rounded-[10px] px-[20px] py-[18px] my-[20px]">
              <div className="font-semibold font-sans text-[#dadada] text-[16px] font-[600px]">
                Payment Details
              </div>

              <div className=" mt-[20px]">
                <p className="text-sm font-sans text-[#FFFFFF] ">
                  {props?.txnPayLoad?.settleType?.toUpperCase()}
                </p>
                <span className="text-xs font-sans text-[#DADADA]">
                  Payment method
                </span>
              </div>
            </div>

            <div className="additional_detail w-full h-auto my-4 px-6 py-5 rounded-[10px] bg-[#314C7F]">
              <span className="additional_detail">Additional Details</span>
              <div className="detail_info">
                {props.txnPayLoad?.mergeCategory ? (
                  renderAdditionalDetails()
                ) : (
                  <div className="detail_info">
                    {props.txnPayLoad?.mergeCategory ? (
                      renderAdditionalDetails()
                    ) : (
                      <div
                        className="text-left text-white text-base antialiased"
                        style={{
                          marginTop: props.txnPayLoad?.mergeCategory
                            ? "0"
                            : "20px",
                        }}
                      >
                        Additional details are not supported for the selected
                        category.
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* <p className=" font-sans font-[600px] text-[16px] text-[#dadada]">
            Additional Details
          </p>
          <div className=" mt-[20px]">
            <p className="font-sans font-[400px] text-[16px] text-[#f9f9f9]">
              83482384
            </p>
            <p className="font-sans font-[400px] text-[12px] text-[#dadada]">
              Invoice number
            </p>
            <div className=" border-b-[1px] mt-[10px] border-[#102C5D]"></div>
          </div> */}

            {/* <div className="w-1/2 h-fit confirmleft">
          <div className="amount items-center mb-5 flex flex-col">
            ₹ <FormatAmount price={props.txnPayLoad?.amount} />
            <hr
              style={{
                width: "85%",
                marginTop: "10px",
                border: "1px solid #1D3A6D",
              }}
            />
            <div
              className="text-sm confirmleft px-8 pt-2 lowercase first-line:capitalize"
              style={{ textAlign: "left" }}
            >
              {props.txnPayLoad.amountWords}
              <br />
              <span className="text-[#DADADA] text-xs mt-[5px]">In Words</span>
            </div>
          </div>
          <div className="settle_method my-6 h-auto bg-[#314C7F] rounded-[30px]">
            <p className="settle_method_p">Source Account Details</p>

            <div className="selected_set_show flex-col">
              <div className="division">
                <div className="accNumber w-1/2 border-r-2 border-solid border-[#102C5D]">
                  <p className="text-sm font-sans  text-[#FFFFFF] ">
                    {formatAccountNumber(props?.txnPayLoad?.ownerBankAccountNo)}
                  </p>
                  <span className="text-xs font-sans text-[#DADADA]">
                    Account number
                  </span>
                </div>
                <div className="ifsc w-1/2">
                  <div className="ml-5">
                    <p className="text-sm font-sans  text-[#FFFFFF] ">
                      {props?.txnPayLoad?.ownerBankIFSC}
                    </p>
                    <span className="text-xs font-sans text-[#DADADA]">
                      IFSC code
                    </span>
                  </div>
                </div>
              </div>
              <hr className="w-full mt-2.5 border border-solid border-[#1D3A6D]" />
              <div className="division mt-2.5">
                <div className="balance w-1/2 border-r-2 border-solid border-[#102C5D]">
                  <p className="text-sm font-sans font-semibold ">
                    <span className="amount-color">₹ </span>
                    {Number(props?.txnPayLoad?.ownerBankBalance).toFixed(2)}
                  </p>
                  <span className="text-xs font-sans">
                    {`Balance updated ${props?.txnPayLoad?.ownerLastUpdated}`}
                  </span>
                </div>
                <div className="payment_method w-1/2">
                  <div className="ml-5">
                    <p className="text-sm font-sans text-[#FFFFFF] ">
                      {props?.txnPayLoad?.settleType?.toUpperCase()}
                    </p>
                    <span className="text-xs font-sans text-[#DADADA]">
                      Payment method
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between ml-[314px] w-32 h-[41px] ">
              <div className="bg-[#ffffff] rounded-tl-[30px] rounded-br-[30px] flex items-center justify-around w-full h-full mt-9">
                {
                  <img
                    src={require("../../../assets/IfscLogo/" +
                      IfscCodeMapper(props?.txnPayLoad?.ownerBankIFSC))}
                    alt="bank"
                    className="w-[70px] h-[18px]"
                  />
                }
              </div>
            </div>
          </div>
        </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default BeneTxnConfirm;
