import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { toast_position } from "../../redux/features/Settings/Authorization";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/features/refreshToken/refreshToken";
import logoutWhiteLogo from "../../assets/LogoutIcons/logout_white.svg";
import settingIcon from "../../assets/SettingIcons/settings_white.svg";
import profileWhite from "../../assets/HomeDashboard/profileWhite.png";
import leftIcon from "../../assets/ArrowsIcons/ChevronLeftWhite.svg";
import { Tooltip, tooltipClasses } from "@mui/material";
import {
  setMultipleEntity,
  setShowReloginModalFalse,
} from "../../redux/features/login/login";

function LogoutProfileResponsive() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.refreshToken.data);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);

  const showToast = () => {
    toast.info("Feature coming soon for mobile!", toast_position);
  };
  const HomeDash = () => {
    navigate("/home");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 800);
      if (window.innerWidth >= 800) {
        navigate("/home");
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      {isMobileView && (
        <div>
          <div className="flex flex-col h-[260px]">
            <div className=" flex items-center h-[80px] w-full  bg-[#314D7E]">
              <img
                src={leftIcon}
                className=" ml-5 w-[20px] h-[20px] cursor-pointer"
                onClick={HomeDash}
              />
              <p className=" text-[#fafafa] ml-[20px]"> Profile </p>
            </div>
            <div className="w-full mx-auto h-[70px] pt-[40px] mb-8 flex justify-center items-center">
              <div className="w-[65px]  h-[65px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  width="100%"
                  viewBox="0 0 512 512"
                >
                  <defs>
                    <linearGradient
                      id="linear-gradient"
                      y1="-38.449"
                      x2="1"
                      y2="-38.449"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stop-color="#506994" />
                      <stop offset="1" stop-color="#506994" />
                    </linearGradient>
                    <clipPath id="clip-profile_1">
                      <rect width="512" height="512" />
                    </clipPath>
                  </defs>
                  <g
                    id="profile_1"
                    data-name="profile – 1"
                    clip-path="url(#clip-profile_1)"
                  >
                    <g
                      id="_1177568"
                      data-name="1177568"
                      transform="translate(10 20)"
                    >
                      <path
                        id="Path_674"
                        data-name="Path 674"
                        d="M492,246c0,135.864-110.136,246-246,246S0,381.864,0,246,110.136,0,246,0,492,110.136,492,246Zm0,0"
                        fill="url(#linear-gradient)"
                      />
                      <path
                        id="Path_675"
                        data-name="Path 675"
                        d="M305.441,241.124a93.162,93.162,0,1,0-111.859,0C133.081,263.834,89.9,322.271,89.9,390.61H118.73c0-72.115,58.67-130.782,130.786-130.782S380.3,318.495,380.3,390.61h28.828c0-68.339-43.178-126.776-103.683-149.486ZM185.178,166.663A64.334,64.334,0,1,1,249.512,231,64.4,64.4,0,0,1,185.178,166.663Zm0,0"
                        transform="translate(-3.512 -2.871)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>

            <div
              className=" mx-4 px-2 mb-2  "
              style={{
                font: "normal normal normal 12px/17px Open Sans",
                color: "#dadada",
                alignSelf: "center",
                height: "17px",
              }}
            >
              {data?.employees?.user_id || "User ID"}
            </div>
            <div
              className="mx-4 px-2 mb-3 w-[255px] flex justify-center"
              style={{
                font: "normal normal 600 14px/19px Open Sans",
                color: "#fff",
                alignSelf: "center",
                height: "19px",
              }}
            >
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textAlign:
                    data?.employees?.first_name.length < 10 ? "center" : "left",
                }}
              >
                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                      },
                    },
                  }}
                  enterTouchDelay={0}
                  title={
                    data?.employees?.first_name +
                    " " +
                    data?.employees?.last_name
                  }
                  placement="bottom-start"
                >
                  {data?.employees?.first_name +
                    " " +
                    data?.employees?.last_name || "User Name"}
                </Tooltip>
              </div>
            </div>

            <div
              className="w-full flex justify-center items-center p-5"
              style={{
                font: "normal normal normal 14px/19px Open Sans",
                color: "#DADADA",
                alignSelf: "center",
                height: "30px",
                backgroundColor: "#314D7E",
              }}
            >
              {data?.user?.user_id || data?.entity?.entity_id || "Entity ID"}
              &nbsp; | &nbsp;
              {data?.entity?.entity_name.length > 18 ? (
                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                      },
                    },
                  }}
                  enterTouchDelay={0}
                  title={data?.entity?.entity_name}
                >
                  <span
                    style={{
                      maxWidth: "170px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      display: "inline-block",
                    }}
                  >
                    {data?.entity?.entity_name || "Entity Name"}
                  </span>
                </Tooltip>
              ) : (
                <span>{data?.entity?.entity_name || "Entity Name"}</span>
              )}
            </div>
          </div>
          <div
            style={{
              height: "96px",
              width: "100%",
              background: "#506994 0% 0% no-repeat padding-box",
              display: "flex",
            }}
          >
            <div
              onClick={() => {
                navigate("/settings/Profile");
                // setOpenProfile(false);
              }}
              className="hover:opacity-60"
              style={{
                height: "80px",
                width: "33%",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "10px",
              }}
            >
              <img
                src={profileWhite}
                alt="My Profile"
                style={{
                  width: "40px",
                  height: "40px",
                  marginBottom: "10px",
                }}
              />
              <p
                style={{
                  height: "14px",
                  font: "normal normal normal 10px/14px Open Sans",
                  color: "#fff",
                }}
              >
                My Profile
              </p>
            </div>
            <div
              style={{
                height: "60px",
                borderRight: "2px solid #1d3a6d",
                marginTop: "15px",
              }}
            ></div>
            <div
              className="hover:opacity-60"
              onClick={() => {
                // showToast();
                navigate("/settings");
                // setOpenProfile(false);
              }}
              style={{
                height: "80px",
                width: "33%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                paddingTop: "10px",
              }}
            >
              <img
                src={settingIcon}
                alt="Settings"
                style={{
                  width: "40px",
                  height: "40px",

                  marginBottom: "10px",
                }}
              />
              <p
                style={{
                  height: "14px",
                  font: "normal normal normal 10px/14px Open Sans",
                  color: "#fff",
                }}
              >
                Settings
              </p>
            </div>
            <div
              style={{
                height: "60px",
                borderRight: "2px solid #1d3a6d",
                marginTop: "15px",
              }}
            ></div>
            <div
              className="hover:opacity-60"
              onClick={() => {
                dispatch(setMultipleEntity());
                dispatch(setShowReloginModalFalse());
                dispatch(logout());
                navigate("/login");
              }}
              style={{
                height: "80px",
                width: "33%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                paddingTop: "10px",
              }}
            >
              <img
                src={logoutWhiteLogo}
                alt="logout"
                style={{
                  width: "40px",
                  height: "40px",
                  marginBottom: "10px",
                }}
              />
              <p
                style={{
                  height: "14px",
                  font: "normal normal normal 10px/14px Open Sans",
                  color: "#fff",
                }}
              >
                Logout
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LogoutProfileResponsive;
