import React from "react";
import "./index.css";
import InternalAccountViewAll from "../../../../containers/Accounts/InternalAccount/InternalAccountViewAll";
import BeneAccViewAll from "../../../../containers/Accounts/BeneficiaryAccount/BeneAccViewAll";
const BeneTxnAccountModal = ({
  onClose,
  onAccountSelect,
  type,
  associateAccount,
  panNumber,
  IFSC,
  accountNumber,
  setOpenInternalAssociate,
  setOpenBeneAssociate,
  beneAccountId,
  accountType,
  flow,
  setTxnPayLoad,
  txnPayLoad,
  setIsNeft,
  setIsImps,
  setIsRtgs,
  BeneTxnAssocAcct,
  setAssociateModalOpen,
  associateTxn,
}) => {
  const handleButtonClick = (dataItem) => {
    if (type === "viewAll") {
      onAccountSelect(
        dataItem.name.trim(),
        dataItem.bank_acc_no.trim(),
        dataItem.ifsc_code.trim(),
        dataItem.id
      );
    } else {
      onAccountSelect(
        dataItem?.name.trim(),
        dataItem?.bank_acc_no.trim(),
        dataItem?.ifsc_code.trim(),
        dataItem?.bank_name,
        dataItem?.category,
        dataItem?.pan_no,
        dataItem?.id,
        dataItem?.internal_account
      );

      if (associateTxn === "associateTxn") {
        setOpenInternalAssociate(false);
      }

      if (BeneTxnAssocAcct) {
        setAssociateModalOpen(false);
      }
    }
  };

  return (
    <>
      <div
        className="fixed top-0 left-0 w-screen h-screen z-[9998] AccviewallMobile"
        style={{ backgroundColor: "#000000", opacity: "0.8" }}
      ></div>
      <div className="main_pop_div">
        {flow === "beneTxn" ? (
          <BeneAccViewAll
            typepop={"popup"}
            onClosepop={onClose}
            handleButtonClick={handleButtonClick}
            associateAccount={associateAccount}
            associatepanNumber={panNumber}
            associateIFSC={IFSC}
            associateaccountNumber={accountNumber}
            setOpenInternalAssociate={setOpenInternalAssociate}
            setOpenBeneAssociate={setOpenBeneAssociate}
            beneAccountId={beneAccountId}
            BeneTxnAssocAcct={BeneTxnAssocAcct}
            accountType={accountType}
            flow={flow}
            setTxnPayLoad={setTxnPayLoad}
            txnPayLoad={txnPayLoad}
            setIsNeft={setIsNeft}
            setIsImps={setIsImps}
            setIsRtgs={setIsRtgs}
            setAssociateModalOpen={setAssociateModalOpen}
            associateTxn={associateTxn}
          />
        ) : (
          <InternalAccountViewAll
            typepop={"popup"}
            onClosepop={onClose}
            handleButtonClick={handleButtonClick}
            associateAccount={associateAccount}
            associatepanNumber={panNumber}
            associateIFSC={IFSC}
            associateaccountNumber={accountNumber}
            setOpenInternalAssociate={setOpenInternalAssociate}
            setOpenBeneAssociate={setOpenBeneAssociate}
            beneAccountId={beneAccountId}
            BeneTxnAssocAcct={BeneTxnAssocAcct}
            accountType={accountType}
            flow={flow}
            setTxnPayLoad={setTxnPayLoad}
            txnPayLoad={txnPayLoad}
            setIsNeft={setIsNeft}
            setIsImps={setIsImps}
            setIsRtgs={setIsRtgs}
            setAssociateModalOpen={setAssociateModalOpen}
            associateTxn={associateTxn}
          />
        )}
      </div>
    </>
  );
};

export default BeneTxnAccountModal;
