import React, { useRef, useState, useEffect } from "react";
import BeneEnterBankDetails from "../../../../components/Accounts/Common/BeneEnterBankDetail";
import "./index.css";
import { Button } from "@mui/material";
import addstatus from "../../../../assets/ErrorIcons/error_with_white_circle.svg";
import { useSelector } from "react-redux";
import BeneficiaryModal from "../../../../components/BeneficiaryModal";

import { useNavigate } from "react-router-dom";
import ViewDetails from "../../../../assets/GeneralIcons/detail_page_edit detail.svg";
import InfoDetails from "../../../../assets/InfoIcons/Info.svg";
import selected from "../../../../assets/CheckmarksIcons/selected_with_green_circle.svg";
import not_selected from "../../../../assets/CheckmarksIcons/not_selected_with_grey_circle.svg";
import unarchiveImg from "../../../../assets/ArchieveUnArchieveIcons/unarchive.svg";
import archiveImg from "../../../../assets/ArchieveUnArchieveIcons/archive.svg";

const BeneBankDetails = ({
  setBankLookupNext,
  bankLookupNext,
  nextButtonEnabled,
  BenEnableNextButton,
  payload,
  setPayload,
  activeStep,
  panNumber,
  bankIsAchived,
  setBankIsArchived,
  bankIsApproved,
  setBankIsApproved,
  setBankIsDennied,
  bankIsDennied,
  bankIsPending,
  setBankIsPending,
  setBankShutViewAll,
  bankShutViewAll,
  setBankDisableCreateNext,
  bankAccountType,
  setBankAccountType,
  active,
  setActive,
  setBankNewMessage,
  hideBankBlock,
  setBankHideBlock,
  setBankHideCreate,
  isButtonDisabled,
  bankUnachivedOpenPop,
  setBankUnachivedOpenPop,
  bankAchivedOpenPop,
  setBankAchivedOpenPop,
  setBankUserInfo,
}) => {
  const benebankLookData = useSelector(
    (state) => state.beneAllVerification.beneLookData
  );
  const beneLookLoading = useSelector(
    (state) => state.beneAllVerification.beneLookLoading
  );
  const isBankError = useSelector(
    (state) => state.beneAllVerification.isBankError
  );

  const { verifyBankisLoading, verifyBank } = useSelector(
    (state) => state?.beneAllVerification
  );

  const [virtualAccount, setVirtualAccount] = useState(false);
  const [isBankLinkedAccount, setIsBankLinkedAccount] = useState(false);
  const [isCreateAccount, setIsCreateAccount] = useState(false);
  const [showAccInfo, setShowAccInfo] = useState(false);
  const [showNoAccInfo, setShowNoAccInfo] = useState(false);
  const navigate = useNavigate();
  const associatedAccountRef = useRef(null);
  const noAssociatedAccountRef = useRef(null);
  const [freshAccount, setFreshAccount] = useState(true);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  let haveAcheived = benebankLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "archived";
  });
  let haveApproved = benebankLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "approved";
  });
  let haveDennied = benebankLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "denied";
  });
  let havePending = benebankLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "pending";
  });
  let haveBlock = benebankLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "blocked";
  });
  let haveRejected = benebankLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "rejected";
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1024);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (benebankLookData?.status && activeStep === 1) {
      BenEnableNextButton(true);
    }
  }, [benebankLookData]);

  const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  const isIFSCValid =
    payload?.ifsc_code?.length === 11 && ifscRegex.test(payload?.ifsc_code);
  useEffect(() => {
    const foundAccount = benebankLookData?.beneficiary_accounts?.find((val) => {
      if (
        payload?.panNumber === val?.pan_no &&
        payload?.bank_account === val?.bank_acc_no &&
        payload?.ifsc_code === val?.ifsc_code
      ) {
        setIsBankLinkedAccount(true);
        setIsCreateAccount(true);

        return true;
      }
      return false;
    });

    if (!foundAccount) {
      setIsBankLinkedAccount(false);
      setIsCreateAccount(false);
    }
  }, [benebankLookData, payload]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      event.stopPropagation();
      if (
        associatedAccountRef.current &&
        !associatedAccountRef.current.contains(event.target)
      ) {
        setShowAccInfo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [associatedAccountRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      event.stopPropagation();
      if (
        noAssociatedAccountRef.current &&
        !noAssociatedAccountRef.current.contains(event.target)
      ) {
        setShowNoAccInfo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [noAssociatedAccountRef]);

  useEffect(() => {
    if (!beneLookLoading) {
      setTimeout(() => {
        if (isIFSCValid && !isBankError) {
          if (benebankLookData?.status) {
            setBankLookupNext(false);
          } else if (!verifyBankisLoading && verifyBank?.status) {
            setBankLookupNext(true);
            setFreshAccount(false);
          }
        }
      }, 0);
    }
    setFreshAccount(true);
  }, [
    beneLookLoading,
    benebankLookData,
    isBankError,
    isIFSCValid,
    virtualAccount,
    verifyBankisLoading,
    verifyBank,
  ]);

  return (
    <>
      {!isMobileView && (
        <div className="beneBank_Main">
          {bankAchivedOpenPop && (
            <BeneficiaryModal
              close={setBankAchivedOpenPop}
              id={bankAccountType.id}
              type={"archived"}
              setBankAccountType={setBankAccountType}
              bankAccountType={bankAccountType}
              message={"Are you sure you want to archive this account?"}
              note={"This account will become inactive."}
              panNumber={panNumber}
              setBankIsArchived={setBankIsArchived}
              payload={payload}
              setBankShutViewAll={setBankShutViewAll}
              createFlow={"bank"}
              title={"Archive account"}
              setIsBankLinkedAccount={setIsBankLinkedAccount}
              isBankLinkedAccount={isBankLinkedAccount}
            />
          )}
          {bankUnachivedOpenPop && (
            <BeneficiaryModal
              close={setBankUnachivedOpenPop}
              id={bankAccountType.id}
              type={"unarchived"}
              setBankAccountType={setBankAccountType}
              bankAccountType={bankAccountType}
              message={"Are you sure you want to unarchive this account?"}
              note={"This account will become active."}
              panNumber={panNumber}
              setBankIsArchived={setBankIsArchived}
              payload={payload}
              setBankShutViewAll={setBankShutViewAll}
              createFlow={"bank"}
              title={"Unarchive account"}
              setIsBankLinkedAccount={setIsBankLinkedAccount}
              isBankLinkedAccount={isBankLinkedAccount}
            />
          )}
          <div className="dividebank">
            {hideBankBlock && !isBankError && !virtualAccount ? (
              <div className="bene_block_message_text">
                <img src={addstatus} alt="info" />
                <p>To unblock this account, please contact customer support</p>
              </div>
            ) : isBankLinkedAccount && isIFSCValid ? (
              <div className="bank_new_message_text_block_nonarchive">
                <img src={addstatus} alt="info" />
                <p>
                  Looks like this bank account is already linked to your PAN.
                  Please update either the bank details or your PAN to proceed.
                </p>
              </div>
            ) : null}
            {virtualAccount && isIFSCValid ? (
              <div className="bene_new_message_text_crt">
                <img src={addstatus} alt="info" />
                <p>
                  <span>Virtual Account Detected</span> <br />
                  <ul className="list-disc px-2 py-2">
                    <li>
                      Account could not be verified as it is virtual account
                    </li>
                    <li style={{ paddingTop: "10px" }}>
                      Please self verify and proceed with caution
                    </li>
                  </ul>
                </p>
              </div>
            ) : null}
            <div className="w-1/2 h-fit bankright">
              <BeneEnterBankDetails
                payload={payload}
                setPayload={setPayload}
                BenEnableNextButton={BenEnableNextButton}
                activeStep={activeStep}
                title="Beneficiary's Bank Details"
                setVirtualAccount={setVirtualAccount}
                setBankUserInfo={setBankUserInfo}
                setShowAccInfo={setShowAccInfo}
                bankLookupNext={bankLookupNext}
                setBankLookupNext={setBankLookupNext}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="bank_new_crt_acc_bottom"
              style={{
                display:
                  benebankLookData?.status && isIFSCValid && !isBankError
                    ? ""
                    : "none",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  flex: "70%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <div className="crt-Confirm-info-new">
                  Confirm to continue account creation
                </div>
                <div className="crt-Confirm-sub-info-new">
                  Confirm and click next to proceed. Creating a new account will
                  transform this account into a joint account and adds it to the
                  active accounts for the provided bank details.
                </div>
              </div>
              <div style={{ flex: "30%" }}>
                <Button
                  className={`${
                    !isButtonDisabled && !isCreateAccount
                      ? "bank_new_create_btn"
                      : "bank_new_create_btn_disabled"
                  }`}
                  style={{
                    color: bankLookupNext ? "#fefefe" : "#FFFFFF",
                    background: bankLookupNext
                      ? "#7185a8 0% 0% no-repeat padding-box"
                      : "#1D3A6D 0% 0% no-repeat padding-box",
                    borderRadius: "10px",
                    cursor:
                      !isButtonDisabled && !isCreateAccount
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() => {
                    if (!isButtonDisabled && !isCreateAccount) {
                      setIsCreateAccount(false);
                      setBankIsApproved(false);
                      setBankLookupNext(true);
                      setBankIsArchived(false);
                      setBankIsDennied(false);
                      setBankIsPending(false);
                      setBankHideBlock(false);
                      setBankDisableCreateNext(false);
                      if (bankLookupNext) {
                        BenEnableNextButton(true);
                      } else {
                        BenEnableNextButton(false);
                      }
                      if (bankLookupNext) {
                        setBankNewMessage(true);
                        setBankHideCreate(false);
                        setBankLookupNext(false);
                      } else {
                        setBankHideCreate(true);
                        setBankNewMessage(false);
                      }
                      setBankAccountType({ type: "", id: "" });
                      setBankDisableCreateNext(true);
                    } else {
                      return;
                    }
                  }}
                >
                  <span style={{ paddingRight: "2px" }}>
                    <img
                      src={nextButtonEnabled ? not_selected : selected}
                      alt="btn-icon"
                    />
                  </span>
                  <span
                    className="bank-confirm-btn-style"
                    style={{ paddingLeft: "2px" }}
                  >
                    Confirm
                  </span>
                </Button>
              </div>
            </div>
            {!freshAccount && (
              <div
                className="dividebank bank_Details"
                style={{
                  marginBottom: "100px",
                  display:
                    !beneLookLoading &&
                    benebankLookData?.status === undefined &&
                    !isBankError &&
                    isIFSCValid &&
                    !virtualAccount
                      ? ""
                      : "none",
                  height: "auto",
                }}
              >
                <div className="bank-no-assacc-found">
                  <p className="bene_top_account-new">
                    Associated Accounts{""}
                    <img
                      ref={noAssociatedAccountRef}
                      src={InfoDetails}
                      alt="info"
                      onClick={() => {
                        setShowNoAccInfo(!showNoAccInfo);
                      }}
                    />
                    {showNoAccInfo ? (
                      <>
                        <div className="associate-acc-info-container">
                          <p className="associate-acc-info">
                            Associated accounts include all active (approved,
                            pending) and inactive (rejected, archived, blocked)
                            accounts linked to the provided Bank details. Click
                            on the accounts to manage them, or create a new
                            account to add it to the active ones.
                          </p>
                        </div>
                      </>
                    ) : null}
                  </p>
                  <div className="bank_hidden_accounts">
                    <p>
                      <span>No associated accounts found</span> <br /> There are
                      no existing accounts that are associated with the bank
                      details provided. You can proceed to create a new account.
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div
              className="dividebank bank_Details"
              style={{
                visibility:
                  benebankLookData?.status && isIFSCValid && !isBankError
                    ? "visible"
                    : "hidden",
                height:
                  bankIsAchived ||
                  ((bankIsApproved || bankIsDennied || bankIsPending) &&
                    bankShutViewAll &&
                    isIFSCValid)
                    ? "auto"
                    : "390px",
              }}
            >
              <div className="bank_ass_top">
                <p className="bene_top_account-new">
                  Associated Accounts{""}
                  <img
                    ref={associatedAccountRef}
                    src={InfoDetails}
                    alt="info"
                    onClick={() => {
                      setShowAccInfo(!showAccInfo);
                    }}
                  />
                  {showAccInfo ? (
                    <>
                      <div className="associate-acc-info-container">
                        <p className="associate-acc-info">
                          Associated accounts include all active (approved,
                          pending) and inactive (rejected, archived, blocked)
                          accounts linked to the provided Bank details. Click on
                          the accounts to manage them, or create a new account
                          to add it to the active ones.
                        </p>
                      </div>
                    </>
                  ) : null}
                </p>
                <p className="bene_top_con-new">
                  The following existing account is associated with the bank
                  provided.
                </p>
                <div className="bene_swap_butt">
                  <Button
                    className="bene_swap_butt_1-new"
                    onClick={() => {
                      setActive(false);
                      setBankIsDennied(false);
                      setBankIsApproved(false);
                      setBankIsArchived(false);
                      setBankIsPending(false);
                      setBankAccountType({
                        type: "",
                        id: "",
                      });
                      setBankHideBlock(false);
                      setShowAccInfo(false);
                    }}
                    style={{
                      backgroundColor: !active ? "#ffffff" : "#242424",
                      color: !active ? "#1d1d1d" : "#f9f9f9",
                    }}
                  >
                    Active
                  </Button>
                  <Button
                    className="bene_swap_butt_2-new"
                    onClick={() => {
                      setActive(true);
                      setShowAccInfo(false);
                      setBankIsDennied(false);
                      setBankIsApproved(false);
                      setBankIsArchived(false);
                      setBankIsPending(false);
                      setBankAccountType({
                        type: "",
                        id: "",
                      });
                    }}
                    style={{
                      backgroundColor: active ? "#ffffff" : "#242424",
                      color: active ? "#1d1d1d" : "#f9f9f9",
                    }}
                  >
                    Inactive
                  </Button>
                </div>
                {active ? (
                  <div className="bank_scroll_All_approval">
                    {haveAcheived ||
                    haveDennied ||
                    haveBlock ||
                    haveRejected ? (
                      <>
                        {haveBlock && (
                          <div className="bank_achived_main-new">
                            <p style={{ whiteSpace: "nowrap" }}>
                              Blocked accounts
                            </p>
                            <div className="bene_achived_scroll">
                              {benebankLookData?.beneficiary_accounts.map(
                                (val, i) => {
                                  if (val?.approval_status === "blocked") {
                                    return (
                                      <Button
                                        style={{
                                          outline:
                                            payload?.panNumber == val?.pan_no &&
                                            payload?.bank_account ==
                                              val?.bank_acc_no &&
                                            payload?.ifsc_code == val?.ifsc_code
                                              ? "2px solid #CE720F"
                                              : "none",
                                        }}
                                        sx={{
                                          backgroundColor:
                                            bankAccountType.id == val?.id
                                              ? "#7185A8 !important"
                                              : "#1d3a6d",
                                          "&:hover": {
                                            backgroundColor:
                                              "#7185A8 !important",
                                          },
                                        }}
                                        className="bene_child_scroll-new"
                                        key={i}
                                        onClick={() => {
                                          setBankIsDennied(false);
                                          setBankIsPending(false);
                                          setBankIsApproved(false);
                                          setBankIsArchived(false);
                                          setBankLookupNext(false);
                                          setBankNewMessage(true);
                                          setBankHideCreate(false);
                                          setBankHideBlock(!hideBankBlock);

                                          if (
                                            bankAccountType.type === "blocked"
                                          ) {
                                            setBankNewMessage(true);
                                            setBankHideBlock(false);
                                          } else {
                                            setBankHideBlock(!hideBankBlock);
                                          }
                                          BenEnableNextButton(true);
                                          if (val?.id === bankAccountType.id) {
                                            setBankAccountType({
                                              type: "",
                                              id: "",
                                            });
                                          } else {
                                            setBankAccountType({
                                              type: val?.approval_status,
                                              id: val?.id,
                                            });
                                          }
                                        }}
                                      >
                                        <div className="bank_client_name">
                                          <p
                                            className="bank_name_client2-new"
                                            style={{
                                              color: "#FF5860",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {val?.name.toLowerCase()}
                                          </p>
                                          <p className="bene_bank_client2-new">
                                            {val?.pan_no}
                                            <span className="bene_bank_no-new">
                                              - PAN number
                                            </span>
                                          </p>
                                        </div>
                                        <div className="bank_viewProfile-new">
                                          <button
                                            // disabled={
                                            //   bankAccountType.id !== val?.id
                                            // }
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              navigate(
                                                `/accounts/beneficiary_account/details/${val?.id}`
                                              );
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                              src={ViewDetails}
                                              alt="viewdetails"
                                            />
                                          </button>
                                          <p>View details</p>
                                        </div>
                                      </Button>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        )}
                        {(haveRejected || haveDennied) && (
                          <div className="bank_achived_main-new">
                            <p style={{ whiteSpace: "nowrap" }}>
                              Rejected accounts
                            </p>
                            <div className="bene_achived_scroll">
                              {benebankLookData?.beneficiary_accounts.map(
                                (val, i) => {
                                  if (
                                    val?.approval_status === "rejected" ||
                                    val?.approval_status === "denied"
                                  ) {
                                    return (
                                      <Button
                                        style={{
                                          outline:
                                            payload?.panNumber == val?.pan_no &&
                                            payload?.bank_account ==
                                              val?.bank_acc_no &&
                                            payload?.ifsc_code == val?.ifsc_code
                                              ? "2px solid #CE720F"
                                              : "none",
                                        }}
                                        sx={{
                                          backgroundColor:
                                            bankAccountType.id == val?.id
                                              ? "#7185A8 !important"
                                              : "#1d3a6d",
                                          "&:hover": {
                                            backgroundColor:
                                              "#7185A8 !important",
                                          },
                                        }}
                                        className="bene_child_scroll-new"
                                        key={i}
                                        onClick={() => {
                                          setBankShutViewAll(true);
                                          setBankIsArchived(false);
                                          setBankLookupNext(false);
                                          setBankIsDennied(false);
                                          setBankIsPending(false);
                                          BenEnableNextButton(true);
                                          setBankNewMessage(true);
                                          setBankHideBlock(false);
                                          setBankHideCreate(false);
                                          setBankDisableCreateNext(false);
                                          if (val?.id === bankAccountType.id) {
                                            setBankAccountType({
                                              type: "",
                                              id: "",
                                            });

                                            setBankIsApproved(false);
                                          } else {
                                            setBankAccountType({
                                              type: val?.approval_status,
                                              id: val?.id,
                                            });
                                            setBankIsApproved(true);
                                          }
                                        }}
                                      >
                                        <div className="bank_client_name">
                                          <p
                                            className="bank_name_client2-new"
                                            style={{
                                              color: "#FF5860",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {val?.name.toLowerCase()}
                                          </p>
                                          <p className="bene_bank_client2-new">
                                            {val?.pan_no}
                                            <span className="bene_bank_no-new">
                                              - PAN number
                                            </span>
                                          </p>
                                        </div>
                                        <div className="bank_viewProfile-new">
                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              navigate(
                                                `/accounts/beneficiary_account/details/${val?.id}`
                                              );
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                              src={ViewDetails}
                                              alt="viewdetails"
                                            />
                                          </button>
                                          <p>View details</p>
                                        </div>
                                      </Button>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        )}
                        {haveAcheived && (
                          <div className="bank_achived_main-new">
                            <p>Archived accounts</p>
                            <div className="bene_achived_scroll">
                              {benebankLookData?.beneficiary_accounts.map(
                                (val, i) => {
                                  if (val?.approval_status === "archived") {
                                    return (
                                      <Button
                                        className="bene_child_scroll-new"
                                        style={{
                                          outline:
                                            payload?.panNumber == val?.pan_no &&
                                            payload?.bank_account ==
                                              val?.bank_acc_no &&
                                            payload?.ifsc_code == val?.ifsc_code
                                              ? "2px solid #CE720F"
                                              : "none",
                                        }}
                                        sx={{
                                          backgroundColor:
                                            bankAccountType.id == val?.id
                                              ? "#7185A8 !important"
                                              : "#1d3a6d",
                                          "&:hover": {
                                            backgroundColor:
                                              "#7185A8 !important",
                                          },
                                        }}
                                        key={i}
                                        id={val?.id}
                                        onClick={() => {
                                          setBankLookupNext(false);
                                          setBankDisableCreateNext(false);
                                          setBankIsDennied(false);
                                          setBankIsPending(false);
                                          setBankHideBlock(false);
                                          setBankNewMessage(true);
                                          setBankHideCreate(false);
                                          setBankIsApproved(false);
                                          if (val?.id === bankAccountType.id) {
                                            setBankAccountType({
                                              type: "",
                                              id: "",
                                            });
                                            setBankIsArchived(false);
                                          } else {
                                            setBankAccountType({
                                              type: val?.approval_status,
                                              id: val?.id,
                                            });
                                            setBankIsArchived(true);
                                          }
                                          BenEnableNextButton(true);
                                        }}
                                      >
                                        <div className="bank_client_name">
                                          <p
                                            className="bank_name_client2-new"
                                            style={{
                                              color: "#DADADA",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {val?.name.toLowerCase()}
                                          </p>
                                          <p className="bene_bank_client2-new">
                                            {val?.pan_no}
                                            <span className="bene_bank_no-new">
                                              - PAN number
                                            </span>
                                          </p>
                                        </div>
                                        <div className="bank_viewProfile-new">
                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              navigate(
                                                `/accounts/beneficiary_account/details/${val?.id}`
                                              );
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                              src={ViewDetails}
                                              alt="viewdetails"
                                            />
                                          </button>
                                          <p>View details</p>
                                        </div>
                                      </Button>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="hidden_accounts">
                        <p>
                          <span>No inactive associated accounts found</span>{" "}
                          <br /> There are no existing inactive accounts that
                          are associated with the bank provided.
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="bank_scroll_All_approval">
                    {haveApproved || havePending ? (
                      <>
                        {haveApproved && (
                          <div className="bank_achived_main-new">
                            <p style={{ whiteSpace: "nowrap" }}>
                              Approved accounts
                            </p>
                            <div className="bene_achived_scroll">
                              {benebankLookData?.beneficiary_accounts.map(
                                (val, i) => {
                                  if (val?.approval_status === "approved") {
                                    return (
                                      <Button
                                        style={{
                                          outline:
                                            payload?.panNumber == val?.pan_no &&
                                            payload?.bank_account ==
                                              val?.bank_acc_no &&
                                            payload?.ifsc_code == val?.ifsc_code
                                              ? "2px solid #CE720F"
                                              : "none",
                                        }}
                                        sx={{
                                          backgroundColor:
                                            bankAccountType.id == val?.id
                                              ? "#7185A8 !important"
                                              : "#1D3A6D !important",
                                          "&:hover": {
                                            backgroundColor:
                                              "#7185A8 !important",
                                          },
                                        }}
                                        className="bene_child_scroll-new"
                                        key={i}
                                        onClick={() => {
                                          setBankShutViewAll(true);
                                          setBankIsArchived(false);
                                          setBankLookupNext(false);
                                          setBankIsDennied(false);
                                          setBankIsPending(false);
                                          BenEnableNextButton(true);
                                          setBankNewMessage(true);
                                          setBankHideBlock(false);
                                          setBankHideCreate(false);
                                          setBankDisableCreateNext(false);
                                          if (val?.id === bankAccountType.id) {
                                            setBankAccountType({
                                              type: "",
                                              id: "",
                                            });

                                            setBankIsApproved(false);
                                          } else {
                                            setBankAccountType({
                                              type: val?.approval_status,
                                              id: val?.id,
                                            });
                                            setBankIsApproved(true);
                                          }
                                        }}
                                      >
                                        <div className="bank_client_name">
                                          <p
                                            className="bank_name_client2-new"
                                            style={{
                                              color: "#4DDD37",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {val?.name.toLowerCase()}
                                          </p>
                                          <p className="bene_bank_client2-new">
                                            {val?.pan_no}
                                            <span className="bene_bank_no-new">
                                              - PAN number
                                            </span>
                                          </p>
                                        </div>
                                        <div
                                          className="bank_viewProfile-new"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(
                                              `/accounts/beneficiary_account/details/${val?.id}`
                                            );
                                          }}
                                        >
                                          <button>
                                            <img
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                              src={ViewDetails}
                                              alt="viewdetails"
                                            />
                                          </button>
                                          <p>View details</p>
                                        </div>
                                      </Button>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        )}
                        {havePending && (
                          <div className="bank_achived_main-new">
                            <p style={{ whiteSpace: "nowrap" }}>
                              Pending accounts
                            </p>
                            <div className="bene_achived_scroll">
                              {benebankLookData?.beneficiary_accounts.map(
                                (val, i) => {
                                  if (val?.approval_status === "pending") {
                                    return (
                                      <Button
                                        style={{
                                          outline:
                                            payload?.panNumber == val?.pan_no &&
                                            payload?.bank_account ==
                                              val?.bank_acc_no &&
                                            payload?.ifsc_code == val?.ifsc_code
                                              ? "2px solid #CE720F"
                                              : "none",
                                        }}
                                        sx={{
                                          backgroundColor:
                                            bankAccountType.id == val?.id
                                              ? "#7185A8 !important"
                                              : "#1d3a6d",
                                          "&:hover": {
                                            backgroundColor:
                                              "#7185A8 !important",
                                          },
                                        }}
                                        className="bene_child_scroll-new"
                                        key={i}
                                        onClick={() => {
                                          setBankShutViewAll(true);
                                          setBankIsApproved(false);
                                          setBankIsArchived(false);
                                          setBankLookupNext(false);
                                          setBankIsDennied(false);
                                          setBankNewMessage(true);
                                          BenEnableNextButton(true);
                                          setBankHideBlock(false);
                                          setBankHideCreate(false);
                                          setBankDisableCreateNext(false);
                                          if (val?.id === bankAccountType.id) {
                                            setBankAccountType({
                                              type: "",
                                              id: "",
                                            });
                                            setBankIsPending(false);
                                          } else {
                                            setBankAccountType({
                                              type: val?.approval_status,
                                              id: val?.id,
                                            });
                                            setBankIsPending(true);
                                          }
                                        }}
                                      >
                                        <div className="bank_client_name">
                                          <p
                                            className="bank_name_client2-new"
                                            style={{
                                              color: "#00FFF5",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {val?.name.toLowerCase()}
                                          </p>
                                          <p className="bene_bank_client2-new">
                                            {val?.pan_no}
                                            <span className="bene_bank_no-new">
                                              - PAN number
                                            </span>
                                          </p>
                                        </div>
                                        <div className="bank_viewProfile-new">
                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              navigate(
                                                `/accounts/beneficiary_account/details/${val?.id}`
                                              );
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                              src={ViewDetails}
                                              alt="viewdetails"
                                            />
                                          </button>
                                          <p>View details</p>
                                        </div>
                                      </Button>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="bank_hidden_accounts">
                        <p>
                          <span>No active associated accounts found</span>{" "}
                          <br /> There are no existing active accounts that are
                          associated with the bank provided.
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div
                className="bank_ass_bottom_crt-new"
                style={{
                  display: `${
                    bankIsAchived ||
                    ((bankIsApproved || bankIsDennied || bankIsPending) &&
                      bankShutViewAll &&
                      isIFSCValid)
                      ? "block"
                      : "none"
                  }`,
                  position: "relative",
                  top: "12px",
                }}
              >
                <p>Account action</p>

                {bankIsAchived ? (
                  <Button
                    className="bank_ass_next-new"
                    onClick={() => {
                      if (bankAccountType.type === "archived") {
                        setBankUnachivedOpenPop(true);
                        if (isBankLinkedAccount) {
                          setIsBankLinkedAccount(true);
                        }
                      }
                    }}
                  >
                    <img
                      className="bank_archive_btn"
                      src={unarchiveImg}
                      alt="arc"
                    />
                    <p>Unarchive account</p>
                  </Button>
                ) : (bankIsApproved || bankIsDennied || bankIsPending) &&
                  bankShutViewAll ? (
                  <Button
                    className="bank_ass_next-new"
                    style={{
                      color: bankLookupNext ? "#031F4F" : "#fefefe",
                      background: "#63ba74 0% 0% no-repeat padding-box",
                    }}
                    onClick={() => {
                      setBankAchivedOpenPop(!bankAchivedOpenPop);
                      if (isBankLinkedAccount) {
                        setIsBankLinkedAccount(true);
                      }
                    }}
                  >
                    <img
                      className="bank_archive_btn"
                      src={archiveImg}
                      alt="arc"
                    />
                    <p>Archive account</p>
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobileView && (
        <div
          className="beneBank_Main_res"
          style={{
            width: `${windowWidth}px`,
            paddingBottom: virtualAccount && isIFSCValid ? "55px" : "",
          }}
        >
          {bankAchivedOpenPop && (
            <BeneficiaryModal
              close={setBankAchivedOpenPop}
              id={bankAccountType.id}
              type={"archived"}
              setBankAccountType={setBankAccountType}
              bankAccountType={bankAccountType}
              message={"Are you sure you want to archive this account?"}
              note={"This account will become inactive."}
              panNumber={panNumber}
              setBankIsArchived={setBankIsArchived}
              payload={payload}
              setBankShutViewAll={setBankShutViewAll}
              createFlow={"bank"}
              title={"Archive account"}
              setIsBankLinkedAccount={setIsBankLinkedAccount}
              isBankLinkedAccount={isBankLinkedAccount}
            />
          )}
          {bankUnachivedOpenPop && (
            <BeneficiaryModal
              close={setBankUnachivedOpenPop}
              id={bankAccountType.id}
              type={"unarchived"}
              setBankAccountType={setBankAccountType}
              bankAccountType={bankAccountType}
              message={"Are you sure you want to unarchive this account?"}
              note={"This account will become active."}
              panNumber={panNumber}
              setBankIsArchived={setBankIsArchived}
              payload={payload}
              setBankShutViewAll={setBankShutViewAll}
              createFlow={"bank"}
              title={"Unarchive account"}
              setIsBankLinkedAccount={setIsBankLinkedAccount}
              isBankLinkedAccount={isBankLinkedAccount}
            />
          )}
          <div className="dividebank_res">
            {hideBankBlock && !isBankError && !virtualAccount ? (
              <div className="bene_block_message_text_responsive">
                <img src={addstatus} alt="info" />
                <p>To unblock this account, please contact customer support</p>
              </div>
            ) : isBankLinkedAccount && isIFSCValid ? (
              <div className="bank_new_message_text_block_nonarchive">
                <img src={addstatus} alt="info" />
                <p>
                  Looks like this bank account is already linked to your PAN.
                  Please update either the bank details or your PAN to proceed.
                </p>
              </div>
            ) : null}
            {virtualAccount && isIFSCValid ? (
              <div className="bene_new_message_text_crt">
                <img src={addstatus} alt="info" />
                <p>
                  <span>Virtual Account Detected</span> <br />
                  <ul className="list-disc px-2 py-2">
                    <li>
                      Account could not be verified as it is virtual account
                    </li>
                    <li style={{ paddingTop: "10px" }}>
                      Please self verify and proceed with caution
                    </li>
                  </ul>
                </p>
              </div>
            ) : null}
            <div className="w-1/2 h-fit bankright">
              <BeneEnterBankDetails
                payload={payload}
                setPayload={setPayload}
                BenEnableNextButton={BenEnableNextButton}
                activeStep={activeStep}
                title="Beneficiary's Bank Details"
                setVirtualAccount={setVirtualAccount}
                setBankUserInfo={setBankUserInfo}
                setShowAccInfo={setShowAccInfo}
                bankLookupNext={bankLookupNext}
                setBankLookupNext={setBankLookupNext}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "inherit",
            }}
          >
            <div
              className="bank_new_crt_acc_bottom_res"
              style={{
                display:
                  benebankLookData?.status && isIFSCValid && !isBankError
                    ? ""
                    : "none",
                marginBottom: "20px",
              }}
            >
              <div style={{ flex: "90%" }}>
                <div className="crt-Confirm-info-new_res">
                  Confirm to continue account creation
                </div>
                <div className="crt-Confirm-sub-info-new_res">
                  Confirm and click next to proceed. Creating a new account will
                  transform this account into a joint account and adds it to the
                  active accounts for the provided bank details.
                </div>
              </div>
              <div style={{ flex: "10%", paddingTop: "10px" }}>
                <Button
                  className={`${
                    !isButtonDisabled && !isCreateAccount
                      ? "bank_new_create_btn"
                      : "bank_new_create_btn_disabled"
                  }`}
                  style={{
                    color: bankLookupNext ? "#fefefe" : "#FFFFFF",
                    background: bankLookupNext
                      ? "#7185a8 0% 0% no-repeat padding-box"
                      : "#1D3A6D 0% 0% no-repeat padding-box",
                    borderRadius: "10px",
                    cursor:
                      !isButtonDisabled && !isCreateAccount
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() => {
                    if (!isButtonDisabled && !isCreateAccount) {
                      setIsCreateAccount(false);
                      setBankIsApproved(false);
                      setBankLookupNext(true);
                      setBankIsArchived(false);
                      setBankIsDennied(false);
                      setBankIsPending(false);
                      setBankHideBlock(false);
                      setBankDisableCreateNext(false);
                      if (bankLookupNext) {
                        BenEnableNextButton(true);
                      } else {
                        BenEnableNextButton(false);
                      }
                      if (bankLookupNext) {
                        setBankNewMessage(true);
                        setBankHideCreate(false);
                        setBankLookupNext(false);
                      } else {
                        setBankHideCreate(true);
                        setBankNewMessage(false);
                      }
                      setBankAccountType({ type: "", id: "" });
                      setBankDisableCreateNext(true);
                    } else {
                      return;
                    }
                  }}
                >
                  <span style={{ paddingRight: "2px" }}>
                    <img
                      src={nextButtonEnabled ? not_selected : selected}
                      alt="btn-icon"
                    />
                  </span>
                  <span
                    className="bank-confirm-btn-style"
                    style={{ paddingLeft: "2px" }}
                  >
                    Confirm
                  </span>
                </Button>
              </div>
            </div>
            {!freshAccount && (
              <div
                className="dividebank_res bank_Details_res"
                style={{
                  marginBottom: "100px",
                  display:
                    !beneLookLoading &&
                    benebankLookData?.status === undefined &&
                    !isBankError &&
                    isIFSCValid &&
                    !virtualAccount
                      ? ""
                      : "none",
                  height: "auto",
                }}
              >
                <div className="bank-no-assacc-found">
                  <p className="bene_top_account-new">
                    Associated Accounts{""}
                    <img
                      ref={noAssociatedAccountRef}
                      src={InfoDetails}
                      alt="info"
                      onClick={() => {
                        setShowNoAccInfo(!showNoAccInfo);
                      }}
                    />
                    {showNoAccInfo ? (
                      <>
                        <div className="associate-acc-info-container_res">
                          <p className="associate-acc-info_res">
                            Associated accounts include all active (approved,
                            pending) and inactive (rejected, archived, blocked)
                            accounts linked to the provided Bank details. Click
                            on the accounts to manage them, or create a new
                            account to add it to the active ones.
                          </p>
                        </div>
                      </>
                    ) : null}
                  </p>
                  <div className="bank_hidden_accounts">
                    <p>
                      <span>No associated accounts found</span> <br /> There are
                      no existing accounts that are associated with the bank
                      details provided. You can proceed to create a new account.
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div
              className="dividebank_res bank_Details_res"
              style={{
                display:
                  benebankLookData?.status && isIFSCValid && !isBankError
                    ? "flex"
                    : "none",
                height:
                  bankIsAchived ||
                  ((bankIsApproved || bankIsDennied || bankIsPending) &&
                    bankShutViewAll &&
                    isIFSCValid)
                    ? "auto"
                    : "390px",
              }}
            >
              <div className="bank_ass_top">
                <p className="bene_top_account-new">
                  Associated Accounts{""}
                  <img
                    ref={associatedAccountRef}
                    src={InfoDetails}
                    alt="info"
                    onClick={() => {
                      setShowAccInfo(!showAccInfo);
                    }}
                  />
                  {showAccInfo ? (
                    <>
                      <div className="associate-acc-info-container_res">
                        <p className="associate-acc-info_res">
                          Associated accounts include all active (approved,
                          pending) and inactive (rejected, archived, blocked)
                          accounts linked to the provided Bank details. Click on
                          the accounts to manage them, or create a new account
                          to add it to the active ones.
                        </p>
                      </div>
                    </>
                  ) : null}
                </p>
                <p className="bene_top_con-new">
                  The following existing account is associated with the bank
                  provided.
                </p>
                <div className="bene_swap_butt">
                  <Button
                    className="bene_swap_butt_1-new"
                    onClick={() => {
                      setActive(false);
                      setBankIsDennied(false);
                      setBankIsApproved(false);
                      setBankIsArchived(false);
                      setBankIsPending(false);
                      setBankAccountType({
                        type: "",
                        id: "",
                      });
                      setBankHideBlock(false);
                      setShowAccInfo(false);
                    }}
                    style={{
                      backgroundColor: !active ? "#ffffff" : "#242424",
                      color: !active ? "#1d1d1d" : "#f9f9f9",
                    }}
                  >
                    Active
                  </Button>
                  <Button
                    className="bene_swap_butt_2-new"
                    onClick={() => {
                      setActive(true);
                      setShowAccInfo(false);
                      setBankIsDennied(false);
                      setBankIsApproved(false);
                      setBankIsArchived(false);
                      setBankIsPending(false);
                      setBankAccountType({
                        type: "",
                        id: "",
                      });
                    }}
                    style={{
                      backgroundColor: active ? "#ffffff" : "#242424",
                      color: active ? "#1d1d1d" : "#f9f9f9",
                    }}
                  >
                    Inactive
                  </Button>
                </div>
                {active ? (
                  <div className="bank_scroll_All_approval">
                    {haveAcheived ||
                    haveDennied ||
                    haveBlock ||
                    haveRejected ? (
                      <>
                        {haveBlock && (
                          <div className="bank_achived_main-new">
                            <p style={{ whiteSpace: "nowrap" }}>
                              Blocked accounts
                            </p>
                            <div className="achived_scroll-responsive">
                              {benebankLookData?.beneficiary_accounts.map(
                                (val, i) => {
                                  if (val?.approval_status === "blocked") {
                                    return (
                                      <Button
                                        style={{
                                          outline:
                                            payload?.panNumber == val?.pan_no &&
                                            payload?.bank_account ==
                                              val?.bank_acc_no &&
                                            payload?.ifsc_code == val?.ifsc_code
                                              ? "2px solid #CE720F"
                                              : "none",
                                        }}
                                        sx={{
                                          backgroundColor:
                                            bankAccountType.id == val?.id
                                              ? "#7185A8 !important"
                                              : "#1d3a6d",
                                          // "&:hover": {
                                          //   backgroundColor:
                                          //     "#7185A8 !important",
                                          // },
                                        }}
                                        className="bene_child_scroll-new-responsive"
                                        key={i}
                                        onClick={() => {
                                          setBankIsDennied(false);
                                          setBankIsPending(false);
                                          setBankIsApproved(false);
                                          setBankIsArchived(false);
                                          setBankLookupNext(false);
                                          setBankNewMessage(true);
                                          setBankHideCreate(false);
                                          setBankHideBlock(!hideBankBlock);

                                          if (
                                            bankAccountType.type === "blocked"
                                          ) {
                                            setBankNewMessage(true);
                                            setBankHideBlock(false);
                                          } else {
                                            setBankHideBlock(!hideBankBlock);
                                          }
                                          BenEnableNextButton(true);
                                          if (val?.id === bankAccountType.id) {
                                            setBankAccountType({
                                              type: "",
                                              id: "",
                                            });
                                          } else {
                                            setBankAccountType({
                                              type: val?.approval_status,
                                              id: val?.id,
                                            });
                                          }
                                        }}
                                      >
                                        <div className="bank_client_name-responsive ">
                                          <p
                                            className="bank_name_client2-new"
                                            style={{
                                              color: "#FF5860",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {val?.name.toLowerCase()}
                                          </p>
                                          <p className="bene_bank_client2-new">
                                            {val?.pan_no}
                                            <span className="bene_bank_no-new">
                                              - PAN number
                                            </span>
                                          </p>
                                        </div>
                                        <div className="bank_viewProfile-new-responsive">
                                          <button
                                            // disabled={
                                            //   bankAccountType.id !== val?.id
                                            // }
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              navigate(
                                                `/accounts/beneficiary_account/details/${val?.id}`
                                              );
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                              src={ViewDetails}
                                              alt="viewdetails"
                                            />
                                          </button>
                                          <p>View details</p>
                                        </div>
                                      </Button>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        )}
                        {(haveRejected || haveDennied) && (
                          <div className="bank_achived_main-new">
                            <p style={{ whiteSpace: "nowrap" }}>
                              Rejected accounts
                            </p>
                            <div className="achived_scroll-responsive">
                              {benebankLookData?.beneficiary_accounts.map(
                                (val, i) => {
                                  if (
                                    val?.approval_status === "rejected" ||
                                    val?.approval_status === "denied"
                                  ) {
                                    return (
                                      <Button
                                        style={{
                                          outline:
                                            payload?.panNumber == val?.pan_no &&
                                            payload?.bank_account ==
                                              val?.bank_acc_no &&
                                            payload?.ifsc_code == val?.ifsc_code
                                              ? "2px solid #CE720F"
                                              : "none",
                                        }}
                                        sx={{
                                          backgroundColor:
                                            bankAccountType.id == val?.id
                                              ? "#7185A8 !important"
                                              : "#1d3a6d",
                                          // "&:hover": {
                                          //   backgroundColor:
                                          //     "#7185A8 !important",
                                          // },
                                        }}
                                        className="bene_child_scroll-new-responsive"
                                        key={i}
                                        onClick={() => {
                                          setBankShutViewAll(true);
                                          setBankIsArchived(false);
                                          setBankLookupNext(false);
                                          setBankIsDennied(false);
                                          setBankIsPending(false);
                                          BenEnableNextButton(true);
                                          setBankNewMessage(true);
                                          setBankHideBlock(false);
                                          setBankHideCreate(false);
                                          setBankDisableCreateNext(false);
                                          if (val?.id === bankAccountType.id) {
                                            setBankAccountType({
                                              type: "",
                                              id: "",
                                            });

                                            setBankIsApproved(false);
                                          } else {
                                            setBankAccountType({
                                              type: val?.approval_status,
                                              id: val?.id,
                                            });
                                            setBankIsApproved(true);
                                          }
                                        }}
                                      >
                                        <div className="bank_client_name-responsive ">
                                          <p
                                            className="bank_name_client2-new"
                                            style={{
                                              color: "#FF5860",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {val?.name.toLowerCase()}
                                          </p>
                                          <p className="bene_bank_client2-new">
                                            {val?.pan_no}
                                            <span className="bene_bank_no-new">
                                              - PAN number
                                            </span>
                                          </p>
                                        </div>
                                        <div className="bank_viewProfile-new-responsive">
                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              navigate(
                                                `/accounts/beneficiary_account/details/${val?.id}`
                                              );
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                              src={ViewDetails}
                                              alt="viewdetails"
                                            />
                                          </button>
                                          <p>View details</p>
                                        </div>
                                      </Button>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        )}
                        {haveAcheived && (
                          <div className="bank_achived_main-new">
                            <p>Archived accounts</p>
                            <div className="achived_scroll-responsive">
                              {benebankLookData?.beneficiary_accounts.map(
                                (val, i) => {
                                  if (val?.approval_status === "archived") {
                                    return (
                                      <Button
                                        className="bene_child_scroll-new-responsive"
                                        style={{
                                          outline:
                                            payload?.panNumber == val?.pan_no &&
                                            payload?.bank_account ==
                                              val?.bank_acc_no &&
                                            payload?.ifsc_code == val?.ifsc_code
                                              ? "2px solid #CE720F"
                                              : "none",
                                        }}
                                        sx={{
                                          backgroundColor:
                                            bankAccountType.id == val?.id
                                              ? "#7185A8 !important"
                                              : "#1d3a6d",
                                          // "&:hover": {
                                          //   backgroundColor:
                                          //     "#7185A8 !important",
                                          // },
                                        }}
                                        key={i}
                                        id={val?.id}
                                        onClick={() => {
                                          setBankLookupNext(false);
                                          setBankDisableCreateNext(false);
                                          setBankIsDennied(false);
                                          setBankIsPending(false);
                                          setBankHideBlock(false);
                                          setBankNewMessage(true);
                                          setBankHideCreate(false);
                                          setBankIsApproved(false);
                                          if (val?.id === bankAccountType.id) {
                                            setBankAccountType({
                                              type: "",
                                              id: "",
                                            });
                                            setBankIsArchived(false);
                                          } else {
                                            setBankAccountType({
                                              type: val?.approval_status,
                                              id: val?.id,
                                            });
                                            setBankIsArchived(true);
                                          }
                                          BenEnableNextButton(true);
                                        }}
                                      >
                                        <div className="bank_client_name-responsive ">
                                          <p
                                            className="bank_name_client2-new"
                                            style={{
                                              color: "#DADADA",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {val?.name.toLowerCase()}
                                          </p>
                                          <p className="bene_bank_client2-new">
                                            {val?.pan_no}
                                            <span className="bene_bank_no-new">
                                              - PAN number
                                            </span>
                                          </p>
                                        </div>
                                        <div className="bank_viewProfile-new-responsive">
                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              navigate(
                                                `/accounts/beneficiary_account/details/${val?.id}`
                                              );
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                              src={ViewDetails}
                                              alt="viewdetails"
                                            />
                                          </button>
                                          <p>View details</p>
                                        </div>
                                      </Button>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="hidden_accounts">
                        <p>
                          <span>No inactive associated accounts found</span>{" "}
                          <br /> There are no existing inactive accounts that
                          are associated with the bank provided.
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="bank_scroll_All_approval">
                    {haveApproved || havePending ? (
                      <>
                        {haveApproved && (
                          <div className="bank_achived_main-new">
                            <p style={{ whiteSpace: "nowrap" }}>
                              Approved accounts
                            </p>
                            <div className="achived_scroll-responsive">
                              {benebankLookData?.beneficiary_accounts.map(
                                (val, i) => {
                                  if (val?.approval_status === "approved") {
                                    return (
                                      <Button
                                        style={{
                                          outline:
                                            payload?.panNumber == val?.pan_no &&
                                            payload?.bank_account ==
                                              val?.bank_acc_no &&
                                            payload?.ifsc_code == val?.ifsc_code
                                              ? "2px solid #CE720F"
                                              : "none",
                                        }}
                                        sx={{
                                          backgroundColor:
                                            bankAccountType.id == val?.id
                                              ? "#7185A8 !important"
                                              : "#1D3A6D !important",
                                          // "&:hover": {
                                          //   backgroundColor:
                                          //     "#7185A8 !important",
                                          // },
                                        }}
                                        className="bene_child_scroll-new-responsive"
                                        key={i}
                                        onClick={() => {
                                          setBankShutViewAll(true);
                                          setBankIsArchived(false);
                                          setBankLookupNext(false);
                                          setBankIsDennied(false);
                                          setBankIsPending(false);
                                          BenEnableNextButton(true);
                                          setBankNewMessage(true);
                                          setBankHideBlock(false);
                                          setBankHideCreate(false);
                                          setBankDisableCreateNext(false);
                                          if (val?.id === bankAccountType.id) {
                                            setBankAccountType({
                                              type: "",
                                              id: "",
                                            });

                                            setBankIsApproved(false);
                                          } else {
                                            setBankAccountType({
                                              type: val?.approval_status,
                                              id: val?.id,
                                            });
                                            setBankIsApproved(true);
                                          }
                                        }}
                                      >
                                        <div className="bank_client_name-responsive ">
                                          <p
                                            className="bank_name_client2-new"
                                            style={{
                                              color: "#4DDD37",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {val?.name.toLowerCase()}
                                          </p>
                                          <p className="bene_bank_client2-new">
                                            {val?.pan_no}
                                            <span className="bene_bank_no-new">
                                              - PAN number
                                            </span>
                                          </p>
                                        </div>
                                        <div
                                          className="bank_viewProfile-new-responsive"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(
                                              `/accounts/beneficiary_account/details/${val?.id}`
                                            );
                                          }}
                                        >
                                          <button>
                                            <img
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                              src={ViewDetails}
                                              alt="viewdetails"
                                            />
                                          </button>
                                          <p>View details</p>
                                        </div>
                                      </Button>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        )}
                        {havePending && (
                          <div className="bank_achived_main-new">
                            <p style={{ whiteSpace: "nowrap" }}>
                              Pending accounts
                            </p>
                            <div className="achived_scroll-responsive">
                              {benebankLookData?.beneficiary_accounts.map(
                                (val, i) => {
                                  if (val?.approval_status === "pending") {
                                    return (
                                      <Button
                                        style={{
                                          outline:
                                            payload?.panNumber == val?.pan_no &&
                                            payload?.bank_account ==
                                              val?.bank_acc_no &&
                                            payload?.ifsc_code == val?.ifsc_code
                                              ? "2px solid #CE720F"
                                              : "none",
                                        }}
                                        sx={{
                                          backgroundColor:
                                            bankAccountType.id == val?.id
                                              ? "#7185A8 !important"
                                              : "#1d3a6d",
                                          // "&:hover": {
                                          //   backgroundColor:
                                          //     "#7185A8 !important",
                                          // },
                                        }}
                                        className="bene_child_scroll-new-responsive"
                                        key={i}
                                        onClick={() => {
                                          setBankShutViewAll(true);
                                          setBankIsApproved(false);
                                          setBankIsArchived(false);
                                          setBankLookupNext(false);
                                          setBankIsDennied(false);
                                          setBankNewMessage(true);
                                          BenEnableNextButton(true);
                                          setBankHideBlock(false);
                                          setBankHideCreate(false);
                                          setBankDisableCreateNext(false);
                                          if (val?.id === bankAccountType.id) {
                                            setBankAccountType({
                                              type: "",
                                              id: "",
                                            });
                                            setBankIsPending(false);
                                          } else {
                                            setBankAccountType({
                                              type: val?.approval_status,
                                              id: val?.id,
                                            });
                                            setBankIsPending(true);
                                          }
                                        }}
                                      >
                                        <div className="bank_client_name-responsive ">
                                          <p
                                            className="bank_name_client2-new"
                                            style={{
                                              color: "#00FFF5",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {val?.name.toLowerCase()}
                                          </p>
                                          <p className="bene_bank_client2-new">
                                            {val?.pan_no}
                                            <span className="bene_bank_no-new">
                                              - PAN number
                                            </span>
                                          </p>
                                        </div>
                                        <div className="bank_viewProfile-new-responsive">
                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              navigate(
                                                `/accounts/beneficiary_account/details/${val?.id}`
                                              );
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                              }}
                                              src={ViewDetails}
                                              alt="viewdetails"
                                            />
                                          </button>
                                          <p>View details</p>
                                        </div>
                                      </Button>
                                    );
                                  }
                                }
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="bank_hidden_accounts">
                        <p>
                          <span>No active associated accounts found</span>{" "}
                          <br /> There are no existing active accounts that are
                          associated with the bank provided.
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div
                className="bank_ass_bottom_crt-new"
                style={{
                  display: `${
                    bankIsAchived ||
                    ((bankIsApproved || bankIsDennied || bankIsPending) &&
                      bankShutViewAll &&
                      isIFSCValid)
                      ? "block"
                      : "none"
                  }`,
                  position: "relative",
                  top: "12px",
                }}
              >
                <p>Account action</p>

                {bankIsAchived ? (
                  <Button
                    className="bank_ass_next-new"
                    onClick={() => {
                      if (bankAccountType.type === "archived") {
                        setBankUnachivedOpenPop(true);
                        if (isBankLinkedAccount) {
                          setIsBankLinkedAccount(true);
                        }
                      }
                    }}
                  >
                    <img
                      className="bank_archive_btn"
                      src={unarchiveImg}
                      alt="arc"
                    />
                    <p>Unarchive account</p>
                  </Button>
                ) : (bankIsApproved || bankIsDennied || bankIsPending) &&
                  bankShutViewAll ? (
                  <Button
                    className="bank_ass_next-new"
                    style={{
                      color: bankLookupNext ? "#031F4F" : "#fefefe",
                      background: "#63ba74 0% 0% no-repeat padding-box",
                    }}
                    onClick={() => {
                      setBankAchivedOpenPop(!bankAchivedOpenPop);
                      if (isBankLinkedAccount) {
                        setIsBankLinkedAccount(true);
                      }
                    }}
                  >
                    <img
                      className="bank_archive_btn"
                      src={archiveImg}
                      alt="arc"
                    />
                    <p>Archive account</p>
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default BeneBankDetails;
