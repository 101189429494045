import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { env } from "../../../env";
import axios from "axios";

let baseUrl = env.REACT_APP_BASE_URL;

export const internalTransactionCreate = createAsyncThunk(
  "internalTransactionCreate",
  async (values, { rejectWithValue }) => {
    try {
      const config = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        data: values?.internalData,
      };
      if (values?.services?.includes("CInternalTxn")) {
        const response = await axios(
          `${baseUrl}/transactions/internal`,
          config
        );
        values?.navigate(
          `/transaction/Internal_transaction/success/${response?.data?.transaction[0]?.txn_id}`
        );
        return response.data;
      } else {
        values?.navigate("/feature_not_assigned");
        return rejectWithValue({ error: "Feature not assigned." });
      }
    } catch (error) {
      values?.navigate("/transaction/Internal_transaction/declined");
      return rejectWithValue(error.response);
    }
  }
);

export const beneInternalTxnSuccessDetail = createAsyncThunk(
  "beneInternalTxnSuccessDetail",
  async (id, { rejectWithValue }) => {
    try {
      if (!id) {
        throw new Error("Invalid beneficiary id");
      }
      const response = await axios.get(
        `${baseUrl}/transactions/internal/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  internalTransactionLoading: true,
  internalTransactionData: null,
  internalTransactionError: null,

  beneInternalTxnSuccessData: null,
  beneInternalTxnSuccessDataError: null,
  beneInternalTxnSuccessLoading: null,
};

const internalTransactionCreateReducer = createSlice({
  name: "internalTransactionCreateReducer",
  initialState,
  reducers: {
    internalTransactionCreateReset: (state) => {
      state.internalTransactionLoading =
        initialState.internalTransactionLoading;
      state.internalTransactionData = initialState.internalTransactionData;
      state.internalTransactionError = initialState.internalTransactionError;
    },
  },
  extraReducers: (reducerResults) => {
    reducerResults.addCase(internalTransactionCreate.pending, (state) => {
      state.internalTransactionLoading = true;
    });
    reducerResults.addCase(
      internalTransactionCreate.fulfilled,
      (state, action) => {
        state.internalTransactionLoading = false;
        state.internalTransactionData = action?.payload;
      }
    );
    reducerResults.addCase(
      internalTransactionCreate.rejected,
      (state, action) => {
        if (action?.payload?.data?.errors) {
          sessionStorage.setItem(
            "internalRejected",
            JSON.stringify(action?.payload?.data?.errors)
          );
        } else {
          sessionStorage.setItem(
            "internalRejected",
            JSON.stringify(action?.payload?.data?.error)
          );
        }

        state.internalTransactionLoading = false;
        state.internalTransactionData = null;
        state.internalTransactionError = action?.payload;
      }
    );
    reducerResults.addCase(beneInternalTxnSuccessDetail.pending, (state) => {
      state.beneInternalTxnSuccessLoading = true;
    });
    reducerResults.addCase(
      beneInternalTxnSuccessDetail.fulfilled,
      (state, action) => {
        state.beneInternalTxnSuccessLoading = false;
        state.beneInternalTxnSuccessDataError = null;
        state.beneInternalTxnSuccessData = action.payload;
      }
    );
    reducerResults.addCase(
      beneInternalTxnSuccessDetail.rejected,
      (state, action) => {
        state.beneInternalTxnSuccessDataError = action.payload;
        state.beneInternalTxnSuccessLoading = true;
        state.beneInternalTxnSuccessData = null;
      }
    );
  },
});

export const { internalTransactionCreateReset } =
  internalTransactionCreateReducer.actions;

export default internalTransactionCreateReducer.reducer;
