import React, { useState, useCallback } from "react";
import "./index.css";
import ActionDrpDown from "../../../../src/components/BeneTransaction/BeneAdditional/index.jsx";
import select_all_with_white_filled_circle from "../../../../src/assets/TransactionDashboardIcons/select_all_with_white_filled_circle.png";
import arrowdown_filled from "../../../../src/assets/TransactionDashboardIcons/arrowdown_filled.png";
import arrowup_filled from "../../../../src/assets/TransactionDashboardIcons/arrowup_filled.png";
import brower_file from "../../../../src/assets/TransactionDashboardIcons/browse_file.png";
import CloseIcon from "../../../assets/ViewAllTransactions/Clear filter.png";
import TimePicker from "../../../components/BeneTransaction/BeneAdditionalTimepicker/index.jsx";

const BeneAdditionDetails = () => {
  const [quantity, setQuantity] = useState("");
  const increment = () => setQuantity(Number(quantity) + 1);
  const decrement = () => setQuantity(Number(quantity) - 1);

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dates = Array.from({ length: 31 }, (_, i) => i + 1);

  const years = Array.from({ length: 25 }, (_, index) => 2000 + index);

  const [checkedStates, setCheckedStates] = useState([true, false]); // Initial state for two switches
  const [files, setFiles] = useState([]);

  const handleToggle = (index) => {
    setCheckedStates((prev) => {
      const newStates = [...prev];
      newStates[index] = !newStates[index]; // Toggle the state for the specific switch
      return newStates;
    });
  };

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  }, []);

  const handleDragOver = (event) => {
    event.preventDefault(); // Prevent default behavior to allow drop
  };

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const [link, setLink] = useState("");

  const handleClick = () => setLink("");

  const handleTimeConfirm = (selectedTime) => {
    console.log("Selected Time:", selectedTime);
  };

  return (
    <>
      <div className="addition-container">
        <div className="left-section">
          <div>
            <ActionDrpDown
              type={"checkbox"}
              title={`Heading (multi select)`}
              ddlValue={["Dummy1", "Dummy2"]}
              img={select_all_with_white_filled_circle}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <ActionDrpDown title={`Heading`} ddlValue={["Yes", "No"]} />
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <ActionDrpDown title={`Day`} ddlValue={days} />
            <ActionDrpDown title={`Date`} ddlValue={dates} />
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <ActionDrpDown title={`Month`} ddlValue={months} />
            <ActionDrpDown title={`Year`} ddlValue={years} />
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <div className="w-[100%]">
              <div className="headerText text-[12px]">Quantity</div>
              <div className="relative mt-[5px]">
                <input
                  type="number"
                  className="quantityInput w-[100%] shadow-lg"
                  value={quantity}
                  min="0"
                  max="100"
                  step="1"
                />
                <span className="inputBtn flex flex-col absolute gap-[1px]">
                  <button
                    className="flex incrementBtn justify-center rounded-tr-[10px]"
                    onClick={increment}
                  >
                    <img
                      src={arrowup_filled}
                      alt="arrow-up"
                      className="w-[20px]"
                    />
                  </button>
                  <button
                    className="decrementBtn flex justify-center rounded-br-[10px]"
                    onClick={decrement}
                  >
                    <img
                      src={arrowdown_filled}
                      alt="arrow-down"
                      className="w-[20px]"
                    />
                  </button>
                </span>
              </div>
            </div>
            <div className="w-[100%] mt-[5px]">
              <h1 className="text-[12px]">Set Time</h1>
              <TimePicker onConfirm={handleTimeConfirm} />
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            <ActionDrpDown
              title={`Heading (radio buttons)`}
              type={"radio"}
              ddlValue={["Dummy1", "Dummy2"]}
            />
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <ActionDrpDown title={`From Year`} ddlValue={years} />
            <ActionDrpDown title={`To Year`} ddlValue={years} />
          </div>
        </div>

        <div className="right-section">
          <article className="mb20">
            <input
              type="text"
              className="input_Box1"
              placeholder="Invoice number *"
              spellCheck={false}
              // id="inVoiceid"
              autoComplete="off"
            />
          </article>
          <article className="mb10">
            <h3>Heading (toggle button)</h3>
            <div
              className={`switch-section ${checkedStates[0] ? "" : "darker"}`}
              onClick={() => handleToggle(0)}
            >
              <p>
                <h4 className={`${checkedStates[0] ? "" : "darkTxt"}`}>
                  Dummy
                </h4>
                <h5 className={`${checkedStates[0] ? "" : "darkTxt"}`}>
                  Allow client to pay via NetBanking
                </h5>
              </p>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={checkedStates[0]}
                  onChange={() => handleToggle(0)}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </article>
          <article className="mb10">
            <h3>Heading (toggle button)</h3>
            <div
              className={`switch-section ${checkedStates[1] ? "" : "darker"}`}
              onClick={() => handleToggle(1)}
            >
              <p>
                <h4 className={`${checkedStates[1] ? "" : "darkTxt"}`}>
                  Dummy
                </h4>
              </p>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={checkedStates[1]}
                  onChange={() => handleToggle(1)}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </article>
          <article className="mt-[10px]">
            <label id="uploadId">Upload</label>
            <article
              className="mb25 flex gap-[15px] items-center"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              style={{
                border: "2px dashed #fff",
                borderRadius: "5px",
                padding: "10px 20px",
                margin: "5px 0 15px 0px",
              }}
            >
              <img src={brower_file} alt="" />
              {files.length === 0 ? (
                <label
                  htmlFor="file-input"
                  style={{ color: "#fff", display: "block" }}
                >
                  Drag and Drop your file here,
                  <br />
                  OR{" "}
                  <span
                    style={{
                      color: "#FF7F33",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Browse file
                  </span>
                </label>
              ) : (
                <div style={{ color: "#FF7F33" }}>
                  <ul>
                    {files.map((file) => (
                      <li key={file.name}>
                        {file.name}
                        <span
                          onClick={() => handleRemoveFile(file.name)}
                          style={{
                            cursor: "pointer",
                            color: "red",
                            marginLeft: "10px",
                          }}
                        >
                          &times; {/* Cross sign */}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <input
                type="file"
                multiple
                onChange={(e) => {
                  const selectedFiles = Array.from(e.target.files);
                  setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
                }}
                style={{ display: "none" }}
                id="file-input"
              />
            </article>
          </article>

          <article className="mt-[10px]">
            <label id="hyperlinkLBl" htmlFor="hyperLinkid">
              Hyperlink
            </label>
            <div className="relative">
              <input
                type="text"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                className="input_Box1"
                placeholder="Enter URL"
                spellCheck={false}
                style={{
                  marginTop: "5px",
                  backgroundColor: "#667CA3",
                  border: "none",
                }}
                id="hyperLinkid"
                autoComplete="off"
              />
              <button className="absolute top-[35%] right-[15px] h-[20px] pl-[5px] border-l-[2px] border-[#304b7d]">
                <img
                  src={CloseIcon}
                  alt=""
                  className="w-[14px] h-[14px]"
                  onClick={handleClick}
                />
              </button>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};

export default BeneAdditionDetails;
