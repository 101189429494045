import DefaultImage from "../../../assets/FileFormatIcons/Default.svg";

const FileFormatMapper = (fileFormat) => {
  if (!fileFormat) {
    return DefaultImage;
  }

  try {
    return require(`../../../assets/FileFormatIcons/${fileFormat}.svg`);
  } catch (error) {
    return DefaultImage;
  }
};

export default FileFormatMapper;
