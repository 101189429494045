import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { env } from "../../../../env";

const baseUrl = env.REACT_APP_BASE_URL;

export const beneViewAllAcc = createAsyncThunk(
  "beneViewAllAcc",
  async (values) => {
    const { page_no, page_size, approval_status } = values;
    try {
      const response = await axios.get(`${baseUrl}/accounts/beneficiary`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page_no,
          page_size: page_size,
          approval_status: approval_status,
          internal_account: values?.type === "internalAccount" ? "Y" :  "N",
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const beneViewAccDataSlice = createSlice({
  name: "beneViewAccData",
  initialState: {
    beneViewAllAccData: null,
    beneViewAllAccLoading: null,
    beneViewAllAccError: null,

  },
  extraReducers: (builder) => {
    builder.addCase(beneViewAllAcc.pending, (state, action) => {
      state.beneViewAllAccLoading = true;
    });
    builder.addCase(beneViewAllAcc.fulfilled, (state, action) => {
      state.beneViewAllAccLoading = false;
      state.beneViewAllAccData = action.payload;

    });
    builder.addCase(beneViewAllAcc.rejected, (state, action) => {
      state.beneViewAllAccError = true;
      state.beneViewAllAccData = null;
    });
  }
})
export default beneViewAccDataSlice.reducer;