import React, { useEffect, useState } from 'react'
import EditEntityLogo from '../../../../../assets/Settings/Entity Details/Edit details.png'
import { useSelector, useDispatch } from 'react-redux';
import DetailsMenuButton from '../../../../../components/Settings/Management/DetailsMenuButton';
import DetailsCards from '../../../../../components/Settings/Management/DetailsCards';
import DetailsDataCard from '../../../../../components/Settings/Management/DetailsDataCard';
import EditRoles from '../../../../../assets/Settings/Entity Details/Edit roles.svg'
import EditServices from '../../../../../assets/Settings/Entity Details/Edit services.svg'
import { getFeatures } from '../../../../../redux/features/Settings/Management/Features';
import FeatureDetailsBanner from '../../../../../components/Settings/Management/Features/FeatureDetailsBanner';


function FeatureDetails() {
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState("Details");

    const menuOptions = [
        "Details",
        "Services",
        "Roles",
        "Users",
        "Entities",
    ]

    useEffect(() => {
        dispatch(getFeatures({
            feature_id: "b8f4ce51-df36-40e2-8571-df4dfd7e5743"
        }))
    }, [])

    const featuresData = useSelector((state) => state.features?.features?.feature);
    const rolesData = useSelector((state) => state.features?.features?.user_roles);
    const servicesData = useSelector((state) => state.features?.features?.feature?.user_features_line_items);
    //Replace this with API data
    const data = {
        "Feature Privilege": {
            "Privilege": "Not given"
        },
        "Origin Timeline": {
            "Created by": featuresData?.created_by_name ? featuresData.created_by_name : "Not Given",
            "Created on": featuresData?.created_on ? featuresData.created_on : "Not Given",
        },
        "Checklist URL": {
            "URL": "Not Given",
        },

    }

    return (
        <div className='bg-[#244277] w-screen h-screen pt-24 flex no-scrollbar'>
            <div className='bg-[#244277] pt-10 w-2/3 shadow-xl pl-32 pr-10 no-scrollbar'>
                <FeatureDetailsBanner data={featuresData} />
                <div className='3xl:text-base 2xl:text-sm 2xl:w-9/12 xl:text-xs w-full py-5'>
                    <DetailsMenuButton menuOptions={menuOptions} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
                </div>
                {selectedOption === "Details" &&
                    <div className='flex gap-5'>
                        <div className='flex flex-col w-6/12 gap-5'>
                            <DetailsCards title={"Feature Privilege"} data={data["Feature Privilege"]} />
                            <DetailsCards title={"Origin Timeline"} data={data["Origin Timeline"]} />
                        </div>
                        <div className='flex flex-col w-6/12 gap-5'>
                            <DetailsCards title={"Checklist URL"} data={data["Checklist URL"]} />
                        </div>
                    </div>}
                {selectedOption === "Roles" &&
                    <div className='flex flex-wrap gap-x-10 gap-y-5'>{rolesData?.map((item) =>
                        <DetailsDataCard>{item.role_name}</DetailsDataCard>
                    )}
                    </div>}
                {selectedOption === "Features" &&
                    <div className='flex flex-col gap-5'>
                        <div className='text-white w-full flex justify-start items-center text-xl font-semibold'>Accounts</div>
                        <div className='flex flex-wrap gap-x-10 gap-y-5'>{featuresData?.map((item) =>
                            <DetailsDataCard>{item.feature_name}</DetailsDataCard>
                        )}
                        </div>
                    </div>}
                {
                    selectedOption === "Services" && <div className='flex flex-col gap-5'>
                        <div className='text-white w-full flex justify-start items-center text-xl font-semibold'>Accounts</div>
                        <div className='flex flex-wrap gap-x-10 gap-y-5'>{servicesData?.map((item) =>
                            <DetailsDataCard>{item.service}</DetailsDataCard>
                        )}
                        </div>
                    </div>
                }
                {
                    selectedOption === "Entities" && <div className='flex flex-col gap-5'>
                        <div className='text-white w-full flex justify-start items-center text-xl font-semibold'>A</div>
                        <div>
                            <DetailsDataCard>Hello iauhskjdhakjsd a kjshdajksbhd aihsdkjahsd ajhsdjkahsdkjh </DetailsDataCard>
                        </div>
                    </div>
                }
            </div>
            <div className='bg-[#1D3A6D] pl-10 pt-10 w-1/3'>
                <div className='mt-5'>
                    <div className='w-[60%] rounded-4xl grid grid-cols-1 divide-y divide-[#1D3A6D] p-3 bg-[#3A5686]'>
                        <div className='flex py-3 divide-x divide-[#1D3A6D]'>
                            <button className='flex flex-col items-center gap-2 w-1/3'>
                                <img src={EditEntityLogo} className='w-10 h-10' />
                                <div className='text-white text-xs'>Edit details</div>
                            </button>
                            <button className='flex flex-col items-center gap-2 w-1/3'>
                                <img src={EditServices} className='w-10 h-10' />
                                <div className='text-white text-xs'>Edit services</div>
                            </button>
                            <button className='flex flex-col items-center gap-2 w-1/3'>
                                <img src={EditRoles} className='w-10 h-10' />
                                <div className='text-white text-xs'>Edit roles</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FeatureDetails