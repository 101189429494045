import React, { useEffect, useState } from "react";
import dataLoading from "../../../../assets/LoaderIcons/Loader.gif";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import GeoMapLeaflet from "../../../../components/_utils/GeoMapLeaflet";
import refresh_balance from "../../../../assets/RejectIcons/refresh_balance.svg";
import arrowRight from "../../../../assets/ArrowsIcons/arrow_right_white.svg";
import arrowRightWhite from "../../../../assets/ArrowsIcons/ChevronRightWhite.svg";
import {
  verifyThisPANGet,
  selectedPan,
} from "../../../../redux/features/verifyPAN/verifyPAN";
import { beneVerifyThisGST } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { checkUsertoken } from "../../../../redux/features/login/login";
import formatGSTDate from "../../../../utils/formatGSTDate";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import { useNavigate } from "react-router-dom";
import gst_verification_blue from "../../../../assets/Settings/Verification/gst_verification_blue.svg";
import whiteLoader from "../../../../assets/LoaderIcons/xpentra lodemore 1.svg";
import { toast, Bounce } from "react-toastify";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import Loader from "../../../../components/Loader/Index";

const SettingGSTDetails = ({ setGstNotFound, hideSearch }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const gstRegex =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[A-Z0-9]{1}$/;

  const { beneVerifyGST, isGSTError, isGSTLoading } = useSelector(
    (state) => state.beneAllVerification
  );

  const toast_position_fectching = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    style: {
      // width: "229px",
      height: "49px",
      // borderRadius: "10px",
      color: "#F9F9F9",
      fontSize: "14px",
    },
  };

  const handleUpdateDetails = async () => {
    const paragraphStyle = {
      // width: "161px",
      height: "19px",
      textAlign: "left",
      font: "normal normal normal 14px/19px Open Sans",
      letterSpacing: "0px",
      color: "#F9F9F9",
      Opacity: 1,
    };
    const divStyle = {
      display: "flex",
      gap: "10px",
      // justifyContent: "center",
      alignItems: "center",
    };
    const imageStyle = {
      // width: "20px",
      height: "20px",
      Opacity: 1,
    };
    toast(
      <div style={divStyle} className="approving-toast-beneTxn">
        <img src={dataLoading} alt="" style={imageStyle} />
        <p style={paragraphStyle}>Fetching details</p>
      </div>,
      {
        position: "bottom-right",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        fontSize: "20px",
        transition: Bounce,
        style: {
          // width: window.innerWidth > 600 ? "229px" : window.innerWidth + "px",
          height: "49px",
          // borderRadius: "10px",
          backgroundColor: "#AE9041",
          color: "#fff",
        },
      }
    );

    const response = await dispatch(
      beneVerifyThisGST({
        search_string: beneVerifyGST?.gstin_details?.gstin_no,
      })
    );

    setTimeout(() => {
      if (response?.payload?.status === true) {
        toast.success("Updated successfully", toast_position_fectching);
      } else {
        toast.error("Failed to update", toast_position_fectching);
      }
    }, 2000);
  };

  return (
    <div className="gst-main-div">
      {loading && !beneVerifyGST ? (
        <div className="loader-container m-auto justify-center items-center flex">
          <Loader />
        </div>
      ) : (
        <>
          <div
            className={`div-fixed-setting-gst ${
              hideSearch ? "pt-[3rem] Qmd:pt-[6rem]" : ""
            }`}
          >
            <div className="w-auto bg-custom-card Qmd:pt-0">
              <div className="max-w-[1024px] mx-auto px-4 mt-[-2px] sm:mt-0">
                <div className="grid grid-cols-1 Qmd:grid-cols-4 items-center border-t border-b border-custom-appBack py-4 Qmd:py-0 Qmd:py-0">
                  <div className="flex flex-col">
                    <h4
                      className="pr-2 truncate lowercase first-line:capitalize text-Font_16 text-Primary_white pb-1 font-sans
                      font-normal leading-[21.79px] whitespace-normal
                      break-words"
                    >
                      {beneVerifyGST?.gstin_details?.business_name}
                    </h4>
                    <h6 className="text-xs text-Primary_grey">Company name</h6>
                  </div>

                  <div></div>
                  <div></div>

                  <div className="hidden Qmd:flex justify-end flex-col Qmd:flex-row items-center">
                    <div
                      className="w-[102px] h-[90px] bg-Blue_7 text-Primary_white flex flex-col items-center justify-center text-center cursor-pointer hover:bg-Blue_7_hover"
                      onClick={
                        //   () => {
                        // dispatch(
                        //   beneVerifyThisGST({
                        //     search_string:
                        //       beneVerifyGST?.gstin_details?.gstin_no,
                        //   })
                        // );
                        //   }
                        handleUpdateDetails
                      }
                    >
                      <img src={refresh_balance} className="w-6 h-6 mb-1" />
                      <span className="text-[10px] leading-tight">
                        Update fetched
                        <br />
                        details
                      </span>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 Qmd:grid-cols-3 items-center border-b border-custom-appBack py-4 Qmd:py-[15px] Qmd:py-[25px]">
                  <div className="border-b Qmd:border-b-0 Qmd:border-r border-custom-appBack flex justify-between items-center pb-4 Qmd:pb-0">
                    <div className="flex flex-col">
                      <h4
                        className="pr-2 truncate  text-Font_16 text-Primary_white font-sans font-normal
                        leading-[21.79px]  pb-1"
                      >
                        {beneVerifyGST?.gstin_details?.gstin_no}
                      </h4>
                      <h6 className="text-xs text-Primary_grey"> GST number</h6>
                    </div>
                  </div>

                  <div
                    className="cursor-pointer hover:bg-Blue_6_hover rounded-lg  Qmd:px-5 sm:px-0  Qmd:pb-0 Qmd:pb-[10px] pt-4 pb-4 Qmd:pt-[10px] Qmd:pt-0 sm:pt-4"
                    onClick={() => {
                      dispatch(checkUsertoken());
                      dispatch(
                        selectedPan(beneVerifyGST?.gstin_details?.pan_no)
                      );
                      dispatch(
                        verifyThisPANGet({
                          search_string: beneVerifyGST?.gstin_details?.pan_no,
                        })
                      );

                      navigate(`/settings/PAN_Verification`, {
                        state: beneVerifyGST?.gstin_details?.pan_no,
                      });
                    }}
                  >
                    <div className="pan-number flex flex-row gap-y-1 w-[98%] justify-between Qmd:w-[100%]">
                      <p className="text-Font_16 text-Primary_white font-sans font-normal leading-[21.79px] ">
                        {beneVerifyGST?.gstin_details?.pan_no}
                      </p>
                      <div className="grid place-content-center w-5 h-5 rounded-[3px] ml-3 bg-Blue_3">
                        <img src={arrowRightWhite} className="w-2.5 h-2.5" />
                      </div>
                    </div>
                    <h6 className="text-xs text-Primary_grey">PAN number</h6>
                  </div>
                  <div className="border-t Qmd:border-t-0 Qmd:border-b-0 Qmd:border-l border-custom-appBack Qmd:px-5 sm:px-0 sm:pb-0 Qmd:pb-0 Qmd:pb-0 pt-4 Qmd:pt-0 Qmd:pt-0 sm:pt-4">
                    <h4 className="text-sm text-Primary_white pb-1">
                      {formatGSTDate(
                        beneVerifyGST?.gstin_details?.date_of_registration
                      )}
                    </h4>
                    <h6 className="text-xs text-Primary_grey">Estd. on</h6>
                  </div>
                </div>
                <div className="grid grid-cols-1 Qmd:grid-cols-3 items-center pt-[1.25rem] Qmd:py-6 Qmd:py-6">
                  <div className="border-b Qmd:border-b-0 Qmd:border-r border-custom-appBack pr-4 pb-4 Qmd:pb-0">
                    <h4 className="text-sm text-Primary_white pb-1">
                      {beneVerifyGST?.gstin_details?.constitution_of_business}
                    </h4>
                    <h6 className="text-xs text-Primary_grey">
                      Class of company
                    </h6>
                  </div>

                  <div className="border-b Qmd:border-b-0 Qmd:border-r border-custom-appBack Qmd:px-5 sm:px-0 sm:pb-4 Qmd:pb-0 Qmd:pb-0 pt-4 pb-4 Qmd:pt-0 sm:pt-4">
                    <h4 className="text-sm text-Primary_white pb-1">
                      {beneVerifyGST?.gstin_details?.taxpayer_type}
                    </h4>
                    <h6 className="text-xs text-Primary_grey">
                      {" "}
                      Taxpayer type
                    </h6>
                  </div>

                  <div className="border-b Qmd:border-b-0 Qmd:border-r border-transparent Qmd:px-5 sm:px-0 sm:pb-4 Qmd:pb-0 Qmd:pb-0 pt-4 pb-4 Qmd:pt-0 sm:pt-4">
                    <h4 className="text-sm text-Primary_white pb-1">
                      {beneVerifyGST?.gstin_details?.status}
                    </h4>
                    <h6 className="text-xs text-Primary_grey">Status</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-Blue_7 block Qmd:hidden mt-5">
            <p className="bg-custom-lightBlue h-12 text-sm font-semibold text-Primary_white pl-[15px] flex items-center">
              Quick Actions
            </p>
            <div
              className="w-full h-[96px] text-Primary_white rounded flex flex-col items-center justify-center text-center cursor-pointer"
              onClick={handleUpdateDetails}
            >
              <img src={refresh_balance} alt="" className="w-8 h-8 mb-1" />
              <span className="text-xs leading-tight">
                Update fetched
                <br />
                details
              </span>
            </div>
          </div>
          <div className="max-w-[1024px] w-full mx-auto px-4 pb-5 Qmd:pb-0 Qmd:pb-0 pt-5 Qmd:pt-0 Qmd:pt-0">
            <button className="bg-Primary_white text-[#373F4B] rounded-4xl px-4 py-1 text-xs font-semibold my-5 hidden Qmd:block">
              Address
            </button>

            <div className="bg-[#314D7E] w-full flex flex-col Qmd:flex-row items-start justify-between rounded-[20px] p-5 gap-4">
              <div className="w-full Qmd:w-[60%] flex flex-col gap-1">
                <p className="text-Primary_grey text-sm font-semibold pb-2.5">
                  Corporate Address
                </p>
                <p className="text-Primary_white text-sm pb-1 first-letter:capitalize">
                  {beneVerifyGST?.gstin_details?.address}
                </p>
              </div>
              <div className="w-full Qmd:w-[40%] flex justify-center Qmd:justify-end">
                <GeoMapLeaflet
                  address={beneVerifyGST?.gstin_details?.address}
                  owner={"gstDetails"}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SettingGSTDetails;
