import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import arrowIcon from "../../../assets/TransactionDashboardIcons/arrow_next.png";
import { useSelector } from "react-redux";
import checkIfEnable from "../../../utils/checkIfEnable";
import ServiceIDByLabel from "../../../services/ServiceIDByLabel";

const MenuItemCardmobile = ({ icon, label, labelm, labela }) => {
  const services_list = useSelector((state) => state.refreshToken.services);
  const [showExandArrow, setShowExpandArrow] = useState(false);

  return (
    <Box
      onMouseLeave={() => setShowExpandArrow(false)}
      onMouseEnter={() => setShowExpandArrow(true)}
      sx={[
        checkIfEnable(ServiceIDByLabel, label, labelm, labela, services_list) &&
          {},
        !checkIfEnable(
          ServiceIDByLabel,
          label,
          labelm,
          labela,
          services_list
        ) && {},
      ]}
    >
      <div
        style={{
          width: "100%",
          float: "left",
          textAlign: "center",
          padding: "0px 20px 20px 10px",
          lineHeight: "20px",
          display: "flex",
          marginBottom: "-15px",
        }}
      >
        <div>
          <img
            width={"40px"}
            height={"40px"}
            src={require("../../../assets/TransactionDashboardIcons/" + icon)}
            alt=""
            style={{ margin: "10px auto" }}
          />
        </div>
        <div className="ml-[10px] mt-[20px] text-[#F9F9F9]">
          <span>{labelm + " " + labela}</span>
        </div>
      </div>
    </Box>
  );
};

export default MenuItemCardmobile;
