import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dataLoading from "../../../assets/Beneficiary Icons/inputloder.gif";
import NoAccountFound from "../../../assets/Beneficiary Icons/noaccfound.png";
import "./index.css";
import { formatAccountNumber } from "../../_utils/FormatAccountNumber";
import { useNavigate } from "react-router-dom";
import { beneCreateAccount } from "../../../redux/features/accounts/BeneficiaryAccount/createAccount";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import { checkUsertoken } from "../../../redux/features/login/login";
import ArrowLeft from "../../../assets/ArrowsIcons/arrow_left_white.svg";
import Loader from "../../Loader/Index";

function BeneAssociateAccountPopUp(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [creteDisabled, setCreateDisabled] = useState(false);
  const [loading, setLoading] = useState(true);

  const { beneLookData, beneLookLoading, verifyPANisLoading } = useSelector(
    (state) => state.verify
  );
  const categories = useSelector(
    (state) => state.beneAllVerification.beneTransCategories.categories
  );

  const bankData = useSelector(
    (state) => state.beneAllVerification.beneLookData
  );

  useEffect(() => {
    if (!beneLookLoading && !verifyPANisLoading) {
      setLoading(false);
    }
  }, [beneLookLoading, verifyPANisLoading]);

  let haveApproved = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "approved";
  });

  let havePending = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "pending";
  });
  let haveBankApproved = bankData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "approved";
  });

  let haveBankPending = bankData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "pending";
  });

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#4ddd37";
      case "denied":
      case "blocked":
        return "#ff5860";
      case "archived":
        return "#a3a3a3";
      default:
        return "#4ddd37";
    }
  };

  const handleClick = async () => {
    setCreateDisabled(true);
    try {
      let updatedPayload = { ...props.payload, navigate };
      if (
        props.selectedFields.length === categories.length ||
        props.selectedFields.length === 0
      ) {
        updatedPayload.categories = null;
      } else {
        updatedPayload = {
          ...updatedPayload,
          categories: props.selectedFields.toString(),
        };
      }
      dispatch(checkUsertoken());
      const { payload: data } = await dispatch(
        beneCreateAccount(updatedPayload)
      );
      const accountId = data.beneficiary_accounts[0].id;
      navigate(`/accounts/beneficiary_account/success/${accountId}`, {
        replace: true,
      });
    } catch (error) {
      console.error("Error creating account:", error);
      toast.error(error.base, toast_position);
    }
    setTimeout(() => {
      setCreateDisabled(false);
    }, 500);
  };

  const handleAssociateCloseModal = () => {
    props.setShowModalPopup(false);
  };

  const showPanButton = !!(haveApproved || havePending);
  const showBankButton = !!(haveBankApproved || haveBankPending);

  useEffect(() => {
    if (!showPanButton && showBankButton) {
      setIsActive(true);
    }
    sessionStorage.setItem("Create BeneAcc Fresh PAN", showPanButton);
    sessionStorage.setItem("Create BeneAcc Fresh Bank", showBankButton);
  }, [showPanButton, showBankButton]);

  return (
    <>
      <div
        className="fixed top-0 left-0 w-screen h-screen z-[9998]"
        style={{ backgroundColor: "#000000", opacity: "0.8" }}
      ></div>
      <div
        className="flex justify-center items-center fixed top-1/2 left-1/2 z-[9999]"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div className="h-fit w-60% flex flex-col bg-[#102C5D] rounded-2xl pb-5 mobile-crt-confirm-page-pop">
          <div className="mobile-view-title-bar">
            <div
              className="mobile-view-title-bar-icon"
              onClick={() => handleAssociateCloseModal()}
            >
              <img alt="icon" src={ArrowLeft} style={{ width: "10px" }} />
            </div>
            <div className="mobile-view-title-bar-label">
              Associated Accounts
            </div>
          </div>
          <div className="flex justify-between">
            <h6 className="text-[#FF7F33] text-[20px] text-center pr-6 pl-9 pt-5 mobile-view-title-message">
              Are you sure you want to create this account?
            </h6>
          </div>
          <div className="flex justify-between">
            <span className="text-[#F9F9F9] text-[14px] text-regular pr-6 pl-9 pb-3 pt-3">
              On creating a new account, it gets added to the list of active
              accounts below as a 'pending' account for the PAN and bank
              provided.
            </span>
          </div>

          <div className="associatebutton-crt-flow pr-6 pl-2">
            {showPanButton && (
              <Button
                className="associatebuttonPan"
                onClick={() => {
                  setIsActive(false);
                }}
                style={{
                  backgroundColor: !isActive ? "#ffffff" : "#373F4B",
                  color: !isActive ? "#1d1d1d" : "#f9f9f9",
                }}
              >
                Associated to PAN
              </Button>
            )}
            {showBankButton && (
              <Button
                className={`associatebutton associatebuttonBank ${
                  !showPanButton ? "left-aligned" : ""
                }`}
                onClick={() => {
                  setIsActive(true);
                }}
                style={{
                  backgroundColor: isActive ? "#ffffff" : "#373F4B",
                  color: isActive ? "#1d1d1d" : "#f9f9f9",
                }}
              >
                Associated to Bank
              </Button>
            )}
          </div>

          <div className="associatebutton-crt-flow-mobile">
            {showPanButton && (
              <Button
                className="associatebutton-crt-flow-PAN"
                onClick={() => {
                  setIsActive(false);
                }}
                style={{
                  backgroundColor: !isActive ? "#ffffff" : "#373F4B",
                  color: !isActive ? "#1d1d1d" : "#f9f9f9",
                }}
              >
                Associated to PAN
              </Button>
            )}
            {showBankButton && (
              <Button
                className="associatebutton-crt-flow-BANK"
                onClick={() => {
                  setIsActive(true);
                }}
                style={{
                  backgroundColor: isActive ? "#ffffff" : "#373F4B",
                  color: isActive ? "#1d1d1d" : "#f9f9f9",
                }}
              >
                Associated to Bank
              </Button>
            )}
          </div>

          <div className="w-full overflow-y-auto h-[445px] pr-7 pl-8 custom-scroll-container associate-pan-bank-mobile-container">
            {isActive ? (
              <div>
                {loading ? (
                  <div className="loader-container flex justify-center items-center mt-6">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <div className="">
                      {haveBankApproved || haveBankPending ? (
                        <>
                          {haveBankApproved && (
                            <>
                              <p className="text-xs text-[#F9F9F9] m-2">
                                Approved accounts
                              </p>
                              <div className="achieved_main">
                                <div className="achieved_scroll">
                                  <div className="flex flex-col gap-5 pb-[5px] py-2 adjust-padding-bottom">
                                    {bankData?.beneficiary_accounts.map(
                                      (val, index) => {
                                        if (
                                          val?.approval_status === "approved"
                                        ) {
                                          return (
                                            <div
                                              key={index}
                                              className="bg-[#3A5686] group p-4 flex flex-col group gap-2 drop-shadow-xl overflow-hidden"
                                            >
                                              <div className="flex flex-row -mr-4">
                                                <div
                                                  className="w-4/5 antialiased truncate text-transform: capitalize"
                                                  style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: " nowrap",
                                                    textTransform: "capitalize",
                                                    color: getStatusColor(
                                                      val.approval_status
                                                    ),
                                                  }}
                                                >
                                                  {val.name.toLowerCase()}
                                                </div>
                                              </div>
                                              <div className="flex flex-row justify-between">
                                                <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                  {val.pan_no}
                                                  <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                    PAN number
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        return null;
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {haveBankPending && (
                            <div className="">
                              <p className="text-xs text-[#F9F9F9] m-2">
                                Pending accounts
                              </p>
                              <div className="achieved_main">
                                <div className="achieved_scroll">
                                  <div className="flex flex-col gap-5 pb-[5px] py-2">
                                    {bankData?.beneficiary_accounts.map(
                                      (val, index) => {
                                        if (
                                          val?.approval_status === "pending"
                                        ) {
                                          return (
                                            <div
                                              key={index}
                                              className="bg-[#3A5686] group p-4 flex flex-col group gap-2 drop-shadow-xl overflow-hidden"
                                            >
                                              <div className="flex flex-row -mr-4">
                                                <div
                                                  className="w-4/5 antialiased truncate text-transform: capitalize"
                                                  style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: " nowrap",
                                                    textTransform: "capitalize",
                                                    color: getStatusColor(
                                                      val.approval_status
                                                    ),
                                                  }}
                                                >
                                                  {val.name.toLowerCase()}
                                                </div>
                                              </div>
                                              <div className="flex flex-row justify-between">
                                                <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                  {val.pan_no}
                                                  <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                    PAN number
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        return null;
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="w-full h-full flex flex-col justify-center items-center gap-5 text-center overflow-hidden">
                          <div className="w-[40%] p-4 flex flex-row justify-center">
                            <img
                              className="max-w-[145%] pt-5"
                              src={NoAccountFound}
                              alt="NoDataImg"
                            />
                          </div>
                          <div className="text-white text-lg antialiased">
                            <p>
                              No associated accounts found for the PAN details
                              provided
                              <br />
                              <span className="text-xs">
                                There are no existing accounts that are
                                associated with the PAN details provided.
                              </span>
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div>
                {loading ? (
                  <div className="loader-container flex justify-center items-center h-full mt-6">
                    <Loader />
                  </div>
                ) : (
                  <>
                    {haveApproved || havePending ? (
                      <>
                        {haveApproved && (
                          <>
                            <p className="text-xs text-[#F9F9F9] m-2">
                              Approved accounts
                            </p>
                            <div className="achieved_main">
                              <div className="achieved_scroll">
                                <div className="flex flex-col gap-5 pb-[5px] py-2">
                                  {beneLookData?.beneficiary_accounts.map(
                                    (val, index) => {
                                      if (val?.approval_status === "approved") {
                                        return (
                                          <div
                                            key={index}
                                            className="bg-[#3A5686] group p-4 flex flex-col group gap-2 drop-shadow-xl overflow-hidden"
                                          >
                                            <div className="flex flex-row -mr-4">
                                              <div
                                                className="w-4/5 antialiased truncate text-transform: capitalize"
                                                style={{
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: " nowrap",
                                                  textTransform: "capitalize",
                                                  color: getStatusColor(
                                                    val.approval_status
                                                  ),
                                                }}
                                              >
                                                {val.name.toLowerCase()}
                                              </div>
                                              <div className="w-2/5 text-sm flex flex-row gap-1 justify-end">
                                                <div
                                                  className={`flex justify-center text-center bg-white -mb-4 -mt-9`}
                                                  style={{
                                                    position: "relative",
                                                    width: "74px",
                                                    height: "100px",
                                                    marginTop: "-15px",
                                                  }}
                                                >
                                                  <div className="flex items-center justify-center">
                                                    <img
                                                      src={require("../../../assets/IfscLogo/" +
                                                        IfscCodeMapper(
                                                          val?.ifsc_code
                                                        ))}
                                                      alt="bank"
                                                      className="view-all-bank-image"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="flex flex-row justify-between mt-[-48px]">
                                              <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                {formatAccountNumber(
                                                  val.bank_acc_no
                                                )}
                                                <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                  Bank account number
                                                </div>
                                              </div>

                                              <div className="flex flex-col pr-16 text-[#FAFAFA] visible self-center text-base antialiased">
                                                {val.ifsc_code}
                                                {val.ifsc_code && (
                                                  <div className="text-[#D5D5D5] text-xs antialiased ml-8">
                                                    IFSC code
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                      return null;
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {havePending && (
                          <>
                            <p className="text-xs text-[#F9F9F9] m-2">
                              Pending accounts
                            </p>
                            <div className="achieved_main">
                              <div className="achieved_scroll">
                                <div className="flex flex-col gap-5 pb-[5px] py-2">
                                  {beneLookData?.beneficiary_accounts.map(
                                    (val, index) => {
                                      if (val?.approval_status === "pending") {
                                        return (
                                          <div
                                            key={index}
                                            className="bg-[#3A5686] group p-4 flex flex-col group gap-2 drop-shadow-xl overflow-hidden"
                                          >
                                            <div className="flex flex-row -mr-4">
                                              <div
                                                className="w-4/5 antialiased truncate text-transform: capitalize"
                                                style={{
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: " nowrap",
                                                  textTransform: "capitalize",
                                                  color: getStatusColor(
                                                    val.approval_status
                                                  ),
                                                }}
                                              >
                                                {val.name.toLowerCase()}
                                              </div>
                                              <div className="w-2/5 text-sm flex flex-row gap-1 justify-end">
                                                <div
                                                  className={`flex justify-center text-center bg-white -mb-4 -mt-9`}
                                                  style={{
                                                    position: "relative",
                                                    width: "74px",
                                                    height: "100px",
                                                    marginTop: "-15px",
                                                  }}
                                                >
                                                  <div className="flex items-center justify-center">
                                                    <img
                                                      src={require("../../../assets/IfscLogo/" +
                                                        IfscCodeMapper(
                                                          val?.ifsc_code
                                                        ))}
                                                      alt="bank"
                                                      className="view-all-bank-image"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="flex flex-row justify-between mt-[-48px]">
                                              <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                {formatAccountNumber(
                                                  val.bank_acc_no
                                                )}
                                                <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                  Bank account number
                                                </div>
                                              </div>

                                              <div className="flex flex-col pr-16 text-[#FAFAFA] visible self-center text-base antialiased">
                                                {val.ifsc_code}
                                                {val.ifsc_code && (
                                                  <div className="text-[#D5D5D5] text-xs antialiased ml-8">
                                                    IFSC code
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                      return null;
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <div className="w-full h-full flex flex-col justify-center items-center gap-5 text-center overflow-hidden">
                        <div className="w-[40%] p-4 flex flex-row justify-center">
                          <img
                            className="max-w-[145%] pt-5"
                            src={NoAccountFound}
                            alt="NoDataImg"
                          />
                        </div>
                        <div className="text-white text-lg antialiased">
                          <p>
                            No associated accounts found for the PAN details
                            provided
                            <br />
                            <span className="text-xs">
                              There are no existing accounts that are associated
                              with the PAN details provided.
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "6%",
              margin: "0px 50px 0px 40px",
              width: "100%",
            }}
            className="pr-8 pt-4 m-4 create-bene-account-btn"
          >
            <Button
              sx={{
                borderColor: "none",
                color: "#F9F9F9",
                textTransform: "capitalize",
                textDecoration: "underline",
                backgroundColor: "transparent",
                fontFamily: "Open Sans",
                fontSize: "14px",
              }}
              onClick={handleAssociateCloseModal}
            >
              Cancel
            </Button>
            <Button
              disabled={creteDisabled}
              sx={{
                borderRadius: "20px",
                "&.Mui-disabled": {
                  color: "#F9F9F9",
                },
                "&.MuiButton-root": {
                  color: "#F9F9F9",
                },
                boxShadow: "0px 3px 6px #00000033",
                textTransform: "capitalize",
                fontFamily: "Open Sans",
                fontSize: "14px",
                backgroundColor: "#69B76F",
                cursor: "pointer",
                border: "transparent",
                marginRight: "40px",
                width: "110px",
                "&:hover": {
                  backgroundColor: "#69B76F",
                  boxShadow: "none",
                },
              }}
              onClick={handleClick}
            >
              Create
            </Button>
          </div>
          <div className="create-bene-account-btn-mobile">
            <Button
              sx={{
                borderColor: "none",
                color: "#fff",
                fontFamily: "Open Sans",
                fontSize: "14px",
              }}
              className="create-bene-account-btn-mobile-btn"
              onClick={() => handleClick()}
            >
              Create
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BeneAssociateAccountPopUp;
