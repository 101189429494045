import React from "react";
import { useNavigate } from "react-router-dom";
import CardLogo from "../../../_utils/logo/CardLogo";
import "./index.css";
import IfscCodeMapper from "../../../_utils/IfscLogoMapper";
import FormatAmount from "../../../_utils/FormatAmount/FormatAmount";
import { formatAccountNumber } from "../../../_utils/FormatAccountNumber";

const OwnerDashboardAccountList = (props) => {
  const navigate = useNavigate();
  const accountNumber = props.accountNumber;
  const id = props.ownerId;
  const balance = props.balance;

  const lastFetchedAt = props.lastFetchedAt;
  const status = props.status;
  const bank_logo = IfscCodeMapper(props.bankName);
  const connected_banking = props.connected_banking;

  function formatWithSpaces(input) {
    return input.replace(/(\d{4})(?=\d)/g, "$1 ");
  }

  return (
    <div
      onClick={() => {
        navigate(`/accounts/owner/owner_account_details/${id}`);
      }}
      className="dashboard-card"
    >
      <div className="bg-custom-card rounded-3xl flex flex-col justify-center gap-3 min-w-full py-4 4xl:py-6 5xl:py-8 6xl:py-10 overflow-hidden hover:bg-custom-cardHover cursor-pointer drop-shadow-xl antialiased group">
        <div className="flex flex-row w-full gap-1">
          <div
            className="w-[70%] font-semibold flex flex-row justify-between gap-5
            pl-4 4xl:pl-4 5xl:pl-4 6xl:pl-4"
            style={{
              color:
                connected_banking === "Y"
                  ? "#4DDD37"
                  : connected_banking === "N"
                  ? "#FF5860"
                  : "",
            }}
          >
            <p>{formatAccountNumber(accountNumber)}</p>
          </div>
          <div className="bank-logo">
            <CardLogo
              bank={bank_logo}
              divCssProps={"rounded-bl-3xl 5xl:rounded-bl-large drop-shadow-xl"}
            />
          </div>
        </div>
        {console.log(isNaN(balance))}
        <div className="font-semibold text-md text-gray-300 w-[70%] pr-4 4xl:pr-6 5xl:pr-8 6xl:pr-10 pl-4 flex flex-col items-start">
          <span className="balance-amt">
            {balance ? (
              <>
                ₹{" "}
                {isNaN(props.balance) ||
                props.balance == "xxxx.xx" ||
                props.balance === null ||
                props.balance === undefined ? (
                  "Redacted"
                ) : (
                  <FormatAmount
                    price={
                      props.balance === "0"
                        ? "0.0"
                        : props.balance === "0.0"
                        ? "0.0"
                        : (
                            Math.floor(Number(props.balance) * 100) / 100
                          ).toFixed(2)
                    }
                  />
                )}
              </>
            ) : (
              "Not available"
            )}
          </span>
          <span className="balance-text">
            {lastFetchedAt ? "Updated -" : "Balance"} {lastFetchedAt}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OwnerDashboardAccountList;
