import React from "react";
import { styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import { useLocation } from "react-router-dom";
import "./index.css";
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  return {};
});

function handleClick(event) {
  event.preventDefault();
}

export default function CustomizedBreadcrumbs() {
  const uuidPattern =
    /\b[a-fA-F\d]{8}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{4}-[a-fA-F\d]{12}\b/;
  const containsNumberAndUppercase = /(?=.*\d)(?=.*[A-Z])/;

  const excludedPaths = [
    "/accounts/beneficiary_account/success/",
    "/transaction/beneficiary_transaction/success/",
    "/transaction/beneficiary_transaction/failed/",
    "/transaction/internal_transaction/success/",
    "/internal_transaction/multi_approval_transaction/success",
    "/internal_transaction/multi_approval_transaction/error",
    "/internal_transaction/multi_approval_transaction/declined",
  ];

  const path = useLocation();
  const isExcludedPath = excludedPaths.some((excludedPath) =>
    path.pathname.startsWith(excludedPath)
  );

  if (isExcludedPath) {
    return null;
  }

  let breadcrumbs = path.pathname.split("/");
  breadcrumbs = breadcrumbs.filter(
    (bread) =>
      bread !== "" &&
      !uuidPattern.test(bread) &&
      !containsNumberAndUppercase.test(bread)
  );

  return (
    <div
      className="max-w-5xl mx-auto border-t-[2px] border-Primary_grey"
      role="presentation"
    >
      <Breadcrumbs
        separator=">"
        aria-label="breadcrumb"
        className="breadcrumb-label"
      >
        {breadcrumbs?.map((crumb, index) => (
          <StyledBreadcrumb
            key={index}
            sx={{ background: "none" }}
            component="a"
            // href="#"
            // onClick={handleClick}
            label={
              <p
                className="capitalize text-Font_14  font-sans"
                style={{
                  color:
                    index === breadcrumbs.length - 1
                      ? "var(--Blue_5)"
                      : "var(--Secondary_grey)",
                  fontWeight:
                    index === breadcrumbs.length - 1
                      ? "var(--Font_semibold)"
                      : "var(--Font_normal)",
                  margin: "0 !important",
                  padding: "0 !important",
                }}
              >
                {crumb.replaceAll("_", " ")}
              </p>
            }
          />
        ))}
      </Breadcrumbs>
    </div>
  );
}
