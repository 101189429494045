export function formatAccountNumber(accountNumber) {
  // if (typeof accountNumber !== "string") {
  //   return null;
  // }
  // const formattedNumber = accountNumber.replace(/(.{4})/g, "$1 ");
  // return formattedNumber.trim();
  if (typeof accountNumber !== "string") {
    return null;
  }

 
  const cleanedNumber = accountNumber.replace(/\s/g, "");

  
  const remainder = cleanedNumber.length % 4;
  const firstGroupLength = remainder === 0 ? 4 : remainder;

  
  const groups = [];
  if (firstGroupLength > 0) {
    groups.push(cleanedNumber.slice(0, firstGroupLength));
  }

  for (let i = firstGroupLength; i < cleanedNumber.length; i += 4) {
    groups.push(cleanedNumber.slice(i, i + 4));
  }

  
  return groups.join(" ");
}
