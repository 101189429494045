import React from "react";
import "./index.css";
import BeneAssociateAccountPopUp from "../BeneAssociatedPop-Up";
import { formatAccountNumber } from "../../_utils/FormatAccountNumber";
import User from "../../../assets/UserProfileIcons/user.svg";
import Contact from "../../../assets/ContactIcons/Contact.svg";
import Gmail from "../../../assets/MailIcons/gmail_with_white_circle.svg";
import { Tooltip, tooltipClasses } from "@mui/material";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import BeneIcon from "../../../assets/CategoryIcons/beneficiary_category_default.svg";

const BeneConfirmAccount = React.memo(
  ({
    payload,
    selectedFields,
    bankAccountName,
    setShowModalPopup,
    showModalPopup,
    bankUserInfo,
  }) => {
    return (
      <>
        {showModalPopup && (
          <div className="fixed top-0 left-0 w-full h-full z-[20000]">
            <BeneAssociateAccountPopUp
              setShowModalPopup={setShowModalPopup}
              showModalPopup={showModalPopup}
              payload={payload}
              selectedFields={selectedFields}
              onClose={() => setShowModalPopup(false)}
            />
          </div>
        )}
        <div className="beneConfirm_Main">
          <div className="dividecon flex flex-col gap-5">
            <div className="flex flex-col bg-[#3A5686] rounded-[20px] gap-4 px-8">
              <div>
                <div className="py-5">
                  <div className="text-[#DADADA] leading-[21.79px] font-semibold text-base">
                    Beneficiary's PAN Details
                  </div>
                </div>
                <div className="rounded-b-large flex flex-col drop-shadow-xl gap-4">
                  <div className="drop-shadow-xl h-full rounded-b-large flex flex-col pb-5">
                    <div className="flex flex-col gap-2.5">
                      <div className="space-y-1">
                        <div className="w-full text-[#F9F9F9] capitalize self-center font-normal text-base leading-[21.79px]">
                          <p className="w-72 overflow-hidden truncate">
                            {payload?.panNumber}
                          </p>
                        </div>
                        <div className="text-xs leading-[16.34px] font-normal text-[#DADADA]">
                          PAN number
                        </div>
                      </div>
                      <>
                        <div className="h-[0.5px] bg-[#102C5D] opacity-100 mt-[2px] mb-[2px]"></div>
                        <div className="space-y-1">
                          <div className="w-full text-[#F9F9F9] capitalize self-center font-normal text-base leading-[21.79px]">
                            <p className="overflow-hidden text-wrap">
                              <Tooltip
                                title={payload?.legalName}
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                arrow
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                              >
                                {payload?.legalName?.toLowerCase()}
                              </Tooltip>
                            </p>
                          </div>
                          <div className="text-xs leading-[16.34px] font-normal text-[#DADADA]">
                            Legal name
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-[#3A5686] rounded-[20px] gap-4 px-8">
              <div>
                <div className="py-5">
                  <div className="text-[#DADADA] leading-[21.79px] font-semibold text-base">
                    Beneficiary's GST Details
                  </div>
                </div>
                <div className="rounded-b-large flex flex-col drop-shadow-xl gap-4">
                  <div className="drop-shadow-xl h-full rounded-b-large flex flex-col pb-5">
                    <div className="flex flex-col gap-2.5">
                      <div className="space-y-1">
                        <div className="w-full text-[#F9F9F9] capitalize self-center font-normal text-base leading-[21.79px]">
                          <p className="w-72 overflow-hidden truncate">
                            {payload.gstin ? (
                              <span>{payload.gstin}</span>
                            ) : (
                              <span>Not Given</span>
                            )}
                          </p>
                        </div>
                        <div className="text-xs leading-[16.34px] font-normal text-[#DADADA]">
                          GSTIN (Optional)
                        </div>
                      </div>
                      <>
                        {payload.gstin ? (
                          <>
                            <div className="h-[1px] bg-[#102C5D] opacity-100 mt-[2px] mb-[2px]"></div>
                          </>
                        ) : null}

                        <div className="space-y-1">
                          <div className="w-full text-[#F9F9F9] capitalize self-center font-normal text-base leading-[21.79px]">
                            <p className="overflow-hidden text-wrap">
                              <Tooltip
                                title={payload?.gst_org_name}
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                arrow
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                              >
                                {payload.gstin
                                  ? payload?.gst_org_name?.toLowerCase()
                                  : null}
                              </Tooltip>
                            </p>
                          </div>
                          <div className="text-xs leading-[16.34px] font-normal text-[#DADADA]">
                            {payload.gstin ? "Company name" : null}
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-[#3A5686] rounded-[20px] gap-4 px-8">
              <div>
                <div className="py-5">
                  <div className="text-[#DADADA] leading-[21.79px] font-semibold text-base">
                    Beneficiary's Bank Details
                  </div>
                </div>
                <div className="rounded-b-large flex flex-col drop-shadow-xl gap-4">
                  <div className="h-full rounded-b-large flex flex-col pb-5">
                    <div className="flex flex-col gap-2.5">
                      <div className="space-y-5">
                        <div className="space-y-1">
                          <div className="w-full text-[#F9F9F9] font-normal capitalize leading-[21.79px] text-base truncate ...">
                            {formatAccountNumber(payload?.bank_account)}
                          </div>
                          <div className="text-xs leading-[16.34px] font-normal text-[#DADADA]">
                            Bank account number
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="space-y-1 w-4/6">
                            <div className=" text-[#F9F9F9] font-normal capitalize leading-[21.79px] text-base truncate ...">
                              {payload?.ifsc_code}
                            </div>
                            <div className="text-xs leading-[16.34px] font-normal text-[#DADADA]">
                              IFSC code
                            </div>
                          </div>
                          <div className="bank-logo-img-crt">
                            <img
                              src={require("../../../assets/IfscLogo/" +
                                IfscCodeMapper(payload?.ifsc_code))}
                              alt="bank"
                              className="bank-logo-img-size"
                            />
                          </div>
                        </div>
                      </div>
                      <>
                        <div className="h-[1px] bg-[#102C5D] opacity-100 mt-[2px] mb-[2px]"></div>
                        <div className="space-y-1">
                          <div className="w-full text-[#F9F9F9] font-normal capitalize leading-[21.79px] text-base truncate ...">
                            {bankAccountName?.toLowerCase()}
                          </div>
                          <div className="text-xs leading-[16.34px] font-normal text-[#DADADA]">
                            Account holder's name
                          </div>
                        </div>
                        {bankUserInfo.length >= 1 ? (
                          <>
                            <div className="space-y-1 mt-2">
                              <div className="w-full text-[#F9F9F9] font-normal capitalize leading-[21.79px] text-base truncate ...">
                                {bankUserInfo[0].branch
                                  ? bankUserInfo[0].branch
                                  : "NA"}
                              </div>
                              <div className="text-xs leading-[16.34px] font-normal text-[#DADADA]">
                                Branch
                              </div>
                            </div>
                            <div className="space-y-1 mt-2">
                              <div className="w-full text-[#F9F9F9] font-normal capitalize leading-[21.79px] text-base truncate ...">
                                {bankUserInfo[0].city
                                  ? bankUserInfo[0].city
                                  : "NA"}
                              </div>
                              <div className="text-xs leading-[16.34px] font-normal text-[#DADADA]">
                                City
                              </div>
                            </div>
                            <div className="space-y-1 mt-2">
                              <div className="w-full text-[#F9F9F9] font-normal capitalize leading-[21.79px] text-base truncate ...">
                                {bankUserInfo[0].district
                                  ? bankUserInfo[0].district
                                  : "NA"}
                              </div>
                              <div className="text-xs leading-[16.34px] font-normal text-[#DADADA]">
                                District
                              </div>
                            </div>
                            <div className="space-y-1 mt-2">
                              <div
                                className="w-full text-[#F9F9F9] font-normal capitalize leading-[21.79px] text-base"
                                style={{
                                  textWrap: "wrap",
                                  wordWrap: "break-word",
                                }}
                              >
                                {bankUserInfo[0].address
                                  ? bankUserInfo[0].address
                                  : "NA"}
                              </div>
                              <div className="text-xs leading-[16.34px] font-normal text-[#DADADA]">
                                Address
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dividecon flex flex-col gap-5 mobilewidthconfirmpage">
            <div className="flex flex-col bg-[#3A5686] py-5 rounded-[20px] gap-4 px-8">
              <div>
                <div>
                  <div className="text-[#DADADA] leading-[21.79px] font-semibold text-base">
                    Beneficiary's Contact Details
                  </div>
                </div>
                <div className="rounded-b-large flex flex-col drop-shadow-xl gap-5 pt-5 pb-5">
                  <div className="drop-shadow-xl h-full rounded-b-large flex flex-row">
                    <div className="flex flex-col">
                      <div className="flex flex-row gap-x-7">
                        <div className="w-5 mt-2">
                          <img src={User} alt="User" />
                        </div>
                        <div className="w-full text-[#F9F9F9] capitalize pb-0.5 self-center font-normal text-base">
                          <p className="w-[350px] overflow-hidden mobile-crt-confirm-page-legal-name">
                            <Tooltip
                              title={payload?.account_name}
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              arrow
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              {payload?.account_name?.toLowerCase()}
                            </Tooltip>
                          </p>
                        </div>
                      </div>
                      <div className="text-xs leading-[16.34px] font-normal text-[#DADADA] pl-[46px]">
                        {payload?.account_name?.toLowerCase() ==
                        payload?.legalName?.toLowerCase()
                          ? "Account name - Using legal name"
                          : "Account name"}
                      </div>
                    </div>
                  </div>
                  <div className="drop-shadow-xl h-full rounded-b-large flex flex-row">
                    <div className="flex flex-col">
                      <div className="flex flex-row gap-x-7">
                        <div className="w-5 mt-2">
                          <img src={Contact} alt="Contact" />
                        </div>
                        <div className="w-full text-[#F9F9F9] font-normal capitalize pb-0.5 self-center text-base">
                          <p className="w-72 overflow-hidden truncate">
                            {"+91-"}
                            {payload?.contact_no}
                          </p>
                        </div>
                      </div>
                      <div className="text-xs leading-[16.34px] font-normal text-[#DADADA] pl-[46px]">
                        Contact Number
                      </div>
                    </div>
                  </div>

                  <div className="drop-shadow-xl h-full rounded-b-large flex flex-row">
                    <div className="flex flex-col">
                      <div className="flex flex-row gap-x-7">
                        <div className="w-5 mt-2">
                          <img src={Gmail} alt="Gmail" />
                        </div>
                        <div className="w-full text-[#F9F9F9] font-normal pb-0.5 self-center text-base">
                          <p
                            id="crt-bene-acc-email-id-firstLetter"
                            className="w-72 overflow-hidden truncate"
                          >
                            <Tooltip
                              title={payload?.email_id}
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              arrow
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              {payload?.email_id.toLowerCase()}
                            </Tooltip>
                          </p>
                        </div>
                      </div>
                      <div className="text-xs leading-[16.34px] font-normal text-[#DADADA] pl-[46px]">
                        Email ID
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col h-80 overflow-hidden scrollbar-thumb-gray-400 scrollbar-track-gray-100 bg-[#3A5686] rounded-[20px] gap-4">
              <div>
                <div className="py-5 px-8">
                  <div className="text-[#DADADA] leading-[21.79px] font-semibold text-base">
                    Assigned Beneficiary Categories
                  </div>
                </div>
                <div className="h-56 overflow-auto py-5 px-8">
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col gap-5">
                      {selectedFields.map((categoryName, index) => (
                        <div
                          key={index}
                          className="bg-[#506994] rounded-[10px] opacity-100 flex flex-row"
                        >
                          <div className="categories-label-iocn">
                            <img
                              className="w-9 h-9 cateEditImage"
                              src={BeneIcon}
                              alt="bene"
                            />
                          </div>
                          <p className="text-[#F9F9F9] font-normal text-base py-2.5 px-3 leading-[21.79px] confirmCategoryName">
                            <Tooltip
                              title={categoryName}
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              arrow
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              {categoryName}
                            </Tooltip>
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default BeneConfirmAccount;
