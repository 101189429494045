const CardLogo = (props) => {
  const defaultDivCss = "rounded-tl-4xl 5xl:rounded-tl-large drop-shadow-xl";
  const divCssProps = props?.divCssProps || defaultDivCss;
  const scheme_logo = props?.scheme_logo || null;
  const bank = scheme_logo || props.bank;

  const cardLogoImage = () => {
    if (scheme_logo) {
      return (
        <img
          className="w-[70%]"
          src={require("../../../assets/BankLogos/Card Logos/" + bank + ".png")}
          alt="BankLogo"
        />
      );
    } else {
      return (
        <img
          className="w-[75%]"
          src={require("../../../assets/IfscLogo/" + bank)}
          alt="BankLogo"
        />
      );
    }
  };

  return (
    <div
      className={`flex py-2 justify-center group-hover:bg-blue-100 text-center bg-white rounded-tl-[10px] rounded-br-[10px]`}
    >
      {cardLogoImage()}
    </div>
  );
};
export default CardLogo;
