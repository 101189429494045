import dataLoading from "../../assets/Beneficiary Icons/inputloder.gif";

const Loader = ({ height = "50px", weight = "50px" }) => {
  const loaderStyle = {
    height,
    weight,
  };
  return (
    <div>
      <img alt="data loader" src={dataLoading} style={loaderStyle} />
    </div>
  );
};
export default Loader;
