import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import noaccount from "../../../assets/No transaction found.svg";
import dataLoading from "../../../assets/LoaderIcons/Loader.gif";
import MyDeviceCard from "../../../components/MyDevices/MyDeviceCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { checkUsertoken } from "../../../redux/features/login/login";
import { getAllDevices } from "../../../redux/features/devices/devices";
import arrow_left_white from "../../../assets/ArrowsIcons/arrow_left_white.svg";
import PaginationComponent from "../../../components/_utils/PaginationComponent";
function MyDevices() {
  const location = useLocation();
  const [allDevicesData, setAllDevicesData] = useState([]);
  const [allDevices, setAllDevices] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
  const [currentPage, setCurrentPage] = useState(1);
  const [mainData, setMainData] = useState([]);
  const [pageSize, setPageSize] = useState(
    window.innerWidth >= 3840 ? 192 : window.innerWidth >= 2560 ? 102 : 50
  );
  const [totalCount, setTotalCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([50, 100]);
  const [selectedValue, setSelectedValue] = useState(50);
  const [storedPage, setStoredPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const isDeviceDeleted = location.state
    ? location.state.isDeviceDeleted
    : false;

  const services = useSelector((state) => state.refreshToken.services);

  const navigate = useNavigate();

  useEffect(() => {
    if (isDeviceDeleted) {
      toast.success("Device has been deleted successfully", toast_position);
      navigate("/settings/Devices");
    }
  }, []);

  const isFirstRender = useRef(true);
  const [dataFetched, setDataFetched] = useState(false);
  const getLoggedInDevices = async () => {
    if (!isFirstRender.current) {
      setLoading(true);
    }

    setDataFetched(false);

    try {
      if (!services || services.length === 0) {
        return;
      }

      if (services.includes("Sup_VDevice")) {
        dispatch(checkUsertoken());

        const response = await dispatch(
          getAllDevices({ page_no: currentPage, page_size: pageSize })
        );

        if (response?.payload) {
          setMainData(response.payload);
          setAllDevicesData(response.payload);
          setTotalCount(response.payload?.total_count || 0);
          setAllDevices(response.payload?.devices || []);
          setDataFetched(true);
        } else {
          console.log("No data received from API");
        }
      } else {
        console.log("Service not available for Sup_VDevice");
      }
    } catch (err) {
      console.error("API Error:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (services && !services.includes("Sup_VDevice")) {
      navigate("/feature_not_assigned");
    } else if (isFirstRender.current) {
      isFirstRender.current = false;
    } else if (services.length > 0) {
      getLoggedInDevices();
    } else {
      console.log("Skipping API call - services not available yet");
    }
  }, [currentPage, pageSize, services]);

  const fetchMoreDevices = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // function handleWindowSizeChange() {
  //   const screenWidth = window.innerWidth;

  //   if (screenWidth >= 1140 && screenWidth < 2560) {
  //     setPageSize(50);
  //   } else if (screenWidth >= 2560 && screenWidth < 3840) {
  //     setPageSize(102);
  //   } else if (screenWidth >= 3840) {
  //     setPageSize(192);
  //   }
  // }

  // useEffect(() => {
  //   handleWindowSizeChange();
  //   window.addEventListener("resize", handleWindowSizeChange);

  //   return () => {
  //     window.removeEventListener("resize", handleWindowSizeChange);
  //   };
  // }, []);
  useEffect(() => {
    const newTotalPages = Math.ceil(totalCount / pageSize);
    setTotalPages(newTotalPages);

    setCurrentPage((prevPage) => {
      if (newTotalPages === 0) {
        return 1;
      }
      if (prevPage > newTotalPages) {
        return newTotalPages;
      }
      return prevPage;
    });
  }, [totalCount, pageSize]);
  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 800);
  };
  window.addEventListener("resize", handleResize);
  const handlePagination = (page) => {
    setCurrentPage(page);
  };
  const gotHome = () => {
    navigate(-1);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleSelect = (value) => {
    setSelectedValue(value);
    setPageSize(value);
    setCurrentPage(Number(storedPage) || currentPage);
    toggleDropdown();
  };
  return (
    <>
      <div className="w-full fixed bottom-0 z-[9999999]">
        {
          <div
            style={{
              zIndex: "9999",
              width: "100%",
              bottom: "0%",
              borderRadius: "0",
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
            }}
            className="fixed w-full bg-[#244277] h-[50px]"
          >
            <PaginationComponent
              handleSelect={handleSelect}
              selectedValue={selectedValue}
              data={mainData}
              pageSize={pageSize}
              dropdownOptions={dropdownOptions}
              handlePagination={handlePagination}
              currentPage={currentPage}
            />
          </div>
        }
        <div
          className={`w-auto my-auto h-[40px] flex items-center justify-center bg-[#031F4F] opacity-80 min-w-[150px] ${
            isMobileView ? "text-center" : "text-right"
          }`}
        >
          <p
            style={{ fontSize: "12px", fontWeight: "600" }}
            className="DataCount_text"
          >
            {allDevicesData?.title?.page_item_count *
              allDevicesData?.title?.current_page -
              allDevicesData?.title?.page_item_count +
              1 || 0}{" "}
            -{" "}
            {Math.min(
              allDevicesData?.total_count,
              allDevicesData?.title?.page_item_count *
                allDevicesData?.title?.current_page
            ) || 0}{" "}
            of {allDevicesData?.total_count?.toLocaleString() || 0}
          </p>
        </div>
      </div>
      <div className="bg-custom-appBack flex flex-col h-screen">
        <div className="devices_div_container">
          <div className="devices-gradient w-[26%] 3xl:w-[31%] 4xl:w-[36%] 5.6xl:w-[42%]"></div>
          <div className="devices_div ">
            <div>
              <div className="device-count-header">
                {isMobileView ? (
                  <img
                    src={arrow_left_white}
                    className=" ml-[10px] mb-2.5 h-[25px] cursor-pointer "
                    onClick={gotHome}
                  />
                ) : null}
                <div className="devices-count flex flex-col items-center justify-center">
                  {loading ? (
                    <div className="w-[35px] h-[35px] flex justify-center items-center">
                      <img src={dataLoading} alt="Loading..." />
                    </div>
                  ) : dataFetched && allDevices.length > 0 ? (
                    <>
                      <p className="text-center">
                        {allDevicesData?.total_count?.toLocaleString() || 0}
                      </p>
                      <p className="text-center">Total devices</p>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="devices-middle_section_container">
          <div
            style={{
              color: "#CE720F",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "90%",
            }}
          >
            Note: Device currently in use is highlighted in orange
          </div>
          {/* <div className="devices-search-div" onClick={handleSearch}>
          <img src={searchIcon} alt="search-icon" />
        </div> */}
        </div>
        <div id="infinityScrollDevicesView">
          {loading ? (
            <div className="w-[35px] h-[35px] m-auto justify-center items-center flex">
              <img src={dataLoading} alt="Loading..." />
            </div>
          ) : dataFetched && allDevices.length > 0 ? (
            <MyDeviceCard data={allDevices} />
          ) : dataFetched ? (
            <div className="flex flex-col items-center justify-center m-auto">
              <img
                src={noaccount}
                alt="No device found"
                className="devices-acc-no-acc-image"
              />
              <p className="devices-acc-no-devicesaccount-text">
                No devices found.
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default MyDevices;
