import React, { useState, forwardRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

const DatePickerComponent = forwardRef(
  (
    {
      startDate,
      endDate,
      handleDateChange,
      handleCancel,
      handleConfirm,
      showDatePicker,
      owner,
      type,
      isOpen,
    },
    ref
  ) => {
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const dropdownClassName = isOpen
      ? "year-dropdown-visible"
      : "hide-year-dropdown";
    // useEffect(() => {
    //   const handleClickOutside = (event) => {
    //     console.log("RUNS!!!");
    //     if (dateRef.current && !dateRef.current.contains(event.target)) {
    //       setShowDatePicker(false); // Close date picker when clicking outside
    //       event.stopPropagation();
    //     }
    //   };

    //   document.addEventListener("mousedown", handleClickOutside);

    //   return () => {
    //     document.removeEventListener("mousedown", handleClickOutside);
    //   };
    // }, [showDatePicker]);
    const dayClassName = (date) => {
      if (
        startDate &&
        new Date(date).getTime() === new Date(startDate).getTime()
      ) {
        return "start-date";
      }
      if (endDate && new Date(date).getTime() === new Date(endDate).getTime()) {
        return "end-date";
      }
      return "";
    };

    if (!showDatePicker) {
      return null;
    }
    return (
      <div
        style={{
          borderRadius: owner ? "0px 0px 10px 10px" : "10px",
          width: owner ? "302px" : "100%",
          borderLeft: owner ? "1px solid #ff5860" : "",
          borderRight: owner ? "1px solid #ff5860" : "",
          borderBottom: owner ? "1px solid #ff5860" : "",
        }}
        className="date-picker-container"
      >
        {type === "single" ? (
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              handleDateChange(date, "start");
            }}
            onMonthChange={(date) => {
              setSelectedMonth(new Date(date).getMonth());
            }}
            startDate={startDate}
            showYearDropdown
            maxDate={new Date()}
            scrollableYearDropdown
            yearDropdownItemNumber={100}
            inline
            startDateClassName="selected-start-date"
            endDateClassName="selected-end-date"
            dayClassName={dayClassName}
          />
        ) : (
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              handleDateChange(date, "start");
            }}
            onMonthChange={(date) => {
              setSelectedMonth(new Date(date).getMonth());
            }}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            showYearDropdown={showDatePicker}
            maxDate={new Date()}
            scrollableYearDropdown={showDatePicker}
            yearDropdownItemNumber={showDatePicker ? 100 : undefined}
            inline
            calendarClassName={dropdownClassName}
            startDateClassName="selected-start-date"
            endDateClassName="selected-end-date"
            dayClassName={dayClassName}
          />
        )}

        <div className="calendar_buttons_single px-5">
          <button
            onClick={(e) => {
              handleCancel();
              e.stopPropagation();
            }}
            className="calendar_cancel_button"
            style={{
              cursor: startDate || endDate ? "pointer" : "default",
              opacity: startDate || endDate ? "1" : "0.8",
            }}
            onMouseEnter={(e) => {
              if (startDate || endDate) {
                e.currentTarget.style.backgroundColor = "#8596b5"; // Set hover color
              }
            }}
            onMouseLeave={(e) => {
              if (startDate || endDate) {
                e.currentTarget.style.backgroundColor = "#667ca3"; // Set hover color
              }
            }}
          >
            <span style={{ opacity: startDate || endDate ? "1" : "0.5" }}>
              Clear
            </span>
          </button>
          {type === "single" ? (
            <button
              onClick={() => {
                if (startDate) {
                  handleConfirm();
                }
              }}
              style={{
                backgroundColor: startDate ? "#FF5860" : "#707070",
                cursor: startDate ? "pointer" : "default",
              }}
              onMouseEnter={(e) => {
                if (startDate || endDate) {
                  e.currentTarget.style.backgroundColor = "#FF7980"; // Set hover color
                }
              }}
              onMouseLeave={(e) => {
                if (startDate || endDate) {
                  e.currentTarget.style.backgroundColor = "#FF5860"; // Set hover color
                }
              }}
              className="calendar_confirm_button"
            >
              Confirm
            </button>
          ) : (
            <button
              onClick={(e) => {
                if (startDate && endDate) {
                  handleConfirm();
                  e.stopPropagation();
                }
              }}
              style={{
                backgroundColor: startDate && endDate ? "#FF5860" : "#707070",
                cursor: startDate && endDate ? "pointer" : "default",
              }}
              onMouseEnter={(e) => {
                if (startDate && endDate) {
                  e.currentTarget.style.backgroundColor = "#FF7980"; // Set hover color
                }
              }}
              onMouseLeave={(e) => {
                if (startDate && endDate) {
                  e.currentTarget.style.backgroundColor = "#FF5860"; // Set hover color
                }
              }}
              className="calendar_confirm_button"
            >
              Confirm
            </button>
          )}
        </div>
      </div>
    );
  }
);

export default DatePickerComponent;
