import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import "./index.css";
import DeviceTokenPopup from "../../../components/MyDevices/DeviceToken";
import DeleteDevicePopup from "../../../components/MyDevices/DeleteDevicePopup";
import "./index.css";
import formatDate from "../../../utils/formatdate";
import {
  getAPIToken,
  setDeviceData,
} from "../../../redux/features/devices/devices";

import generateToken from "../../../assets/GenerateToken/token.png";
import chrome from "../../../assets/MultiPlatformHubIcons/Chrome.svg";
import firefox from "../../../assets/MultiPlatformHubIcons/MozillaFirefox.svg";
import internetExplorer from "../../../assets/MultiPlatformHubIcons/Explorer.svg";
import android from "../../../assets/MultiPlatformHubIcons/Android.svg";
import apple from "../../../assets/MultiPlatformHubIcons/MacOs.svg";
import deleteIcon from "../../../assets/DeleteIcons/delete_with_white_circle.svg";
import safari from "../../../assets/MultiPlatformHubIcons/Safari.svg";
import DefaultPlatform from "../../../assets/MultiPlatformHubIcons/default_platform.svg";

const MyDeviceDetails = () => {
  const [token, setToken] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTokenGenerated, setIsTokenGenerated] = useState(false);
  const [tokenGeneratedFlag, setTokenGeneratedFlag] = useState(false);
  const [wantDeleted, setWantDeleted] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const locationData = location?.state?.ddata || {};

  const loggedInDeviceFromState = useSelector(
    (state) => state?.devices?.deviceData?.loggedInUser
  );
  const deviceIdFromState = useSelector(
    (state) => state?.devices?.deviceData?.deviceId
  );
  const devicesDataFromState = useSelector(
    (state) => state?.devices?.deviceData?.devices
  );
  const services = useSelector((state) => state.refreshToken.services);

  const loggedInDevice = locationData.loggedInUser || loggedInDeviceFromState;
  const deviceId = locationData.deviceId || deviceIdFromState;
  let devicesData = locationData.devices || devicesDataFromState;

  useEffect(() => {
    if (!devicesDataFromState) {
      dispatch(setDeviceData(locationData));
    }

    if (location.state === undefined) {
      devicesData = deviceIdFromState;
    }
  }, [locationData, devicesDataFromState, dispatch]);

  const currDevice = devicesData?.find((device) => device?.id === deviceId);

  useEffect(() => {
    const tokenGenerated = sessionStorage.getItem("tokenGenerated");

    if (tokenGenerated === "true") {
      setIsTokenGenerated(true);
    } else {
      setIsTokenGenerated(false);
    }
  }, [tokenGeneratedFlag]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.className === "device-content-page") {
        setIsPopupOpen(false);
      }
    };

    if (isPopupOpen) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isPopupOpen]);

  async function getToken() {
    try {
      const response = await dispatch(getAPIToken(loggedInDevice?.id));
      setToken(response.payload.device_details.q2p_token);
      sessionStorage.setItem("tokenGenerated", "true");
      setTokenGeneratedFlag((prev) => !prev);
      setIsTokenGenerated(true);
    } catch (err) {
      console.error(err);
    }
  }

  const handleTokenClick = () => {
    if (services && !services?.includes("GenerateAPIToken")) {
      navigate("/feature_not_assigned");
      return;
    }
    getToken();
    setIsPopupOpen(true);
  };

  const {
    id,
    browser_name,
    logged_in_flag,
    os_version,
    platform,
    created_by_name,
    logged_in_time,
    last_used,
    is_unauthorized,
    is_primary,
    model,
  } = currDevice || {};

  return (
    <div
      style={{
        overflowY: isPopupOpen || wantDeleted ? "hidden" : "",
        height: "100vh",
      }}
    >
      {wantDeleted && services?.includes("DDevice") && (
        <DeleteDevicePopup close={setWantDeleted} deviceId={id} />
      )}
      {isPopupOpen && (
        <DeviceTokenPopup
          className="bg-opacity-80"
          token={token}
          onClose={setIsPopupOpen}
          isPopupOpen={isPopupOpen}
        />
      )}
      {loading ? (
        <div className="device-loader-container m-auto justify-center items-center flex"></div>
      ) : (
        <div className="device-content-page">
          <div className="device-container">
            <div className="device-section">
              <div className="device-major-div ">
                <div className="device-individual-device-detail-first">
                  <div className="device-model-div">
                    <span className="device-entity">
                      {browser_name || model}
                    </span>
                    <div className="device-detail-label text-xs">Model</div>
                  </div>
                  <div className="device-createdBy-div">
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={created_by_name}
                    >
                      <div className="device-content">
                        <div className="overflow-hidden whitespace-nowrap overflow-ellipsis font-normal text-sm leading-19 device-entity capitalize">
                          {created_by_name}
                          <div className="device-detail-label text-xs">
                            Created by
                          </div>
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                </div>

                <div className="device-individual-device-detail-second">
                  <div className="device-platform-div">
                    <span className="device-entity">
                      {platform
                        ?.toLowerCase()
                        ?.split(" ")
                        ?.map(function (word) {
                          return word.charAt(0).toUpperCase() + word.slice(1);
                        })
                        ?.join(" ")}
                    </span>
                    <div className="device-detail-label text-xs">Platform</div>
                  </div>

                  <div className="device-os-div">
                    <div className="device-content-detail">
                      <div className="device-entity">
                        {os_version ? os_version : "Not Available"}
                        <div className="device-detail-label text-xs">
                          Operating system
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="device-individual-device-detail-third ">
                  <div className="device-loggedIn-div">
                    <span className="device-entity">
                      {formatDate(logged_in_time)}
                    </span>
                    <div className="device-detail-label text-xs">
                      {logged_in_flag === "Y"
                        ? "Logged in at"
                        : "Last logged in at"}
                    </div>
                  </div>
                  <div className="device-lastUsed-div">
                    <div className="device-content-detail">
                      <span className="device-entity">
                        {formatDate(last_used)}
                      </span>
                      <div className="device-detail-label text-xs">
                        Last used at
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="device-minor-div">
                <div className="device-flex-device-div">
                  <div className="device-browserLogo-div">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        className="device-browser-logo"
                        src={
                          currDevice?.platform === "android"
                            ? android
                            : currDevice?.platform === "apple"
                            ? apple
                            : currDevice?.browser_name === "Google Chrome"
                            ? chrome
                            : currDevice?.browser_name === "Mozilla Firefox"
                            ? firefox
                            : currDevice?.browser_name === "Internet Explorer"
                            ? internetExplorer
                            : currDevice?.browser_name === "Apple Safari"
                            ? safari
                            : DefaultPlatform
                        }
                        alt="generate token"
                      />
                      <p className="device-image-label text-Blue_3 h-8/12 self-center antialiased truncate">
                        Current <br />
                        Device
                      </p>
                    </Box>
                  </div>
                </div>
                <div className="device-flex-device-div">
                  {/* <div className="w-1/2 flex-grow bg-[#3a5686]"></div> */}
                  <div
                    onClick={
                      logged_in_flag === "N" || isTokenGenerated
                        ? undefined
                        : handleTokenClick
                    }
                    className={`device-token-div ${
                      logged_in_flag === "N" || isTokenGenerated
                        ? ""
                        : " hover:bg-custom-cardHover"
                    }`}
                    style={{
                      cursor:
                        logged_in_flag === "N" || isTokenGenerated
                          ? "default"
                          : "cursor",
                    }}
                  >
                    <Box>
                      <button
                        className="device-token-btn flex justify-center px-2"
                        disabled={
                          logged_in_flag === "N" || isTokenGenerated
                            ? true
                            : false
                        }
                        style={{
                          opacity:
                            logged_in_flag === "N" || isTokenGenerated
                              ? "0.1"
                              : "0.9",
                          cursor:
                            logged_in_flag === "N" || isTokenGenerated
                              ? "default"
                              : "cursor",
                        }}
                      >
                        <img
                          className="device-token-img"
                          src={generateToken}
                          alt="generate token"
                        />
                        <p className="device-image-label text-Primary_white h-8/12 self-center antialiased truncate">
                          Generate <br />
                          Token
                        </p>
                      </button>
                    </Box>
                  </div>
                </div>
                <div className="device-flex-device-div ">
                  {/* <div className="w-1/2 flex-grow bg-[#3a5686]"></div> */}
                  <div
                    onClick={() => {
                      if (!services?.includes("DDevice")) {
                        navigate("/feature_not_assigned");
                        return;
                      }
                      setWantDeleted(true);
                    }}
                    className={`device-deleteDevice-div ${
                      services?.includes("DDevice") &&
                      "hover:bg-custom-cardHover"
                    }`}
                    style={{
                      cursor: services?.includes("DDevice")
                        ? "pointer"
                        : "default",
                    }}
                  >
                    <Box>
                      <button
                        style={{
                          opacity: services?.includes("DDevice")
                            ? "0.9"
                            : "0.1",
                          cursor: services?.includes("DDevice")
                            ? "pointer"
                            : "default",
                        }}
                      >
                        <img
                          className="device-deleteDevice-img"
                          src={deleteIcon}
                          alt="delete device"
                        />
                        <p className="device-image-label text-Primary_white h-8/12 self-center antialiased truncate">
                          Delete
                        </p>
                      </button>
                    </Box>
                  </div>
                </div>
              </div>
            </div>

            <div className="device-details-div-section w-screen mt-10">
              <div className="device-details-section">
                <button className="device-details-heading text-white">
                  Details
                </button>
                <div className="device-addDetails-div">
                  <div className="device-detail-card w-1/4">
                    <h4 className="device-title1">Login details</h4>
                    <div className="flex flex-row justify-between">
                      <div className="device-data-div">Logged out</div>
                    </div>

                    <div className="device-status-div pb-3 border-b border-solid border-Blue_3">
                      Login status
                    </div>

                    <div className="w-50 h-50 device-acc-type-section ">
                      <div className="data-div">
                        {is_primary ? "Primary" : "Secondary"}
                      </div>
                      <div className="device-status-div pb-3 border-b border-solid border-Blue_3">
                        Device priority
                      </div>
                    </div>
                    <div className="w-50 h-50 device-acc-type-section">
                      <div className="data-div">
                        {is_unauthorized ? "Unauthorized" : "Authorized"}
                      </div>
                      <div className="device-status-div">
                        Device authorization
                      </div>
                    </div>
                  </div>
                  {platform === "android" && (
                    <div className="device-detail-card w-1/4">
                      <h4 className="title1">Device details</h4>
                      <div className="flex flex-row justify-between">
                        <div className="device-data-div">
                          {currDevice?.manufacturer}
                        </div>
                      </div>

                      <div className="device-status-div pb-3 border-b border-solid border-Blue_3">
                        Manufacturer
                      </div>

                      <div className="w-50 h-50 device-acc-type-section ">
                        <div className="device-data-div">
                          {currDevice?.brand}
                        </div>
                        <div className="device-status-div pb-3 border-b border-solid border-Blue_3">
                          Brand
                        </div>
                      </div>
                      <div className="w-50 h-50 device-acc-type-section">
                        <div className="device-data-div">
                          {currDevice?.app_version_name}
                        </div>
                        <div className="device-status-div">App version</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyDeviceDetails;
