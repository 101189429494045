import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { viewInternalAccountDetail } from "../../../../redux/features/internalApi/internalApi";
import { checkUsertoken } from "../../../../redux/features/login/login";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber";
import formatDateViewAll from "../../../../utils/formatDateViewAll";
import error_with_white_outlined_circle from "../../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import {
  beneVerifyThisGST,
  beneVerifyThisPAN,
} from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { toast } from "react-toastify";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import { ownerListViewAllAcc } from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { beneInternalWaitingApproved } from "../../../../redux/features/internalApi/internalApi";
import { beneLookUp } from "../../../../redux/features/verifyPAN/verifyPAN";
import { beneBankLookUp } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { Tooltip, tooltipClasses } from "@mui/material";
import DeclinedPopup from "../../BeneficiaryAccount/BeneAcountApproval/DeclinedPopup";
import RefreshToast from "../../../../components/_utils/RefreshToast";
import TransactionHistory from "../../../../components/TransactionHistoryModal";
import BeneTxnAccountModal from "../../../../components/BeneTransaction/BeneTxnAccount/BeneTxnAccountModal";
import associated_accounts_found from "../../../../assets/GeneralIcons/associated_accounts_found.svg";
import transactionHistory from "../../../../assets/GeneralIcons/transaction_history.svg";
import editImage from "../../../../assets/EditIcons/edit_with_white_circle.svg";
import linked_white_circle from "../../../../assets/GeneralIcons/linked_white_circle.svg";
import arrow_right_white from "../../../../assets/ArrowsIcons/arrow_right_white.svg";
import declineImage from "../../../../assets/RejectIcons/reject_icon_red_with_white_circle.svg";
import checkmark from "../../../../assets/CheckmarksIcons/approve_circle_green_checkmark.svg";
import internalAccImage from "../../../../assets/Beneficiary Icons/internal.svg";
import initiate_internal_transaction from "../../../../assets/Beneficiary Icons/initiate_internal_transaction.svg";
import skipping_verification from "../../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";
import xpentraloader from "../../../../assets/Beneficiary Icons/inputloder.gif";
import closeIcon from "../../../../assets/General Icons/close.svg";
import "./index.css";

const InternalAccDetail = (payload) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const services = useSelector((state) => state.refreshToken.services);

  const [loading, setLoading] = useState(true);
  const [viewIntData, setViewIntData] = useState([]);
  const [openTxnHistory, setOpenTxnHistory] = useState(false);
  const [openBeneAssociate, setOpenBeneAssociate] = useState(false);
  const [openInternalAssociate, setOpenInternalAssociate] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [owneraccountId, setOwnerAccountId] = useState("");
  const [deniedId, setDeniedId] = useState(null);
  const [preventMultiEnter, setPreventMultiEnter] = useState(false);
  const [currentAccountDetails, setCurrentAccountDetails] = useState({
    name: "",
    bank_name: "",
    ifsc_code: "",
    bank_acc_no: "",
    category: "",
    id: "",
    pan: "",
    bank_account_holder_full_name: "",
  });
  const { viewInternalAccountDetailLoading, viewInternalAccountDetailData } =
    useSelector((state) => state.internalAcc);
  useEffect(() => {
    async function fetchData() {
      if (viewInternalAccountDetailLoading) setLoading(true);
      try {
        dispatch(checkUsertoken());
        let response;
        if (payload?.type === "bene_account_detail_modal") {
          response = await dispatch(
            viewInternalAccountDetail({ id: payload?.bene_account_id })
          );
        } else {
          response = await dispatch(viewInternalAccountDetail({ id }));
        }

        if (response?.error?.message == "Request failed with status code 404") {
          navigate("*");
        }
      } catch (error) {
        console.error("Failed to load the account details", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [payload?.bene_account_id, id, dispatch]);

  useEffect(() => {
    const fetchownerID = async () => {
      let data = await dispatch(
        ownerListViewAllAcc({
          bank_account_no:
            viewInternalAccountDetailData?.internal_accounts[0]?.bank_acc_no,
          pan: viewInternalAccountDetailData?.internal_accounts[0]?.pan_no,
          ifsc: viewInternalAccountDetailData?.internal_accounts[0]?.ifsc_code,
        })
      );
      setOwnerAccountId(data?.payload?.owner_accounts[0]?.id);
    };
    if (viewInternalAccountDetailData) {
      fetchownerID();
      setViewIntData(viewInternalAccountDetailData?.internal_accounts);
    }
  }, [viewInternalAccountDetailData]);

  useEffect(() => {
    if (
      viewInternalAccountDetailData &&
      viewInternalAccountDetailData.internal_accounts &&
      viewInternalAccountDetailData.internal_accounts.length > 0
    ) {
      const { bank_name, bank_acc_no, category, ifsc_code, id, pan_no, name } =
        viewInternalAccountDetailData.internal_accounts[0];
      setCurrentAccountDetails({
        bank_name,
        bank_acc_no,
        category,
        ifsc_code,
        id,
        pan: pan_no,
        bank_account_holder_full_name: name,
        name: name,
      });
    }
  }, [viewInternalAccountDetailData]);

  const handlePANDetails = async (pan) => {
    if (pan?.length === 10) {
      dispatch(checkUsertoken());
      const response = await dispatch(
        beneVerifyThisPAN({ search_string: pan })
      );
      if (response?.payload?.status === true) {
        navigate(`/settings/PAN_Verification`, { state: pan });
      } else if (response?.payload?.errors?.user_unauthorized?.[0]) {
        navigate("/feature_not_assigned", {
          state: {
            unavailableService: "VPAN",
          },
        });
      } else if (response?.payload?.status === false) {
        toast.error(`${response?.payload?.errors?.base[0]}`, toast_position);
      }
    }
  };

  const handleGSTDetails = async (gstin) => {
    if (gstin?.length === 15) {
      dispatch(checkUsertoken());
      const response = await dispatch(
        beneVerifyThisGST({ search_string: gstin })
      );
      if (response?.payload?.status === true) {
        navigate(`/accounts/beneficiary_account/details/GST_details/${gstin}`);
      } else if (response?.payload?.status === false) {
        const error_response =
          response?.payload?.errors?.base?.[0] ||
          response?.payload?.errors?.user_unauthorized?.[0];
        toast.error(error_response, toast_position);
      }
    }
  };

  const handleApprovalDetails = async (id) => {
    RefreshToast("approvalaccountsRefresh");
    dispatch(checkUsertoken());
    let response = await dispatch(beneInternalWaitingApproved(id));

    if (response?.payload?.errors?.user_unauthorized?.length > 0) {
      navigate("/feature_not_assigned", {
        state: {
          unavailableService: "IAAccount",
        },
      });
      return;
    }
    if (
      response?.payload?.status === false ||
      response?.payload === undefined
    ) {
      let error_msg = "";
      if (response?.payload === undefined) {
        error_msg = "An error occured while taking action on this account";
      } else if (response?.payload?.errors?.internal_account?.[0]) {
        error_msg = response.payload.errors.internal_account[0];
      } else if (response?.payload?.errors?.bank_account_number?.[0]) {
        error_msg = response.payload.errors.bank_account_number[0];
      } else {
        error_msg = "An unknown error occurred.";
      }
      if (error_msg === "Internal account already approved") {
        const updatedData =
          viewInternalAccountDetailData?.internal_accounts.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                approval_status:
                  item.approval_status === "pending" ? "approved" : "pending",
              };
            }
            return item;
          });
        setViewIntData(updatedData);
        dispatch(checkUsertoken());
        await dispatch(viewInternalAccountDetail({ id }));
      } else {
        const updatedData =
          viewInternalAccountDetailData?.internal_accounts.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                approval_status:
                  item.approval_status === "pending" ? "pending" : "",
                error_msg,
              };
            }
            return item;
          });

        setViewIntData(updatedData);
        dispatch(checkUsertoken());
      }
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
    } else {
      const updatedData = viewInternalAccountDetailData?.internal_accounts.map(
        (item) => {
          if (item.id === id) {
            return {
              ...item,
              approval_status:
                item.approval_status === "pending" ? "approved" : "pending",
            };
          }
          return item;
        }
      );
      setViewIntData(updatedData);
      dispatch(checkUsertoken());

      await dispatch(viewInternalAccountDetail({ id }));

      const successMessage = "Internal account approved successfully";

      toast.success(successMessage, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      if (payload?.type === "bene_account_detail_modal") {
        setTimeout(() => {
          payload?.closeBeneAccountDetailModal();
          window.location.reload();
        }, 3000);
        setTimeout(() => {
          navigate("/accounts/internal_account/approval_required");
        }, 5000);
      }
    }
  };

  const handleDeclinedDetials = (id) => {
    setShowPopup(true);
    setDeniedId(id);
  };

  const createIntTransaction = () => {
    if (
      services?.find(
        (service) => service === "CInternalTxn" || service === "Admin"
      ) !== undefined &&
      currentAccountDetails?.category?.length > 0
    ) {
      navigate("/transaction/internal_transaction/create", {
        state: currentAccountDetails,
      });
    } else if (
      services?.find(
        (service) => service === "CInternalTxn" || service === "Admin"
      ) !== undefined &&
      currentAccountDetails.category.length <= 0
    ) {
      toast.error("Category can't be blank", toast_position);
    }
  };

  const openAssociatedAccountsFound = () => {
    dispatch(checkUsertoken());
    dispatch(beneLookUp({ pan_number: currentAccountDetails.pan }));
    dispatch(
      beneBankLookUp({
        ifsc: currentAccountDetails.ifsc_code,
        bank_account_no: currentAccountDetails.bank_acc_no,
        bank_account_holder_full_name:
          currentAccountDetails.bank_account_holder_full_name,
      })
    );

    setOpenInternalAssociate(true);
  };

  const closeAssociatedAccountsFound = () => {
    setOpenInternalAssociate(false);
  };

  const openTransactionHistory = () => {
    setOpenTxnHistory(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const updateAllAccounts = (updatedAccounts) => {
    setViewIntData(updatedAccounts);
  };
  const actionRestricted = () => {
    toast.warn("Action restricted in popup mode", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: {
        // width: window.innerWidth > 600 ? "252px" : window.innerWidth + "px",
        // height: "84px",
        // borderRadius: "10px",
        // backgroundColor: "#e74c3c",
        color: "var(--Primary_white)",
      },
    });
  };
  const showToast = () => {
    toast.info("Feature coming soon", toast_position);
  };

  const capitalizeName = (str) => {
    return str
      ? str
          .split(" ")
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(" ")
      : "";
  };

  return (
    <>
      <div
        className={`${
          payload?.type === "bene_account_detail_modal"
            ? "main-div h-screen overflow-hidden"
            : "main-div h-screen overflow-auto"
        }`}
      >
        {openTxnHistory && (
          <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80">
            <TransactionHistory
              onClose={() => setOpenTxnHistory(false)}
              Owner_account_number={
                viewInternalAccountDetailData?.internal_accounts[0]?.bank_acc_no
              }
              internalAccount={true}
              OwnerDetail={true}
              BeneAccId={id}
            />
          </div>
        )}
        {openInternalAssociate && (
          <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80">
            <BeneTxnAccountModal
              onClose={closeAssociatedAccountsFound}
              // onAccountSelect={handleAccountSelection}
              accountNumber={currentAccountDetails.bank_acc_no}
              IFSC={currentAccountDetails.ifsc_code}
              associateAccount={true}
              panNumber={currentAccountDetails.pan}
              setOpenInternalAssociate={setOpenInternalAssociate}
              setOpenBeneAssociate={setOpenBeneAssociate}
              beneAccountId={currentAccountDetails.id}
              accountType={"internal"}
            />
          </div>
        )}

        {payload?.type === "bene_account_detail_modal" && (
          <div
            className={`${
              payload?.type === "bene_account_detail_modal"
                ? "flex flex-row justify-between items-center p-5 bg-Blue_3 "
                : "none"
            }`}
          >
            <div className="flex flex-col">
              <p className="text-base text-Primary_white font-sans font-semibold">
                Account detail
              </p>
            </div>
            <div
              className="flex justify-center items-center cursor-pointer w-7 h-7 rounded-[5px] bg-Blue_5 hover:bg-Blue_7"
              onClick={() => payload?.closeBeneAccountDetailModal()}
            >
              <img src={closeIcon} className="w-4 h-4" />
            </div>
          </div>
        )}
        {showPopup && (
          <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#000] bg-opacity-80">
            <DeclinedPopup
              onClose={handleClosePopup}
              accountId={deniedId}
              allAccounts={viewIntData}
              updateAllAccounts={updateAllAccounts}
              status={"Internal_Account_Detail"}
              setPreventMultiEnter={setPreventMultiEnter}
              type={"Internal_Account_Detail"}
              popup={payload?.type}
              closeBeneAccountDetailModal={payload?.closeBeneAccountDetailModal}
            />
          </div>
        )}
        {viewInternalAccountDetailLoading ? (
          <>
            <div className="flex justify-center items-center m-auto h-screen">
              <img src={xpentraloader} className="loaderImg" />
            </div>
          </>
        ) : (
          <>
            <div
              className={`${
                payload?.type === "bene_account_detail_modal"
                  ? "h-auto bg-Blue_6"
                  : "h-auto bg-Blue_6 primaryDetailsandFunctionblock"
              }`}
            >
              <div className="w-full max-w-5xl m-auto bg-Blue_6">
                <div className="primaryDetailsBlock1 flex flex-row border-b border-solid border-Blue_3">
                  <div className="infoBlock flex flex-row py-6">
                    <div className="accountName flex flex-col w-1/2 gap-y-1 pr-5 whitespace-normal break-words">
                      <span className="text-Font_16 text-Primary_white font-normal font-sans leading-5">
                        {capitalizeName(
                          viewInternalAccountDetailData?.internal_accounts[0]?.name?.trim()
                        )}
                      </span>
                      <span className="text-Font_12 text-Primary_grey font-normal font-sans leading-4">
                        Account name
                      </span>
                    </div>
                    <div className="border-r border-solid border-Blue_3"></div>
                    {viewIntData?.map((account) => (
                      <div className="accountStatus flex flex-col w-1/2 gap-y-1 pl-5">
                        <span
                          className={`text-Font_16 font-semibold font-sans leading-5 capitalize ${
                            account.approval_status === "pending" ||
                            account.approval_status === "error"
                              ? "text-Pending"
                              : account.approval_status === "approved"
                              ? "text-Settled"
                              : account.approval_status === "rejected" ||
                                account.approval_status === "blocked"
                              ? "text-Failed"
                              : "text-Abandoned"
                          }`}
                        >
                          {account?.approval_status === "error"
                            ? "Pending"
                            : account?.approval_status}
                        </span>
                        <span className="text-Font_12 text-Primary_grey font-normal font-sans leading-4">
                          Account status
                        </span>
                      </div>
                    ))}
                  </div>
                  {/* " */}
                  <div
                    className={`${
                      payload.type === "bene_account_detail_modal"
                        ? "functionBlock flex flex-row bg-Blue_7 opacity-50 cursor-default"
                        : "functionBlock flex flex-row bg-Blue_7"
                    }`}
                  >
                    <div
                      className={`${
                        payload.type === "bene_account_detail_modal"
                          ? "flex flex-col justify-center w-1/2 px-2.5 gap-y-0.5 cursor-default "
                          : "flex flex-col justify-center w-1/2 px-2.5 gap-y-0.5 cursor-pointer hover:bg-Blue_7_hover"
                      }`}
                      onClick={() => {
                        if (payload.type === "bene_account_detail_modal") {
                          actionRestricted();
                        } else {
                          openAssociatedAccountsFound();
                        }
                      }}
                    >
                      <div className="flex justify-center">
                        <img
                          src={associated_accounts_found}
                          className="functionBlockImg"
                          alt=""
                        />
                      </div>
                      <span className="text-Font_10 text-Primary_white font-normal font-sans text-center leading-[13.62px]">
                        Associated accounts found
                      </span>
                    </div>
                    <div className="border-r border-solid border-Blue_3 my-4"></div>
                    <div
                      className={`${
                        payload.type === "bene_account_detail_modal"
                          ? "flex flex-col justify-center w-1/2 px-2.5 gap-y-0.5 cursor-default  "
                          : "flex flex-col justify-center w-1/2 px-2.5 gap-y-0.5 cursor-pointer hover:bg-Blue_7_hover"
                      }`}
                      onClick={() => {
                        if (payload.type === "bene_account_detail_modal") {
                          actionRestricted();
                        } else {
                          openTransactionHistory();
                        }
                      }}
                    >
                      <div className="flex justify-center">
                        <img
                          src={transactionHistory}
                          className="functionBlockImg"
                          alt=""
                        />
                      </div>
                      <span className="text-Font_10 text-Primary_white font-normal font-sans text-center leading-[13.62px]">
                        Transaction<br></br> history
                      </span>
                    </div>
                  </div>
                </div>
                <div className="primaryDetailsBlock2 flex flex-row border-b border-solid border-Blue_3">
                  <div className="infoBlock flex flex-row py-6">
                    <div className="accountNumber flex flex-col w-1/2 gap-y-1">
                      <span className="text-Font_16 text-Primary_white font-normal font-sans leading-5">
                        {formatAccountNumber(
                          viewInternalAccountDetailData?.internal_accounts[0]
                            ?.bank_acc_no
                        )}
                      </span>
                      <span className="text-Font_12 text-Primary_grey font-normal font-sans leading-4">
                        Account number
                      </span>
                    </div>
                    <div className="border-r border-solid border-Blue_3"></div>
                    <div className="accountHolderName flex flex-col w-1/2 gap-y-1 px-5 whitespace-normal break-words">
                      <span className="text-Font_16 text-Primary_white font-normal font-sans leading-5">
                        {capitalizeName(
                          viewInternalAccountDetailData?.internal_accounts[0]
                            ?.bank_account_holder_full_name
                        )}
                      </span>
                      <span className="text-Font_12 text-Primary_grey font-normal font-sans leading-4">
                        Bank account holder name
                      </span>
                    </div>
                  </div>
                  <div className="functionBlock flex flex-row bg-Blue_7">
                    <div
                      className="flex flex-col justify-center w-1/2 px-2.5 gap-y-0.5 opacity-50"
                      onClick={() => {
                        if (payload.type === "bene_account_detail_modal") {
                          actionRestricted();
                        } else {
                          showToast();
                        }
                      }}
                    >
                      <div className="flex justify-center">
                        <img src={editImage} className="functionBlockImg" />
                      </div>
                      <span className="text-Font_10 text-Primary_white font-normal font-sans text-center leading-[13.62px] ">
                        Edit <br></br>accounts
                      </span>
                    </div>
                    <div className="border-r border-solid border-Blue_3 my-4"></div>
                    <div
                      className={`${
                        payload.type === "bene_account_detail_modal"
                          ? "flex flex-col justify-center w-1/2 px-2.5 gap-y-0.5 cursor-default  opacity-50 "
                          : "flex flex-col justify-center w-1/2 px-2.5 gap-y-0.5 cursor-pointer hover:bg-Blue_7_hover"
                      }`}
                      onClick={() => {
                        if (payload.type === "bene_account_detail_modal") {
                          actionRestricted();
                        } else {
                          if (owneraccountId) {
                            navigate(
                              `/accounts/owner/owner_account_details/${owneraccountId}`
                            );
                          } else {
                            toast.error(
                              "Can't fetch Owner ID from backend",
                              toast_position
                            );
                          }
                        }
                      }}
                    >
                      <div className="flex justify-center">
                        <img
                          src={linked_white_circle}
                          className="functionBlockImg"
                        />
                      </div>
                      <span className="text-Font_10 text-Primary_white font-normal font-sans text-center leading-[13.62px]">
                        View linked owner account
                      </span>
                    </div>
                  </div>
                </div>
                <div className="primaryDetailsBlock3 flex flex-row">
                  <div className="bankImageBlock">
                    <div className="bankImage flex justify-center items-center px-2.5 bg-Primary_white">
                      <img
                        src={require("../../../../assets/IfscLogo/" +
                          IfscCodeMapper(
                            viewInternalAccountDetailData?.internal_accounts[0]
                              ?.ifsc_code
                          ))}
                        alt="bank"
                      />
                    </div>
                  </div>
                  <div className="border-r border-solid border-Blue_3 my-4"></div>
                  {viewIntData.map((account) => (
                    <>
                      <div
                        className={`flex ${
                          account?.error_msg
                            ? "ifscCodeBlock_Error"
                            : "ifscCodeBlock"
                        }`}
                      >
                        <div className="bankIfscCode pl-4 py-5">
                          <div
                            className="flex flex-col gap-y-1 p-1 rounded-lg cursor-pointer hover:bg-Blue_6_hover"
                            onClick={() => {
                              if (
                                services?.find(
                                  (service) =>
                                    service === "VIFSC" || service === "Admin"
                                ) !== undefined &&
                                viewInternalAccountDetailData
                                  ?.internal_accounts[0]?.ifsc_code
                              ) {
                                navigate(
                                  `/accounts/beneficiary_account/details/IFSC/${viewInternalAccountDetailData?.internal_accounts[0]?.ifsc_code}`
                                );
                              } else {
                                navigate("/feature_not_assigned", {
                                  state: {
                                    unavailableService: "VIFSC",
                                  },
                                });
                              }
                            }}
                          >
                            <div className="flex flex-row justify-between gap-x-5">
                              <span className="text-Font_16 text-Primary_white font-normal font-sans leading-5">
                                {
                                  viewInternalAccountDetailData
                                    ?.internal_accounts[0]?.ifsc_code
                                }
                              </span>
                              <div className="redirectBlockArrow flex justify-center items-center">
                                <img
                                  src={arrow_right_white}
                                  className="arrowDimensions"
                                />
                              </div>
                            </div>
                            <span className="text-Font_12 text-Primary_grey font-normal font-sans leading-4">
                              IFSC code
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`flex ${
                          account?.error_msg
                            ? "functionBlock_Error"
                            : "functionBlock"
                        }`}
                      >
                        {viewIntData.map((account) => {
                          return account?.approval_status === "rejected" &&
                            account?.error_msg?.length > 0 ? (
                            <div className="flex flex-row h-full w-full bg-Failed_to_take_action py-4 px-2.5">
                              <div className="flex place-items-center">
                                <img
                                  src={skipping_verification}
                                  className="functionBlockImg"
                                />
                              </div>
                              <div className="flex flex-col gap-y-1 w-5/6 ml-2.5">
                                <span className="text-Font_14 text-Primary_white font-sans font-semibold">
                                  Failed to take action
                                </span>

                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                        [`& .${tooltipClasses.arrow}`]: {
                                          color: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  title={account?.error_msg}
                                  arrow
                                  placement="bottom"
                                  PopperProps={{
                                    style: {
                                      maxWidth: "none",
                                    },
                                  }}
                                >
                                  <span className="text-Font_12 text-Primary_white errorMsg break-words">
                                    {account?.error_msg}
                                  </span>
                                </Tooltip>
                              </div>
                            </div>
                          ) : account.approval_status === "pending" &&
                            account.error_msg?.length > 0 ? (
                            <div className="flex flex-row h-full w-full bg-Failed_to_take_action py-4 px-2.5">
                              <div className="flex place-items-center">
                                <img
                                  src={skipping_verification}
                                  className="functionBlockImg"
                                />
                              </div>
                              <div className="flex flex-col gap-y-1 w-5/6 ml-2.5">
                                <span className="text-Font_14 text-Primary_white font-sans font-semibold">
                                  Failed to take action
                                </span>
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                        [`& .${tooltipClasses.arrow}`]: {
                                          color: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  title={account?.error_msg}
                                  arrow
                                  placement="bottom"
                                  PopperProps={{
                                    style: {
                                      maxWidth: "none",
                                    },
                                  }}
                                >
                                  <span className="text-Font_12 text-Primary_white errorMsg break-words">
                                    {account?.error_msg}
                                  </span>
                                </Tooltip>
                              </div>
                            </div>
                          ) : account.approval_status === "pending" ? (
                            <>
                              <div className="functionBlock flex flex-row">
                                <div
                                  className={`rejectIntAccount flex flex-col justify-center items-center w-1/2 gap-y-1 bg-Button_red ${
                                    services?.find(
                                      (service) =>
                                        service === "IAAccount" ||
                                        service === "Admin"
                                    ) !== undefined
                                      ? "opacity-100 hover:bg-Button_red_hover cursor-pointer"
                                      : "opacity-50 cursor-default"
                                  }`}
                                  onClick={() => {
                                    if (
                                      services?.find(
                                        (service) =>
                                          service === "IAAccount" ||
                                          service === "Admin"
                                      ) !== undefined
                                    ) {
                                      handleDeclinedDetials(
                                        viewInternalAccountDetailData
                                          ?.internal_accounts[0]?.id
                                      );
                                    } else {
                                      navigate("/feature_not_assigned", {
                                        state: {
                                          unavailableService: "IAAccount",
                                        },
                                      });
                                    }
                                  }}
                                >
                                  <img
                                    src={declineImage}
                                    className="functionBlockImg"
                                  />
                                  <span className="text-Font_10 text-Primary_white font-normal font-sans text-center">
                                    Reject
                                  </span>
                                </div>
                                <div
                                  className={`approveIntAccount flex flex-col justify-center items-center w-1/2 gap-y-1 bg-Button_green ${
                                    services?.find(
                                      (service) =>
                                        service === "IAAccount" ||
                                        service === "Admin"
                                    ) !== undefined
                                      ? "opacity-100 hover:bg-Button_green_hover cursor-pointer"
                                      : "opacity-50 cursor-default"
                                  }`}
                                  onClick={() => {
                                    if (
                                      services?.find(
                                        (service) =>
                                          service === "IAAccount" ||
                                          service === "Admin"
                                      ) !== undefined
                                    ) {
                                      handleApprovalDetails(
                                        viewInternalAccountDetailData
                                          ?.internal_accounts[0]?.id
                                      );
                                    } else {
                                      navigate("/feature_not_assigned", {
                                        state: {
                                          unavailableService: "IAAccount",
                                        },
                                      });
                                    }
                                  }}
                                >
                                  <img
                                    src={checkmark}
                                    className="functionBlockImg"
                                  />
                                  <span className="text-Font_10 text-Primary_white font-normal font-sans text-center">
                                    Approve
                                  </span>
                                </div>
                              </div>
                            </>
                          ) : account.approval_status === "approved" ? (
                            <>
                              <div
                                className={`functionBlock flex flex-col justify-center items-center gap-y-1 bg-Button_green ${
                                  services?.find(
                                    (service) =>
                                      service === "CInternalTxn" ||
                                      service === "Admin"
                                  ) !== undefined &&
                                  currentAccountDetails?.category?.length > 0
                                    ? "hover:bg-Button_green_hover cursor-pointer opacity-100"
                                    : "cursor-default opacity-50"
                                }`}
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) =>
                                        service === "CInternalTxn" ||
                                        service === "Admin"
                                    ) !== undefined
                                  ) {
                                    createIntTransaction();
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "CInternalTxn",
                                      },
                                    });
                                  }
                                }}
                              >
                                <img
                                  src={initiate_internal_transaction}
                                  className="functionBlockImg"
                                />
                                <span className="text-Font_12 text-Primary_white font-sans font-normal text-center leading-4">
                                  Initiate internal transaction
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="functionBlock bg-Blue_6"></div>
                            </>
                          );
                        })}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex-grow w-full bg-Blue_3 my-5 secondaryDetailsblock">
              <div className="max-w-5xl m-auto">
                <div className="detailsButton flex justify-center items-center mb-5 bg-Primary_white">
                  <span className="text-Font_12 text-Detail_chip text-center font-sans font-semibold leading-4">
                    Details
                  </span>
                </div>
                <div className="flex flex-row justify-evenly gap-x-5">
                  <div className="detailsCardBlock h-auto flex flex-col self-start p-5 bg-Blue_5">
                    <span className="text-Font_14 text-Primary_grey font-sans font-semibold leading-5">
                      Contact Details
                    </span>
                    <div className="flex flex-col mt-5 gap-y-1">
                      <span className="text-Font_14 text-Primary_white font-sans font-normal leading-5 whitespace-normal break-words">
                        {"+91" +
                          " " +
                          viewInternalAccountDetailData?.internal_accounts[0]
                            ?.contact_no}
                      </span>
                      <span className="text-Font_12 text-Primary_grey font-sans font-normal leading-4">
                        Contact number
                      </span>
                    </div>
                    <div className="border-b border-solid border-Blue_3 my-2.5"></div>
                    <div className="flex flex-col gap-y-1">
                      <span className="text-Font_14 text-Primary_white font-sans font-normal leading-5 whitespace-normal break-words">
                        {viewInternalAccountDetailData?.internal_accounts[0]?.email_id?.toLowerCase()}
                      </span>
                      <span className="text-Font_12 text-Primary_grey font-sans font-normal leading-4">
                        Email ID
                      </span>
                    </div>
                  </div>
                  <div className="detailsCardBlock h-auto flex flex-col self-start p-5 bg-Blue_5">
                    <span className="pl-1 text-Font_14 text-Primary_grey font-sans font-semibold leading-5">
                      Account Details
                    </span>
                    <div
                      className="flex flex-col gap-y-0.5 mt-3.5 p-1 rounded-lg cursor-pointer hover:bg-Blue_5_hover"
                      onClick={
                        viewInternalAccountDetailData?.internal_accounts[0]
                          ?.pan_no
                          ? () => {
                              if (
                                services?.find(
                                  (service) =>
                                    service === "VPAN" || service === "Admin"
                                ) !== undefined &&
                                viewInternalAccountDetailData
                                  ?.internal_accounts[0]?.pan_no
                              ) {
                                handlePANDetails(
                                  viewInternalAccountDetailData
                                    ?.internal_accounts[0]?.pan_no
                                );
                              } else {
                                navigate("/feature_not_assigned", {
                                  state: {
                                    unavailableService: "VPAN",
                                  },
                                });
                              }
                            }
                          : null
                      }
                    >
                      <div className="flex flex-row justify-between">
                        <span className="text-Font_14 text-Primary_white font-sans font-normal leading-5">
                          {
                            viewInternalAccountDetailData?.internal_accounts[0]
                              ?.pan_no
                          }
                        </span>
                        <div className="redirectBlockArrow flex justify-center items-center">
                          <img
                            src={arrow_right_white}
                            className="arrowDimensions"
                          />
                        </div>
                      </div>
                      <span className="text-Font_12 text-Primary_grey font-sans font-normal leading-4">
                        PAN number
                      </span>
                    </div>
                    <div className="border-b border-solid border-Blue_3 my-1.5"></div>
                    <div
                      className={`flex flex-col gap-y-0.5 p-1 ${
                        viewInternalAccountDetailData?.internal_accounts[0]
                          ?.gstin_detail
                          ? "hover:bg-Blue_5_hover rounded-lg cursor-pointer"
                          : "cursor-default"
                      }`}
                      onClick={
                        viewInternalAccountDetailData?.internal_accounts[0]
                          ?.gstin_detail
                          ? () => {
                              if (
                                services?.find(
                                  (service) =>
                                    service === "VGSTIN" || service === "Admin"
                                ) !== undefined
                              ) {
                                handleGSTDetails(
                                  viewInternalAccountDetailData
                                    ?.internal_accounts[0]?.gstin_detail
                                );
                              } else {
                                navigate("/feature_not_assigned", {
                                  state: {
                                    unavailableService: "VGSTIN",
                                  },
                                });
                              }
                            }
                          : null
                      }
                    >
                      <div className="flex flex-row justify-between">
                        <span className="text-Font_14 text-Primary_white font-sans font-normal leading-5">
                          {viewInternalAccountDetailData?.internal_accounts[0]
                            ?.gstin_detail
                            ? viewInternalAccountDetailData
                                ?.internal_accounts[0]?.gstin_detail
                            : "Not Given"}
                        </span>
                        {viewInternalAccountDetailData?.internal_accounts[0]
                          ?.gstin_detail && (
                          <div className="redirectBlockArrow flex justify-center items-center">
                            <img
                              src={arrow_right_white}
                              className="arrowDimensions"
                            />
                          </div>
                        )}
                      </div>
                      <span className="text-Font_12 text-Primary_grey font-sans font-normal leading-4">
                        GST number
                      </span>
                    </div>
                  </div>
                  <div className="detailsCardBlock h-auto flex flex-col self-start p-5 bg-Blue_5">
                    <span className="text-Font_14 text-Primary_grey font-sans font-semibold leading-5">
                      Origin Timeline
                    </span>
                    <div className="flex flex-col mt-5 gap-y-1">
                      <div className="flex flex-row gap-x-2.5 justify-between">
                        <span className="createdByName text-Font_14 text-Primary_white font-sans font-normal leading-5 whitespace-normal break-words">
                          {capitalizeName(
                            viewInternalAccountDetailData?.internal_accounts[0]
                              ?.created_by_name
                          )}
                        </span>
                        <span className="text-Font_10 text-Primary_grey text-right font-sans font-normal leading-[13.62px]">
                          {viewInternalAccountDetailData?.internal_accounts[0]
                            ?.created_on
                            ? formatDateViewAll(
                                viewInternalAccountDetailData
                                  ?.internal_accounts[0]?.created_on,
                                false
                              )
                            : "Not given"}
                        </span>
                      </div>
                      <span className="text-Font_12 text-Primary_grey font-sans font-normal leading-4">
                        Created by
                      </span>
                    </div>
                    <div className="border-b border-solid border-Blue_3 my-2.5"></div>
                    <div className="flex flex-col gap-y-1">
                      {viewIntData.map((account) => {
                        return account.approval_status === "blocked" ? (
                          <>
                            <div className="flex flex-row justify-between gap-x-2.5">
                              <span className="initiatorName text-Font_14 text-Primary_white font-sans font-normal leading-5 whitespace-normal break-words">
                                {capitalizeName(account.blocked_by_name)}
                              </span>
                              <span className="text-Font_10 text-Primary_grey font-sans font-normal text-right leading-3">
                                {formatDateViewAll(account.blocked_on)}
                              </span>
                            </div>
                            <span className="text-Font_12 text-Primary_grey font-sans font-normal leading-4">
                              Blocked by
                            </span>
                          </>
                        ) : account.approval_status === "approved" ? (
                          <>
                            <div className="flex flex-row justify-between gap-x-2.5">
                              <span className="initiatorName text-Font_14 text-Primary_white font-sans font-normal leading-5 whitespace-normal break-words">
                                {capitalizeName(account.approved_by_name)}
                              </span>
                              <span className="text-Font_10 text-Primary_grey font-sans font-normal text-right leading-3">
                                {formatDateViewAll(account.approved_on)}
                              </span>
                            </div>
                            <span className="text-Font_12 text-Primary_grey font-sans font-normal leading-4">
                              Approved by
                            </span>
                          </>
                        ) : account.approval_status === "rejected" ? (
                          <>
                            <div className="flex flex-row justify-between gap-x-2.5">
                              <span className="initiatorName text-Font_14 text-Primary_white font-sans font-normal leading-5 whitespace-normal break-words">
                                {capitalizeName(account.rejected_by_name)}
                              </span>
                              <span className="text-Font_10 text-Primary_grey font-sans font-normal text-right leading-3">
                                {formatDateViewAll(account.rejected_on)}
                              </span>
                            </div>
                            <span className="text-Font_12 text-Primary_grey font-sans font-normal leading-4">
                              Rejected by
                            </span>
                            <div className="border-b border-solid border-Blue_3 my-2.5"></div>
                            <div className="flex flex-col gap-y-1">
                              <span className="text-Font_14 text-Primary_white font-sans font-normal leading-5 whitespace-normal break-words">
                                {account.rejection_reason}
                              </span>
                              <span className="text-Font_12 text-Primary_grey font-sans font-normal leading-4">
                                Rejected reason
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <span className="text-Font_14 text-Primary_white font-sans font-normal leading-5">
                              Pending
                            </span>
                            <span className="text-Font_12 text-Primary_grey font-sans font-normal leading-4">
                              Approved by
                            </span>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="detailsCardBlock h-auto flex flex-col self-start p-5 bg-Blue_5">
                    <span className="text-Font_14 text-Primary_grey font-sans font-semibold leading-5">
                      Support Information
                    </span>
                    <div className="flex flex-col mt-5 gap-y-1">
                      <span className="text-Font_14 text-Primary_white font-sans font-normal leading-5">
                        {
                          viewInternalAccountDetailData?.internal_accounts[0]
                            ?.id
                        }
                      </span>
                      <span className="text-Font_12 text-Primary_grey font-sans font-normal leading-4">
                        Account ID
                      </span>
                    </div>
                    <div className="border-b border-solid border-Blue_3 my-2.5"></div>
                    <div className="flex flex-col relative gap-y-1">
                      <span className="text-Font_14 text-Primary_white font-sans font-normal leading-5">
                        Internal account
                      </span>
                      <span className="text-Font_12 text-Primary_grey font-sans font-normal leading-4">
                        Account type
                      </span>
                      <div className="internalImageDiv flex justify-center items-center">
                        <img
                          src={internalAccImage}
                          className="internalAccImageDimensions"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default InternalAccDetail;
