import { Tooltip, tooltipClasses } from "@mui/material";
import BalanceLoader from "../../../_utils/BalanceLoader";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import refreshIcon from "../../../../assets/RefreshIcons/reload-refresh_white.svg";
import { getOwnerAccBalance } from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { checkUsertoken } from "../../../../redux/features/login/login";
import "./index.css";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import { useState } from "react";
import AnimationUpAndDown from "../../../BeneTransaction/OwnerPopup/AnimationUpAndDown";
import FormatAmount from "../../../_utils/FormatAmount/FormatAmount";
import { useNavigate } from "react-router-dom";

function ConnectedBankOwnerCard({
  owner,
  ownerAccounts,
  setOwnerAccounts,
  ownerAccBalanceLoading,
  ownerAccBalanceError,
  updateTotalBalance,
  total_balance,
  connectedBankingOwnerAccounts,
  setConnectedBankingOwnerAccounts,
}) {
  const navigate = useNavigate();
  const [accId, setAccId] = useState("");
  const services = useSelector((state) => state.refreshToken.services);
  const [currentConnectedBal, setCurrentConnectedBal] = useState("");
  const [prevConnectedBal, setPrevConnectedBal] = useState("");
  const [refreshedConnectedId, setRefreshedConnectedId] = useState("");
  const [isMore, setIsMore] = useState(false);
  const dispatch = useDispatch();

  const handleRefresh = async (id) => {
    try {
      dispatch(checkUsertoken());
      let response = await dispatch(getOwnerAccBalance(id));

      if (response?.payload?.status === true) {
        setRefreshedConnectedId(id);
        let updatedOwnersData = ownerAccounts?.map((item) => {
          if (
            response?.payload?.owner_accounts[0]?.owner_account_id === item?.id
          ) {
            return {
              ...item,
              owner_account_balance_last_fetched_at_in_words:
                response?.payload?.owner_accounts[0]
                  ?.owner_account_balance_last_fetched_at_in_words,
              owner_account_balance:
                response?.payload?.owner_accounts[0]?.owner_account_balance,
              owner_account_balance_last_fetched_at:
                response.payload?.owner_accounts[0]
                  ?.owner_account_balance_last_fetched_at,
            };
          }
          return item;
        });

        const updatedConnectedBankingData = connectedBankingOwnerAccounts?.map(
          (item) => {
            if (
              response?.payload?.owner_accounts[0]?.owner_account_id ===
              item?.id
            ) {
              return {
                ...item,
                owner_account_balance_last_fetched_at_in_words:
                  response?.payload?.owner_accounts[0]
                    ?.owner_account_balance_last_fetched_at_in_words,
                owner_account_balance:
                  response?.payload?.owner_accounts[0]?.owner_account_balance,
                owner_account_balance_last_fetched_at:
                  response.payload?.owner_accounts[0]
                    ?.owner_account_balance_last_fetched_at,
              };
            }
            return item;
          }
        );

        setOwnerAccounts(updatedOwnersData);
        setConnectedBankingOwnerAccounts(updatedConnectedBankingData);

        const prevBalance = connectedBankingOwnerAccounts?.find(
          (owner) => owner.id === id
        )?.owner_account_balance;

        setPrevConnectedBal(prevBalance);

        const refreshedBalance = response?.payload.owner_accounts.find(
          (owner) => owner.owner_account_id === id
        )?.owner_account_balance;

        setCurrentConnectedBal(refreshedBalance);

        const updatedConnectedBankingTotal =
          Number(total_balance) -
          Number(prevBalance) +
          Number(refreshedBalance);

        updateTotalBalance(String(updatedConnectedBankingTotal));

        if (refreshedBalance > prevBalance) {
          setIsMore(true);
        } else {
          setIsMore(false);
        }
      } else {
        setTimeout(() => {
          toast.dismiss();
          toast.error(response?.error.message, toast_position);
        }, 500);
      }
    } catch (error) {
      toast.error(error, toast_position);
      throw error;
    }
  };

  return (
    <div
      className={"balance-card"}
      onClick={() => {
        navigate(`/accounts/owner/owner_account_details/${owner?.id}`);
      }}
    >
      <AnimationUpAndDown
        loader={ownerAccBalanceLoading}
        currentId={refreshedConnectedId}
        previousId={owner?.id}
        currentBalance={currentConnectedBal}
        previousBalance={prevConnectedBal}
        refreshType={"single"}
        classType={"owner_connected"}
      />

      <Tooltip
        componentsProps={{
          popper: {
            sx: {
              [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: (theme) => theme.palette.common.black,
              },
            },
          },
        }}
        title={owner.bank_acc_no}
      >
        <div className="left-side-div">
          <p>
            {" "}
            {owner.bank_acc_no.substring(0, 4) +
              " •••• " +
              owner.bank_acc_no.substring(owner.bank_acc_no.length - 4)}
          </p>
          <p>Account number</p>
        </div>
      </Tooltip>
      <div className="right-side-div">
        <div className="right-first-child">
          <p>
            ₹{" "}
            {isNaN(owner.owner_account_balance) ||
            owner.owner_account_balance == "xxxx.xx" ||
            owner.owner_account_balance === null ||
            owner.owner_account_balance === undefined ? (
              "Redacted"
            ) : (
              <FormatAmount
                price={
                  owner.owner_account_balance === "0"
                    ? "0.0"
                    : owner.owner_account_balance === "0.0"
                    ? "0.0"
                    : (
                        Math.floor(Number(owner.owner_account_balance) * 100) /
                        100
                      ).toFixed(2)
                }
              />
            )}
          </p>
          <p>
            Balance - {owner?.owner_account_balance_last_fetched_at_in_words}
          </p>
        </div>
        <Tooltip
          componentsProps={{
            popper: {
              sx: {
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: (theme) => theme.palette.common.black,
                },
              },
            },
          }}
          title="Refresh"
        >
          <div
            style={{
              cursor:
                services?.includes("VAccountBalance") ||
                services?.includes("Admin")
                  ? "pointer"
                  : "not-allowed",
              opacity:
                services?.includes("VAccountBalance") ||
                services?.includes("Admin")
                  ? "1"
                  : "0.6",
            }}
            className="right-second-child"
            onClick={(e) => {
              if (
                services?.includes("VAccountBalance") ||
                services?.includes("Admin")
              ) {
                e.stopPropagation();
                handleRefresh(owner?.id);
                setAccId(owner?.id);
              } else {
                e.stopPropagation();
                navigate("/feature_not_assigned", {
                  state: { unavailableService: "VAccountBalance,Admin" },
                });
              }
            }}
          >
            {ownerAccBalanceLoading && accId === owner?.id ? (
              <BalanceLoader loader={ownerAccBalanceLoading} type="owner-dsb" />
            ) : !ownerAccBalanceLoading &&
              !ownerAccBalanceError &&
              accId === owner?.id ? (
              <BalanceLoader
                loader={ownerAccBalanceLoading}
                setAccId={setAccId}
                type="owner-dsb"
              />
            ) : (
              <img src={refreshIcon} alt="refresh icon" />
            )}
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

export default ConnectedBankOwnerCard;
