import React, { useEffect, useState } from "react";
import dataLoading from "../../../../assets/Beneficiary Icons/inputloder.gif";
import entities from "../../../../assets/Beneficiary Icons/entities.svg";
import birthday from "../../../../assets/Beneficiary Icons/birthday.svg";
import companytype from "../../../../assets/Beneficiary Icons/companytype.svg";
import companyaddress from "../../../../assets/Beneficiary Icons/companyaddress.svg";
import address from "../../../../assets/Beneficiary Icons/address.svg";
import arrowRight from "../../../../assets/Accounts/Create/arrowright.png";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import GeoMapLeaflet from "../../../../components/_utils/GeoMapLeaflet";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  beneVerifyThisGST,
  beneVerifyThisPAN,
} from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { checkUsertoken } from "../../../../redux/features/login/login";
import formatGSTDate from "../../../../utils/formatGSTDate";

const BeneGSTDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const { beneVerifyGST, isGSTError, isGSTLoading } = useSelector(
    (state) => state.beneAllVerification
  );
  const services = useSelector((state) => state.refreshToken.services);
  useEffect(() => {
    if (id.length == 15) {
      async function fetchGSTData() {
        setLoading(true);
        try {
          dispatch(checkUsertoken());
          let response = await dispatch(
            beneVerifyThisGST({ search_string: id })
          );
          if (
            response?.error?.message == "Request failed with status code 404"
          ) {
            navigate("*");
          }
        } catch (error) {
          console.error("Error", error);
        } finally {
          setLoading(false);
        }
      }

      fetchGSTData();
    }
  }, [id]);
  const handlePANDetails = async (pan) => {
    if (pan?.length === 10) {
      dispatch(checkUsertoken());
      const response = await dispatch(
        beneVerifyThisPAN({ search_string: pan })
      );
      if (response?.payload?.status === true) {
        // navigate(`/accounts/beneficiary_account/details/GST_details/${gstin}`);
        navigate(`/settings/PAN_Verification`, { state: pan });
      } else if (response?.payload?.status === false) {
        toast.error(`${response?.payload?.errors?.base[0]}`, toast_position);
      }
    }
  };
  return (
    <div className="main-div">
      {loading ? (
        <div className="loader-container m-auto justify-center items-center flex">
          <img src={dataLoading} alt="" />
        </div>
      ) : (
        <>
          <div className="div-fixed-gst">
            <div className="profile">
              <div className="">
                <div className="corp-name">Corporate Profile</div>
                <div className="parent-div top-bottom-spacing">
                  <div className="corp-divide-one">
                    <div className="title-label-block">
                      <div className="title-padding">
                        <img
                          src={entities}
                          alt="entities"
                          style={{ height: "60px", width: "60px" }}
                        />
                      </div>
                      <div className="corp-name-main">
                        {beneVerifyGST.gstin_details.business_name.toLowerCase()}
                        <div className="corp-name-label">Company Name</div>
                      </div>
                    </div>
                    <div className="title-label-block">
                      <div className="title-padding title-padding-add"></div>
                      <div className="corp-sub-name row-sub-name">
                        <img
                          src={birthday}
                          alt="birthday"
                          style={{ height: "20px", width: "16px" }}
                        />
                        <span className="row-sub-name-pad">
                          {formatGSTDate(
                            beneVerifyGST.gstin_details.date_of_registration
                          )}
                        </span>
                        <div className="corp-name-label"></div>
                      </div>
                    </div>
                    <div className="title-label-block">
                      <div className="title-padding title-padding-add"></div>
                      <div className="corp-sub-name row-sub-name">
                        <img
                          src={companytype}
                          alt="companytype"
                          style={{ height: "20px", width: "16px" }}
                        />
                        <span className="row-sub-name-pad">
                          {beneVerifyGST.gstin_details.taxpayer_type}
                        </span>
                        <div className="corp-name-label"></div>
                      </div>
                    </div>
                  </div>
                  <div className="corp-divide-two">
                    <div className="title-label-block mb-[10px]">
                      <div className="corp-sub-name title-padding-gst">
                        {beneVerifyGST.gstin_details.gstin_no}
                        <div className="corp-name-label">GST No.</div>
                      </div>
                    </div>
                    <div className="title-label-block mt-[0px]">
                      <div
                        className={`corp-sub-name nav-pan-padding-add hover:bg-[#667CA3] hover:shadow-lg hover:rounded-md pl-[5px] ${
                          services?.includes("VPAN")
                            ? "cursor-pointer"
                            : "cursor-default"
                        }`}
                        onClick={
                          beneVerifyGST.gstin_details.pan_no
                            ? () => {
                                if (
                                  services?.find(
                                    (service) =>
                                      service === "VPAN" || service === "Admin"
                                  ) !== undefined &&
                                  beneVerifyGST.gstin_details.pan_no
                                ) {
                                  handlePANDetails(
                                    beneVerifyGST.gstin_details.pan_no
                                  );
                                } else {
                                  navigate("/feature_not_assigned", {
                                    state: {
                                      unavailableService: "VPAN",
                                    },
                                  });
                                }
                              }
                            : null
                        }
                      >
                        <div className="nav-pan-btn">
                          <div>{beneVerifyGST.gstin_details.pan_no}</div>
                          <div>
                            {beneVerifyGST.gstin_details.pan_no && (
                              <div className="grid place-content-center w-5 h-5 rounded-md nav-pan-style mt-[4px]">
                                <img
                                  src={arrowRight}
                                  alt="document"
                                  className="w-3 h-3"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="corp-name-label">PAN Number</div>
                      </div>
                    </div>
                    <div className="title-label-block">
                      <div className="corp-sub-name title-padding-add">
                        {beneVerifyGST.gstin_details.status}
                        <div className="corp-name-label">Company Status</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-auto-gst">
            <div className="location">
              <div className="parent-div">
                <div className="corp-divide-one">
                  <div className="title-label-block">
                    <div className="title-padding">
                      <img
                        src={address}
                        alt="entities"
                        style={{ height: "40px", width: "40px" }}
                      />
                    </div>
                    <div className="corp-sub-name address-corp">
                      Corporate Address
                    </div>
                  </div>
                  <div className="title-label-block">
                    <div
                      className="title-padding location-icon"
                      style={{ height: "40px", width: "40px" }}
                    >
                      {/* <img
                          src={address}
                          alt="entities"
                          style={{ height: "40px", width: "40px" }}
                        /> */}
                    </div>
                    <div className="corp-sub-name">
                      {beneVerifyGST.gstin_details.address}
                    </div>
                  </div>
                </div>
                <div className="corp-divide-two">
                  <div className="geo-map-pad">
                    <>
                      <GeoMapLeaflet
                        address={beneVerifyGST.gstin_details.address}
                        owner={"beneDetails"}
                      />
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BeneGSTDetails;
