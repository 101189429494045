import React from "react";
import Navbar from "../../components/LandingPage/Navbar";
import { Outlet } from "react-router-dom";

const LandingPageContainer = () => {
  return (
    <>
      <div className="App" style={{ backgroundColor: "#031f4f" }}>
        <Navbar />
        <Outlet />
      </div>
    </>
  );
};

export default LandingPageContainer;
