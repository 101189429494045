import { useEffect } from "react";
import InternalTransactionDetails from "../../../containers/InternalTransactionMain/InternalTransactionDetails";
import "./index.css";

function InternalTxnDetailPagePopup({
  closeInternalTxnDetailModal,
  internal_txn_id,
  type,
  error_msg,
  setShowInternalTxnDetailPop,
  setErrorMessage,
}) {
  useEffect(() => {
    return () => {
      setShowInternalTxnDetailPop(false);
      setErrorMessage("");
    };
  }, []);

  return (
    <>
      <div
        className="InternalTxnDetailPop_div fixed overflow-hidden rounded-[20px] z-[9999] bg-[#1D3A6D]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="h-full w-full">
          <InternalTransactionDetails
            internal_txn_id={internal_txn_id}
            closeInternalTxnDetailModal={closeInternalTxnDetailModal}
            type={type}
            error_msg={error_msg}
          />
        </div>
      </div>
    </>
  );
}

export default InternalTxnDetailPagePopup;
