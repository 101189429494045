import React, { useEffect, useState } from 'react'
import EditEntityLogo from '../../../../../assets/Settings/Entity Details/Edit details.png'
import { useSelector, useDispatch } from 'react-redux';
import UserDetailsBanner from '../../../../../components/Settings/Management/Users/UserDetailsBanner';
import DetailsMenuButton from '../../../../../components/Settings/Management/DetailsMenuButton';
import DetailsCards from '../../../../../components/Settings/Management/DetailsCards';
import DetailsDataCard from '../../../../../components/Settings/Management/DetailsDataCard';
import EditRoles from '../../../../../assets/Settings/Entity Details/Edit roles.svg'
import { getUsers } from '../../../../../redux/features/Settings/Management/Users/index';


function UserDetails() {
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState("Details");

    const menuOptions = [
        "Details",
        "Roles",
        "Features",
        "Services",
        "Entities",
    ]

    useEffect(() => {
        dispatch(getUsers({
            user_id: "43279294-cce0-4c72-ac8c-0fd0ee044ba8"
        }))
    }, [])

    const userData = useSelector((state) => state.users?.users?.users[0]);
    const rolesData = useSelector((state) => state.users?.users?.user_roles);
    const servicesData = useSelector((state) => state.users?.users?.user_services);
    const featuresData = useSelector((state) => state.users?.users?.user_features);

    //Replace this with API data
    const data = {
        "PAN details": {
            "PAN number": "OIAPK1298I"
        },
        "Additional Details": {
            "Department (optional)": "Not Given",
            "Designation (optional)": "Not Given",
            "Employment ID (optional)": "Not Given",
        },
        "Contact details": {
            "Contact Number": userData?.mob_no ? userData.mob_no : "Not Given",
            "Email ID": userData?.email_id ? userData.email_id : "Not Given",
        },
        "Origin Timeline": {
            "Created by": userData?.created_by ? userData.created_by : "Not Given",
            "Created on": "Not Given"
        }
    }

    return (
        <div className='bg-[#244277] w-screen h-screen pt-24 flex no-scrollbar'>
            <div className='bg-[#244277] pt-10 w-2/3 shadow-xl pl-32 pr-10 no-scrollbar'>
                <UserDetailsBanner data={userData} />
                <div className='3xl:text-base 2xl:text-sm 2xl:w-9/12 xl:text-xs w-full py-5'>
                    <DetailsMenuButton menuOptions={menuOptions} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
                </div>
                {selectedOption === "Details" &&
                    <div className='flex gap-5'>
                        <div className='flex flex-col w-6/12 gap-5'>
                            <DetailsCards title={"PAN details"} data={data["PAN details"]} />
                            <DetailsCards title={"Additional Details"} data={data["Additional Details"]} />
                        </div>
                        <div className='flex flex-col w-6/12 gap-5'>
                            <DetailsCards title={"Contact Details"} data={data["Contact details"]} />
                            <DetailsCards title={"Origin Timeline"} data={data["Origin Timeline"]} />
                        </div>
                    </div>}
                {selectedOption === "Roles" &&
                    <div className='flex flex-wrap gap-x-10 gap-y-5'>{rolesData?.map((item) =>
                        <DetailsDataCard>{item.role_name}</DetailsDataCard>
                    )}
                    </div>}
                {selectedOption === "Features" &&
                    <div className='flex flex-col gap-5'>
                        <div className='text-white w-full flex justify-start items-center text-xl font-semibold'>Accounts</div>
                        <div className='flex flex-wrap gap-x-10 gap-y-5'>{featuresData?.map((item) =>
                            <DetailsDataCard>{item.feature_name}</DetailsDataCard>
                        )}
                        </div>
                    </div>}
                {
                    selectedOption === "Services" && <div className='flex flex-col gap-5'>
                        <div className='text-white w-full flex justify-start items-center text-xl font-semibold'>Accounts</div>
                        <div className='flex flex-wrap gap-x-10 gap-y-5'>{servicesData?.map((item) =>
                            <DetailsDataCard>{item.service_name}</DetailsDataCard>
                        )}
                        </div>
                    </div>
                }
                {
                    selectedOption === "Entities" && <div className='flex flex-col gap-5'>
                        <div className='text-white w-full flex justify-start items-center text-xl font-semibold'>A</div>
                        <div>
                            <DetailsDataCard>Hello iauhskjdhakjsd a kjshdajksbhd aihsdkjahsd ajhsdjkahsdkjh </DetailsDataCard>
                        </div>
                    </div>
                }
            </div>
            <div className='bg-[#1D3A6D] pl-10 pt-10 w-1/3'>
                <div className='mt-5'>
                    <div className='w-[60%] rounded-4xl grid grid-cols-1 divide-y divide-[#1D3A6D] p-3 bg-[#3A5686]'>
                        <div className='flex py-3 divide-x divide-[#1D3A6D]'>
                            <button className='flex flex-col items-center gap-2 w-1/2'>
                                <img src={EditEntityLogo} className='w-10 h-10' />
                                <div className='text-white text-xs'>Edit Details</div>
                            </button>
                            <button className='flex flex-col items-center gap-2 w-1/2'>
                                <img src={EditRoles} className='w-10 h-10' />
                                <div className='text-white text-xs'>Edit Roles</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default UserDetails