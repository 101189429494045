const formatDate = (timestamp, need_time) => {
  const date = new Date(timestamp * 1000);
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = weekdays[date.getDay()];
  const dayNumber = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  let hours = date.getHours();
  const ampm = hours >= 12 ? "pm" : "am";
  hours %= 12;
  hours = hours || 12;
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  var formattedDate = "";
  if (!need_time) {
    formattedDate = `${day}, ${dayNumber} ${month} ${year}`;
  }
  if (need_time == undefined) {
    formattedDate = `${day}, ${dayNumber} ${month} ${year}, ${hours}:${
      minutes < 10 ? "0" + minutes : minutes
    } ${ampm}`;
  }
  if (need_time) {
    formattedDate = `${month} ${dayNumber}, ${year}, ${
      hours < 10 ? "0" + hours : hours
    }:${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    } ${ampm.toUpperCase()}`;
  }
  return formattedDate;
};
export default formatDate;
