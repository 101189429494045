import React, { useState, useEffect } from "react";
import Footer from "../../../../components/LandingPage/Footer";
import BackToTop from "../../../../assets/landingPage/TopArrow.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import termsimage from "../../../../assets/Landing/terms.png";
import { useSelector } from "react-redux";

const TermsConditions = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPercent, setScrollPercent] = useState(0);

  const { mobilestatus, webstatus } = useSelector(
    (state) => state.landingpagestate
  );

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20 || document.documentElement.scrollTop > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scrollTop = window.scrollY;
      let docHeight = document.documentElement.scrollHeight;
      let winHeight = window.innerHeight;
      let scrollPercentt = scrollTop / (docHeight - winHeight);
      let scrollPercentRounded = Math.round(scrollPercentt * 100);
      setScrollPercent(scrollPercentRounded);
    });
  }, [scrollPercent]);

  useEffect(() => {
    window.onload = function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 50);
      setScrollPercent(0);
    };
  }, []);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          size="59px"
          sx={{ color: "#31E5E5" }}
          variant="determinate"
          {...props}
        />
        <Box
          sx={{
            zIndex: 999,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: "text.secondary" }}
          >
            <img
              src={BackToTop}
              alt="back to top"
              width="50"
              style={{ right: "45px", position: "fixed", bottom: "36px" }}
            />
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      <div>
        {isVisible && (
          <div
            onClick={scrollToTop}
            className="fixed bottom-[25px] right-[40px] cursor-pointer z-20"
          >
            <CircularProgressWithLabel value={scrollPercent} />
          </div>
        )}
        <>
          <div
            style={{
              overflow: webstatus ? "hidden" : "",
              overflowY: mobilestatus && "hidden",
              height: "100vh",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              WebkitOverflowScrolling: "touch",
            }}
          >
            <div className="flex flex-col justify-center items-center relative pt-[88px] bg-[#1D3A6D]">
              <div className="text-center">
                <h2 className="text-[28px] pb-2 text-[#F9F9F9] font-semibold">
                  Terms and <span className="text-[#31E5E5]">Conditions</span>
                </h2>
              </div>

              <div className="h-full max-w-[1024px] w-screen flex justify-center mx-auto relative">
                <div className="w-full flex flex-col md:flex-row items-center py-5 px-5">
                  <div className="w-full md:w-1/2 order-1 md:order-2">
                    <img
                      className="landingimg mx-auto"
                      src={termsimage}
                      alt="Terms and Conditions"
                    />
                  </div>
                  <div className="text-[#DADADA] w-full md:w-1/2 mt-4 md:mt-0 md:pl-5 order-2 md:order-1">
                    Using the functionality and services offered on this website
                    means that you accept all of the terms and conditions
                    contained in the User Agreement signed between the
                    Corporation and Xpentra technologies Pvt Ltd.
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </>
      </div>
    </div>
  );
};

export default TermsConditions;
