import React, { useState, useEffect, useRef, useCallback } from "react";
import "./index.css";
import { debounce } from "lodash";

const TxnSearchBar = ({
  showSearchBar,
  handleSearch,
  handlePaste,
  setTxnAmount,
  txnAmount,
  txnAddDetails,
  setTxnAddDetails,
  txnSearchType,
  setTxnSearchType,
  setShowSearchBar,
  toggleShowSearchBar,
  setCurrentPage,
  searchBarInputRef,
  transactionHistorypopup,
  flow,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [localAmount, setLocalAmount] = useState(txnAmount);
  const [localAddDetails, setLocalAddDetails] = useState(txnAddDetails);

  const inputRef = useRef(null);

  const debouncedSetFilterPayload = useRef(
    debounce((value) => {
      setTxnAddDetails(value);
    }, 1000)
  ).current;

  const handleChange = useCallback(
    (e) => {
      const data = e.target.value;
      setLocalAddDetails(data);
      setTxnSearchType("additional");

      if (data === "") {
        setTxnAmount("");
      }

      debouncedSetFilterPayload(data);
    },
    [debouncedSetFilterPayload, setTxnSearchType]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter") {
        handleSearch(localAddDetails);
      }
    },
    [handleSearch, localAddDetails]
  );

  const handleClear = (e) => {
    e?.stopPropagation();
    setTxnAmount("");
    setLocalAddDetails("");
    setTxnAddDetails("");
  };

  useEffect(() => {
    if (localAddDetails === "") {
      handleClear();
    }
  }, [localAddDetails]);

  const handleButtonClick = () => {
    handleSearch(localAddDetails);
  };

  useEffect(() => {
    setLocalAddDetails(txnAddDetails);
  }, [txnAddDetails]);

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  // useEffect(() => {
  //   const handleClickOutside = (e) => {
  //     if (searchBarRef.current && !searchBarRef.current.contains(e.target)) {
  //       setIsOpen(false);
  //       setShowSearchBar(false);
  //     }
  //   };

  //   window.addEventListener("mousedown", handleClickOutside);
  //   return () => window.removeEventListener("mousedown", handleClickOutside);
  // }, [setIsOpen, setShowSearchBar]);

  const handleOpenSearchBar = () => {
    setIsOpen(true);
    setShowSearchBar(true);
  };

  return (
    <div
      className={`bene-txn-searchbar ${isOpen ? "open" : "closed"}`}
      ref={searchBarInputRef}
      onClick={handleOpenSearchBar}
    >
      {showSearchBar && (
        <div
          className="bene-txn-viewall-search-bar"
          style={{
            paddingBottom:
              transactionHistorypopup && flow === "internal"
                ? "22px"
                : transactionHistorypopup
                ? "12px"
                : null,
            borderTop: flow === "internal" ? "1px solid rgb(29, 58, 109)" : "",
            borderBottom:
              flow !== "internal" ? "1px solid rgb(29, 58, 109)" : "",
            margin:
              transactionHistorypopup && flow === "internal"
                ? "-12px auto auto auto"
                : flow === "internal"
                ? "-26px auto auto auto"
                : "0 auto",
            paddingTop:
              flow === "internal" && !transactionHistorypopup ? "2px" : "",
          }}
        >
          <div className="relative h-[36px] opacity-100">
            <img
              className="bene_viewall_search_inner_image_acc cursor-default"
              src={require("../../../assets/Approve transactions/search-white.png")}
              alt="search-inner-image"
              onClick={(e) => {
                e.stopPropagation();
                inputRef.current.focus();
              }}
              style={{
                marginTop:
                  (transactionHistorypopup && flow === "internal") ||
                  flow === "internal"
                    ? "22px"
                    : transactionHistorypopup
                    ? "12px"
                    : "-2px",
              }}
            />
            <input
              autoFocus
              ref={inputRef}
              onClick={handleInputClick}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              autoComplete="off"
              id="txn_search_input"
              name="search"
              className={`block h-[36px] rounded-[5px] bg-Blue_4 text-Primary_grey placeholder:text-Primary_grey placeholder:text-Font_14`}
              placeholder="Search by additional details"
              value={localAddDetails}
              style={{
                marginTop:
                  (transactionHistorypopup && flow === "internal") ||
                  flow === "internal"
                    ? "10px"
                    : transactionHistorypopup
                    ? "0px"
                    : "-13px",
              }}
            />
            <div className="cursor-pointer m-auto absolute right-0 flex items-center pr-3 top-[-3px]">
              {localAddDetails && (
                <img
                  onClick={handleClear}
                  className="bene_txn_search_close_image"
                  src={require("../../../assets/Approve transactions/Close-icon.png")}
                  alt="search-close"
                  style={{
                    marginTop:
                      transactionHistorypopup && flow === "internal"
                        ? "23px"
                        : transactionHistorypopup && flow === "beneTxn"
                        ? "12px"
                        : flow === "beneTxn"
                        ? "0px"
                        : "23px",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TxnSearchBar;
