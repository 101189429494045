import { createSlice } from "@reduxjs/toolkit"

let initialState = {
    webstatus: false,
    mobilestatus: false,
}

export const landingpagestate = createSlice({
    name: "landingpagestate",
    initialState,
    reducers: {
        weblandingpagestate: (state, action) => {
            state.webstatus = action.payload
        },
        mobilelandingpagestate: (state, action) => {
            state.mobilestatus = action.payload
        },
        resetlandingpagestate: (state) => {
            state.webstatus = false
            state.mobilestatus = false
        }
    }
})

export const { weblandingpagestate, mobilelandingpagestate, resetlandingpagestate } = landingpagestate.actions;

export default landingpagestate.reducer;