import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import "./index.css";
import { useNavigate } from "react-router-dom";
import addbutton from "../../../assets/AddIcons/add_button_white.svg";
import arrowright from "../../../assets/ArrowsIcons/ChevronRightWhite.svg";
import Arrow_Right from "../../../assets/ArrowsIcons/arrow_left_blue_box.svg";
import bill from "../../../assets/GeneralIcons/send_mis_report_gradient.svg";
import PrevIcon from "../../../assets/ArrowsIcons/arrow_left_white_circle.svg";
import NextIcon from "../../../assets/ArrowsIcons/arrow_right_white_circle.svg";
import { useEffect, useState } from "react";
import { internalTxnViewAll } from "../../../redux/features/internalTransaction/internalTransactionViewAll";

import InternalTxnDataCard from "../../../components/InternalTransactionMain/InternalDataCard";
import Datacard from "../../../components/Datacard";
import xpentraloader from "../../../assets/Beneficiary Icons/lodemore.gif";
import NoDataImg from "../../../assets/Graphics/No transaction found.svg";
import internalImg from "../../../assets/InternalIcons/internal_with_white_circle.svg";
import mobilehamburgerMenu from "../../../assets/hamburger_menu_with_downwards_arrow.svg";
import { checkUsertoken } from "../../../redux/features/login/login";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { toast } from "react-toastify";
import { resetViewAllAcc } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import Confidential_transactions from "../../../assets/GeneralIcons/confidential_transaction.svg";
import Loader from "../../../components/Loader/Index";
const InternalTxnDashboard = () => {
  const [showSecondContent, setShowSecondContent] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [currentDot, setcurrentDot] = useState(1);
  const [disablePrev, setDisablePrev] = useState(true);
  const [isConfidentialFilter, setisConfidentialFilter] = useState(
    "Show only confidential"
  );

  const services = useSelector((state) => state.refreshToken.services);
  const navigate = useNavigate();
  // const [internalTxnData, setInternalTxnData] = useState([]);

  const [windowWidth, setWindowWidth] = useState(window?.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const dispatch = useDispatch();

  const { internalTxnViewAllAccData, internalTxnViewAllAccLoading } =
    useSelector((state) => state?.internalTxnViewall);

  const getHeight = () => {
    if (currentDot === 1) return "270px";
    if (currentDot === 2) return "380px";
    if (currentDot === 3) return "150px";
    return "auto";
  };

  useEffect(() => {
    dispatch(checkUsertoken());
    const fetchData = async () => {
      try {
        const responseApproval = await dispatch(
          internalTxnViewAll({
            page_no: 1,
            page_size: 50,
          })
        );
      } catch (error) {
        console.error("Error fetching transaction data:", error);
      }
    };
    fetchData();
  }, [dispatch]);

  const getNavHeight = () => {
    if (windowWidth <= 800) {
      if (
        firstFiveDataCards?.length > 3 ||
        SecondFiveDataCards?.length > 3 ||
        ThirdFiveDataCards?.length > 3
      ) {
        return "320px";
      } else {
        return "200px";
      }
    } else {
      return "280px";
    }
  };
  const hasConfidential = services?.includes("VConfidentialTxn");
  const sortOrder = [
    "actionable_transaction_count",
    "pending_transaction_count",
    "approved_transaction_count",
    "rejected_transaction_count",
    "abandoned_transaction_count",
    "distribution_complete_distribution_transaction_count",
    "created_distribution_transaction_count",
    "approved_distribution_transaction_count",
    "distribution_initiated_distribution_transaction_count",
    "awaiting_funds_distribution_transaction_count",
    "rejected_distribution_transaction_count",
    "failed_distribution_transaction_count",
    "abandoned_distribution_transaction_count",
  ];

  const handleDatacardClick = (approvalStatus, distributionStatus) => {
    const routeMap = {
      confidential: "/transaction/internal_transaction/view_all",
      approved: "/transaction/internal_transaction/view_all",
      pending: "/transaction/internal_transaction/view_all",
      // require:
      //   isMobileView === true && approvalStatus === "require"
      //     ? handleCommingToast()
      //     : "/transaction/beneficiary_transaction/transaction_single_approval",
      require: "/internal_transaction/single_approval_transaction",
      rejected: "/transaction/internal_transaction/view_all",
      settled: "/transaction/internal_transaction/view_all",
      cancelled: "/transaction/internal_transaction/view_all",
      failed: "/transaction/internal_transaction/view_all",
      abandoned: "/transaction/internal_transaction/view_all",
      queued: "/transaction/internal_transaction/view_all",
      distribution_initiated: "/transaction/internal_transaction/view_all",
      distribution_complete: "/transaction/internal_transaction/view_all",
      awaiting_funds: "/transaction/internal_transaction/view_all",
      created: "/transaction/internal_transaction/view_all",
    };

    const isDistributionStatus = distributionStatus
      .toLowerCase()
      .includes("distribution");
    const isDashboradCheck = approvalStatus === "confidential" ? true : false;

    const DistributionStatus = isDistributionStatus ? true : false;
    navigate(routeMap[approvalStatus], {
      state: {
        approvalStatus,
        DistributionDashBoard: DistributionStatus,
        isConfidentialFilter,
        isDashboradCheck,
      },
    });
  };

  const summaryData =
    Object.entries(internalTxnViewAllAccData?.summary || []).sort(
      (a, b) => sortOrder.indexOf(a[0]) - sortOrder.indexOf(b[0])
    ) || [];

  const distributionData = summaryData?.filter(([key]) =>
    key.includes("distribution")
  );

  const transactionData = summaryData?.filter(
    ([key]) => !key.includes("distribution")
  );

  const hasNonZeroDistribution = distributionData?.some(
    ([key, value]) => value === 0
  );

  const dashboardData = internalTxnViewAllAccData?.transactions?.slice(0, 8);

  let firstFiveDataCards = [];
  let SecondFiveDataCards = [];
  let ThirdFiveDataCards = [];

  if (transactionData?.length > 0) {
    firstFiveDataCards = transactionData;
  }

  if (distributionData?.length > 7) {
    SecondFiveDataCards = distributionData?.slice(0, 7);
    ThirdFiveDataCards = distributionData?.slice(7);
  } else {
    SecondFiveDataCards = distributionData;
  }

  const getTransactionDataCardsDetails = (accountType, accountAccount) => {
    let accountTypeName;
    let status;
    let color;
    let distributionStatus;
    switch (accountType) {
      case "confidential_transaction_count":
        accountTypeName = "Confidential transactions";
        distributionStatus = "confidential_transaction_coun";
        status = "confidential";
        color = "var(--Primary_white)";
        break;
      case "approved_transaction_count":
        accountTypeName = "Approved";
        distributionStatus = "approved_transaction_count";
        status = "approved";
        color = "var(--Awaiting)";
        break;
      case "actionable_transaction_count":
        accountTypeName = "Require approval";
        status = "require";
        distributionStatus = "actionable_transaction_count";
        color = "var(--Pending)";
        break;
      case "pending_transaction_count":
        accountTypeName = "Pending";
        status = "pending";
        color = "var(--Pending)";
        distributionStatus = "pending_transaction_count";
        break;
      case "rejected_transaction_count":
        accountTypeName = "Rejected";
        status = "rejected";
        color = "var(--Failed)";
        distributionStatus = "rejected_transaction_count";
        break;
      case "abandoned_transaction_count":
        accountTypeName = "Abandoned";
        status = "abandoned";
        distributionStatus = "abandoned_transaction_count";
        color = "var(--Abandoned)";
        break;
      case "distribution_complete_distribution_transaction_count":
        accountTypeName = "Distribution Complete";
        status = "distribution_complete";
        distributionStatus =
          "distribution_complete_distribution_transaction_count";
        color = "var(--Settled)";
        break;
      case "created_distribution_transaction_count":
        accountTypeName = "Distribution Created";
        distributionStatus = "created_distribution_transaction_count";
        status = "created";
        color = "var(--Queued)";
        break;
      case "approved_distribution_transaction_count":
        accountTypeName = `Distribution Approved`;
        status = "approved";
        color = "var(--Queued)";
        distributionStatus = "approved_distribution_transaction_count";
        break;
      case "distribution_initiated_distribution_transaction_count":
        accountTypeName = "Distribution initiated";
        status = "distribution_initiated";
        color = "var(--Queued)";
        distributionStatus =
          "distribution_initiated_distribution_transaction_count";
        break;
      case "awaiting_funds_distribution_transaction_count":
        accountTypeName = "Awaiting funds";
        status = "awaiting_funds";
        distributionStatus = "awaiting_funds_distribution_transaction_count";
        color = "var(--Queued)";
        break;
      case "rejected_distribution_transaction_count":
        accountTypeName = `Distribution rejected`;
        status = "rejected";
        color = "var(--Queued)";
        distributionStatus = "rejected_distribution_transaction_count";
        break;
      case "failed_distribution_transaction_count":
        accountTypeName = `Distribution failed`;
        status = "failed";
        color = "var(--Queued)";
        distributionStatus = "failed_distribution_transaction_count";
        break;

      case "abandoned_distribution_transaction_count":
        accountTypeName = "Distribution Abandoned";
        status = "abandoned";
        color = "var(--Abandoned)";
        distributionStatus = "abandoned_distribution_transaction_count";
        break;

      default:
        accountTypeName = "Unknown";
        status = "unknown";
        distributionStatus = "Unknown";
        color = "var(--Queued)";
        break;
    }

    return { accountTypeName, status, color, distributionStatus };
  };

  const beneTxnNext = () => {
    if (dashboardData?.length === 0) {
      if (currentDot < 2) {
        setcurrentDot(currentDot + 1);
        setShowSecondContent(!showSecondContent);
        setDisablePrev(false);
      }
    } else {
      if (hasNonZeroDistribution) {
        if (currentDot < 2) {
          setcurrentDot(currentDot + 1);
          setShowSecondContent(!showSecondContent);
          setDisablePrev(false);
        }
      } else if (currentDot < 3) {
        setcurrentDot(currentDot + 1);
        setShowSecondContent(!showSecondContent);
        // setDisableNext(true);
        setDisablePrev(false);
      }
    }
  };

  const beneTxnPrev = () => {
    if (currentDot > 1) {
      setcurrentDot(currentDot - 1);
      setShowSecondContent(!showSecondContent);
      // setDisablePrev(true);
      setDisableNext(false);
    }
  };
  const showDataCardDetailsFirstDiv = (cards) => {
    return cards

      ?.filter(([accountType, accountCount], index, arr) => {
        const excludeTypes = [
          // "approved_transaction_count",
          "cancelled_transaction_count",
          "blocked_transaction_count",
        ];

        if (
          accountType === "pending_transaction_count" &&
          arr.some(
            ([otherType, otherCount]) =>
              otherType === "actionable_transaction_count" &&
              otherCount === accountCount
          )
        ) {
          return false;
        }
        if (excludeTypes.includes(accountType)) {
          return false;
        }

        if (
          accountCount <= 0 &&
          accountType !== "pending_transaction_count" &&
          accountType !== "actionable_transaction_count"
        ) {
          return false;
        }

        // Service-based filtering
        if (
          (services?.includes("AInternalTxn") &&
            services?.includes("CInternalTxn")) ||
          services?.includes("Admin")
        ) {
          return true;
        }
        if (
          accountType === "confidential_transaction_count" &&
          !services?.includes("VConfidentialTxn")
        ) {
          return false;
        }
        if (
          services?.includes("AInternalTxn") &&
          !services?.includes("CInternalTxn")
        ) {
          // return accountType !== "pending_transaction_count";
          return true;
        }

        if (
          services?.includes("CInternalTxn") &&
          !services?.includes("AInternalTxn")
        ) {
          return accountType !== "actionable_transaction_count";
        }
        return true;
      })
      ?.sort((a, b) => sortOrder.indexOf(a[0]) - sortOrder.indexOf(b[0]))
      ?.map(([accountType, accountCount]) => {
        const { accountTypeName, status, color, distributionStatus } =
          getTransactionDataCardsDetails(accountType, accountCount);
        return (
          <Box
            key={accountType}
            sx={{
              opacity: "0.9",
              height: "111px",
              // width: windowWidth <= 800 ? "100px" : "100%",
            }}
          >
            <Datacard
              type="internal-dsb"
              color={color}
              onClick={() => {
                handleDatacardClick(status, distributionStatus);
              }}
              text={
                accountTypeName === "Unknown" ? accountType : accountTypeName
              }
              number={accountCount}
            />
          </Box>
        );
      });
  };

  const showDataCardDetailsSecondDiv = (cards) => {
    return cards
      ?.filter(([accountType, accountCount]) => {
        // if (
        //   (accountCount <= 0 &&
        //     accountType !== "pending_transaction_count" &&
        //     accountType !== "actionable_transaction_count") ||
        //   accountType === "approved_transaction_count"
        // ) {
        //   return false;
        // }
        if (
          accountCount <= 0 &&
          accountType !== "distribution_complete_distribution_transaction_count"
        ) {
          return false;
        }
        if (
          (services?.includes("AInternalTxn") &&
            services?.includes("CInternalTxn")) ||
          services?.includes("Admin")
        ) {
          return true;
        }
        if (
          services?.includes("AInternalTxn") &&
          !services?.includes("CInternalTxn")
        ) {
          return accountType !== "pending_transaction_count";
        }

        if (
          services?.includes("CInternalTxn") &&
          !services?.includes("AInternalTxn")
        ) {
          return accountType !== "actionable_transaction_count";
        }

        return accountType;
      })
      ?.sort((a, b) => sortOrder.indexOf(a[0]) - sortOrder.indexOf(b[0]))
      ?.map(([accountType, accountCount]) => {
        const { accountTypeName, status, color, distributionStatus } =
          getTransactionDataCardsDetails(accountType, accountCount);
        return (
          <Box
            key={accountType}
            sx={{
              opacity: "0.9",
              height: "111px",
            }}
          >
            <Datacard
              type="internal-dsb"
              color={color}
              onClick={() => handleDatacardClick(status, distributionStatus)}
              text={
                accountTypeName === "Unknown" ? accountType : accountTypeName
              }
              number={accountCount}
            />
          </Box>
        );
      });
  };

  const showDataCardDetailsThirdDiv = (cards) => {
    return cards
      ?.filter(([accountType, accountCount]) => {
        if (
          (accountCount <= 0 &&
            accountType !== "pending_transaction_count" &&
            accountType !== "actionable_transaction_count") ||
          accountType === "approved_transaction_count"
        ) {
          return false;
        }
        if (
          (services?.includes("AInternalTxn") &&
            services?.includes("CInternalTxn")) ||
          services?.includes("Admin")
        ) {
          return true;
        }
        if (
          services?.includes("AInternalTxn") &&
          !services?.includes("CInternalTxn")
        ) {
          return accountType !== "pending_transaction_count";
        }

        if (
          services?.includes("CInternalTxn") &&
          !services?.includes("AInternalTxn")
        ) {
          return accountType !== "actionable_transaction_count";
        }

        return accountType;
      })
      ?.sort((a, b) => sortOrder.indexOf(a[0]) - sortOrder.indexOf(b[0]))
      ?.map(([accountType, accountCount]) => {
        const { accountTypeName, status, color, distributionStatus } =
          getTransactionDataCardsDetails(accountType, accountCount);
        return (
          <Box
            key={accountType}
            sx={{
              opacity: "0.9",
              height: "111px",
            }}
          >
            <Datacard
              type="internal-dsb"
              color={color}
              onClick={() => handleDatacardClick(status, distributionStatus)}
              text={
                accountTypeName === "Unknown" ? accountType : accountTypeName
              }
              number={accountCount}
            />
          </Box>
        );
      });
  };

  return (
    <div className="internalTxn-dsbMainParent">
      <div className="internalTxn_Dashboard_mobile_header">
        <div className="internalTxnMobHeaderCont">
          <div className="bt-header-left-mobile">
            <img src={internalImg} alt="internalImg" />
            <div className="bt-header-left-mContent">
              <div className="internalText-dashboard-mob">Internal</div>
              <div className="internalTransText-dashboard-mob">
                Transactions
              </div>
            </div>
          </div>
          {/* <div className="bt-header-right-mobile">
            <img src={mobilehamburgerMenu} alt="mobilehamburgerMenu" />
          </div> */}
        </div>
      </div>
      <Box
        sx={{
          width: "100%",
          cursor: "default",
          marginTop: "60px",
          opacity: 1,
          height: "90px",
          justifyContent: "center",
          bgcolor: "var(--Blue_8)",
          display: windowWidth >= 800 && "none",
        }}
      >
        <div
          className={`internal-createAc ${
            services?.includes("CInternalTxn") || services?.includes("Admin")
              ? "hover:bg-custom-cardHover"
              : ""
          }`}
          style={{
            height: "90px",
            opacity:
              services?.includes("CInternalTxn") || services?.includes("Admin")
                ? "1"
                : "0.5",
            cursor:
              services?.includes("CInternalTxn") || services?.includes("Admin")
                ? "pointer"
                : "default",

            // pointerEvents:
            //   services?.includes("CInternalTxn") ||
            //   services?.includes("Admin")
            //     ? "auto"
            //     : "none",
          }}
          onClick={() => {
            if (services && !services.includes("CInternalTxn")) {
              dispatch(checkUsertoken());
              navigate("/feature_not_assigned", {
                state: {
                  unavailableService: "CInternalTxn",
                },
              });

              return;
            }
            if (
              services?.find((service) => service === "CInternalTxn") !==
              undefined
            ) {
              navigate("/transaction/internal_transaction/create");
            } else {
              navigate("/feature_not_assigned", {
                state: {
                  unavailableService: "CInternalTxn",
                },
              });
            }
            // toast.info("Feature coming soon...", toast_position);
          }}
        >
          <img src={addbutton} alt="img add" />
          <p className="internalTxn_CreateTxn">Create transaction</p>
        </div>
      </Box>
      <div
        className="internalTxn-right-middleSection"
        style={{
          opacity: summaryData?.length === 0 && "0.4",
          display: windowWidth >= 800 && "none",
        }}
      >
        <h5>Auto Transfer Matrix</h5>
        <p>
          Auto transfer allows you to schedule internal transactions for a
          single time or set it up to repeat at your chosen frequency.
        </p>{" "}
      </div>
      <button
        className="mob-view-matrix"
        style={{
          cursor: summaryData?.length === 0 && "default",
          display: windowWidth >= 800 && "none",
        }}
        disabled={summaryData?.length === 0 && true}
        onClick={() => {
          toast.info("Feature coming soon...", toast_position);
        }}
      >
        <p
          style={{
            opacity: summaryData?.length === 0 && "0.4",
          }}
        >
          View Matrix
        </p>
      </button>
      <div
        className="internalTxn-navMenu"
        style={{
          // height: accountTypesSummary?.length > 7 ? "230px" : "200px",

          height: getNavHeight(),

          padding: "15px 10px 20px 10px",
        }}
      >
        <>
          <div
            className="internal_GridLayout"
            style={{
              // height: isMobileView ? getHeight() : "9rem",
              height: windowWidth <= 800 ? "auto" : "8rem",
            }}
          >
            <div
              className={`transition-effects ${
                currentDot === 1 ? "showFirstContents" : "hideFirstContents"
              } ${windowWidth <= 800 && "isMobile"} `}
            >
              {summaryData?.length > 0 ? (
                showDataCardDetailsFirstDiv(firstFiveDataCards)
              ) : (
                <>
                  {(services?.includes("AInternalTxn") &&
                    services?.includes("CInternalTxn")) ||
                  services?.includes("Admin") ||
                  (services?.includes("AInternalTxn") &&
                    !services?.includes("CInternalTxn")) ? (
                    <div
                      className="internal_GridLayout"
                      style={{ height: "9rem" }}
                    >
                      <Box
                        sx={{
                          opacity: "0.9",
                          height: "111px",
                          cursor: "pointer",
                        }}
                      >
                        <Datacard
                          type="internal-dsb"
                          color={"var(--Pending)"}
                          text={"Require Approval"}
                          number={"00"}
                        />
                      </Box>
                    </div>
                  ) : services?.includes("CInternalTxn") &&
                    !services?.includes("AInternalTxn") ? (
                    <div
                      className="internal_GridLayout"
                      style={{ height: "9rem" }}
                    >
                      <Box
                        sx={{
                          opacity: "0.9",
                          height: "111px",
                          cursor: "pointer",
                        }}
                      >
                        <Datacard
                          type="internal-dsb"
                          color={"var(--Pending)"}
                          text={"Pending"}
                          number={"00"}
                        />
                      </Box>
                    </div>
                  ) : (
                    <div
                      className="internal_GridLayout"
                      style={{ height: "9rem" }}
                    >
                      <Box
                        sx={{
                          opacity: "0.9",
                          height: "111px",
                          cursor: "pointer",
                        }}
                      >
                        <Datacard
                          type="internal-dsb"
                          color={"var(--Pending)"}
                          text={"Require Approval"}
                          number={"0"}
                        />
                      </Box>
                    </div>
                  )}
                </>
              )}
            </div>
            <div
              className={`transition-effects ${
                currentDot === 2 ? "showSecondContents" : "hideSecondContents"
              }  ${windowWidth <= 800 && "isMobile"}`}
            >
              {summaryData?.length > 0 ? (
                showDataCardDetailsSecondDiv(SecondFiveDataCards)
              ) : (
                <div className="internal_GridLayout" style={{ height: "9rem" }}>
                  <Box
                    sx={{
                      opacity: "0.9",
                      height: "111px",
                      cursor: "pointer",
                    }}
                  >
                    <Datacard
                      type="internal-dsb"
                      color={"var(--Settled)"}
                      // onClick={() => handleDatacardClick("approved")}
                      text={"Distribution complete"}
                      number={"0"}
                    />
                  </Box>
                </div>
              )}
            </div>
            <div
              className={`transition-effects ${
                currentDot === 3 ? "showThirdContents" : "hideThirdContents"
              }  ${windowWidth <= 800 && "isMobile"}`}
            >
              {showDataCardDetailsThirdDiv(ThirdFiveDataCards)}
            </div>
          </div>
        </>
        <div
          className="internal_CaraouselBar"
          style={
            {
              // display: !summaryData?.length && "none",
            }
          }
        >
          <div>
            <p className="mx-auto  h-[19px] font-semibold text-[14px] leading-[19px] text-Primary_white">
              {currentDot === 1 ? "Approval Statuses" : "Distribution Statuses"}
            </p>
          </div>
          <div className="internal_CarouselDotbar">
            <div
              className="internal_FirstCaraoDot"
              // onClick={disablePrev ? null : beneTxnPrev}
              style={{
                width: currentDot === 1 ? "20px" : "12px",
                opacity: currentDot === 1 ? "1" : "0.65",
                transition: "width 0.4s ease, opacity 0.4s ease",
                // pointerEvents: isMobileView === false ? "none" : "auto",
              }}
            ></div>
            <div
              className="internal_SecondCaraoDot"
              // onClick={disableNext ? null : beneTxnNext}
              style={{
                width: currentDot === 2 ? "20px" : "12px",
                opacity: currentDot === 2 ? "1" : "0.65",
                transition: "width 0.4s ease, opacity 0.4s ease",
                // pointerEvents: isMobileView === false ? "none" : "auto",
              }}
            ></div>
            {ThirdFiveDataCards?.length > 0 && (
              <div
                className="internal_ThirdCaraoDot"
                // onClick={disableNext ? null : beneTxnNext}
                style={{
                  width: currentDot === 3 ? "20px" : "12px",
                  opacity: currentDot === 3 ? "1" : "0.65",
                  transition: "width 0.4s ease, opacity 0.4s ease",
                  // pointerEvents: isMobileView === false ? "none" : "auto",
                }}
              ></div>
            )}
          </div>
          <div className="internal_NextAndPrevIcon">
            <div
              className="internal_CarouPrevIcon"
              style={{
                cursor:
                  currentDot === 3 || currentDot === 2 ? "pointer" : "default",
              }}
              onClick={disablePrev ? null : beneTxnPrev}
            >
              <img
                src={PrevIcon}
                alt=""
                style={{
                  opacity: currentDot === 3 || currentDot === 2 ? "1" : "0.5",
                  transition: "opacity 0.3s ease",
                }}
              />
            </div>
            <div
              className="internal_CarouNextIcon"
              style={{
                cursor:
                  (currentDot === 2 && ThirdFiveDataCards?.length > 0) ||
                  (hasNonZeroDistribution && currentDot === 2)
                    ? "default"
                    : currentDot === 1 || currentDot === 2
                    ? "pointer"
                    : "default",
              }}
              onClick={
                disableNext ||
                (currentDot === 2 && ThirdFiveDataCards?.length === 0)
                  ? null
                  : beneTxnNext
              }
            >
              <img
                src={NextIcon}
                alt=""
                style={{
                  opacity:
                    (currentDot === 2 && ThirdFiveDataCards?.length === 0) ||
                    (hasNonZeroDistribution && currentDot === 2)
                      ? "0.5"
                      : currentDot === 1 || currentDot === 2
                      ? "1"
                      : "0.5",
                  transition: "opacity 0.3s ease",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`internalTxn-allSection`}
        style={{
          height: windowWidth <= 800 ? "100%" : "calc(100vh - 295px)",
        }}
      >
        <div className="internalTxn-section">
          {internalTxnViewAllAccLoading ? (
            <>
              {" "}
              <div
                className="loader-container expentraloaderwithDashBoard"
                // id="beneTnxXpentraLoaderWeb"
                style={{
                  width: internalTxnViewAllAccLoading && "67%",
                  height: "100%",
                }}
              >
                <Loader />
              </div>
            </>
          ) : (
            <div className="internalTxn-content">
              {internalTxnViewAllAccData?.transactions?.length > 0 && (
                <div className="internalTxn_RecentPage">
                  <div className="internal_RecentText">Recent</div>
                  <div
                    className="internal_ViewallPage hover:bg-custom-cardHover"
                    onClick={() =>
                      navigate("/transaction/internal_transaction/view_all", {
                        state: { approvalStatus: "all" },
                      })
                    }
                  >
                    <p>View All</p>
                    <img src={arrowright} alt="" />
                  </div>
                </div>
              )}
              <div className="internal_GridLaySection">
                {dashboardData?.length > 0 ? (
                  <div
                    className="internal-GridContainer customJustifyStart"
                    style={{ position: "relative" }}
                  >
                    {dashboardData?.map((itemcard) => {
                      return (
                        <InternalTxnDataCard
                          amount={itemcard?.amount}
                          internal_account_ifsc_code={
                            itemcard?.internal_account_ifsc_code
                          }
                          owner_account_ifsc_code={
                            itemcard?.owner_account_ifsc_code
                          }
                          benAccNo={itemcard?.internal_account_bank_acc_no}
                          ownerAccNo={itemcard?.owner_account_bank_acc_no}
                          confidentialTransaction={
                            itemcard?.confidential_transaction
                          }
                          createdOn={itemcard?.created_on}
                          transactionId={itemcard?.txn_id}
                          windowWidth={windowWidth}
                          hasConfidential={hasConfidential}
                        />
                      );
                    })}
                  </div>
                ) : (
                  <div className="w-full flex justify-center items-center">
                    <div className="beneTxnDashNotFound">
                      <img width={"100%"} src={NoDataImg} alt="NoDataImg" />

                      <Typography
                        sx={{
                          color: "var(--Primary_white)",
                          textAlign: "center",
                        }}
                      >
                        No transactions found <br />
                        All internal transactions will appear here.
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            className="internalTxn-rightSection"
            style={{
              width: windowWidth <= 800 ? "100%" : "32%",
            }}
          >
            <Box
              sx={{
                width: "95%",
                cursor: "default",
                //   marginLeft: "22px",
                marginTop: "30px",
                opacity: 1,
                height: "65px",
                borderRadius: "22px",
                justifyContent: "center",
                bgcolor: "var(--Blue_6)",
                display: windowWidth <= 800 && "none",
              }}
            >
              <div
                className={`internal-createAc ${
                  services?.includes("CInternalTxn") ||
                  services?.includes("Admin")
                    ? "hover:bg-custom-cardHover"
                    : ""
                }`}
                style={{
                  opacity:
                    services?.includes("CInternalTxn") ||
                    services?.includes("Admin")
                      ? "1"
                      : "0.5",
                  cursor:
                    services?.includes("CInternalTxn") ||
                    services?.includes("Admin")
                      ? "pointer"
                      : "default",
                  borderRadius: "22px",

                  // pointerEvents:
                  //   services?.includes("CInternalTxn") ||
                  //   services?.includes("Admin")
                  //     ? "auto"
                  //     : "none",
                }}
                onClick={() => {
                  if (
                    services?.find((service) => service === "CInternalTxn") !==
                    undefined
                  ) {
                    dispatch(resetViewAllAcc());
                    navigate("/transaction/internal_transaction/create");
                  } else {
                    navigate("/feature_not_assigned", {
                      state: {
                        unavailableService: "CInternalTxn",
                      },
                    });
                  }
                  // toast.info("Feature coming soon...", toast_position);
                }}
              >
                <img src={addbutton} alt="img add" />
                <p className="internalTxn_CreateTxn">Create transaction</p>
              </div>
            </Box>
            <div
              className="internalTxn-right-middleSection"
              style={{
                opacity: summaryData?.length === 0 && "0.4",
                display: windowWidth <= 800 && "none",
              }}
            >
              <h5>Auto Transfer Matrix</h5>
              <p>
                Auto transfer allows you to schedule internal transactions for a
                single time or set it up to repeat at your chosen frequency.
              </p>
              <button
                style={{
                  cursor: summaryData?.length === 0 && "default",
                }}
                disabled={summaryData?.length === 0 && true}
                onClick={() => {
                  toast.info("Feature coming soon...", toast_position);
                }}
              >
                View Matrix
              </button>
            </div>

            {/*  */}
            <div
              className="internalTxn-quickActionSec"
              style={{
                opacity: summaryData?.length === 0 && "0.4",
              }}
            >
              <div className="internalTxn_LeftBenfContent">
                <p>Quick actions</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "65px",
                  width: "100%",
                }}
              >
                <div className="internalTxn_RightBenfContent">
                  <div className="internalTxn_leftSidecontent">
                    <img src={bill} alt="" />
                    <p>Send MIS report</p>
                  </div>
                  <div
                    style={{
                      cursor: summaryData?.length === 0 ? "default" : "pointer",
                      pointerEvents:
                        summaryData?.length === 0 ? "none" : "auto",
                    }}
                    className="internalTxn_RightSideContent"
                    onClick={() => {
                      toast.info("Feature coming soon...", toast_position);
                    }}
                  >
                    <img src={Arrow_Right} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalTxnDashboard;
