import React, { useEffect, useRef, useState } from "react";
import close_with_blue_square from "../../assets/CloseIcons/close_with_blue_square.svg";
import search_white from "../../assets/FilterSearchIcons/search_white.svg";
import close_with_white_circle from "../../assets/CloseIcons/close_with_white_circle.svg";
import arrow_down_white_filled from "../../assets/ArrowsIcons/arrow_down_white_filled.svg";
import not_selected_with_grey_circle from "../../assets/CheckmarksIcons/not_selected_with_grey_circle.svg";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import Selected from "../../assets/CheckmarksIcons/selected_with_green_circle.svg";
import { checkUsertoken } from "../../redux/features/login/login";
import { ownerViewAllAcc } from "../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  AddUserTag,
  getUsers,
  RemoveUserTag,
} from "../../redux/features/Settings/Management/Users";
import { formatAccountNumber } from "../_utils/FormatAccountNumber";
import rejected_white from "../../assets/CloseIcons/rejected_white.svg";
import notags from "../../assets/GeneralIcons/No assigned tags found.svg";
import notagsuser from "../../assets/GeneralIcons/No unassigned tags found user.svg";
import dataloading from "../../assets/LoaderIcons/Loader.gif";
import "./index.css";
import CheckIcon from "../../assets/CheckmarksIcons/check_mark_white.svg";
import clearFilter from "../../assets/CloseIcons/close_with_white_circle.svg";
import arrow_left_white from "../../assets/ArrowsIcons/arrow_left_white.svg";
import { drop, filter, set } from "lodash";
import { json } from "react-router-dom";

function UserTag({
  onClose,
  handleAddTags,

  handleRemoveTags,
  newTags,
  setNewTags,

  AllTags,
  setAllTags,

  setLoading,
  users,
  id,
  ownerDetailData,
  type,
  loading,
  selectedAssignedAccountID,
  setSelectedAssignedAccountID,
  selectedTagsCards,
  selectedAccount,
  setSelectedAccount,
}) {
  const dropdownRef = useRef(null);
  const tagInputRef = useRef("");
  const [tagSearchName, setTagSearchName] = useState("");
  const [message, setMessage] = useState(false);
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const refreshData = useSelector((state) => state.refreshToken.data);
  const [isNewTagsOpaque, setIsNewTagsOpaque] = useState(false);
  const [isAllTagsOpaque, setIsAllTagsOpaque] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const services = useSelector((state) => state.refreshToken.services);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [activeStep, setactiveStep] = useState(0);

  // console.log("jjjjj");
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const handleDropDown = (e) => {
      if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
      } else {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("click", handleDropDown);
    document.addEventListener("mousedown", handleDropDown);
  }, [dropdownRef]);

  const handleAccountSelect = (account, e) => {
    setSelectedAccount(
      e.target.textContent === "All selected accounts"
        ? "All selected accounts"
        : account
    );
    setIsDropdownOpen(false);
    let selectedOwnerId;
    if (users) {
      selectedOwnerId = ownerDetailData?.find(
        (owner) => owner?.id === account
      )?.id;
    } else {
      selectedOwnerId = ownerDetailData?.find(
        (owner) => owner?.bank_acc_no === account
      )?.id;
    }
    setSelectedAssignedAccountID(
      e.target.textContent === "All selected accounts" ||
        e.target.textContent === "All selected users"
        ? ""
        : selectedOwnerId
    );
    // onSelectAccount(account);
  };

  const toggleDetails = () => {
    setactiveStep(0);
  };

  const toggleDetails1 = () => {
    setactiveStep(1);
  };

  const newTagsOpacity = isNewTagsOpaque ? "opacity-30" : "opacity-100";
  const allTagsOpacity = isAllTagsOpaque ? "opacity-30" : "opacity-100";

  const result = newTags?.filter((tag) => {
    return tag?.value?.toLowerCase() === tagSearchName?.toLowerCase();
  });

  const result1 = AllTags?.filter((tag) => {
    return tag?.value?.toLowerCase() === tagSearchName?.toLowerCase();
  });

  const InputChange = (e) => {
    const valueChange = e.target.value;
    const limitedValue = valueChange.substring(0, 30);
    // const processChange = limitedValue
    //   .replace(/^\s+/, "")
    //   .replace(/\s{2,}/g, " ")
    //   .toLowerCase();

    const processChange = limitedValue.replace(/\s+/g, "").toLowerCase();
    setTagSearchName(processChange);

    const filteredNewTags = newTags?.filter((tag) =>
      tag?.value?.toLowerCase().includes(processChange)
    );

    const filteredAllTags = AllTags?.filter((tag) =>
      tag?.value?.toLowerCase().includes(processChange)
    );

    if (
      filteredNewTags?.length === 0 &&
      filteredAllTags?.length === 0 &&
      processChange.length > 0
    ) {
      setMessage(true);
    } else {
      setMessage(false);
    }
  };

  const handleClose = () => {
    if (type == "userViewAll") {
      setSelectedAccount("All selected accounts");
      setSelectedAssignedAccountID("");
    }

    setNewTags((prev) => {
      return [...prev].map((result) => {
        return { selected: true, value: result?.value, type: result?.type };
      });
    });
    onClose();
  };

  const filteredExistingTags = newTags?.filter((tag) => {
    return (
      (tag?.type === "existingTag" || tag?.type === "ownerTags") &&
      tag?.value?.toLowerCase().includes(tagSearchName?.toLowerCase())
    );
  });

  const filteredExistingTags1 = AllTags?.filter((tag) => {
    return (
      (tag?.type === "Available" || tag?.type === "ownerTags") &&
      tag?.value?.toLowerCase().includes(tagSearchName?.toLowerCase()) &&
      !filteredExistingTags.some(
        (assignedTag) => assignedTag.value === tag.value
      )
    );
  });

  const clearField = () => {
    setTagSearchName("");
  };

  const handleExistingTag = (selectedTag) => {
    const updatedTags = newTags?.map((tag) => {
      if (tag.value === selectedTag && tag.type !== "newTag") {
        return { ...tag, selected: !tag.selected };
      } else {
        return tag;
      }
    });

    setNewTags(updatedTags);
  };

  const handleExistingTag1 = (selectedTag) => {
    const updatedTags = AllTags.map((tag, id) => {
      if (tag?.value === selectedTag) {
        return { ...tag, selected: !tag.selected };
      } else {
        return tag;
      }
    });
    setAllTags(updatedTags);
    setIsAllTagsOpaque(!isAllTagsOpaque);
    setIsNewTagsOpaque(!isNewTagsOpaque);
  };

  const handleNewTag = (item) => {
    const tagExists = newTags?.some(
      (tag) => tag?.value?.toLowerCase() === tagSearchName?.toLowerCase()
    );

    if (tagExists) {
    } else {
      handleAddTags(item);
      setTagSearchName("");
    }
  };

  const handleSelectandDeselect = (selectedTag) => {
    const updatedTags = newTags?.reduce((acc, res) => {
      if (res.value === selectedTag && res.type === "newTag") {
        return [...acc, { ...res, selected: true }];
      } else {
        return [...acc, res];
      }
    }, []);

    setNewTags(updatedTags);
  };

  const HandleUserAvailTags = async (item) => {
    // setLoading(true);
    try {
      dispatch(checkUsertoken());

      let response = await dispatch(
        AddUserTag({
          user_id: refreshData?.employees?.id,
          account_tags: item,
        })
      );

      if (response?.payload?.status === true) {
        HandleAllTags();

        setAllTags((prev) => [...prev, response?.meta?.arg?.account_tags]);
      } else {
        setLoading(false);
        toast.error(response?.payload?.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (users) {
      handleuserAll();
    } else {
      HandleAllTags();
    }
  }, []);

  const handleuserAll = async () => {
    setLoading(true);

    try {
      dispatch(checkUsertoken());
      let response = await dispatch(getUsers({ page_no: 1, page_size: 50 }));
      if (response?.payload?.status === true) {
        // setAllTags(response?.payload?.summary?.available_tags);
        // getOwnerDetaildata();

        const data = response?.payload?.available_tags?.map((res) => {
          return { selected: true, value: res, type: "Available" };
        });
        if (type !== "userViewAll" || type !== "ownerViewAll") {
          setAllTags(data);
        }
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response?.payload?.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const HandleAllTags = async () => {
    // setLoading(true);

    try {
      dispatch(checkUsertoken());
      let response = await dispatch(
        ownerViewAllAcc({ page_no: 1, page_size: 50 })
      );

      if (response?.payload?.status === true) {
        // setAllTags(response?.payload?.summary?.available_tags);
        // getOwnerDetaildata();

        const data = response?.payload?.summary?.available_tags?.map((res) => {
          return { selected: true, value: res, type: "Available" };
        });

        if (type !== "ownerViewAll") {
          setAllTags(data);
        }
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response?.payload?.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleUnassign = async () => {
    const deselectedTags = newTags.filter((tag) => tag.selected);
    // const de5selectedTags = newTags.filter((tag) => tag.selected);

    const remainingTags = newTags.filter((tag) => !tag.selected);
    const data = remainingTags.map((tag) => {
      return tag.value;
    });

    await handleRemoveTags(data);
    if (!users) {
      await HandleAllTags();
    }

    // setLoading(true);
  };

  const handleAssign = () => {
    const selectedTags = AllTags?.filter((tag) => tag.selected);
    const remainingTags = AllTags?.filter((tag) => !tag.selected);

    const data1 = remainingTags?.map((tag) => {
      return tag.value;
    });

    handleAddTags({ data1, type: "newAss" });

    setAllTags(selectedTags);
  };

  const isAnyTagDeselected = newTags?.some((tag) => tag.selected === false);
  const isAnyTagDeselectedUnassign = AllTags?.some(
    (tag) => tag.selected === false
  );

  const handleKeyPress = (e) => {
    if (users && !services?.includes("CTag")) {
      return;
    } else if (!users && !services?.includes("COwnerTag")) {
      return;
    } else {
      if (e.key === "Enter") {
        const inputValue = e.target.value;
        if (inputValue.trim()) {
          handleNewTag(inputValue);
        }
      }
    }
  };

  return (
    <>
      <div
        className="flex  w-full h-full justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999] overflow-y-auto overflow-x-hidden"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div
          className={` flex flex-col relative bg-[#1D3A6D]  ${
            isMobile
              ? " w-[100%] h-[100%] overflow-y-auto "
              : "w-[1024px] h-auto rounded-[20px] "
          }  `}
          style={{ scrollbarWidth: isMobile ? "none" : "" }}
          onClick={(e) => e.stopPropagation()}
        >
          {isMobile && (
            <>
              <div className=" h-[56px] fixed z-[9999999] w-[100%] bg-[#3A5686]">
                <div className=" flex">
                  <div className=" p-[20px]" onClick={handleClose}>
                    <img
                      src={arrow_left_white}
                      className=" w-[16px] h-[20.36px] cursor-pointer"
                    />
                  </div>
                  <div className=" flex flex-col items-center justify-center ">
                    {/* <p className="font-sans font-[400px] text-[16px] text-[#f9f9f9]">
                        {users
                          ? users
                          : formatAccountNumber(
                              Array.isArray(ownerDetailData)
                                ? ownerDetailData[0]?.bank_acc_no
                                : ownerDetailData?.bank_acc_no
                            )}
                      </p> */}
                    <p className="font-sans  flex items-center text-[14px] text-[#f9f9f9]">
                      Assign / Unassign Tags
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className={` ${isMobile ? "h-[100%] mt-[56px]" : ""}`}>
            {isMobile ? (
              <>
                {/* --------------------------------------------------------------------------------------------- */}

                <div className=" w-full relative h-[45px] mt-[20px]   bg-[#667CA3] mr-[20px] ">
                  <div
                    ref={dropdownRef}
                    className=" w-full  flex items-center bg-[#667CA3] rounded-[10px] top-0 left-0 "
                    style={{
                      zIndex: "99999",
                      height: isDropdownOpen ? "" : "45px",
                      position: isDropdownOpen ? "absolute" : "relative",
                      borderRadius:
                        (type === "ownerViewAll" || type == "userViewAll") &&
                        isDropdownOpen
                          ? "10px"
                          : "10px",
                    }}
                  >
                    {type === "ownerDetails" || ownerDetailData?.length == 1 ? (
                      <>
                        <div className=" w-full opacity-50 flex items-center px-[10px]">
                          <p className="font-sans font-[400px] text-[14px] ml-[10px] w-full text-[#f9f9f9] cursor-default ">
                            {users
                              ? users
                              : formatAccountNumber(
                                  Array.isArray(ownerDetailData)
                                    ? ownerDetailData[0]?.bank_acc_no
                                    : ownerDetailData?.bank_acc_no
                                )}
                          </p>
                          <img
                            src={arrow_down_white_filled}
                            className=" w-[16px] h-[16px] mr-[10px]"
                          />
                          {/* <div className=" border-r-[1px] h-[16px] mx-[10px] border-[#102C5D]"></div>
                          <img
                            src={arrow_down_white_filled}
                            className="  w-[9px] h-[9px]  "
                          /> */}
                        </div>
                      </>
                    ) : (type === "ownerViewAll" || type == "userViewAll") &&
                      ownerDetailData?.length > 1 ? (
                      <div
                        ref={dropdownRef}
                        style={{
                          position: "relative",
                          width: "100%",
                          // border: isDropdownOpen ? " 1px solid red" : "",
                        }}
                      >
                        <div
                          className="dropdown-div-responsive"
                          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          style={{
                            border:
                              (type === "ownerViewAll" ||
                                type === "userViewAll") &&
                              isDropdownOpen
                                ? "1px solid #FF5860"
                                : "",
                            width:
                              selectedAssignedAccountID !== "" &&
                              !isDropdownOpen
                                ? "96%"
                                : "100%",
                          }}
                        >
                          <Tooltip
                            componentsProps={{
                              popper: {
                                placement: "bottom",
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  zIndex: "2147483647 !important",
                                },
                              },
                            }}
                            title={selectedAccount}
                          >
                            <p className="font-sans font-[400px] text-[14px] w-full truncate text-[#f9f9f9]">
                              {isDropdownOpen
                                ? "Select type"
                                : selectedAccount !== "All selected accounts"
                                ? selectedAccount
                                : users
                                ? "All selected users"
                                : "All selected accounts"}
                            </p>
                          </Tooltip>
                          {!isDropdownOpen &&
                          selectedAccount !== "" &&
                          selectedAssignedAccountID !== "" ? (
                            <div
                              className="dropdown-img-div flex-row"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={rejected_white}
                                alt="close"
                                className=" ml-[18px]  w-[16px] h-[16px] cursor-pointer "
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsDropdownOpen(false);
                                  setSelectedAssignedAccountID("");
                                  setSelectedAccount("All selected accounts");
                                }}
                              />
                              <div className=" border-r-[1px] h-[16px] mx-[10px] border-[#102C5D]"></div>
                              <img
                                src={arrow_down_white_filled}
                                className="  w-[16px] h-[16px]  "
                              />
                            </div>
                          ) : (
                            <img
                              style={{
                                transform: isDropdownOpen
                                  ? "rotate(180deg)"
                                  : "",
                              }}
                              src={arrow_down_white_filled}
                              className="  w-[16px] h-[16px] mr-[10px] cursor-pointer "
                            />
                          )}
                        </div>
                        {isDropdownOpen && (
                          <div className="dropdown-content-responsive">
                            <div
                              className="dropdown-item"
                              onClick={(e) =>
                                handleAccountSelect("All selected accounts", e)
                              }
                              style={{
                                backgroundColor:
                                  selectedAccount === "All selected accounts"
                                    ? "#3A5686"
                                    : "",
                                borderRadius:
                                  selectedAccount === "All selected accounts"
                                    ? "5px"
                                    : "",
                              }}
                            >
                              {users ? (
                                <p>All selected users</p>
                              ) : (
                                <p>All selected accounts</p>
                              )}

                              {selectedAccount === "All selected accounts" && (
                                <img src={CheckIcon} alt="check mark" />
                              )}
                            </div>
                            {users ? (
                              <div>
                                {ownerDetailData.map((account, index) => (
                                  <div
                                    key={index}
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      handleAccountSelect(
                                        account.first_name +
                                          " " +
                                          account.last_name,
                                        e
                                      );
                                    }}
                                    style={{
                                      backgroundColor:
                                        selectedAccount ===
                                        account.first_name +
                                          " " +
                                          account.last_name
                                          ? "#3A5686"
                                          : "",
                                      borderRadius:
                                        selectedAccount ===
                                        account.first_name +
                                          " " +
                                          account.last_name
                                          ? "5px"
                                          : "",
                                    }}
                                  >
                                    <p className="truncate">
                                      <Tooltip
                                        componentsProps={{
                                          popper: {
                                            placement: "bottom",
                                            sx: {
                                              [`& .${tooltipClasses.tooltip}`]:
                                                {
                                                  backgroundColor: (theme) =>
                                                    theme.palette.common.black,
                                                },
                                              zIndex: "2147483647 !important",
                                            },
                                          },
                                        }}
                                        title={
                                          account.first_name +
                                          " " +
                                          account.last_name
                                        }
                                      >
                                        {account.first_name +
                                          " " +
                                          account.last_name}
                                      </Tooltip>
                                    </p>
                                    {selectedAccount ===
                                      account.first_name +
                                        " " +
                                        account.last_name && (
                                      <img src={CheckIcon} alt="check mark" />
                                    )}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div>
                                {ownerDetailData.map((account, index) => (
                                  <div
                                    key={index}
                                    className="dropdown-item"
                                    onClick={(e) =>
                                      handleAccountSelect(
                                        account.bank_acc_no,
                                        e
                                      )
                                    }
                                    style={{
                                      backgroundColor:
                                        selectedAccount === account.bank_acc_no
                                          ? "#3A5686"
                                          : "",
                                      borderRadius:
                                        selectedAccount === account.bank_acc_no
                                          ? "5px"
                                          : "",
                                    }}
                                  >
                                    <p>{account.bank_acc_no}</p>
                                    {selectedAccount ===
                                      account.bank_acc_no && (
                                      <img src={CheckIcon} alt="check mark" />
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <>
                        <p className="font-sans font-[400px] text-[12px] w-[174px] text-[#f9f9f9]">
                          All selected users
                        </p>
                        <img
                          src={arrow_down_white_filled}
                          className=" ml-[13px] w-[9px] h-[9px] cursor-pointer "
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="bg-[#667CA3] h-[49px] flex items-center w-full  pl-[20px] py-[18px]  mt-[20px]">
                  <img src={search_white} className="w-[14px] h-[14.38px] " />
                  <input
                    ref={tagInputRef}
                    placeholder={
                      users && !services?.includes("CTag")
                        ? "Search tag"
                        : "Search / Enter new tag"
                    }
                    className="  text-[#dadada] h-[19px] ml-[10px] bg-[#667CA3] font-sans  text-[16px] font-medium outline-none  w-full "
                    onClick={() => tagInputRef.current.focus()}
                    onChange={InputChange}
                    onKeyDown={handleKeyPress}
                    value={tagSearchName}
                  />
                </div>
                <div className="flex">
                  <div
                    className={`w-1/2  flex items-center justify-center cursor-pointer  ${
                      // result?.length === 0 &&
                      // result1?.length === 0 &&
                      tagSearchName !== ""
                        ? " cursor-pointer  "
                        : " cursor-default "
                    } bg-[#314D7E] h-[40px]`}
                    onClick={clearField}
                  >
                    <div></div>
                    <div
                      className={` ${
                        tagSearchName !== "" ? "  opacity-100  " : " opacity-30"
                      } `}
                    >
                      <img
                        src={close_with_white_circle}
                        className="w-[14px] h-[14.38px]   "
                      />
                    </div>
                    <div
                      className={`ml-[4px] font-sans} ${
                        tagSearchName !== ""
                          ? " text-[#f9f9f9] opacity-100  text-[14px]  font-medium "
                          : " text-[#f9f9f9]  text-[14px] opacity-30"
                      }    `}
                    >
                      Clear
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      if (
                        !(
                          result?.length === 0 &&
                          result1?.length === 0 &&
                          tagSearchName !== ""
                        )
                      ) {
                        return;
                      }
                      handleNewTag(tagSearchName);
                    }}
                    className={` h-[40px] w-1/2 flex items-center justify-center ${
                      result?.length === 0 &&
                      result1?.length === 0 &&
                      tagSearchName !== ""
                        ? "bg-[#506994] cursor-pointer hover:opacity-60 "
                        : "bg-[#707070] cursor-default"
                    } `}
                  >
                    <div>
                      <p
                        className={` font-sans font-[400px] ${
                          isMobile ? "text-[14px]" : "text-[10px] "
                        }  text-[#f9f9f9]`}
                      >
                        {" "}
                        Assign new tag
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex h-[39px] mt-[20px]">
                  <button
                    className={`w-1/2 flex items-center justify-center ${
                      activeStep === 0
                        ? "bg-white text-[#314D7E] font-semibold"
                        : "bg-[#314D7E] text-[#dadada] font-semibold"
                    }`}
                    onClick={toggleDetails}
                  >
                    Assigned
                  </button>
                  <button
                    className={`w-1/2 flex items-center justify-center ${
                      activeStep === 1
                        ? "bg-white text-[#314D7E] font-semibold"
                        : "bg-[#314D7E] text-[#dadada] font-semibold"
                    }`}
                    onClick={toggleDetails1}
                  >
                    Unassigned
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className=" flex w-full pt-[20px] px-[20px] ">
                  <div className=" w-full">
                    <p className=" font-sans font-semibold text-[16px] text-[#dadada]">
                      Assign/ Unassign tags:
                    </p>

                    <p className="font-sans font-[400px] text-[12px] mt-[2px] text-[#f9f9f9]">
                      {users
                        ? "Tagged users will gain access to view only owner accounts that share the same tags."
                        : "Tagged owner accounts will be visible to the users who share   the same tags."}
                    </p>
                  </div>
                  <div onClick={handleClose}>
                    <img
                      src={close_with_blue_square}
                      className=" w-[30px] h-[30px] cursor-pointer rounded-[10px] hover:bg-[#667CA3] hover:opacity-60 hover:w-[30px] hover:h-[30px]"
                    />
                  </div>
                </div>
                <div className=" flex w-full px-[20px] pb-[20px]  mt-[20px]">
                  <div className=" w-[216px] relative h-[36px]  bg-[#667CA3] mr-[20px] rounded-[10px]">
                    <div
                      ref={dropdownRef}
                      className=" w-full  flex items-center bg-[#667CA3] rounded-[10px] top-0 left-0 "
                      style={{
                        zIndex: "9999999",
                        height: isDropdownOpen ? "134px" : "36px",
                        position: isDropdownOpen ? "absolute" : "relative",
                        borderRadius:
                          (type === "ownerViewAll" || type == "userViewAll") &&
                          isDropdownOpen
                            ? "10px"
                            : "10px",

                        border:
                          (type === "ownerViewAll" || type === "userViewAll") &&
                          isDropdownOpen
                            ? "1px solid #FF5860"
                            : "",
                      }}
                    >
                      {type === "ownerDetails" ||
                      ownerDetailData?.length == 1 ? (
                        <>
                          <div className=" opacity-50 flex items-center px-[10px]">
                            <p className="font-sans font-[400px] text-[12px] w-[174px] text-[#f9f9f9] cursor-default ">
                              {users
                                ? users
                                : formatAccountNumber(
                                    Array.isArray(ownerDetailData)
                                      ? ownerDetailData[0]?.bank_acc_no
                                      : ownerDetailData?.bank_acc_no
                                  )}
                            </p>
                            <img
                              src={rejected_white}
                              className=" w-[9px] h-[9px]"
                            />
                            <div className=" border-r-[1px] h-[16px] mx-[10px] border-[#102C5D]"></div>
                            <img
                              src={arrow_down_white_filled}
                              className="  w-[9px] h-[9px]  "
                            />
                          </div>
                        </>
                      ) : (type === "ownerViewAll" || type == "userViewAll") &&
                        ownerDetailData?.length > 1 ? (
                        <div
                          ref={dropdownRef}
                          style={{
                            position: "relative",
                          }}
                        >
                          <div
                            className="dropdown-div"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            style={{
                              width:
                                selectedAssignedAccountID !== "" &&
                                !isDropdownOpen
                                  ? "82%"
                                  : "100%",
                            }}
                          >
                            {users ? (
                              <>
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      placement: "bottom",
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                        zIndex: "2147483647 !important",
                                      },
                                    },
                                  }}
                                  title={
                                    ownerDetailData?.find(
                                      (owner) => owner?.id === selectedAccount
                                    )?.first_name
                                      ? ownerDetailData?.find(
                                          (owner) =>
                                            owner?.id === selectedAccount
                                        )?.first_name +
                                        " " +
                                        ownerDetailData?.find(
                                          (owner) =>
                                            owner?.id === selectedAccount
                                        )?.last_name
                                      : "All selected users"
                                  }
                                >
                                  <p className="font-sans font-[400px] text-[12px] w-[174px] px-[10px] truncate text-[#f9f9f9]">
                                    {isDropdownOpen
                                      ? "Select type"
                                      : selectedAccount !==
                                        "All selected accounts"
                                      ? ownerDetailData?.find(
                                          (owner) =>
                                            owner?.id === selectedAccount
                                        )?.first_name +
                                        " " +
                                        ownerDetailData?.find(
                                          (owner) =>
                                            owner?.id === selectedAccount
                                        )?.last_name
                                      : users
                                      ? "All selected users"
                                      : "All selected accounts"}
                                  </p>
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      placement: "bottom",
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                        zIndex: "2147483647 !important",
                                      },
                                    },
                                  }}
                                  title={selectedAccount}
                                >
                                  <p className="font-sans font-[400px] text-[12px] w-[174px] truncate text-[#f9f9f9]">
                                    {isDropdownOpen
                                      ? "Select type"
                                      : selectedAccount !==
                                        "All selected accounts"
                                      ? selectedAccount
                                      : users
                                      ? "All selected users"
                                      : "All selected accounts"}
                                  </p>
                                </Tooltip>
                              </>
                            )}
                            {!isDropdownOpen &&
                            selectedAccount !== "" &&
                            selectedAssignedAccountID !== "" ? (
                              <div
                                className="dropdown-img-div flex-row"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={rejected_white}
                                  alt="close"
                                  className=" ml-[18px]  w-[9px] h-[9px] cursor-pointer "
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setIsDropdownOpen(false);
                                    setSelectedAssignedAccountID("");
                                    setSelectedAccount("All selected accounts");
                                  }}
                                />
                                <div className=" border-r-[1px] h-[16px] mx-[10px] border-[#102C5D]"></div>
                                <img
                                  src={arrow_down_white_filled}
                                  className="  w-[9px] h-[9px]  "
                                />
                              </div>
                            ) : (
                              <img
                                style={{
                                  transform: isDropdownOpen
                                    ? "rotate(180deg)"
                                    : "",
                                }}
                                src={arrow_down_white_filled}
                                className=" ml-[13px] w-[9px] h-[9px] cursor-pointer "
                              />
                            )}
                          </div>
                          {isDropdownOpen && (
                            <div className="dropdown-content">
                              <div
                                className={`dropdown-item1 ${
                                  selectedAccount === "All selected accounts" &&
                                  "bg-[#3A5686] hover:bg-[#2E456B]"
                                } hover:bg-[#8596B5] rounded-[5px]`}
                                onClick={(e) =>
                                  handleAccountSelect(
                                    "All selected accounts",
                                    e
                                  )
                                }
                                style={{
                                  // backgroundColor:
                                  //   selectedAccount === "All selected accounts"
                                  //     ? "#3A5686"
                                  //     : "",
                                  borderRadius:
                                    selectedAccount === "All selected accounts"
                                      ? "5px"
                                      : "",
                                }}
                              >
                                {users ? (
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        placement: "bottom",
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                          zIndex: "2147483647 !important",
                                        },
                                      },
                                    }}
                                    title={"All selected users"}
                                  >
                                    <p>All selected users</p>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        placement: "bottom",
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                          zIndex: "2147483647 !important",
                                        },
                                      },
                                    }}
                                    title={"All selected accounts"}
                                  >
                                    <p>All selected accounts</p>
                                  </Tooltip>
                                )}

                                {selectedAccount ===
                                  "All selected accounts" && (
                                  <img src={CheckIcon} alt="check mark" />
                                )}
                              </div>
                              {users ? (
                                <div>
                                  {ownerDetailData.map((account, index) => (
                                    <div
                                      key={index}
                                      className={`dropdown-item ${
                                        selectedAccount === account.id &&
                                        "bg-[#3A5686] hover:bg-[#2E456B]"
                                      } hover:bg-[#8596B5]`}
                                      onClick={(e) => {
                                        handleAccountSelect(account.id, e);
                                      }}
                                      style={{
                                        // backgroundColor:
                                        //   selectedAccount === account.id
                                        //     ? "#3A5686"
                                        //     : "",
                                        borderRadius:
                                          selectedAccount === account.id
                                            ? "5px"
                                            : "",
                                      }}
                                    >
                                      <p className="truncate">
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              placement: "bottom",
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                                zIndex: "2147483647 !important",
                                              },
                                            },
                                          }}
                                          title={
                                            account.first_name +
                                            " " +
                                            account.last_name
                                          }
                                        >
                                          {account.first_name +
                                            " " +
                                            account.last_name}
                                        </Tooltip>
                                      </p>
                                      {selectedAccount === account.id && (
                                        <img src={CheckIcon} alt="check mark" />
                                      )}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div>
                                  {ownerDetailData.map((account, index) => (
                                    <div
                                      key={index}
                                      className={`dropdown-item ${
                                        selectedAccount ===
                                          account.bank_acc_no &&
                                        "bg-[#3A5686] hover:bg-[#2E456B]"
                                      } hover:bg-[#8596B5]`}
                                      // className="dropdown-item"
                                      onClick={(e) =>
                                        handleAccountSelect(
                                          account.bank_acc_no,
                                          e
                                        )
                                      }
                                      style={{
                                        // backgroundColor:
                                        //   selectedAccount ===
                                        //   account.bank_acc_no
                                        //     ? "#3A5686"
                                        //     : "",
                                        borderRadius:
                                          selectedAccount ===
                                          account.bank_acc_no
                                            ? "5px"
                                            : "",
                                      }}
                                    >
                                      <p>{account.bank_acc_no}</p>
                                      {selectedAccount ===
                                        account.bank_acc_no && (
                                        <img src={CheckIcon} alt="check mark" />
                                      )}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <p className="font-sans font-[400px] text-[12px] w-[174px] text-[#f9f9f9]">
                            All selected users
                          </p>
                          <img
                            src={arrow_down_white_filled}
                            className=" ml-[13px] w-[9px] h-[9px] cursor-pointer "
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className=" w-[741px] h-[36px] flex items-center rounded-[10px] pl-[10px] py-[5.29px] bg-[#314D7E]">
                    <div
                      className={`${
                        users && !services?.includes("CTag")
                          ? "w-[718px]"
                          : !users && !services?.includes("COwnerTag")
                          ? "w-[718px]"
                          : "w-[622px]"
                      } flex items-center`}
                    >
                      <img
                        src={search_white}
                        className="w-[14px] h-[14.38px] "
                      />
                      <input
                        ref={tagInputRef}
                        placeholder={
                          users && !services?.includes("CTag")
                            ? "Search tag"
                            : !users && !services?.includes("COwnerTag")
                            ? "Search tag"
                            : "Search / Enter new tag"
                        }
                        className=" bg-[#314D7E] text-[#dadada] text-[12px] font-[400px] outline-none ml-[10px] w-full "
                        onClick={() => tagInputRef.current.focus()}
                        onChange={InputChange}
                        onKeyDown={handleKeyPress}
                        value={tagSearchName}
                      />
                      <img
                        src={close_with_white_circle}
                        className="w-[14px] h-[14.38px]  cursor-pointer  "
                        onClick={clearField}
                      />
                    </div>
                    <div
                      style={{
                        display:
                          users && !services?.includes("CTag")
                            ? "none"
                            : !users && !services?.includes("COwnerTag")
                            ? "none"
                            : "flex",
                      }}
                      className=" border-r-[1px] border-[#1D3A6D] h-[25.41px] mx-[8px]"
                    ></div>

                    <div
                      style={{
                        display:
                          users && !services?.includes("CTag")
                            ? "none"
                            : !users && !services?.includes("COwnerTag")
                            ? "none"
                            : "flex",
                      }}
                      className={`w-[86px] h-[25.41px]  flex items-center pl-[9px] py-[5.29px] rounded-[5px] ${
                        result?.length === 0 &&
                        result1?.length === 0 &&
                        tagSearchName !== ""
                          ? "bg-[#667ca3] cursor-pointer hover:opacity-60 "
                          : "bg-[#707070] cursor-default"
                      } `}
                      onClick={() => {
                        if (
                          !(
                            result?.length === 0 &&
                            result1?.length === 0 &&
                            tagSearchName !== ""
                          )
                        ) {
                          return;
                        }
                        handleNewTag(tagSearchName);
                      }}
                    >
                      <p className="font-sans font-[400px] text-[10px]  text-[#f9f9f9]">
                        {" "}
                        Assign new tag
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className={` ${isMobile ? "h-full  pb-[20px]" : ""}`}>
              <div
                className={` flex  ${
                  isMobile ? "w-[100%] h-[100%]" : " w-[1024px]"
                }  `}
              >
                <div
                  className={` bg-[#3A5686]  ${
                    isMobile ? " w-[100%]" : "w-[512px] rounded-bl-[20px] "
                  }  relative  border-r-[1px] border-[#102C5D]`}
                  style={{
                    display: activeStep !== 0 && isMobile && "none",
                  }}
                >
                  <div
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      display:
                        users && !services?.includes("DTag")
                          ? "block"
                          : !users && !services?.includes("DOwnerTag")
                          ? "block"
                          : AllTags?.some((tag) => {
                              return tag.selected == false;
                            })
                          ? "block"
                          : "none",
                    }}
                    className="absolute w-full h-full bg-black bg-opacity-30  z-[99999]"
                  ></div>
                  <div
                    className={`" px-[20px] py-[10px] ${
                      isMobile ? "" : " min-h-[66px]"
                    } `}
                  >
                    {!isMobile && (
                      <>
                        <p className="font-sans font-semibold text-[14px] text-[#dadada]">
                          Assigned tags
                        </p>
                      </>
                    )}

                    <>
                      {filteredExistingTags.length > 0 ? (
                        <p className="font-sans font-[400px]  text-[12px] text-[#dadada]">
                          {users
                            ? 'Select tag(s) and click "Unassign" to unlink them from the user(s).'
                            : 'Select tag(s) and click "Unassign" to unlink them from the owner account(s).'}
                        </p>
                      ) : filteredExistingTags.length === 0 &&
                        !AllTags.some((e) =>
                          e.value
                            .toLowerCase()
                            .includes(tagSearchName.toLowerCase())
                        ) ? (
                        <p className="font-sans font-[400px] text-[12px] text-[#dadada]">
                          Click on ‘Assign new tag’ to assign the tag entered
                          above.
                        </p>
                      ) : newTags?.length === 0 ? (
                        <p className="font-sans font-[400px] text-[12px] text-[#dadada]">
                          To assign a tag, either select from the list of
                          unassigned tags and assign them or type a new tag name
                          in the search bar and click ‘Assign new tag.
                        </p>
                      ) : (
                        <p
                          className="font-sans font-[400px] text-[12px] text-[#dadada]"
                          style={{
                            visibility: "hidden",
                          }}
                        >
                          Click on ‘Assign new tag’ to assign the tag entered
                          above.
                        </p>
                      )}
                    </>

                    <>
                      {
                        !newTags?.filter((fil) => {
                          return (
                            JSON.stringify(fil)?.includes(tagSearchName) &&
                            newTags?.length === 0 && (
                              <>
                                <p className="font-sans font-[400px]  text-[14px] text-[#dadada]">
                                  To assign a tag, either select from the list
                                  of unassigned tags and assign them or type a
                                  new tag name in the search bar and click
                                  ‘Assign’.
                                </p>
                                <div className="m-[50px] flex flex-col items-center   ">
                                  <img src={notags} className="  h-[100px] " />
                                  <p className="  font-[400px] w-[150px] mt-3 ml-[15px] text-[12px] text-[#dadada] ">
                                    No assigned tags found.
                                  </p>
                                </div>
                              </>
                            )
                          );
                        })
                      }
                    </>
                  </div>
                  <div
                    className={`${
                      isMobile &&
                      filteredExistingTags?.length === 0 &&
                      tagSearchName.length > 0
                        ? " h-full pb-[20px] "
                        : ""
                    }  
                  ${
                    isMobile ? "h-full pb-[20px] " : "h-[354px]"
                  }   min-h-[372px]  ml-[20px] overflow-y-auto`}
                  >
                    {/* <>
                    {newTags?.length === 0 && (
                      <>
                        <div>
                          <p className=" font-[400px] text-[12px] text-[#dadada]">
                            To assign a tag, either select from the list of
                            unassigned tags and assign them or type a new tag
                            name in the search bar and click ‘Assign’.
                          </p>
                          <div className="m-[50px] flex flex-col items-center   ">
                            <img src={notags} className="  h-[150px] " />
                            <p className="  font-[400px] w-[150px] mt-3 ml-[15px] text-[12px] text-[#dadada] ">
                              No assigned tags found.
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </> */}

                    <div
                      className={`flex ${
                        isMobile && loading
                          ? "flex justify-center items-center"
                          : "flex mx-auto "
                      }  flex-wrap`}
                    >
                      {loading ? (
                        <>
                          <div className="flex justify-center items-center h-full">
                            <img
                              src={dataloading}
                              className={` ${
                                isMobile ? "  ml-[50%]" : " ml-[220px]"
                              } mt-[130px] w-[40px] h-[40px]`}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="flex flex-wrap"> </div>
                          {newTags?.length > 0 &&
                          filteredExistingTags?.length > 0 &&
                          !loading ? (
                            <div>
                              <div
                                className="flex flex-wrap "
                                style={{
                                  overflowX: "hidden",
                                }}
                              >
                                {newTags?.length > 0 &&
                                  newTags
                                    ?.filter((fil) => {
                                      return JSON.stringify(
                                        fil.value
                                      )?.includes(tagSearchName?.toLowerCase());
                                    })
                                    ?.map((tag) => {
                                      return (
                                        <>
                                          <Box
                                            key={tag?.value}
                                            className={`px-4 tag-box-item ${
                                              isMobile ? "" : "hover:opacity-60"
                                            } relative  `}
                                            style={{
                                              backgroundColor:
                                                tag.selected == false
                                                  ? "#4A6D4B"
                                                  : "#667CA3",
                                            }}
                                            onClick={() =>
                                              handleExistingTag(tag.value)
                                            }
                                          >
                                            <div>
                                              <>
                                                <span
                                                  id="id-textTag"
                                                  style={{
                                                    display: "inline-block",
                                                    fontSize: "12px",
                                                    paddingRight: "5px",
                                                  }}
                                                  className=" text-[#f9f9f9]"
                                                >
                                                  {tag.value}
                                                </span>

                                                <span className="w-[17px] h-[17]">
                                                  {tag.selected == false ? (
                                                    <img
                                                      className=""
                                                      src={Selected}
                                                      alt=""
                                                    />
                                                  ) : (
                                                    <img
                                                      className=""
                                                      src={
                                                        not_selected_with_grey_circle
                                                      }
                                                      alt=""
                                                    />
                                                  )}
                                                </span>
                                              </>
                                            </div>
                                          </Box>
                                        </>
                                      );
                                    })}
                              </div>
                            </div>
                          ) : (
                            <div className="existing-tag mt-3 w-full ">
                              {!loading &&
                              filteredExistingTags?.length === 0 &&
                              tagSearchName.length > 0 ? (
                                <div
                                  className={` flex flex-col ${
                                    isMobile ? "pt-[60px]" : "pt-[90px]"
                                  }  pr-[20px] justify-center items-center w-full`}
                                >
                                  <img src={notags} className="h-[150px]" />
                                  <p className="font-[400] w-[150px] mt-3 text-[12px] text-[#dadada] text-center">
                                    No Assigned tags found.
                                  </p>
                                </div>
                              ) : (
                                !loading && (
                                  <div
                                    className={`  flex flex-col ${
                                      isMobile ? "" : "pt-[90px] "
                                    } pr-[20px] justify-center items-center w-full`}
                                  >
                                    <img src={notags} className="h-[150px]" />
                                    <p className="font-[400] w-[150px] mt-3 text-[12px] text-[#dadada] text-center">
                                      No Assigned tags found.
                                    </p>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className={`  ${
                      !isMobile
                        ? "absolute bottom-[71px] w-[93%] border-b-[1px] border-[#102C5D] "
                        : ""
                    } mx-[20px]`}
                  ></div>

                  {!isMobile && (
                    <>
                      <div
                        className={`flex items-center  py-[15px] ${
                          !isMobile ? "absolute bottom-0" : ""
                        }  `}
                      >
                        <div className=" flex items-center justify-center w-[40px] h-[40px]   pl-[15px] pr-[15px] ml-[20px] py-[9px] bg-[#102C5D] mr-[10px] rounded-large">
                          <p
                            className={`font-sans font-semibold text-[14px]  text-[#f9f9f9]`}
                          >
                            {" "}
                            {/* {newTags?.filter((fil) => !fil.selected)?.length} */}
                            {!loading && filteredExistingTags?.length}
                          </p>
                        </div>
                        <p
                          className={`font-sans w-[320px] font-[400px] text-[14px]  ${
                            isMobile ? "mr-auto " : ""
                          }  text-[#F9F9F9]`}
                        >
                          {type == "userViewAll"
                            ? "Tags are assigned to all the users"
                            : users
                            ? "Tags are assigned to the selected user"
                            : "Tags are assigned to the selected owner account"}
                        </p>

                        <div
                          className={`flex items-center justify-center w-[84px] h-[32px] ${
                            isMobile ? "mr-[15px]" : ""
                          } ${
                            isAnyTagDeselected
                              ? "bg-[#69B76F] cursor-pointer hover:opacity-60 "
                              : "bg-[#707070] cursor-default"
                          } rounded-[75px] ml-[20.5px] `}
                          onClick={
                            isAnyTagDeselected ? handleUnassign : undefined
                          }
                        >
                          <p
                            className={`font-sans font-semibold text-[12px] ${
                              isMobile ? "p-[10px]" : ""
                            } text-[#f9f9f9]`}
                          >
                            Unassign
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {isMobile && (
                  <>
                    <div
                      className={`flex items-center py-[15px] ${
                        isMobile
                          ? "fixed bottom-0 z-[99999] border-t-[1px] border-[#102C5D] bg-[#3A5686] w-full"
                          : ""
                      }  `}
                      style={{
                        display: activeStep !== 0 && isMobile && "none",
                      }}
                    >
                      <div className=" flex items-center justify-center w-[40px] h-[40px]   pl-[15px] pr-[15px] ml-[20px] py-[9px] bg-[#102C5D] mr-[10px] rounded-large">
                        <p
                          className={`font-sans font-semibold text-[14px]  text-[#f9f9f9]`}
                        >
                          {" "}
                          {/* {newTags?.filter((fil) => !fil.selected)?.length} */}
                          {!loading && filteredExistingTags?.length}
                        </p>
                      </div>
                      <p
                        className={`font-sans w-[320px] font-[400px] text-[14px]  ${
                          isMobile ? "mr-auto " : ""
                        }  text-[#F9F9F9]`}
                      >
                        {type == "userViewAll"
                          ? "Tags are assigned to all the users"
                          : users
                          ? "Tags are assigned to the selected user"
                          : "Tags are assigned to the selected owner account"}
                      </p>

                      <div
                        className={`flex items-center justify-center w-[84px] h-[32px] ${
                          isMobile ? "mr-[15px]" : ""
                        } ${
                          isAnyTagDeselected
                            ? "bg-[#69B76F] cursor-pointer hover:opacity-60 "
                            : "bg-[#707070] cursor-default"
                        } rounded-[75px] ml-[20.5px] `}
                        onClick={
                          isAnyTagDeselected ? handleUnassign : undefined
                        }
                      >
                        <p
                          className={`font-sans font-semibold text-[12px] ${
                            isMobile ? "p-[10px]" : ""
                          } text-[#f9f9f9]`}
                        >
                          Unassign
                        </p>
                      </div>
                    </div>
                  </>
                )}
                <div
                  className={`bg-[#244277]  ${
                    isMobile ? "w-[100%]" : "w-[512px]"
                  }  relative rounded-br-[20px]`}
                  style={{
                    display: activeStep !== 1 && isMobile && "none",
                  }}
                >
                  <div
                    onClick={(e) => e.stopPropagation()}
                    style={{
                      display:
                        users && !services?.includes("CTag")
                          ? "block"
                          : !users && !services?.includes("COwnerTag")
                          ? "block"
                          : newTags?.some((tag) => {
                              return tag.selected == false;
                            })
                          ? "block"
                          : "none",
                    }}
                    className="absolute w-full h-full bg-black bg-opacity-30  z-[99999]"
                  ></div>
                  <div
                    className={`${
                      AllTags?.length === 0
                        ? "px-[20px] pt-[20px] pb-[5px]"
                        : " px-[20px] py-[10px]"
                    } ${isMobile ? "min-h-[67px]" : "min-h-[68px]"}`}
                  >
                    {!isMobile && (
                      <>
                        <p className="font-sans font-semibold text-[14px] text-[#dadada]">
                          Unassigned tags
                        </p>
                      </>
                    )}

                    {AllTags?.filter(
                      (fill) =>
                        !newTags.some((data) => data?.value === fill?.value)
                    )?.length !== 0 ? (
                      <>
                        {filteredExistingTags1.length > 0 ? (
                          <p
                            className={`font-sans font-[400px]  text-[12px] text-[#dadada] ${
                              isMobile ? " min-h-[36px]" : ""
                            } `}
                          >
                            {users
                              ? 'Select tag(s) and click "Assign" to link them to the user(s).'
                              : 'Select tag(s) and click "Assign" to link them to the owner account(s).'}
                          </p>
                        ) : (
                          <p
                            className="font-sans font-[400px]  text-[12px] text-[#dadada]"
                            style={{
                              visibility:
                                filteredExistingTags1.length === 0 && "hidden",
                            }}
                          >
                            ""
                          </p>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    className={`  ${
                      isMobile
                        ? " h-full pb-[20px]   "
                        : AllTags?.filter(
                            (fill) =>
                              !newTags.some(
                                (data) => data?.value === fill?.value
                              )
                          )?.length === 0
                        ? "h-[372px]"
                        : "h-[354px]"
                    } ${
                      isMobile && filteredExistingTags?.length > 0 ? "" : ""
                    } min-h-[372px] ml-[20px] ${
                      loading ? "" : "overflow-y-auto"
                    } `}
                  >
                    {loading ? (
                      <div
                        className={`flex justify-center  ${
                          isMobile ? "mt-[160px]" : " items-center"
                        } pb-[40px] h-full`}
                      >
                        <img src={dataloading} className="w-[40px] h-[40px]" />
                      </div>
                    ) : AllTags?.filter(
                        (fill) =>
                          !newTags.some((data) => data?.value === fill?.value)
                      )?.length === 0 ? (
                      <div
                        className={`flex flex-col ${
                          isMobile ? "" : "pt-[90px]  pr-[20px]"
                        }  justify-center items-center`}
                      >
                        <img src={notagsuser} className="h-[150px]" />
                        <p className="font-[400]  mt-3 text-[12px] text-[#dadada] text-center">
                          No unassigned tags found.
                        </p>
                      </div>
                    ) : (
                      <div className="flex flex-wrap ">
                        {filteredExistingTags1?.length > 0 &&
                        AllTags?.length > 0 &&
                        !loading ? (
                          <div
                            className="flex flex-wrap"
                            style={{
                              overflowX: "hidden",
                            }}
                          >
                            {AllTags.filter((fil) =>
                              JSON.stringify(fil.value)?.includes(
                                tagSearchName?.toLowerCase()
                              )
                            )
                              .filter(
                                (filter) =>
                                  !newTags.some(
                                    (data) => data?.value === filter?.value
                                  )
                              )
                              .map((tag) => (
                                <Box
                                  key={tag.value}
                                  className={`px-4 tag-box-item relative  ${
                                    isMobile ? "" : "hover:opacity-60"
                                  } `}
                                  style={{
                                    backgroundColor: tag.selected
                                      ? "#667CA3"
                                      : "#4A6D4B",
                                  }}
                                  onClick={() => handleExistingTag1(tag?.value)}
                                >
                                  <div>
                                    <span
                                      style={{
                                        display: "inline-block",
                                        fontSize: "12px",
                                        paddingRight: "5px",
                                      }}
                                      className=" text-[#f9f9f9]"
                                    >
                                      {tag?.value}
                                    </span>
                                    <span className="w-[17px] h-[17]">
                                      {tag.selected ? (
                                        <img
                                          src={not_selected_with_grey_circle}
                                          alt=""
                                        />
                                      ) : (
                                        <img src={Selected} alt="" />
                                      )}
                                    </span>
                                  </div>
                                </Box>
                              ))}
                          </div>
                        ) : (
                          <div className="existing-tag mt-3 w-full">
                            {filteredExistingTags1?.length === 0 &&
                            tagSearchName.length > 0 ? (
                              <div
                                className={` flex flex-col ${
                                  isMobile ? "" : " pt-[90px]"
                                } pr-[20px] justify-center items-center w-full`}
                              >
                                <img src={notagsuser} className="h-[150px]" />
                                <p className="font-[400] mt-3 text-[12px] text-[#dadada] text-center">
                                  No Unassigned tags found.
                                </p>
                              </div>
                            ) : (
                              <div className=" flex flex-col pt-[90px] pr-[20px] justify-center items-center w-full">
                                <img src={notagsuser} className="h-[150px]" />
                                <p className="font-[400] mt-3 text-[12px] text-[#dadada] text-center">
                                  No Unassigned tags found.
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div
                    className={` ${
                      isMobile
                        ? ""
                        : " border-b-[1px] border-[#102C5D] mx-[20px]"
                    }`}
                  ></div>
                  {!isMobile ? (
                    <>
                      <div className="flex items-center py-[15px]  ">
                        <div className=" flex items-center justify-center w-[40px] h-[40px]   px-[16px] ml-[20px] py-[9px] bg-[#102C5D] mr-[10px] rounded-large">
                          <p className="font-sans font-semibold text-[14px] text-[#f9f9f9]">
                            {" "}
                            {/* {AllTags?.filter((fil) => !fil.selected)?.length} */}
                            {!loading && filteredExistingTags1?.length}
                          </p>
                        </div>
                        <p
                          className={`font-sans  ${
                            isMobile ? "mr-auto " : "mr-[210px] "
                          } font-[400px] text-[14px]  text-[#F9F9F9]`}
                        >
                          Tags unassigned
                        </p>
                        <div
                          className={` flex items-center justify-center w-[84px] h-[32px] bg-[#707070]  ${
                            isMobile ? "mr-3" : ""
                          }   rounded-[75px] ml-[20.5px] ${
                            isAnyTagDeselectedUnassign
                              ? "bg-[#69B76F] cursor-pointer hover:opacity-60  "
                              : "bg-[#707070] cursor-default "
                          }`}
                          onClick={
                            isAnyTagDeselectedUnassign
                              ? handleAssign
                              : undefined
                          }
                        >
                          <p className="font-sans font-semibold text-[12px] text-[#f9f9f9]">
                            {" "}
                            Assign
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={`flex items-center py-[15px] ${
                          isMobile
                            ? "fixed bottom-0 z-[99999] border-t-[1px] border-[#102C5D] bg-[#3A5686] w-full"
                            : ""
                        }  `}
                        style={{
                          display: activeStep !== 1 && isMobile && "none",
                        }}
                      >
                        <div className=" flex items-center justify-center w-[40px] h-[40px]   px-[16px] ml-[20px] py-[9px] bg-[#102C5D] mr-[10px] rounded-large">
                          <p className="font-sans font-semibold text-[14px] text-[#f9f9f9]">
                            {" "}
                            {/* {AllTags?.filter((fil) => !fil.selected)?.length} */}
                            {!loading && filteredExistingTags1?.length}
                          </p>
                        </div>
                        <p
                          className={`font-sans  ${
                            isMobile ? "mr-auto " : "mr-[210px] "
                          } font-[400px] text-[14px]  text-[#F9F9F9]`}
                        >
                          Tags unassigned
                        </p>
                        <div
                          className={` flex items-center justify-center w-[84px] h-[32px] bg-[#707070]  ${
                            isMobile ? "mr-3" : ""
                          }   rounded-[75px] ml-[20.5px] ${
                            isAnyTagDeselectedUnassign
                              ? "bg-[#69B76F] cursor-pointer hover:opacity-60  "
                              : "bg-[#707070] cursor-default "
                          }`}
                          onClick={
                            isAnyTagDeselectedUnassign
                              ? handleAssign
                              : undefined
                          }
                        >
                          <p className="font-sans font-semibold text-[12px] text-[#f9f9f9]">
                            {" "}
                            Assign
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserTag;
