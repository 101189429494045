import React, { useRef } from "react";
import "./index.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { beneCategories } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { checkUsertoken } from "../../../../redux/features/login/login";
import arrow_down_red from "../../../../assets/ArrowsIcons/arrow_down_white_filled.svg";
import cancel from "../../../../assets/CloseIcons/close_with_white_circle.svg";
import search from "../../../../assets/FilterSearchIcons/search_white.svg";
import DatePickerComponent from "../../../../components/_utils/Calendar";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber";

import arrowforward from "../../../../assets/ArrowsIcons/arrow_right_white – 1.svg";
import Validation from "../../../../utils/validation";

import { Button, Tooltip, tooltipClasses } from "@mui/material";
import { init } from "@emailjs/browser";
import clear_filter from "../../../../assets/ViewAllTransactions/Clear filter.png";
const BeneAccFilterSidebar = ({
  isOpen,
  onClose,
  categoryName,
  sendCategory,
  sendDateRange,
  setPage,
  sidebarRef,
  setIsSidebarOpen,
  setIsSidebarOpenm,
  initCategory,
  setStartDateFilter,
  setEndDateFilter,
  setInitCategory,
  setAccountNumber,
  accountNumber,
  sendAccountNumber,
  setPanNumber,
  panNumber,
  sendPanNumber,
  isSidebarOpen,
  isSidebarOpenm,
  searchPanText,
  setSearchPanText,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  startDatem,
  setStartDatem,
  endDatem,
  setEndDatem,
  toggleSidebar,
  isIfsc,
  setIsIfsc,
  searchIfscText,
  setSearchIfscText,
  searchAccountText,
  setSearchAccountText,
  showDatePicker,
  setShowDatePicker,
  setCurrentPage,
  typepop,
  startDateFilter,
  endDateFilter,
  setTempEndDate,
  setTempStartDate,
  handleFilterDateRange,
  tempEndDate,
  tempStartDate,
  balanceType,
  associateAccount,
  InetrnalAssociatepanNumber,
  InetrnalAssociateIFSC,
  InetrnalAssociateaccountNumber,
  setcallFilterFunc,
  isInternalFilter,
  setIsInternalFilter,
  internalAccount,
  associateTxn,
  BeneTxnAssocAcct,
  setFilterStatus,
  dateRef,
  flow,
}) => {
  const [dropdownOpen, setdropdownOpen] = useState(false);
  const [dropdownOpenm, setdropdownOpenm] = useState(false);
  const [categorySearch, setCategorySearch] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [activeSection, setActiveSection] = useState(null);
  const [label, setLabel] = useState("Select category");
  const [isDateConfirmed, setIsDateConfirmed] = useState(false);
  const dispatch = useDispatch();
  const searchRef = useRef(null);
  const searchInputRef = useRef(null);
  const categorydropdownRef = useRef(null);
  const categorydropdownRefm = useRef(null);
  const yearDropdownRef = useRef(null);
  const applyBtnRef = useRef(null);
  const categories = useSelector(
    (state) => state.beneAllVerification.beneTransCategories.categories
  );

  useEffect(() => {}, [isOpen]);
  const handleScroll = (scrollAmount) => {
    window.scrollBy({
      top: scrollAmount,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (typeof setcallFilterFunc !== "function") {
      return () => null;
    }
    setcallFilterFunc(filterCategory);
  }, [setcallFilterFunc, balanceType]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.keyCode) {
        case 13: // enter key
          isOpen && applyBtnRef.current.click();
          break;
        case 83: // s key
          setScrollAmount(30);
          break;
        case 87: // w key
          setScrollAmount(-30);
          break;
        default:
          break;
      }
    };

    const handleKeyUp = () => {
      setScrollAmount(0);
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    const scrollInterval = setInterval(() => {
      if (scrollAmount !== 0) {
        handleScroll(scrollAmount);
      }
    }, 10);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      clearInterval(scrollInterval);
    };
  }, [scrollAmount, isOpen]);
  const handleAccountSearch = (text) => {
    setSearchAccountText(text);
    // setAccountNumber(text);
  };
  const handlePanSearch = (text) => {
    setSearchPanText(text);
    // setAccountNumber(text);
  };
  const handleIfscSearch = (text) => {
    setSearchIfscText(text);
  };
  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneCategories());
  }, [dispatch]);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    onClose(open);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      event.stopPropagation();
      if (
        categorydropdownRef.current &&
        !categorydropdownRef.current.contains(event.target)
      ) {
        setdropdownOpen(false);
      }

      if (
        categorydropdownRefm.current &&
        !categorydropdownRefm.current.contains(event.target)
      ) {
        setdropdownOpenm(false);
      }
      // if (dateRef.current && !dateRef.current.contains(event.target)) {
      //   setShowDatePicker(false);
      // }

      // if (dateRefm.current && !dateRefm.current.contains(event.target)) {
      //   setShowDatePicker(false);
      // }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    categorydropdownRef,
    categorydropdownRefm,
    // dateRef,
    // dateRefm
  ]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleClick = (name) => {
    setInitCategory(name);
    setPage(1);
    setdropdownOpen(false);
    setdropdownOpenm(false);
  };
  const handleCategorySearchChange = (event) => {
    setCategorySearch(event.target.value);
  };
  const clearFilter = () => {
    setInitCategory("");
    sendCategory("");
    setShowDatePicker(false);
    setStartDate(null);
    setEndDate(null);
    setTempEndDate("");
    setTempStartDate("");
    setStartDateFilter("");
    setEndDateFilter("");
    // setIsSidebarOpen(false);
    // setIsSidebarOpenm(false);

    setIsIfsc("");
    // setIsInternalFilter(false);
    if (
      (associateAccount ||
        BeneTxnAssocAcct ||
        associateTxn === "associateTxn") &&
      balanceType === "Pan"
    ) {
      setSearchPanText(searchPanText);
      setPanNumber(panNumber);
    } else {
      setSearchPanText("");
      setPanNumber("");
    }
    if ((associateAccount || BeneTxnAssocAcct) && balanceType === "Bank") {
      setSearchIfscText(searchIfscText);
      setSearchAccountText(searchAccountText);
      setAccountNumber(accountNumber);
    } else {
      setSearchIfscText("");
      setSearchAccountText("");
      setAccountNumber("");
    }
  };
  const allowedValue = (name, value) => {
    if (name === "accountnumber") return Validation.allowedBankAccNum(value);
    if (name === "ifscnumber") return Validation.allowedBankIfsc(value);
    if (name === "pannumber") return Validation.allowedPAN(value);
    return true;
  };

  const handleInput = (e) => {
    let { name, value } = e.target;

    let val = value.replaceAll(" ", "");
    if (allowedValue(name, val)) {
      if (name === "accountnumber") {
        setSearchAccountText(val.toUpperCase());

        if (val.length === 0) {
          setSearchIfscText("");
        }
      } else if (name === "ifscnumber") {
        setSearchIfscText(val.toUpperCase());
      } else if (name === "pannumber") {
        setSearchPanText(val.toUpperCase());
      }

      setActiveSection(name);
      setdropdownOpen(false);
      setdropdownOpenm(false);
      setShowDatePicker(false);
    }
  };

  const handleConfirm = () => {
    setShowDatePicker(false);
    setIsDateConfirmed(true);
  };

  const handleCancel = () => {
    setShowDatePicker(false);
    setStartDate(null);
    setEndDate(null);
    setIsDateConfirmed(false);
  };
  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const timestamp = Math.floor(date.getTime() / 1000);

    return timestamp;
  };
  function filterCategory() {
    if (handleFilterEnable()) {
      sendCategory(initCategory);
      setPage(1);

      handleFilterDateRange(convertDate(startDate), convertDate(endDate));

      setTempStartDate(startDateFilter);
      setTempEndDate(endDateFilter);

      setAccountNumber(searchAccountText);
      setPanNumber(
        searchPanText.length === 0 ? "" : searchPanText || panNumber
      );
      setIsIfsc(searchIfscText);
      setShowDatePicker(false);
      setIsSidebarOpen(false);
    }
  }
  const filterCategorym = () => {
    if (handleFilterEnable()) {
      sendCategory(initCategory);
      setPage(1);
      if (startDate && endDate) {
        setStartDateFilter(convertDate(startDate));
        setEndDateFilter(convertDate(endDate));
      }
      setAccountNumber(searchAccountText);
      setPanNumber(searchPanText);
      setIsIfsc(searchIfscText);
      setShowDatePicker(false);
      setIsSidebarOpenm(false);
    }
  };
  useEffect(() => {
    if ((!startDate && endDate) || (startDate && !endDate)) {
      setIsDateConfirmed(false);
    }
  }, [startDate, endDate]);
  const handleFilterEnable = () => {
    // if (associateAccount) {
    //   if (balanceType === "Pan") {
    //     if (
    //       searchAccountText.length === 0 &&
    //       searchIfscText.length === 0 &&
    //       (!startDate || !endDate) &&
    //       initCategory.length === 0
    //     ) {
    //       return false;
    //     }
    //   } else if (balanceType === "Bank") {
    //     if (
    //       searchPanText.length === 0 &&
    //       (!startDate || !endDate) &&
    //       initCategory.length === 0
    //     ) {
    //       return false;
    //     }
    //   }
    // }
    if (searchPanText?.length > 0 && searchPanText?.length !== 10) {
      return false;
    }

    if (searchAccountText?.length > 0 && searchAccountText?.length < 9) {
      return false;
    }

    if (searchAccountText?.length > 0 && searchIfscText?.length !== 11) {
      return false;
    }

    if (searchIfscText?.length > 0 && searchAccountText?.length < 9) {
      return false;
    }

    if (searchAccountText?.length === 0 && searchIfscText?.length > 0) {
      return false;
    }

    if ((startDate || endDate) && !isDateConfirmed) {
      return false;
    }

    if (
      searchAccountText?.length === 0 &&
      searchIfscText?.length === 0 &&
      searchPanText?.length === 0 &&
      (!startDate || !endDate) &&
      initCategory?.length === 0
    ) {
      return false;
    }
    return true;
  };

  // useEffect(() => {
  //   setFilterStatus(
  //     initCategory ||
  //       (startDate && endDate) ||
  //       (searchAccountText && searchIfscText) ||
  //       searchPanText
  //       ? true
  //       : false
  //   );
  // }, [
  //   initCategory,
  //   startDate,
  //   endDate,
  //   searchAccountText,
  //   searchIfscText,
  //   searchPanText,
  // ]);

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(categorySearch.toLowerCase())
  );
  const formatDate = (date) => {
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })}, ${date.getFullYear()}`;
  };
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const internalCursorEnable = (type) => {
    if (
      associateAccount ||
      BeneTxnAssocAcct ||
      associateTxn === "associateTxn"
    ) {
      if (balanceType === "Pan") {
        if (
          (searchPanText &&
            searchAccountText.length > 0 &&
            searchAccountText.length < 9) ||
          (searchAccountText.length > 0 && searchIfscText.length !== 11) ||
          (searchIfscText.length > 0 && searchAccountText.length < 9) ||
          (searchAccountText.length === 0 && searchIfscText.length > 0) ||
          ((startDate || endDate) && !isDateConfirmed) ||
          (searchAccountText.length === 0 &&
            searchIfscText.length === 0 &&
            (!startDate || !endDate) &&
            initCategory.length === 0)
        ) {
          if (type === "opacity") {
            return 0.5;
          } else {
            return "default";
          }
        } else {
          if (type === "opacity") {
            return 1;
          } else {
            return "pointer";
          }
        }
      } else if (balanceType === "Bank") {
        if (
          (searchAccountText &&
            searchIfscText &&
            (startDate || endDate) &&
            !isDateConfirmed) ||
          (searchPanText.length !== 10 &&
            (!startDate || !endDate) &&
            initCategory.length === 0)
        ) {
          if (type === "opacity") {
            return 0.5;
          } else {
            return "default";
          }
        } else {
          if (type === "opacity") {
            return 1;
          } else {
            return "pointer";
          }
        }
      }
    } else {
      if (handleFilterEnable()) {
        return "pointer";
      } else {
        return "default";
      }
    }
  };

  const internalCursorOpacity = () => {
    if (associateAccount || associateTxn === "associateTxn") {
      if (balanceType === "Pan") {
        if (
          (searchPanText &&
            searchAccountText.length > 0 &&
            searchAccountText.length < 9) ||
          (searchAccountText.length > 0 && searchIfscText.length !== 11) ||
          (searchIfscText.length > 0 && searchAccountText.length < 9) ||
          (searchAccountText.length === 0 && searchIfscText.length > 0) ||
          ((startDate || endDate) && !isDateConfirmed) ||
          (searchAccountText.length === 0 &&
            searchIfscText.length === 0 &&
            (!startDate || !endDate) &&
            initCategory.length === 0)
        ) {
          return "none";
        } else {
          return "hover:bg-custom-cardHover";
        }
      } else if (balanceType === "Bank") {
        if (
          (searchAccountText &&
            searchIfscText &&
            (startDate || endDate) &&
            !isDateConfirmed) ||
          (searchPanText.length !== 10 &&
            (!startDate || !endDate) &&
            initCategory.length === 0)
        ) {
          return "none";
        } else {
          return "hover:bg-custom-cardHover";
        }
      }
    } else {
      if (handleFilterEnable()) {
        return "hover:bg-custom-cardHover";
      } else {
        return "none";
      }
    }
  };

  const isNoCategorySelected =
    initCategory === "" &&
    startDate === null &&
    endDate === null &&
    searchAccountText === "" &&
    searchIfscText === "" &&
    searchPanText === "";

  const getBgColor = () => {
    if (
      associateAccount &&
      isInternalFilter &&
      associateTxn === "associateTxn"
    ) {
      if (balanceType === "Pan") {
        if (
          (searchPanText &&
            searchAccountText.length > 0 &&
            searchAccountText.length < 9) ||
          (searchAccountText.length > 0 && searchIfscText.length !== 11) ||
          (searchIfscText.length > 0 && searchAccountText.length < 9) ||
          (searchAccountText.length === 0 && searchIfscText.length > 0) ||
          ((startDate || endDate) && !isDateConfirmed) ||
          (searchAccountText.length === 0 &&
            searchIfscText.length === 0 &&
            (!startDate || !endDate) &&
            initCategory.length === 0)
        ) {
          return "#727272";
        } else {
          return "#F05A6A";
        }
      } else if (balanceType === "Bank") {
        if (
          (searchAccountText &&
            searchIfscText &&
            (startDate || endDate) &&
            !isDateConfirmed) ||
          (searchPanText.length !== 10 &&
            (!startDate || !endDate) &&
            initCategory.length === 0)
        ) {
          return "#727272";
        } else {
          return "#F05A6A";
        }
      }
    } else {
      if (handleFilterEnable()) {
        return "#F05A6A";
      } else {
        return "#727272";
      }
    }
  };
  const isFilterClickable = () => {
    if (
      associateAccount &&
      isInternalFilter &&
      associateTxn === "associateTxn"
    ) {
      if (balanceType === "Pan") {
        if (
          (searchPanText &&
            searchAccountText.length > 0 &&
            searchAccountText.length < 9) ||
          (searchAccountText.length > 0 && searchIfscText.length !== 11) ||
          (searchIfscText.length > 0 && searchAccountText.length < 9) ||
          (searchAccountText.length === 0 && searchIfscText.length > 0) ||
          ((startDate || endDate) && !isDateConfirmed) ||
          (searchAccountText.length === 0 &&
            searchIfscText.length === 0 &&
            (!startDate || !endDate) &&
            initCategory.length === 0)
        ) {
          return "default";
        } else {
          return "pointer";
        }
      } else if (balanceType === "Bank") {
        if (
          (searchAccountText &&
            searchIfscText &&
            (startDate || endDate) &&
            !isDateConfirmed) ||
          (searchPanText.length !== 10 &&
            (!startDate || !endDate) &&
            initCategory.length === 0)
        ) {
          return "default";
        } else {
          return "pointer";
        }
      }
    } else {
      if (handleFilterEnable()) {
        return "default";
      } else {
        return "pointer";
      }
    }
  };
  const isFilterCallable = () => {
    if (
      associateAccount &&
      isInternalFilter &&
      associateTxn === "associateTxn"
    ) {
      if (balanceType === "Pan") {
        if (
          (searchPanText &&
            searchAccountText.length > 0 &&
            searchAccountText.length < 9) ||
          (searchAccountText.length > 0 && searchIfscText.length !== 11) ||
          (searchIfscText.length > 0 && searchAccountText.length < 9) ||
          (searchAccountText.length === 0 && searchIfscText.length > 0) ||
          ((startDate || endDate) && !isDateConfirmed) ||
          (searchAccountText.length === 0 &&
            searchIfscText.length === 0 &&
            (!startDate || !endDate) &&
            initCategory.length === 0)
        ) {
          return "none";
        } else {
          return "auto";
        }
      }
      if (balanceType === "Bank") {
        if (
          (searchAccountText &&
            searchIfscText &&
            (startDate || endDate) &&
            !isDateConfirmed) ||
          (searchPanText.length !== 10 &&
            (!startDate || !endDate) &&
            initCategory.length === 0)
        ) {
          return "none";
        } else {
          return "auto";
        }
      }
    } else {
      if (handleFilterEnable()) {
        return "auto";
      } else {
        return "none";
      }
    }
  };

  return (
    <>
      <div
        ref={sidebarRef}
        id="webviewd"
        className={`beneAccviewallsidebar ${isOpen ? "open" : "closed"}`}
        style={{
          position: typepop === "popup" ? "absolute" : "fixed",
        }}
      >
        <div
          className="beneAccviewallFilterDiv pl-5"
          style={{
            visibility: isOpen ? "visible" : "hidden",
            transition: `opacity 0.2s ease-in ${isOpen ? "0s" : "0.2s"}`,
            opacity: isOpen ? "1" : "0",
          }}
        >
          <div className="beneAccFilterSection1">
            <span className="text-[#F05A6A] font-normal">Filter </span>
            {/* {internalAccount !== "internalAccount" && (
              <>
                <p
                  style={{
                    color: "#C9C9C9",
                    display: "inline-block",
                    marginTop: "17px",
                    height: "17px",
                    fontSize: "12px",
                    width: "216px",
                  }}
                >
                  Beneficiary Category
                </p>
                <div
                  className="bene_acc_category_select mt-[10px]"
                  ref={categorydropdownRef}
                  onClick={(e) => {
                    e.stopPropagation();
                    setdropdownOpen(!dropdownOpen);
                    setIsDropdownOpen(false);
                    setShowDatePicker(false); // Close date picker if it's open
                  }}
                  style={{
                    height: dropdownOpen ? "198px" : "35px",
                    outline: dropdownOpen ? "1px solid #E25869" : "",
                    transition: dropdownOpen ? "none" : "",
                    position: "relative",
                  }}
                >
                  <div className="bene_txn_category_select_select">
                    <div
                      className="txn_category_select_scroll_line"
                      style={{ display: dropdownOpen ? "block" : "none" }}
                    ></div>

                    <span
                      style={{
                        fontSize: "12px",
                        paddingLeft: dropdownOpen && "9px",
                        color: initCategory ? "#f9f9f9" : "#dadada",
                      }}
                    >
                      {initCategory || "Select a category"}
                    </span>
                    <img
                      src={arrow_down_red}
                      alt="down"
                      style={{
                        transform: dropdownOpen
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                  </div>

                  {dropdownOpen && (
                    <div className="txn_category_select_scroll">
                      <div className="bene_input_div_element">
                        <img
                          src={search}
                          alt="search"
                          className="search_owner_element"
                          onClick={(e) => {
                            e.stopPropagation();
                            searchRef.current.focus();
                          }}
                        />
                        <input
                          ref={searchRef}
                          type="text"
                          value={categorySearch}
                          onChange={handleCategorySearchChange}
                          placeholder="Search"
                          onClick={(e) => e.stopPropagation()}
                          className="placeholder-[#dadada] text-xs"
                        />
                        <img
                          src={cancel}
                          alt="cancel"
                          className="search_owner_element"
                          onClick={(e) => {
                            e.stopPropagation();
                            setCategorySearch("");
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>

                      {categorySearch === "" ? (
                        <div className="bene_txn_sort_by_category_scroll">
                          {categories.map((category) => (
                            <div key={category.id} className="category-wrapper">
                              <Tooltip
                                key={category.id}
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      zIndex: "2147483647 !important",
                                    },
                                  },
                                }}
                                title={category.name}
                              >
                                <div
                                  className="bene_acc_category_select_scroll_com"
                                  onClick={() => {
                                    handleClick(category.name);
                                  }}
                                >
                                  <span className="w-[150px] pl-[4px] truncate ... cursor-pointer">
                                    {category.name}
                                  </span>
                                </div>
                              </Tooltip>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="bene_Acc_filter_by_category_scroll">
                          {filteredCategories.map((category) => (
                            <div key={category.id} className="category-wrapper">
                              <Tooltip
                                key={category.id}
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      zIndex: "2147483647 !important",
                                    },
                                  },
                                }}
                                title={category.name}
                              >
                                <div
                                  className="bene_acc_category_select_scroll_com"
                                  onClick={() => {
                                    handleClick(category.name);
                                  }}
                                >
                                  {" "}
                                  <span className="w-[150px] pl-[4px] truncate ...">
                                    {category.name}
                                  </span>
                                </div>
                              </Tooltip>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )} */}
            {/* {!dropdownOpen && ( */}
            <div
              className="relative w-[200px] h-[35px]"
              style={{
                marginTop:
                  associateAccount ||
                  associateTxn === "associateTxn" ||
                  BeneTxnAssocAcct ||
                  internalAccount === "internalAccount"
                    ? ""
                    : "",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setActiveSection("pannumber");
                setdropdownOpen(false);
                setShowDatePicker(false);
              }}
            >
              <span
                style={{
                  color: "#C9C9C9",
                  display: "inline-block",
                  marginTop:
                    associateAccount ||
                    BeneTxnAssocAcct ||
                    associateTxn === "associateTxn"
                      ? ""
                      : internalAccount === "internalAccount"
                      ? "15px"
                      : "18px",
                  // width: "113px",
                  height: "17px",
                  fontSize: "12px",
                }}
              >
                PAN number
              </span>
              <input
                ref={searchInputRef}
                className="w-[216px] h-[35px] px-4 py-2.5 rounded-xl bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] text-xs mt-[5px]"
                type="text"
                placeholder="Enter PAN number"
                value={searchPanText}
                name="pannumber"
                onChange={handleInput}
                onFocus={() => setActiveSection("pannumber")}
                disabled={
                  balanceType === "Pan" &&
                  (associateAccount ||
                    BeneTxnAssocAcct ||
                    associateTxn === "associateTxn") &&
                  "disabled"
                }
                style={{
                  opacity:
                    balanceType === "Pan" &&
                    (associateAccount ||
                      BeneTxnAssocAcct ||
                      associateTxn === "associateTxn")
                      ? "0.6"
                      : "1",
                }}
              />
              {/* {searchPanText && (
                <img
                  src={closeIcon}
                  alt="Clear"
                  className="bene-acc-viewall-search-close-image absolute right-[17px] top-[40px] transform -translate-y-1/2 cursor-pointer"
                  onClick={() => {
                    setSearchPanText("");
                    searchInputRef.current.focus();
                  }}
                />
              )}{" "}
              {!searchPanText && (
                <img
                  src={search}
                  alt="search"
                  className="bene-acc-viewall-search-inner-image absolute right-[16px] w-4 h-4 top-[20px] transform -translate-y-1/2 cursor-pointer"
                />
              )} */}
            </div>
            {/* )} */}
            {(associateAccount !== true &&
              BeneTxnAssocAcct !== true &&
              internalAccount !== "internalAccount") ||
            (associateAccount && typepop === "popup") ? (
              <div
                style={{
                  marginTop:
                    associateAccount !== true ||
                    (BeneTxnAssocAcct !== true &&
                      internalAccount !== "internalAccount")
                      ? "60px"
                      : "60px",
                  display: flow === "internalTxn" ? "none" : "flex",
                }}
              >
                <p
                  style={{
                    color: "#C9C9C9",
                    display: "inline-block",
                    marginTop: "5px",
                    height: "17px",
                    fontSize: "12px",
                    width: "200px",
                  }}
                >
                  Beneficiary Category
                </p>
                <div
                  className="bene_acc_category_select mt-[5px]"
                  ref={categorydropdownRef}
                  onClick={(e) => {
                    e.stopPropagation();
                    setdropdownOpen(!dropdownOpen);
                    setIsDropdownOpen(false);
                    setShowDatePicker(false);
                  }}
                  style={{
                    marginTop: "30px",
                    height: dropdownOpen ? "198px" : "35px",
                    outline: dropdownOpen ? "1px solid #E25869" : "",
                    transition: dropdownOpen ? "none" : "",
                    position: "relative",
                  }}
                >
                  <div className="bene_txn_category_select_select">
                    <div
                      className="txn_category_select_scroll_line"
                      style={{ display: dropdownOpen ? "block" : "none" }}
                    ></div>
                    <span
                      style={{
                        fontSize: "12px",
                        paddingLeft: dropdownOpen && "9px",
                        color: initCategory ? "#f9f9f9" : "#dadada",
                      }}
                    >
                      {initCategory || "Select a category"}
                    </span>
                    <div className="flex flex-row items-center">
                      <img
                        src={clear_filter}
                        alt="clear"
                        className="w-[9px] h-[9px]"
                        style={{
                          visibility:
                            initCategory && isOpen ? "visible" : "hidden",
                        }}
                        onClick={(e) => {
                          setInitCategory("");
                          e.stopPropagation();
                        }}
                      />
                      <div
                        className="w-[1px] h-[16px] mx-[8px]"
                        style={{
                          backgroundColor: "var(--Blue_3)",
                          visibility:
                            initCategory && isOpen ? "visible" : "hidden",
                        }}
                      ></div>
                      <img
                        src={arrow_down_red}
                        alt="down"
                        className="w-[11px] h-[11px]"
                        style={{
                          transform: dropdownOpen
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    </div>
                  </div>
                  {dropdownOpen && (
                    <div className="txn_category_select_scroll">
                      <div className="bene_inputtxn_div_element">
                        <img
                          src={search}
                          alt="search"
                          className="search_owner_element"
                          onClick={(e) => {
                            e.stopPropagation();
                            searchRef.current.focus();
                          }}
                        />
                        <input
                          ref={searchRef}
                          type="text"
                          value={categorySearch}
                          onChange={handleCategorySearchChange}
                          placeholder="Search"
                          onClick={(e) => e.stopPropagation()}
                          className="placeholder-[#dadada] text-xs"
                        />
                        <img
                          src={cancel}
                          alt="cancel"
                          className="search_owner_element"
                          onClick={(e) => {
                            e.stopPropagation();
                            setCategorySearch("");
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      {categorySearch === "" ? (
                        <div className="bene_acc_sort_by_category_scroll">
                          {categories.map((category) => (
                            <div
                              key={category.id}
                              className="category-wrapper"
                              style={{ marginLeft: "5px" }}
                            >
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      zIndex: "2147483647 !important",
                                    },
                                  },
                                }}
                                title={category.name}
                              >
                                <div
                                  className="bene_acc_category_select_scroll_com"
                                  onClick={() => {
                                    handleClick(category.name);
                                  }}
                                >
                                  <span className="w-[150px] pl-[4px] truncate ... cursor-pointer">
                                    {category.name}
                                  </span>
                                </div>
                              </Tooltip>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="bene_Acc_filter_by_category_scroll">
                          {filteredCategories.map((category) => (
                            <div key={category.id} className="category-wrapper">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      zIndex: "2147483647 !important",
                                    },
                                  },
                                }}
                                title={category.name}
                              >
                                <div
                                  className="bene_acc_category_select_scroll_com"
                                  onClick={() => {
                                    handleClick(category.name);
                                  }}
                                >
                                  <span className="w-[150px] pl-[4px] truncate ...">
                                    {category.name}
                                  </span>
                                </div>
                              </Tooltip>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
          {/* <div className="lower-div flex place-items-stretch">
            <div className="bene-acc-lower-div2 px-7 mb-5 flex-grow flex flex-row h-[413px]"> */}
          {/* <div className="flex flex-row justify-between mt-8 h-10 px-5 py-2.5 rounded-xl bg-[#3A5686]"> */}

          <div className="beneAccFilterSection2 pl-[30px] mt-[36px]">
            <div
              className="relative w-[216px]"
              onClick={(e) => {
                e.stopPropagation();
                setActiveSection("accountnumber");
                setdropdownOpen(false);
                setShowDatePicker(false);
              }}
            >
              <span
                style={{
                  color: "#C9C9C9",
                  width: "35px",
                  height: "19px",
                  font: "normal normal 12px/19px Open Sans",
                }}
              >
                Account number
              </span>
              <input
                ref={searchInputRef}
                className="w-[216px] h-[35px] px-4 py-2.5 rounded-xl bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] text-xs mt-[5px]"
                type="text"
                placeholder="Enter account number"
                value={searchAccountText}
                name="accountnumber"
                onChange={handleInput}
                onFocus={() => setActiveSection("accountnumber")}
                disabled={
                  balanceType === "Bank" &&
                  (associateAccount || BeneTxnAssocAcct) &&
                  "disabled"
                }
                style={{
                  opacity:
                    balanceType === "Bank" &&
                    (associateAccount || BeneTxnAssocAcct)
                      ? "0.6"
                      : "1",
                }}
              />
              {/* {searchAccountText && (
                  <img
                    src={closeIcon}
                    alt="Clear"
                    className="bene-acc-viewall-search-close-image absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={() => {
                      setSearchAccountText("");
                      searchInputRef.current.focus();
                    }}
                  />
                )}{" "}
                {!searchAccountText && (
                  <img
                    src={search}
                    alt="search"
                    className="bene-acc-viewall-search-inner-image absolute right-[14px] w-4 h-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  />
                )} */}
            </div>

            <div
              className="relative w-[216px] mt-[20px]"
              onClick={(e) => {
                e.stopPropagation();
                setActiveSection("ifscnumber");
                setdropdownOpen(false);
                setShowDatePicker(false);
              }}
            >
              <span
                style={{
                  color: "#C9C9C9",
                  width: "35px",
                  height: "19px",
                  font: "normal normal 12px/19px Open Sans",
                }}
              >
                IFSC code
              </span>
              <input
                ref={searchInputRef}
                className="w-[216px] h-[35px] px-4 py-2.5 rounded-xl bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] text-xs mt-[5px] mb-[20px]"
                type="text"
                placeholder="Enter IFSC code"
                value={searchIfscText}
                name="ifscnumber"
                onChange={handleInput}
                onFocus={() => setActiveSection("ifscnumber")}
                disabled={balanceType === "Bank" && "disabled"}
                style={{
                  opacity: balanceType === "Bank" ? "0.6" : "1",
                }}
              />
              {/* {searchAccountText && (
                  <img
                    src={closeIcon}
                    alt="Clear"
                    className="bene-acc-viewall-search-close-image absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={() => {
                      setSearchAccountText("");
                      searchInputRef.current.focus();
                    }}
                  />
                )}{" "}
                {!searchAccountText && (
                  <img
                    src={search}
                    alt="search"
                    className="bene-acc-viewall-search-inner-image absolute right-[14px] w-4 h-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  />
                )} */}
            </div>
            <span
              style={{
                color: "#B4B4B4",
                width: "218px",
                height: "32px",
                font: "normal normal 12px/19px Open Sans",
                paddingRight: "8px",
              }}
            >
              Note: Account number and IFSC code must be filled to filter by
              bank details
            </span>
          </div>

          <div className="beneAccFilterSection3 pl-[30px] mt-[42px]">
            <span
              style={{
                color: "#C9C9C9",
                width: "62px",
                height: "17px",
                font: "normal normal 12px/19px Open Sans",
                marginBottom: "8px",
              }}
            >
              Date range
            </span>
            <div
              className="bene_acc_Calendar_main_div relative flex flex-col items-center cursor-pointer"
              // style={{ opacity: dropdownOpen ? 0 : 1 }}

              style={{
                width: "305px",
                height: showDatePicker ? "371px" : "35px",
                border: showDatePicker ? "1px solid #ff5860" : "none",
                borderRadius: "0.75rem",
                // borderRadius: showDatePicker ? "10px" : "auto",
              }}
            >
              <div
                className="flex flex-row items-center justify-between rounded-xl bg-[#667CA3] cursor-pointer"
                style={{
                  width: "305px",
                  height: showDatePicker ? "" : "35px",
                  // border: showDatePicker ? "none" : "1px solid #ff5860",
                  // borderRadius: showDatePicker ? "10px" : "auto",
                }}
                onClick={(e) => {
                  setShowDatePicker((prevState) => !prevState);
                  setdropdownOpen(false);
                  setActiveSection("date");
                  e.stopPropagation();
                }}
                // style={{ opacity: dropdownOpen ? 0 : 1 }}
              >
                <div
                  className="beneAcc_date_heading_div cursor-pointer"
                  style={{
                    borderTopLeftRadius: showDatePicker ? "7px" : "none",
                    borderLeft: showDatePicker ? "1px solid #ff5860" : "none",
                  }}
                  // onClick={() => setShowDatePicker((prev) => !prev)}
                >
                  <div className="beneAcc_date_heading_leftm">
                    {" "}
                    {startDate ? formatDate(startDate) : "Select from date"}
                  </div>
                </div>
                <img
                  src={arrowforward}
                  className="bene_acc_viewall_calendar_icon"
                />
                <div
                  className="beneAcc_date_select_div cursor-pointer"
                  style={{
                    borderTopRightRadius: showDatePicker ? "7px" : "none",
                    borderRight: showDatePicker ? "1px solid #ff5860" : "none",
                  }}
                  // onClick={() => setShowDatePicker((prev) => !prev)}
                >
                  <div className="beneAcc_date_heading_rightm">
                    {endDate ? formatDate(endDate) : "Select to date"}
                  </div>
                </div>
              </div>
              {showDatePicker && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  ref={dateRef}
                >
                  <DatePickerComponent
                    startDate={startDate}
                    endDate={endDate}
                    handleDateChange={handleDateChange}
                    handleCancel={handleCancel}
                    handleConfirm={handleConfirm}
                    showDatePicker={showDatePicker}
                    dateRef={dateRef}
                    setShowDatePicker={setShowDatePicker}
                    isOpen={isOpen}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="beneAccFilterbuttonContainer">
            <div
              className={`acc-viewall-clear-filter border border-[#F9F9F9] text-[#FFFFFF] text-xs text-center ${
                isNoCategorySelected === true ||
                balanceType === "Pan" ||
                balanceType === "Bank"
                  ? ""
                  : "hover:bg-custom-cardHover"
              } w-[87px] h-[25px]`}
              onClick={(e) => {
                if (
                  initCategory ||
                  startDate ||
                  endDate ||
                  searchAccountText ||
                  searchIfscText ||
                  searchPanText
                ) {
                  clearFilter();
                  e.stopPropagation();
                }
              }}
              style={{
                cursor:
                  associateAccount ||
                  BeneTxnAssocAcct ||
                  associateTxn === "associateTxn"
                    ? internalCursorEnable()
                    : initCategory !== "" ||
                      startDate !== null ||
                      endDate !== null ||
                      searchAccountText !== "" ||
                      searchIfscText !== "" ||
                      searchPanText !== ""
                    ? "pointer"
                    : "default",
                opacity:
                  associateAccount ||
                  BeneTxnAssocAcct ||
                  associateTxn === "associateTxn"
                    ? internalCursorEnable("opacity")
                    : initCategory !== "" ||
                      startDate !== null ||
                      endDate !== null ||
                      searchAccountText !== "" ||
                      searchIfscText !== "" ||
                      searchPanText !== ""
                    ? 1
                    : 0.5,
              }}
            >
              Clear
            </div>
            <div
              ref={applyBtnRef}
              className={`acc-viewall-apply-filter text-center text-[#FFFFFF] text-xs ml-[10px] w-[87px] h-[25px]  ${
                isNoCategorySelected === true ? "" : "hover:bg-custom-cardHover"
              }`}
              onClick={(e) => {
                if (
                  initCategory ||
                  (startDate && endDate) ||
                  (searchAccountText && searchIfscText) ||
                  searchPanText
                ) {
                  filterCategory();
                  e.stopPropagation();
                }
              }}
              style={{
                // backgroundColor: handleFilterEnable() ? "#F05A6A" : "#727272",
                backgroundColor: getBgColor(),
                cursor: "pointer",
                pointerEvents: isFilterCallable(),
              }}
            >
              Apply
            </div>
          </div>
        </div>
      </div>
      <div
        id="mobileviewd"
        className={`beneAccviewallsidebarm ${isOpen ? "open" : "closed"}`}
        style={{
          marginTop: isOpen ? "68px" : "",
        }}
      >
        <div style={{ height: "76vh", overflowX: "hidden", overflowY: "auto" }}>
          <div className="row">
            <div
              className="col-xl-12"
              style={{ padding: "20px 20px 10px 20px" }}
            >
              <span className="text-[#F05A6A] font-normal">Filter</span>
            </div>
          </div>
          <div className="row">
            <div
              className="col-xl-12"
              style={{
                padding: "10px 20px",
                display: isOpen ? "block" : "none",
              }}
            >
              <span
                style={{
                  color: "#C9C9C9",
                  display: "inline-block",
                  // marginTop: "20px",
                  marginBottom: "0.6rem",
                  // width: "113px",
                  height: "17px",
                  fontSize: "12px",
                }}
              >
                Date range
              </span>
              <div
                className="bene_Txn_Calendar_main_div relative flex flex-col items-center bg-[#667CA3] cursor-pointer"
                // style={{ opacity: dropdownOpenm ? 0 : 1 }}
                ref={dateRef}
                style={{
                  width: "100%",
                  height: showDatePicker ? "" : "35px",
                  border: showDatePicker ? "1px solid #ff5860" : "none",
                  // borderRadius: showDatePicker ? "10px" : "auto",
                }}
              >
                <div
                  className="flex flex-row items-center justify-between rounded-xl bg-[#667CA3] cursor-pointer"
                  style={{
                    width: "100%",
                    height: showDatePicker ? "" : "39px",
                    // border: showDatePicker ? "none" : "1px solid #ff5860",
                    // marginTop: "10px",
                  }}
                  onClick={(e) => {
                    setShowDatePicker((prevState) => !prevState);
                    setdropdownOpenm(false);
                    setActiveSection("date");
                    e.stopPropagation();
                  }}
                  // style={{ opacity: dropdownOpenm ? 0 : 1 }}
                >
                  <div
                    className="beneTxn_date_heading_div cursor-pointer"
                    style={{
                      borderTopLeftRadius: showDatePicker ? "7px" : "none",
                      // borderLeft: showDatePicker ? "1px solid black" : "none",
                    }}
                    // onClick={() => setShowDatePicker((prev) => !prev)}
                  >
                    <div className="beneAcc_date_heading_left">
                      {startDate ? formatDate(startDate) : "Select from date"}
                    </div>
                  </div>
                  <img
                    src={arrowforward}
                    className="bene_txn_viewall_calendar_icon"
                  />
                  <div
                    className="beneTxn_date_select_div cursor-pointer"
                    style={{
                      borderTopRightRadius: showDatePicker ? "7px" : "none",
                    }}
                    // onClick={() => setShowDatePicker((prev) => !prev)}
                  >
                    <div className="beneAcc_date_heading_right">
                      {" "}
                      {endDate ? formatDate(endDate) : "Select to date"}
                    </div>
                  </div>
                </div>

                {showDatePicker && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    ref={sidebarRef}
                  >
                    <DatePickerComponent
                      startDate={startDate}
                      endDate={endDate}
                      handleDateChange={handleDateChange}
                      handleCancel={handleCancel}
                      handleConfirm={handleConfirm}
                      showDatePicker={showDatePicker}
                      dateRef={dateRef}
                      setShowDatePicker={setShowDatePicker}
                      isOpen={isOpen}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="row"
            style={{ display: flow === "internalTxn" ? "none" : "flex" }}
          >
            {associateAccount !== true &&
            BeneTxnAssocAcct !== true &&
            internalAccount !== "internalAccount" ? (
              <div className="col-xl-12" style={{ padding: "10px 20px" }}>
                <p
                  style={{
                    color: "#C9C9C9",
                    display: "inline-block",
                    marginTop: "5px",
                    height: "17px",
                    fontSize: "12px",
                    width: "200px",
                  }}
                >
                  Beneficiary Category
                </p>

                <div
                  className="bene_acc_category_selectm mt-[10px]"
                  ref={categorydropdownRefm}
                  onClick={() => {
                    setdropdownOpenm(!dropdownOpenm);
                    setIsDropdownOpen(false);
                    setShowDatePicker(false); // Close date picker if it's open
                  }}
                  style={{
                    height: dropdownOpenm ? "198px" : "35px",
                    outline: dropdownOpenm ? "1px solid #E25869" : "",
                    transition: dropdownOpenm ? "none" : "",
                    position: "relative",
                  }}
                >
                  <div className="bene_txn_category_select_select">
                    <div
                      className="txn_category_select_scroll_linem"
                      style={{ display: dropdownOpenm ? "block" : "none" }}
                    ></div>

                    <span
                      style={{
                        fontSize: "12px",
                        flex: "auto",
                        paddingRight: "30px",
                        paddingLeft: dropdownOpenm ? "16px" : "15px",
                        color: initCategory ? "#f9f9f9" : "#dadada",
                      }}
                    >
                      {initCategory || "Select a category"}
                    </span>
                    <div className="flex flex-row items-center">
                      <img
                        src={clear_filter}
                        alt="clear"
                        className="w-[9px] h-[9px]"
                        style={{
                          visibility:
                            initCategory && isOpen ? "visible" : "hidden",
                        }}
                        onClick={(e) => {
                          setInitCategory("");
                          e.stopPropagation();
                        }}
                      />
                      <div
                        className="w-[1px] h-[16px] mx-[8px]"
                        style={{
                          backgroundColor: "var(--Blue_3)",
                          visibility:
                            initCategory && isOpen ? "visible" : "hidden",
                        }}
                      ></div>
                      <img
                        src={arrow_down_red}
                        alt="down"
                        className="w-[11px] h-[11px]"
                        style={{
                          transform: dropdownOpen
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    </div>
                  </div>

                  {dropdownOpenm && (
                    <div className="txn_category_select_scrollm">
                      <div className="bene_input_div_elementm">
                        <img
                          src={search}
                          alt="search"
                          className="search_owner_element"
                          onClick={(e) => {
                            e.stopPropagation();
                            searchRef.current.focus();
                          }}
                        />
                        <input
                          ref={searchRef}
                          type="text"
                          value={categorySearch}
                          onChange={handleCategorySearchChange}
                          placeholder="Search"
                          onClick={(e) => e.stopPropagation()}
                          className="placeholder-[#dadada] text-xs"
                        />
                        <img
                          src={cancel}
                          alt="cancel"
                          className="search_owner_element"
                          onClick={(e) => {
                            e.stopPropagation();
                            setCategorySearch("");
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>

                      <div className="bene_txn_sort_by_category_scrollm">
                        {categories
                          .filter((fil) => {
                            return fil?.name
                              ?.toLowerCase()
                              .includes(categorySearch.toLowerCase());
                          })
                          .map((category) => (
                            <div key={category.id} className="category-wrapper">
                              <Tooltip
                                key={category.id}
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      zIndex: "2147483647 !important",
                                    },
                                  },
                                }}
                                title={category.name}
                              >
                                <button
                                  className="bene_acc_category_select_scroll_com_m"
                                  onClick={(e) => {
                                    handleClick(category.name);
                                    e.stopPropagation();
                                  }}
                                >
                                  <span className="w-[270px] pl-[4px] truncate ...">
                                    {category.name}
                                  </span>
                                </button>
                              </Tooltip>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="col-xl-12" style={{ padding: "10px 20px" }}>
              <div
                className="relative w-[100%]"
                style={{
                  marginTop: dropdownOpenm
                    ? "185px"
                    : associateAccount === true ||
                      associateTxn === "associateTxn" ||
                      internalAccount === "internalAccount"
                    ? "0px"
                    : "35px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveSection("pannumber");
                  setdropdownOpenm(false);
                  setShowDatePicker(false);
                }}
              >
                <span
                  style={{
                    color: "#C9C9C9",
                    display: "inline-block",
                    // marginTop: "20px",
                    // width: "113px",
                    height: "17px",
                    fontSize: "12px",
                  }}
                >
                  PAN number
                </span>
                <input
                  ref={searchInputRef}
                  className="custom-autofill w-[100%] h-[35px] px-4 py-2.5 rounded-xl bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] text-xs mt-[5px]"
                  type="text"
                  placeholder="Enter PAN number"
                  value={searchPanText}
                  name="pannumber"
                  onChange={handleInput}
                  onFocus={() => setActiveSection("pannumber")}
                  disabled={
                    balanceType === "Pan" &&
                    (associateAccount ||
                      BeneTxnAssocAcct ||
                      associateTxn === "associateTxn")
                  }
                  style={{
                    opacity:
                      balanceType === "Pan" &&
                      (associateAccount ||
                        BeneTxnAssocAcct ||
                        associateTxn === "associateTxn")
                        ? "0.6"
                        : "1",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12" style={{ padding: "10px 20px" }}>
              <div
                className="w-[100%]"
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveSection("accountnumber");
                  setdropdownOpenm(false);
                  setShowDatePicker(false);
                }}
              >
                <span
                  style={{
                    color: "#C9C9C9",
                    width: "35px",
                    height: "19px",
                    font: "normal normal 12px/19px Open Sans",
                  }}
                >
                  Account number
                </span>
                <input
                  ref={searchInputRef}
                  className="custom-autofill w-[100%] h-[35px] px-4 py-2.5 rounded-xl bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] text-xs mt-[5px]"
                  type="text"
                  placeholder="Enter account number"
                  value={searchAccountText}
                  name="accountnumber"
                  onChange={handleInput}
                  onFocus={() => setActiveSection("accountnumber")}
                />
                {/* {searchAccountText && (
                    <img
                      src={closeIcon}
                      alt="Clear"
                      className="bene-acc-viewall-search-close-image absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      onClick={() => {
                        setSearchAccountText("");
                        searchInputRef.current.focus();
                      }}
                    />
                  )}{" "}
                  {!searchAccountText && (
                    <img
                      src={search}
                      alt="search"
                      className="bene-acc-viewall-search-inner-image absolute right-[14px] w-4 h-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    />
                  )} */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12" style={{ padding: "10px 20px" }}>
              <div
                className="relative w-[100%]"
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveSection("ifscnumber");
                  setdropdownOpenm(false);
                  setShowDatePicker(false);
                }}
              >
                <span
                  style={{
                    color: "#C9C9C9",
                    width: "35px",
                    height: "19px",
                    font: "normal normal 12px/19px Open Sans",
                  }}
                >
                  IFSC code
                </span>
                <input
                  ref={searchInputRef}
                  className="custom-autofill w-[100%] h-[35px] px-4 py-2.5 rounded-xl bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] text-xs mt-[5px] mb-[20px]"
                  type="text"
                  placeholder="Enter IFSC code"
                  value={searchIfscText}
                  name="ifscnumber"
                  onChange={handleInput}
                  onFocus={() => setActiveSection("ifscnumber")}
                />
                {/* {searchAccountText && (
                    <img
                      src={closeIcon}
                      alt="Clear"
                      className="bene-acc-viewall-search-close-image absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      onClick={() => {
                        setSearchAccountText("");
                        searchInputRef.current.focus();
                      }}
                    />
                  )}{" "}
                  {!searchAccountText && (
                    <img
                      src={search}
                      alt="search"
                      className="bene-acc-viewall-search-inner-image absolute right-[14px] w-4 h-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    />
                  )} */}
              </div>
              <span
                style={{
                  color: "#B4B4B4",
                  width: "208px",
                  height: "34px",
                  font: "normal normal 12px/19px Open Sans",
                  paddingRight: "8px",
                }}
              >
                Note: Account number and IFSC code must be filled to filter by
                bank details
              </span>
            </div>
          </div>
        </div>
        <div className="beneaccFilterbuttonContainerm">
          <div style={{ width: "max-content", margin: "10px auto" }}>
            <div
              className={`acc-viewall-clear-filter border border-[#F9F9F9] text-[#FFFFFF] text-xs text-center ${
                isNoCategorySelected === true ? "" : "hover:bg-custom-cardHover"
              }
              ${
                associateAccount || associateTxn === "associateTxn"
                  ? internalCursorOpacity()
                  : ""
              } w-[87px] h-[25px]`}
              onClick={() => {
                if (
                  initCategory ||
                  startDate ||
                  endDate ||
                  searchAccountText ||
                  searchIfscText ||
                  searchPanText
                ) {
                  clearFilter();
                }
              }}
              style={{
                float: "left",
                cursor:
                  initCategory !== "" ||
                  startDate !== null ||
                  endDate !== null ||
                  searchAccountText !== "" ||
                  searchIfscText !== "" ||
                  searchPanText !== ""
                    ? "pointer"
                    : "default",
                // opacity: dropdownOpenm ? 0 : 1,
              }}
            >
              Clear
            </div>
            <div
              className={`acc-viewall-apply-filter text-center text-[#FFFFFF] text-xs ml-[10px] w-[87px] h-[25px]  ${
                isNoCategorySelected === true ? "" : "hover:bg-custom-cardHover"
              }`}
              onClick={() => {
                if (
                  initCategory ||
                  (startDate && endDate) ||
                  (searchAccountText && searchIfscText) ||
                  searchPanText
                ) {
                  filterCategorym();
                }
              }}
              style={{
                float: "left",
                backgroundColor: handleFilterEnable() ? "#F05A6A" : "#727272",
                cursor: handleFilterEnable() ? "pointer" : "default",
              }}
            >
              Apply
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneAccFilterSidebar;
