import React, { useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import FormatAmount from "../FormatAmount/FormatAmount";

const MinimumBal = ({ setMinBalance, amount, setAmount }) => {
  const [showRupeeSymbol, setShowRupeeSymbol] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;

    if (/^\d{0,10}$/.test(value)) {
      setAmount(value);
      setShowRupeeSymbol(true);
    }
  };

  const handleSet = (value) => {
    localStorage.setItem("minBalanceAmount", value);
    setAmount(value);
    setMinBalance(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSet(amount);
    }
  };

  return (
    <div className="w-[560px] bg-Blue_3 rounded-[20px] mx-4">
      <div className="p-6">
        <p className="w-auto h-auto font-semibold text-custom-orange mb-1">
          Set minimum balance
        </p>
        <p className="font-normal text-Primary_white mb-6">
          The required minimum balance to be maintained in this account and
          cannot be utilized for any transactions.
        </p>
        <TextField
          label="Enter amount *"
          style={{
            marginBottom: "25px",
          }}
          value={amount}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: showRupeeSymbol && (
              <InputAdornment position="start">
                <span
                  style={{
                    fontSize: "16px",
                    color: "var(--Primary_white)",
                    fontWeight: "400px",
                    marginLeft: "10px",
                  }}
                >
                  ₹
                </span>
              </InputAdornment>
            ),
          }}
          size="small"
          sx={{
            "& label": {
              color: amount
                ? "#00FF00 !important"
                : "var(--Primary_grey) !important",
              padding: "0px",
              fontSize: "16px !important",
              textTransform: "none !important",
            },
            "& input": {
              color: "var(--Primary_white)",
            },
            "& fieldset": {
              borderRadius: "10px !important",
              borderColor: amount
                ? "var(--Settled) !important"
                : "var(--Primary_grey) !important",
              borderWidth: "2px",
            },
          }}
        />
        <div className="text-right mt-1">
          <button
            className="text-Primary_white mr-6 underline"
            onClick={() => setMinBalance(false)}
          >
            Cancel
          </button>
          <button
            className="w-[79px] h-[32px] rounded-2xl bg-Button_green text-Primary_white"
            onClick={() => handleSet(amount)}
          >
            Set
          </button>
        </div>
      </div>
    </div>
  );
};

export default MinimumBal;
