import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import OwnerViewAllCard from "../OwnerViewAllCard/index";
import ListView from "../../../../components/Accounts/OwnerAccounts/listView/index";
import RefreshAllLoader from "../../../../components/Accounts/OwnerAccounts/RefreshAllLoader/index";
import { Box, tooltipClasses, Tooltip } from "@mui/material";
import RectangleSearchBar from "../../../../components/_utils/RectangleSearchBar/index";
import SelectBank from "../../../../components/_utils/SelectBank";
import TypeOfAccountDropdown from "../../../../components/_utils/TypeOfAccountDropdown/index";
import SearchIcon from "../../../../assets/FilterSearchIcons/search_white.svg";
import SearchBlue from "../../../../assets/FilterSearchIcons/search_blue.svg";
import RefreshBalanceToast from "../../../../components/_utils/RefreshBalanceToast/index";
import PaginationComponent from "../../../../components/_utils/PaginationComponent/index";
import selectWhiteCircle from "../../../../../src/assets/CheckmarksIcons/select_all_with_white_outlined_circle.svg";
import selectWithGreenFilledCircle from "../../../../../src/assets/CheckmarksIcons/selected_with_green_circle.svg";
import SelectAccountBalance from "../../../../components/SelectAccountBalance/index";
import {
  ownerListViewAllAcc,
  getOwnerAccBalance,
  setshowsuccestoast,
  setToggle,
  removeOwnerAccTags,
  ownerAccDetail,
  addOwnerAccTags,
  setTags,
  resetToggle,
} from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { useDispatch, useSelector } from "react-redux";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import { toast } from "react-toastify";
import ConnectedBankingDropdown from "../../../../components/_utils/connectedBankingDropdopwn/selectConnectedBanking";
import noaccount from "../../../../assets/Graphics/No transaction found.svg";
import { useLocation, useNavigate } from "react-router-dom";
import dataLoading from "../../../../assets/LoaderIcons/Loader.gif";
import Refreshicon from "../../../../assets/RefreshIcons/reload-refresh_white.svg";
import GridBlue from "../../../../assets/ViewIcons/Grid view blue.svg";
import GridWhite from "../../../../assets/ViewIcons/Grid view white.svg";
import ListBlue from "../../../../assets/ViewIcons/list_view_blue.svg";
import ListWhite from "../../../../assets/ViewIcons/list_view_white.svg";
import clearFilter from "../../../../assets/CloseIcons/close_with_blue_circle.svg";
import { checkUsertoken } from "../../../../redux/features/login/login";
import ArrowDown from "../../../../assets/ArrowsIcons/arrow_down_white.svg";
import ArrowUp from "../../../../assets/ArrowsIcons/arrow_up_white.svg";
import ArrowDown8 from "../../../../assets/ArrowsIcons/arrow_down_blue.svg";
import ArrowUp4 from "../../../../assets/ArrowsIcons/arrow_up_blue.svg";
import Arrowdonw5 from "../../../../assets/ArrowsIcons/arrow_down_white_filled.svg";
import FilterBlue from "../../../../assets/FilterSearchIcons/filter_blue.svg";
import Filter from "../../../../assets/FilterSearchIcons/filter.svg";
import SplitView1 from "../../../../assets/ViewIcons/Split View.svg";
import SplitView2 from "../../../../assets/ViewIcons/Split View Color.svg";
import Cancel from "../../../../assets/CloseIcons/close.svg";
import Info from "../../../../assets/InfoIcons/Info.svg";
import Validation from "../../../../utils/validation";
import formatTotalBalance from "../../../../utils/formatTotalBalance";
import FormatAmount from "../../../../components/_utils/FormatAmount/FormatAmount";
import AnimationUpAndDown from "../../../../components/BeneTransaction/OwnerPopup/AnimationUpAndDown";
import { getRefreshTokenNew } from "../../../../redux/features/refreshToken/refreshToken";
import OwnerDetailsSelectTag from "../../../../components/Accounts/OwnerAccounts/OwnerDetailsSelectTag";
import OwnerViewStatementModal from "../../../../components/Accounts/OwnerAccounts/OwnerViewStatementModal";
import ArrowLeft from "../../../../assets/ArrowsIcons/ChevronLeftWhite.svg";
import assignTagImage from "../../../../assets/GeneralIcons/assign_tag.svg";
import OwnerAllStatement from "../OwnerAllStatement";
import UserTag from "../../../../components/UserTag";
import { beneViewAllAcc } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import Loader from "../../../../components/Loader/Index";
import { set } from "lodash";

const OwnerAccViewAll = ({
  typepop,
  setOpenOwner,
  setIsNeft,
  setIsImps,
  setIsRtgs,
  txnPayLoad,
  setTxnPayLoad,
  BenEnableNextButton,
  handleButtonClick,
  onAccountSelect,
  TypeAccount,
  flow,
  owneraccountNumber,
  setownerAccountNumber,
  owneraccountnumberstate,
  setowneraccountnumberstate,
  multiSelect,
  onClosed,
  setDefaultAccountNumber,
  owneraccountDefaultNumber,
  popType,
  handlePopupClose,
}) => {
  const location = useLocation();
  if (typepop === "popup") {
    location.state = null;
  }

  const dropdownOptions = [50, 100];
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [accumulatedData, setAccumulatedData] = useState([]);
  const [bankOptions, setBankOptions] = useState([]);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [connectedBankingData, setConnectedBankingData] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [previd, setID] = useState(false);
  const [searchUserText, setSearchUserText] = useState("");
  const [mainData, setMainData] = useState([]);
  const [selectSortAmt, setSelectSortAmt] = useState(false);
  const [selectedTagOpenPop, setSelectedTagOpenPop] = useState(false);
  const [selectTypeOfAccount, setSelectTypeofAccount] = useState(false);
  const [showTotalBalancePopup, setShowTotalBalancePopup] = useState(false);
  const [filterApply, setFilterApply] = useState(
    location?.state ? true : false
  );
  const [accData, setAccData] = useState("");
  const [viewStatementOpenPop, setViewstatementOpenPop] = useState(false);
  const [statementId, setStatementId] = useState("");
  const [total_balance_state, setTotal_balance_state] = useState(0);
  const [prev_total_balance_state, setPrev_Total_balance_state] = useState(0);
  const [refreshAllData, setRefreshAllData] = useState([]);
  const [successRefreshId, setSuccessRefreshId] = useState([]);
  const [failedRefreshId, setFailedRefreshId] = useState([]);
  const [refreshType, setRefreshtype] = useState("");
  const [selectAmtType, setSelectAmtType] = useState("Default");
  const [refreshAll, setRefreshAll] = useState(false);
  const [splitView, setSplitView] = useState("");
  const [selectConnectedBanking, setSelectConnectedBanking] = useState(false);
  const [showTotalBalanceDiff, setShowTotalBalanceDiff] = useState(false);
  const [newTags, setNewTags] = useState([]);
  const [showSelectBank, setShowSelectBank] = useState(false);
  const [pageSize, setPageSize] = useState(dropdownOptions[0]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  const [connectedbankingstate, setConnectedbankingstate] = useState(
    location?.state ? location?.state?.approvalStatus : ""
  );
  const [typeaccountstate, settypeaccountstate] = useState("");
  const [bankstate, setbankstate] = useState("");
  const [accountbalancestate, setaccountbalancestate] = useState("");
  const [accountnumberstate, setaccountnumberstate] = useState("");
  const [ifscstate, setifscstate] = useState("");
  const [panstate, setpanstate] = useState("");
  const [ownerTagState, setOwnerTagState] = useState([]);
  const [isAssignTagsClicked, setIsAssignTagsClicked] = useState(false);
  const [assignTagsAccounts, setAssignTagsAccounts] = useState([]);
  const [newSelectedTags, setNewSelectedTags] = useState([]);
  const [AllTags, setAllTags] = useState([]);
  const [isTagsPopupOpen, setIsTagsPopupOpen] = useState(false);
  const [selectedAssignedAccountID, setSelectedAssignedAccountID] =
    useState("");
  const [selectedAccount, setSelectedAccount] = useState(
    "All selected accounts"
  );
  const [calculateNewTagsValues, setCalculateNewTagsValues] = useState(false);
  const [allData, setAllData] = useState([]);
  const [backupAllData, setBackupAllData] = useState(allData);

  const [filterPayload, setFilterPayload] = useState({
    pagination: 0,
    accNoInput: "",
    sortBank: "",
    sortAccountNo: "",
    bankNameInput: "",
    connectedBanking:
      typepop === "popup"
        ? "Active"
        : location?.state
        ? location?.state.approvalStatus
        : "",
    typeofaccount: "",
    accountnumber: "",
    ifscnumber: "",
    pannumber: "",
    ownerTags: [],
    panFilter: false,
    BankFilter: false,
    TypeAccFilter: false,
    ConnectedFilter: location?.state ? true : false,
    AccIfscFilter: false,
    SortBankFilter: false,
    TagFilter: false,
  });

  const containerRef = useRef(null);

  const handleScrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const services = useSelector((state) => state.refreshToken.services);

  const {
    ownerAccBalanceLoading,
    ownerAccBalanceError,
    toggle,
    showSuccessToast,
    storedPage,
    removeOwnerAccTagsLoading,
  } = useSelector((state) => state.ownerAllVerification);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filterRef = useRef(null);
  const filterButtonRef = useRef(null);
  const balanceRef = useRef(null);
  const balanceButtonRef = useRef(null);
  const searchRef = useRef(null);
  const searchButtonRef = useRef(null);
  const totalBalanceRef = useRef(null);
  const applyButtonRef = useRef(null);

  useEffect(() => {
    if (Number(storedPage) > 1) {
      setCurrentPage(Number(storedPage));
    }
  }, []);

  useEffect(() => {
    fetchData(filterApply);
  }, [currentPage, toggle, searchUserText, pageSize, calculateNewTagsValues]);

  useEffect(() => {
    if (typepop === "popup" && flow !== "internalTxn") {
      setFilterApply(true);

      setConnectedbankingstate("Active");
    }
  }, []);
  let availableTags = mainData?.summary?.available_tags || tagsOptions || []; // ✅ Ensured default empty array

  const selectedTagsCards = accumulatedData?.filter((account) =>
    assignTagsAccounts.includes(account.id)
  );

  // const getOwnerDetaildata = async () => {
  //   setLoading(true);
  //   try {
  //     dispatch(checkUsertoken());
  //     const response = await dispatch(ownerAccDetail(id));

  //     dispatch(
  //       beneViewAllAcc({
  //         bank_account_no: response?.payload?.owner_accounts[0]?.bank_acc_no,
  //         ifsc: response?.payload?.owner_accounts[0]?.ifsc_code,
  //         pan: response?.payload?.owner_accounts[0]?.pan_no,
  //         type: "internalAccount",
  //       })
  //     );

  //     // setOwnerdetaildata(response?.payload?.owner_accounts[0]);

  //     if (
  //       response?.payload?.owner_accounts[0]?.tags &&
  //       response?.payload?.owner_accounts[0]?.tags.length > 0
  //     ) {
  //       const data = response?.payload?.owner_accounts[0].tags?.map((res) => {
  //         return { selected: true, value: res, type: "ownerTags" };
  //       });

  //       setNewTags(data);
  //       setLoading(true);
  //     }

  //     setLoading(false);
  //     if (response?.error?.message == "Request failed with status code 404") {
  //       navigate("*");
  //     }

  //     if (response?.payload === undefined) {
  //       navigate("/feature_not_assigned");
  //     } else {
  //       console.log("No user unauthorized error.");
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  // console.log(AllTags, "AllTags");
  const fetchData = async (val) => {
    let applied = val ? val : false;
    setFailedRefreshId([]);
    setSuccessRefreshId([]);
    setRefreshtype(false);

    try {
      setLoading(true);
      dispatch(checkUsertoken());
      let response = await dispatch(
        ownerListViewAllAcc({
          page_no: currentPage,
          page_size: pageSize,
          search_string: searchUserText.length > 0 ? searchUserText : undefined,
          type_of_account: applied
            ? typeaccountstate.length > 0
              ? typeaccountstate
              : undefined
            : undefined,
          bank_name: applied
            ? bankstate.length > 0
              ? bankstate
              : undefined
            : undefined,
          connected_banking:
            typepop === "popup" && flow !== "internalTxn"
              ? "Y"
              : applied
              ? connectedbankingstate.length > 0
                ? connectedbankingstate === "Active"
                  ? "Y"
                  : "N"
                : undefined
              : undefined,
          bank_account_no: applied
            ? accountnumberstate.length > 3
              ? accountnumberstate
              : undefined
            : undefined,
          ifsc: applied
            ? ifscstate.length > 10
              ? ifscstate
              : undefined
            : undefined,
          pan: applied
            ? panstate.length > 9
              ? panstate
              : undefined
            : undefined,
          sort_by_balance: applied
            ? accountbalancestate.length > 0
              ? accountbalancestate
              : undefined
            : undefined,
          tag: applied
            ? ownerTagState.length > 0
              ? ownerTagState
              : undefined
            : undefined,
        })
      );
      if (
        (!applied && searchUserText.length == 0 && bankOptions.length === 0) ||
        (location?.state &&
          searchUserText.length == 0 &&
          bankOptions.length === 0)
      ) {
        setTagsOptions(response?.payload?.summary?.available_tags || []);
        setBankOptions(response?.payload?.summary?.available_banks || []);
      } else {
        if (sessionStorage?.getItem("AvailableTags")) {
          let val = sessionStorage?.getItem("AvailableTags");
          setTagsOptions(JSON.parse(val) || []);
        }
      }
      const newData = response?.payload?.owner_accounts || [];
      setMainData(response?.payload);
      setTotal_balance_state(Number(response?.payload?.total_balance || 0));
      setAccumulatedData(newData);
      setAllData((prev) => {
        return [
          ...new Map(
            [...prev, ...newData].map((item) => [item["id"], item])
          ).values(),
        ];
      });
      setConnectedBankingData(
        newData.filter((account) => {
          return account.connected_banking === "Y";
        })
      );
      setLoading(false);

      if (
        response?.payload?.response?.data?.errors?.user_unauthorized?.length > 0
      ) {
        navigate("/feature_not_assigned", {
          state: { unavailableService: "VAccount" },
        });
      }
    } catch (error) {
      setAccumulatedData([]);
      setMainData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [filterOpen]);

  const handleSelect = (value) => {
    setCurrentPage(1);
    setSelectedValue(value);
    setPageSize(value);
  };

  useEffect(() => {
    if (ownerAccBalanceLoading) {
      RefreshBalanceToast();
    }
    if (!ownerAccBalanceLoading && !ownerAccBalanceError) {
      setTimeout(() => {
        toast.dismiss();

        if (showSuccessToast) {
          setTimeout(() => {
            toast.success("Balance refreshed successfully", {
              position: "bottom-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            dispatch(setshowsuccestoast());
          }, 250);
        }
      }, 500);
    }
  }, [ownerAccBalanceLoading]);

  const handleAddTags = async (item) => {
    try {
      dispatch(checkUsertoken());

      const selectedTags = newTags
        .filter((tag) => tag.selected === false)
        .map((tag) => tag.value);

      let tagsToAdd;
      if (typeof item === "string") {
        tagsToAdd = [item];
      } else if (item?.type === "newAss" && Array.isArray(item?.data1)) {
        tagsToAdd = item.data1;
      } else {
        throw new Error("Invalid item format");
      }

      const response = await dispatch(
        addOwnerAccTags({
          owner_id:
            selectedAssignedAccountID !== ""
              ? [selectedAssignedAccountID]
              : [...assignTagsAccounts],
          tags: tagsToAdd,
        })
      );

      if (response?.payload?.status === true) {
        // setNewSelectedTags((prev) => [
        //   ...prev,
        //   ...tagsToAdd.map((tag) => ({
        //     value: tag,
        //     selected: true, // or false, depending on your requirement
        //     type: "ownerTags", // or the appropriate type
        //   })),
        // ]);

        // setCalculateNewTagsValues((prev) => !prev);
        // toast.success("Tags added successfully.", toast_position);
        fetchData();
        setLoading(true);
      } else {
        setLoading(false);
        // const errorMessage =
        //   response?.payload?.message || "Failed to add tags.";
        // toast.error(errorMessage);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error adding tags:", error);
      toast.error(error.message || "An error occurred while adding the tags.");
    }
  };

  const handleRemoveTags = async (item) => {
    setLoading(true);

    try {
      dispatch(checkUsertoken());

      const response = await dispatch(
        removeOwnerAccTags({
          owner_id:
            selectedAssignedAccountID !== ""
              ? [selectedAssignedAccountID]
              : [...assignTagsAccounts],
          tags: item,
        })
      );

      if (response?.payload?.status === true) {
        // toast.success("Tags removed successfully.", toast_position);

        await fetchData();
        setLoading(true);
      } else {
        setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error removing tag:", error);
    }
  };

  const handleApplyEnable = () => {
    if (
      filterPayload?.pannumber?.length > 0 &&
      filterPayload?.pannumber?.length !== 10 &&
      panstate.length < 10
    ) {
      return false;
    } else if (
      filterPayload?.accountnumber?.length > 0 &&
      accountnumberstate.length < 9 &&
      filterPayload.ifscnumber?.length !== 11 &&
      ifscstate.length < 11
    ) {
      return false;
    } else if (
      filterPayload?.accountnumber?.length < 9 &&
      accountnumberstate.length < 9 &&
      filterPayload?.ifscnumber?.length > 10 &&
      ifscstate.length < 11
    ) {
      return false;
    } else if (
      filterPayload?.accountnumber?.length === 0 &&
      accountnumberstate.length < 4 &&
      filterPayload?.ifscnumber?.length > 0 &&
      ifscstate.length < 11
    ) {
      return false;
    } else if (
      filterPayload?.sortAccountNo?.length == 0 &&
      accountbalancestate.length === 0 &&
      filterPayload?.pannumber?.length == 0 &&
      panstate.length < 10 &&
      (filterPayload?.connectedBanking?.length == 0 || typepop === "popup") &&
      (connectedbankingstate.length == 0 || typepop === "popup") &&
      filterPayload?.typeofaccount?.length == 0 &&
      typeaccountstate.length == 0 &&
      filterPayload?.bankNameInput?.length == 0 &&
      bankstate.length == 0 &&
      filterPayload?.accountnumber?.length == 0 &&
      accountnumberstate.length == 0 &&
      filterPayload?.ifscnumber?.length == 0 &&
      ifscstate.length == 0 &&
      filterPayload?.ownerTags?.length == 0 &&
      ownerTagState.length == 0
    ) {
      return false;
    }
    return true;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
    }
    if (event.key === "Enter" && filterOpen) {
      applyButtonRef.current.click();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    if (typepop === "popup") {
      dispatch(resetToggle());
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [filterOpen]);

  const handleRefresh = async (id) => {
    try {
      dispatch(checkUsertoken());
      let response = await dispatch(getOwnerAccBalance(id));
      if (response?.payload?.status == true) {
        setSuccessRefreshId([id]);
        let balance = 0;
        let data = accumulatedData.map((item) => {
          if (response.payload.owner_accounts[0].owner_account_id === item.id) {
            balance =
              Number(total_balance_state) -
              Number(item.owner_account_balance) +
              Number(response.payload.owner_accounts[0].owner_account_balance);
            return {
              ...item,
              owner_account_balance_last_fetched_at_in_words:
                response.payload.owner_accounts[0]
                  .owner_account_balance_last_fetched_at_in_words,
              owner_account_balance:
                response.payload.owner_accounts[0].owner_account_balance,
              owner_account_balance_last_fetched_at:
                response.payload.owner_accounts[0]
                  .owner_account_balance_last_fetched_at,
              difference: Math.abs(
                Number(item.owner_account_balance) -
                  Number(
                    response.payload.owner_accounts[0].owner_account_balance
                  )
              ),
              increaseOrDecrease:
                Number(item.owner_account_balance) >
                Number(response.payload.owner_accounts[0].owner_account_balance)
                  ? "decreased"
                  : Number(item.owner_account_balance) <
                    Number(
                      response.payload.owner_accounts[0].owner_account_balance
                    )
                  ? "increased"
                  : "",
            };
          }
          return item;
        });
        setTotal_balance_state(Number(balance));
        setAccumulatedData(data);
      } else {
        setFailedRefreshId([id]);

        setTimeout(() => {
          toast.dismiss();
          toast.error(response?.error?.message, toast_position);
        }, 500);
      }
    } catch (error) {
      toast.error(error, toast_position);
      throw error;
    }
  };

  useEffect(() => {
    if (typepop === "popup" && flow !== "internalTxn" && handleApplyEnable()) {
      fetchData(true);
    }
    if (
      filterPayload?.typeofaccount?.length === 0 &&
      filterPayload?.sortAccountNo?.length === 0 &&
      filterPayload?.ifscnumber?.length === 0 &&
      filterPayload?.connectedBanking?.length === 0 &&
      filterPayload?.bankNameInput?.length === 0 &&
      filterPayload?.pannumber?.length === 0 &&
      filterPayload?.accountnumber?.length === 0 &&
      filterPayload?.ownerTags?.length === 0 &&
      connectedbankingstate?.length === 0 &&
      panstate?.length < 10 &&
      accountbalancestate?.length === 0 &&
      ownerTagState?.length === 0 &&
      typeaccountstate?.length === 0 &&
      bankstate?.length === 0 &&
      accountnumberstate?.length === 0 &&
      ifscstate?.length === 0 &&
      filterOpen &&
      filterApply
    ) {
      setTimeout(() => {
        if (typepop === "popup" && flow !== "internalTxn") {
          setFilterApply(true);
        } else {
          setFilterApply(false);
        }
        fetchData(false);
      }, 500);
    }
    if (
      filterPayload?.typeofaccount?.length > 0 &&
      filterPayload?.sortAccountNo?.length > 0 &&
      filterPayload?.ifscnumber?.length === 11 &&
      filterPayload?.connectedBanking?.length > 0 &&
      filterPayload?.bankNameInput?.length > 0 &&
      filterPayload?.pannumber?.length === 10 &&
      filterPayload?.accountnumber?.length > 8 &&
      filterPayload?.ownerTags?.length > 0 &&
      !filterOpen
    ) {
      fetchData(filterApply);
    }
    if (
      (filterPayload?.typeofaccount?.length === 0 ||
        filterPayload?.sortAccountNo?.length === 0 ||
        filterPayload?.ifscnumber?.length === 0 ||
        filterPayload?.connectedBanking?.length === 0 ||
        filterPayload?.bankNameInput?.length === 0 ||
        filterPayload?.pannumber?.length === 0 ||
        filterPayload?.ownerTags?.length === 0 ||
        filterPayload?.accountnumber?.length === 0) &&
      filterApply &&
      !filterOpen
    ) {
      fetchData(filterApply);
    }
    if (
      filterPayload?.typeofaccount?.length === 0 &&
      filterPayload?.sortAccountNo?.length === 0 &&
      filterPayload?.ifscnumber?.length === 0 &&
      filterPayload?.bankNameInput?.length === 0 &&
      filterPayload?.connectedBanking?.length === 0 &&
      filterPayload?.pannumber?.length === 0 &&
      filterPayload?.ownerTags?.length === 0 &&
      filterPayload?.accountnumber?.length === 0 &&
      connectedbankingstate?.length === 0 &&
      accountbalancestate?.length === 0 &&
      ownerTagState?.length === 0 &&
      typeaccountstate?.length === 0 &&
      bankstate?.length === 0 &&
      panstate?.length < 10 &&
      accountnumberstate?.length === 0 &&
      ifscstate?.length === 0 &&
      filterApply &&
      !filterOpen
    ) {
      if (typepop === "popup" && flow !== "internalTxn") {
        setFilterApply(true);
      } else {
        setFilterApply(false);
      }
      fetchData(false);
    }
  }, [filterPayload, dispatch, calculateNewTagsValues]);

  const filteredData = accumulatedData;
  const postiveBalanceData = accumulatedData.filter(
    (item) =>
      item.connected_banking == "Y" && Number(item.owner_account_balance) >= 0
  );
  const negativeBalanceData = accumulatedData.filter(
    (item) =>
      item.connected_banking == "Y" && Number(item.owner_account_balance) < 0
  );

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const allowedValue = (name, value) => {
    if (name === "accountnumber") return Validation.allowedBankAccNum(value);
    if (name === "ifscnumber") return Validation.allowedBankIfsc(value);
    if (name === "pannumber") return Validation.allowedPAN(value);
    return true;
  };

  const handleInput = (e) => {
    let { name, value } = e.target;
    let val = value.replaceAll(" ", "");
    if (allowedValue(name, val)) {
      const newObj = {
        ...filterPayload,
        [name]: val.toUpperCase(),
      };
      if (name == "accountnumber" && value == "") {
        newObj.ifscnumber = "";
        setFilterPayload(newObj);
      } else {
        setFilterPayload(newObj);
      }
    }
  };

  const handleViewStatement = () => {
    setViewstatementOpenPop(true);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    setSearchUserText(e.target.value);
  };

  useEffect(() => {
    const handlefilter = (e) => {
      if (filterRef.current && filterRef.current.contains(e.target)) {
      } else if (
        filterButtonRef.current &&
        filterButtonRef.current.contains(e.target)
      ) {
      } else {
        setFilterOpen(false);
      }
    };
    document.addEventListener("click", handlefilter);
  }, [filterRef]);

  useEffect(() => {
    const handlePopup = (e) => {
      if (balanceRef.current && balanceRef.current.contains(e.target)) {
      } else if (
        balanceButtonRef.current &&
        balanceButtonRef.current.contains(e.target)
      ) {
      } else {
        setShowTotalBalancePopup(false);
      }
    };
    document.addEventListener("click", handlePopup);
  }, [balanceRef]);

  useEffect(() => {
    const handleDropDown = (e) => {
      if (
        totalBalanceRef.current &&
        totalBalanceRef.current.contains(e.target)
      ) {
      } else {
        setShowTotalBalanceDiff(false);
      }
    };
    document.addEventListener("click", handleDropDown);
  }, [totalBalanceRef]);

  useEffect(() => {
    const handleDropDown = (e) => {
      if (searchRef.current && searchRef.current.contains(e.target)) {
      } else if (
        searchButtonRef.current &&
        searchButtonRef.current.contains(e.target)
      ) {
      } else {
        setShowSearchBar(false);
      }
    };
    document.addEventListener("click", handleDropDown);
  }, [searchRef]);

  const handleSelectAmt = () => {
    if (selectAmtType == "Default") {
      return "66px";
    } else if (selectAmtType == "Lakhs") {
      return "128px";
    } else if (selectAmtType == "Crores") {
      return "183px";
    } else if (selectAmtType == "Millions") {
      return "244px";
    } else if (selectAmtType == "Billions") {
      return "305px";
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (selectedAccount !== "All selected accounts") {
      const selectedAccountTags =
        allData?.find((account) => account.id === selectedAssignedAccountID)
          ?.tags || [];

      setNewSelectedTags(
        selectedAccountTags.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );

      setAllTags(
        availableTags?.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );
    } else if (
      selectedTagsCards?.length === 1 &&
      selectedAccount !== "All selected accounts"
    ) {
      const accountTags =
        allData?.find((account) => assignTagsAccounts.includes(account.id))
          ?.tags || [];
      setNewSelectedTags(
        accountTags.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );
      setAllTags(
        availableTags?.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );
    } else if (
      selectedTagsCards?.length > 1 ||
      selectedAccount === "All selected accounts"
    ) {
      let a = allData?.filter((account) =>
        assignTagsAccounts.includes(account.id)
      );
      const commonTags = a.reduce((common, account) => {
        return common.filter((tag) => account.tags?.includes(tag));
      }, a[0]?.tags || []);

      const uncommonTags = a.reduce((uncommon, account) => {
        return uncommon.concat(
          account.tags?.filter((tag) => !commonTags.includes(tag)) || []
        );
      }, []);

      setNewSelectedTags(
        commonTags.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );

      setAllTags(
        [...new Set([...availableTags, ...uncommonTags])].map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );
    } else {
      setNewSelectedTags([]);
      setAllTags(
        availableTags?.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );
    }
  }, [
    assignTagsAccounts,
    // availableTags,

    selectedAssignedAccountID,
    accumulatedData,
    mainData,
    accumulatedData,
    allData,
  ]);

  const handleSelectAll = () => {
    if (owneraccountnumberstate?.length == 0) {
      setowneraccountnumberstate(() => {
        return filteredData?.slice(0, 10)?.map((ac) => {
          return ac.id;
        });
      });
    } else if (
      (owneraccountnumberstate?.length == 10 ||
        owneraccountnumberstate?.length === filteredData.length) &&
      searchUserText.length == 0 &&
      accountbalancestate.length == 0 &&
      ownerTagState.length == 0 &&
      typeaccountstate.length == 0 &&
      bankstate.length == 0 &&
      panstate.length == 0 &&
      accountnumberstate.length == 0 &&
      ifscstate.length == 0
    ) {
      setowneraccountnumberstate([]);
    } else if (
      (searchUserText.length > 0 ||
        accountbalancestate.length > 0 ||
        ownerTagState.length > 0 ||
        typeaccountstate.length > 0 ||
        bankstate.length > 0 ||
        panstate.length > 0 ||
        accountnumberstate.length > 0 ||
        ifscstate.length > 0) &&
      (owneraccountnumberstate?.length == 10 ||
        owneraccountnumberstate?.length === filteredData.length ||
        filteredData?.every((ac) => {
          return owneraccountnumberstate?.includes(ac.id);
        }))
    ) {
      let arr = [...owneraccountnumberstate];
      for (let i = 0; i < filteredData.length; i++) {
        if (owneraccountnumberstate?.includes(filteredData[i].id)) {
          arr = arr.filter((ac) => {
            return ac != filteredData[i].id;
          });
        }
      }
      setowneraccountnumberstate(arr);
    } else {
      let arr = [...owneraccountnumberstate];
      for (let i = 0; i < 10; i++) {
        if (arr.length < 10) {
          if (!owneraccountnumberstate?.includes(filteredData[i]?.id)) {
            if (filteredData[i]?.id) {
              arr.push(filteredData[i]?.id);
            }
          }
        }
      }
      setowneraccountnumberstate(arr);
    }
  };

  const handleClose = () => {
    setIsTagsPopupOpen(false);
  };

  const handleOpenAssignTagsFlow = () => {
    setIsAssignTagsClicked(true);
    setSplitView(false);
    setSearchUserText("");
    setIsAssignTagsClicked(true);
    if (typepop === "popup" && flow !== "internalTxn") {
      setConnectedbankingstate("Active");
    } else {
      setConnectedbankingstate("");
    }
    setbankstate("");
    settypeaccountstate("");
    setaccountbalancestate("");
    setaccountnumberstate("");
    setifscstate("");
    setpanstate("");
    setOwnerTagState([]);
    setFilterPayload({
      pagination: 0,
      connectedBanking:
        typepop === "popup" && flow !== "internalTxn" ? "Active" : "",
      accNoInput: "",
      sortBank: "",
      sortAccountNo: "",
      bankNameInput: "",
      accountnumber: "",
      pannumber: "",
      ifscnumber: "",
      typeofaccount: "",
      ownerTags: [],
      panFilter: false,
      BankFilter: false,
      TypeAccFilter: false,
      ConnectedFilter:
        typepop === "popup" && flow !== "internalTxn" ? true : false,
      AccIfscFilter: false,
      SortBankFilter: false,
      TagFilter: false,
    });
    setCurrentPage(1);
    setSplitView(false);
  };
  return (
    <>
      {isTagsPopupOpen && (
        <div
          onClick={() => {
            setIsTagsPopupOpen(false);
          }}
          className="fixed left-0 w-full top-0 h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[99999]"
        >
          <UserTag
            onClose={handleClose}
            newTags={newSelectedTags}
            setNewTags={setNewSelectedTags}
            AllTags={AllTags}
            setAllTags={setAllTags}
            ownerDetailData={[
              ...new Map(allData.map((item) => [item["id"], item])).values(),
            ].filter((account) => assignTagsAccounts.includes(account.id))}
            type="ownerViewAll"
            loading={loading}
            setLoading={setLoading}
            handleAddTags={handleAddTags}
            handleRemoveTags={handleRemoveTags}
            selectedAssignedAccountID={selectedAssignedAccountID}
            setSelectedAssignedAccountID={setSelectedAssignedAccountID}
            selectedTagsCards={[
              ...new Map(allData.map((item) => [item["id"], item])).values(),
            ].filter((account) => assignTagsAccounts.includes(account.id))}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
          />
        </div>
      )}

      {!isMobile ? (
        <>
          {viewStatementOpenPop && (
            <div
              style={{ zIndex: "2147483647" }}
              className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80"
            >
              <div
                className="fixed top-0 left-0 w-screen h-screen z-[9998] "
                style={{ backgroundColor: "#000000", opacity: "0.8" }}
              ></div>
              <div
                style={{ backgroundColor: "#1d3a6d" }}
                className="main_pop_div relative"
              >
                <div className="absolute top-0 bg-Blue_3 z-[99999] px-[20px] left-0 h-[70px] flex justify-between items-center w-full">
                  <p className="popup-header">View account statement</p>
                  <div
                    onClick={() => setViewstatementOpenPop(false)}
                    className="h-[30px] w-[30px] flex justify-center items-center bg-Blue_5 cursor-pointer rounded-[5px] hover:bg-Blue_5_hover"
                  >
                    <img className="h-[15px] w-[15px]" src={Cancel} alt="" />
                  </div>
                </div>
                <OwnerAllStatement
                  popType={"ownerStatement"}
                  ownerDetailId={statementId}
                  ownerDetailData={accData}
                />
              </div>
            </div>
          )}

          {
            <div
              style={{
                zIndex: filterOpen ? "0" : "9999",
                width: typepop === "popup" ? "96%" : "100%",
                bottom: typepop === "popup" ? "4%" : "0",
                borderRadius: typepop === "popup" ? "0 0 20px 20px" : "0",
              }}
              className="fixed w-full bg-[#244277] h-[50px]"
            >
              <PaginationComponent
                handleSelect={handleSelect}
                selectedValue={selectedValue}
                data={mainData}
                pageSize={pageSize}
                dropdownOptions={dropdownOptions}
                handlePagination={handlePagination}
                currentPage={currentPage}
              />
            </div>
          }
          {selectedTagOpenPop && (
            <div
              onClick={(e) => e.stopPropagation()}
              className="fixed top-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9999]"
            >
              {
                <OwnerDetailsSelectTag
                  onClose={() => setSelectedTagOpenPop(false)}
                  handlefilterPayload={setFilterPayload}
                  newTags={newTags}
                  existingTags={filterPayload?.ownerTags}
                  setNewTags={setNewTags}
                  ownerExistingTag={tagsOptions}
                  ownerviewall={true}
                />
              }
            </div>
          )}

          {refreshAll && (
            <div
              className={
                refreshAll
                  ? "refresh-transition-open"
                  : "refresh-transition-close"
              }
              style={{
                paddingTop: typepop === "popup" ? "10px" : "60px",
                marginTop: typepop === "popup" ? "55px" : "",
                height: typepop === "popup" ? "100px" : "150px",
              }}
            >
              <RefreshAllLoader
                setRefreshAll={setRefreshAll}
                data={connectedBankingData}
                setAccumulatedData={setAccumulatedData}
                mainData={filteredData}
                total_balance_state={total_balance_state}
                setTotal_balance_state={setTotal_balance_state}
                setFailedRefreshId={setFailedRefreshId}
                setSuccessRefreshId={setSuccessRefreshId}
              />
            </div>
          )}
          {showTotalBalancePopup && (
            <div className="fixed top-0 w-[40%] 2xl:w-[44%] 3xl:w-[48%] 3.6xl:w-[48%] 4xl:w-[47%] 4.3xl:w-[45%]4xl:w-[47%] 5.6xl:w-[46%] 6xl:w-[49%] 6.5xl:w-[48%] 7xl:w-[49%] h-[350px] flex  items-center justify-end z-[9999]">
              <div
                ref={balanceRef}
                style={{
                  width: "242px",
                  height: "57px",
                  borderRadius: "10px",
                  backgroundColor: "#314D7E",
                  padding: "20px 15px",
                  boxShadow: "6px #00000029",
                }}
              >
                <p
                  style={{
                    width: "250px",
                    height: "17px",
                    font: "normal normal normal 12px/17px Open Sans",
                    color: "#C9C9C9",
                  }}
                >
                  Total balance:
                  <span
                    style={{
                      height: "19px",
                      font: "normal normal 600 14px/19px Open Sans",
                      color: "#4DDD37",
                      paddingLeft: "5px",
                    }}
                  >
                    {<FormatAmount price={Number(total_balance_state)} />}
                  </span>
                </p>
              </div>
            </div>
          )}
          <div
            onClick={(e) => {
              if (!filterOpen && !showSearchBar) {
                e.stopPropagation();
              }
            }}
            className={`bg-custom-appBack flex flex-col h-screen overflow-y-hidden`}
          >
            <div
              style={{
                height: isAssignTagsClicked && showSearchBar && "205px",
                marginTop: "60px",
              }}
              className={`owner_div_container ${
                showSearchBar && "owner_div_height"
              }`}
            >
              <div
                onClick={(e) => e.stopPropagation()}
                className="top_line_of_div"
                style={{
                  display: typepop === "popup" ? "flex" : "none",
                }}
              >
                <div>
                  <h3
                    style={{
                      width: multiSelect ? "480px" : "277px",
                    }}
                    className="text-[16px] font-sans font-[600px] text-[#f9f9f9]"
                  >
                    {flow === "internalTxn"
                      ? "Select Destination Account"
                      : TypeAccount === "InternalAccountTxn"
                      ? "Select Source Account"
                      : "Select Owner Account:"}
                    {multiSelect && (
                      <p className="font-normal text-[12px] text-[#f9f9f9] ">
                        Select up to 10 accounts to view their consolidated
                        statement.
                      </p>
                    )}
                  </h3>
                  <p className="text-[12px] font-sans font-normal text-[#f9f9f9]">
                    {flow === "internalTxn"
                      ? "Only active (pending & approved) accounts are enabled for selection ff."
                      : TypeAccount === "InternalAccountTxn"
                      ? "Only accounts with connected banking active are available for selection."
                      : ""}
                  </p>
                </div>

                <div
                  className="h-[30px] w-[30px] flex justify-center items-center bg-Blue_5 cursor-pointer rounded-[5px] hover:bg-Blue_5_hover"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (typepop === "popup") {
                      setOpenOwner(false);
                    }
                  }}
                >
                  <img
                    className="h-[15px] w-[15px]"
                    src={Cancel}
                    alt="cancel"
                  />
                </div>
              </div>
              {isAssignTagsClicked && !multiSelect ? (
                <div className={"owner_div-tags"}>
                  <div className="w-[591px] mt-[45px] flex gap-[20px]">
                    <div
                      onClick={(e) => {
                        const textContent = e.target.textContent.trim();
                        if (textContent === "Clear") {
                          setAssignTagsAccounts([]);
                          return;
                        } else if (textContent === "Cancel") {
                          setIsAssignTagsClicked(false);

                          if (typepop === "popup" && flow !== "internalTxn") {
                            setConnectedbankingstate("Active");
                          } else {
                            setConnectedbankingstate("");
                          }
                          setSearchUserText("");
                          setIsAssignTagsClicked(false);
                          setbankstate("");
                          settypeaccountstate("");
                          setaccountbalancestate("");
                          setaccountnumberstate("");
                          setifscstate("");
                          setpanstate("");
                          setOwnerTagState([]);
                          setFilterPayload({
                            pagination: 0,
                            connectedBanking:
                              typepop === "popup" && flow !== "internalTxn"
                                ? "Active"
                                : "",
                            accNoInput: "",
                            sortBank: "",
                            sortAccountNo: "",
                            bankNameInput: "",
                            accountnumber: "",
                            pannumber: "",
                            ifscnumber: "",
                            typeofaccount: "",
                            ownerTags: [],
                            panFilter: false,
                            BankFilter: false,
                            TypeAccFilter: false,
                            ConnectedFilter:
                              typepop === "popup" && flow !== "internalTxn"
                                ? true
                                : false,
                            AccIfscFilter: false,
                            SortBankFilter: false,
                            TagFilter: false,
                          });
                          handleScrollToTop();
                          setCurrentPage(1);
                          setSplitView("");
                        }
                      }}
                      style={{
                        // opacity: assignTagsAccounts?.length > 0 ? "1" : "0.5",
                        cursor: "pointer",
                      }}
                      className="w-[125px] h-[60px]  bg-[#506994] flex justify-center items-center"
                    >
                      {assignTagsAccounts.length > 0 ? (
                        <p className="w-[39px] h-[22px] text-[16px] text-[#f9f9f9]">
                          Clear{" "}
                        </p>
                      ) : (
                        <p className="w-[39px] h-[22px] text-[16px] text-[#f9f9f9]">
                          Cancel{" "}
                        </p>
                      )}
                    </div>
                    <div className="w-[158px] py-[10px] flex flex-col">
                      <p className="text-[14px] font-semibold text-[#f9f9f9]">
                        {assignTagsAccounts?.length || 0}
                      </p>
                      <p className="text-[12px] text-[#dadada]">
                        {assignTagsAccounts?.length > 1
                          ? "Accounts selected"
                          : "Account selected"}
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "105px",
                      width:
                        multiSelect || isAssignTagsClicked ? "190px" : "235px",
                      paddingTop: "45px",
                    }}
                  >
                    <div
                      style={{
                        display:
                          multiSelect || isAssignTagsClicked ? "none" : "flex",
                        opacity:
                          filterOpen ||
                          showSearchBar ||
                          (!services?.includes("VAccountBalance") &&
                            !services?.includes("Admin"))
                            ? "0.6"
                            : "1",
                        cursor:
                          !services?.includes("VAccountBalance") &&
                          !services?.includes("Admin")
                            ? "not-allowed"
                            : filterOpen || showSearchBar
                            ? "default"
                            : "pointer",
                      }}
                      className={`owner-refresh-div ${
                        !filterOpen &&
                        !showSearchBar &&
                        (services?.includes("VAccountBalance") ||
                          services?.includes("Admin"))
                          ? "hover:bg-Blue_4_hover"
                          : ""
                      } `}
                      onClick={() => {
                        if (!filterOpen && !showSearchBar) {
                          if (
                            services?.includes("VAccountBalance") ||
                            services?.includes("Admin")
                          ) {
                            dispatch(checkUsertoken());
                            dispatch(getRefreshTokenNew());
                            setTimeout(() => {
                              let selectedData = accumulatedData?.map(
                                (item) => {
                                  return {
                                    id: item.id,
                                    prevbalance: item.owner_account_balance,
                                  };
                                }
                              );
                              setFailedRefreshId([]);
                              setSuccessRefreshId([]);
                              setRefreshtype("all");
                              setPrev_Total_balance_state(total_balance_state);
                              setID(true);
                              setRefreshAllData(selectedData);
                              setRefreshAll(true);
                            }, 500);
                          } else {
                            navigate("/feature_not_assigned", {
                              state: {
                                unavailableService: "VAccountBalance,Admin",
                              },
                            });
                          }
                        }
                      }}
                    >
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              zIndex: "2147483647 !important",
                            },
                          },
                        }}
                        title="Refresh all balances"
                      >
                        <img
                          className="h-[14px] w-[14px]"
                          src={Refreshicon}
                          alt=""
                        />
                      </Tooltip>
                    </div>

                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      title="Search"
                    >
                      <div
                        ref={searchButtonRef}
                        style={{
                          backgroundColor:
                            searchUserText.length > 0 && "var(--Primary_white)",
                          opacity: filterOpen ? "0.6" : "1",
                          cursor: filterOpen ? "default" : "pointer",
                        }}
                        className={`owner_search_div ${
                          filterOpen ? "" : "hover:bg-Blue_4_hover"
                        }`}
                        onClick={() => {
                          if (!filterOpen) {
                            setShowSearchBar(!showSearchBar);
                          }
                        }}
                      >
                        <img
                          className="h-[14px] w-[14px]"
                          src={
                            searchUserText.length > 0 ? SearchBlue : SearchIcon
                          }
                          alt="search-image"
                        />
                        <img
                          style={{
                            transform: showSearchBar && "rotate(180deg)",
                          }}
                          src={
                            searchUserText.length > 0 ? ArrowDown8 : ArrowDown
                          }
                          alt=""
                          className="filter-images"
                        />
                      </div>
                    </Tooltip>

                    <div
                      style={{
                        display:
                          multiSelect || isAssignTagsClicked ? "none" : "flex",
                      }}
                      ref={filterButtonRef}
                      onClick={() => {
                        if (!showSearchBar) {
                          setFilterOpen(!filterOpen);
                          setSelectTypeofAccount(false);
                          setSelectSortAmt(false);
                        }
                      }}
                    >
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              zIndex: "2147483647 !important",
                            },
                          },
                        }}
                        title="Filters"
                      >
                        <div
                          style={{
                            backgroundColor:
                              typepop === "popup" ||
                              connectedbankingstate.length > 0 ||
                              accountbalancestate.length > 0 ||
                              ownerTagState.length > 0 ||
                              typeaccountstate.length > 0 ||
                              bankstate.length > 0 ||
                              panstate.length > 0 ||
                              accountnumberstate.length > 0 ||
                              ifscstate.length > 0
                                ? "#F9F9F9"
                                : "",
                            opacity: showSearchBar ? "0.6" : "1",
                            cursor: showSearchBar ? "default" : "pointer",
                          }}
                          onClick={() => {
                            if (!showSearchBar) {
                              setFilterOpen(!filterOpen);

                              setSelectTypeofAccount(false);
                              setSelectSortAmt(false);
                            }
                          }}
                          className={`filter-containers ${
                            showSearchBar ? "" : "hover:bg-Blue_4_hover"
                          }`}
                        >
                          <Box className="filters-box">
                            <img
                              src={
                                typepop === "popup" ||
                                connectedbankingstate.length > 0 ||
                                accountbalancestate.length > 0 ||
                                ownerTagState.length > 0 ||
                                typeaccountstate.length > 0 ||
                                bankstate.length > 0 ||
                                panstate.length > 0 ||
                                accountnumberstate.length > 0 ||
                                ifscstate.length > 0
                                  ? FilterBlue
                                  : Filter
                              }
                              alt=""
                              className="filter-images"
                            />
                          </Box>
                          <Box className="filter-box">
                            <img
                              src={
                                filterOpen
                                  ? typepop === "popup" ||
                                    connectedbankingstate.length > 0 ||
                                    accountbalancestate.length > 0 ||
                                    ownerTagState.length > 0 ||
                                    typeaccountstate.length > 0 ||
                                    bankstate.length > 0 ||
                                    panstate.length > 0 ||
                                    accountnumberstate.length > 0 ||
                                    ifscstate.length > 0
                                    ? ArrowUp4
                                    : ArrowUp
                                  : typepop === "popup" ||
                                    connectedbankingstate.length > 0 ||
                                    accountbalancestate.length > 0 ||
                                    ownerTagState.length > 0 ||
                                    typeaccountstate.length > 0 ||
                                    bankstate.length > 0 ||
                                    panstate.length > 0 ||
                                    accountnumberstate.length > 0 ||
                                    ifscstate.length > 0
                                  ? ArrowDown8
                                  : ArrowDown
                              }
                              alt=""
                              className="filter-images"
                            />
                          </Box>
                        </div>
                      </Tooltip>
                    </div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        if (assignTagsAccounts?.length > 0) {
                          setIsTagsPopupOpen(true);
                          // if (owneraccountNumber.length == 0) {
                          //   setownerAccountNumber(assignTagsAccounts);
                          //   setDefaultAccountNumber(assignTagsAccounts);
                          // }
                          // onClosed(false);
                        }
                      }}
                      style={{
                        display: isAssignTagsClicked ? "flex" : "none",
                        backgroundColor:
                          assignTagsAccounts?.length > 0
                            ? "#F9F9F9"
                            : "#707070",
                        cursor:
                          assignTagsAccounts?.length > 0
                            ? "pointer"
                            : "default",
                        opacity: assignTagsAccounts?.length > 0 ? "1" : "0.8",
                      }}
                      className="assignTags-btn"
                    >
                      <p
                        style={{
                          color:
                            assignTagsAccounts?.length > 0
                              ? "#1D3A6D"
                              : "#FFFFFF",
                        }}
                      >
                        Assign Tag/s
                      </p>
                    </div>
                    <div
                      style={{
                        display:
                          multiSelect || isAssignTagsClicked ? "none" : "flex",
                      }}
                      className="toggle-containers"
                    >
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              zIndex: "2147483647 !important",
                            },
                          },
                        }}
                        title="Grid"
                      >
                        <Box
                          onClick={(e) => {
                            if (!filterOpen && !showSearchBar) {
                              e.stopPropagation();
                              setFailedRefreshId([]);
                              setSuccessRefreshId([]);
                              dispatch(setToggle(false));
                              setCurrentPage(1);
                            }
                          }}
                          sx={{
                            backgroundColor: toggle ? "" : "#f9f9f9",
                            opacity: filterOpen || showSearchBar ? "0.6" : "1",
                            cursor:
                              filterOpen || showSearchBar
                                ? "default"
                                : "pointer",
                          }}
                          className={`${
                            toggle && !showSearchBar && !filterOpen
                              ? "hover:bg-Blue_4_hover"
                              : ""
                          } toggle-box`}
                        >
                          <img
                            src={toggle ? GridWhite : GridBlue}
                            alt=""
                            className="toggle-images"
                          />
                        </Box>
                      </Tooltip>
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              zIndex: "2147483647 !important",
                            },
                          },
                        }}
                        title="List"
                      >
                        <Box
                          onClick={(e) => {
                            if (!filterOpen && !showSearchBar) {
                              e.stopPropagation();
                              setFailedRefreshId([]);
                              setSuccessRefreshId([]);
                              dispatch(setToggle(true));
                              setCurrentPage(1);
                            }
                          }}
                          sx={{
                            backgroundColor: toggle ? "#f9f9f9" : "",
                            opacity: filterOpen || showSearchBar ? "0.6" : "1",
                            cursor:
                              filterOpen || showSearchBar
                                ? "default"
                                : "pointer",
                          }}
                          className={`toggle-box ${
                            toggle === false && !showSearchBar && !filterOpen
                              ? "hover:bg-Blue_4_hover"
                              : ""
                          }`}
                        >
                          <img
                            src={toggle ? ListBlue : ListWhite}
                            alt=""
                            className="toggle-images"
                          />
                        </Box>
                      </Tooltip>
                    </div>
                  </div>
                  {/* {assignButtonClicked && (
                    <p className="select-tags-text">
                      Select accounts to apply tags. Tagged users will gain
                      access to view only owner accounts that share the same
                      tags.
                    </p>
                  )} */}
                </div>
              ) : (
                <div className={"owner_div"}>
                  {multiSelect ? (
                    <div className="w-[591px] mt-[45px] flex gap-[20px]">
                      <div
                        onClick={() => {
                          setowneraccountnumberstate([]);
                        }}
                        style={{
                          // opacity:
                          //   owneraccountnumberstate?.length > 0 ? "1" : "0.5",
                          cursor:
                            owneraccountnumberstate?.length > 0
                              ? "pointer"
                              : "default",
                        }}
                        className={`w-[125px] h-[60px]  flex justify-center items-center ${
                          owneraccountnumberstate?.length > 0
                            ? "bg-[#506994] hover:bg-[#7387A9]"
                            : "bg-[#506994] bg-opacity-50"
                        }`}
                      >
                        <p className="w-[39px] h-[22px] text-[16px] text-[#f9f9f9]">
                          Clear
                        </p>
                      </div>
                      <div className="w-[158px] py-[10px] flex flex-col">
                        <p className="text-[14px] font-semibold text-[#f9f9f9]">
                          {owneraccountnumberstate?.length}
                        </p>
                        <p className="text-[12px] text-[#dadada]">
                          {owneraccountnumberstate?.length > 1
                            ? "Accounts selected"
                            : "Account selected"}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div
                      ref={totalBalanceRef}
                      onClick={() => {
                        if (!filterOpen) {
                          if (successRefreshId.length > 0) {
                            setShowTotalBalanceDiff(!showTotalBalanceDiff);
                          }
                        }
                      }}
                      style={{
                        cursor:
                          successRefreshId.length > 0 ? "pointer" : "default",
                        height: " 105px",
                        transition: "height 0.2s ease-in-out",
                        padding: "45px 20px",
                      }}
                      className=" all-containers"
                    >
                      <div
                        style={{
                          color:
                            total_balance_state >= 0 ? "#4DDD37" : "#ff5860",
                        }}
                        className="Nos-of-balancess"
                      >
                        <div>
                          <span className="rupee-count-symbol">₹ </span>
                          {total_balance_state !== undefined
                            ? formatTotalBalance(
                                total_balance_state,
                                selectAmtType
                              ).amount
                            : "00.00"}
                          {total_balance_state !== undefined &&
                            formatTotalBalance(total_balance_state).prefix ==
                              "crores" && (
                              <span
                                ref={balanceButtonRef}
                                onClick={(e) => {
                                  if (!filterOpen) {
                                    e.stopPropagation();
                                    setShowTotalBalancePopup(true);
                                  }
                                }}
                                className="owner-total-div"
                              >
                                <img src={Info} alt="Info" />
                              </span>
                            )}
                        </div>
                        {successRefreshId.length > 0 && (
                          <span
                            style={{
                              position: "relative",
                              top: "-5px",
                            }}
                          >
                            <AnimationUpAndDown
                              loader={ownerAccBalanceLoading}
                              currentId={true}
                              previousId={previd}
                              currentBalance={total_balance_state}
                              refreshType={"all"}
                              allPrevData={prev_total_balance_state}
                            />
                          </span>
                        )}
                      </div>
                      <div className="views-counts">
                        <p>
                          Total balance{" "}
                          {formatTotalBalance(
                            total_balance_state,
                            selectAmtType
                          ).prefix == ""
                            ? ""
                            : "in " +
                              formatTotalBalance(
                                total_balance_state,
                                selectAmtType
                              ).prefix}
                        </p>
                        {successRefreshId.length > 0 && (
                          <div
                            style={{
                              cursor: "pointer",
                              height: "20px",
                              width: "20px",
                              borderRadius: "3px",
                              padding: "5px",
                              backgroundColor: "#1D3A6D",
                              marginTop: "-4px",
                            }}
                          >
                            <img
                              style={{
                                height: "10px",
                                width: "10px",
                              }}
                              src={showTotalBalanceDiff ? ArrowUp : ArrowDown}
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                      {showTotalBalanceDiff && successRefreshId.length > 0 && (
                        <div
                          style={{
                            animation: "growDown 300ms ease-in-out forwards",
                            transformOrigin: "top center",
                            width: "209px",
                            borderTop: "1px solid #1D3A6D",
                            transition: "all 0.2s ease-in",
                            height: "60px",
                            position: "absolute",
                            top: "105px",
                            paddingTop: "7px",
                            backgroundColor: "#506994",
                            paddingLeft: "20px",
                            marginLeft: "-20px",
                          }}
                        >
                          <p
                            style={{
                              color:
                                prev_total_balance_state < total_balance_state
                                  ? "#4DE659"
                                  : prev_total_balance_state >
                                    total_balance_state
                                  ? "#FF5860"
                                  : "#F9F9F9",
                            }}
                          >
                            {
                              formatTotalBalance(
                                prev_total_balance_state - total_balance_state,
                                selectAmtType
                              ).amount
                            }
                          </p>
                          <p className="account_text">
                            Balance{" "}
                            {prev_total_balance_state <= total_balance_state
                              ? "increased"
                              : "decreased"}{" "}
                            by{" "}
                            {formatTotalBalance(
                              prev_total_balance_state - total_balance_state,
                              selectAmtType
                            ).prefix == ""
                              ? ""
                              : "- in " +
                                formatTotalBalance(
                                  prev_total_balance_state -
                                    total_balance_state,
                                  selectAmtType
                                ).prefix}
                          </p>
                        </div>
                      )}
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "105px",
                      width:
                        typepop === "popup" && !multiSelect
                          ? "250px"
                          : multiSelect
                          ? "250px"
                          : "435px",

                      paddingTop: "45px",
                    }}
                  >
                    <div
                      style={{
                        display:
                          multiSelect || typepop === "popup" ? "none" : "flex",

                        opacity:
                          showSearchBar ||
                          (!services?.includes("COwnerTag") &&
                            !services?.includes("DOwnerTag"))
                            ? "0.6"
                            : "1",
                        pointerEvents: showSearchBar ? "none" : "auto",
                        cursor:
                          !services?.includes("COwnerTag") &&
                          !services?.includes("DOwnerTag")
                            ? "default"
                            : "pointer",
                      }}
                      className="assign-tags-btn  hover:bg-custom-cardHover"
                      onClick={() => {
                        if (
                          !services?.includes("COwnerTag") &&
                          !services?.includes("DOwnerTag")
                        ) {
                          navigate("/feature_not_assigned", {
                            state: {
                              unavailableService: !services?.includes(
                                "COwnerTag"
                              )
                                ? "COwnerTag"
                                : "DOwnerTag",
                            },
                          });
                        }

                        handleScrollToTop();
                        setSearchUserText("");
                        setIsAssignTagsClicked(true);
                        if (typepop === "popup" && flow !== "internalTxn") {
                          setConnectedbankingstate("Active");
                        } else {
                          setConnectedbankingstate("");
                        }
                        setbankstate("");
                        settypeaccountstate("");
                        setaccountbalancestate("");
                        setaccountnumberstate("");
                        setifscstate("");
                        setpanstate("");
                        setOwnerTagState([]);
                        setFilterPayload({
                          pagination: 0,
                          connectedBanking:
                            typepop === "popup" && flow !== "internalTxn"
                              ? "Active"
                              : "",
                          accNoInput: "",
                          sortBank: "",
                          sortAccountNo: "",
                          bankNameInput: "",
                          accountnumber: "",
                          pannumber: "",
                          ifscnumber: "",
                          typeofaccount: "",
                          ownerTags: [],
                          panFilter: false,
                          BankFilter: false,
                          TypeAccFilter: false,
                          ConnectedFilter:
                            typepop === "popup" && flow !== "internalTxn"
                              ? true
                              : false,
                          AccIfscFilter: false,
                          SortBankFilter: false,
                          TagFilter: false,
                        });
                        setCurrentPage(1);
                        setSplitView(false);
                      }}
                    >
                      <img src={assignTagImage} alt="assign tag img" />
                      <p>Assign tags to accounts</p>
                    </div>
                    <div
                      style={{
                        display: multiSelect ? "none" : "flex",
                        opacity:
                          filterOpen ||
                          showSearchBar ||
                          (!services?.includes("VAccountBalance") &&
                            !services?.includes("Admin"))
                            ? "0.6"
                            : "1",
                        cursor:
                          !services?.includes("VAccountBalance") &&
                          !services?.includes("Admin")
                            ? "not-allowed"
                            : filterOpen || showSearchBar
                            ? "default"
                            : "pointer",
                      }}
                      className={`owner-refresh-div ${
                        !filterOpen &&
                        !showSearchBar &&
                        (services?.includes("VAccountBalance") ||
                          services?.includes("Admin"))
                          ? "hover:bg-Blue_4_hover"
                          : ""
                      } `}
                      onClick={(e) => {
                        if (!filterOpen && !showSearchBar) {
                          e.stopPropagation();
                          if (
                            services?.includes("VAccountBalance") ||
                            services?.includes("Admin")
                          ) {
                            dispatch(checkUsertoken());
                            dispatch(getRefreshTokenNew());
                            setTimeout(() => {
                              let selectedData = accumulatedData?.map(
                                (item) => {
                                  return {
                                    id: item.id,
                                    prevbalance: item.owner_account_balance,
                                  };
                                }
                              );
                              setFailedRefreshId([]);
                              setSuccessRefreshId([]);
                              setRefreshtype("all");
                              setPrev_Total_balance_state(total_balance_state);
                              setID(true);
                              setRefreshAllData(selectedData);
                              setRefreshAll(true);
                            }, 500);
                          } else {
                            navigate("/feature_not_assigned", {
                              state: {
                                unavailableService: "VAccountBalance,Admin",
                              },
                            });
                          }
                        }
                      }}
                    >
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              zIndex: "2147483647 !important",
                            },
                          },
                        }}
                        title="Refresh all balances"
                      >
                        <img
                          className="h-[14px] w-[14px]"
                          src={Refreshicon}
                          alt=""
                        />
                      </Tooltip>
                    </div>

                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      title="Search"
                    >
                      <div
                        ref={searchButtonRef}
                        style={{
                          backgroundColor:
                            searchUserText.length > 0 && "var(--Primary_white)",
                          opacity: filterOpen ? "0.6" : "1",
                          cursor: filterOpen ? "default" : "pointer",
                        }}
                        className={`owner_search_div ${
                          filterOpen ? "" : "hover:bg-Blue_4_hover"
                        }`}
                        onClick={(e) => {
                          if (!filterOpen) {
                            e.stopPropagation();
                            setShowSearchBar(!showSearchBar);
                          }
                        }}
                      >
                        <img
                          className="h-[14px] w-[14px]"
                          src={
                            searchUserText.length > 0 ? SearchBlue : SearchIcon
                          }
                          alt="search-image"
                        />
                        <img
                          style={{
                            transform: showSearchBar && "rotate(180deg)",
                          }}
                          src={
                            searchUserText.length > 0 ? ArrowDown8 : ArrowDown
                          }
                          alt=""
                          className="filter-images"
                        />
                      </div>
                    </Tooltip>

                    <div
                      ref={filterButtonRef}
                      onClick={() => {
                        if (!showSearchBar) {
                          setFilterOpen(!filterOpen);
                          setSelectTypeofAccount(false);
                          setSelectSortAmt(false);
                        }
                      }}
                    >
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              zIndex: "2147483647 !important",
                            },
                          },
                        }}
                        title="Filters"
                      >
                        <div
                          style={{
                            backgroundColor:
                              typepop === "popup" ||
                              connectedbankingstate.length > 0 ||
                              accountbalancestate.length > 0 ||
                              ownerTagState.length > 0 ||
                              typeaccountstate.length > 0 ||
                              bankstate.length > 0 ||
                              panstate.length > 0 ||
                              accountnumberstate.length > 0 ||
                              ifscstate.length > 0
                                ? "#F9F9F9"
                                : "",
                            opacity: showSearchBar ? "0.6" : "1",
                            cursor: showSearchBar ? "default" : "pointer",
                          }}
                          onClick={(e) => {
                            if (!showSearchBar) {
                              e.stopPropagation();
                              setFilterOpen(!filterOpen);

                              setSelectTypeofAccount(false);
                              setSelectSortAmt(false);
                            }
                          }}
                          className={`filter-containers ${
                            showSearchBar ? "" : "hover:bg-Blue_4_hover"
                          }`}
                        >
                          <Box className="filters-box">
                            <img
                              src={
                                typepop === "popup" ||
                                connectedbankingstate.length > 0 ||
                                accountbalancestate.length > 0 ||
                                ownerTagState.length > 0 ||
                                typeaccountstate.length > 0 ||
                                bankstate.length > 0 ||
                                panstate.length > 0 ||
                                accountnumberstate.length > 0 ||
                                ifscstate.length > 0
                                  ? FilterBlue
                                  : Filter
                              }
                              alt=""
                              className="filter-images"
                            />
                          </Box>
                          <Box className="filter-box">
                            <img
                              src={
                                filterOpen
                                  ? typepop === "popup" ||
                                    connectedbankingstate.length > 0 ||
                                    accountbalancestate.length > 0 ||
                                    ownerTagState.length > 0 ||
                                    typeaccountstate.length > 0 ||
                                    bankstate.length > 0 ||
                                    panstate.length > 0 ||
                                    accountnumberstate.length > 0 ||
                                    ifscstate.length > 0
                                    ? ArrowUp4
                                    : ArrowUp
                                  : typepop === "popup" ||
                                    connectedbankingstate.length > 0 ||
                                    accountbalancestate.length > 0 ||
                                    ownerTagState.length > 0 ||
                                    typeaccountstate.length > 0 ||
                                    bankstate.length > 0 ||
                                    panstate.length > 0 ||
                                    accountnumberstate.length > 0 ||
                                    ifscstate.length > 0
                                  ? ArrowDown8
                                  : ArrowDown
                              }
                              alt=""
                              className="filter-images"
                            />
                          </Box>
                        </div>
                      </Tooltip>
                    </div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        if (owneraccountnumberstate?.length > 0) {
                          if (
                            owneraccountNumber.length == 0 &&
                            owneraccountDefaultNumber.length == 0
                          ) {
                            // remove this for new improvement
                            // setownerAccountNumber(owneraccountnumberstate);
                            setDefaultAccountNumber(owneraccountnumberstate);
                            setownerAccountNumber(owneraccountnumberstate);
                            setowneraccountnumberstate(owneraccountnumberstate);
                          }
                          onClosed(false);
                        }
                      }}
                      style={{
                        display: multiSelect ? "flex" : "none",
                        cursor:
                          owneraccountnumberstate?.length > 0
                            ? "pointer"
                            : "default",
                      }}
                      className={`w-[125px] h-[60px] flex justify-center items-center ${
                        owneraccountnumberstate?.length > 0
                          ? "bg-[#69B76F] hover:bg-[#87C58C]"
                          : "bg-[#707070]"
                      }`}
                    >
                      <p className="w-[63px] h-[22px] text-[16px] text-[#f9f9f9]">
                        Confirm
                      </p>
                    </div>
                    <div
                      style={{
                        display: multiSelect ? "none" : "flex",
                      }}
                      className="toggle-containers"
                    >
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              zIndex: "2147483647 !important",
                            },
                          },
                        }}
                        title="Grid"
                      >
                        <Box
                          onClick={(e) => {
                            if (!filterOpen && !showSearchBar) {
                              e.stopPropagation();
                              setFailedRefreshId([]);
                              setSuccessRefreshId([]);
                              dispatch(setToggle(false));
                              setCurrentPage(1);
                            }
                          }}
                          sx={{
                            backgroundColor: toggle ? "" : "#f9f9f9",
                            opacity: filterOpen || showSearchBar ? "0.6" : "1",
                            cursor:
                              filterOpen || showSearchBar
                                ? "default"
                                : "pointer",
                          }}
                          className={`${
                            toggle && !showSearchBar && !filterOpen
                              ? "hover:bg-Blue_4_hover"
                              : ""
                          } toggle-box`}
                        >
                          <img
                            src={toggle ? GridWhite : GridBlue}
                            alt=""
                            className="toggle-images"
                          />
                        </Box>
                      </Tooltip>
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              zIndex: "2147483647 !important",
                            },
                          },
                        }}
                        title="List"
                      >
                        <Box
                          onClick={(e) => {
                            if (!filterOpen && !showSearchBar) {
                              e.stopPropagation();
                              setFailedRefreshId([]);
                              setSuccessRefreshId([]);
                              dispatch(setToggle(true));
                              setCurrentPage(1);
                            }
                          }}
                          sx={{
                            backgroundColor: toggle ? "#f9f9f9" : "",
                            opacity: filterOpen || showSearchBar ? "0.6" : "1",
                            cursor:
                              filterOpen || showSearchBar
                                ? "default"
                                : "pointer",
                          }}
                          className={` toggle-box ${
                            toggle === false && !showSearchBar && !filterOpen
                              ? "hover:bg-Blue_4_hover"
                              : ""
                          }`}
                        >
                          <img
                            src={toggle ? ListBlue : ListWhite}
                            alt=""
                            className="toggle-images"
                          />
                        </Box>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              )}
              {isAssignTagsClicked && (
                <div className="assign-select-text">
                  Select accounts to apply tags. Tagged users will gain access
                  to view only owner accounts that share the same tags.
                </div>
              )}
              <div
                className={`filter-transition-${filterOpen ? "open" : "close"}`}
              >
                {filterOpen && (
                  <div ref={filterRef}>
                    <div className="owner_filterdropdown">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          width: "25%",
                          height: "157px",
                          paddingTop: "4px",
                        }}
                      >
                        <TypeOfAccountDropdown
                          selectTypeOfAccount={selectTypeOfAccount}
                          filterPayload={filterPayload}
                          setFilterPayload={setFilterPayload}
                          setSelectTypeofAccount={setSelectTypeofAccount}
                        />

                        <SelectBank
                          option={bankOptions}
                          filterPayload={filterPayload}
                          setFilterPayload={setFilterPayload}
                          selectBank={showSelectBank}
                          setSelectBank={setShowSelectBank}
                          owner={true}
                        />
                      </div>
                      <div className="owner-border-div"></div>
                      <div
                        style={{
                          paddingTop: "40px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                          width: "25%",
                          gap: "25px",
                          height: "270px",
                          paddingLeft: "10px",
                          paddingRight: "5px",
                        }}
                      >
                        <div>
                          <p className="owner_account_number_text">
                            Account number
                          </p>

                          <input
                            className="owner_account_number_text_input"
                            type="text"
                            value={filterPayload?.accountnumber?.toUpperCase()}
                            onChange={handleInput}
                            name="accountnumber"
                            placeholder="Enter account number"
                          />
                        </div>
                        <div>
                          <p className="owner_account_number_text">IFSC code</p>

                          <input
                            maxLength={11}
                            className="owner_account_number_text_input"
                            type="text"
                            onChange={handleInput}
                            value={filterPayload?.ifscnumber?.toUpperCase()}
                            name="ifscnumber"
                            placeholder="Enter IFSC code"
                          />
                        </div>
                        <div>
                          <p className="owner_account_number_text">
                            Note : Account number and IFSC code must be filled
                            to filter by bank details
                          </p>
                        </div>
                      </div>
                      <div className="owner-border-div"></div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          width: "25%",
                          gap: "25px",
                          height: "194px",
                          paddingTop: "40px",
                        }}
                      >
                        <ConnectedBankingDropdown
                          filterPayload={filterPayload}
                          setFilterPayload={setFilterPayload}
                          selectConnectedBanking={selectConnectedBanking}
                          setSelectConnectedBanking={setSelectConnectedBanking}
                          owner={true}
                          typepop={typepop}
                          flow={flow}
                        />
                        <div>
                          <p className="owner_account_number_text">
                            PAN number
                          </p>

                          <input
                            className="owner_account_number_text_input "
                            type="text"
                            onChange={handleInput}
                            value={filterPayload?.pannumber?.toUpperCase()}
                            name="pannumber"
                            placeholder="Enter PAN number"
                          />
                        </div>
                      </div>
                      <div className="owner-border-div"></div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          width: "25%",
                          height: "290px",
                          paddingTop: "40px",
                        }}
                      >
                        <div
                          style={{
                            height: "118px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <SelectAccountBalance
                            filterPayload={filterPayload}
                            setFilterPayload={setFilterPayload}
                            selectSortAmt={selectSortAmt}
                            setSelectSortAmt={setSelectSortAmt}
                            owner={true}
                          />

                          <div style={{ position: "relative" }}>
                            <p
                              style={{
                                color: "#C9C9C9",
                                height: "22px",
                                fontSize: "12px",
                                marginBottom: "5px",
                              }}
                            >
                              Assigned tags
                            </p>
                            <div
                              onClick={() => setSelectedTagOpenPop(true)}
                              className="owner_connected_bank cursor-pointer"
                              style={{
                                height: "37px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  color: "#dadada",
                                  padding: "8px 15px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  {filterPayload?.ownerTags?.length > 0
                                    ? filterPayload?.ownerTags?.length + " tags"
                                    : "Select tags"}
                                </span>
                                <img
                                  src={Arrowdonw5}
                                  alt="down"
                                  style={{
                                    height: "12px",
                                    transform:
                                      "rotate(270deg) translate(0px, 5px)",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="buttoncontainer">
                          <div
                            style={{
                              cursor: handleApplyEnable()
                                ? "pointer"
                                : "default",
                              opacity: handleApplyEnable() ? "1" : "0.6",
                            }}
                            onClick={() => {
                              if (handleApplyEnable()) {
                                if (
                                  typepop === "popup" &&
                                  flow !== "internalTxn"
                                ) {
                                  setConnectedbankingstate("Active");
                                } else {
                                  setConnectedbankingstate("");
                                }
                                setbankstate("");
                                settypeaccountstate("");
                                setaccountbalancestate("");
                                setaccountnumberstate("");
                                setifscstate("");
                                setpanstate("");
                                setOwnerTagState([]);
                                setFilterPayload({
                                  pagination: 0,
                                  connectedBanking:
                                    typepop === "popup" &&
                                    flow !== "internalTxn"
                                      ? "Active"
                                      : "",
                                  accNoInput: "",
                                  sortBank: "",
                                  sortAccountNo: "",
                                  bankNameInput: "",
                                  accountnumber: "",
                                  pannumber: "",
                                  ifscnumber: "",
                                  typeofaccount: "",
                                  ownerTags: [],
                                  panFilter: false,
                                  BankFilter: false,
                                  TypeAccFilter: false,
                                  ConnectedFilter:
                                    typepop === "popup" &&
                                    flow !== "internalTxn"
                                      ? true
                                      : false,
                                  AccIfscFilter: false,
                                  SortBankFilter: false,
                                  TagFilter: false,
                                });
                                setCurrentPage(1);
                              }
                            }}
                            className={`clear ${
                              handleApplyEnable() ? "hover:bg-[#506994]" : ""
                            }`}
                          >
                            <p className="buttoncontainer-text">Clear</p>
                          </div>
                          <div
                            ref={applyButtonRef}
                            onClick={() => {
                              if (handleApplyEnable()) {
                                let obj = filterPayload;
                                if (
                                  filterPayload?.accountnumber?.length > 8 &&
                                  filterPayload?.ifscnumber?.length === 11
                                ) {
                                  setaccountnumberstate(
                                    filterPayload?.accountnumber
                                  );
                                  setifscstate(filterPayload?.ifscnumber);
                                  obj = {
                                    ...obj,
                                    AccIfscFilter: true,
                                  };
                                } else {
                                  obj = {
                                    ...obj,
                                    AccIfscFilter: false,
                                  };
                                  setaccountnumberstate("");
                                  setifscstate("");
                                }
                                if (filterPayload?.pannumber?.length === 10) {
                                  setpanstate(filterPayload?.pannumber);
                                  obj = {
                                    ...obj,
                                    panFilter: true,
                                  };
                                } else {
                                  obj = {
                                    ...obj,
                                    panFilter: false,
                                  };
                                  setpanstate("");
                                }
                                if (filterPayload?.bankNameInput?.length > 0) {
                                  setbankstate(filterPayload?.bankNameInput);
                                  obj = {
                                    ...obj,
                                    BankFilter: true,
                                  };
                                } else {
                                  obj = {
                                    ...obj,
                                    BankFilter: false,
                                  };
                                  setbankstate("");
                                }
                                if (filterPayload?.typeofaccount?.length > 0) {
                                  settypeaccountstate(
                                    filterPayload?.typeofaccount
                                  );
                                  obj = {
                                    ...obj,
                                    TypeAccFilter: true,
                                  };
                                } else {
                                  obj = {
                                    ...obj,
                                    TypeAccFilter: false,
                                  };
                                  settypeaccountstate("");
                                }
                                if (filterPayload?.sortAccountNo?.length > 0) {
                                  setaccountbalancestate(
                                    filterPayload?.sortAccountNo
                                  );
                                  obj = {
                                    ...obj,
                                    SortBankFilter: true,
                                  };
                                } else {
                                  obj = {
                                    ...obj,
                                    SortBankFilter: false,
                                  };
                                  setaccountbalancestate("");
                                }
                                if (
                                  filterPayload?.connectedBanking?.length > 0
                                ) {
                                  setConnectedbankingstate(
                                    filterPayload?.connectedBanking
                                  );
                                  obj = {
                                    ...obj,
                                    ConnectedFilter: true,
                                  };
                                } else {
                                  if (
                                    typepop === "popup" &&
                                    flow !== "internalTxn"
                                  ) {
                                    setConnectedbankingstate("Active");
                                  } else {
                                    obj = {
                                      ...obj,
                                      ConnectedFilter: false,
                                    };
                                    setConnectedbankingstate("");
                                  }
                                }
                                if (filterPayload?.ownerTags?.length > 0) {
                                  setOwnerTagState(filterPayload?.ownerTags);
                                  obj = {
                                    ...obj,
                                    TagFilter: true,
                                  };
                                } else {
                                  obj = {
                                    ...obj,
                                    TagFilter: false,
                                  };
                                  setOwnerTagState([]);
                                }
                                setCurrentPage(1);
                                setFilterPayload(obj);
                                setFilterOpen(false);
                                setFilterApply(true);
                              }
                            }}
                            className={`apply ${
                              handleApplyEnable() ? "hover:opacity-90" : ""
                            }`}
                            style={{
                              cursor: handleApplyEnable()
                                ? "pointer"
                                : "default",
                              backgroundColor: handleApplyEnable()
                                ? "#FB5963"
                                : "#707070",
                            }}
                          >
                            <p className="buttoncontainer-text">Apply</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full mx-auto">
                {showSearchBar && (
                  <RectangleSearchBar
                    handleSearch={setSearchUserText}
                    showSearchBar={showSearchBar}
                    searchUserText={searchUserText}
                    setSearchUserText={setSearchUserText}
                    onPaste={handlePaste}
                    toggleShowSearchBar={() => {
                      setFilterOpen(false);
                      setShowSearchBar(!showSearchBar);
                    }}
                    bgColor={"bg-[#1D3A6D]"}
                    bgHover={"hover:bg-custom-cardHover"}
                    setCurrentPage={setCurrentPage}
                    searchRef={searchRef}
                    placeholder="Search by owner account ID/ name/ email ID"
                    setIsAssignTagsClicked={setIsAssignTagsClicked}
                    setAssignTagsAccounts={setAssignTagsAccounts}
                  />
                )}
              </div>
            </div>
            {filterApply &&
              (connectedbankingstate?.length > 0 ||
                accountbalancestate?.length > 0 ||
                ownerTagState?.length > 0 ||
                typeaccountstate?.length > 0 ||
                bankstate?.length > 0 ||
                panstate?.length > 0 ||
                accountnumberstate?.length > 0 ||
                ifscstate?.length > 0) && (
                <div className="filter_applied_div w-full">
                  <div
                    style={{
                      borderTop: "1px solid #031F4F",
                    }}
                    className="w-[1024px] mx-auto py-[10px] flex"
                  >
                    <p className="filterby_text">Filtered by :</p>
                    <div className="filter_badge_div">
                      {connectedbankingstate?.length > 0 && (
                        <div className="filter_badge">
                          <p className="filter_badge_text">
                            {connectedbankingstate}
                          </p>
                          <img
                            style={{
                              opacity:
                                typepop === "popup" && flow !== "internalTxn"
                                  ? "0.4"
                                  : "1",
                              cursor:
                                typepop === "popup" && flow !== "internalTxn"
                                  ? "default"
                                  : "pointer",
                            }}
                            onClick={() => {
                              if (typepop === "popup" && flow !== "internalTxn")
                                return;
                              setConnectedbankingstate("");

                              let obj = filterPayload;
                              if (filterPayload?.ifscnumber?.length !== 11) {
                                obj = {
                                  ...obj,
                                  connectedBanking: "",
                                  ifscnumber: "",
                                  ConnectedFilter: false,
                                };
                              }
                              if (filterPayload?.pannumber?.length != 10) {
                                obj = {
                                  ...obj,
                                  connectedBanking: "",
                                  pannumber: "",
                                  ConnectedFilter: false,
                                };
                              }
                              if (filterPayload?.accountnumber?.length < 9) {
                                obj = {
                                  ...obj,
                                  connectedBanking: "",
                                  accountnumber: "",
                                  ifscnumber: "",
                                  ConnectedFilter: false,
                                };
                              } else {
                                obj = {
                                  ...obj,
                                  connectedBanking: "",
                                  ConnectedFilter: false,
                                };
                              }
                              setFilterPayload(obj);
                            }}
                            className="filter_badge_img"
                            src={clearFilter}
                            alt="X"
                          />
                        </div>
                      )}
                      {typeaccountstate?.length > 0 && (
                        <div className="filter_badge">
                          <p className="filter_badge_text">
                            {typeaccountstate}
                          </p>
                          <img
                            onClick={() => {
                              settypeaccountstate("");
                              let obj = filterPayload;
                              if (filterPayload?.ifscnumber?.length !== 11) {
                                obj = {
                                  ...obj,
                                  typeofaccount: "",
                                  ifscnumber: "",
                                  TypeAccFilter: false,
                                };
                              }
                              if (filterPayload?.pannumber?.length != 10) {
                                obj = {
                                  ...obj,
                                  typeofaccount: "",
                                  pannumber: "",
                                  TypeAccFilter: false,
                                };
                              }
                              if (filterPayload?.accountnumber?.length < 9) {
                                obj = {
                                  ...obj,
                                  typeofaccount: "",
                                  accountnumber: "",
                                  ifscnumber: "",
                                  TypeAccFilter: false,
                                };
                              } else {
                                obj = {
                                  ...obj,
                                  typeofaccount: "",
                                  TypeAccFilter: false,
                                };
                              }
                              setFilterPayload(obj);
                            }}
                            className="filter_badge_img"
                            src={clearFilter}
                            alt="X"
                          />
                        </div>
                      )}
                      {accountbalancestate.length > 0 && (
                        <div className="filter_badge">
                          <p className="filter_badge_text">
                            {accountbalancestate}
                          </p>
                          <img
                            onClick={() => {
                              setaccountbalancestate("");
                              let obj = filterPayload;
                              if (filterPayload?.ifscnumber?.length !== 11) {
                                obj = {
                                  ...obj,
                                  sortAccountNo: "",
                                  ifscnumber: "",
                                };
                              }
                              if (filterPayload?.pannumber?.length != 10) {
                                obj = {
                                  ...obj,
                                  sortAccountNo: "",
                                  pannumber: "",
                                };
                              }
                              if (filterPayload?.accountnumber?.length < 9) {
                                obj = {
                                  ...obj,
                                  sortAccountNo: "",
                                  accountnumber: "",
                                  ifscnumber: "",
                                };
                              } else {
                                obj = {
                                  ...obj,
                                  sortAccountNo: "",
                                };
                              }
                              setFilterPayload(obj);
                            }}
                            className="filter_badge_img"
                            src={clearFilter}
                            alt="X"
                          />
                        </div>
                      )}

                      {ownerTagState?.length > 0 && (
                        <div className="filter_badge">
                          <p className="filter_badge_text">
                            {ownerTagState?.length} Tags
                          </p>
                          <img
                            onClick={() => {
                              setOwnerTagState([]);
                              let obj = filterPayload;
                              if (filterPayload?.ifscnumber?.length !== 11) {
                                obj = {
                                  ...obj,
                                  ownerTags: "",
                                  ifscnumber: "",
                                  TagFilter: false,
                                };
                              }
                              if (filterPayload?.pannumber?.length != 10) {
                                obj = {
                                  ...obj,
                                  ownerTags: "",
                                  pannumber: "",
                                  TagFilter: false,
                                };
                              }
                              if (filterPayload?.accountnumber?.length < 9) {
                                obj = {
                                  ...obj,
                                  ownerTags: "",
                                  accountnumber: "",
                                  ifscnumber: "",
                                  TagFilter: false,
                                };
                              } else {
                                obj = {
                                  ...obj,
                                  ownerTags: [],
                                  TagFilter: false,
                                };
                              }
                              setFilterPayload(obj);
                            }}
                            className="filter_badge_img"
                            src={clearFilter}
                            alt="X"
                          />
                        </div>
                      )}
                      {bankstate.length > 0 && (
                        <div className="filter_badge">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  zIndex: "2147483647 !important",
                                },
                              },
                            }}
                            title={bankstate}
                          >
                            <p className="filter_badge_text truncate overflow-ellipsis max-w-[80px]">
                              {bankstate}
                            </p>
                          </Tooltip>
                          <img
                            onClick={() => {
                              setbankstate("");
                              let obj = filterPayload;
                              if (filterPayload?.ifscnumber?.length !== 11) {
                                obj = {
                                  ...obj,
                                  bankNameInput: "",
                                  ifscnumber: "",
                                  BankFilter: false,
                                };
                              }
                              if (filterPayload?.pannumber?.length != 10) {
                                obj = {
                                  ...obj,
                                  bankNameInput: "",
                                  pannumber: "",
                                  BankFilter: false,
                                };
                              }
                              if (filterPayload?.accountnumber?.length < 9) {
                                obj = {
                                  ...obj,
                                  bankNameInput: "",
                                  accountnumber: "",
                                  ifscnumber: "",
                                  BankFilter: false,
                                };
                              } else {
                                obj = {
                                  ...obj,
                                  bankNameInput: "",
                                  BankFilter: false,
                                };
                              }
                              setFilterPayload(obj);
                            }}
                            className="filter_badge_img"
                            src={clearFilter}
                            alt="X"
                          />
                        </div>
                      )}
                      {accountnumberstate.length > 3 &&
                        ifscstate.length === 11 && (
                          <div className="filter_badge">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    zIndex: "2147483647 !important",
                                  },
                                },
                              }}
                              title={accountnumberstate}
                            >
                              <p className="filter_badge_text truncate overflow-ellipsis max-w-[120px]">
                                {accountnumberstate}
                              </p>
                            </Tooltip>
                            <img
                              onClick={() => {
                                setaccountnumberstate("");
                                setifscstate("");
                                if (filterPayload?.pannumber?.length != 10) {
                                  const obj = {
                                    ...filterPayload,
                                    accountnumber: "",
                                    ifscnumber: "",
                                    pannumber: "",
                                    AccIfscFilter: false,
                                  };
                                  setFilterPayload(obj);
                                } else {
                                  const obj = {
                                    ...filterPayload,
                                    accountnumber: "",
                                    ifscnumber: "",
                                    AccIfscFilter: false,
                                  };
                                  setFilterPayload(obj);
                                }
                              }}
                              className="filter_badge_img"
                              src={clearFilter}
                              alt="X"
                            />
                          </div>
                        )}
                      {panstate.length > 9 && (
                        <div className="filter_badge">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  zIndex: "2147483647 !important",
                                },
                              },
                            }}
                            title={panstate}
                          >
                            <p className="filter_badge_text">{panstate}</p>
                          </Tooltip>
                          <img
                            onClick={() => {
                              setpanstate("");
                              if (
                                filterPayload?.accountnumber?.length < 9 ||
                                filterPayload?.ifscnumber?.length !== 11
                              ) {
                                const obj = {
                                  ...filterPayload,
                                  accountnumber: "",
                                  ifscnumber: "",
                                  pannumber: "",
                                  panFilter: false,
                                };
                                setFilterPayload(obj);
                              } else {
                                const obj = {
                                  ...filterPayload,
                                  pannumber: "",
                                  panFilter: false,
                                };
                                setFilterPayload(obj);
                              }
                            }}
                            className="filter_badge_img"
                            src={clearFilter}
                            alt="X"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            <div
              className={
                filterOpen
                  ? "overflow-y-hidden h-screen ownerviewallscroll opacity-80  bg-black mt-[5px]"
                  : filteredData?.length === 0 || accumulatedData?.length === 0
                  ? "overflow-y-auto h-screen pb-[60px] ownerviewallscroll mt-[5px]"
                  : "overflow-y-auto h-screen mb-[5px] ownerviewallscroll mt-[5px]"
              }
              ref={containerRef}
            >
              {multiSelect ? (
                <div
                  style={{
                    display: filteredData?.length > 0 ? "flex" : "none",
                  }}
                  className="flex justify-between items-center h-[30px] px-[50px] mt-[20px] mb-[10px]"
                >
                  <p className="w-[211px] font-semibold text-[#f9f9f9] text-[16px]">
                    Select Accounts To Proceed
                  </p>
                  <div
                    style={{
                      cursor: filteredData?.length > 0 ? "pointer" : "default",
                      opacity: filteredData?.length > 0 ? "1" : "0.8",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (filteredData?.length > 0) {
                        handleSelectAll();
                      }
                    }}
                    className="w-[178px] h-[30px] flex justify-between items-center"
                  >
                    <p className="w-[138px] font-semibold text-[#dadada] text-[12px]">
                      Select all (Maximum 10)
                    </p>
                    <div className="w-[30px] h-[30px] rounded-[10px] bg-[#314D7E] flex justify-center items-center">
                      <img
                        className="w-[15px] h-[15px]"
                        src={
                          owneraccountnumberstate?.length == 10 ||
                          owneraccountnumberstate?.length ===
                            filteredData.length ||
                          filteredData?.every((ac) => {
                            return owneraccountnumberstate?.includes(ac.id);
                          })
                            ? selectWithGreenFilledCircle
                            : selectWhiteCircle
                        }
                        alt="white-filled-check"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="balance_type_container"
                  style={{
                    display: isAssignTagsClicked && "none",
                  }}
                >
                  <>
                    <div className="select_balance_type">
                      <p
                        style={{ color: "#dadada", marginRight: "10px" }}
                        className="select_balance_text"
                      >
                        Balance:{" "}
                      </p>
                      <div
                        style={{
                          top: "7%",
                          position: "absolute",
                          backgroundColor: "#667CA3",
                          width: selectAmtType == "Lakhs" ? "45px" : "51px",
                          height: "26px",
                          left: handleSelectAmt(),
                          borderRadius: "4px",
                          zIndex: "1",
                          transition: "all 0.2s ease-in",
                        }}
                      ></div>
                      <div
                        onClick={() => setSelectAmtType("Default")}
                        className="select_balance_btn"
                      >
                        <p className="select_balance_text">Default</p>
                      </div>
                      <div
                        style={{ width: "55px" }}
                        onClick={() => setSelectAmtType("Lakhs")}
                        className="select_balance_btn"
                      >
                        <p
                          style={{ width: "45px" }}
                          className="select_balance_text"
                        >
                          Lakhs
                        </p>
                      </div>
                      <div
                        onClick={() => setSelectAmtType("Crores")}
                        className="select_balance_btn"
                      >
                        <p className="select_balance_text">Crores</p>
                      </div>
                      <div
                        onClick={() => setSelectAmtType("Millions")}
                        className="select_balance_btn"
                      >
                        <p className="select_balance_text">Millions</p>
                      </div>
                      <div
                        onClick={() => setSelectAmtType("Billions")}
                        className="select_balance_btn"
                      >
                        <p className="select_balance_text">Billions</p>
                      </div>
                    </div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        splitView == ""
                          ? setSplitView(true)
                          : setSplitView(!splitView);
                      }}
                      className="split_view"
                    >
                      <img
                        className={`${
                          splitView
                            ? "animm-img"
                            : splitView !== ""
                            ? "split_view_img"
                            : "split_view_img_def"
                        }`}
                        src={splitView ? SplitView2 : SplitView1}
                        alt="split"
                      />
                      <p
                        className={`${
                          splitView
                            ? "animm-text"
                            : splitView !== ""
                            ? "split_view_text"
                            : "split_view_text_def"
                        }`}
                      >
                        Split view
                      </p>
                    </div>
                  </>
                </div>
              )}
              <div
                className={
                  (filterOpen ? `h-full bg-black opacity-80` : "") + "mt-[-5px]"
                }
                style={{ paddingBottom: typepop === "popup" ? "60px" : "" }}
              >
                <div className="flex flex-col items-center justify-center">
                  {loading ? (
                    <div
                      className={
                        isMobile
                          ? `loader-container m-auto justify-center items-center flex w-full h-full`
                          : `owner_loader-container m-auto justify-center pt-40 items-center flex w-[50px] h-[50px] max-h-[50px] max-w-[50px]`
                      }
                    >
                      <Loader />
                    </div>
                  ) : filteredData?.length > 0 ||
                    accumulatedData?.length > 0 ||
                    splitView ? (
                    <div
                      className={`flex flex-col items-center justify-center p-1 mx-auto w-full ${
                        filterOpen && "bg-black opacity-10 z-[-9999]"
                      }`}
                    >
                      {!toggle || isAssignTagsClicked ? (
                        <OwnerViewAllCard
                          typepop={typepop}
                          handleButtonClick={handleButtonClick}
                          setIsNeft={setIsNeft}
                          setIsImps={setIsImps}
                          flow={flow}
                          setIsRtgs={setIsRtgs}
                          txnPayLoad={txnPayLoad}
                          setTxnPayLoad={setTxnPayLoad}
                          BenEnableNextButton={BenEnableNextButton}
                          setOpenOwner={setOpenOwner}
                          isopen={filterOpen}
                          setFailedRefreshId={setFailedRefreshId}
                          failedRefreshId={failedRefreshId}
                          successRefreshId={successRefreshId}
                          setSuccessRefreshId={setSuccessRefreshId}
                          handleRefresh={handleRefresh}
                          data={filteredData}
                          page={currentPage}
                          refreshAllData={refreshAllData}
                          refreshType={refreshType}
                          setRefreshtype={setRefreshtype}
                          setId={setID}
                          setPrev_Total_balance_state={
                            setPrev_Total_balance_state
                          }
                          total_balance={total_balance_state}
                          currency={selectAmtType}
                          splitView={splitView}
                          postiveBalanceData={postiveBalanceData}
                          negativeBalanceData={negativeBalanceData}
                          refreshAll={refreshAll}
                          handleViewStatement={handleViewStatement}
                          setStatementId={setStatementId}
                          accData={accData}
                          setAccData={setAccData}
                          multiSelect={multiSelect}
                          isAssignTagsClicked={isAssignTagsClicked}
                          owneraccountnumberstate={
                            isAssignTagsClicked
                              ? assignTagsAccounts
                              : owneraccountnumberstate
                          }
                          setowneraccountnumberstate={
                            isAssignTagsClicked
                              ? setAssignTagsAccounts
                              : setowneraccountnumberstate
                          }
                          popType={popType}
                        />
                      ) : (
                        <ListView
                          typepop={typepop}
                          handleButtonClick={handleButtonClick}
                          setIsNeft={setIsNeft}
                          setIsImps={setIsImps}
                          setIsRtgs={setIsRtgs}
                          flow={flow}
                          txnPayLoad={txnPayLoad}
                          setTxnPayLoad={setTxnPayLoad}
                          BenEnableNextButton={BenEnableNextButton}
                          setOpenOwner={setOpenOwner}
                          handleRefresh={handleRefresh}
                          data={filteredData}
                          isopen={filterOpen}
                          page={currentPage}
                          refreshAllData={refreshAllData}
                          refreshType={refreshType}
                          setRefreshtype={setRefreshtype}
                          setFailedRefreshId={setFailedRefreshId}
                          failedRefreshId={failedRefreshId}
                          successRefreshId={successRefreshId}
                          setSuccessRefreshId={setSuccessRefreshId}
                          setId={setID}
                          setPrev_Total_balance_state={
                            setPrev_Total_balance_state
                          }
                          total_balance={total_balance_state}
                          currency={selectAmtType}
                          splitView={splitView}
                          postiveBalanceData={postiveBalanceData}
                          negativeBalanceData={negativeBalanceData}
                          refreshAll={refreshAll}
                          handleViewStatement={handleViewStatement}
                          setStatementId={setStatementId}
                          accData={accData}
                          setAccData={setAccData}
                        />
                      )}
                    </div>
                  ) : (
                    <div
                      className={
                        filterOpen
                          ? `h-full bg-black opacity-80 flex flex-col items-center justify-center m-auto`
                          : `flex flex-col items-center justify-center m-auto ${
                              multiSelect ? "h-[50vh]" : ""
                            }`
                      }
                    >
                      <img
                        src={noaccount}
                        alt="No accounts found"
                        style={{
                          width: "320px",
                          height: "226px",
                        }}
                        className={
                          filterOpen ? "no-acc-image-opacity" : "no-acc-image"
                        }
                      />
                      <p
                        className={
                          filterOpen
                            ? "no-owneraccount-text-opacity"
                            : "no-owneraccount-text"
                        }
                      >
                        No data found.
                      </p>
                      {/* <div
                        style={{
                          display:
                            searchUserText.length > 0 ||
                            connectedbankingstate.length > 0 ||
                            accountbalancestate.length > 0 ||
                            ownerTagState.length > 0 ||
                            typeaccountstate.length > 0 ||
                            bankstate.length > 0 ||
                            panstate.length > 0 ||
                            accountnumberstate.length > 0 ||
                            ifscstate.length > 0
                              ? "flex"
                              : "none",
                        }}
                        className="oas-selectaccount min-h-[36px]"
                        onClick={() => {
                          if (typepop === "popup") {
                            setConnectedbankingstate("Active");
                          } else {
                            setConnectedbankingstate("");
                          }
                          setSearchUserText("");
                          setbankstate("");
                          settypeaccountstate("");
                          setaccountbalancestate("");
                          setaccountnumberstate("");
                          setifscstate("");
                          setpanstate("");
                          setOwnerTagState([]);
                          setFilterPayload({
                            pagination: 0,
                            connectedBanking:
                              typepop === "popup" ? "Active" : "",
                            accNoInput: "",
                            sortBank: "",
                            sortAccountNo: "",
                            bankNameInput: "",
                            accountnumber: "",
                            pannumber: "",
                            ifscnumber: "",
                            typeofaccount: "",
                            ownerTags: [],
                            panFilter: false,
                            BankFilter: false,
                            TypeAccFilter: false,
                            ConnectedFilter: typepop === "popup" ? true : false,
                            AccIfscFilter: false,
                            SortBankFilter: false,
                            TagFilter: false,
                          });
                          setCurrentPage(1);
                        }}
                      >
                        <p className="oas-selectaccounttext">
                          Clear parameters
                        </p>
                      </div> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {viewStatementOpenPop && (
            <div
              style={{ zIndex: "2147483647" }}
              className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80"
            >
              <div
                className="fixed top-0 left-0 w-screen h-screen z-[9998] "
                style={{ backgroundColor: "#000000", opacity: "0.8" }}
              ></div>
              <div
                style={{ backgroundColor: "#1d3a6d" }}
                className="main_pop_div relative"
              >
                <div className="absolute top-0 bg-Blue_3 z-[99999] px-[20px] left-0 h-[70px] flex justify-between items-center w-full">
                  <p className="popup-header">View account statement</p>
                  <div
                    onClick={() => setViewstatementOpenPop(false)}
                    className="h-[30px] w-[30px] flex justify-center items-center bg-Blue_5 cursor-pointer rounded-[5px] hover:bg-Blue_5_hover"
                  >
                    <img className="h-[15px] w-[15px]" src={Cancel} alt="" />
                  </div>
                </div>
                <OwnerAllStatement
                  popType={"ownerStatement"}
                  ownerDetailId={statementId}
                  ownerDetailData={accData}
                />
              </div>
            </div>
          )}

          {isAssignTagsClicked && (
            <div
              style={{
                position: "fixed",
                width: "100%",
                bottom: "10",
                // height: "10px",
                zIndex: "9999",
                height: "60px",
                bottom: "50px",
              }}
            >
              <div className="assign-tags-select-mob">
                <button
                  style={{
                    opacity: assignTagsAccounts.length > 0 ? "1" : "0.5",
                    cursor:
                      assignTagsAccounts.length > 0 ? "pointer" : "default",
                  }}
                  onClick={() => {
                    setAssignTagsAccounts([]);
                  }}
                >
                  Clear
                </button>
                <button
                  style={{
                    backgroundColor: assignTagsAccounts.length > 0 && "#F9F9F9",
                    color: assignTagsAccounts.length > 0 && "#1D3A6D",
                    cursor:
                      assignTagsAccounts.length > 0 ? "pointer" : "default",
                    opacity: assignTagsAccounts.length > 0 ? "1" : "0.5",
                  }}
                  onClick={() => {
                    if (assignTagsAccounts?.length > 0) {
                      setIsTagsPopupOpen(true);

                      // if (owneraccountNumber.length == 0) {
                      //   setownerAccountNumber(assignTagsAccounts);
                      //   setDefaultAccountNumber(assignTagsAccounts);
                      // }
                      // onClosed(false);
                    }
                  }}
                >
                  Assign Tag/s
                </button>
              </div>
            </div>
          )}
          {
            <div
              style={{
                zIndex: filterOpen ? "0" : "9999",
                width: "100%",
                bottom: "0",
                borderRadius: "0",
              }}
              className="fixed w-full bg-[#244277] h-[50px]"
            >
              <PaginationComponent
                handleSelect={handleSelect}
                selectedValue={selectedValue}
                data={mainData}
                pageSize={pageSize}
                dropdownOptions={dropdownOptions}
                handlePagination={handlePagination}
                currentPage={currentPage}
              />
            </div>
          }
          {selectedTagOpenPop && (
            <div
              onClick={(e) => e.stopPropagation()}
              className="fixed top-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9999]"
            >
              {
                <OwnerDetailsSelectTag
                  onClose={() => setSelectedTagOpenPop(false)}
                  handlefilterPayload={setFilterPayload}
                  newTags={newTags}
                  existingTags={filterPayload?.ownerTags}
                  setNewTags={setNewTags}
                  ownerExistingTag={tagsOptions}
                  ownerviewall={true}
                />
              }
            </div>
          )}
          {refreshAll && (
            <div
              className={
                refreshAll
                  ? "refresh-transition-open"
                  : "refresh-transition-close"
              }
              style={{
                paddingTop: typepop === "popup" ? "10px" : "60px",
                marginTop: typepop === "popup" ? "55px" : "",
                height: typepop === "popup" ? "100px" : "150px",
              }}
            >
              <RefreshAllLoader
                setRefreshAll={setRefreshAll}
                data={connectedBankingData}
                setAccumulatedData={setAccumulatedData}
                mainData={filteredData}
                total_balance_state={total_balance_state}
                setTotal_balance_state={setTotal_balance_state}
                setFailedRefreshId={setFailedRefreshId}
                setSuccessRefreshId={setSuccessRefreshId}
              />
            </div>
          )}
          {showTotalBalancePopup && (
            <div className="fixed top-0 w-[40%] 2xl:w-[44%] 3xl:w-[48%] 3.6xl:w-[48%] 4xl:w-[47%] 4.3xl:w-[45%]4xl:w-[47%] 5.6xl:w-[46%] 6xl:w-[49%] 6.5xl:w-[48%] 7xl:w-[49%] h-[350px] flex  items-center justify-end z-[9999]">
              <div
                ref={balanceRef}
                style={{
                  width: "242px",
                  height: "57px",
                  borderRadius: "10px",
                  backgroundColor: "#314D7E",
                  padding: "20px 15px",
                  boxShadow: "6px #00000029",
                }}
              >
                <p
                  style={{
                    width: "250px",
                    height: "17px",
                    font: "normal normal normal 12px/17px Open Sans",
                    color: "#C9C9C9",
                  }}
                >
                  Total balance:
                  <span
                    style={{
                      height: "19px",
                      font: "normal normal 600 14px/19px Open Sans",
                      color: "#4DDD37",
                      paddingLeft: "5px",
                    }}
                  >
                    {<FormatAmount price={Number(total_balance_state)} />}
                  </span>
                </p>
              </div>
            </div>
          )}
          <div
            className={`bg-custom-appBack flex flex-col overflow-y-hidden h-[100dvh]`}
            style={{
              overflow: filterOpen && "hidden",
            }}
          >
            <div
              className={`owner_div_container ${
                showSearchBar && "owner_div_height"
              }`}
              // style={{
              //   marginTop: typepop === "popup" ? "10px" : "0",
              //   height: "50px",
              // }}
            >
              <div
                className={"owner_div"}
                style={{
                  height: "60px",
                  position: "fixed",
                  top: "0",
                  zIndex: "999",
                  backgroundColor: "#3A5686",
                  padding: isMobile && "0px 13px",
                }}
              >
                <div className="flex items-center">
                  <img
                    src={ArrowLeft}
                    alt=""
                    className="cursor-pointer h-[17px] w-[17px] pl-[3px] mr-[5px]"
                    onClick={() => {
                      if (isAssignTagsClicked) {
                        setIsAssignTagsClicked(false);
                        setAssignTagsAccounts([]);
                        setSearchUserText("");
                        setIsAssignTagsClicked(false);
                        setbankstate("");
                        settypeaccountstate("");
                        setaccountbalancestate("");
                        setaccountnumberstate("");
                        setifscstate("");
                        setpanstate("");
                        setOwnerTagState([]);
                        setFilterPayload({
                          pagination: 0,
                          connectedBanking:
                            typepop === "popup" && flow !== "internalTxn"
                              ? "Active"
                              : "",
                          accNoInput: "",
                          sortBank: "",
                          sortAccountNo: "",
                          bankNameInput: "",
                          accountnumber: "",
                          pannumber: "",
                          ifscnumber: "",
                          typeofaccount: "",
                          ownerTags: [],
                          panFilter: false,
                          BankFilter: false,
                          TypeAccFilter: false,
                          ConnectedFilter:
                            typepop === "popup" && flow !== "internalTxn"
                              ? true
                              : false,
                          AccIfscFilter: false,
                          SortBankFilter: false,
                          TagFilter: false,
                        });
                        handleScrollToTop();
                      } else if (typepop === "popup") {
                        setOpenOwner(false);
                      } else if (flow === "internalTxnSource") {
                        setOpenOwner(false);
                      } else {
                        if (filterOpen) {
                          setFilterOpen(false);
                        } else {
                          navigate(-1);
                        }
                      }
                    }}
                  />
                  {typepop === "popup" ? (
                    <>
                      <h3 className="text-[#f9f9f9] text-[14px]">
                        Select Owner Account
                      </h3>
                    </>
                  ) : (
                    <>
                      <div
                        ref={totalBalanceRef}
                        onClick={() => {
                          if (!filterOpen) {
                            if (successRefreshId.length > 0) {
                              setShowTotalBalanceDiff(!showTotalBalanceDiff);
                            }
                          }
                        }}
                        style={{
                          cursor:
                            successRefreshId.length > 0 ? "pointer" : "default",
                          height: " 50px",
                          transition: "height 0.2s ease-in-out",
                          backgroundColor: "transparent",
                          justifyContent: "center",
                          paddingLeft: "10px",
                        }}
                        className=" all-containers"
                      >
                        <div
                          style={{
                            color: isAssignTagsClicked
                              ? "var(--Primary_white)"
                              : total_balance_state >= 0
                              ? "#4DDD37"
                              : "#ff5860",
                            margin: "0",
                          }}
                          className="Nos-of-balancess"
                        >
                          {isAssignTagsClicked ? (
                            assignTagsAccounts.length
                          ) : (
                            <div>
                              <span className="rupee-count-symbol">₹ </span>
                              {total_balance_state !== undefined
                                ? formatTotalBalance(
                                    total_balance_state,
                                    selectAmtType
                                  ).amount
                                : "00.00"}
                              {total_balance_state !== undefined &&
                                formatTotalBalance(total_balance_state)
                                  .prefix == "crores" && (
                                  <span
                                    ref={balanceButtonRef}
                                    onClick={(e) => {
                                      if (!filterOpen) {
                                        e.stopPropagation();
                                        setShowTotalBalancePopup(true);
                                      }
                                    }}
                                    className="owner-total-div"
                                  >
                                    <img src={Info} alt="Info" />
                                  </span>
                                )}
                            </div>
                          )}
                          {successRefreshId.length > 0 && (
                            <span
                              style={{
                                position: "relative",
                                top: "-5px",
                              }}
                            >
                              <AnimationUpAndDown
                                loader={ownerAccBalanceLoading}
                                currentId={true}
                                previousId={previd}
                                currentBalance={total_balance_state}
                                refreshType={"all"}
                                allPrevData={prev_total_balance_state}
                              />
                            </span>
                          )}
                        </div>
                        <div className="views-counts" style={{ padding: "0" }}>
                          {isAssignTagsClicked ? (
                            <p>
                              {assignTagsAccounts?.length > 1
                                ? "Accounts selected"
                                : "Account selected"}
                            </p>
                          ) : (
                            <p>
                              Total balance{" "}
                              {formatTotalBalance(
                                total_balance_state,
                                selectAmtType
                              ).prefix == ""
                                ? ""
                                : "in " +
                                  formatTotalBalance(
                                    total_balance_state,
                                    selectAmtType
                                  ).prefix}
                            </p>
                          )}
                          {successRefreshId.length > 0 && (
                            <div
                              style={{
                                cursor: "pointer",
                                height: "20px",
                                width: "20px",
                                borderRadius: "3px",
                                padding: "5px",
                                backgroundColor: "#1D3A6D",
                                marginTop: "-4px",
                              }}
                            >
                              <img
                                style={{
                                  height: "10px",
                                  width: "10px",
                                }}
                                src={showTotalBalanceDiff ? ArrowUp : ArrowDown}
                                alt=""
                              />
                            </div>
                          )}
                        </div>
                        {showTotalBalanceDiff &&
                          successRefreshId.length > 0 && (
                            <div
                              style={{
                                animation:
                                  "growDown 300ms ease-in-out forwards",
                                transformOrigin: "top center",
                                width: "209px",
                                borderTop: "1px solid #1D3A6D",
                                transition: "all 0.2s ease-in",
                                height: "60px",
                                position: "absolute",
                                top: "55px",
                                paddingTop: "7px",
                                backgroundColor: "#506994",
                                paddingLeft: "20px",
                                marginLeft: "-20px",
                              }}
                            >
                              <p
                                style={{
                                  color:
                                    prev_total_balance_state <
                                    total_balance_state
                                      ? "#4DE659"
                                      : prev_total_balance_state >
                                        total_balance_state
                                      ? "#FF5860"
                                      : "#F9F9F9",
                                }}
                              >
                                {
                                  formatTotalBalance(
                                    prev_total_balance_state -
                                      total_balance_state,
                                    selectAmtType
                                  ).amount
                                }
                              </p>
                              <p className="account_text">
                                Balance{" "}
                                {prev_total_balance_state <= total_balance_state
                                  ? "increased"
                                  : "decreased"}{" "}
                                by{" "}
                                {formatTotalBalance(
                                  prev_total_balance_state -
                                    total_balance_state,
                                  selectAmtType
                                ).prefix == ""
                                  ? ""
                                  : "- in " +
                                    formatTotalBalance(
                                      prev_total_balance_state -
                                        total_balance_state,
                                      selectAmtType
                                    ).prefix}
                              </p>
                            </div>
                          )}
                      </div>
                    </>
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {!isAssignTagsClicked && (
                    <div
                      style={{
                        display: multiSelect ? "none" : "flex",
                        opacity:
                          filterOpen ||
                          showSearchBar ||
                          (!services?.includes("VAccountBalance") &&
                            !services?.includes("Admin"))
                            ? "0.6"
                            : "1",
                        cursor:
                          !services?.includes("VAccountBalance") &&
                          !services?.includes("Admin")
                            ? "not-allowed"
                            : filterOpen || showSearchBar
                            ? "default"
                            : "pointer",
                        background: "transparent",
                      }}
                      className={`owner-refresh-div ${
                        !filterOpen &&
                        !showSearchBar &&
                        (services?.includes("VAccountBalance") ||
                          services?.includes("Admin"))
                          ? "hover:bg-Blue_4_hover"
                          : ""
                      } `}
                      onClick={() => {
                        if (!filterOpen && !showSearchBar) {
                          if (
                            services?.includes("VAccountBalance") ||
                            services?.includes("Admin")
                          ) {
                            dispatch(checkUsertoken());
                            dispatch(getRefreshTokenNew());
                            setTimeout(() => {
                              let selectedData = accumulatedData?.map(
                                (item) => {
                                  return {
                                    id: item.id,
                                    prevbalance: item.owner_account_balance,
                                  };
                                }
                              );
                              setFailedRefreshId([]);
                              setSuccessRefreshId([]);
                              setRefreshtype("all");
                              setPrev_Total_balance_state(total_balance_state);
                              setID(true);
                              setRefreshAllData(selectedData);
                              setRefreshAll(true);
                            }, 500);
                          } else {
                            navigate("/feature_not_assigned", {
                              state: {
                                unavailableService: "VAccountBalance,Admin",
                              },
                            });
                          }
                        }
                      }}
                    >
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              zIndex: "2147483647 !important",
                            },
                          },
                        }}
                        title="Refresh all balances"
                      >
                        <img
                          className="h-[14px] w-[14px]"
                          src={Refreshicon}
                          alt=""
                        />
                      </Tooltip>
                    </div>
                  )}

                  <div
                    ref={filterButtonRef}
                    onClick={() => {
                      if (!showSearchBar) {
                        setFilterOpen(!filterOpen);
                        setSelectTypeofAccount(false);
                        setSelectSortAmt(false);
                      }
                    }}
                  >
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      title="Filters"
                    >
                      {!isAssignTagsClicked && (
                        <div
                          style={{
                            opacity: showSearchBar ? "0.6" : "1",
                            cursor: showSearchBar ? "default" : "pointer",
                            background: "transparent",
                            boxShadow: "none",
                            justifyContent: "center",
                            borderRight: "2px solid rgb(30, 58, 109)",
                            borderLeft: "2px solid rgb(30, 58, 109)",
                            borderRadius: "0",
                            alignItems: "center",
                            margin: "0",
                            height: "35px",
                            width: "35px",
                          }}
                          onClick={(e) => {
                            if (!showSearchBar) {
                              setFilterOpen(!filterOpen);

                              setSelectTypeofAccount(false);
                              setSelectSortAmt(false);
                            }
                          }}
                          className={`filter-containers ${
                            showSearchBar ? "" : "hover:bg-Blue_4_hover"
                          }`}
                        >
                          <Box className="filters-box">
                            <img
                              src={Filter}
                              alt=""
                              className="filter-images"
                            />
                          </Box>
                        </div>
                      )}
                    </Tooltip>
                  </div>

                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                          zIndex: "2147483647 !important",
                        },
                      },
                    }}
                    title="Search"
                  >
                    <div
                      ref={searchButtonRef}
                      style={{
                        backgroundColor:
                          searchUserText.length > 0 && "var(--Primary_white)",
                        opacity: filterOpen ? "0.6" : "1",
                        cursor: filterOpen ? "default" : "pointer",
                        backgroundColor: "transparent",
                        borderRadius: "0",
                        // borderRight: "2px solid #1e3a6d",
                        margin: "0",
                        height: "35px",
                        width: "35px",
                      }}
                      className={`owner_search_div ${
                        filterOpen ? "" : "hover:bg-Blue_4_hover"
                      }`}
                      onClick={() => {
                        if (!filterOpen) {
                          setShowSearchBar(!showSearchBar);
                        }
                      }}
                    >
                      <img
                        className="h-[14px] w-[14px]"
                        src={
                          searchUserText.length > 0 ? SearchBlue : SearchIcon
                        }
                        alt="search-image"
                      />
                    </div>
                  </Tooltip>
                  {/* <div
                    className="toggle-containers"
                    style={{
                      background: "transparent",
                      boxShadow: "none",
                      width: "35px",
                      height: "35px",
                      margin: "0",
                    }}
                  >
                    {toggle ? (
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              zIndex: "2147483647 !important",
                            },
                          },
                        }}
                        title="Grid"
                      >
                        <Box
                          onClick={() => {
                            if (!filterOpen && !showSearchBar) {
                              setFailedRefreshId([]);
                              setSuccessRefreshId([]);
                              dispatch(setToggle(false));
                              setCurrentPage(1);
                            }
                          }}
                          sx={{
                            backgroundColor: toggle ? "" : "#f9f9f9",
                            opacity: filterOpen || showSearchBar ? "0.6" : "1",
                            cursor:
                              filterOpen || showSearchBar
                                ? "default"
                                : "pointer",
                          }}
                          className={`toggle-box`}
                        >
                          <img
                            //src={toggle ? GridWhite : GridBlue}
                            src={ListWhite}
                            alt=""
                            className="toggle-images"
                          />
                        </Box>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              zIndex: "2147483647 !important",
                            },
                          },
                        }}
                        title="List"
                      >
                        <Box
                          onClick={() => {
                            if (!filterOpen && !showSearchBar) {
                              setFailedRefreshId([]);
                              setSuccessRefreshId([]);
                              dispatch(setToggle(true));
                              setCurrentPage(1);
                            }
                          }}
                          sx={{
                            backgroundColor: toggle ? "#f9f9f9" : "",
                            opacity: filterOpen || showSearchBar ? "0.6" : "1",
                            cursor:
                              filterOpen || showSearchBar
                                ? "default"
                                : "pointer",
                          }}
                          className={`toggle-box `}
                        >
                          <img
                            //src={toggle ? ListBlue : ListWhite}
                            src={GridWhite}
                            alt=""
                            className="toggle-images"
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </div> */}
                </div>
              </div>

              <div
                className={`filter-transition-${filterOpen ? "open" : "close"}`}
                style={{ backgroundColor: "#1d3a6d" }}
              >
                {filterOpen && (
                  <div ref={filterRef}>
                    <div
                      className="owner_filterdropdown"
                      style={{
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        padding: "0 15px",
                        overflow: "auto",
                        height: "82vh",
                      }}
                    >
                      <TypeOfAccountDropdown
                        selectTypeOfAccount={selectTypeOfAccount}
                        filterPayload={filterPayload}
                        setFilterPayload={setFilterPayload}
                        setSelectTypeofAccount={setSelectTypeofAccount}
                      />

                      <ConnectedBankingDropdown
                        filterPayload={filterPayload}
                        setFilterPayload={setFilterPayload}
                        selectConnectedBanking={selectConnectedBanking}
                        setSelectConnectedBanking={setSelectConnectedBanking}
                        owner={true}
                        typepop={typepop}
                      />

                      <SelectBank
                        option={bankOptions}
                        filterPayload={filterPayload}
                        setFilterPayload={setFilterPayload}
                        selectBank={showSelectBank}
                        setSelectBank={setShowSelectBank}
                        owner={true}
                      />
                      <div
                        style={{
                          borderBottom: "2px solid #031F4F",
                          margin: "12px 0",
                        }}
                      ></div>
                      <div>
                        <p className="owner_account_number_text">
                          Account number
                        </p>

                        <input
                          className="owner_account_number_text_input"
                          type="text"
                          value={filterPayload?.accountnumber?.toUpperCase()}
                          onChange={handleInput}
                          name="accountnumber"
                          placeholder="Enter account number"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div>
                        <p className="owner_account_number_text">IFSC code</p>

                        <input
                          maxLength={11}
                          className="owner_account_number_text_input"
                          type="text"
                          onChange={handleInput}
                          value={filterPayload?.ifscnumber?.toUpperCase()}
                          name="ifscnumber"
                          placeholder="Enter IFSC code"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div>
                        <p className="owner_account_number_text">
                          Note : Account number and IFSC code must be filled to
                          filter by bank details
                        </p>
                      </div>
                      <div
                        style={{
                          borderBottom: "2px solid #031F4F",
                          margin: "12px 0",
                        }}
                      ></div>

                      <div>
                        <p className="owner_account_number_text">PAN number</p>

                        <input
                          className="owner_account_number_text_input"
                          type="text"
                          onChange={handleInput}
                          value={filterPayload?.pannumber?.toUpperCase()}
                          name="pannumber"
                          placeholder="Enter PAN number"
                          style={{ width: "100%" }}
                        />
                      </div>

                      <div style={{ position: "relative" }}>
                        <p
                          style={{
                            color: "#C9C9C9",
                            height: "22px",
                            fontSize: "12px",
                          }}
                        >
                          Assigned tags
                        </p>
                        <div
                          // onClick={() => setSelectedTagOpenPop(true)}
                          onClick={() => {
                            toast.info("Feature Coming Soon!", toast_position);
                          }}
                          className="owner_connected_bank cursor-pointer"
                          style={{
                            height: "37px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              color: "#dadada",
                              padding: "8px 15px",
                              opacity: 0.5,
                            }}
                          >
                            <span
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              {filterPayload?.ownerTags?.length > 0
                                ? filterPayload?.ownerTags?.length + " tags"
                                : "Select tags"}
                            </span>
                            <img
                              src={Arrowdonw5}
                              alt="down"
                              style={{
                                height: "12px",
                                transform: "rotate(270deg) translate(0px, 5px)",
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <SelectAccountBalance
                        filterPayload={filterPayload}
                        setFilterPayload={setFilterPayload}
                        selectSortAmt={selectSortAmt}
                        setSelectSortAmt={setSelectSortAmt}
                        owner={true}
                      />
                    </div>
                    <div
                      className="buttoncontainer rounded-tl-[20px] rounded-tr-[20px]"
                      style={{
                        position: "fixed",
                        bottom: "0",
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        padding: "10px",
                        backgroundColor: "#3A5686",
                        zIndex: "999999",
                        gap: "30px",
                        height: "60px",
                      }}
                    >
                      <div
                        style={{
                          cursor: handleApplyEnable() ? "pointer" : "default",
                        }}
                        onClick={() => {
                          if (
                            filterPayload?.typeofaccount?.length > 0 ||
                            filterPayload?.accountnumber?.length > 0 ||
                            filterPayload?.connectedBanking?.length > 0 ||
                            filterPayload?.bankNameInput?.length > 0 ||
                            filterPayload?.ifscnumber?.length > 0 ||
                            filterPayload?.ownerTags?.length > 0 ||
                            filterPayload?.pannumber?.length > 0 ||
                            panstate.length === 10 ||
                            connectedbankingstate.length > 0 ||
                            accountbalancestate.length > 0 ||
                            ownerTagState.length > 0 ||
                            typeaccountstate.length > 0 ||
                            bankstate.length > 0 ||
                            ifscstate.length > 0 ||
                            accountnumberstate.length > 0 ||
                            filterPayload?.sortAccountNo?.length > 0
                          ) {
                            if (typepop === "popup") {
                              setConnectedbankingstate("Active");
                            } else {
                              setConnectedbankingstate("");
                            }
                            setbankstate("");
                            settypeaccountstate("");
                            setaccountbalancestate("");
                            setaccountnumberstate("");
                            setifscstate("");
                            setpanstate("");
                            setOwnerTagState([]);
                            setFilterPayload({
                              pagination: 0,
                              connectedBanking:
                                typepop === "popup" ? "Active" : "",
                              accNoInput: "",
                              sortBank: "",
                              sortAccountNo: "",
                              bankNameInput: "",
                              accountnumber: "",
                              pannumber: "",
                              ifscnumber: "",
                              typeofaccount: "",
                              ownerTags: [],
                              panFilter: false,
                              BankFilter: false,
                              TypeAccFilter: false,
                              ConnectedFilter:
                                typepop === "popup" ? true : false,
                              AccIfscFilter: false,
                              SortBankFilter: false,
                              TagFilter: false,
                            });
                            setCurrentPage(1);
                          }
                        }}
                        className={`clear ${
                          handleApplyEnable() ? "hover:bg-[#506994]" : ""
                        }`}
                      >
                        <p className="buttoncontainer-text">Clear</p>
                      </div>
                      <div
                        onClick={() => {
                          if (handleApplyEnable()) {
                            let obj = filterPayload;
                            if (
                              filterPayload?.accountnumber?.length > 8 &&
                              filterPayload?.ifscnumber?.length === 11
                            ) {
                              setaccountnumberstate(
                                filterPayload?.accountnumber
                              );
                              setifscstate(filterPayload?.ifscnumber);
                              obj = {
                                ...obj,
                                AccIfscFilter: true,
                              };
                            } else {
                              obj = {
                                ...obj,
                                AccIfscFilter: false,
                              };
                              setaccountnumberstate("");
                              setifscstate("");
                            }
                            if (filterPayload?.pannumber?.length === 10) {
                              setpanstate(filterPayload?.pannumber);
                              obj = {
                                ...obj,
                                panFilter: true,
                              };
                            } else {
                              obj = {
                                ...obj,
                                panFilter: false,
                              };
                              setpanstate("");
                            }
                            if (filterPayload?.bankNameInput?.length > 0) {
                              setbankstate(filterPayload?.bankNameInput);
                              obj = {
                                ...obj,
                                BankFilter: true,
                              };
                            } else {
                              obj = {
                                ...obj,
                                BankFilter: false,
                              };
                              setbankstate("");
                            }
                            if (filterPayload?.typeofaccount?.length > 0) {
                              settypeaccountstate(filterPayload?.typeofaccount);
                              obj = {
                                ...obj,
                                TypeAccFilter: true,
                              };
                            } else {
                              obj = {
                                ...obj,
                                TypeAccFilter: false,
                              };
                              settypeaccountstate("");
                            }
                            if (filterPayload?.sortAccountNo?.length > 0) {
                              setaccountbalancestate(
                                filterPayload?.sortAccountNo
                              );
                              obj = {
                                ...obj,
                                SortBankFilter: true,
                              };
                            } else {
                              obj = {
                                ...obj,
                                SortBankFilter: false,
                              };
                              setaccountbalancestate("");
                            }
                            if (filterPayload?.connectedBanking?.length > 0) {
                              setConnectedbankingstate(
                                filterPayload?.connectedBanking
                              );
                              obj = {
                                ...obj,
                                ConnectedFilter: true,
                              };
                            } else {
                              if (typepop === "popup") {
                                setConnectedbankingstate("Active");
                              } else {
                                obj = {
                                  ...obj,
                                  ConnectedFilter: false,
                                };
                                setConnectedbankingstate("");
                              }
                            }
                            if (filterPayload?.ownerTags?.length > 0) {
                              setOwnerTagState(filterPayload?.ownerTags);
                              obj = {
                                ...obj,
                                TagFilter: true,
                              };
                            } else {
                              obj = {
                                ...obj,
                                TagFilter: false,
                              };
                              setOwnerTagState([]);
                            }
                            setCurrentPage(1);
                            setFilterPayload(obj);
                            setFilterOpen(false);
                            setFilterApply(true);
                          }
                        }}
                        className={`apply ${
                          handleApplyEnable() ? "hover:opacity-90" : ""
                        }`}
                        style={{
                          cursor: handleApplyEnable() ? "pointer" : "default",
                          backgroundColor: handleApplyEnable()
                            ? "#FB5963"
                            : "#707070",
                        }}
                      >
                        <p className="buttoncontainer-text">Apply</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-full mx-auto">
                {showSearchBar && (
                  <RectangleSearchBar
                    handleSearch={setSearchUserText}
                    showSearchBar={showSearchBar}
                    searchUserText={searchUserText}
                    setSearchUserText={setSearchUserText}
                    onPaste={handlePaste}
                    toggleShowSearchBar={() => {
                      setFilterOpen(false);
                      setShowSearchBar(!showSearchBar);
                    }}
                    bgColor={"bg-[#1D3A6D]"}
                    bgHover={"hover:bg-custom-cardHover"}
                    setCurrentPage={setCurrentPage}
                    searchRef={searchRef}
                    placeholder="Search by owner account ID/ name/ email ID"
                    setIsAssignTagsClicked={setIsAssignTagsClicked}
                    setAssignTagsAccounts={setAssignTagsAccounts}
                  />
                )}
              </div>
            </div>
            {isAssignTagsClicked && (
              <div className="assign-tags-text-mob">
                <p>
                  Select accounts to apply tags. Tagged users will gain access
                  to view only owner accounts that share the same tags.
                </p>
              </div>
            )}

            {!isAssignTagsClicked && (
              <div
                className="assign-tags-div-mob  hover:bg-custom-cardHover"
                onClick={() => {
                  if (
                    !services?.includes("COwnerTag") &&
                    !services?.includes("DOwnerTag")
                  ) {
                    navigate("/feature_not_assigned", {
                      state: {
                        unavailableService: !services?.includes("COwnerTag")
                          ? "COwnerTag"
                          : "DOwnerTag",
                      },
                    });
                  }
                  handleScrollToTop();
                  handleOpenAssignTagsFlow();
                }}
                style={{
                  opacity:
                    showSearchBar ||
                    (!services?.includes("COwnerTag") &&
                      !services?.includes("DOwnerTag"))
                      ? "0.6"
                      : "1",
                  pointerEvents: showSearchBar ? "none" : "auto",
                  cursor:
                    !services?.includes("COwnerTag") &&
                    !services?.includes("DOwnerTag")
                      ? "default"
                      : "posinter",
                }}
              >
                <img src={assignTagImage} alt="assign tag img" />
                <p>Assign tags to accounts</p>
              </div>
            )}
            {filterApply &&
              (connectedbankingstate?.length > 0 ||
                accountbalancestate?.length > 0 ||
                ownerTagState?.length > 0 ||
                typeaccountstate?.length > 0 ||
                bankstate?.length > 0 ||
                panstate?.length > 0 ||
                accountnumberstate?.length > 0 ||
                ifscstate?.length > 0) && (
                <div className="filter_applied_div w-full">
                  <div
                    style={{
                      borderTop: "1px solid #031F4F",
                    }}
                    className="mx-auto py-[10px] flex pl-3 overflow-x-auto"
                  >
                    <p className="filterby_text flex-shrink-0">Filtered by :</p>
                    <div className="filter_badge_div">
                      {typeaccountstate?.length > 0 && (
                        <div className="filter_badge">
                          <p className="filter_badge_text">
                            {typeaccountstate}
                          </p>
                          <img
                            onClick={() => {
                              settypeaccountstate("");
                              let obj = filterPayload;
                              if (filterPayload?.ifscnumber?.length !== 11) {
                                obj = {
                                  ...obj,
                                  typeofaccount: "",
                                  ifscnumber: "",
                                  TypeAccFilter: false,
                                };
                              }
                              if (filterPayload?.pannumber?.length != 10) {
                                obj = {
                                  ...obj,
                                  typeofaccount: "",
                                  pannumber: "",
                                  TypeAccFilter: false,
                                };
                              }
                              if (filterPayload?.accountnumber?.length < 9) {
                                obj = {
                                  ...obj,
                                  typeofaccount: "",
                                  accountnumber: "",
                                  ifscnumber: "",
                                  TypeAccFilter: false,
                                };
                              } else {
                                obj = {
                                  ...obj,
                                  typeofaccount: "",
                                  TypeAccFilter: false,
                                };
                              }
                              setFilterPayload(obj);
                            }}
                            className="filter_badge_img"
                            src={clearFilter}
                            alt="X"
                          />
                        </div>
                      )}
                      {accountbalancestate.length > 0 && (
                        <div className="filter_badge">
                          <p className="filter_badge_text">
                            {accountbalancestate}
                          </p>
                          <img
                            onClick={() => {
                              setaccountbalancestate("");
                              let obj = filterPayload;
                              if (filterPayload?.ifscnumber?.length !== 11) {
                                obj = {
                                  ...obj,
                                  sortAccountNo: "",
                                  ifscnumber: "",
                                };
                              }
                              if (filterPayload?.pannumber?.length != 10) {
                                obj = {
                                  ...obj,
                                  sortAccountNo: "",
                                  pannumber: "",
                                };
                              }
                              if (filterPayload?.accountnumber?.length < 9) {
                                obj = {
                                  ...obj,
                                  sortAccountNo: "",
                                  accountnumber: "",
                                  ifscnumber: "",
                                };
                              } else {
                                obj = {
                                  ...obj,
                                  sortAccountNo: "",
                                };
                              }
                              setFilterPayload(obj);
                            }}
                            className="filter_badge_img"
                            src={clearFilter}
                            alt="X"
                          />
                        </div>
                      )}
                      {connectedbankingstate?.length > 0 && (
                        <div className="filter_badge">
                          <p className="filter_badge_text">
                            {connectedbankingstate}
                          </p>
                          <img
                            style={{
                              opacity: typepop === "popup" ? "0.4" : "1",
                              cursor:
                                typepop === "popup" ? "default" : "pointer",
                            }}
                            onClick={() => {
                              if (typepop === "popup") return;
                              setConnectedbankingstate("");

                              let obj = filterPayload;
                              if (filterPayload?.ifscnumber?.length !== 11) {
                                obj = {
                                  ...obj,
                                  connectedBanking: "",
                                  ifscnumber: "",
                                  ConnectedFilter: false,
                                };
                              }
                              if (filterPayload?.pannumber?.length != 10) {
                                obj = {
                                  ...obj,
                                  connectedBanking: "",
                                  pannumber: "",
                                  ConnectedFilter: false,
                                };
                              }
                              if (filterPayload?.accountnumber?.length < 9) {
                                obj = {
                                  ...obj,
                                  connectedBanking: "",
                                  accountnumber: "",
                                  ifscnumber: "",
                                  ConnectedFilter: false,
                                };
                              } else {
                                obj = {
                                  ...obj,
                                  connectedBanking: "",
                                  ConnectedFilter: false,
                                };
                              }
                              setFilterPayload(obj);
                            }}
                            className="filter_badge_img"
                            src={clearFilter}
                            alt="X"
                          />
                        </div>
                      )}
                      {ownerTagState?.length > 0 && (
                        <div className="filter_badge">
                          <p className="filter_badge_text">
                            {ownerTagState?.length} Tags
                          </p>
                          <img
                            onClick={() => {
                              setOwnerTagState([]);
                              let obj = filterPayload;
                              if (filterPayload?.ifscnumber?.length !== 11) {
                                obj = {
                                  ...obj,
                                  ownerTags: "",
                                  ifscnumber: "",
                                  TagFilter: false,
                                };
                              }
                              if (filterPayload?.pannumber?.length != 10) {
                                obj = {
                                  ...obj,
                                  ownerTags: "",
                                  pannumber: "",
                                  TagFilter: false,
                                };
                              }
                              if (filterPayload?.accountnumber?.length < 9) {
                                obj = {
                                  ...obj,
                                  ownerTags: "",
                                  accountnumber: "",
                                  ifscnumber: "",
                                  TagFilter: false,
                                };
                              } else {
                                obj = {
                                  ...obj,
                                  ownerTags: [],
                                  TagFilter: false,
                                };
                              }
                              setFilterPayload(obj);
                            }}
                            className="filter_badge_img"
                            src={clearFilter}
                            alt="X"
                          />
                        </div>
                      )}
                      {bankstate.length > 0 && (
                        <div className="filter_badge">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  zIndex: "2147483647 !important",
                                },
                              },
                            }}
                            title={bankstate}
                          >
                            <p className="filter_badge_text truncate overflow-ellipsis max-w-[80px]">
                              {bankstate}
                            </p>
                          </Tooltip>
                          <img
                            onClick={() => {
                              setbankstate("");
                              let obj = filterPayload;
                              if (filterPayload?.ifscnumber?.length !== 11) {
                                obj = {
                                  ...obj,
                                  bankNameInput: "",
                                  ifscnumber: "",
                                  BankFilter: false,
                                };
                              }
                              if (filterPayload?.pannumber?.length != 10) {
                                obj = {
                                  ...obj,
                                  bankNameInput: "",
                                  pannumber: "",
                                  BankFilter: false,
                                };
                              }
                              if (filterPayload?.accountnumber?.length < 9) {
                                obj = {
                                  ...obj,
                                  bankNameInput: "",
                                  accountnumber: "",
                                  ifscnumber: "",
                                  BankFilter: false,
                                };
                              } else {
                                obj = {
                                  ...obj,
                                  bankNameInput: "",
                                  BankFilter: false,
                                };
                              }
                              setFilterPayload(obj);
                            }}
                            className="filter_badge_img"
                            src={clearFilter}
                            alt="X"
                          />
                        </div>
                      )}
                      {accountnumberstate.length > 3 &&
                        ifscstate.length === 11 && (
                          <div className="filter_badge">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    zIndex: "2147483647 !important",
                                  },
                                },
                              }}
                              title={accountnumberstate}
                            >
                              <p className="filter_badge_text truncate overflow-ellipsis max-w-[120px]">
                                {accountnumberstate}
                              </p>
                            </Tooltip>
                            <img
                              onClick={() => {
                                setaccountnumberstate("");
                                setifscstate("");
                                if (filterPayload?.pannumber?.length != 10) {
                                  const obj = {
                                    ...filterPayload,
                                    accountnumber: "",
                                    ifscnumber: "",
                                    pannumber: "",
                                    AccIfscFilter: false,
                                  };
                                  setFilterPayload(obj);
                                } else {
                                  const obj = {
                                    ...filterPayload,
                                    accountnumber: "",
                                    ifscnumber: "",
                                    AccIfscFilter: false,
                                  };
                                  setFilterPayload(obj);
                                }
                              }}
                              className="filter_badge_img"
                              src={clearFilter}
                              alt="X"
                            />
                          </div>
                        )}
                      {panstate.length > 9 && (
                        <div className="filter_badge">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  zIndex: "2147483647 !important",
                                },
                              },
                            }}
                            title={panstate}
                          >
                            <p className="filter_badge_text">{panstate}</p>
                          </Tooltip>
                          <img
                            onClick={() => {
                              setpanstate("");
                              if (
                                filterPayload?.accountnumber?.length < 9 ||
                                filterPayload?.ifscnumber?.length !== 11
                              ) {
                                const obj = {
                                  ...filterPayload,
                                  accountnumber: "",
                                  ifscnumber: "",
                                  pannumber: "",
                                  panFilter: false,
                                };
                                setFilterPayload(obj);
                              } else {
                                const obj = {
                                  ...filterPayload,
                                  pannumber: "",
                                  panFilter: false,
                                };
                                setFilterPayload(obj);
                              }
                            }}
                            className="filter_badge_img"
                            src={clearFilter}
                            alt="X"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            <div
              ref={containerRef}
              className={
                loading
                  ? "overflow-none"
                  : filterOpen
                  ? " overflow-hidden ownerviewallscroll opacity-80  bg-black mt-[5px]"
                  : filteredData?.length === 0 || accumulatedData?.length === 0
                  ? " overflow-auto pb-[50px] ownerviewallscroll m-auto"
                  : " overflow-auto mb-[5px] ownerviewallscroll mt-[5px]"
              }
            >
              <div
                className={
                  (filterOpen ? `h-full bg-black opacity-80` : "") + "mt-[-5px]"
                }
                style={{ paddingBottom: typepop === "popup" ? "60px" : "" }}
              >
                <div className="flex flex-col items-center justify-center">
                  {loading ? (
                    <div
                      className={
                        isMobile
                          ? `loader-container m-auto justify-center items-center flex w-full h-full`
                          : `owner_loader-container m-auto justify-center pt-40 items-center flex w-[50px] h-[50px] max-h-[50px] max-w-[50px]`
                      }
                    >
                      <Loader />
                    </div>
                  ) : filteredData?.length > 0 ||
                    accumulatedData?.length > 0 ||
                    splitView ? (
                    <div
                      // here
                      className={`flex flex-col items-center justify-center p-2 mx-auto w-full ${
                        filterOpen && "bg-black opacity-10 z-[-9999]"
                      }`}
                    >
                      {!toggle || isAssignTagsClicked ? (
                        <OwnerViewAllCard
                          typepop={typepop}
                          handleButtonClick={handleButtonClick}
                          setIsNeft={setIsNeft}
                          setIsImps={setIsImps}
                          flow={flow}
                          setIsRtgs={setIsRtgs}
                          txnPayLoad={txnPayLoad}
                          setTxnPayLoad={setTxnPayLoad}
                          BenEnableNextButton={BenEnableNextButton}
                          setOpenOwner={setOpenOwner}
                          isopen={filterOpen}
                          setFailedRefreshId={setFailedRefreshId}
                          failedRefreshId={failedRefreshId}
                          successRefreshId={successRefreshId}
                          setSuccessRefreshId={setSuccessRefreshId}
                          handleRefresh={handleRefresh}
                          data={filteredData}
                          page={currentPage}
                          refreshAllData={refreshAllData}
                          refreshType={refreshType}
                          setRefreshtype={setRefreshtype}
                          setId={setID}
                          setPrev_Total_balance_state={
                            setPrev_Total_balance_state
                          }
                          total_balance={total_balance_state}
                          currency={selectAmtType}
                          splitView={splitView}
                          postiveBalanceData={postiveBalanceData}
                          negativeBalanceData={negativeBalanceData}
                          refreshAll={refreshAll}
                          handleViewStatement={handleViewStatement}
                          setStatementId={setStatementId}
                          accData={accData}
                          setAccData={setAccData}
                          multiSelect={multiSelect}
                          isAssignTagsClicked={isAssignTagsClicked}
                          owneraccountnumberstate={
                            isAssignTagsClicked
                              ? assignTagsAccounts
                              : owneraccountnumberstate
                          }
                          setowneraccountnumberstate={
                            isAssignTagsClicked
                              ? setAssignTagsAccounts
                              : setowneraccountnumberstate
                          }
                        />
                      ) : (
                        <ListView
                          typepop={typepop}
                          handleButtonClick={handleButtonClick}
                          setIsNeft={setIsNeft}
                          setIsImps={setIsImps}
                          setIsRtgs={setIsRtgs}
                          flow={flow}
                          txnPayLoad={txnPayLoad}
                          setTxnPayLoad={setTxnPayLoad}
                          BenEnableNextButton={BenEnableNextButton}
                          setOpenOwner={setOpenOwner}
                          handleRefresh={handleRefresh}
                          data={filteredData}
                          isopen={filterOpen}
                          page={currentPage}
                          refreshAllData={refreshAllData}
                          refreshType={refreshType}
                          setRefreshtype={setRefreshtype}
                          setFailedRefreshId={setFailedRefreshId}
                          failedRefreshId={failedRefreshId}
                          successRefreshId={successRefreshId}
                          setSuccessRefreshId={setSuccessRefreshId}
                          setId={setID}
                          setPrev_Total_balance_state={
                            setPrev_Total_balance_state
                          }
                          total_balance={total_balance_state}
                          currency={selectAmtType}
                          splitView={splitView}
                          postiveBalanceData={postiveBalanceData}
                          negativeBalanceData={negativeBalanceData}
                          refreshAll={refreshAll}
                          handleViewStatement={handleViewStatement}
                          setStatementId={setStatementId}
                          accData={accData}
                          setAccData={setAccData}
                        />
                      )}
                    </div>
                  ) : (
                    <div
                      className={
                        filterOpen
                          ? `h-full bg-black opacity-80 flex flex-col items-center justify-center m-auto`
                          : "flex flex-col items-center justify-center m-auto"
                      }
                    >
                      <img
                        src={noaccount}
                        alt="No accounts found"
                        style={{
                          width: typepop === "popup" ? "260px" : "360px",
                          height: typepop === "popup" ? "200px" : "300px",
                        }}
                        className={
                          filterOpen ? "no-acc-image-opacity" : "no-acc-image"
                        }
                      />
                      <p
                        className={
                          filterOpen
                            ? "no-owneraccount-text-opacity"
                            : "no-owneraccount-text"
                        }
                      >
                        No data found.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OwnerAccViewAll;
