import React, { useEffect, useState } from "react";
import "./index.css";
import successImage from "../../../assets/NewImg.png";
import createTransaction from "../../../assets/create_transaction.png";
import viewDetails from "../../../assets/view_details.png";
// import ArrowDown from "../../../assets/ArrowsIcons/arrow_down_blue_box.svg";
import ArrowDown from "../../../assets/ArrowsIcons/arrow_right_blue.svg";
import dashboard from "../../../assets/Dashboard.png";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { checkUsertoken } from "../../../redux/features/login/login";
import FormatAmount from "../../../components/_utils/FormatAmount/FormatAmount";
import { formatAccountNumber } from "../../../components/_utils/FormatAccountNumber";
import IfscCodeMapper from "../../../components/_utils/IfscLogoMapper";
import { beneInternalTxnSuccessDetail } from "../../../redux/features/internalTransaction/internalTransactionCreate";
import { resetViewAllAcc } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import pending_yellow from "../../../assets/PendingIcons/pending_yellow.svg";
import ConfidentialIcon from "../../../assets/GeneralIcons/confidential_transaction blue.svg";

const InternalTxnSuccessPage = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const transaction = useSelector((state) => state.beneTransactionAccounts);

  const {
    beneInternalTxnSuccessData,
    beneInternalTxnSuccessDataError,
    beneInternalTxnSuccessLoading,
  } = useSelector((state) => state?.internalTransactionCreate);

  useEffect(() => {
    if (
      beneInternalTxnSuccessDataError == "Request failed with status code 404"
    ) {
      navigate("*");
    }
  }, [beneInternalTxnSuccessDataError]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneInternalTxnSuccessDetail(id));
  }, [dispatch, id]);

  const viewDetailsHandler = () => {
    navigate(`/transaction/internal_transaction/details/${id}`);
  };

  const createAccountHandler = () => {
    navigate("/transaction/internal_transaction/create");
    dispatch(resetViewAllAcc());
  };

  const dashboardHandler = () => {
    navigate("/transaction/internal_transaction/dashboard");
  };

  return (
    <>
      {!isMobileView && (
        <div className="min-h-screen bg-[#457549]">
          <div className="flex justify-center">
            <div className="flex flex-row max-w-screen-lg justify-center">
              <div className="flex-1 mt-[66px] pt-[70px]">
                <div className="flex min-w-screen mx-5 justify-center items-center bg-[#457549]">
                  <div className="p-5 flex flex-col items-center justify-center text-center text-Primary_white text-2xl">
                    <img
                      src={successImage}
                      width={"100%"}
                      height={"100%"}
                      alt=""
                      srcSet=""
                    />
                    <div className="pt-5">
                      <p className="text-xl font-semibold text-Primary_white">
                        {beneInternalTxnSuccessData?.transaction[0]
                          .lifecycle_status === "settled" ? (
                          `Internal transaction settled successfully!`
                        ) : beneInternalTxnSuccessData?.transaction[0]
                            .lifecycle_status === "pending" ? (
                          `Internal transaction created successfully and
                              approval is pending.`
                        ) : beneInternalTxnSuccessData?.transaction[0]
                            .lifecycle_status === "approved" ? (
                          <>
                            <p className="txnMessage_top font-semibold text-Primary_white">
                              Internal transaction has been created and
                              approved.
                            </p>
                            <p className="txnMessage_bottom font-semibold text-Primary_white">
                              It will be auto settled once sufficient balance is
                              available in the source account.
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-1 mt-[66px] py-[35px]">
                <div
                  className="flex flex-col mx-5 bg-white rounded-t-[20px] rounded-b-[20px] items-center justify-between"
                  style={{ height: "554px", overflowY: "auto" }}
                >
                  <div className="w-full flex flex-col gap-1 pb-2">
                    <div className="flex flex-col font-bold pt-5 pb-3 px-7">
                      <div className="text-bold text-xl text-[#3B965E] w-full text-center pb-1">
                        <p
                          className="w-full"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: " nowrap",
                            textTransform: "capitalize",
                          }}
                        >
                          ₹
                          <span className="pl-[7px]">
                            <FormatAmount
                              price={
                                beneInternalTxnSuccessData?.transaction[0]
                                  ?.amount == undefined
                                  ? 0
                                  : beneInternalTxnSuccessData?.transaction[0]
                                      ?.amount
                              }
                            />
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="w-full flex items-center">
                      <div
                        className="w-full h-[2px] mx-5"
                        style={{
                          backgroundImage:
                            "repeating-linear-gradient(90deg, #DADADA, #DADADA 10px, transparent 5px, transparent 20px)",
                        }}
                      ></div>
                    </div>

                    <div className="overflow-y-auto h-[369px] pb-5 pt-3">
                      <div className="successDetailText"></div>

                      <div className="pl-5 pr-[15px]">
                        <div>
                          <p className="text-left font-normal text-[15px] leading-[22px] tracking-normal text-Primary_black opacity-100">
                            {formatAccountNumber(
                              beneInternalTxnSuccessData?.transaction[0]
                                ?.owner_account_bank_acc_no
                            )}
                          </p>
                          <span className="w-[134px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-Secondary_grey opacity-100">
                            Source account number
                          </span>
                        </div>
                        <div className="mt-[12px] h-[1px] bg-Primary_grey"></div>
                        <div className="flex justify-between pt-4">
                          <div>
                            <p className="w-[97px] h-[22px] text-left font-normal text-[15px] leading-[22px] tracking-normal text-Primary_black opacity-100">
                              {
                                beneInternalTxnSuccessData?.transaction[0]
                                  ?.owner_account_ifsc_code
                              }
                            </p>
                            <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-Secondary_grey opacity-100">
                              IFSC code
                            </span>
                          </div>
                          <div className="flex flex-row w-2/6 h-5 mt-3 justify-center">
                            <img
                              src={require("../../../assets/IfscLogo/" +
                                IfscCodeMapper(
                                  beneInternalTxnSuccessData?.transaction[0]
                                    ?.owner_account_ifsc_code
                                ))}
                              alt="bank"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-full flex justify-between pl-5 pr-[15px] pt-2">
                        <div className="w-[188px] h-0 border border-Primary_grey opacity-100"></div>
                        <div className="w-[20px] h-[20px] relative top-[-10px]">
                          <img className="rotate-90" src={ArrowDown} alt="" />
                        </div>
                        <div className="w-[188px] h-0 border border-Primary_grey opacity-100"></div>
                      </div>

                      <div className="pl-5 pr-[15px]">
                        <div>
                          <p className="text-left font-normal text-[15px] leading-[22px] tracking-normal text-Primary_black opacity-100">
                            {formatAccountNumber(
                              beneInternalTxnSuccessData?.transaction[0]
                                ?.internal_account_bank_acc_no
                            )}
                          </p>
                          <span className="w-[134px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-Secondary_grey opacity-100">
                            Destination account number
                          </span>
                        </div>

                        <div className="mt-[12px] h-[1px] bg-Primary_grey"></div>

                        <div className="flex justify-between pt-4">
                          <div>
                            <p className="w-[97px] h-[22px] text-left font-normal text-[15px] leading-[22px] tracking-normal text-Primary_black opacity-100">
                              {
                                beneInternalTxnSuccessData?.transaction[0]
                                  ?.internal_account_ifsc_code
                              }
                            </p>
                            <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-Secondary_grey opacity-100">
                              IFSC code
                            </span>
                          </div>
                          <div className="flex flex-row w-2/6 h-5 mt-3 justify-center">
                            <img
                              src={require("../../../assets/IfscLogo/" +
                                IfscCodeMapper(
                                  beneInternalTxnSuccessData?.transaction[0]
                                    ?.internal_account_ifsc_code
                                ))}
                              alt="bank"
                            />
                          </div>
                        </div>
                        {beneInternalTxnSuccessData?.transaction[0]
                          ?.confidential_transaction === "Y" && (
                          <>
                            <div className="mt-[12px] h-[1px] bg-Primary_grey"></div>
                            <div className="flex justify-between pt-4">
                              <div>
                                <p className="w-[97px] h-[22px] text-left font-normal text-[15px] leading-[22px] tracking-normal text-Primary_black opacity-100">
                                  Yes
                                </p>
                                <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-Secondary_grey opacity-100">
                                  Confidential transaction
                                </span>
                              </div>
                              <div className="flex flex-row w-2/6 h-[30px] mt-3 justify-center">
                                <img src={ConfidentialIcon} alt="pending" />
                              </div>
                            </div>
                          </>
                        )}

                        {beneInternalTxnSuccessData?.transaction[0]
                          .lifecycle_status === "pending" ? (
                          <>
                            <div className="w-full mt-[12px] h-[1.5px] bg-Primary_grey"></div>
                            <div className="flex justify-between pt-4">
                              <div>
                                <p className="w-[97px] h-[22px] text-left font-normal text-[15px] leading-[22px] tracking-normal text-Primary_black opacity-100">
                                  Pending
                                </p>
                                <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-Secondary_grey opacity-100">
                                  Transaction status
                                </span>
                              </div>
                              <div className="flex flex-row w-2/6 h-[30px] mt-3 justify-center">
                                <img src={pending_yellow} alt="pending" />
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full divide-x-2  cursor-pointer text-Primary_white rounded-[20px] bg-Bottom_tab flex justify-evenly items-center p-4 mt-[-35px]">
                    <div
                      className="flex flex-col space-y-1 w-2/6 h-3/4 items-center justify-center text-center "
                      onClick={createAccountHandler}
                    >
                      <div className="w-3/6 flex justify-center">
                        <img
                          width={"60%"}
                          height={"60%"}
                          src={createTransaction}
                          alt=""
                          srcSet=""
                        />
                      </div>
                      <p className="text-xs">
                        Create
                        <br />
                        transaction
                      </p>
                    </div>
                    <div
                      className="flex flex-col w-2/6 h-3/4 items-center justify-center text-center "
                      onClick={viewDetailsHandler}
                    >
                      <div className="w-3/6 flex justify-center">
                        <img
                          width={"60%"}
                          height={"60%"}
                          src={viewDetails}
                          alt=""
                          srcSet=""
                        />
                      </div>
                      <p className="text-xs">
                        View
                        <br />
                        details
                      </p>
                    </div>
                    <div
                      onClick={dashboardHandler}
                      className="flex flex-col space-y-1 w-2/6 h-3/4  items-center justify-center text-center  "
                    >
                      <div className="w-3/6 flex justify-center">
                        <img
                          width={"60%"}
                          height={"60%"}
                          src={dashboard}
                          alt=""
                          srcSet=""
                        />
                      </div>
                      <p className="text-xs">
                        Go to <br /> dashboard
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isMobileView && (
        <div
          className=" bg-white "
          style={{ height: window.innerHeight + "px" }}
        >
          <div className="flex flex-col max-w-screen-lg justify-center">
            <div className="bg-Settled flex flex-col justify-center items-center text-center pt-[28px] pb-[10px] h-auto px-[20px]">
              <div className="w-full flex justify-center">
                <img src={successImage} alt="Transaction Success" />
              </div>
              <p className="text-lg py-6 text-white">
                {beneInternalTxnSuccessData?.transaction[0].lifecycle_status ===
                "settled" ? (
                  "Internal transaction settled successfully!"
                ) : beneInternalTxnSuccessData?.transaction[0]
                    .lifecycle_status === "pending" ? (
                  "Internal transaction created successfully and approval is pending."
                ) : beneInternalTxnSuccessData?.transaction[0]
                    .lifecycle_status === "approved" ? (
                  <>
                    <p className="txnMessage_top">
                      Internal transaction has been created and approved.
                    </p>
                    <p className="txnMessage_bottom">
                      It will be auto settled once sufficient balance is
                      available in the source account.
                    </p>
                  </>
                ) : (
                  ""
                )}
              </p>
            </div>

            <div className="w-full bg-white pb-[92px]">
              <div className="w-full flex flex-col gap-1 px-5 pb-5">
                <div className="flex flex-col font-bold pt-4 pb-3 px-7">
                  <div className="text-bold text-2xl text-Pending w-full text-center">
                    <p
                      className="w-full"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: " nowrap",
                        textTransform: "capitalize",
                      }}
                    >
                      ₹
                      <FormatAmount
                        price={
                          beneInternalTxnSuccessData?.transaction[0]?.amount ==
                          undefined
                            ? 0
                            : beneInternalTxnSuccessData?.transaction[0]?.amount
                        }
                      />
                    </p>
                  </div>
                </div>

                <div className="w-full content-center">
                  <div className="mx-0 dashedLine"></div>
                </div>
                <div className="successDetailText"></div>

                <div className="w-full">
                  <div>
                    <p className="text-left font-normal text-[15px] leading-[22px] tracking-normal text-Primary_black opacity-100">
                      {formatAccountNumber(
                        beneInternalTxnSuccessData?.transaction[0]
                          ?.owner_account_bank_acc_no
                      )}
                    </p>
                    <span className="w-[134px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-Secondary_grey opacity-100">
                      Source account number
                    </span>
                  </div>
                  <div className="flex justify-between pt-4">
                    <div>
                      <p className="w-[97px] h-[22px] text-left font-normal text-[15px] leading-[22px] tracking-normal text-Primary_black opacity-100">
                        {
                          beneInternalTxnSuccessData?.transaction[0]
                            ?.owner_account_ifsc_code
                        }
                      </p>
                      <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-Secondary_grey opacity-100">
                        IFSC code
                      </span>
                    </div>
                    <div className="flex flex-row justify-end w-2/6 h-5 mt-3 mr-8">
                      <img
                        src={require("../../../assets/IfscLogo/" +
                          IfscCodeMapper(
                            beneInternalTxnSuccessData?.transaction[0]
                              ?.owner_account_ifsc_code
                          ))}
                        alt="bank"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-between pt-2 translate-y-[5px]">
                  <div className="w-[40vw] h-0 border border-[#A0A0A0] opacity-100"></div>
                  <div className="w-[20px] h-[20px] relative top-[-10px]">
                    <img className="rotate-90" src={ArrowDown} alt="" />
                  </div>
                  <div className="w-[40vw] h-0 border border-[#A0A0A0] opacity-100"></div>
                </div>

                <div className="w-full">
                  <div>
                    <p className="text-left font-normal text-[15px] leading-[22px] tracking-normal text-Primary_black opacity-100">
                      {formatAccountNumber(
                        beneInternalTxnSuccessData?.transaction[0]
                          ?.beneficiary_account_bank_acc_no
                      )}
                    </p>
                    <span className="w-[134px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-Secondary_grey opacity-100">
                      Destination account number
                    </span>
                  </div>
                  <div className="flex justify-between pt-4">
                    <div>
                      <p className="w-[97px] h-[22px] text-left font-normal text-[15px] leading-[22px] tracking-normal text-Primary_black opacity-100">
                        {
                          beneInternalTxnSuccessData?.transaction[0]
                            ?.internal_account_ifsc_code
                        }
                      </p>
                      <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-Secondary_grey opacity-100">
                        IFSC code
                      </span>
                    </div>
                    <div className="flex flex-row justify-end w-2/6 h-5 mt-3 mr-8">
                      <img
                        src={require("../../../assets/IfscLogo/" +
                          IfscCodeMapper(
                            beneInternalTxnSuccessData?.transaction[0]
                              ?.internal_account_ifsc_code
                          ))}
                        alt="bank"
                      />
                    </div>
                  </div>
                </div>

                {beneInternalTxnSuccessData?.transaction[0]
                  ?.confidential_transaction === "Y" && (
                  <>
                    <div className="mt-[12px] h-[2.5px] bg-Primary_grey"></div>
                    <div className="flex justify-between pt-4">
                      <div>
                        <p className="w-[97px] h-[22px] text-left font-normal text-[15px] leading-[22px] tracking-normal text-Primary_black opacity-100">
                          Yes
                        </p>
                        <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-Secondary_grey opacity-100">
                          Confidential transaction
                        </span>
                      </div>
                      <div className="flex flex-row w-2/6 h-[30px] mt-3 justify-center">
                        <img src={ConfidentialIcon} alt="pending" />
                      </div>
                    </div>
                  </>
                )}

                <div className="mt-[12px] h-[2.5px] bg-Primary_grey"></div>
                {beneInternalTxnSuccessData?.transaction[0].lifecycle_status ===
                "pending" ? (
                  <div className="flex justify-between pt-4 pb-4">
                    <div>
                      <p className="w-[97px] h-[22px] text-left font-normal text-[15px] leading-[22px] tracking-normal text-Primary_black opacity-100">
                        Pending
                      </p>
                      <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-[17px] tracking-normal text-Secondary_grey opacity-100">
                        Transaction status
                      </span>
                    </div>
                    <div className="flex flex-row w-2/6 h-[30px] mt-3 justify-center">
                      <img src={pending_yellow} alt="pending" />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="w-full divide-x-2  cursor-pointer text-Primary_white rounded-t-lg bg-Bottom_tab flex justify-evenly items-center p-4 bottom-0 fixed">
              <div
                className="flex flex-col space-y-1 w-2/6 h-3/4  items-center justify-center text-center "
                onClick={createAccountHandler}
              >
                <div className="w-3/6 flex justify-center">
                  <img
                    width={"60%"}
                    height={"60%"}
                    src={createTransaction}
                    alt=""
                    srcSet=""
                  />
                </div>
                <p className="text-xs">
                  Create
                  <br />
                  transaction
                </p>
              </div>
              <div
                className="flex flex-col w-2/6 h-3/4 items-center justify-center text-center "
                onClick={viewDetailsHandler}
              >
                <div className="w-3/6 flex justify-center">
                  <img
                    width={"60%"}
                    height={"60%"}
                    src={viewDetails}
                    alt=""
                    srcSet=""
                  />
                </div>
                <p className="text-xs">
                  View
                  <br />
                  details
                </p>
              </div>
              <div
                onClick={dashboardHandler}
                className="flex flex-col space-y-1 w-2/6 h-3/4  items-center justify-center text-center  "
              >
                <div className="w-3/6 flex justify-center">
                  <img
                    width={"60%"}
                    height={"60%"}
                    src={dashboard}
                    alt=""
                    srcSet=""
                  />
                </div>
                <p className="text-xs">
                  Go to <br /> dashboard
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InternalTxnSuccessPage;
