import React, { useState, useEffect, useRef } from "react";
import arrow_down_red from "../../../../src/assets/ArrowsIcons/arrow_down_white_filled.svg";
import allChecker from "../../../../src/assets/CheckmarksIcons/check_mark_white.svg";
import "./index.css";

export const ActionDrpDown = ({
  ddlValue,
  optionalLabel,
  isFieldRequired,
  fieldname,
  handleInputChange,
  data_type,
  type,
  setShowDatePicker,
  value,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value); // Store local selected value

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleSelect = (val, fieldName, data_type) => {
    if (type === "checkbox") {
      const currentSelections = selectedValue === "Select" ? [] : selectedValue;

      let store = currentSelections.includes(val)
        ? currentSelections.filter((item) => item !== val)
        : [...currentSelections, val];

      setSelectedValue(store.length === 0 ? "" : store);
      handleInputChange({
        target: {
          name: fieldName,
          value: store.length === 0 ? "" : store,
          data_typeChan: data_type,
        },
      });
    } else {
      setDropdownOpen(false);
      setSelectedValue(val);
      handleInputChange({
        target: {
          name: fieldName,
          value: data_type === "year" ? val.toString() : val,
          data_typeChan: data_type,
        },
      });
    }
  };

  const dropdownRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-[100%] mt-[5px]" ref={dropdownRef}>
      <div
        className="headerText"
        id="uploadId"
        style={{
          color:
            selectedValue === "Select" ||
            selectedValue === "" ||
            selectedValue?.length === 0
              ? "#F9F9F9"
              : selectedValue === "Not Given"
              ? "#F9F9F9"
              : "#F9F9F9",
        }}
      >
        {`${fieldname}${isFieldRequired ? "*" : ""}${optionalLabel}`}
      </div>
      <div
        className={`selectDrp ${dropdownOpen ? "open" : ""}`}
        onClick={() => {
          setShowDatePicker(false);
          setDropdownOpen(!dropdownOpen);
        }}
      >
        <div className="selectDrp_select">
          {type === "checkbox" ? (
            <span
              style={{
                color:
                  selectedValue === "Select" || selectedValue?.length === 0
                    ? "#DADADA"
                    : "#f9f9f9",
                textTransform: "capitalize !important",
              }}
            >
              {selectedValue === "Select"
                ? "Select"
                : `${selectedValue?.length} selected`}
            </span>
          ) : (
            <span
              style={{
                color:
                  selectedValue === "Select" ||
                  selectedValue === "" ||
                  selectedValue === "Not Given"
                    ? "#DADADA"
                    : "#f9f9f9",
              }}
            >
              {selectedValue === "Not Given" ? "Select" : selectedValue}
            </span>
          )}

          <img
            src={arrow_down_red}
            alt="down"
            style={{
              transform: dropdownOpen ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </div>
        <div
          className="selectDrp_scroll_line"
          style={{ display: dropdownOpen ? "block" : "none" }}
        ></div>

        {dropdownOpen && (
          <div className="scroll_content cursor-pointer">
            {type ? (
              type === "checkbox" ? (
                <ul>
                  {ddlValue.map((val, index) => (
                    <li
                      key={`rd${index}`}
                      className={`category-wrapper checkbox ${
                        selectedValue.includes(val) ? "selected" : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelect(val, fieldname, data_type);
                      }}
                    >
                      <input
                        type={type}
                        name={`val${index}`}
                        checked={selectedValue.includes(val)}
                        onChange={() => handleSelect(val, fieldname, data_type)}
                        required={isFieldRequired}
                      />
                      <label>{val}</label>
                      {/* {selectedValue.includes(val) && (
                        <img
                          src={allChecker}
                          alt="Checkmark"
                          className="all-txn-status-icon_acc"
                        />
                      )} */}
                    </li>
                  ))}
                </ul>
              ) : (
                <ul>
                  {ddlValue.map((val, index) => (
                    <li
                      key={`rd${index}`}
                      className={`category-wrapper ${
                        selectedValue === val ? "selected" : ""
                      }`}
                      onClick={() => handleSelect(val, fieldname, data_type)}
                    >
                      <label>{val}</label>
                      {selectedValue === val && (
                        <img
                          src={allChecker}
                          alt="Checkmark"
                          className="all-txn-status-icon_acc"
                        />
                      )}
                    </li>
                  ))}
                </ul>
              )
            ) : (
              <>
                {ddlValue.map((val, index) => (
                  <div
                    key={`rd${index}`}
                    className={`category-wrapper ${
                      selectedValue === val ? "selected" : ""
                    }`}
                    onClick={() => handleSelect(val, fieldname, data_type)}
                  >
                    {val}
                    {selectedValue === val && (
                      <img
                        src={allChecker}
                        alt="Checkmark"
                        className="all-txn-status-icon_acc"
                      />
                    )}
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionDrpDown;
