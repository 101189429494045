import React, { useRef, useEffect } from "react";
import { tooltipClasses, Tooltip } from "@mui/material";
import "./index.css";
import { Button } from "@mui/material";
import ArrowDown from "../../../assets/ArrowsIcons/arrow_down_white_filled.svg";
import CheckIcon from "../../../assets/CheckmarksIcons/check_mark_white.svg";
import clear_filter from "../../../assets/CloseIcons/close.svg";

const OwnerAccStatisticsDropdown = ({
  // filterPayload,
  // setFilterPayload,
  // selectTypeOfAccount,
  // setSelectTypeofAccount,
  setAmountRange,
  amountRange,
  amountRangeValue,
  setAmountRangeValue,
  setInvestmentRate,
  investmentRate,
  investmentRateValue,
  setInvestmentRateValue,
  selectAmountRange,
  setSelectAmountRange,
  selectInvestmentRate,
  setSelectInvestmentRate,
  setInfoState,
  type,
}) => {
  const accountBalanceRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountBalanceRef.current &&
        !accountBalanceRef.current.contains(event.target)
      ) {
        if (type === "amountRange") {
          setSelectAmountRange(false);
        } else if (type === "investment") {
          setSelectInvestmentRate(false);
        }
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="owner_type_of_acc_balance">
        <Button
          ref={accountBalanceRef}
          className="owner_detail_type_of_acc_select"
          onClick={() => {
            setInfoState(false);
            if (type === "amountRange") {
              setSelectAmountRange(!selectAmountRange);
            } else if (type === "investment") {
              setSelectInvestmentRate(!selectInvestmentRate);
            }
          }}
          sx={{
            zIndex:
              type === "amountRange" && selectAmountRange
                ? "999 !important"
                : type === "investment" && selectInvestmentRate
                ? "99 !important"
                : "",
            height:
              type === "amountRange" && selectAmountRange
                ? "196px"
                : type === "investment" && selectInvestmentRate
                ? "165px"
                : "37px",
            outline:
              selectAmountRange || selectInvestmentRate
                ? "1px solid #E25869"
                : "",
          }}
        >
          <div className="owner_detail_type_of_acc_select_select">
            <div
              className="owner_type_of_acc_select_scroll_line"
              style={{
                display:
                  selectAmountRange || selectInvestmentRate ? "block" : "none",
              }}
            ></div>

            <span
              style={{
                fontSize: "12px",
                paddingLeft: "14px",
                width:
                  (type === "investment" && investmentRateValue !== 6.62) ||
                  (type === "amountRange" && amountRangeValue !== "")
                    ? "15.625rem"
                    : null,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                color:
                  (type === "investment" && investmentRateValue !== 6.62) ||
                  (type === "amountRange" && amountRangeValue !== "")
                    ? "var(--Primary_white)"
                    : "var(--Primary_grey)",
              }}
            >
              {type === "amountRange" ? (
                amountRange
              ) : type === "investment" ? (
                <>
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title={investmentRate}
                  >
                    {investmentRate}
                  </Tooltip>
                </>
              ) : null}
            </span>
            <div className="flex flex-row items-center">
              {type === "amountRange" && amountRangeValue !== "" ? (
                <>
                  <img
                    src={clear_filter}
                    alt="clear"
                    className="w-[10px] h-[10px]"
                    style={{
                      visibility:
                        amountRangeValue !== "" ? "visible" : "hidden",
                    }}
                    onClick={(e) => {
                      setAmountRange("Absolute (amount)");
                      setAmountRangeValue("");
                      e.stopPropagation();
                    }}
                  />
                  <div
                    className="w-[1px] h-[16px] mx-[8px]"
                    style={{
                      backgroundColor: "var(--Blue_3)",
                      visibility:
                        amountRangeValue !== "" ? "visible" : "hidden",
                    }}
                  ></div>
                </>
              ) : type === "investment" && investmentRateValue !== 6.62 ? (
                <>
                  <img
                    src={clear_filter}
                    alt="clear"
                    className="w-[10px] h-[10px]"
                    style={{
                      visibility:
                        investmentRateValue !== 6.62 ? "visible" : "hidden",
                    }}
                    onClick={(e) => {
                      setInvestmentRate(
                        "Bank of India Overnight Fund-Reg(G) - 6.62%"
                      );
                      setInvestmentRateValue(6.62);

                      e.stopPropagation();
                    }}
                  />
                  <div
                    className="w-[1px] h-[16px] mx-[8px]"
                    style={{
                      backgroundColor: "var(--Blue_3)",
                      visibility:
                        investmentRateValue !== 6.62 ? "visible" : "hidden",
                    }}
                  ></div>
                </>
              ) : null}
              <img
                src={ArrowDown}
                alt="down"
                className="w-[11px] h-[11px]"
                style={{
                  transform:
                    selectAmountRange || selectInvestmentRate
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                }}
              />
            </div>
            <div
              style={{
                display:
                  selectAmountRange || selectInvestmentRate ? "block" : "none",
              }}
              className="type_of_acc_select_scroll"
            >
              {type === "amountRange" ? (
                <>
                  <Button
                    className="owner_detail_type_of_acc_select_scroll_com hover:bg-Blue_7"
                    style={{
                      backgroundColor:
                        amountRange === "Absolute (amount)"
                          ? "var(--Blue_6)"
                          : "",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAmountRange("Absolute (amount)");
                      setAmountRangeValue("");
                      setSelectAmountRange(false);
                    }}
                  >
                    Absolute (amount)
                    <div
                      style={{
                        display:
                          amountRange === "Absolute (amount)" ? "flex" : "none",
                        textAlign: "right",
                        justifyContent: "end",
                        paddingRight: "1px",
                      }}
                    >
                      <img
                        style={{
                          height: "13px",
                          width: "11px",
                        }}
                        src={CheckIcon}
                        alt="check"
                      />
                    </div>
                  </Button>

                  <Button
                    className="owner_detail_type_of_acc_select_scroll_com hover:bg-Blue_7"
                    style={{
                      backgroundColor:
                        amountRange === "Amount in lakhs"
                          ? "var(--Blue_6)"
                          : "",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAmountRange("Amount in lakhs");
                      setAmountRangeValue("Lakhs");
                      setSelectAmountRange(false);
                    }}
                  >
                    Amount in lakhs
                    <div
                      style={{
                        display:
                          amountRange === "Amount in lakhs" ? "flex" : "none",
                        textAlign: "right",
                        justifyContent: "end",
                        paddingRight: "1px",
                      }}
                    >
                      <img
                        style={{
                          height: "13px",
                          width: "11px",
                        }}
                        src={CheckIcon}
                        alt="check"
                      />
                    </div>
                  </Button>

                  <Button
                    className="owner_detail_type_of_acc_select_scroll_com hover:bg-Blue_7"
                    style={{
                      backgroundColor:
                        amountRange === "Amount in crores"
                          ? "var(--Blue_6)"
                          : "",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAmountRange("Amount in crores");
                      setAmountRangeValue("Crores");
                      setSelectAmountRange(false);
                    }}
                  >
                    Amount in crores
                    <div
                      style={{
                        display:
                          amountRange === "Amount in crores" ? "flex" : "none",
                        textAlign: "right",
                        justifyContent: "end",
                        paddingRight: "1px",
                      }}
                    >
                      <img
                        style={{
                          height: "13px",
                          width: "11px",
                        }}
                        src={CheckIcon}
                        alt="check"
                      />
                    </div>
                  </Button>

                  <Button
                    className="owner_detail_type_of_acc_select_scroll_com hover:bg-Blue_7"
                    style={{
                      backgroundColor:
                        amountRange === "Amount in millions"
                          ? "var(--Blue_6)"
                          : "",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAmountRange("Amount in millions");
                      setAmountRangeValue("Millions");
                      setSelectAmountRange(false);
                    }}
                  >
                    Amount in millions
                    <div
                      style={{
                        display:
                          amountRange === "Amount in millions"
                            ? "flex"
                            : "none",
                        textAlign: "right",
                        justifyContent: "end",
                        paddingRight: "1px",
                      }}
                    >
                      <img
                        style={{
                          height: "13px",
                          width: "11px",
                        }}
                        src={CheckIcon}
                        alt="check"
                      />
                    </div>
                  </Button>

                  <Button
                    className="owner_detail_type_of_acc_select_scroll_com hover:bg-Blue_7"
                    style={{
                      backgroundColor:
                        amountRange === "Amount in billions"
                          ? "var(--Blue_6)"
                          : "",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAmountRange("Amount in billions");
                      setAmountRangeValue("Billions");
                      setSelectAmountRange(false);
                    }}
                  >
                    Amount in billions
                    <div
                      style={{
                        display:
                          amountRange === "Amount in billions"
                            ? "flex"
                            : "none",
                        textAlign: "right",
                        justifyContent: "end",
                        paddingRight: "1px",
                      }}
                    >
                      <img
                        style={{
                          height: "13px",
                          width: "11px",
                        }}
                        src={CheckIcon}
                        alt="check"
                      />
                    </div>
                  </Button>
                </>
              ) : type === "investment" ? (
                <>
                  <Button
                    className="owner_detail_type_of_acc_select_scroll_com hover:bg-Blue_7"
                    style={{
                      backgroundColor:
                        investmentRate ===
                        "Bank of India Overnight Fund-Reg(G) - 6.62%"
                          ? "var(--Blue_6)"
                          : "",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setInvestmentRate(
                        "Bank of India Overnight Fund-Reg(G) - 6.62%"
                      );
                      setInvestmentRateValue(6.62);
                      setSelectInvestmentRate(false);
                    }}
                  >
                    Bank of India Overnight Fund-Reg(G) - 6.62%
                    <div
                      style={{
                        display:
                          investmentRate ===
                          "Bank of India Overnight Fund-Reg(G) - 6.62%"
                            ? "flex"
                            : "none",
                        textAlign: "right",
                        justifyContent: "end",
                        paddingRight: "1px",
                      }}
                    >
                      <img
                        style={{
                          height: "13px",
                          width: "11px",
                        }}
                        src={CheckIcon}
                        alt="check"
                      />
                    </div>
                  </Button>

                  <Button
                    className="owner_detail_type_of_acc_select_scroll_com hover:bg-Blue_7"
                    style={{
                      backgroundColor:
                        investmentRate ===
                        "Franklin India Overnight Fund(G) - 6.51%"
                          ? "var(--Blue_6)"
                          : "",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setInvestmentRate(
                        "Franklin India Overnight Fund(G) - 6.51%"
                      );
                      setInvestmentRateValue(6.51);
                      setSelectInvestmentRate(false);
                    }}
                  >
                    Franklin India Overnight Fund(G) - 6.51%
                    <div
                      style={{
                        display:
                          investmentRate ===
                          "Franklin India Overnight Fund(G) - 6.51%"
                            ? "flex"
                            : "none",
                        textAlign: "right",
                        justifyContent: "end",
                        paddingRight: "1px",
                      }}
                    >
                      <img
                        style={{
                          height: "13px",
                          width: "11px",
                        }}
                        src={CheckIcon}
                        alt="check"
                      />
                    </div>
                  </Button>

                  <Button
                    className="owner_detail_type_of_acc_select_scroll_com hover:bg-Blue_7"
                    style={{
                      backgroundColor:
                        investmentRate === "Kotak Overnight Fund-Reg(G) - 6.54%"
                          ? "var(--Blue_6)"
                          : "",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setInvestmentRate("Kotak Overnight Fund-Reg(G) - 6.54%");
                      setInvestmentRateValue(6.54);
                      setSelectInvestmentRate(false);
                    }}
                  >
                    Kotak Overnight Fund-Reg(G) - 6.54%
                    <div
                      style={{
                        display:
                          investmentRate ===
                          "Kotak Overnight Fund-Reg(G) - 6.54%"
                            ? "flex"
                            : "none",
                        textAlign: "right",
                        justifyContent: "end",
                        paddingRight: "1px",
                      }}
                    >
                      <img
                        style={{
                          height: "13px",
                          width: "11px",
                        }}
                        src={CheckIcon}
                        alt="check"
                      />
                    </div>
                  </Button>

                  <Button
                    className="owner_detail_type_of_acc_select_scroll_com hover:bg-Blue_7"
                    style={{
                      backgroundColor:
                        investmentRate ===
                        "Nippon India Overnight Fund-Reg(G) - 6.53%"
                          ? "var(--Blue_6)"
                          : "",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setInvestmentRate(
                        "Nippon India Overnight Fund-Reg(G) - 6.53%"
                      );
                      setInvestmentRateValue(6.53);
                      setSelectInvestmentRate(false);
                    }}
                  >
                    Nippon India Overnight Fund-Reg(G) - 6.53%
                    <div
                      style={{
                        display:
                          investmentRate ===
                          "Nippon India Overnight Fund-Reg(G) - 6.53%"
                            ? "flex"
                            : "none",
                        textAlign: "right",
                        justifyContent: "end",
                        paddingRight: "1px",
                      }}
                    >
                      <img
                        style={{
                          height: "13px",
                          width: "11px",
                        }}
                        src={CheckIcon}
                        alt="check"
                      />
                    </div>
                  </Button>
                </>
              ) : null}
            </div>
          </div>
        </Button>
      </div>
    </>
  );
};

export default OwnerAccStatisticsDropdown;
