import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { env } from "../../../env";
import { toast_position } from "../Settings/Authorization";
import { toast } from "react-toastify";

const baseUrl = env.REACT_APP_BASE_URL;

export const BBPS_Catergories = createAsyncThunk(
  "BBPS_Catergories",
  async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/bbps_billers/biller_categories`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response?.data;
    } catch (error) {
      toast.error(error.response.data.errors.base[0], toast_position);
      throw error;
    }
  }
);

export const operatorList = createAsyncThunk(
  "operatorList",
  async (values, { rejectWithValue }) => {
    const { operator_category, search_string } = values;

    try {
      const response = await axios.get(
        `${baseUrl}/bbps_billers/live_search_operator_list`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
          params: {
            operator_category: operator_category,
            search_string: search_string,
          },
        }
      );

      if (response.data?.errors) {
        toast.error(response.data.errors.base[0], toast_position);
      }

      return response.data;
    } catch (error) {
      console.error("Error", error);
      return rejectWithValue(error);
    }
  }
);

export const BBPS_SpecificBiller = createAsyncThunk(
  "BBPS_SpecificBiller",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}/bbps_billers/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
      });

      return response.data;
    } catch (error) {
      toast.error(error.response.data.errors.base[0], toast_position);
      throw error;
    }
  }
);

export const BBPS_DeleteBiller = createAsyncThunk(
  "BBPS_DeleteBiller",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${baseUrl}/bbps_billers/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
      });

      return response.data;
    } catch (error) {
      toast.error(error.response.data.errors.base[0], toast_position);
      throw error;
    }
  }
);

export const BBPS_FetchBiller = createAsyncThunk(
  "BBPS_FetchBiller",
  async (values, { rejectWithValue }) => {
    const { page_no, page_size } = values;
    try {
      const response = await axios.get(`${baseUrl}/bbps_billers`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: values.page_no,
          page_size: values.page_size,
        },
      });

      return response.data;
    } catch (error) {
      toast.error(error.response.data.errors.base[0], toast_position);
      throw error;
    }
  }
);
export const getBillerInputParams = createAsyncThunk(
  "getBillerInputParams",
  async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/bbps_billers/biller_bill_parameters`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
          params: {
            operator_id: id, // Pass the id as a query parameter
          },
        }
      );

      return response.data;
    } catch (error) {
      toast.error(error.message, toast_position);
      console.error("Error", error);
      throw error;
    }
  }
);

export const GetAllBillers = createAsyncThunk(
  "GetAllBillers",
  async (values, { rejectWithValue }) => {
    const { page_no, page_size } = values;
    try {
      const response = await axios.get(`${baseUrl}/bbps_billers`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page_no,
          page_size: page_size,
        },
      });

      return response.data;
    } catch (error) {
      toast.error(error.response.data.errors.base[0], toast_position);
      return rejectWithValue(error);
    }
  }
);

export const createBiller = createAsyncThunk(
  "createBiller",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseUrl}/bbps_billers`,
        values, // Send `values` as the request body for a POST request
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );

      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.base?.[0] ||
        "An error occurred while creating the biller!";
      toast.error(errorMessage, toast_position);
      return rejectWithValue(errorMessage);
    }
  }
);

const bbpsAccountSlice = createSlice({
  name: "bbpsCreateAccount",
  initialState: {
    BBPS_CatergoriesAccData: [],
    BBPS_CatergoriesAccLoading: null,
    BBPS_CatergoriesAccError: null,

    operatorData: [],
    operatorLoading: null,
    operatorError: null,

    BBPSAccLoading: null,
    BBPSAccData: null,
    BBPSAccError: null,

    bppsViewAllAccData: null,
    bppsViewAllAccLoading: true,
    bppsViewAllAccError: false,
    bppsViewAllAccErrorData: null,

    BBPSAccDashLoading: null,
    BBPSAccDashData: null,
    BBPSAccDashError: null,

    BillerParamsLoading: false,
    BillerParamsData: [],
    BillerParamsError: null,

    createBillerLoading: false,
    createBillerOutput: null,
    createBillerError: null,

    BBPSAccDashLoading: null,
    BBPSAccDashData: null,
    BBPSAccDashError: null,
    bpps_StoredPage: 1,
  },
  reducers: {
    resetpage: (state) => {
      state.bpps_StoredPage = 1;
    },
    setStoredPage: (state, action) => {
      state.bpps_StoredPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(operatorList.pending, (state, action) => {
      state.operatorLoading = true;
    });
    builder.addCase(operatorList.fulfilled, (state, action) => {
      state.operatorLoading = false;
      state.operatorData = Array.isArray(action.payload.live_operator_lists)
        ? action.payload.live_operator_lists
        : [];
    });
    builder.addCase(operatorList.rejected, (state, action) => {
      state.operatorData = [];
      state.operatorLoading = false;
    });
    builder.addCase(BBPS_Catergories.pending, (state, action) => {
      state.BBPS_CatergoriesAccLoading = true;
    });
    builder.addCase(BBPS_Catergories.fulfilled, (state, action) => {
      state.BBPS_CatergoriesAccLoading = false;
      state.BBPS_CatergoriesAccData = Array.isArray(action.payload.categories)
        ? action.payload.categories
        : [];
    });
    builder.addCase(BBPS_Catergories.rejected, (state, action) => {
      state.BBPS_CatergoriesAccError = true;
      state.BBPS_CatergoriesAccData = null;
    });

    builder.addCase(BBPS_SpecificBiller.pending, (state, action) => {
      state.BBPSAccLoading = true;
    });
    builder.addCase(BBPS_SpecificBiller.fulfilled, (state, action) => {
      state.BBPSAccLoading = false;
      state.BBPSAccData = action.payload;
    });
    builder.addCase(BBPS_SpecificBiller.rejected, (state, action) => {
      state.BBPSAccError = true;
    });

    builder.addCase(BBPS_DeleteBiller.pending, (state, action) => {
      state.BBPSAccLoading = true;
    });
    builder.addCase(BBPS_DeleteBiller.fulfilled, (state, action) => {
      state.BBPSAccLoading = false;
      state.BBPSAccData = action.payload;
    });
    builder.addCase(BBPS_DeleteBiller.rejected, (state, action) => {
      state.BBPSAccError = true;
    });

    builder.addCase(BBPS_FetchBiller.pending, (state, action) => {
      state.BBPSAccDashLoading = true;
    });
    builder.addCase(BBPS_FetchBiller.fulfilled, (state, action) => {
      state.BBPSAccDashLoading = false;
      state.BBPSAccDashData = action.payload;
    });
    builder.addCase(BBPS_FetchBiller.rejected, (state, action) => {
      state.BBPSAccDashLoading = false;
      state.BBPSAccDashData = null;
      state.BBPSAccDashError = true;
    });
    builder.addCase(GetAllBillers.pending, (state, action) => {
      state.bppsViewAllAccError = false;
      state.bppsViewAllAccErrorData = null;
    });
    builder.addCase(GetAllBillers.fulfilled, (state, action) => {
      state.bppsViewAllAccLoading = false;
      state.bppsViewAllAccData = action.payload;
      state.bppsViewAllAccError = false;
      state.bppsViewAllAccErrorData = null;
    });
    builder.addCase(GetAllBillers.rejected, (state, action) => {
      state.bppsViewAllAccLoading = false;
      state.bppsViewAllAccError = true;
      state.bppsViewAllAccData = null;
      state.bppsViewAllAccErrorData = action.payload;
    });
    builder.addCase(getBillerInputParams.pending, (state, action) => {
      state.BillerParamsLoading = true;
    });
    builder.addCase(getBillerInputParams.fulfilled, (state, action) => {
      state.BillerParamsLoading = false;
      state.BillerParamsData = action.payload;
    });
    builder.addCase(getBillerInputParams.rejected, (state, action) => {
      state.BillerParamsError = true;
    });

    builder.addCase(createBiller.pending, (state, action) => {
      state.createBillerLoading = true;
    });
    builder.addCase(createBiller.fulfilled, (state, action) => {
      state.createBillerLoading = false;
      state.createBillerOutput = action.payload;
    });
    builder.addCase(createBiller.rejected, (state, action) => {
      state.BillerParamsError = true;
    });
  },
});

export const { resetpage, setStoredPage } = bbpsAccountSlice.actions;
export default bbpsAccountSlice.reducer;
