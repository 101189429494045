import React, { useState, useEffect, useRef } from "react";
import Session from "../../../assets/svg/sessionTimeout.svg";
import Input from "@mui/material/Input";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import {
  loginNewToken,
  resetLoginModalstate,
  setMultipleEntity,
  setShowReloginModalFalse,
} from "../../../redux/features/login/login";
import { logout } from "../../../redux/features/refreshToken/refreshToken";
import Loader from "../../../assets/LoaderIcons/Loader.gif";
import WhiteEyeOff from "../../../assets/PasswordIcons/password_hidden_lightgrey.svg";
import WhiteEyeOn from "../../../assets/PasswordIcons/password_visible_lightgrey.svg";
import { Tooltip, tooltipClasses } from "@mui/material";
import skippedVerification from "../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";

const ReLoginModal = ({ setShowModalPopup, isLoading, isError }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const reLoginInputRef = useRef(null);
  const [previousUserId, setPreviousUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [status, setStatus] = useState("");
  const [cred, setCred] = useState({
    user_name: previousUserId,
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isClicked, setIsclicked] = useState(false);
  const [isReLoggingIn, setIsReLoggingIn] = useState(false);
  const token = window.sessionStorage.getItem("q2p_token");
  const loggedIn = sessionStorage.getItem("loggedIn");
  const loginSlice = useSelector((state) => state.login);
  const multipleEntity = loginSlice.multipleEntity;
  const loginError = loginSlice.isError;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [reLoginError, setReLoginError] = useState(false);

  const { showReloginModal, resetLoginModal } = useSelector(
    (state) => state?.login
  );

  useEffect(() => {
    if (showReloginModal == "Error" && resetLoginModal == "Error") {
      setReLoginError(true);
      dispatch(resetLoginModalstate());
    }
  }, [showReloginModal, resetLoginModal]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if ((token || loggedIn) && isClicked) {
      if (window.location.pathname == "/home") {
        navigate("/login");
      } else {
        navigate("/home");
      }
    }
  }, [token, loggedIn, multipleEntity]);

  const handleChange = (field, value) => {
    const sanitizedValue = value.replace(/\s/g, "");
    setCred((prevCred) => ({ ...prevCred, [field]: sanitizedValue }));
  };

  const isCredentialsFilled = cred.password.trim().length >= 4;

  useEffect(() => {
    const storedUserId = localStorage.getItem("user_id");
    setPreviousUserId(storedUserId);
    setCred((prevCred) => ({ ...prevCred, user_name: storedUserId || "" }));
  }, []);

  useEffect(() => {
    const storedfirstName = localStorage.getItem("first_name");
    setFirstName(storedfirstName);
  }, []);

  useEffect(() => {
    const storedStatus = sessionStorage.getItem("status");
    setStatus(storedStatus);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setShowReloginModalFalse());
    };
  }, []);

  if (isLoading && !isError) {
    return (
      <div className="loader-container w-full h-full bg-custom-backdrop bg-opacity-80 justify-center items-center ">
        <img
          style={{
            height: "100px",
            width: "100px",
          }}
          src={Loader}
        />
      </div>
    );
  }

  const handleReLogin = () => {
    setIsReLoggingIn(true);
    setIsclicked(true);
    dispatch(loginNewToken(cred)).finally(() => {
      setIsReLoggingIn(false);
    });
  };
  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter" && isCredentialsFilled) {
      handleReLogin();
    }
  };

  if (location.pathname === "/login" || location.pathname === "/") {
    return null;
  }

  return (
    <>
      <div className="fixed w-screen top-0 left-0 h-screen bg-custom-backdrop opacity-80 z-[2147483647]"></div>
      <div
        className="flex justify-center items-center m-auto top-1/2 left-1/2 fixed z-[2147483647]"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div
          className={`h-fit flex flex-col gap-2 py-4 px-2 bg-custom-appBackLight rounded-2xl ${
            isMobile ? "w-[320px] ml-[-8px]" : "w-[560px]"
          }`}
        >
          <div className="mx-4">
            <h6 className="pb-1 w-full h-[22px] text-[16px] leading-[21.79px] text-[#F9F9F9] truncate capitalize font-semibold">
              <Tooltip
                sx={{ zIndex: "2147483647 !important" }}
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                      zIndex: "2147483647 !important",
                    },
                  },
                }}
                placement="bottom-start"
                title={firstName}
              >
                Hi {firstName},
              </Tooltip>
            </h6>
            <div className="flex items-center my-[10px]">
              <img src={Session} alt="sessiontimeout" className="w-4 h-4" />
              <span
                className="w-full text-[#FF7F33] leading-[21.79px] h-[22px] pl-[10px] font-semibold"
                style={{
                  fontSize: window.innerWidth <= 600 ? "12px" : "16px",
                }}
              >
                Your session has timed out
              </span>
            </div>
            <div className="mb-[15px]">
              <p className="text-[#DADADA] text-[20px] leading-[27.24px] h-[27px] w-[520px] font-semibold truncate">
                {previousUserId}
              </p>
              <span
                className="text-[#DADADA] text-[14px] leading-[19.07px] text-sm h-[19px]"
                style={{
                  fontFamily: "Open Sans",
                }}
              >
                User ID
              </span>
            </div>
            <TextField
              id="outlined-password-input"
              label="Password"
              type={!showPassword ? "password" : "none"}
              autoComplete="current-password"
              className="w-full"
              variant="outlined"
              name="Password"
              size="small"
              sx={{
                "& label.Mui-focused": {
                  color:
                    reLoginError && cred.password.length !== 0
                      ? "#FF5860"
                      : "#31E5E5",
                },
                "& label": {
                  color:
                    reLoginError && cred.password.length !== 0
                      ? "#FF5860"
                      : cred.password.length !== 0
                      ? "#31E5E5"
                      : "#DADADA",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor:
                      reLoginError && cred.password.length !== 0
                        ? "#FF5860"
                        : cred.password.length !== 0
                        ? "#31E5E5"
                        : "#DADADA",
                    outline: "none !important",
                    borderRadius: "10px",
                  },
                  "&:hover fieldset": {
                    borderColor:
                      reLoginError && cred.password.length !== 0
                        ? "#FF5860"
                        : cred.password.length !== 0
                        ? "#31E5E5"
                        : "#DADADA",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor:
                      reLoginError && cred.password.length !== 0
                        ? "#FF5860"
                        : "#31E5E5",
                  },
                  "& input:-webkit-autofill": {
                    WebkitBoxShadow: "0 0 0 100px #244277 inset !important",
                    WebkitTextFillColor: "#DADADA !important",
                    WebkitBorderRadius: "10px",
                  },
                },
              }}
              inputProps={{
                style: { color: "#DADADA" },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div className="w-[22px] h-[22px]">
                      {reLoginError && cred.password.length !== 0 ? (
                        <>
                          <img
                            src={skippedVerification}
                            alt={"Error Login"}
                            style={{
                              width: "22px",
                              height: "22px",
                              cursor: "default",
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={showPassword ? WhiteEyeOn : WhiteEyeOff}
                            alt={
                              showPassword ? "Hide password" : "Show password"
                            }
                            style={{
                              width: "22px",
                              height: "22px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </InputAdornment>
                ),
              }}
              value={cred.password}
              onChange={(e) => {
                handleChange("password", e.target.value);
                setReLoginError(false);
              }}
              onKeyDown={handleEnterKeyPress}
              inputRef={reLoginInputRef}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "6%",
              paddingRight: "15px",
              marginTop: "10px",
            }}
          >
            {!isReLoggingIn && (
              <Button
                sx={{
                  borderColor: "none",
                  color: "#F9F9F9",
                  textTransform: "capitalize",
                  textDecoration: "underline",
                }}
                style={{
                  fontFamily: "Open Sans",
                  lineHeight: "16.34px",
                  fontWeight: 400,
                }}
                onClick={() => {
                  dispatch(setMultipleEntity());
                  dispatch(logout());
                  dispatch(setShowReloginModalFalse());
                  setShowModalPopup(false);
                  navigate("/login");
                }}
              >
                Logout
              </Button>
            )}

            <Button
              sx={{
                borderRadius: "75px",
                color: "#F9F9F9",
                textTransform: "capitalize",
                border: "1px solid transparent",
                backgroundColor:
                  isCredentialsFilled && !isReLoggingIn && !reLoginError
                    ? "#69B76F"
                    : "#707070",
                pointerEvents:
                  isCredentialsFilled && !isReLoggingIn && !reLoginError
                    ? "auto"
                    : "none",
                cursor:
                  isCredentialsFilled && !isReLoggingIn && !reLoginError
                    ? "pointer"
                    : "default",
                "&:hover": {
                  backgroundColor:
                    isCredentialsFilled && !isReLoggingIn && !reLoginError
                      ? "#69B76F"
                      : "#707070",
                },
                width: "88px",
                height: "35px",
              }}
              style={{
                fontFamily: "Open Sans",
                lineHeight: "16.34px",
              }}
              onClick={handleReLogin}
            >
              {isReLoggingIn ? (
                <img
                  src={Loader}
                  alt="Loading"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              ) : (
                "Login"
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReLoginModal;
