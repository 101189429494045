import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { toast_position } from "../Settings/Authorization";
import { env } from "../../../env";

const baseUrl = env.REACT_APP_BASE_URL;

export const ViewAllAccountInternal = createAsyncThunk(
  "ViewAllAccountInternal",
  async (values) => {
    const {
      page_no,
      page_size,
      approval_status,
      categoryName,
      search_string,
      bank_account_no,
      ifsc,
      start_date,
      end_date,
      pan,
      type,
    } = values;
    try {
      const response = await axios.get(`${baseUrl}/accounts/internal`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page_no,
          page_size: page_size,
          approval_status:
            approval_status && approval_status?.length !== 0
              ? [approval_status]
              : "",
          category: categoryName,
          search_string: search_string,
          start_date,
          end_date: start_date,
          end_date,
          bank_account_no: bank_account_no,
          ifsc: ifsc,
          pan: pan,
          internal_account: type === "internalAccount" ? "Y" : "N",
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error, toast_position);
      throw error;
    }
  }
);

const ViewAllAccountInternalDataSlice = createSlice({
  name: "ViewAllAccountInternal",
  initialState: {
    storedPage: 1,
    toggle: false,

    internalAccViewAllData: null,
    internalAccViewAllLoading: false,
    internalAccViewAllError: null,
  },
  reducers: {
    setToggle: (state, action) => {
      state.toggle = action.payload;
    },
    resetToggle: (state) => {
      state.toggle = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ViewAllAccountInternal.fulfilled, (state, action) => {
      state.internalAccViewAllLoading = false;
      state.internalAccViewAllData = action.payload;
    });
    builder.addCase(ViewAllAccountInternal.rejected, (state, action) => {
      state.internalAccViewAllError = true;
      state.internalAccViewAllData = null;
      state.internalAccViewAllLoading = false;
    });
    builder.addCase(ViewAllAccountInternal.pending, (state, action) => {
      state.internalAccViewAllLoading = true;
    });
  },
});

export const { setToggle, resetpage, setStoredPage, resetToggle } =
  ViewAllAccountInternalDataSlice.actions;

export default ViewAllAccountInternalDataSlice.reducer;
