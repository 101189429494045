import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import { debounce } from "lodash";
import cancel from "../../../assets/CloseIcons/close_with_white_circle.svg";
import search from "../../../assets/FilterSearchIcons/search_white.svg";

const SearchBar = (props) => {
  const {
    showSearchBar,
    handlePaste,
    searchUserText,
    setSearchUserText,
    searchBarRefAcc,
    setShowSearchBar,
    setSearchTerm,
    searchTerm,
    type,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const debouncedSetFilterPayload = useRef(
    debounce((value) => {
      setSearchUserText(value);
    }, 1000)
  ).current;

  const handleChange = (e) => {
    if (e.key !== "Enter") {
      let value = e.target.value;
      setSearchTerm(value);
      debouncedSetFilterPayload(value);
    }
  };

  const inputRef = useRef("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setSearchUserText(searchUserText);
    }
  };

  const handleClear = (e) => {
    e?.stopPropagation();
    setSearchUserText("");
    setSearchTerm("");
  };

  useEffect(() => {
    if (searchUserText === "") {
      handleClear();
    }
  }, [searchUserText]);

  const handleButtonClick = () => {
    setSearchUserText(searchUserText);
  };

  useEffect(() => {
    setSearchUserText(searchUserText);
  }, [searchUserText]);

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  const handleOpenSearchBar = () => {
    setIsOpen(true);
    setShowSearchBar(true);
  };

  return (
    <div
      ref={searchBarRefAcc}
      className={`bene-txn-searchbar_acc ${isOpen ? "open" : "closed"}`}
      onClick={handleOpenSearchBar}
      style={{
        height: props?.typepop === "popup" ? "100px" : "92px",
        position: props?.typepop === "popup" ? "absolute" : "fixed",
        top: props?.typepop === "popup" ? "170px" : "170px",
        // backgroundColor: props?.typepop === "popup" ? "#3A5686" : "",
      }}
    >
      {showSearchBar ? (
        <div
          className={`bene-txn-viewall-search-bar_acc ${
            showSearchBar ? "expand" : "collapse"
          }`}
          style={{
            borderTop: type == "txnSearchBar" ? "none" : null,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <label htmlFor="search" className="sr-only">
            Search
          </label>

          <div className="search_bar_acc">
            <img
              className="bene_txn_viewall_search_inner_image_acc"
              src={search}
              alt="search-inner-image"
              onClick={(e) => {
                e.stopPropagation();
                inputRef.current.focus();
              }}
            />
            <input
              autoFocus
              ref={inputRef}
              onClick={handleInputClick}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              autoComplete="off"
              id="txn_search_acc"
              name="search"
              className={`block h-8 rounded-[10px] bg-[#244277] text-[#DADADA] placeholder:text-[#DADADA] placeholder:text-[12px] placeholder:ml-[30px]`}
              placeholder={
                type == "txnSearchBar"
                  ? "Search amount"
                  : "Search by account ID/ name/ contact number/ email ID"
              }
              type="text"
              value={searchTerm}
            />
            {searchUserText && (
              <img
                onClick={handleClear}
                className="bene_txn_search_close_image_acc cursor-pointer"
                src={cancel}
                alt="search-close"
              />
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SearchBar;
