import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import loadingLogo from "../../../assets/Beneficiary Icons/lodemore.gif";
import {
  beneVerifyThisBank,
  beneBankLookUp,
} from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { checkUsertoken } from "../../../redux/features/login/login";
import verifyLogo from "../../../assets/CreateTransactionIcons/Verified.png";
import skippedVerification from "../../../assets/CreateTransactionIcons/Skipping verivifaction.png";
import Validation from "../../../utils/validation";
import { toast } from "react-toastify";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import "./index.css";
import { useNavigate } from "react-router-dom";

const BeneEditBankDetail = (props) => {
  const isBankError = useSelector(
    (state) => state.beneAllVerification.isBankError
  );
  const bankStatus = useSelector(
    (state) => state.beneAllVerification.verifyBank
  );
  const bankLookData = useSelector(
    (state) => state.beneAllVerification.beneLookData
  );
  const bankStatusLoading = useSelector(
    (state) => state.beneAllVerification.verifyBankisLoading
  );
  const isBankMessage = useSelector(
    (state) => state.beneAllVerification.bankMessage
  );

  const [bankVerificationStatus, setBankVerificationStatus] = useState(false);
  const [customErrors, setCustomErrors] = useState({});
  const [isblur, setisblur] = useState(false);

  const services = useSelector((state) => state.refreshToken.services);

  const dispatch = useDispatch();
  const disabledToggle = Boolean(props.disabledToggle);

  const ifscFieldRef = useRef(null);
  const accountFieldRef = useRef(null);
  const navigate = useNavigate();

  const modifyValue = (type, value) => {
    if (
      type === "pan_no" ||
      type === "bank_account" ||
      type === "ifsc_code" ||
      type === "gstin"
    ) {
      return value?.toUpperCase();
    }
    if (type === "email_id") {
      return value?.toLowerCase();
    }
    return value;
  };

  const allowedValue = (name, value) => {
    if (name === "bank_account") return Validation.allowedBankAccNum(value);
    if (name === "ifsc_code") return Validation.allowedBankIfsc(value);
    return true;
  };

  const validateAndRecordError = (name, value) => {
    let errors = { ...customErrors };
    if (name === "ifsc_code")
      errors = Validation.validateIfsc(name, value, errors);
    if (name === "bank_account")
      errors = Validation.validateBankAcc(name, value, errors);
    setCustomErrors(errors);
  };

  const specialRegex = /^[a-zA-Z0-9]*$/;

  useEffect(() => {
    if (isBankError === true) {
      props.BenEnableNextButton(true);
      props?.setVirtualAccount(false);
    }
  }, [isBankError]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    value = modifyValue(name, value);

    if (allowedValue(name, value)) {
      validateAndRecordError(name, value);
      let newObj;
      if (name === "bank_account") {
        newObj = { ...props.payload, [name]: value, ["ifsc_code"]: "" };
        setBankVerificationStatus(false);
      } else {
        newObj = { ...props.payload, [name]: value };
      }
      props.setPayload(newObj);
    }
    if (!specialRegex.test(value)) {
      return;
    }
    if (props.payload.bank_account?.length === 0) {
      setBankVerificationStatus(false);
      props?.setVirtualAccount(false);
    }

    if (
      props.payload.ifsc_code?.length === 0 ||
      props.payload.ifsc_code?.length <= 11
    ) {
      setBankVerificationStatus(false);
      props?.setVirtualAccount(false);
      props.BenEnableNextButton(true);
    }
  };

  const showErrors = (name) => {
    let errorMessage = customErrors[name];
    if (!errorMessage && name === "ifsc_code" && bankValuesFilled())
      errorMessage = customErrors["bank_account"];
    if (typeof errorMessage === "string") {
      errorMessage = { "Wrong input": [errorMessage] };
    }

    Object.entries(errorMessage).forEach(([errorType, errorMsg]) => {
      toast.error(errorMsg[0], {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  useEffect(() => {
    if (isBankError === true) {
      props.setPayload({ ...props.payload, skip_account_verification: "Y" });
      setBankVerificationStatus(false);
      props?.BenEnableNextButton(true);
    }

    if (bankStatus?.status === true) {
      let errors = { ...customErrors };
      delete errors["bank_account"];
      setCustomErrors(errors);
      props.setPayload({
        ...props.payload,
        acc_holder_name: bankStatus?.verification_details[0].full_name,
        bank_name: bankStatus?.verification_details[0].bank_name,
        skip_account_verification: "N",
      });
      setBankVerificationStatus(true);
      props?.BenEnableNextButton(false);
      props?.setShowAccInfo(false);
    }
    if (
      bankStatus?.status === true &&
      bankStatus?.verification_details[0].is_virtual == "Y"
    ) {
      props?.BenEnableNextButton(false);
      props?.setVirtualAccount(true);
      props.setPayload({
        ...props.payload,
        acc_holder_name: bankStatus?.verification_details[0].legal_name,
      });
    }
  }, [bankStatus]);

  useEffect(() => {
    if (customErrors["bank_account"]) setBankVerificationStatus(false);
    if (customErrors["ifsc_code"]) setBankVerificationStatus(false);
  }, [customErrors]);

  useEffect(() => {
    if (props.activeStep == 0) {
      if (
        props.payload.bank_account !== props.payload.old_bank_account &&
        bankStatus?.status === true &&
        bankValuesFilled()
      ) {
        props?.BenEnableNextButton(false);
      }
      if (isBankError == true) {
        props.BenEnableNextButton(true);
        props.setCreateNewEdit(true);
      }
      if (
        bankStatus?.verification_details[0].account_exists === "N" &&
        !bankLookData &&
        bankValuesFilled()
      ) {
        props.BenEnableNextButton(false);
        props.setCreateNewEdit(true);
      }
      if (props.payload.bank_account == props.payload.old_bank_account) {
        props.setCreateNewEdit(false);
        props.setBankNewMessage(true);
      }
      if (
        props.payload.bank_account !== props.payload.old_bank_account &&
        bankLookData
      ) {
        props.setCreateNewEdit(true);
      }
      if (!props.payload.bank_account || !props.payload.ifsc_code) {
        props.BenEnableNextButton(true);
      }
    }
  }, [bankLookData, isBankError, bankStatus, props.payload]);
  const bankValuesFilled = () => {
    return (
      props.payload.bank_account?.length >= 9 &&
      props.payload.ifsc_code?.length === 11 &&
      /^[A-Z]{4}0[A-Z0-9]{6}$/.test(props.payload.ifsc_code)
    );
  };
  useEffect(() => {
    if (
      props.payload.bank_account?.length === 0 &&
      props.payload.ifsc_code?.length > 0
    ) {
      const newObj = { ...props.payload, ["ifsc_code"]: "" };
      props.setPayload(newObj);
      setBankVerificationStatus(false);
      props.BenEnableNextButton(true);
      props.setIsBankLinkedAccount(false);
    }
    if (props.payload.bank_account?.length < 9) {
      const obj = { ...props.payload, ["ifsc_code"]: "" };
      props.setPayload(obj);
    }
    if (
      props.payload.bank_account?.length >= 9 &&
      props.payload.ifsc_code?.length === 11 &&
      /^[A-Z]{4}0[A-Z0-9]{6}$/.test(props.payload.ifsc_code)
    ) {
      dispatch(checkUsertoken());
      if (services?.includes("BUAccount") || services?.includes("Admin")) {
        dispatch(
          beneVerifyThisBank({
            ifsc_code: props.payload.ifsc_code,
            bank_account: props.payload.bank_account,
            legal_name: props.payload.legal_name,
            navigate: navigate,
          })
        );
        dispatch(
          beneBankLookUp({
            ifsc: props.payload.ifsc_code,
            bank_account_no: props.payload.bank_account,
          })
        );
      } else {
        navigate("/feature_not_assigned", {
          state: { unavailableService: "BUAccount" },
        });
      }
    }
  }, [props.payload.ifsc_code, props.payload.bank_account]);

  const handleKeyDown = (e, nextRef) => {
    if (e.key === "Tab") {
      e.preventDefault();
      nextRef.current.focus();
    }
  };

  const getBankVerifiedElementDiv = (
    isLoading,
    bankDetails,
    ifscDetails,
    name,
    value,
    bankStatus,
    isError
  ) => {
    const textFieldStyle = {
      "&.Mui-focused": {
        color:
          bankDetails.name === "bank_account" &&
          bankDetails.value?.length > 0 &&
          bankVerificationStatus &&
          !isblur &&
          !isBankError // Use bankStatus instead of status
            ? "#00FF14 !important"
            : (bankDetails.name === "bank_account" ||
                bankDetails.name === "ifsc_code") &&
              bankDetails.value?.length > 0 &&
              !bankVerificationStatus &&
              isblur &&
              isBankError // Use bankStatus instead of status
            ? "#FF5860"
            : "#DADADA",
        borderColor:
          (bankDetails.name === "bank_account" ||
            bankDetails.name === "ifsc_code") &&
          bankDetails.value?.length > 0 &&
          bankVerificationStatus &&
          !isblur &&
          !isBankError // Use bankStatus instead of status
            ? "#00FF14 !important"
            : (bankDetails.name === "bank_account" ||
                bankDetails.name === "ifsc_code") &&
              bankDetails.value?.length > 0 &&
              !bankVerificationStatus &&
              isblur &&
              isBankError // Use bankStatus instead of status
            ? "#FF5860"
            : "#DADADA",
      },
      borderColor:
        (bankDetails.name === "bank_account" ||
          bankDetails.name === "ifsc_code") &&
        bankDetails.value?.length > 0 &&
        bankVerificationStatus &&
        isblur &&
        !isBankError
          ? "#00FF14 !important" // Green when verified
          : (bankDetails.name === "bank_account" ||
              bankDetails.name === "ifsc_code") &&
            bankDetails.value?.length > 0 &&
            !bankVerificationStatus &&
            isblur &&
            isBankError // Check if there is an error
          ? "#FF5860" // Red when there's an error
          : // : "yellow !important" // Default border color
            "#DADADA !important", // Default border color
    };
    const ifsctextFieldStyle = {
      "&.Mui-focused": {
        color:
          ifscDetails.value?.length === 11 && bankVerificationStatus // Use bankStatus instead of status
            ? "#00FF14"
            : ifscDetails.value?.length === 11 && !bankVerificationStatus // Use bankStatus instead of status
            ? "#FF5860"
            : "#DADADA",
        borderColor:
          ifscDetails.value?.length === 11 && bankVerificationStatus // Use bankStatus instead of status
            ? "#00FF14"
            : ifscDetails.value?.length === 11 && !bankVerificationStatus // Use bankStatus instead of status
            ? "#FF5860"
            : "#DADADA",
      },
      borderColor:
        ifscDetails.value?.length === 11 && bankVerificationStatus
          ? "#00FF14" // Green when verified
          : ifscDetails.value?.length === 11 && !bankVerificationStatus // Check if there is an error
          ? "#FF5860 !important" // Red when there's an error
          : // : "yellow !important" // Default border color
            "#DADADA !important", // Default border color
    };
    const labelStyle = {
      color:
        bankDetails.name === "bank_account" &&
        bankDetails.value?.length > 0 &&
        bankVerificationStatus
          ? "#00FF14 "
          : bankDetails.name === "bank_account" &&
            bankDetails.value?.length > 0 &&
            !bankVerificationStatus &&
            isblur &&
            isBankError // Use bankStatus instead of status
          ? "#FF5860"
          : "#DADADA",
      "&.Mui-focused": {
        color:
          bankDetails.name === "bank_account" &&
          bankDetails.value?.length > 0 &&
          bankVerificationStatus &&
          !isblur &&
          !isBankError // Use bankStatus instead of status
            ? "#00FF14 !important"
            : bankDetails.name === "bank_account" &&
              bankDetails.value?.length > 0 &&
              !bankVerificationStatus &&
              !isblur &&
              isBankError // Use bankStatus instead of status
            ? "#FF5860"
            : "#DADADA",
      },
      "&.Mui-error": {
        color: "#FF5860 !important",
        borderColor: "#FF5860 !important",
      },
    };

    const ifsclabelStyle = {
      color:
        ifscDetails.value?.length === 11 && bankVerificationStatus // Use bankStatus instead of status
          ? "#00FF14"
          : ifscDetails.value?.length === 11 && !bankVerificationStatus // Use bankStatus instead of status
          ? "#FF5860"
          : "#DADADA",
      "&.Mui-focused": {
        color:
          ifscDetails.value?.length === 11 && bankVerificationStatus // Use bankStatus instead of status
            ? "#00FF14 !important"
            : ifscDetails.value?.length === 11 && !bankVerificationStatus // Use bankStatus instead of status
            ? "#FF5860"
            : "#DADADA",
      },
      "&.Mui-error": {
        color: "#FF5860",
        borderColor: "#FF5860 !important",
      },
    };

    return (
      <div
        className={`min-w-full flex flex-col self-center gap-6
        ${bankValuesFilled() && "-mx-1"}`}
      >
        <TextField
          style={textFieldStyle}
          inputRef={accountFieldRef}
          onKeyDown={(e) => handleKeyDown(e, ifscFieldRef)}
          className="w-full antialiased text-xs"
          required
          inputProps={{
            style: {
              color: "white",
            },
            maxLength: bankDetails.maxLength,
            readOnly: props.activeStep === 2 || props.readOnly,
          }}
          id="outlined-size-small"
          size="small"
          variant="outlined"
          sx={{
            "& label.Mui-focused": {
              color:
                (bankDetails.name === "bank_account" ||
                  bankDetails.name === "ifsc_code") &&
                bankDetails.value?.length > 0 &&
                bankVerificationStatus &&
                !isblur &&
                !isBankError
                  ? "#00FF14 !important"
                  : (bankDetails.name === "bank_account" ||
                      bankDetails.name === "ifsc_code") &&
                    bankDetails.value?.length > 0 &&
                    !bankVerificationStatus &&
                    isblur &&
                    isBankError
                  ? "#FF5860 !important"
                  : "#00FFF5 !important",
            },
            "& .Mui-focused": {
              "& fieldset": {
                borderColor:
                  (bankDetails.name === "bank_account" ||
                    bankDetails.name === "ifsc_code") &&
                  bankDetails.value?.length > 0 &&
                  bankVerificationStatus &&
                  !isblur &&
                  !isBankError
                    ? "#00FF14 !important"
                    : (bankDetails.name === "bank_account" ||
                        bankDetails.name === "ifsc_code") &&
                      bankDetails.value?.length > 0 &&
                      !bankVerificationStatus &&
                      isblur &&
                      isBankError
                    ? "#FF5860 !important"
                    : "#00FFF5 !important",
              },
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor:
                  (bankDetails.name === "bank_account" ||
                    bankDetails.name === "ifsc_code") &&
                  bankDetails.value?.length > 0 &&
                  bankVerificationStatus
                    ? "#00FF14"
                    : (bankDetails.name === "bank_account" ||
                        bankDetails.name === "ifsc_code") &&
                      bankDetails.value?.length > 0 &&
                      !bankVerificationStatus &&
                      isblur &&
                      isBankError
                    ? "#FF5860"
                    : "#DADADA",
                color:
                  (bankDetails.name === "bank_account" ||
                    bankDetails.name === "ifsc_code") &&
                  bankDetails.value?.length > 0 &&
                  bankVerificationStatus &&
                  !isblur &&
                  !isBankError
                    ? "#00FF14"
                    : (bankDetails.name === "bank_account" ||
                        bankDetails.name === "ifsc_code") &&
                      bankDetails.value?.length > 0 &&
                      !bankVerificationStatus &&
                      isblur &&
                      isBankError
                    ? "#FF5860 !important"
                    : "#DADADA",
                outline: "none !important",
              },
            },
            "&:hover": {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor:
                    (bankDetails.name === "bank_account" ||
                      bankDetails.name === "ifsc_code") &&
                    bankDetails.value?.length > 0 &&
                    bankVerificationStatus &&
                    isblur &&
                    !isBankError
                      ? "#00FF14"
                      : (bankDetails.name === "bank_account" ||
                          bankDetails.name === "ifsc_code") &&
                        bankDetails.value?.length > 0 &&
                        !bankVerificationStatus &&
                        isblur &&
                        isBankError
                      ? "#FF5860"
                      : "#DADADA",
                  outline: "none !important",
                },
              },
            },
          }}
          InputLabelProps={{ style: labelStyle }}
          InputProps={{
            sx: {
              borderRadius: "10px",
            },
            disableUnderline: true,
            readOnly: props.activeStep === 2,
            endAdornment: (
              <InputAdornment position="end">
                {isLoading && (
                  <img
                    src={loadingLogo}
                    alt="verified logo"
                    className="w-6 h-5 "
                  />
                )}
                {!isLoading &&
                bankVerificationStatus &&
                bankDetails.value?.length >= 9 ? (
                  <img
                    src={verifyLogo}
                    alt="verified logo"
                    className="w-6 h-5 "
                  />
                ) : null}
                {!isLoading &&
                bankDetails.value?.length > 9 &&
                ifscDetails.value?.length !== 0 &&
                isBankError ? (
                  <img
                    src={skippedVerification}
                    alt="verified logo"
                    className=" w-6 h-5 "
                  />
                ) : null}
                {props.readOnly && (
                  <img
                    src="/assets/CreateTransactionicons/Lock.png"
                    alt="verified logo"
                    className="cursor-pointer mx-4 w-6 h-5 "
                  />
                )}
              </InputAdornment>
            ),
          }}
          label={bankDetails.label}
          name={bankDetails.name}
          onChange={handleChange}
          value={bankDetails.value}
          onBlurCapture={() => setisblur(true)}
          onFocus={() => setisblur(false)}
          readOnly={disabledToggle}
          autoComplete="off"
        />
        <TextField
          inputRef={ifscFieldRef}
          onKeyDown={(e) => handleKeyDown(e, accountFieldRef)}
          disabled={props.payload.bank_account?.length < 9 ? true : false}
          style={ifsctextFieldStyle}
          className="w-full antialiased text-xs"
          required
          id="outlined-size-small"
          size="small"
          variant="outlined"
          sx={{
            "& label.Mui-focused": {
              color:
                ifscDetails.value?.length === 11 && bankVerificationStatus
                  ? "#00FF14 !important"
                  : ifscDetails.value?.length === 11 && !bankVerificationStatus
                  ? "#FF5860"
                  : "#00FFF5 !important",
            },
            "& .Mui-disabled": {
              "& fieldset": {
                color: "#71797E  !important",
                borderColor: "#71797E  !important",
              },
              color: "#71797E  !important",
              borderColor: "#71797E  !important",
            },
            "& .Mui-focused": {
              "& fieldset": {
                borderColor:
                  ifscDetails.value?.length === 11 && bankVerificationStatus
                    ? "#00FF14 !important"
                    : ifscDetails.value?.length === 11 &&
                      !bankVerificationStatus
                    ? "#FF5860 !important"
                    : "#00FFF5 !important",
              },
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor:
                  ifscDetails.value?.length === 11 && bankVerificationStatus
                    ? "#00FF14"
                    : ifscDetails.value?.length === 11 &&
                      !bankVerificationStatus
                    ? "#FF5860"
                    : "#DADADA",
                outline: "none !important",
              },
            },
            "&:hover": {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor:
                    ifscDetails.value?.length === 11 && bankVerificationStatus
                      ? "#00FF14"
                      : ifscDetails.value?.length === 11 &&
                        !bankVerificationStatus
                      ? "#FF5860"
                      : "#DADADA",
                  outline: "none !important",
                },
              },
            },
          }}
          inputProps={{
            style: {
              color: "white",
            },
            maxLength: ifscDetails.maxLength,
            readOnly: props.activeStep === 2 || props.readOnly,
          }}
          InputLabelProps={{ style: ifsclabelStyle }}
          InputProps={{
            sx: {
              borderRadius: "10px",
            },
            disableUnderline: true,
            readOnly: props.activeStep === 2,
            endAdornment: (
              <InputAdornment position="end">
                {isLoading && (
                  <img
                    src={loadingLogo}
                    alt="verified logo"
                    className="w-6 h-5"
                  />
                )}

                {!isLoading &&
                bankVerificationStatus &&
                ifscDetails.value?.length === 11 ? (
                  <img
                    src={verifyLogo}
                    alt="verified logo"
                    className="w-6 h-5 "
                  />
                ) : null}
                {!isLoading &&
                ((ifscDetails.value?.length <= 11 &&
                  customErrors["ifsc_code"] &&
                  ifscDetails.value?.length > 0) ||
                  (bankValuesFilled() && customErrors["bank_account"]) ||
                  (isBankError && ifscDetails.value?.length > 0)) ? (
                  <img
                    src={skippedVerification}
                    onClick={() => {
                      showErrors(ifscDetails.name);
                    }}
                    alt="verified logo"
                    className="cursor-pointer w-6 h-5"
                  />
                ) : null}
                {props.readOnly && (
                  <img
                    src="/assets/CreateTransactionicons/Lock.png"
                    alt="verified logo"
                    className="cursor-pointer mx-4 w-6 h-5 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                )}
              </InputAdornment>
            ),
          }}
          label={ifscDetails.label}
          name={ifscDetails.name}
          onChange={handleChange}
          value={ifscDetails.value}
          readOnly={disabledToggle}
          autoComplete="off"
        />
      </div>
    );
  };

  const bankVerificationDiv = () => {
    return (
      <div className="min-w-full flex flex-col -ml-4 md:-ml-6 lg:-ml-8 xl:-ml-10 4xl:-ml-24 -mr-4 md:-mr-6 lg:-mr-8 xl:-mr-10 4xl:-mr-24">
        <div
          className={`min-w-full flex justify-center py-1 px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24`}
        >
          <div className="min-w-full">
            {getBankVerifiedElementDiv(
              bankStatusLoading,
              {
                name: "bank_account",
                status: bankVerificationStatus,
                label: "Bank account number",
                value: props.payload.bank_account,
                maxLength: 18,
              },
              {
                name: "ifsc_code",
                status: bankVerificationStatus,
                label: " IFSC code",
                value: props.payload.ifsc_code,
                maxLength: 11,
              }
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${
        bankValuesFilled() && !bankVerificationStatus && isBankError
          ? "bg-custom-red"
          : "bg-[#1D3A6D]"
      } flex flex-col relative rounded-large min-w-[480px] `}
    >
      <div className="bank-details-crt">
        <div className=" bg-[#3A5686] rounded-t-[30px] flex flex-row justify-between py-5 px-7">
          <div className="text-white self-center font-semibold text-base">
            {props.title}
          </div>
        </div>
        <div className="pb-5 bg-[#3A5686] flex flex-col rounded-b-[30px] gap-4 h-[140px]">
          <div className="w-full flex flex-col px-6">
            {props.activeStep !== 3 ? (
              <div className="w-full">{bankVerificationDiv()}</div>
            ) : (
              <div className="flex flex-col gap-8 pb-4">
                <div>
                  <div className="w-full antaliased font-[100] text-white capitalize self-center text-xl truncate ...">
                    {props?.payload?.bank_account}
                  </div>
                  <div className="antaliased text-white text-xs opacity-70">
                    Bank account number
                  </div>
                </div>
                <div>
                  <div className="w-full antaliased font-[100] text-white capitalize self-center text-xl 3xl:text-2xl 5xl:text-3xl truncate ...">
                    {props?.payload?.ifsc_code}
                  </div>
                  <div className="antaliased text-white text-xs opacity-70">
                    IFSC code
                  </div>
                </div>
                {props.payload.skip_account_verification === "N" ? (
                  <>
                    <div className="h-[1px] bg-white px-8 opacity-70"></div>
                    <div className="flex justify-between">
                      <div className="antaliased font-[100] text-white capitalize self-center text-xl 3xl:text-2xl 5xl:text-3xl truncate ...">
                        {bankStatus?.verification_details[0]?.full_name.toLowerCase()}
                      </div>
                      <div className="antaliased text-white text-xs opacity-70">
                        Account holders name
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="h-[1px] bg-white px-8 opacity-70"></div>
                    <div className="flex flex-row justify-between items-center gap-2">
                      <div className="h-full flex flex-col justify-between p-1 text-white text-base">
                        <div className="text-md font-extrabold">
                          SKIPPING VERIFICATION
                        </div>
                      </div>
                      <div className="flex flex-col w-8 h-8 2xl:w-9 2xl:h-9 3xl:w-10 3xl:h-10 5xl:w-11 5xl:h-11">
                        <img
                          className="p-1"
                          src={skippedVerification}
                          alt="verified logo"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {bankValuesFilled() &&
      props.activeStep !== 3 &&
      bankVerificationStatus &&
      !customErrors["bank_account"] &&
      bankStatus?.verification_details?.length > 0 ? (
        <>
          <div className="edit-box-rounded flex flex-row edit-bank-name">
            <div className="mt-3 pl-0">
              <div className="beneEditAcc_holder_name_div">
                <div
                  className="merge_name"
                  style={{ alignItems: "flex-start" }}
                >
                  <img
                    className="w-5 h-5 mt-1"
                    style={{ marginTop: "2px" }}
                    src={verifyLogo}
                    alt="verified logo"
                  />
                  <span className="beneEditAcc_holder_name">
                    {bankStatus?.verification_details[0].full_name.toLowerCase()}
                  </span>
                </div>
              </div>

              <div className="beneEditAcc_holder_span_div">
                <span className="beneEditAcc_holder_span">
                  {props.virtualAccount &&
                  props.isIFSCValid &&
                  props.createNewedit
                    ? "Account holder's name - Using legal name"
                    : "Account holder's name"}
                </span>
                <div
                  className="rounded-br-[31px] rounded-tl-[32px] bg-[#F9F9F9] flex items-center justify-around mt-[-20px] mr-[-32px] h-12"
                  style={{
                    position: "relative",
                    left: 277,
                    bottom: -8,
                    width: "132px",
                    height: "47px",
                    marginLeft: "8px",
                  }}
                >
                  <div className="antialiased">
                    <img
                      className=""
                      style={{ height: "18px", width: "72px" }}
                      src={require("../../../assets/IfscLogo/" +
                        IfscCodeMapper(props?.payload?.ifsc_code))}
                      alt="bank"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="bg-[#3A5686] h-25 rounded-b-4xl 4xl:rounded-b-large 5xl:rounded-b-customXl
            flex w-full flex-col justify-between overflow-hidden user-crt-bank-address py-5 pl-5"
          >
            <span className="bank-crt-main-header">Address Details</span>
            <div className="h-50 overflow-y-scroll overflow-x-hidden flex flex-col gap-4">
              <span className="bank-crt-main-header">
                {bankStatus?.verification_details[0].branch
                  ? bankStatus?.verification_details[0].branch.toLowerCase()
                  : "NA"}
                <p className="bank-crt-sub-header">Branch</p>
              </span>
              <span className="bank-crt-main-header">
                {bankStatus?.verification_details[0].city
                  ? bankStatus?.verification_details[0].city.toLowerCase()
                  : "NA"}
                <p className="bank-crt-sub-header">City</p>
              </span>
              <span className="bank-crt-main-header">
                {bankStatus?.verification_details[0].district
                  ? bankStatus?.verification_details[0].district.toLowerCase()
                  : "NA"}
                <p className="bank-crt-sub-header">District</p>
              </span>
              <span
                className="bank-crt-main-header"
                style={{
                  paddingBottom: "50px",
                  textWrap: "wrap",
                }}
              >
                <span
                  className="bank-crt-main-header"
                  style={{
                    wordWrap: "break-word",
                  }}
                >
                  {bankStatus?.verification_details[0].address
                    ? bankStatus?.verification_details[0].address.toLowerCase()
                    : "NA"}{" "}
                </span>
                <p className="bank-crt-sub-header">Address</p>
              </span>
            </div>
          </div>
        </>
      ) : null}
      {bankValuesFilled() && props.activeStep !== 3 && isBankError ? (
        <div className="h-30 relative">
          <div className=" bg-custom-dark-orange flex flex-col h-full">
            <div className="bg-custom-red pt-2 px-2 flex rounded-b-4xl 4xl:rounded-b-large 5xl:rounded-b-customXl">
              <div className="p-2 flex flex-row items-start gap-2">
                <div className="flex flex-col w-8 h-8 2xl:w-9 2xl:h-9 3xl:w-10 3xl:h-10 5xl:w-11 5xl:h-11">
                  <img
                    className="p-1"
                    src={skippedVerification}
                    alt="verified logo"
                  />
                </div>

                <div className="h-full flex flex-col justify-between p-1 text-white text-base">
                  <div className="text-md font-extrabold">
                    VERIFICATION FAILED
                  </div>
                  <ul className="list-disc px-2 py-2">
                    <li>Account could not be verified</li>
                    <li>Crosscheck the information given above</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-custom-dark-orange w-[100%] mx-auto rounded-b-large flex absolute left-0 right-0 -bottom-26 px-7 text-white">
            <div className="flex items-start flex-col pb-4 px-4 pt-2 ">
              <div className="capitalize px-2 text-base italic font-semibold">
                BANK REMARK:
              </div>
              <div className="text-xs px-2 3xl:text-sm">{isBankMessage}</div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default BeneEditBankDetail;
