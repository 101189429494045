import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import dataLoading from "../../../assets/Beneficiary Icons/lodemore.gif";
import Loader from "../../../components/Loader/Index";

const FeatureNotAssign = (props) => {
  const navigate = useNavigate();
  const { services, isLoading } = useSelector((state) => state.refreshToken);

  useMemo(() => {
    if (!isLoading && services != null) {
      if (
        !services?.includes(props?.userServices) &&
        !services?.includes("Admin")
      ) {
        navigate("/feature_not_assigned", {
          state: { unavailableService: `${props?.userServices}` },
        });
      }
    }
  }, [services]);

  return services?.includes(props?.userServices) ||
    services?.includes("Admin") ? (
    <Outlet />
  ) : (
    <div
      className="loader-container grid place-items-center absolute w-full h-full"
      style={{ top: "56px" }}
    >
      <Loader />
    </div>
  );
};

export default FeatureNotAssign;
