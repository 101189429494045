import React, { useRef, useEffect } from "react";
import "./index.css";
import { Button } from "@mui/material";
import ArrowDown from "../../../assets/ArrowsIcons/arrow_down_white_filled.svg";
import CheckIcon from "../../../assets/CheckmarksIcons/check_mark_white.svg";
import clear_filter from "../../../assets/ViewAllTransactions/Clear filter.png";

const TypeOfAccountDropdown = ({
  filterPayload,
  setFilterPayload,
  selectTypeOfAccount,
  setSelectTypeofAccount,
}) => {
  const accountBalanceRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountBalanceRef.current &&
        !accountBalanceRef.current.contains(event.target)
      ) {
        setSelectTypeofAccount(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="owner_type_of_acc_balance ">
      <p
        style={{
          color: "#F05A6A",
          width: "100px",
          height: "19px",
          font: "normal normal 600 14px/19px Open Sans",
          marginBottom: "20px",
          paddingTop: "10px",
        }}
      >
        Filter
      </p>
      <p style={{ height: "22px" }}>Type of account</p>
      <Button
        ref={accountBalanceRef}
        className="owner_type_of_acc_select"
        onClick={() => {
          setSelectTypeofAccount(!selectTypeOfAccount);
        }}
        sx={{
          zIndex: "214783547 !important",
          height: selectTypeOfAccount ? "140px" : "37px",
          outline: selectTypeOfAccount ? "1px solid #E25869" : "",
        }}
      >
        <div className="type_of_acc_select_select">
          <div
            className="type_of_acc_select_scroll_line"
            style={{ display: selectTypeOfAccount ? "block" : "none" }}
          ></div>

          <span
            style={{
              fontSize: selectTypeOfAccount ? "12px" : "14px",
              paddingLeft: "14px",
            }}
          >
            {filterPayload.typeofaccount.length > 0
              ? filterPayload.typeofaccount.toLowerCase()
              : "Select type"}
          </span>
          <div className="flex flex-row items-center">
            <img
              src={clear_filter}
              alt="clear"
              className="w-[9px] h-[9px]"
              style={{
                visibility:
                  filterPayload.typeofaccount.length > 0 ? "visible" : "hidden",
              }}
              onClick={(e) => {
                setFilterPayload((prev) => {
                  return { ...prev, typeofaccount: "" };
                });
                e.stopPropagation();
              }}
            />
            <div
              className="w-[1px] h-[16px] mx-[8px]"
              style={{
                backgroundColor: "var(--Blue_3)",
                visibility:
                  filterPayload.typeofaccount.length > 0 ? "visible" : "hidden",
              }}
            ></div>
            <img
              src={ArrowDown}
              alt="down"
              className="w-[11px] h-[11px]"
              style={{
                transform: selectTypeOfAccount
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
              }}
            />
          </div>
        </div>
        <div
          style={{ zIndex: "2147483647 !important" }}
          className="type_of_acc_select_scroll"
        >
          <Button
            className="owner_type_of_acc_select_scroll_com hover:bg-[#506994]"
            onClick={(e) => {
              e.stopPropagation();
              setFilterPayload({
                ...filterPayload,
                typeofaccount: "",
              });
              setSelectTypeofAccount(false);
            }}
          >
            None
          </Button>
          <Button
            className="owner_type_of_acc_select_scroll_com hover:bg-[#506994]"
            style={{
              zIndex: "2147483647 !important",
              backgroundColor:
                filterPayload.typeofaccount === "Current Account"
                  ? "#3A5686"
                  : "",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setFilterPayload({
                ...filterPayload,
                typeofaccount: "Current Account",
              });
              setSelectTypeofAccount(false);
            }}
          >
            Current account
            <div
              style={{
                display:
                  filterPayload.typeofaccount === "Current Account"
                    ? "flex"
                    : "none",
                textAlign: "right",
                justifyContent: "end",
                paddingRight: "1px",
              }}
            >
              <img
                style={{
                  height: "13px",
                  width: "11px",
                }}
                src={CheckIcon}
                alt="check"
              />
            </div>
          </Button>
          <Button
            className="owner_type_of_acc_select_scroll_com hover:bg-[#506994]"
            style={{
              zIndex: "2147483647 !important",
              backgroundColor:
                filterPayload.typeofaccount === "Saving Account"
                  ? "#3A5686"
                  : "",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setFilterPayload({
                ...filterPayload,
                typeofaccount: "Saving Account",
              });
              setSelectTypeofAccount(false);
            }}
          >
            Saving account
            <div
              style={{
                display:
                  filterPayload.typeofaccount === "Saving Account"
                    ? "flex"
                    : "none",
                textAlign: "right",
                justifyContent: "end",
                paddingRight: "1px",
              }}
            >
              <img
                style={{
                  height: "13px",
                  width: "11px",
                }}
                src={CheckIcon}
                alt="check"
              />
            </div>
          </Button>
        </div>
      </Button>
    </div>
  );
};

export default TypeOfAccountDropdown;
