const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
const alphanumericRegex = /^[a-zA-Z0-9]*$/;
const numericRegex = /^[0-9]*$/;
const floatRegexTwoDecimal = /^[1-9][0-9]*\.?[0-9]{0,2}$/;
const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/;
const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
const cvvRegex = /^[0-9]{3}$/;
const cardExpriyRegex = /^[0-9]{4}$/;
const cardNumberRegex =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$/;
const gstRegex =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[A-Z]{1}[A-Z0-9]{1}$/;

const allowedAmount = (value) => {
  let floatAmount = value?.length > 0 ? parseFloat(value).toFixed(2) : 0.0;
  if (
    floatRegexTwoDecimal.test(value) &&
    floatAmount >= 1.0 &&
    floatAmount <= 999999999.99
  )
    return true;
  return false;
};

const allowedPAN = (value) => {
  if (value?.length > 10) return false;
  if (value?.length <= 10 && !alphanumericRegex.test(value)) return false;
  return true;
};

const allowedCIN = (value) => {
  if (value?.length > 21) return false;
  if (value?.length < 21 && !alphanumericRegex.test(value)) return false;
  return true;
};

const allowedBankAccNum = (value) => {
  if (value?.length > 20) return false;
  if (value?.length <= 20 && !alphanumericRegex.test(value)) return false;
  return true;
};

const allowedBankIfsc = (value) => {
  if (value?.length > 11) return false;
  if (value?.length <= 11 && !alphanumericRegex.test(value)) return false;
  return true;
};

const allowedContactNum = (value) => {
  if (value?.length > 10) return false;
  if (value?.length <= 10 && !numericRegex.test(value)) return false;
  return true;
};

const allowedName = (value) => {
  if (value?.length >= 25) return false;
  return true;
};

const allowedEmail = (value) => {
  if (value?.length > 50) return false;
  return true;
};

const validatePan = (name, value, errors) => {
  if (value?.length > 0 && value?.length < 10)
    errors[name] = "PAN number should be 10 digits long";
  if (value?.length === 10 && !panRegex.test(value))
    errors[name] = "PAN format is incorrect !";
  if (value?.length === 10 && panRegex.test(value)) delete errors[name];
  return errors;
};

const validateCIN = (name, value, errors) => {
  if (value?.length > 0 && value?.length < 21)
    errors[name] = "CIN number should be 21 digits long";
  if (value?.length === 21 && !alphanumericRegex.test(value))
    errors[name] = "CIN format is incorrect !";
  if (value?.length === 21 && alphanumericRegex.test(value))
    delete errors[name];
  return errors;
};

const validateBankAcc = (name, value, errors) => {
  if (value?.length > 0 && value?.length < 4)
    errors[name] = "Bank account number should be 4 digits long";
  if (value?.length >= 4) delete errors[name];
  return errors;
};

const validateIfsc = (name, value, errors) => {
  if (value?.length > 0 && value?.length < 11)
    errors[name] = "Bank IFSC code should be 11 digits long";
  if (value?.length === 11 && !ifscRegex.test(value))
    errors[name] = "IFSC code format is incorrect !";
  if (value?.length === 11 && ifscRegex.test(value)) delete errors[name];
  return errors;
};

const validateEmail = (name, value, errors) => {
  if (value?.length >= 0 && !emailRegex.test(value))
    errors[name] = "Email format is incorrect !";
  else delete errors[name];
  return errors;
};

const validateContactNum = (name, value, errors) => {
  if (value?.length >= 0 && value?.length < 10)
    errors[name] = "Contact number should be 10 digits long";
  else delete errors[name];
  return errors;
};

const validateAmountLimit = (name, value, errors) => {
  return errors;
};

const allowedAmountLimit = (value) => {
  if (value?.length > 10) return false;
  if (value?.length < 10 && !numericRegex.test(value)) return false;
  return true;
};

const allowedPincode = (value) => {
  if (value?.length > 6) return false;
  if (value?.length < 6 && !numericRegex.test(value)) return false;
  return true;
};

const allowedCardNumber = (value) => {
  if (value?.length > 16) return false;
  if (!numericRegex.test(value)) return false;
  return true;
};

const allowedCardExpiry = (value) => {
  if (value?.length > 4) return false;
  if (!numericRegex.test(value)) return false;
  return true;
};

const allowedCardCvv = (value) => {
  if (value?.length > 3) return false;
  if (!numericRegex.test(value)) return false;
  return true;
};

const validateCardNumber = (name, value, errors) => {
  if (value?.length >= 0 && value?.length < 16)
    errors[name] = "Card number should be 16 digits long";
  else delete errors[name];
  return errors;
};

const validateCardExpiry = (name, value, errors) => {
  const date = new Date();
  if (value?.length >= 0 && value?.length < 4)
    errors[name] = "Card expiry should be 4 digits long";
  else if (value.substring(0, 2) > 12)
    errors[name] = "Card expiry month should be less than or equal to 12";
  else if (value.substring(0, 2) < 1)
    errors[name] = "Card expiry month should be greater than or equal to 01";
  else if (
    value.substring(2, 4) < date.getFullYear().toString().substring(2, 4)
  )
    errors[name] =
      "Card expiry year should be greater than or equal to " +
      date.getFullYear().toString().substring(2, 4);
  else delete errors[name];
  return errors;
};

const validateCardCvv = (name, value, errors) => {
  if (value?.length >= 0 && value?.length < 3)
    errors[name] = "Card CVV should be 3 digits long";
  else delete errors[name];
  return errors;
};

const validatePincode = (name, value, errors, setPincodeValidation) => {
  if (value?.length >= 1 && value?.length < 6) {
    setPincodeValidation(false);
    errors[name] = "Pincode should be 6 digits long";
  } else {
    delete errors[name];
    setPincodeValidation(true);
  }
  return errors;
};

const validateAddress = (name, value, errors, setAddressValidation) => {
  if (value?.length >= 1 && value?.length < 6) {
    setAddressValidation(false);
    errors[name] = "Address must be atleast 6 characters long";
  } else {
    delete errors[name];
    setAddressValidation(true);
  }
  return errors;
};

const validateName = (name, value, errors) => {
  if (value?.length >= 1 && value?.length < 6)
    errors[name] = "Name must be atleast 6 characters long";
  else delete errors[name];
  return errors;
};

const allowedGstin = (value) => {
  if (value?.length > 15) return false;
  if (value?.length < 15 && !alphanumericRegex.test(value)) return false;
  return true;
};

const validateGstin = (name, value, errors) => {
  if (value?.length > 0 && value?.length < 15)
    errors[name] = "GSTIN number should be 15 digits long";
  if (value?.length === 15 && !alphanumericRegex.test(value))
    errors[name] = "GSTIN format is incorrect !";
  if (value?.length === 15 && alphanumericRegex.test(value))
    delete errors[name];
  return errors;
};

const validateGstWithRegex = (value) => {
  if (value?.length > 15 || !gstRegex.test(value)) {
    return false;
  } else {
    return true;
  }
};

const validateAccountName = (name, value, errors) => {
  if (value?.length < 0)
    errors[name] = "Account Name Cant be Empty!";
  else delete errors[name];
  return errors;
};

const Validation = {
  allowedAmount,
  allowedPAN,
  allowedBankAccNum,
  allowedBankIfsc,
  allowedContactNum,
  allowedEmail,
  allowedGstin,
  allowedName,
  validatePan,
  validateCIN,
  validateIfsc,
  validateBankAcc,
  validateEmail,
  validateContactNum,
  allowedAmountLimit,
  validateAmountLimit,
  allowedPincode,
  validatePincode,
  validateAddress,
  validateName,
  allowedCardNumber,
  allowedCardExpiry,
  allowedCardCvv,
  validateCardNumber,
  validateCardExpiry,
  validateCardCvv,
  allowedCIN,
  validateGstin,
  validateGstWithRegex,
  validateAccountName,
};

export default Validation;
