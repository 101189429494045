import React, { useState, useRef } from "react";
import Apostrophe from "../../../../src/assets/landingPage/Apostrophe.svg";

const ExpandingCards = ({ data }) => {
  const [activeId, setActiveId] = useState(data[0]?.id);
  const hoverTimeout = useRef(null);

  const handleMouseOver = (id) => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }

    hoverTimeout.current = setTimeout(() => {
      setActiveId(id);
    }, 500);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
  };

  return (
    <div className="flex w-[765px] mx-auto overflow-hidden">
      {data.map((card, index) => (
        <div
          key={card.id}
          className={`relative flex items-start h-[275px] bg-cover bg-center bg-no-repeat bg-[#244277] text-white cursor-pointer transition-all duration-1000 ease-in-out m-2 
        ${activeId === card.id ? "flex-[4]" : "flex-[1.1]"} 
        ${activeId === card.id ? "opacity-100" : "opacity-40"} 
        ${index === 0 ? "rounded-l-[10px]" : ""} 
        ${index === data.length - 1 ? "rounded-r-[10px]" : ""}`}
          onMouseOver={() => handleMouseOver(card.id)} // Use handleMouseOver here
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={`transition-[width, height] ${
              activeId === card.id ? "w-[153px] h-auto" : "w-[127px] h-full"
            }`}
          >
            <img
              className={`h-[275px] object-cover
            ${index === 0 ? "rounded-l-[10px]" : ""}
            ${
              index === data.length - 1
                ? activeId === card.id
                  ? "rounded-r-none"
                  : "rounded-r-[10px]"
                : ""
            }
          `}
              style={{ maxWidth: activeId === card.id ? "460px" : "127px" }}
              src={card.profileWeb}
              alt={card.name}
            />
          </div>
          <div
            className={`flex-grow-0 relative py-[20px] overflow-hidden transition-[width,opacity] duration-[1200ms] ease-in-out 
          ${
            activeId === card.id
              ? "opacity-100 w-[307px] px-[15px]"
              : "opacity-0 w-0"
          }`}
          >
            <p
              style={{ minWidth: "277px" }}
              className="text-[16px] font-semibold"
            >
              {card.name}
            </p>
            <p style={{ minWidth: "277px" }} className="text-[10px] mb-[10px]">
              {card.title}
            </p>
            {/* <div className="h-[24px]"></div> */}
            <p
              className="text-[10px] overflow-hidden overflow-ellipsis"
              style={{
                maxHeight: "300px",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 13,
                minWidth: "277px",
              }}
            >
              {card.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExpandingCards;
