const formatCurrency = (value) => {
  if (value === null || value === undefined) return "";
  if (value < 0) return "N/A";
  if (value === "0.0") return "00.00";
  return (+value)
    .toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    })
    .substring(1, 20);
};

export default formatCurrency;
