import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dataLoading from "../../assets/Beneficiary Icons/inputloder.gif";
import NoAccountFound from "../../assets/Beneficiary Icons/noaccfound.png";
import "./index.css";
import { formatAccountNumber } from "../_utils/FormatAccountNumber";
import { useNavigate } from "react-router-dom";
import IfscCodeMapper from "../_utils/IfscLogoMapper";
import arrow_left_white from "../../assets/ArrowsIcons/arrow_left_white.svg";
import Loader from "../Loader/Index";

const BeneViewAssociatedAccount = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [accountType, setAccountType] = useState({ type: "", id: "" });
  const [confirmButton, setConfirmButton] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [filtereddata, setFilteredData] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  const { beneLookData, beneLookLoading, verifyPANisLoading } = useSelector(
    (state) => state.verify
  );

  const bankData = useSelector(
    (state) => state.beneAllVerification.beneLookData
  );

  useEffect(() => {
    if (!beneLookLoading && !verifyPANisLoading) {
      setLoading(false);
    }
  }, [beneLookLoading, verifyPANisLoading]);

  let haveApproved = beneLookData?.beneficiary_accounts
    .filter((item) => item.id != props.beneAccountId)
    .find((val) => {
      return val?.approval_status === "approved";
    });

  let havePending = beneLookData?.beneficiary_accounts
    .filter((item) => item.id != props.beneAccountId)
    .find((val) => {
      return val?.approval_status === "pending";
    });

  let haveBlocked = beneLookData?.beneficiary_accounts
    .filter((item) => item.id != props.beneAccountId)
    .find((val) => {
      return val?.approval_status === "blocked";
    });

  let haveArchived = beneLookData?.beneficiary_accounts
    .filter((item) => item.id != props.beneAccountId)
    .find((val) => {
      return val?.approval_status === "archived";
    });

  let haveRejected = beneLookData?.beneficiary_accounts
    .filter((item) => item.id != props.beneAccountId)
    .find((val) => {
      return val?.approval_status === "rejected";
    });

  let haveBankApproved = bankData?.beneficiary_accounts
    .filter((item) => item.id != props.beneAccountId)
    .find((val) => {
      return val?.approval_status === "approved";
    });

  let havebankBlocked = bankData?.beneficiary_accounts
    .filter((item) => item.id != props.beneAccountId)
    .find((val) => {
      return val?.approval_status === "blocked";
    });

  let haveBankArchived = bankData?.beneficiary_accounts
    .filter((item) => item.id != props.beneAccountId)
    .find((val) => {
      return val?.approval_status === "archived";
    });

  let haveBankRejected = bankData?.beneficiary_accounts
    .filter((item) => item.id != props.beneAccountId)
    .find((val) => {
      return val?.approval_status === "rejected";
    });

  let haveBankPending = bankData?.beneficiary_accounts
    .filter((item) => item.id != props.beneAccountId)
    .find((val) => {
      return val?.approval_status === "pending";
    });

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#4ddd37";
      case "rejected":
        return "#ff5860";
      case "blocked":
        return "#ff5860";
      case "archived":
        return "#a3a3a3";
      default:
        return "#4ddd37";
    }
  };

  const handleButtonClick = (dataItem, id) => {
    if (
      dataItem.ifsc_code === props.IFSC &&
      dataItem.bank_acc_no === props.accountNumber
    ) {
      setSelectedAccount(dataItem);
      setConfirmButton(true);
      setSelectedIndex(null);
    } else {
      setSelectedAccount(dataItem);
      setSelectedIndex(id);
      setConfirmButton(false);
    }
  };

  const handleClick = () => {
    setConfirmButton(true);
    props.onAsscociateClose();
    navigate(`/accounts/beneficiary_account/details/${selectedAccount.id}`);
    props.toggleContactDetails();
  };

  const toggleSelectionPan = (dataItem, id) => {
    if (selectedIndex === id) {
      setSelectedIndex(null);
      setConfirmButton(true);
    } else {
      setSelectedIndex(id);
      setConfirmButton(false);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  const toggleSelectionBank = (dataItem, index) => {
    if (
      dataItem.id !== props.beneAccountId &&
      dataItem.pan_no !== props.panNumber
    ) {
      if (selectedIndex === index) {
        setSelectedIndex(null);
        setConfirmButton(true);
      } else {
        setSelectedIndex(index);
        setConfirmButton(false);
      }
    }
  };

  return (
    <>
      {/* <div className="fixed top-0 left-0 w-screen h-screen bg-black opacity-80 z-[9998]"></div> */}
      {isMobile ? (
        <div
          className="w-full overflow-hidden"
          style={{
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <div className="h-[60px] bg-[#3A5686] opacity-100 w-full flex gap-6 items-center pl-[20px]">
            <img
              // onClick={() => navigate(-1)}
              onClick={props.onAsscociateClose}
              className="w-[16px] h-[16px]"
              src={arrow_left_white}
              alt=""
            />
            <p className=" text-left font-sans  text-[16px] font-normal  text-[#F9F9F9] opacity-100">
              Associated Accounts
            </p>
          </div>
          <div className=" flex flex-col gap-[8px]  h-[110px] bg-[#1D3A6D] w-full pl-[20px] pt-[10px]">
            {/* //#1D3A6D] */}
            <p className="font-semibold text-[16px] font-sans text-[#FF7F33] opacity-100">
              Associated accounts found
            </p>
            <span className=" font-normal text-[14px]  text-[#F9F9F9]">
              The accounts listed below are linked to this account. Click on the
              account and then View to access it's details.
            </span>
          </div>
          {loading ? (
            <div className="loader-container flex justify-center items-center mt-6">
              <Loader />
            </div>
          ) : (
            <div className="w-full h-[43px] bg-[#314D7E]">
              <div className="flex justify-between h-[43px]">
                <button
                  className="h-full  w-[50%]  "
                  onClick={() => {
                    setIsActive(false);
                    setAccountType({
                      type: "",
                      id: "",
                    });
                  }}
                  style={{
                    backgroundColor: !isActive ? "#F9F9F9" : "#314D7E",
                    color: !isActive ? "#314D7E" : "#F9F9F9",

                    fontSize: "14px",
                  }}
                >
                  Associated to PAN
                </button>
                <button
                  className="h-full w-[50%] "
                  onClick={() => {
                    setIsActive(true);
                    setAccountType({
                      type: "",
                      id: "",
                    });
                  }}
                  style={{
                    backgroundColor: !isActive ? "#314D7E" : "#F9F9F9",
                    color: !isActive ? "#F9F9F9" : "#314D7E",
                    fontSize: "14px",
                  }}
                >
                  Associated to Bank
                </button>
              </div>
              <div
                className="w-full overflow-y-auto pl-[10px] pr-[10px] pb-[15px]"
                style={{
                  height: "calc(100vh - 255px)",
                }}
              >
                {isActive ? (
                  <div>
                    {loading ? (
                      <div className="loader-container flex justify-center items-center mt-6">
                        <Loader />
                      </div>
                    ) : (
                      <>
                        <div className="">
                          {haveBankApproved ||
                          haveBankPending ||
                          havebankBlocked ||
                          haveBankArchived ||
                          haveBankRejected ? (
                            <>
                              {haveBankApproved && (
                                <>
                                  <p className="text-[12px] font-semibold text-[#F9F9F9] m-2 pt-2">
                                    Approved accounts
                                  </p>
                                  <div className="achieved_main">
                                    <div className="achieved_scroll">
                                      <div className="flex flex-col gap-5 pb-[5px] py-2">
                                        {bankData?.beneficiary_accounts
                                          .filter(
                                            (item) =>
                                              item.id !== props.beneAccountId
                                          )
                                          .map((val, index) => {
                                            if (
                                              val?.approval_status ===
                                              "approved"
                                            ) {
                                              return (
                                                <div
                                                  key={index}
                                                  style={{
                                                    border:
                                                      val.ifsc_code ===
                                                        props.IFSC &&
                                                      val?.bank_acc_no ===
                                                        props.accountNumber /*&&
                                                  val.pan_no === props.panNumber
                                                    ? "2px solid orange"
                                                    : "",*/,
                                                  }}
                                                  onClick={() => {
                                                    handleButtonClick(
                                                      val,
                                                      index
                                                    );
                                                    toggleSelectionBank(
                                                      val,
                                                      index
                                                    );
                                                  }}
                                                  className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                    selectedIndex === index
                                                      ? "bg-[#667CA3]"
                                                      : "bg-[#3A5686]"
                                                  }`}
                                                >
                                                  <div className="flex flex-row -mr-4">
                                                    <div
                                                      className="w-4/5 antialiased truncate"
                                                      style={{
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                        whiteSpace: " nowrap",
                                                        textTransform:
                                                          "capitalize",
                                                        color: getStatusColor(
                                                          val.approval_status
                                                        ),
                                                      }}
                                                    >
                                                      {val.name.toLowerCase()}
                                                      <div
                                                        className="text-[#D5D5D5] self-center text-xs antialiased first-letter:capitalize"
                                                        style={{
                                                          textTransform:
                                                            "lowercase",
                                                        }}
                                                      >
                                                        Account name
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="flex flex-row justify-between">
                                                    <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                      {val.pan_no}
                                                      <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                        PAN number
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                            return null;
                                          })}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {haveBankPending && (
                                <>
                                  <p className="text-[12px] font-semibold text-[#F9F9F9] m-2 pt-1">
                                    Pending accounts
                                  </p>
                                  <div className="achieved_main">
                                    <div className="achieved_scroll">
                                      <div className="flex flex-col gap-5 pb-[5px] py-2">
                                        {bankData?.beneficiary_accounts
                                          .filter(
                                            (item) =>
                                              item.id !== props.beneAccountId
                                          )
                                          .map((val, index) => {
                                            if (
                                              val?.approval_status === "pending"
                                            ) {
                                              return (
                                                <div
                                                  key={index}
                                                  style={{
                                                    border:
                                                      val.ifsc_code ===
                                                      props.IFSC /*&&
                                                  val?.bank_acc_no ===
                                                    props.accountNumber &&
                                                  val.pan_no === props.panNumber
                                                    ? "2px solid orange"
                                                    : "",*/,
                                                  }}
                                                  onClick={() => {
                                                    handleButtonClick(
                                                      val,
                                                      index
                                                    );
                                                    toggleSelectionBank(
                                                      val,
                                                      index
                                                    );
                                                  }}
                                                  className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                    selectedIndex === index
                                                      ? "bg-[#667CA3]"
                                                      : "bg-[#3A5686]"
                                                  }`}
                                                >
                                                  <div className="flex flex-row -mr-4">
                                                    <div
                                                      className="w-4/5 antialiased truncate"
                                                      style={{
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                        whiteSpace: " nowrap",
                                                        textTransform:
                                                          "capitalize",
                                                        color: getStatusColor(
                                                          val.approval_status
                                                        ),
                                                      }}
                                                    >
                                                      {val.name.toLowerCase()}
                                                      <div
                                                        className="text-[#D5D5D5] self-center text-xs antialiased first-letter:capitalize"
                                                        style={{
                                                          textTransform:
                                                            "lowercase",
                                                        }}
                                                      >
                                                        Account name
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="flex flex-row justify-between">
                                                    <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                      {val.pan_no}
                                                      <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                        PAN number
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                            return null;
                                          })}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {haveBankRejected && (
                                <>
                                  <p className="text-[12px] font-semibold text-[#F9F9F9] m-2 pt-1">
                                    Rejected accounts
                                  </p>
                                  <div className="achieved_main">
                                    <div className="achieved_scroll">
                                      <div className="flex flex-col gap-5 pb-[5px] py-2">
                                        {bankData?.beneficiary_accounts
                                          .filter(
                                            (item) =>
                                              item.id !== props.beneAccountId
                                          )
                                          .map((val, index) => {
                                            if (
                                              val?.approval_status ===
                                              "rejected"
                                            ) {
                                              return (
                                                <div
                                                  key={index}
                                                  style={{
                                                    border:
                                                      val.ifsc_code ===
                                                      props.IFSC /*&&
                                                  val?.bank_acc_no ===
                                                    props.accountNumber &&
                                                  val.pan_no === props.panNumber
                                                    ? "2px solid orange"
                                                    : "",*/,
                                                  }}
                                                  onClick={() => {
                                                    handleButtonClick(
                                                      val,
                                                      index
                                                    );
                                                    toggleSelectionBank(
                                                      val,
                                                      index
                                                    );
                                                  }}
                                                  className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                    selectedIndex === index
                                                      ? "bg-[#667CA3]"
                                                      : "bg-[#3A5686]"
                                                  }`}
                                                >
                                                  <div className="flex flex-row -mr-4">
                                                    <div
                                                      className="w-4/5 antialiased truncate"
                                                      style={{
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                        whiteSpace: " nowrap",
                                                        textTransform:
                                                          "capitalize",
                                                        color: getStatusColor(
                                                          val.approval_status
                                                        ),
                                                      }}
                                                    >
                                                      {val.name.toLowerCase()}
                                                      <div
                                                        className="text-[#D5D5D5] self-center text-xs antialiased first-letter:capitalize"
                                                        style={{
                                                          textTransform:
                                                            "lowercase",
                                                        }}
                                                      >
                                                        Account name
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="flex flex-row justify-between">
                                                    <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                      {val.pan_no}
                                                      <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                        PAN number
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                            return null;
                                          })}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {haveBankArchived && (
                                <>
                                  <p className="text-[12px] font-semibold text-[#F9F9F9] m-2 pt-1">
                                    Archived accounts
                                  </p>
                                  <div className="achieved_main">
                                    <div className="achieved_scroll">
                                      <div className="flex flex-col gap-5 pb-[5px] py-2">
                                        {bankData?.beneficiary_accounts
                                          .filter(
                                            (item) =>
                                              item.id !== props.beneAccountId
                                          )
                                          .map((val, index) => {
                                            if (
                                              val?.approval_status ===
                                              "archived"
                                            ) {
                                              return (
                                                <div
                                                  key={index}
                                                  style={{
                                                    border:
                                                      val.ifsc_code ===
                                                      props.IFSC /*&&
                                                  val?.bank_acc_no ===
                                                    props.accountNumber &&
                                                  val.pan_no === props.panNumber
                                                    ? "2px solid orange"
                                                    : "",*/,
                                                  }}
                                                  onClick={() => {
                                                    handleButtonClick(
                                                      val,
                                                      index
                                                    );
                                                    toggleSelectionBank(
                                                      val,
                                                      index
                                                    );
                                                  }}
                                                  className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                    selectedIndex === index
                                                      ? "bg-[#667CA3]"
                                                      : "bg-[#3A5686]"
                                                  }`}
                                                >
                                                  <div className="flex flex-row -mr-4">
                                                    <div
                                                      className="w-4/5 antialiased truncate"
                                                      style={{
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                        whiteSpace: " nowrap",
                                                        textTransform:
                                                          "capitalize",
                                                        color: getStatusColor(
                                                          val.approval_status
                                                        ),
                                                      }}
                                                    >
                                                      {val.name.toLowerCase()}
                                                      <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                        <span
                                                          style={{
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                        >
                                                          Account
                                                        </span>
                                                        &nbsp;
                                                        <span
                                                          style={{
                                                            textTransform:
                                                              "lowercase",
                                                          }}
                                                        >
                                                          name
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="flex flex-row justify-between">
                                                    <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                      {val.pan_no}
                                                      <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                        PAN number
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                            return null;
                                          })}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {havebankBlocked && (
                                <div className="">
                                  <p className="text-[12px] font-semibold text-[#F9F9F9] m-2 pt-1">
                                    Blocked accounts
                                  </p>
                                  <div className="achieved_main">
                                    <div className="achieved_scroll">
                                      <div className="flex flex-col gap-5 pb-[5px] py-2">
                                        {bankData?.beneficiary_accounts
                                          .filter(
                                            (item) =>
                                              item.id !== props.beneAccountId
                                          )
                                          .map((val, index) => {
                                            if (
                                              val?.approval_status === "blocked"
                                            ) {
                                              return (
                                                <div
                                                  key={index}
                                                  style={{
                                                    border:
                                                      val.ifsc_code ===
                                                      props.IFSC /*&&
                                                  val?.bank_acc_no ===
                                                    props.accountNumber &&
                                                  val.pan_no === props.panNumber
                                                    ? "2px solid orange"
                                                    : "",*/,
                                                  }}
                                                  onClick={() => {
                                                    handleButtonClick(
                                                      val,
                                                      index
                                                    );
                                                    toggleSelectionBank(
                                                      val,
                                                      index
                                                    );
                                                  }}
                                                  className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                    selectedIndex === index
                                                      ? "bg-[#667CA3]"
                                                      : "bg-[#3A5686]"
                                                  }`}
                                                >
                                                  <div className="flex flex-row -mr-4">
                                                    <div
                                                      className="w-4/5 antialiased truncate"
                                                      style={{
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                        whiteSpace: " nowrap",
                                                        textTransform:
                                                          "capitalize",
                                                        color: getStatusColor(
                                                          val.approval_status
                                                        ),
                                                      }}
                                                    >
                                                      {val.name.toLowerCase()}
                                                      <div
                                                        className="text-[#D5D5D5] self-center text-xs antialiased first-letter:capitalize"
                                                        style={{
                                                          textTransform:
                                                            "lowercase",
                                                        }}
                                                      >
                                                        Account name
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="flex flex-row justify-between">
                                                    <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                      {val.pan_no}
                                                      <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                        PAN number
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                            return null;
                                          })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            <div className="w-full h-full flex flex-col justify-center items-center mt-4 gap-1 text-center overflow-hidden">
                              <div className="w-[40%] p-4 flex flex-row justify-center">
                                <img
                                  className="max-w-[145%] pt-8"
                                  src={NoAccountFound}
                                  alt="NoDataImg"
                                />
                              </div>
                              <div className="">
                                <p className="text-[#F9F9F9] text-[16px] font-normal">
                                  No associated accounts found for the Bank
                                  details provided
                                  <br />
                                  <span className="text-[#F9F9F9] text-[12px] font-normal">
                                    There are no existing accounts that are
                                    associated with the Bank details provided.
                                  </span>
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div>
                    {loading ? (
                      <div className="loader-container flex justify-center items-center h-full mt-6">
                        <Loader />
                      </div>
                    ) : (
                      <>
                        {haveApproved ||
                        havePending ||
                        haveBlocked ||
                        haveArchived ||
                        haveRejected ? (
                          <>
                            {haveApproved && (
                              <>
                                <p className="text-[12px] font-semibold text-[#F9F9F9] m-2 pt-2">
                                  Approved accounts
                                </p>
                                <div className="achieved_main">
                                  <div className="achieved_scroll">
                                    <div className="flex flex-col gap-5 pb-[5px] py-2">
                                      {beneLookData?.beneficiary_accounts
                                        .filter(
                                          (item) =>
                                            item.id !== props.beneAccountId
                                        )
                                        .map((val, index) => {
                                          if (
                                            val?.approval_status === "approved"
                                          ) {
                                            return (
                                              <div
                                                key={val.id}
                                                style={{
                                                  border:
                                                    val.ifsc_code ===
                                                    props.IFSC /*&&
                                                val?.bank_acc_no ===
                                                  props.accountNumber
                                                  ? "2px solid #CE720F"
                                                  : "",*/,
                                                }}
                                                onClick={() => {
                                                  handleButtonClick(
                                                    val,
                                                    val.id
                                                  );
                                                  toggleSelectionPan(
                                                    val,
                                                    val.id
                                                  );
                                                }}
                                                className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                  selectedIndex === val.id
                                                    ? "bg-[#667CA3]"
                                                    : "bg-[#3A5686]"
                                                }`}
                                              >
                                                <div className="flex flex-row -mr-4">
                                                  <div
                                                    className="w-4/5 antialiased truncate"
                                                    style={{
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      whiteSpace: " nowrap",
                                                      textTransform:
                                                        "capitalize",
                                                      color: getStatusColor(
                                                        val.approval_status
                                                      ),
                                                    }}
                                                  >
                                                    {val.name.toLowerCase()}
                                                    <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                      <span
                                                        style={{
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                      >
                                                        Account
                                                      </span>
                                                      &nbsp;
                                                      <span
                                                        style={{
                                                          textTransform:
                                                            "lowercase",
                                                        }}
                                                      >
                                                        name
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="w-2/5 text-sm flex flex-row gap-1 justify-end">
                                                    <button className="group">
                                                      <div
                                                        className={`flex justify-center text-center bg-white -mb-4 -mt-9`}
                                                        style={{
                                                          position: "relative",
                                                          borderRadius:
                                                            "0px 20px",
                                                        }}
                                                      >
                                                        <div className="w-[98px] h-[37px] flex items-center justify-center">
                                                          <img
                                                            src={require("../../assets/IfscLogo/" +
                                                              IfscCodeMapper(
                                                                val?.ifsc_code
                                                              ))}
                                                            alt="bank"
                                                            className="view-all-bank-image"
                                                          />
                                                        </div>
                                                      </div>
                                                    </button>
                                                  </div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                  <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                    {formatAccountNumber(
                                                      val.bank_acc_no
                                                    )}
                                                    <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                      Account number
                                                    </div>
                                                  </div>

                                                  <div className="text-[#FAFAFA] visible self-center text-base antialiased">
                                                    {val.ifsc_code}
                                                    {val.ifsc_code && (
                                                      <div className="text-[#D5D5D5] text-xs antialiased ml-10">
                                                        IFSC Code
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {havePending && (
                              <>
                                <p className="text-[12px] font-semibold text-[#F9F9F9] m-2 pt-1">
                                  Pending accounts
                                </p>
                                <div className="achieved_main">
                                  <div className="achieved_scroll">
                                    <div className="flex flex-col gap-5 pb-[5px] py-2">
                                      {beneLookData?.beneficiary_accounts
                                        .filter(
                                          (item) =>
                                            item.id !== props.beneAccountId
                                        )
                                        .map((val, index) => {
                                          if (
                                            val?.approval_status === "pending"
                                          ) {
                                            return (
                                              <div
                                                key={val.id}
                                                style={{
                                                  border:
                                                    val.ifsc_code ===
                                                    props.IFSC /* &&
                                                val?.bank_acc_no ===
                                                  props.accountNumber
                                                  ? "2px solid #CE720F"
                                                  : "",*/,
                                                }}
                                                onClick={() => {
                                                  handleButtonClick(
                                                    val,
                                                    val.id
                                                  );
                                                  toggleSelectionPan(
                                                    val,
                                                    val.id
                                                  );
                                                }}
                                                className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                  selectedIndex === val.id
                                                    ? "bg-[#667CA3]"
                                                    : "bg-[#3A5686]"
                                                }`}
                                              >
                                                <div className="flex flex-row -mr-4">
                                                  <div
                                                    className="w-4/5 antialiased truncate"
                                                    style={{
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      whiteSpace: " nowrap",
                                                      textTransform:
                                                        "capitalize",
                                                      color: getStatusColor(
                                                        val.approval_status
                                                      ),
                                                    }}
                                                  >
                                                    {val.name.toLowerCase()}
                                                    <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                      <span
                                                        style={{
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                      >
                                                        Account
                                                      </span>
                                                      &nbsp;
                                                      <span
                                                        style={{
                                                          textTransform:
                                                            "lowercase",
                                                        }}
                                                      >
                                                        name
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="w-2/5 text-sm flex flex-row gap-1 justify-end">
                                                    <button className="group">
                                                      <div
                                                        className={`flex justify-center text-center bg-white -mb-4 -mt-9`}
                                                        style={{
                                                          position: "relative",
                                                          borderRadius:
                                                            "0px 20px",
                                                        }}
                                                      >
                                                        <div className="w-[98px] h-[37px] flex items-center justify-center">
                                                          <img
                                                            src={require("../../assets/IfscLogo/" +
                                                              IfscCodeMapper(
                                                                val?.ifsc_code
                                                              ))}
                                                            alt="bank"
                                                            className="view-all-bank-image"
                                                          />
                                                        </div>
                                                      </div>
                                                    </button>
                                                  </div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                  <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                    {formatAccountNumber(
                                                      val.bank_acc_no
                                                    )}
                                                    <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                      Account number
                                                    </div>
                                                  </div>

                                                  <div className="text-[#FAFAFA] visible self-center text-base antialiased">
                                                    {val.ifsc_code}
                                                    {val.ifsc_code && (
                                                      <div className="text-[#D5D5D5] text-xs antialiased ml-10">
                                                        IFSC Code
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {haveRejected && (
                              <>
                                <p className="text-[12px] font-semibold text-[#F9F9F9] m-2 pt-1">
                                  Rejected accounts
                                </p>
                                <div className="achieved_main">
                                  <div className="achieved_scroll">
                                    <div className="flex flex-col gap-5 pb-[5px] py-2">
                                      {beneLookData?.beneficiary_accounts
                                        .filter(
                                          (item) =>
                                            item.id !== props.beneAccountId
                                        )
                                        .map((val, index) => {
                                          if (
                                            val?.approval_status === "rejected"
                                          ) {
                                            return (
                                              <div
                                                key={val.id}
                                                style={{
                                                  border:
                                                    val.ifsc_code ===
                                                    props.IFSC /* &&
                                                val?.bank_acc_no ===
                                                  props.accountNumber
                                                  ? "2px solid #CE720F"
                                                  : "",*/,
                                                }}
                                                onClick={() => {
                                                  handleButtonClick(
                                                    val,
                                                    val.id
                                                  );
                                                  toggleSelectionPan(
                                                    val,
                                                    val.id
                                                  );
                                                }}
                                                className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                  selectedIndex === val.id
                                                    ? "bg-[#667CA3]"
                                                    : "bg-[#3A5686]"
                                                }`}
                                              >
                                                <div className="flex flex-row -mr-4">
                                                  <div
                                                    className="w-4/5 antialiased truncate"
                                                    style={{
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      whiteSpace: " nowrap",
                                                      textTransform:
                                                        "capitalize",
                                                      color: getStatusColor(
                                                        val.approval_status
                                                      ),
                                                    }}
                                                  >
                                                    {val.name.toLowerCase()}
                                                    <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                      <span
                                                        style={{
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                      >
                                                        Account
                                                      </span>
                                                      &nbsp;
                                                      <span
                                                        style={{
                                                          textTransform:
                                                            "lowercase",
                                                        }}
                                                      >
                                                        name
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="w-2/5 text-sm flex flex-row gap-1 justify-end">
                                                    <button className="group">
                                                      <div
                                                        className={`flex justify-center text-center bg-white -mb-4 -mt-9`}
                                                        style={{
                                                          position: "relative",
                                                          borderRadius:
                                                            "0px 20px",
                                                        }}
                                                      >
                                                        <div className="w-[98px] h-[37px] flex items-center justify-center">
                                                          <img
                                                            src={require("../../assets/IfscLogo/" +
                                                              IfscCodeMapper(
                                                                val?.ifsc_code
                                                              ))}
                                                            alt="bank"
                                                            className="view-all-bank-image"
                                                          />
                                                        </div>
                                                      </div>
                                                    </button>
                                                  </div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                  <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                    {formatAccountNumber(
                                                      val.bank_acc_no
                                                    )}
                                                    <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                      Account number
                                                    </div>
                                                  </div>

                                                  <div className="text-[#FAFAFA] visible self-center text-base antialiased">
                                                    {val.ifsc_code}
                                                    {val.ifsc_code && (
                                                      <div className="text-[#D5D5D5] text-xs antialiased ml-10">
                                                        IFSC Code
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {haveArchived && (
                              <>
                                <p className="text-[12px] font-semibold text-[#F9F9F9] m-2 pt-1">
                                  Archived accounts
                                </p>
                                <div className="achieved_main">
                                  <div className="achieved_scroll">
                                    <div className="flex flex-col gap-5 pb-[5px] py-2">
                                      {beneLookData?.beneficiary_accounts
                                        .filter(
                                          (item) =>
                                            item.id !== props.beneAccountId
                                        )
                                        .map((val, index) => {
                                          if (
                                            val?.approval_status === "archived"
                                          ) {
                                            return (
                                              <div
                                                key={val.id}
                                                style={{
                                                  border:
                                                    val.ifsc_code ===
                                                    props.IFSC /* &&
                                                val?.bank_acc_no ===
                                                  props.accountNumber
                                                  ? "2px solid #CE720F"
                                                  : "",*/,
                                                }}
                                                onClick={() => {
                                                  handleButtonClick(
                                                    val,
                                                    val.id
                                                  );
                                                  toggleSelectionPan(
                                                    val,
                                                    val.id
                                                  );
                                                }}
                                                className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                  selectedIndex === val.id
                                                    ? "bg-[#667CA3]"
                                                    : "bg-[#3A5686]"
                                                }`}
                                              >
                                                <div className="flex flex-row -mr-4">
                                                  <div
                                                    className="w-4/5 antialiased truncate"
                                                    style={{
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      whiteSpace: " nowrap",
                                                      textTransform:
                                                        "capitalize",
                                                      color: getStatusColor(
                                                        val.approval_status
                                                      ),
                                                    }}
                                                  >
                                                    {val.name.toLowerCase()}
                                                    <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                      <span
                                                        style={{
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                      >
                                                        Account
                                                      </span>
                                                      &nbsp;
                                                      <span
                                                        style={{
                                                          textTransform:
                                                            "lowercase",
                                                        }}
                                                      >
                                                        name
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="w-2/5 text-sm flex flex-row gap-1 justify-end">
                                                    <button className="group">
                                                      <div
                                                        className={`flex justify-center text-center bg-white -mb-4 -mt-9`}
                                                        style={{
                                                          position: "relative",
                                                          borderRadius:
                                                            "0px 20px",
                                                        }}
                                                      >
                                                        <div className="w-[98px] h-[37px] flex items-center justify-center">
                                                          <img
                                                            src={require("../../assets/IfscLogo/" +
                                                              IfscCodeMapper(
                                                                val?.ifsc_code
                                                              ))}
                                                            alt="bank"
                                                            className="view-all-bank-image"
                                                          />
                                                        </div>
                                                      </div>
                                                    </button>
                                                  </div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                  <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                    {formatAccountNumber(
                                                      val.bank_acc_no
                                                    )}
                                                    <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                      Account number
                                                    </div>
                                                  </div>

                                                  <div className="text-[#FAFAFA] visible self-center text-base antialiased">
                                                    {val.ifsc_code}
                                                    {val.ifsc_code && (
                                                      <div className="text-[#D5D5D5] text-xs antialiased ml-10">
                                                        IFSC Code
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {haveBlocked && (
                              <div className="">
                                <p className="text-[12px] font-semibold text-[#F9F9F9] m-2 pt-1">
                                  Blocked accounts
                                </p>
                                <div className="achieved_main">
                                  <div className="achieved_scroll">
                                    <div className="flex flex-col gap-5 pb-[5px] py-2">
                                      {beneLookData?.beneficiary_accounts
                                        .filter(
                                          (item) =>
                                            item.id !== props.beneAccountId
                                        )
                                        .map((val, index) => {
                                          if (
                                            val?.approval_status === "blocked"
                                          ) {
                                            return (
                                              <div
                                                key={index}
                                                onClick={() => {
                                                  handleButtonClick(val, index);
                                                  toggleSelectionPan(
                                                    val,
                                                    index
                                                  );
                                                }}
                                                className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                  selectedIndex === index
                                                    ? "bg-[#667CA3]"
                                                    : "bg-[#3A5686]"
                                                }`}
                                                style={{
                                                  border:
                                                    val.ifsc_code ===
                                                    props.IFSC /* &&
                                                val?.bank_acc_no ===
                                                  props.accountNumber
                                                  ? "2px solid #CE720F"
                                                  : "",*/,
                                                }}
                                              >
                                                <div className="flex flex-row -mr-4">
                                                  <div
                                                    className="w-4/5 antialiased truncate"
                                                    style={{
                                                      color: getStatusColor(
                                                        val.approval_status
                                                      ),
                                                      textTransform:
                                                        "capitalize",
                                                    }}
                                                  >
                                                    {val.name.toLowerCase()}
                                                    <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                      Account name
                                                    </div>
                                                  </div>
                                                  <div className="w-2/5 text-sm flex flex-row gap-1 justify-end">
                                                    <button className="group">
                                                      <div
                                                        className={`flex justify-center text-center bg-white -mb-4 -mt-9`}
                                                        style={{
                                                          position: "relative",
                                                          borderRadius:
                                                            "0px 20px",
                                                        }}
                                                      >
                                                        <div className="w-[98px] h-[37px] flex items-center justify-center">
                                                          <img
                                                            src={require("../../assets/IfscLogo/" +
                                                              IfscCodeMapper(
                                                                val?.ifsc_code
                                                              ))}
                                                            alt="bank"
                                                            className="view-all-bank-image"
                                                          />
                                                        </div>
                                                      </div>
                                                    </button>
                                                  </div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                  <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                    {formatAccountNumber(
                                                      val.bank_acc_no
                                                    )}
                                                    <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                      Account number
                                                    </div>
                                                  </div>

                                                  <div className="text-[#FAFAFA] visible self-center text-base antialiased ">
                                                    {val.ifsc_code}
                                                    {val.ifsc_code && (
                                                      <div className="text-[#D5D5D5] text-xs antialiased ml-11">
                                                        IFSC Code
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="w-full h-full flex flex-col justify-center mt-4 items-center gap-1 text-center overflow-hidden">
                            <div className="w-[40%] p-4 flex flex-row justify-center">
                              <img
                                className="max-w-[145%] pt-8"
                                src={NoAccountFound}
                                alt="NoDataImg"
                              />
                            </div>
                            <div className=" ">
                              <p className="text-[#F9F9F9] text-[16px] font-normal">
                                No associated accounts found for the PAN details
                                provided
                                <br />
                                <span className="text-[#F9F9F9] text-[12px] font-normal">
                                  There are no existing accounts that are
                                  associated with the Bank details provided.
                                </span>
                              </p>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
              <div
                className="flex justify-center items-center h-[42px] bg-[#707070]"
                onClick={handleClick}
                disabled={confirmButton}
                style={{
                  backgroundColor: confirmButton ? "#707070" : "#69B76F",
                }}
              >
                <p className="text-center text-[#F9F9F9] font-normal text-[16px] font-sans">
                  View
                </p>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className="flex justify-center items-center fixed top-1/2 left-1/2 z-[9999]"
          style={{ transform: "translate(-50%, -50%)" }}
        >
          <div
            className="h-fit w-60% flex flex-col bg-[#102C5D] rounded-2xl pb-5"
            style={{
              width: window.innerWidth <= 768 ? "95vw" : "50vw",
              maxWidth: "600px",
            }}
          >
            <div className="flex justify-between">
              <h6 className="text-[#FF7F33] text-[20px] text-center pr-6 pl-9 pt-5 ">
                Associated accounts found
              </h6>
            </div>
            <div className="flex justify-between">
              <span className="text-[#DADADA] text-[14px] text-start pr-6 pl-9 pb-3 pt-3">
                The accounts listed below are linked to this account. Click on
                the account
                <br /> and then View to access it's details.
              </span>
            </div>

            <div className="associatebutton-acc pr-6 pl-2">
              <Button
                className="associatebuttonPan-acc"
                onClick={() => {
                  setIsActive(false);
                  setAccountType({
                    type: "",
                    id: "",
                  });
                }}
                style={{
                  backgroundColor: !isActive ? "#ffffff" : "#373F4B",
                  color: !isActive ? "#1d1d1d" : "#f9f9f9",
                }}
              >
                Associated to PAN
              </Button>
              <Button
                className="associatebuttonBank-acc"
                onClick={() => {
                  setIsActive(true);
                  setAccountType({
                    type: "",
                    id: "",
                  });
                }}
                style={{
                  backgroundColor: isActive ? "#ffffff" : "#373F4B",
                  color: isActive ? "#1d1d1d" : "#f9f9f9",
                }}
              >
                Associated to Bank
              </Button>
            </div>

            <div className="w-full overflow-y-auto h-[445px] pr-7 pl-8">
              {isActive ? (
                <div>
                  {loading ? (
                    <div className="loader-container flex justify-center items-center mt-6">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <div className="">
                        {haveBankApproved ||
                        haveBankPending ||
                        havebankBlocked ||
                        haveBankArchived ||
                        haveBankRejected ? (
                          <>
                            {haveBankApproved && (
                              <>
                                <p className="text-xs text-white m-2">
                                  Approved accounts
                                </p>
                                <div className="achieved_main">
                                  <div className="achieved_scroll">
                                    <div className="flex flex-col gap-5 pb-[5px] py-2">
                                      {bankData?.beneficiary_accounts
                                        .filter(
                                          (item) =>
                                            item.id !== props.beneAccountId
                                        )
                                        .map((val, index) => {
                                          if (
                                            val?.approval_status === "approved"
                                          ) {
                                            return (
                                              <div
                                                key={index}
                                                style={{
                                                  border:
                                                    val.ifsc_code ===
                                                      props.IFSC &&
                                                    val?.bank_acc_no ===
                                                      props.accountNumber /*&&
                                                  val.pan_no === props.panNumber
                                                    ? "2px solid orange"
                                                    : "",*/,
                                                }}
                                                onClick={() => {
                                                  handleButtonClick(val, index);
                                                  toggleSelectionBank(
                                                    val,
                                                    index
                                                  );
                                                }}
                                                className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                  selectedIndex === index
                                                    ? "bg-[#667CA3]"
                                                    : "bg-[#3A5686]"
                                                }`}
                                              >
                                                <div className="flex flex-row -mr-4">
                                                  <div
                                                    className="w-4/5 antialiased truncate"
                                                    style={{
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      whiteSpace: " nowrap",
                                                      textTransform:
                                                        "capitalize",
                                                      color: getStatusColor(
                                                        val.approval_status
                                                      ),
                                                    }}
                                                  >
                                                    {val.name.toLowerCase()}
                                                    <div
                                                      className="text-[#D5D5D5] self-center text-xs antialiased first-letter:capitalize"
                                                      style={{
                                                        textTransform:
                                                          "lowercase",
                                                      }}
                                                    >
                                                      Account name
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                  <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                    {val.pan_no}
                                                    <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                      PAN number
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {haveBankPending && (
                              <>
                                <p className="text-xs text-white m-2">
                                  Pending accounts
                                </p>
                                <div className="achieved_main">
                                  <div className="achieved_scroll">
                                    <div className="flex flex-col gap-5 pb-[5px] py-2">
                                      {bankData?.beneficiary_accounts
                                        .filter(
                                          (item) =>
                                            item.id !== props.beneAccountId
                                        )
                                        .map((val, index) => {
                                          if (
                                            val?.approval_status === "pending"
                                          ) {
                                            return (
                                              <div
                                                key={index}
                                                style={{
                                                  border:
                                                    val.ifsc_code ===
                                                    props.IFSC /*&&
                                                  val?.bank_acc_no ===
                                                    props.accountNumber &&
                                                  val.pan_no === props.panNumber
                                                    ? "2px solid orange"
                                                    : "",*/,
                                                }}
                                                onClick={() => {
                                                  handleButtonClick(val, index);
                                                  toggleSelectionBank(
                                                    val,
                                                    index
                                                  );
                                                }}
                                                className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                  selectedIndex === index
                                                    ? "bg-[#667CA3]"
                                                    : "bg-[#3A5686]"
                                                }`}
                                              >
                                                <div className="flex flex-row -mr-4">
                                                  <div
                                                    className="w-4/5 antialiased truncate"
                                                    style={{
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      whiteSpace: " nowrap",
                                                      textTransform:
                                                        "capitalize",
                                                      color: getStatusColor(
                                                        val.approval_status
                                                      ),
                                                    }}
                                                  >
                                                    {val.name.toLowerCase()}
                                                    <div
                                                      className="text-[#D5D5D5] self-center text-xs antialiased first-letter:capitalize"
                                                      style={{
                                                        textTransform:
                                                          "lowercase",
                                                      }}
                                                    >
                                                      Account name
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                  <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                    {val.pan_no}
                                                    <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                      PAN number
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {haveBankRejected && (
                              <>
                                <p className="text-xs text-white m-2">
                                  Rejected accounts
                                </p>
                                <div className="achieved_main">
                                  <div className="achieved_scroll">
                                    <div className="flex flex-col gap-5 pb-[5px] py-2">
                                      {bankData?.beneficiary_accounts
                                        .filter(
                                          (item) =>
                                            item.id !== props.beneAccountId
                                        )
                                        .map((val, index) => {
                                          if (
                                            val?.approval_status === "rejected"
                                          ) {
                                            return (
                                              <div
                                                key={index}
                                                style={{
                                                  border:
                                                    val.ifsc_code ===
                                                    props.IFSC /*&&
                                                  val?.bank_acc_no ===
                                                    props.accountNumber &&
                                                  val.pan_no === props.panNumber
                                                    ? "2px solid orange"
                                                    : "",*/,
                                                }}
                                                onClick={() => {
                                                  handleButtonClick(val, index);
                                                  toggleSelectionBank(
                                                    val,
                                                    index
                                                  );
                                                }}
                                                className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                  selectedIndex === index
                                                    ? "bg-[#667CA3]"
                                                    : "bg-[#3A5686]"
                                                }`}
                                              >
                                                <div className="flex flex-row -mr-4">
                                                  <div
                                                    className="w-4/5 antialiased truncate"
                                                    style={{
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      whiteSpace: " nowrap",
                                                      textTransform:
                                                        "capitalize",
                                                      color: getStatusColor(
                                                        val.approval_status
                                                      ),
                                                    }}
                                                  >
                                                    {val.name.toLowerCase()}
                                                    <div
                                                      className="text-[#D5D5D5] self-center text-xs antialiased first-letter:capitalize"
                                                      style={{
                                                        textTransform:
                                                          "lowercase",
                                                      }}
                                                    >
                                                      Account name
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                  <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                    {val.pan_no}
                                                    <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                      PAN number
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {haveBankArchived && (
                              <>
                                <p className="text-xs text-white m-2">
                                  Archived accounts
                                </p>
                                <div className="achieved_main">
                                  <div className="achieved_scroll">
                                    <div className="flex flex-col gap-5 pb-[5px] py-2">
                                      {bankData?.beneficiary_accounts
                                        .filter(
                                          (item) =>
                                            item.id !== props.beneAccountId
                                        )
                                        .map((val, index) => {
                                          if (
                                            val?.approval_status === "archived"
                                          ) {
                                            return (
                                              <div
                                                key={index}
                                                style={{
                                                  border:
                                                    val.ifsc_code ===
                                                    props.IFSC /*&&
                                                  val?.bank_acc_no ===
                                                    props.accountNumber &&
                                                  val.pan_no === props.panNumber
                                                    ? "2px solid orange"
                                                    : "",*/,
                                                }}
                                                onClick={() => {
                                                  handleButtonClick(val, index);
                                                  toggleSelectionBank(
                                                    val,
                                                    index
                                                  );
                                                }}
                                                className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                  selectedIndex === index
                                                    ? "bg-[#667CA3]"
                                                    : "bg-[#3A5686]"
                                                }`}
                                              >
                                                <div className="flex flex-row -mr-4">
                                                  <div
                                                    className="w-4/5 antialiased truncate"
                                                    style={{
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      whiteSpace: " nowrap",
                                                      textTransform:
                                                        "capitalize",
                                                      color: getStatusColor(
                                                        val.approval_status
                                                      ),
                                                    }}
                                                  >
                                                    {val.name.toLowerCase()}
                                                    <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                      <span
                                                        style={{
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                      >
                                                        Account
                                                      </span>
                                                      &nbsp;
                                                      <span
                                                        style={{
                                                          textTransform:
                                                            "lowercase",
                                                        }}
                                                      >
                                                        name
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                  <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                    {val.pan_no}
                                                    <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                      PAN number
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {havebankBlocked && (
                              <div className="">
                                <p className="text-xs text-white m-2">
                                  Blocked accounts
                                </p>
                                <div className="achieved_main">
                                  <div className="achieved_scroll">
                                    <div className="flex flex-col gap-5 pb-[5px] py-2">
                                      {bankData?.beneficiary_accounts
                                        .filter(
                                          (item) =>
                                            item.id !== props.beneAccountId
                                        )
                                        .map((val, index) => {
                                          if (
                                            val?.approval_status === "blocked"
                                          ) {
                                            return (
                                              <div
                                                key={index}
                                                style={{
                                                  border:
                                                    val.ifsc_code ===
                                                    props.IFSC /*&&
                                                  val?.bank_acc_no ===
                                                    props.accountNumber &&
                                                  val.pan_no === props.panNumber
                                                    ? "2px solid orange"
                                                    : "",*/,
                                                }}
                                                onClick={() => {
                                                  handleButtonClick(val, index);
                                                  toggleSelectionBank(
                                                    val,
                                                    index
                                                  );
                                                }}
                                                className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                  selectedIndex === index
                                                    ? "bg-[#667CA3]"
                                                    : "bg-[#3A5686]"
                                                }`}
                                              >
                                                <div className="flex flex-row -mr-4">
                                                  <div
                                                    className="w-4/5 antialiased truncate"
                                                    style={{
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      whiteSpace: " nowrap",
                                                      textTransform:
                                                        "capitalize",
                                                      color: getStatusColor(
                                                        val.approval_status
                                                      ),
                                                    }}
                                                  >
                                                    {val.name.toLowerCase()}
                                                    <div
                                                      className="text-[#D5D5D5] self-center text-xs antialiased first-letter:capitalize"
                                                      style={{
                                                        textTransform:
                                                          "lowercase",
                                                      }}
                                                    >
                                                      Account name
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                  <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                    {val.pan_no}
                                                    <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                      PAN number
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="w-full h-full flex flex-col justify-center items-center gap-5 text-center overflow-hidden">
                            <div className="w-[40%] p-4 flex flex-row justify-center">
                              <img
                                className="max-w-[145%] pt-5"
                                src={NoAccountFound}
                                alt="NoDataImg"
                              />
                            </div>
                            <div className="text-white text-lg antialiased">
                              <p>
                                No associated accounts found for the Bank
                                details provided
                                <br />
                                <span className="text-xs">
                                  There are no existing accounts that are
                                  associated with the Bank details provided.
                                </span>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div>
                  {loading ? (
                    <div className="loader-container flex justify-center items-center h-full mt-6">
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {haveApproved ||
                      havePending ||
                      haveBlocked ||
                      haveArchived ||
                      haveRejected ? (
                        <>
                          {haveApproved && (
                            <>
                              <p className="text-xs text-white m-2">
                                Approved accounts
                              </p>
                              <div className="achieved_main">
                                <div className="achieved_scroll">
                                  <div className="flex flex-col gap-5 pb-[5px] py-2">
                                    {beneLookData?.beneficiary_accounts
                                      .filter(
                                        (item) =>
                                          item.id !== props.beneAccountId
                                      )
                                      .map((val, index) => {
                                        if (
                                          val?.approval_status === "approved"
                                        ) {
                                          return (
                                            <div
                                              key={val.id}
                                              style={{
                                                border:
                                                  val.ifsc_code ===
                                                  props.IFSC /*&&
                                                val?.bank_acc_no ===
                                                  props.accountNumber
                                                  ? "2px solid #CE720F"
                                                  : "",*/,
                                              }}
                                              onClick={() => {
                                                handleButtonClick(val, val.id);
                                                toggleSelectionPan(val, val.id);
                                              }}
                                              className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                selectedIndex === val.id
                                                  ? "bg-[#667CA3]"
                                                  : "bg-[#3A5686]"
                                              }`}
                                            >
                                              <div className="flex flex-row -mr-4">
                                                <div
                                                  className="w-4/5 antialiased truncate"
                                                  style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: " nowrap",
                                                    textTransform: "capitalize",
                                                    color: getStatusColor(
                                                      val.approval_status
                                                    ),
                                                  }}
                                                >
                                                  {val.name.toLowerCase()}
                                                  <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                    <span
                                                      style={{
                                                        textTransform:
                                                          "capitalize",
                                                      }}
                                                    >
                                                      Account
                                                    </span>
                                                    &nbsp;
                                                    <span
                                                      style={{
                                                        textTransform:
                                                          "lowercase",
                                                      }}
                                                    >
                                                      name
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="w-2/5 text-sm flex flex-row gap-1 justify-end">
                                                  <button className="group">
                                                    <div
                                                      className={`flex justify-center text-center bg-white -mb-4 -mt-9`}
                                                      style={{
                                                        position: "relative",
                                                        borderRadius:
                                                          "0px 20px",
                                                      }}
                                                    >
                                                      <div className="w-[98px] h-[37px] flex items-center justify-center">
                                                        <img
                                                          src={require("../../assets/IfscLogo/" +
                                                            IfscCodeMapper(
                                                              val?.ifsc_code
                                                            ))}
                                                          alt="bank"
                                                          className="view-all-bank-image"
                                                        />
                                                      </div>
                                                    </div>
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="flex flex-row justify-between">
                                                <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                  {formatAccountNumber(
                                                    val.bank_acc_no
                                                  )}
                                                  <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                    Account number
                                                  </div>
                                                </div>

                                                <div className="text-[#FAFAFA] visible self-center text-base antialiased">
                                                  {val.ifsc_code}
                                                  {val.ifsc_code && (
                                                    <div className="text-[#D5D5D5] text-xs antialiased ml-10">
                                                      IFSC Code
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        return null;
                                      })}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {havePending && (
                            <>
                              <p className="text-xs text-white m-2">
                                Pending accounts
                              </p>
                              <div className="achieved_main">
                                <div className="achieved_scroll">
                                  <div className="flex flex-col gap-5 pb-[5px] py-2">
                                    {beneLookData?.beneficiary_accounts
                                      .filter(
                                        (item) =>
                                          item.id !== props.beneAccountId
                                      )
                                      .map((val, index) => {
                                        if (
                                          val?.approval_status === "pending"
                                        ) {
                                          return (
                                            <div
                                              key={val.id}
                                              style={{
                                                border:
                                                  val.ifsc_code ===
                                                  props.IFSC /* &&
                                                val?.bank_acc_no ===
                                                  props.accountNumber
                                                  ? "2px solid #CE720F"
                                                  : "",*/,
                                              }}
                                              onClick={() => {
                                                handleButtonClick(val, val.id);
                                                toggleSelectionPan(val, val.id);
                                              }}
                                              className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                selectedIndex === val.id
                                                  ? "bg-[#667CA3]"
                                                  : "bg-[#3A5686]"
                                              }`}
                                            >
                                              <div className="flex flex-row -mr-4">
                                                <div
                                                  className="w-4/5 antialiased truncate"
                                                  style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: " nowrap",
                                                    textTransform: "capitalize",
                                                    color: getStatusColor(
                                                      val.approval_status
                                                    ),
                                                  }}
                                                >
                                                  {val.name.toLowerCase()}
                                                  <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                    <span
                                                      style={{
                                                        textTransform:
                                                          "capitalize",
                                                      }}
                                                    >
                                                      Account
                                                    </span>
                                                    &nbsp;
                                                    <span
                                                      style={{
                                                        textTransform:
                                                          "lowercase",
                                                      }}
                                                    >
                                                      name
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="w-2/5 text-sm flex flex-row gap-1 justify-end">
                                                  <button className="group">
                                                    <div
                                                      className={`flex justify-center text-center bg-white -mb-4 -mt-9`}
                                                      style={{
                                                        position: "relative",
                                                        borderRadius:
                                                          "0px 20px",
                                                      }}
                                                    >
                                                      <div className="w-[98px] h-[37px] flex items-center justify-center">
                                                        <img
                                                          src={require("../../assets/IfscLogo/" +
                                                            IfscCodeMapper(
                                                              val?.ifsc_code
                                                            ))}
                                                          alt="bank"
                                                          className="view-all-bank-image"
                                                        />
                                                      </div>
                                                    </div>
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="flex flex-row justify-between">
                                                <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                  {formatAccountNumber(
                                                    val.bank_acc_no
                                                  )}
                                                  <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                    Account number
                                                  </div>
                                                </div>

                                                <div className="text-[#FAFAFA] visible self-center text-base antialiased">
                                                  {val.ifsc_code}
                                                  {val.ifsc_code && (
                                                    <div className="text-[#D5D5D5] text-xs antialiased ml-10">
                                                      IFSC Code
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        return null;
                                      })}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {haveRejected && (
                            <>
                              <p className="text-xs text-white m-2">
                                Rejected accounts
                              </p>
                              <div className="achieved_main">
                                <div className="achieved_scroll">
                                  <div className="flex flex-col gap-5 pb-[5px] py-2">
                                    {beneLookData?.beneficiary_accounts
                                      .filter(
                                        (item) =>
                                          item.id !== props.beneAccountId
                                      )
                                      .map((val, index) => {
                                        if (
                                          val?.approval_status === "rejected"
                                        ) {
                                          return (
                                            <div
                                              key={val.id}
                                              style={{
                                                border:
                                                  val.ifsc_code ===
                                                  props.IFSC /* &&
                                                val?.bank_acc_no ===
                                                  props.accountNumber
                                                  ? "2px solid #CE720F"
                                                  : "",*/,
                                              }}
                                              onClick={() => {
                                                handleButtonClick(val, val.id);
                                                toggleSelectionPan(val, val.id);
                                              }}
                                              className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                selectedIndex === val.id
                                                  ? "bg-[#667CA3]"
                                                  : "bg-[#3A5686]"
                                              }`}
                                            >
                                              <div className="flex flex-row -mr-4">
                                                <div
                                                  className="w-4/5 antialiased truncate"
                                                  style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: " nowrap",
                                                    textTransform: "capitalize",
                                                    color: getStatusColor(
                                                      val.approval_status
                                                    ),
                                                  }}
                                                >
                                                  {val.name.toLowerCase()}
                                                  <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                    <span
                                                      style={{
                                                        textTransform:
                                                          "capitalize",
                                                      }}
                                                    >
                                                      Account
                                                    </span>
                                                    &nbsp;
                                                    <span
                                                      style={{
                                                        textTransform:
                                                          "lowercase",
                                                      }}
                                                    >
                                                      name
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="w-2/5 text-sm flex flex-row gap-1 justify-end">
                                                  <button className="group">
                                                    <div
                                                      className={`flex justify-center text-center bg-white -mb-4 -mt-9`}
                                                      style={{
                                                        position: "relative",
                                                        borderRadius:
                                                          "0px 20px",
                                                      }}
                                                    >
                                                      <div className="w-[98px] h-[37px] flex items-center justify-center">
                                                        <img
                                                          src={require("../../assets/IfscLogo/" +
                                                            IfscCodeMapper(
                                                              val?.ifsc_code
                                                            ))}
                                                          alt="bank"
                                                          className="view-all-bank-image"
                                                        />
                                                      </div>
                                                    </div>
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="flex flex-row justify-between">
                                                <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                  {formatAccountNumber(
                                                    val.bank_acc_no
                                                  )}
                                                  <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                    Account number
                                                  </div>
                                                </div>

                                                <div className="text-[#FAFAFA] visible self-center text-base antialiased">
                                                  {val.ifsc_code}
                                                  {val.ifsc_code && (
                                                    <div className="text-[#D5D5D5] text-xs antialiased ml-10">
                                                      IFSC Code
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        return null;
                                      })}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {haveArchived && (
                            <>
                              <p className="text-xs text-white m-2">
                                Archived accounts
                              </p>
                              <div className="achieved_main">
                                <div className="achieved_scroll">
                                  <div className="flex flex-col gap-5 pb-[5px] py-2">
                                    {beneLookData?.beneficiary_accounts
                                      .filter(
                                        (item) =>
                                          item.id !== props.beneAccountId
                                      )
                                      .map((val, index) => {
                                        if (
                                          val?.approval_status === "archived"
                                        ) {
                                          return (
                                            <div
                                              key={val.id}
                                              style={{
                                                border:
                                                  val.ifsc_code ===
                                                  props.IFSC /* &&
                                                val?.bank_acc_no ===
                                                  props.accountNumber
                                                  ? "2px solid #CE720F"
                                                  : "",*/,
                                              }}
                                              onClick={() => {
                                                handleButtonClick(val, val.id);
                                                toggleSelectionPan(val, val.id);
                                              }}
                                              className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                selectedIndex === val.id
                                                  ? "bg-[#667CA3]"
                                                  : "bg-[#3A5686]"
                                              }`}
                                            >
                                              <div className="flex flex-row -mr-4">
                                                <div
                                                  className="w-4/5 antialiased truncate"
                                                  style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: " nowrap",
                                                    textTransform: "capitalize",
                                                    color: getStatusColor(
                                                      val.approval_status
                                                    ),
                                                  }}
                                                >
                                                  {val.name.toLowerCase()}
                                                  <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                    <span
                                                      style={{
                                                        textTransform:
                                                          "capitalize",
                                                      }}
                                                    >
                                                      Account
                                                    </span>
                                                    &nbsp;
                                                    <span
                                                      style={{
                                                        textTransform:
                                                          "lowercase",
                                                      }}
                                                    >
                                                      name
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="w-2/5 text-sm flex flex-row gap-1 justify-end">
                                                  <button className="group">
                                                    <div
                                                      className={`flex justify-center text-center bg-white -mb-4 -mt-9`}
                                                      style={{
                                                        position: "relative",
                                                        borderRadius:
                                                          "0px 20px",
                                                      }}
                                                    >
                                                      <div className="w-[98px] h-[37px] flex items-center justify-center">
                                                        <img
                                                          src={require("../../assets/IfscLogo/" +
                                                            IfscCodeMapper(
                                                              val?.ifsc_code
                                                            ))}
                                                          alt="bank"
                                                          className="view-all-bank-image"
                                                        />
                                                      </div>
                                                    </div>
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="flex flex-row justify-between">
                                                <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                  {formatAccountNumber(
                                                    val.bank_acc_no
                                                  )}
                                                  <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                    Account number
                                                  </div>
                                                </div>

                                                <div className="text-[#FAFAFA] visible self-center text-base antialiased">
                                                  {val.ifsc_code}
                                                  {val.ifsc_code && (
                                                    <div className="text-[#D5D5D5] text-xs antialiased ml-10">
                                                      IFSC Code
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        return null;
                                      })}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {haveBlocked && (
                            <div className="">
                              <p className="text-xs text-white m-2">
                                Blocked accounts
                              </p>
                              <div className="achieved_main">
                                <div className="achieved_scroll">
                                  <div className="flex flex-col gap-5 pb-[5px] py-2">
                                    {beneLookData?.beneficiary_accounts
                                      .filter(
                                        (item) =>
                                          item.id !== props.beneAccountId
                                      )
                                      .map((val, index) => {
                                        if (
                                          val?.approval_status === "blocked"
                                        ) {
                                          return (
                                            <div
                                              key={index}
                                              onClick={() => {
                                                handleButtonClick(val, index);
                                                toggleSelectionPan(val, index);
                                              }}
                                              className={`group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl overflow-hidden cursor-pointer ${
                                                selectedIndex === index
                                                  ? "bg-[#667CA3]"
                                                  : "bg-[#3A5686]"
                                              }`}
                                              style={{
                                                border:
                                                  val.ifsc_code ===
                                                  props.IFSC /* &&
                                                val?.bank_acc_no ===
                                                  props.accountNumber
                                                  ? "2px solid #CE720F"
                                                  : "",*/,
                                              }}
                                            >
                                              <div className="flex flex-row -mr-4">
                                                <div
                                                  className="w-4/5 antialiased truncate"
                                                  style={{
                                                    color: getStatusColor(
                                                      val.approval_status
                                                    ),
                                                    textTransform: "capitalize",
                                                  }}
                                                >
                                                  {val.name.toLowerCase()}
                                                  <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                    Account name
                                                  </div>
                                                </div>
                                                <div className="w-2/5 text-sm flex flex-row gap-1 justify-end">
                                                  <button className="group">
                                                    <div
                                                      className={`flex justify-center text-center bg-white -mb-4 -mt-9`}
                                                      style={{
                                                        position: "relative",
                                                        borderRadius:
                                                          "0px 20px",
                                                      }}
                                                    >
                                                      <div className="w-[98px] h-[37px] flex items-center justify-center">
                                                        <img
                                                          src={require("../../assets/IfscLogo/" +
                                                            IfscCodeMapper(
                                                              val?.ifsc_code
                                                            ))}
                                                          alt="bank"
                                                          className="view-all-bank-image"
                                                        />
                                                      </div>
                                                    </div>
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="flex flex-row justify-between">
                                                <div className="w-4/5 antialiased text-[#FAFAFA] self-center">
                                                  {formatAccountNumber(
                                                    val.bank_acc_no
                                                  )}
                                                  <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                                    Account number
                                                  </div>
                                                </div>

                                                <div className="text-[#FAFAFA] visible self-center text-base antialiased ">
                                                  {val.ifsc_code}
                                                  {val.ifsc_code && (
                                                    <div className="text-[#D5D5D5] text-xs antialiased ml-11">
                                                      IFSC Code
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                        return null;
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="w-full h-full flex flex-col justify-center items-center gap-5 text-center overflow-hidden">
                          <div className="w-[40%] p-4 flex flex-row justify-center">
                            <img
                              className="max-w-[145%] pt-5"
                              src={NoAccountFound}
                              alt="NoDataImg"
                            />
                          </div>
                          <div className="text-white text-lg antialiased">
                            <p>
                              No associated accounts found for the PAN details
                              provided
                              <br />
                              <span className="text-xs">
                                There are no existing accounts that are
                                associated with the PAN details provided.
                              </span>
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "6%",
                margin: "0px 50px",
                width: "100%",
              }}
              className="pr-8 pt-4 m-4"
            >
              <Button
                sx={{
                  borderColor: "none",
                  color: "#fff",
                  textTransform: "capitalize",
                  textDecoration: "underline",
                  backgroundColor: "transparent",
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                }}
                onClick={props.onAsscociateClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  borderRadius: "20px",
                  "&.Mui-disabled": {
                    color: confirmButton ? "#D5D5D5" : "#fff",
                  },
                  "&.MuiButton-root": {
                    color: confirmButton ? "#D5D5D5" : "#fff",
                  },
                  boxShadow: "0px 3px 6px #00000033",
                  textTransform: "capitalize",
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  textTransform: "capitalize",
                  backgroundColor: confirmButton ? "#707070" : "#69B76F",
                  cursor: confirmButton ? "not-allowed" : "pointer",
                  // border: confirmButton ? "1px solid #CCCCCC" : "transparent",
                  marginRight: "40px",
                  width: "110px",
                  "&:hover": {
                    backgroundColor: confirmButton ? "transparent" : "#69B76F",
                    boxShadow: "none",
                  },
                }}
                onClick={handleClick}
                disabled={confirmButton}
              >
                View
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BeneViewAssociatedAccount;
