import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { env } from "../../../env";
import { toast } from "react-toastify";
import { toast_position } from "../Settings/Authorization";
 
const baseUrl = env.REACT_APP_BASE_URL;
 
export const xpentraLogout = createAsyncThunk(
    "xpentraLogout",
    async (_, rejectWithValue) => {
        try {
 
            const response = await fetch(`${baseUrl}/users/logout`, {
                "method": "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "X-Api-Key": sessionStorage.getItem("q2p_token"),
                },
            });
            if (!response.ok) {
                const errordata = await response.json();
                throw new Error(JSON.stringify(errordata));
            }
            const data = await response.json();
 
            if (data?.status) {
                toast.success(data?.message, {...toast_position, style: {color: "var(--Primary_white)"}});
            }
 
            return data;
 
        } catch (error) {
 
            if (JSON.parse(error?.message)?.errors?.token_expired) {
                toast.error(JSON.parse(error?.message)?.errors?.token_expired[0], toast_position);
            } else if (JSON.parse(error?.message)?.errors?.user_unauthorized) {
                toast.error(JSON.parse(error?.message)?.errors?.user_unauthorized[0], toast_position);
            }
 
            return rejectWithValue(JSON.parse(error?.message)?.errors);
        
        }
    }
);
 
const initialState = {
    xpentraLogoutLoading: null,
    xpentraLogoutData: null,
    xpentraLogoutError: null,
};
 
export const xpentraLogoutSlice = createSlice({
    name: "xpentraLogoutSlice",
    initialState,
    reducers: {
        xpentraLogoutSliceReset: (state) => {
            state.xpentraLogoutLoading = initialState?.xpentraLogoutLoading;
            state.xpentraLogoutData = initialState?.xpentraLogoutData;
            state.xpentraLogoutError = initialState?.xpentraLogoutError;
        }
    },
    extraReducers: (reducerResults) => {
        reducerResults.addCase(xpentraLogout.pending, (state) => {
            state.xpentraLogoutLoading = true;
        });
        reducerResults.addCase(xpentraLogout.fulfilled, (state, action) => {
            state.xpentraLogoutLoading = false;
            state.xpentraLogoutData = action?.payload;
        });
        reducerResults.addCase(xpentraLogout.rejected, (state, action) => {
            state.xpentraLogoutLoading = false;
            state.xpentraLogoutData = false;
            state.xpentraLogoutError = {isErrorStatus: true};
        });
    }
});
 
export const { xpentraLogoutSliceReset } = xpentraLogoutSlice.actions;
 
export default xpentraLogoutSlice.reducer;