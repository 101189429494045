import React, { useRef, useState } from "react";
import "./index.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import forgotSuccess from "../../../assets/loginImage/forgotSuccess.png";
import xpentraLogo from "../../../assets/TransactionDashboardIcons/Xpentra.png";
import { logout } from "../../../redux/features/refreshToken/refreshToken";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import {
  changePassword,
  resetFirstLoginData,
  setMultipleEntity,
  setShowReloginModalFalse,
} from "../../../redux/features/login/login";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import Loader from "../../../assets/LoaderIcons/Loader.gif";
import eyeOff from "../../../assets/PasswordIcons/password_hidden_darkgrey.svg";
import eyeOn from "../../../assets/PasswordIcons/password_visible_darkgrey.svg";

const SetNewPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLabelLoading, setIsLabelLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const oldInputRef = useRef(null);
  const newInputRef = useRef(null);
  const confirmInputRef = useRef(null);

  const handleClickShowOldPassword = () => {
    const input = oldInputRef.current;
    const cursorPosition = input.selectionStart;
    setShowOldPassword((prev) => !prev);
    setTimeout(() => {
      input.setSelectionRange(cursorPosition, cursorPosition);
      input.focus();
    }, 0);
  };

  const handleClickShowNewPassword = () => {
    const newInput = newInputRef.current;
    const cursorPosition = newInput.selectionStart;

    setShowNewPassword((prev) => !prev);
    setTimeout(() => {
      newInput.setSelectionRange(cursorPosition, cursorPosition);
      newInput.focus();
    }, 0);
  };
  const handleClickShowConfirmPassword = () => {
    const confirmInput = confirmInputRef.current;
    const cursorPosition = confirmInput.selectionStart;
    setShowConfirmPassword((prev) => !prev);
    setTimeout(() => {
      confirmInput.setSelectionRange(cursorPosition, cursorPosition);
      confirmInput.focus();
    }, 0);
  };

  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleChange = (field, value) => {
    const cleanedValue = value.replace(/\s/g, "");
    setPassword((prevPassword) => ({
      ...prevPassword,
      [field]: cleanedValue,
    }));
  };

  const handleSetNewPassword = async () => {
    setIsLabelLoading(true);
    try {
      const result = await dispatch(changePassword(password));
      if (result?.payload?.status) {
        toast.success("Password reset successfully!", toast_position);
        dispatch(setMultipleEntity());
        dispatch(setShowReloginModalFalse());
        dispatch(logout());
        dispatch(resetFirstLoginData());
        navigate("/login");
      }
    } catch (error) {
      console.error("Password change failed", error);
    } finally {
      setIsLabelLoading(false);
    }
  };

  const isCredentialsFilled =
    password.newPassword && password.confirmPassword && password.oldPassword;
  const isPasswordMatch = password.newPassword === password.confirmPassword;

  return (
    <div className="w-full h-full absolute">
      <div
        className="flex flex-col md:flex-row justify-center items-center max-w-[1024px] h-full"
        style={{ margin: "auto" }}
      >
        <div className="flex-1 w-full md:w-1/2 flex flex-col items-center reset-password-user">
          <img src={forgotSuccess} className="w-full h-auto" alt="bg_image" />
          <div className="text-center mt-8 text-[#FAFAFA]">
            <h1 className="text-2xl">Login successful!</h1>
            <p className="mt-4 pt-5" style={{ marginBottom: "20px" }}>
              It is mandatory for you to set <br />a new password, which is not
              the same as the <br /> password provided by the admin.
            </p>
          </div>
        </div>
        <div className="flex-1 w-full md:w-1/2 px-4">
          <div className="bg-white p-12 shadow-md rounded-[40px]">
            <div className="w-1/2 mx-auto mb-16 flex justify-center">
              <img src={xpentraLogo} alt="xpentraLogo" />
            </div>
            <label className="block text-base font-semibold">
              Set new password
            </label>
            <div className="mb-6 mt-3">
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel
                  htmlFor="standard-adornment-old-password"
                  sx={{
                    color: password.oldPassword
                      ? "#707070"
                      : isActive
                      ? "#707070"
                      : "#313131",
                    font: "normal normal 600 16px/22px Open Sans",
                    fontSize: "16px",
                    "&.Mui-focused": {
                      color: "#2273ef !important",
                    },
                  }}
                >
                  Old password
                </InputLabel>
                <Input
                  id="standard-adornment-old-password"
                  type={showOldPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        id="standard-adornment-password"
                        onClick={handleClickShowOldPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        <img
                          src={showOldPassword ? eyeOn : eyeOff}
                          alt={
                            showOldPassword ? "Hide password" : "Show password"
                          }
                          style={{ width: "18px", height: "18px" }}
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={password.oldPassword}
                  onChange={(e) => handleChange("oldPassword", e.target.value)}
                  inputRef={oldInputRef}
                  sx={{
                    color: "#313131 !important",
                    "&:before": {
                      borderBottomColor: password.oldPassword
                        ? "#707070"
                        : isActive
                        ? "#2273ef"
                        : "#313131",
                    },
                    "&:after": {
                      borderBottomColor: password.oldPassword
                        ? ""
                        : isActive
                        ? ""
                        : "#2273ef",
                    },
                    "&:hover:not(.Mui-disabled):before": {
                      borderBottomColor: password.oldPassword
                        ? "#707070"
                        : isActive
                        ? "#2273ef"
                        : "#707070",
                    },
                    "&:hover:not(.Mui-disabled):after": {
                      borderBottomColor: password.oldPassword
                        ? "#2273ef"
                        : isActive
                        ? ""
                        : "#2273ef",
                    },
                  }}
                />
              </FormControl>
            </div>
            <div className="my-6">
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel
                  htmlFor="standard-adornment-new-password"
                  sx={{
                    color: password.newPassword
                      ? "#707070"
                      : isActive
                      ? "#707070"
                      : "#313131",
                    font: "normal normal 600 16px/22px Open Sans",
                    fontSize: "16px",
                    "&.Mui-focused": {
                      color: "#2273ef !important",
                    },
                  }}
                >
                  New password
                </InputLabel>
                <Input
                  id="standard-adornment-new-password"
                  type={showNewPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        <img
                          src={showNewPassword ? eyeOn : eyeOff}
                          alt={
                            showNewPassword ? "Hide password" : "Show password"
                          }
                          style={{ width: "18px", height: "18px" }}
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={password.newPassword}
                  onChange={(e) => handleChange("newPassword", e.target.value)}
                  inputRef={newInputRef}
                  sx={{
                    color: "#313131 !important",
                    "&:before": {
                      borderBottomColor: isPasswordMatch
                        ? "#707070"
                        : isActive
                        ? "#2273ef"
                        : "#FF0001",
                    },
                    "&:after": {
                      borderBottomColor: isPasswordMatch
                        ? "#2273ef"
                        : isActive
                        ? "#2273ef"
                        : "#FF0001",
                    },
                    "&:hover:not(.Mui-disabled):before": {
                      borderBottomColor: isPasswordMatch
                        ? "#707070"
                        : isActive
                        ? "#2273ef"
                        : "",
                    },
                    "&:hover:not(.Mui-disabled):after": {
                      borderBottomColor: isPasswordMatch
                        ? "#2273ef"
                        : isActive
                        ? "#2273ef"
                        : "#FF0001",
                    },
                  }}
                />
              </FormControl>
            </div>
            <div className="mt-5">
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel
                  htmlFor="standard-adornment-confirm-password"
                  sx={{
                    color: password.confirmPassword
                      ? "#707070"
                      : isActive
                      ? "#707070"
                      : "#313131",
                    font: "normal normal 600 16px/22px Open Sans",
                    fontSize: "16px",
                    "&.Mui-focused": {
                      color: "#2273ef !important",
                    },
                  }}
                >
                  Confirm password
                </InputLabel>
                <Input
                  id="standard-adornment-confirm-password"
                  type={showConfirmPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        <img
                          src={showConfirmPassword ? eyeOn : eyeOff}
                          alt={
                            showConfirmPassword
                              ? "Hide password"
                              : "Show password"
                          }
                          style={{ width: "18px", height: "18px" }}
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                  value={password.confirmPassword}
                  onChange={(e) =>
                    handleChange("confirmPassword", e.target.value)
                  }
                  inputRef={confirmInputRef}
                  sx={{
                    color: "#313131 !important",
                    "&:before": {
                      borderBottomColor: isPasswordMatch
                        ? "#707070"
                        : isActive
                        ? "#2273ef"
                        : "#FF0001",
                    },
                    "&:after": {
                      borderBottomColor: isPasswordMatch
                        ? "#2273ef"
                        : isActive
                        ? "#2273ef"
                        : "#FF0001",
                    },
                    "&:hover:not(.Mui-disabled):before": {
                      borderBottomColor: isPasswordMatch
                        ? "#707070"
                        : isActive
                        ? "#2273ef"
                        : "#FF0001",
                    },
                    "&:hover:not(.Mui-disabled):after": {
                      borderBottomColor: isPasswordMatch
                        ? "#2273ef"
                        : isActive
                        ? "#2273ef"
                        : "#FF0001",
                    },
                  }}
                />
                {!isPasswordMatch && password.confirmPassword && (
                  <p
                    style={{
                      color: "#FF0001",
                      fontSize: "14px",
                      marginTop: "8px",
                      fontWeight: "600",
                    }}
                  >
                    The passwords do not match
                  </p>
                )}
              </FormControl>
            </div>
            <div className="text-center">
              <button
                id="button"
                className={`mb-0 px-5 w-full setBtncolor ${
                  isCredentialsFilled && isPasswordMatch ? "filled" : "disabled"
                }`}
                disabled={!isCredentialsFilled || !isPasswordMatch}
                onClick={handleSetNewPassword}
              >
                {isLabelLoading ? (
                  <img
                    src={Loader}
                    alt="Loading"
                    style={{
                      width: "40px",
                      height: "40px",
                      margin: "auto",
                    }}
                  />
                ) : (
                  <span>Set new password</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetNewPassword;
