import React, { useEffect, useRef, useState } from "react";
import arrowup from "../../../assets/OwnerAccount/arrowup.png";
import arrowdown from "../../../assets/OwnerAccount/arrow_down.svg";
import clear_filter from "../../../assets/ViewAllTransactions/ClearFilter.svg";
import allChecker from "../../../assets/Beneficiary Icons/whitecheckmark.png";
import "./index.css";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";

const DistributionStatusPopover = ({
  typepop,
  data,
  setData,
  getAccStatus,
  lifecycleStatus,
  selectedStatus,
  setPage,
  isStatusSelected,
  isDistributionSelected,
  setIsDistributionSelected,
  isDistributionPopoverOpen,
  setIsDistributionPopoverOpen,
  DashBoardStatusData,
  DistributionStatusData,
  SetDashBoardStatusData,
  setSelectedBeneAccId,
  setBeneAccountId,
  setAccountName,
  isSidebarOpen,
  showSearchBar,
}) => {
  const distributionRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  const handleClick = () => {
    if (isStatusSelected) {
      toast.error(
        "Only one status filter can be selected at a time.",
        toast_position
      );
    } else {
      setIsDistributionPopoverOpen((prev) => !prev);
    }
  };

  const handleClose = () => {
    setIsDistributionPopoverOpen(false);
    SetDashBoardStatusData("all");
  };

  const handleClearStatus = (event) => {
    if (event) event.stopPropagation();
    handleClose();
    getAccStatus(null, "distribution");
    SetDashBoardStatusData("all");
    setIsDistributionSelected(false);

    setPage(1);
  };

  const handleStatus = (status) => {
    handleClose();

    let statusToSend;

    switch (status) {
      case "distribution complete":
        statusToSend = "distribution_complete";
        break;
      case "distribution created":
        statusToSend = "created";
        break;
      case "distribution initiated":
        statusToSend = "distribution_initiated";
        break;
      case "distribution approved":
        statusToSend = "approved";
        break;
      case "distribution pending":
        statusToSend = "distribution_pending";
        break;
      case "awaiting funds":
        statusToSend = "awaiting_funds";
        break;
      case "distribution reversal":
        statusToSend = "reversal";
        break;
      case "distribution denied":
        statusToSend = "denied";
        break;
      case "distribution rejected":
        statusToSend = "rejected";
        break;
      case "distribution failed":
        statusToSend = "failed";
        break;
      case "distribution abandoned":
        statusToSend = "abandoned";
        break;
      default:
        statusToSend = status;
        break;
    }

    getAccStatus(statusToSend, "distribution");
    setPage(1);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "distribution rejected":
      case "distribution cancelled":
      case "distribution denied":
      case "distribution failed":
      case "distribution reversal":
      case "reversal":
      case "denied":
      case "rejected":
      case "failed":
        return "var(--Failed)";
      case "distribution initiated":
      case "distribution_initiated":
      case "distribution approved":
      case "approved":
      case "distribution pending":
      case "distribution_pending":
      case "awaiting funds":
      case "awaiting_funds":
      case "distribution created":
      case "created":
        return "var(--Queued)";
      case "distribution complete":
      case "distribution_complete":
        return "var(--Settled)";
      case "distribution abandoned":
      case "abandoned":
        return "var(--Abandoned)";
      case "all":
      default:
        return "var(--Primary_white)";
    }
  };

  const formatStatusText = (status) => {
    if (!status || typeof status !== "string") {
      return "";
    }

    let nameElement = `${status[0]?.toUpperCase()}${status
      .slice(1)
      .toLowerCase()
      .replace("_", " ")}`;

    return nameElement;
  };

  const getIcon = () => {
    return { src: arrowdown, onClick: handleClick };
  };

  const { src: icon, onClick: iconClickHandler } = getIcon();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        distributionRef.current &&
        !distributionRef.current.contains(event.target)
      ) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const statuses = [
    "all",
    "distribution complete",
    "distribution created",
    "distribution approved",
    "awaiting funds",
    "distribution initiated",
    "distribution rejected",
    "distribution failed",
    "distribution abandoned",
  ];
  useEffect(() => {
    if (DashBoardStatusData !== "all" && DistributionStatusData === true) {
      handleStatus(DashBoardStatusData);
    }
  }, [DashBoardStatusData]);
  return (
    <div
      ref={distributionRef}
      className="beneTxnViewAllDistriPopover"
      style={{
        opacity: isStatusSelected ? 0.5 : 1,
        cursor: isStatusSelected ? "default" : "pointer",
      }}
    >
      <div
        className="benTxnViewall_distri_inner_div flex justify-between relative"
        style={{
          borderBottom: isDistributionPopoverOpen
            ? "1px solid var(--Blue_3)"
            : "none",
          opacity: isStatusSelected ? 0.5 : 1,
          cursor: isStatusSelected ? "default" : "pointer",
          alignContent: "center",
          height: typepop == "internalTxnDistributionStatus" ? "92px" : "93px",
          padding: "5px 20px",
          // marginLeft: "0",
          //gap: isMobile ? "100px" : "20px",
        }}
        onClick={() => {
          if (!isSidebarOpen && !showSearchBar) {
            handleClick();
          }
        }}
      >
        <div
          className="flex flex-col"
          style={{
            width: isMobile ? "80%" : "",
            marginTop: isMobile ? "" : "12px",
          }}
        >
          <button
            id="bene_txn_distri_popover_section"
            className={`bene_txn_pop_overbtn truncate ${formatStatusText(
              selectedStatus
            )}`}
            style={{ color: getStatusColor(selectedStatus) }}
          >
            <span className="bene_txn_main_content">
              {selectedStatus === "created"
                ? "Distribution created"
                : selectedStatus === "approved"
                ? "Distribution approved"
                : selectedStatus === "reversal"
                ? "Distribution reversal"
                : selectedStatus === "denied"
                ? "Distribution denied"
                : selectedStatus === "rejected"
                ? "Distribution rejected"
                : selectedStatus === "failed"
                ? "Distribution failed"
                : selectedStatus === "abandoned"
                ? "Distribution abandoned"
                : formatStatusText(selectedStatus || "All")}
            </span>
          </button>
          <p className="bene_viewall_distri_subHeading">Distribution status</p>
        </div>
        <div className={`flex flex-col-reverse gap-1 ${isMobile && "pb-4"}`}>
          <div
            style={{
              width: "20px",
              height: "20px",
              marginBottom: "8px",
            }}
            onClick={(event) => {
              if (!isSidebarOpen && !showSearchBar) {
                event.stopPropagation();
                if (iconClickHandler) {
                  iconClickHandler();
                }
              }
            }}
          >
            {icon && (
              <img
                src={icon}
                alt={
                  selectedStatus === "All" ? "Arrow Icon" : "Clear Filter Icon"
                }
                className={`bene_txndistri_status_icon ${
                  selectedStatus === "All" ? "arrow-icon" : "cancel-icon"
                } ${isDistributionPopoverOpen ? "rotate-180" : "rotate-0"}`}
              />
            )}
          </div>
          <div
            style={{
              width: "20px",
              height: "20px",
              visibility:
                selectedStatus !== "all" && selectedStatus !== null
                  ? "visible"
                  : "hidden",
            }}
            onClick={(event) => {
              if (!isSidebarOpen && !showSearchBar) {
                event.stopPropagation();
                handleClearStatus();
              }
            }}
          >
            <img
              src={clear_filter}
              alt={
                selectedStatus === "All" ? "Arrow Icon" : "Clear Filter Icon"
              }
              className={`bene_txn_status_icon ${
                selectedStatus === "All" ? "arrow-icon" : "cancel-icon"
              }`}
              style={{
                display:
                  selectedStatus !== "All" && selectedStatus !== null
                    ? "block"
                    : "none",
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={`bene-txn-distri-viewall-popover ${
          isDistributionPopoverOpen ? "open" : "closed"
        }`}
      >
        {statuses.map((status) => (
          <div
            className={`popover-item ${formatStatusText(
              status
            )}_bene_txn_distri_popover_btn h-[30px] font-semibold hover:bg-Blue_6 rounded-[5px] flex items-center justify-between mt-[5px] md:pl-[14px] sm:pl-0 `}
            key={status}
            onClick={() => handleStatus(status)}
            style={{
              transition: "opacity 0.3s ease-in",
              opacity: isDistributionPopoverOpen ? "1" : "0",
              visibility: isDistributionPopoverOpen ? "visible" : "hidden",
              color: getStatusColor(status),
              fontSize: "14px",
              padding: "0 3px 0 8px",
              backgroundColor:
                (selectedStatus === status && selectedStatus !== "all") ||
                (status === "distribution complete" &&
                  selectedStatus === "distribution_complete") ||
                (status === "distribution initiated" &&
                  selectedStatus === "distribution_initiated") ||
                (status === "distribution pending" &&
                  selectedStatus === "distribution_pending") ||
                (status === "awaiting funds" &&
                  selectedStatus === "awaiting_funds") ||
                (status === "distribution created" &&
                  selectedStatus === "created") ||
                (status === "distribution created" &&
                  selectedStatus === "created") ||
                (status === "distribution reversal" &&
                  selectedStatus === "reversal") ||
                (status === "distribution denied" &&
                  selectedStatus === "denied") ||
                (status === "distribution rejected" &&
                  selectedStatus === "rejected") ||
                (status === "distribution failed" &&
                  selectedStatus === "failed") ||
                (status === "distribution approved" &&
                  selectedStatus === "approved") ||
                (status === "distribution abandoned" &&
                  selectedStatus === "abandoned")
                  ? "var(--Blue_6)"
                  : "",
            }}
          >
            {status === selectedStatus ||
            (status === "distribution complete" &&
              selectedStatus === "distribution_complete") ||
            (status === "distribution initiated" &&
              selectedStatus === "distribution_initiated") ||
            (status === "distribution pending" &&
              selectedStatus === "distribution_pending") ||
            (status === "awaiting funds" &&
              selectedStatus === "awaiting_funds") ||
            (status === "distribution created" &&
              selectedStatus === "created") ||
            (status === "distribution reversal" &&
              selectedStatus === "reversal") ||
            (status === "distribution denied" && selectedStatus === "denied") ||
            (status === "distribution abandoned" &&
              selectedStatus === "abandoned") ||
            (status === "distribution rejected" &&
              selectedStatus === "rejected") ||
            (status === "distribution failed" && selectedStatus === "failed") ||
            (status === "distribution approved" &&
              selectedStatus === "approved") ? (
              <>
                <span className="sm:w-auto w-[135px] truncate">
                  {formatStatusText(status)}
                </span>

                <img
                  src={allChecker}
                  alt="Checkmark"
                  className="all-txn-status-icon mr-1"
                />
              </>
            ) : (
              <span className="sm:w-auto w-[165px] truncate">
                {formatStatusText(status)}
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DistributionStatusPopover;
