import { useEffect, useState } from "react";

export const useCustomHook = (isMounted, delayTime, direction) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender]);

  const animationClass = isMounted
    ? direction === "next"
      ? "mountedRight"
      : "mountedLeft"
    : direction === "next"
    ? "unmountedLeft"
    : "unmountedRight";

  return { shouldRender, animationClass };
};
