import React from "react";
import "./index.css";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";

const BeneCardLogo = (props) => {
  return (
    <div
      className={`flex justify-center items-center group-hover:bg-blue-100 text-center bg-white `}
      style={{
        position: "relative",
        borderRadius:
          props.owner || props?.type === "account"
            ? "10px 0px 0px 10px"
            : "20px 0px",
        width: props?.type === "account" ? "65px" : "98px",
        height: props?.type === "account" ? "26px" : "30px",
      }}
    >
      <div className="view-all-bank-div">
        <img
          src={require("../../../../assets/IfscLogo/" +
            IfscCodeMapper(props.data?.ifsc_code))}
          alt="bank"
          className="view-all-bank-image"
          style={{
            width: props?.type === "account" ? "39px" : "58px",
            height: props?.type === "account" ? "10px" : "22px",
          }}
        />
      </div>
    </div>
  );
};

export default BeneCardLogo;
