import React from "react";
import InternalAccDetail from "../../../containers/Accounts/InternalAccount/InternalAccountDetail";
const InternalAccDetailsPopUp = ({
  closeBeneAccountDetailModal,
  bene_account_id,
  type,
}) => {
  return (
    <>
      <div
        className="beneAccountDetailPop_div fixed overflow-hidden rounded-[20px] z-[999] bg-Blue_3"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="h-full w-full">
          <InternalAccDetail
            bene_account_id={bene_account_id}
            closeBeneAccountDetailModal={closeBeneAccountDetailModal}
            type={type}
            servicesType="BenAdmin"
          />
        </div>
      </div>
    </>
  );
};

export default InternalAccDetailsPopUp;
