import React, { useRef } from "react";
import debounce from "lodash/debounce"; // Import the debounce function from lodash
import "./index.css";
import Search from "../../../assets/FilterSearchIcons/search_white.svg";
import CircleCancel from "../../../assets/CloseIcons/close_with_white_circle.svg";

const SearchBar = ({
  smallSearch,
  setSmallSearch,
  inputRef1,
  removeAccNo,
  setFilterPayload,
  setRefreshAnimationId,
  filterPayload,
}) => {
  const debouncedSetFilterPayload = useRef(
    debounce((value) => {
      setFilterPayload((prevFilterPayload) => ({
        ...prevFilterPayload,
        accNoInput: value,
      }));
    }, 300)
  ).current;

  const handleChange = (e) => {
    const { value } = e.target;
    setFilterPayload({ ...filterPayload, searchTerm: value });
    debouncedSetFilterPayload(value);
  };

  return (
    <>
      {!smallSearch ? (
        <button
          className="smallInputIcon"
          onClick={() => {
            setSmallSearch(true);
            setRefreshAnimationId("");
            setTimeout(() => {
              inputRef1.current.focus();
            }, 100);
          }}
        >
          <img src={Search} alt="search" />
        </button>
      ) : (
        <div className="input_div">
          <img
            src={Search}
            alt="search"
            className="search_owner_acc_bar"
            onClick={() => inputRef1.current.focus()}
          />
          <input
            ref={inputRef1}
            type="text"
            value={filterPayload?.searchTerm}
            onChange={handleChange}
            placeholder="Search"
          />
          <img
            src={CircleCancel}
            alt="search"
            className="search_owner_acc_bar"
            onClick={() => {
              removeAccNo();
            }}
            style={{
              cursor: "pointer",
            }}
          />
        </div>
      )}
    </>
  );
};

export default SearchBar;
