import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { toast_position } from "../Settings/Authorization";
import { env } from "../../../env";

const baseUrl = env.REACT_APP_BASE_URL;

export const beneInternalDashboard = createAsyncThunk(
  "beneInternalDashboard",
  async ({ page, page_size }) => {
    try {
      const response = await axios.get(`${baseUrl}/accounts/internal`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page,
          page_size: page_size,
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error, toast_position);
      throw error;
    }
  }
);
export const beneInternalViewAllAcc = createAsyncThunk(
  "beneInternalViewAllAcc",
  async (values) => {
    const {
      page_no,
      page_size,
      approval_status,
      categoryName,
      search_string,
      bank_account_no,
      ifsc,
      start_date,
      end_date,
      pan,
    } = values;
    try {
      const response = await axios.get(`${baseUrl}/accounts/internal`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page_no,
          page_size: page_size,
          approval_status:
            approval_status && approval_status?.length !== 0
              ? [approval_status]
              : "",
          category: categoryName,
          search_string: search_string,
          start_date,
          end_date: start_date,
          end_date,
          bank_account_no: bank_account_no,
          ifsc: ifsc,
          pan: pan,
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error, toast_position);
      throw error;
    }
  }
);

export const viewInternalAccountDetail = createAsyncThunk(
  "viewInternalAccountDetail",
  async (values) => {
    const { id } = values ? values : { id: null };

    try {
      const response = await axios.get(`${baseUrl}/accounts/internal/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const beneInternalRequiredApproval = createAsyncThunk(
  "beneInternalRequiredApproval",
  async (values) => {
    const { page_no } = values;
    try {
      const response = await axios.get(`${baseUrl}/accounts/internal`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          approval_status: ["pending"],
          page_no: page_no,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const beneInternalWaitingApproved = createAsyncThunk(
  "beneWaitingApproved",
  async (id) => {
    const requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(
      `${baseUrl}/accounts/internal/${id}/approve`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.log("error", error);
      });
  }
);
export const beneInternalDeniedApproval = createAsyncThunk(
  "beneInternalDeniedApproval",
  async ({ id, AccountIdData }) => {
    const data = {
      txn_id: [id],
      rejection_reason: AccountIdData,
    };
    const requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },

      body: JSON.stringify(data),
    };

    return await fetch(
      `${baseUrl}/accounts/internal/${id}/reject`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.log("error", error);
      });
  }
);
const internalSlice = createSlice({
  name: "internalAcc",
  initialState: {
    beneInternalDashboardAccData: null,
    beneInternalDashboardAccLoading: null,
    beneInternalDashboardAccError: null,
    beneInternalViewAllAccData: null,
    beneInternalViewAllAccLoading: null,
    beneInternalViewAllAccError: null,
    viewInternalAccountDetailData: null,
    viewInternalAccountDetailLoading: null,
    viewInternalAccountDetailError: null,
    beneInternalRequiredApprovalData: null,
    beneInternalRequiredApprovalLoading: null,
    beneInternalRequiredApprovalError: null,
    beneInternalWaitingApprovalData: null,
    beneInternalWaitingApprovalLoading: null,
    beneInternalWaitingApprovalError: null,
    beneInternalDeniedApprovalData: null,
    beneInternalDeniedApprovalLoading: null,
    beneInternalDeniedApprovalError: null,
    toggle: false,
  },
  reducers: {
    resetViewInternalAccountDetailData(state) {
      state.viewInternalAccountDetailData = null;
      state.viewInternalAccountDetailError = null;
    },
    resetInternalToggle(state) {
      state.toggle = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(beneInternalDashboard.pending, (state) => {
      state.beneInternalDashboardAccLoading = true;
    });
    builder.addCase(beneInternalDashboard.fulfilled, (state, action) => {
      state.beneInternalDashboardAccLoading = false;
      state.beneInternalDashboardAccData = action.payload;
    });
    builder.addCase(beneInternalDashboard.rejected, (state) => {
      state.beneInternalDashboardAccError = true;
      state.beneInternalDashboardAccLoading = false;
      state.beneInternalDashboardAccData = null;
    });
    builder.addCase(beneInternalViewAllAcc.pending, (state) => {
      state.beneInternalViewAllAccLoading = true;
    });
    builder.addCase(beneInternalViewAllAcc.fulfilled, (state, action) => {
      state.beneInternalViewAllAccLoading = false;
      state.beneInternalViewAllAccData = action.payload;
    });
    builder.addCase(beneInternalViewAllAcc.rejected, (state) => {
      state.beneInternalViewAllAccError = true;
      state.beneInternalViewAllAccData = null;
      state.beneInternalViewAllAccLoading = false;
    });
    builder.addCase(viewInternalAccountDetail.pending, (state) => {
      state.viewInternalAccountDetailLoading = true;
    });
    builder.addCase(viewInternalAccountDetail.fulfilled, (state, action) => {
      state.viewInternalAccountDetailLoading = false;
      state.viewInternalAccountDetailData = action.payload;
    });
    builder.addCase(viewInternalAccountDetail.rejected, (state) => {
      state.viewInternalAccountDetailLoading = false;
      state.viewInternalAccountDetailError = true;
      state.viewInternalAccountDetailData = null;
    });

    builder.addCase(beneInternalRequiredApproval.pending, (state) => {
      state.beneInternalRequiredApprovalLoading = true;
    });
    builder.addCase(beneInternalRequiredApproval.fulfilled, (state, action) => {
      state.beneInternalRequiredApprovalLoading = false;
      state.beneInternalRequiredApprovalData = action.payload;
    });
    builder.addCase(beneInternalRequiredApproval.rejected, (state) => {
      state.beneInternalRequiredApprovalLoading = false;
      state.beneInternalRequiredApprovalError = true;
      state.beneInternalRequiredApprovalData = null;
    });

    builder.addCase(beneInternalWaitingApproved.pending, (state, action) => {
      state.beneInternalWaitingApprovalLoading = true;
    });
    builder.addCase(beneInternalWaitingApproved.fulfilled, (state, action) => {
      state.beneInternalWaitingApprovalLoading = false;
      state.beneInternalWaitingApprovalData = action.payload;
    });
    builder.addCase(beneInternalWaitingApproved.rejected, (state, action) => {
      state.beneInternalWaitingApprovalError = true;
      state.beneInternalWaitingApprovalData = null;
      state.beneInternalWaitingApprovalLoading = false;
    });
    builder.addCase(beneInternalDeniedApproval.pending, (state, action) => {
      state.beneInternalDeniedApprovalLoading = true;
    });

    builder.addCase(beneInternalDeniedApproval.fulfilled, (state, action) => {
      state.beneInternalDeniedApprovalLoading = false;
      state.beneInternalDeniedApprovalData = action.payload;
    });
    builder.addCase(beneInternalDeniedApproval.rejected, (state, action) => {
      state.beneInternalDeniedApprovalError = true;
      state.beneInternalDeniedApprovalData = null;
      state.beneInternalDeniedApprovalLoading = false;
    });
  },
});
export const { resetViewInternalAccountDetailData, resetInternalToggle } =
  internalSlice.actions;
export default internalSlice.reducer;
