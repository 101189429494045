import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import aprovaldenied from "../../../assets/RejectIcons/reject_icon_red_with_white_circle.svg";
import aprovalaccept from "../../../assets/CheckmarksIcons/approve_circle_green_checkmark.svg";
import xpentraloader from "../../../assets/Beneficiary Icons/lodemore.gif";
import NoAccountFound from "../../../assets/Graphics/No account remaining to approved.svg";
import ArrowLeft from "../../../assets/ArrowsIcons/arrow_right_blue_white.svg";
import FiterNoAccount from "../../../assets/Graphics/No transaction found.svg";
import ArrowRight from "../../../assets/ArrowsIcons/arrow_right_blue_filled.svg";
import ReloadWhite from "../../../assets/RefreshIcons/reload-refresh_white.svg";
import closeBtnImg from "../../../assets/General Icons/close.svg";
import WhiteArrowRight from "../../../assets/ArrowsIcons/arrow_right_white_filled.svg";
import BeneTxn_Queued from "../../../assets/AwaitingIcons/queued.svg";
import DeclinedRed from "../../../assets/CloseIcons/rejected_red.svg";
import Approvalgreen from "../../../assets/CheckmarksIcons/approved_green.svg";
import whiteLoader from "../../../assets/LoaderIcons/xpentra lodemore 1.svg";
import pendingImages from "../../../assets/PendingIcons/pending_blue.svg";
import dataLoading from "../../../assets/Beneficiary Icons/inputloder.gif";
import BeneTxnDataFormat from "../../../utils/BeneTxnDataFormat.js";
import { formatTime } from "../../../utils/formatTime.js";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { useDispatch, useSelector } from "react-redux";
import DeclinedPopup from "../../Accounts/BeneficiaryAccount/BeneAcountApproval/DeclinedPopup";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BeneTxnRequireApprovalPage,
  beneTxnAccountDetail,
  beneTxnWaitingApproval,
} from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { viewAccountDetail } from "../../../redux/features/verifyPAN/verifyPAN.js";
import {
  getOwnerAccBalance,
  setshowsuccestoast,
} from "../../../redux/features/owner/ownerAllVerification/ownerAllVerification.js";
import BalanceLoader from "../../../components/_utils/BalanceLoader/index.jsx";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import FormatAmount from "../../../components/_utils/FormatAmount/FormatAmount.js";
import SelectCategoryTransaction from "../../../components/_utils/SelectCategoryTransaction/index.jsx";
import { Box, tooltipClasses, Tooltip } from "@mui/material";
import GridBlue from "../../../assets/ViewIcons/Grid view blue.svg";
import GridWhite from "../../../assets/ViewIcons/Grid view white.svg";
import ListBlue from "../../../assets/ViewIcons/list_view_blue.svg";
import ListWhite from "../../../assets/ViewIcons/list_view_white.svg";
import arrow_up_blue from "../../../assets/ArrowsIcons/arrow_up_blue.svg";
import ArrowDown from "../../../assets/ArrowsIcons/arrow_down_white.svg";
import ArrowUp from "../../../assets/ArrowsIcons/arrow_up_white.svg";
import arrow_down_blue from "../../../assets/ArrowsIcons/arrow_down_blue.svg";
import FilterBlue from "../../../assets/FilterSearchIcons/filter_blue.svg";
import Filter from "../../../assets/FilterSearchIcons/filter.svg";
import SkipVerification from "../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";
import RefreshBalanceToast from "../../../components/_utils/RefreshBalanceToast/index.jsx";
import BeneTxnSideDrawer from "../../../components/SideDrawer/index.jsx";
import BeneTxnDetailPagePopup from "../../../components/BeneTransaction/BeneTxnDetailPopup/index.jsx";
import { checkUsertoken } from "../../../redux/features/login/login.js";
import IfscCodeMapper from "../../../components/_utils/IfscLogoMapper/index.js";
import AnimationUpAndDown from "../../../components/BeneTransaction/OwnerPopup/AnimationUpAndDown/index.jsx";
import BeneTxnFilter from "../../../components/BeneTransaction/BeneTxnFilter/index.jsx";
import LeftSign from "../../../assets/arrow_left_white.svg";
import WhiteGrid from "../../../assets/ViewIcons/Grid view white.svg";
import WhiteArrowRightFilled from "../../../assets/ArrowsIcons/arrow_right_white_filled.svg";
import ArrowDownCircle from "../../../assets/ArrowsIcons/arrow_down_blue_white.svg";
import CancelCircle from "../../../assets/CloseIcons/close_with_blue_circle.svg";
import { formatAccountNumber } from "../../../components/_utils/FormatAccountNumber/index.js";
import BeneAccDetailPagePopup from "../../../components/Accounts/BeneAccDetailPagePopup/index.jsx";
import Loader from "../../../components/Loader/Index.jsx";
const BeneTxnRequireApproval = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [deniedId, SetdeniedId] = useState(null);
  const [loading, setLoading] = useState({});
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const [BeneTxnDetailsId, SetBeneTxnDetailsId] = useState("");
  const [refreshId, SetrefreshId] = useState("");
  const [DrawerStatus, SetDrawerStatus] = useState("");
  const [refreshAnimation, setrefreshAnimation] = useState("");
  const [pageSize, setPageSize] = useState(
    window.innerWidth >= 3840 ? 192 : 50
  );
  const [AllBeneTxnAccounts, setAllBeneTxnAccounts] = useState([]);
  const [allBeneTxnAccountsLoading, setAllBeneTxnAccountsLoading] =
    useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterCat, setFilterCat] = useState(false);
  const [PrevBalance, setPrevBalance] = useState(null);
  const [initCategory, setInitCategory] = useState("");
  const [toggle, setToggle] = useState(true);
  const [label, setlabel] = useState("Select an option");
  const [showTxnDetailPop, setShowTxnDetailPop] = useState(false);
  const [txnId, setTxnId] = useState(null);
  const [currentCategory, setCurrentCategory] = useState("");
  const [sideDrawerDisabled, setSideDrawerDisabled] = useState(false);
  const [ShowFilterErrorPage, setShowFilterErrorPage] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const filterRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [drawerOpen, setDrawerOpen] = useState(null);

  const { ownerAccBalanceLoading, ownerAccBalanceError } = useSelector(
    (state) => state.ownerAllVerification
  );
  const navigate = useNavigate();
  const { BeneTxnRequireApprovalData, beneTxnAccountData } = useSelector(
    (state) => state.beneTxnViewAccData
  );
  const services = useSelector((state) => state.refreshToken.services);

  useEffect(() => {
    if (page === 1 && filterCat === false) {
      setAllBeneTxnAccountsLoading(true);
    }
    dispatch(checkUsertoken());
    if (label === "Select an option") {
      dispatch(
        BeneTxnRequireApprovalPage({
          page_no: page,
          category: label !== "Select an option" ? label : undefined,
        })
      )
        .then((response) => {
          setTotalPages(response?.payload?.title?.total_pages);
          setAllBeneTxnAccounts((prevAccounts) => {
            const newData =
              (response?.payload?.transactions &&
                response?.payload?.transactions?.map((account) => ({
                  ...account,
                  loading: false,
                  error: "false",
                  message: "",
                  last_updated_user_id_check: "false",
                  distribution_lifecycle_status: "",
                }))) ||
              [];
            return [...prevAccounts, ...newData];
          });
          setAllBeneTxnAccountsLoading(false);
        })
        .catch(() => {
          setAllBeneTxnAccountsLoading(false);
        });
    } else if (label !== "Select an option" && page !== 1) {
      dispatch(
        BeneTxnRequireApprovalPage({
          page_no: page,
          category: label !== "Select an option" ? label : undefined,
        })
      )
        .then((response) => {
          setTotalPages(response?.payload?.title?.total_pages);
          setAllBeneTxnAccounts((prevAccounts) => {
            const newData =
              (response?.payload?.transactions &&
                response?.payload?.transactions?.map((account) => ({
                  ...account,
                  loading: false,
                  error: "false",
                  message: "",
                  last_updated_user_id_check: "false",
                  distribution_lifecycle_status: "",
                }))) ||
              [];
            return [...prevAccounts, ...newData];
          });
          setAllBeneTxnAccountsLoading(false);
        })
        .catch(() => {
          setAllBeneTxnAccountsLoading(false);
        });
    }
  }, [page]);

  const handleOpenPopup = (id) => {
    setShowPopup(true);
    SetdeniedId(id);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const openBeneTxnDetailModal = () => {
    setShowTxnDetailPop(true);
  };

  const closeBeneTxnDetailModal = () => {
    setShowTxnDetailPop(false);
  };
  const closeBeneAccountDetailModal = () => {
    setisDrawerOpen(false);
  };

  useEffect(() => {
    if (ownerAccBalanceLoading) {
      RefreshBalanceToast();
    }
    if (!ownerAccBalanceLoading && !ownerAccBalanceError) {
      setTimeout(() => {
        toast.dismiss();
      }, 150);
    }
  }, [ownerAccBalanceLoading]);

  const handleMultiViewClick = () => {
    navigate("/transaction/Beneficiary_Transaction/multi_transaction");
  };

  const handleRefresh = async (e, id, prevBalance) => {
    if (services?.includes("VAccountBalance") || services?.includes("Admin")) {
      e.stopPropagation();
      setrefreshAnimation(id);
      setPrevBalance(prevBalance);
      try {
        dispatch(checkUsertoken());
        let response = await dispatch(getOwnerAccBalance(id));

        if (response?.payload?.status === true) {
          let data = AllBeneTxnAccounts?.map((item) => {
            if (
              response?.payload.owner_accounts[0].owner_account_id ===
              item.owner_account_id
              // &&
              // item.txn_id === txn_id
            ) {
              return {
                ...item,
                owner_account_balance_last_fetched_at_in_words:
                  response.payload.owner_accounts[0]
                    .owner_account_balance_last_fetched_at_in_words,
                owner_account_balance:
                  response.payload.owner_accounts[0].owner_account_balance,

                owner_account_balance_last_fetched_at:
                  response.payload.owner_accounts[0]
                    .owner_account_balance_last_fetched_at,
              };
            }
            return item;
          });
          setAllBeneTxnAccounts(data);
          setTimeout(() => {
            toast.success("Balance refreshed successfully", {
              position: "bottom-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            dispatch(setshowsuccestoast());
          }, 250);
        } else {
          setTimeout(() => {
            toast.dismiss();
            toast.error(response?.error.message, toast_position);
          }, 500);
        }
      } catch (error) {
        toast.error(error, toast_position);
        throw error;
      }
    } else {
      e.stopPropagation();
      navigate("/feature_not_assigned");
    }
  };

  const handleApprovalToastSuccess = () => {
    toast.success("Transaction approved successfully", {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    });
  };

  const SideDrawerOpen = async (id, txnId) => {
    SetBeneTxnDetailsId(id);
    setisDrawerOpen(!isDrawerOpen);
    await dispatch(beneTxnAccountDetail(txnId));
  };

  const updateAllAccounts = (updatedAccounts) => {
    setAllBeneTxnAccounts(updatedAccounts);
    // SetstatustxnApproval("TxnReQuiredApproval");
  };
  const viewTxnNavigate = (id) => {
    navigate(
      `/transaction/beneficiary_transaction/details/${id}`
      // , {
      // state: totalPages,
      // }
    );
  };
  const loginid = JSON.parse(localStorage.getItem("response"));

  const handleTxnApproval = async (id) => {
    setLoading((prevLoadingStates) => ({
      ...prevLoadingStates,
      [id]: true,
    }));

    const width = window.innerWidth;

    const imageStyle = {
      width: "40px",
      height: "40px",
      opacity: 1,
    };

    const paragraphStyle = {
      width: "160px",
      height: "19px",
      textAlign: "left",
      font: "normal normal normal 14px/19px Open Sans",
      letterSpacing: "0px",
      color: "#FFFFFF",
      opacity: 1,
    };
    const divStyle = {
      display: "flex",
      gap: "10px",
      justifyContent: width < 480 ? "left" : "center",
      alignItems: "center",
    };
    toast(
      <div style={divStyle}>
        <img src={whiteLoader} alt="" style={imageStyle} />
        <p style={paragraphStyle}>Approving transaction</p>
      </div>,
      {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        fontSize: "20px",
        transition: Bounce,
        style: {
          width: width < 480 ? `${width}px` : "310px",
          height: "50px",
          borderRadius: "10px",
          backgroundColor: "#AE9041",
          color: "#fff",
        },
      }
    );
    dispatch(checkUsertoken());
    const data = await dispatch(beneTxnWaitingApproval(id));
    console.log("data", data);
    let errorMessage = "";
    let lifecycleStatus = "";
    let distributionStatus = "";
    if (data?.payload?.status === 500) {
      let lifecycleStatus = "pending";
      setTimeout(() => {
        toast.error(data?.payload?.error, toast_position);
      }, 3000);
    }
    if (data?.payload?.errors?.user_unauthorized?.[0]) {
      setTimeout(() => {
        // toast.error(
        //   data?.payload?.errors?.user_unauthorized?.[0],
        //   toast_position
        // );
        navigate("/feature_not_assigned", {
          state: {
            unavailableService: "VBeneficiaryTxn",
          },
        });
      }, 2000);
    } else if (data.payload.status !== 404) {
      const updatedData = AllBeneTxnAccounts?.map((item) => {
        if (item.txn_id === id) {
          if (
            data.payload.status === true &&
            data.payload.transactions[0].lifecycle_status === "approved"
          ) {
            lifecycleStatus = data.payload.transactions[0].lifecycle_status;
            distributionStatus =
              data.payload.transactions[0].distribution_lifecycle_status;
          } else if (
            data?.payload?.errors?.base[0]?.errors &&
            data?.payload?.errors?.base[0]?.errors[0]?.reason
          ) {
            errorMessage = data.payload.errors.base[0].errors[0].reason[0];
            if (data?.payload?.errors?.base[0]?.errors[0]?.reason.length >= 1) {
              let msgs = data.payload.errors.base[0].errors[0].reason;
              msgs?.forEach((error) => {
                if (data?.payload?.status === false) {
                  setTimeout(() => {
                    toast.error(error, toast_position);
                  }, 3000);
                }
              });
            }
          } else if (
            data?.payload?.errors?.base[0]?.errors &&
            data?.payload?.errors?.base[0]?.errors[0]?.reason
          ) {
            errorMessage =
              data.payload.errors.base[0].errors[0].reason ||
              data.payload.errors.base[0].errors[0].reason[0];
            if (data?.payload?.errors?.base[0]?.errors.length > 1) {
              let msgs = data.payload.errors.base[0].errors[0].reason;

              msgs?.forEach((error) => {
                if (data?.payload?.status === false) {
                  setTimeout(() => {
                    toast.error(error, toast_position);
                  }, 3000);
                }
              });
            }
            if (data?.payload?.errors?.base[0]?.errors.length >= 1) {
              let msgs = data.payload.errors.base[0].errors[0].reason;
              errorMessage = msgs;
            }
          } else if (data?.payload?.errors?.base[0]) {
            errorMessage = data.payload.errors.base[0];
            setTimeout(() => {
              toast.error(errorMessage, toast_position);
            }, 3000);
          } else if (
            data.payload.status === true &&
            data.payload.transactions[0].lifecycle_status === "pending"
          ) {
            lifecycleStatus = "pendingOne";
          }

          return {
            ...item,
            last_updated_status:
              item.last_updated_status === "Created" &&
              data?.payload.status === true &&
              data.payload.transactions[0].last_updated_status === "Approved"
                ? "Approved"
                : "Created",

            lifecycle_status:
              data?.payload.status === true &&
              item.lifecycle_status === "pending"
                ? lifecycleStatus
                : "error",
            error:
              item.error === "false" && data?.payload.status === false
                ? "true"
                : "false",
            message:
              item.message === "" && data?.payload.status === false
                ? errorMessage
                : "",

            last_updated_user_id_check:
              item.last_updated_user_id_check === "false" &&
              data.payload.status === true &&
              loginid.employees.id ===
                data.payload.transactions[0].last_updated_user_id
                ? "true"
                : "false",
            distribution_lifecycle_status:
              item.distribution_lifecycle_status === "" &&
              data?.payload.status === true
                ? distributionStatus
                : "",
          };
        }

        return item;
      });
      setAllBeneTxnAccounts(updatedData);
    }

    setTimeout(() => {
      setLoading((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: false,
      }));
      toast.dismiss();
    }, 2000);

    setTimeout(() => {
      if (
        data?.payload?.status === true &&
        data.payload.transactions[0].lifecycle_status === "approved"
      ) {
        handleApprovalToastSuccess();
      }
      if (data.payload.status === 404) {
        toast.error(data.payload.error, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    }, 2000);
  };
  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleFilterCategory = () => {
    setCurrentCategory(label);
    setAllBeneTxnAccounts([]);
    if (page === 1) {
      setAllBeneTxnAccountsLoading(true);
    }
    dispatch(checkUsertoken());

    dispatch(
      BeneTxnRequireApprovalPage({
        page_no: page,
        category: label !== "Select an option" ? label : undefined,
      })
    )
      .then((response) => {
        const transactions = response?.payload?.transactions || [];

        const newData = transactions.map((account) => ({
          ...account,
          loading: false,
          error: "false",
          message: "",
          last_updated_user_id_check: "false",
          distribution_lifecycle_status: "",
        }));

        setAllBeneTxnAccounts(newData);
        setShowFilterErrorPage(true);
        setAllBeneTxnAccountsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setAllBeneTxnAccountsLoading(false);
      });

    setInitCategory(false);
    setFilterOpen(false);
    setFilterCat(false);
  };

  const handleClearCategory = () => {
    setlabel("Select an option");
    setAllBeneTxnAccountsLoading(true);
    setPage(1);
    setCurrentCategory("");
    setInitCategory("");
    if (label !== "Select an option") {
      dispatch(
        BeneTxnRequireApprovalPage({
          page_no: page,
          category: undefined,
        })
      )
        .then((response) => {
          const transactions = response?.payload?.transactions || [];

          const newData = transactions.map((account) => ({
            ...account,
            loading: false,
            error: "false",
            message: "",
            last_updated_user_id_check: "false",
            distribution_lifecycle_status: "",
          }));

          setAllBeneTxnAccounts(newData);
          setAllBeneTxnAccountsLoading(false);
          setShowFilterErrorPage(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setAllBeneTxnAccountsLoading(false);
        });
    }
  };

  useEffect(() => {
    const handlefilter = (e) => {
      if (filterRef.current && filterRef.current.contains(e.target)) {
        setFilterOpen(false);
      }
    };
    document.addEventListener("click", handlefilter);
  }, [filterRef]);

  const getMarginTop = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (width === 2560 && height === 1440) {
      return "125px";
    } else if (width === 3840 && height === 1920) {
      return "120px";
    } else if (width === 1440 && height === 960) {
      return "150px";
    } else if (width === 1920 && height === 1080) {
      return "140px";
    } else {
      return "5.2rem";
    }
  };

  const style = {
    opacity: filterOpen ? 0.8 : 1,
    background: filterOpen ? "#000" : "",
    marginTop: currentCategory ? getMarginTop() : "",
    overflow: filterOpen ? "hidden" : "",
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (showPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showPopup]);

  return (
    <>
      {showPopup && (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#000] bg-opacity-80">
          <DeclinedPopup
            onClose={handleClosePopup}
            accountId={deniedId}
            allAccounts={AllBeneTxnAccounts}
            updateAllAccounts={updateAllAccounts}
            status={"Transaction"}
            isMobile={isMobile}
          />
        </div>
      )}
      {!isMobile ? (
        <div
          id="RequireApprovalPageTxn"
          className="w-[100%] h-screen overflow-hidden"
          style={{
            background: filterOpen ? "#000" : "",
          }}
        >
          {showTxnDetailPop && (
            <div
              className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80"
              onClick={() => {
                closeBeneTxnDetailModal();
              }}
            >
              <BeneTxnDetailPagePopup
                closeBeneTxnDetailModal={closeBeneTxnDetailModal}
                bene_txn_id={txnId}
                type={"bene_txn_detail_modal"}
              />
            </div>
          )}
          <div className="w-full h-[208px]">
            <div className="w-full bg-gradient-to-r from-[#3a5686] to-[#3a5686] relative z-50 opacity-100 h-[208px]">
              <div className="w-[1024px] flex flex-row-reverse items-end mx-auto h-[208px]">
                <div className="flex">
                  <div className="w-[872px] h-[103px] mt-[30px]">
                    <div className="w-[802px] h-[55px] mt-[15px]">
                      <p className="w-[258px] h-[19px] text-left mt-5 text-[14px] font-normal leading-normal text-white opacity-100">
                        Post Approval Process
                      </p>
                      <p className="text_12_normal mt-2 text-Primary_grey opacity-100">
                        Once approved, transaction distribution will get created
                        and auto settled using selected source account. If there
                        are insufficient funds, the transaction will settle once
                        the source account is refilled, and distribution will be
                        initiated thereafter.
                      </p>
                    </div>
                  </div>
                  <div className="flex h-[103px] pt-[40px]">
                    <div className="filter_div_container">
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title="Filters"
                      >
                        <div
                          style={{
                            backgroundColor:
                              currentCategory !== "" ? "#F9F9F9" : "#1d3a6d ",
                            opacity:
                              ShowFilterErrorPage ||
                              AllBeneTxnAccounts.length > 0
                                ? "1"
                                : "0.5",
                            cursor:
                              ShowFilterErrorPage ||
                              AllBeneTxnAccounts.length > 0
                                ? "pointer"
                                : "auto",
                          }}
                          className={`filter-mainBene ${
                            AllBeneTxnAccounts.length === 0
                              ? "bg-red"
                              : "bg-[#1d3a6d]"
                          }`}
                          onClick={() => {
                            // toast.info("Feature coming soon..", toast_position);
                            if (
                              ShowFilterErrorPage &&
                              AllBeneTxnAccounts.length === 0
                            ) {
                              setFilterOpen(!filterOpen);
                              setInitCategory(false);
                            } else if (AllBeneTxnAccounts.length === 0) {
                              setFilterOpen(filterOpen);
                              setInitCategory(false);
                            } else if (
                              AllBeneTxnAccounts.length === 0 ||
                              AllBeneTxnAccounts.length > 0
                            ) {
                              setFilterOpen(!filterOpen);
                              setInitCategory(false);
                            }
                          }}
                        >
                          <Box className="filters-box">
                            <img
                              src={currentCategory !== "" ? FilterBlue : Filter}
                              alt=""
                              className="filter-images-elementMain"
                            />
                          </Box>
                          <Box className="filter-box">
                            <img
                              src={
                                filterOpen
                                  ? currentCategory !== ""
                                    ? arrow_up_blue
                                    : ArrowUp
                                  : currentCategory !== ""
                                  ? arrow_down_blue
                                  : ArrowDown
                              }
                              alt=""
                              className="filter-images-elementMain"
                            />
                          </Box>
                        </div>
                      </Tooltip>
                    </div>
                    <div className="Single_toggle-containers">
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title="Single Approval"
                      >
                        <Box
                          onClick={() => {
                            setToggle(true);
                          }}
                          sx={{
                            backgroundColor: toggle ? "#F9F9F9" : "",
                          }}
                          className="toggle-box_Txn"
                        >
                          <img
                            src={toggle ? ListBlue : ListWhite}
                            alt=""
                            className="toggle-images"
                          />
                        </Box>
                      </Tooltip>
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title="Multi Approval"
                      >
                        <Box
                          onClick={() => {
                            handleMultiViewClick();
                            setToggle(false);
                          }}
                          sx={{
                            backgroundColor: toggle ? "" : "#F9F9F9",
                          }}
                          className={`toggle-box_Txn`}
                        >
                          <img
                            src={toggle ? GridWhite : GridBlue}
                            alt=""
                            className="toggle-images"
                          />
                        </Box>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    className={`filterdropdown-transition-${
                      filterOpen ? "open" : "close"
                    }`}
                  >
                    {filterOpen && (
                      <div className="filterdropdown-container-div">
                        <div className="filterdropdown-div">
                          <div
                            style={{
                              display: "flex",
                              width: "45%",
                            }}
                          >
                            <SelectCategoryTransaction
                              data={AllBeneTxnAccounts}
                              label={label}
                              setlabel={setlabel}
                              initCategory={initCategory}
                              setFilterOpen={setFilterOpen}
                              sendCategory={handleFilterCategory}
                              filterOpen={filterOpen}
                              setInitCategory={setInitCategory}
                              transaction={true}
                              setPage={setPage}
                              setFilterCat={setFilterCat}
                              filteredType={"singleApproveType"}
                            />
                          </div>

                          <div className="button_container_div">
                            <div
                              style={{
                                cursor:
                                  currentCategory !== ""
                                    ? "pointer"
                                    : "default",
                                hover:
                                  currentCategory !== ""
                                    ? "pointer"
                                    : "default",
                              }}
                              onClick={() => {
                                if (label !== "Select an option") {
                                  handleClearCategory();
                                }
                              }}
                              className="clear hover:bg-[#506994]"
                            >
                              <p className="buttoncontainer-text">Clear</p>
                            </div>
                            <div
                              onClick={() => {
                                if (label !== "Select an option") {
                                  handleFilterCategory();
                                }
                              }}
                              className="apply hover:opacity-80"
                              style={{
                                backgroundColor:
                                  label === "Select an option"
                                    ? "#707070"
                                    : "#FB5963",
                                cursor:
                                  label === "Select an option"
                                    ? "default"
                                    : "pointer",
                                hover:
                                  currentCategory !== ""
                                    ? "pointer"
                                    : "default",
                              }}
                            >
                              <p className="buttoncontainer-text">Apply</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div ref={filterRef} id="txnViewallScrollContainerBar" style={style}>
            {currentCategory && (
              <div className="bene_txn_MultiApproval_FilterBar">
                <div className="bene_txn_MultiApprovalfilterdropdown">
                  <div
                    style={{
                      display: "flex",
                      // width: "45%",
                    }}
                  >
                    <div className="bene_txn_MultiApp_filter_div_leftCat">
                      <p className="pr-2 w-[87px]">Filtered by : </p>
                      {currentCategory !== "" && (
                        <div className="bene_txn_MultiApp_filter_body px-1 pt-1">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={currentCategory}
                          >
                            <p className="px-2 text-xs truncate w-[100px] h-[19px]">
                              {currentCategory}
                            </p>
                          </Tooltip>
                          <div
                            className="bene_txn_MultiApp_close_btnCat"
                            onClick={handleClearCategory}
                          >
                            {" "}
                            <img
                              src={closeBtnImg}
                              alt="close-btn"
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {allBeneTxnAccountsLoading ? (
              <div className="loader-container m-auto justify-center items-center flex mt-20">
                <Loader />
              </div>
            ) : BeneTxnRequireApprovalData === undefined ||
              AllBeneTxnAccounts.length === 0 ? (
              <div
                className=" w-full"
                style={{
                  overflow: "hidden",
                }}
                ref={filterRef}
              >
                <div
                  ref={filterRef}
                  className="BeneAccount_NoAccountFound "
                  style={{
                    opacity: filterOpen ? 0.2 : 1,
                  }}
                >
                  <div className="BeneAccount_RightTextContentNoAccount">
                    <img
                      src={
                        ShowFilterErrorPage && AllBeneTxnAccounts.length === 0
                          ? FiterNoAccount
                          : NoAccountFound
                      }
                      alt=""
                    />
                  </div>
                  <div className="BeneAccount_LeftTextContentNoAccount">
                    {ShowFilterErrorPage && AllBeneTxnAccounts.length === 0 ? (
                      <span className="">
                        No data found for the selected filter.
                      </span>
                    ) : (
                      <>
                        <p className="w-[426px]">
                          There are no more transactions that require your
                          approval.
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : AllBeneTxnAccounts?.length > 0 ? (
              <>
                <div
                  ref={filterRef}
                  className={`w-[100%]  ${
                    filterOpen && "h-screen  bg-[#000] opacity-80 z-[9998] "
                  }`}
                >
                  <div
                    className={`Benetxn_SubAccountApproval w-[1024px] m-auto`}
                    ref={filterRef}
                    style={{
                      height: filterOpen ? "100%" : "",
                      overflow: filterOpen ? "hidden" : "auto",
                    }}
                  >
                    {isDrawerOpen && (
                      <div
                        className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80"
                        onClick={() => {
                          closeBeneAccountDetailModal();
                        }}
                      >
                        <BeneAccDetailPagePopup
                          closeBeneAccountDetailModal={
                            closeBeneAccountDetailModal
                          }
                          bene_account_id={BeneTxnDetailsId}
                          type={"bene_account_detail_modal"}
                        />
                      </div>
                    )}
                    {/* <BeneTxnSideDrawer
                  isOpen={isDrawerOpen}
                  onClose={SideDrawerOpen}
                  txnRequiredStatus={"BeneTxnRequiredApproval"}
                  setIsSidebarOpen={setisDrawerOpen}
                  BeneTxnDetailsPage={BeneTxnDetailsId}
                  BeneTxnDetailsFunc={SetBeneTxnDetailsId}
                  AllBeneTxnData={setAllBeneTxnAccounts}
                  DrawerOpenStatus={DrawerStatus}
                  disabled={sideDrawerDisabled}
                  setDisabled={setSideDrawerDisabled} */}
                    {/* /> */}
                    <div
                      className={`scroll flex flex-col items-center justify-center w-full`}
                    >
                      <InfiniteScroll
                        style={{ width: "inherit" }}
                        dataLength={AllBeneTxnAccounts.length || []}
                        next={fetchMoreData}
                        hasMore={
                          page <
                          Math.ceil(
                            BeneTxnRequireApprovalData?.total_count / pageSize
                          )
                        }
                        loader={
                          <div className="loader-container m-auto justify-center items-center flex">
                            <Loader />
                          </div>
                        }
                        scrollableTarget="txnViewallScrollContainerBar"
                        scrollThreshold={0.3}
                      >
                        {AllBeneTxnAccounts?.map((account) => (
                          <>
                            <div
                              className={`BeneTxn_ApprovedAcoutDetailsPage flex w-[1024px] h-[220px] rounded-[30px] ${
                                filterOpen && "bg-[#000] opacity-10 z-[9998]"
                              }`}
                              key={account.txn_id}
                            >
                              {(account.lifecycle_status === "pending" ||
                                loading[account.txn_id]) &&
                                account.beneficiary_account_approval_status ===
                                  "approved" && (
                                  <div className="BeneTxn_ApprovalAcdivFirst   bg-[#506994]">
                                    <div className="flex  items-center rounded-tl-[30px] justify-between bg-[#314D7E] text-white h-[80px] ">
                                      <div className="flex  flex-col gap-1 ml-[20px]">
                                        <p className="w-[140px] h-[22px]  font-semibold text-[15px] leading-22 text-[#4DDD37] opacity-100">
                                          <span
                                            style={{
                                              display: "inline-block",
                                              fontSize: "15px",
                                              paddingRight: "5px",
                                            }}
                                          >
                                            ₹
                                          </span>
                                          {
                                            <FormatAmount
                                              price={account.amount}
                                            />
                                          }
                                        </p>
                                        <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#DADADA] opacity-100">
                                          Transaction amount
                                        </span>
                                      </div>
                                      <div className="ml-[40px] flex flex-col gap-1">
                                        {account?.category &&
                                        account?.category.length >= 10 ? (
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={account?.category}
                                          >
                                            <p className="w-[94px]  text-right  overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                              {account?.category}
                                            </p>
                                          </Tooltip>
                                        ) : (
                                          <p className="w-[90px] text-right  overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                            {account?.category}
                                          </p>
                                        )}
                                        <span className="w-[99px] h-[17px] font-normal text-[11px] leading-17 tracking-normal text-[#DADADA] opacity-100">
                                          Category selected
                                        </span>
                                      </div>

                                      <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                    </div>

                                    <div className="bg-[#506994]   h-[140px] rounded-bl-[30px]">
                                      <div className="flex justify-between w-full h-[40px] pt-[20px] ">
                                        <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px]">
                                          <p className="w-[139px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                            {formatAccountNumber(
                                              account?.owner_account_bank_acc_no
                                            )}
                                          </p>
                                          <span className="w-[96px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#DADADA] opacity-100">
                                            Source account
                                          </span>
                                        </div>

                                        <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                          <img
                                            className="w-[79px] h-[20px] relative top-[8px] left-[11px]"
                                            src={require("../../../assets/IfscLogo/" +
                                              IfscCodeMapper(
                                                account?.owner_account_ifsc_code
                                              ))}
                                            alt="bank"
                                          />
                                        </div>
                                        <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                      </div>

                                      <div className="w-[341px] h-[20px] ml-2 mt-5 flex justify-end  items-end  relative z-20 ">
                                        <img
                                          className="w-[20px] h-[20px] "
                                          src={ArrowLeft}
                                          alt=""
                                        />
                                      </div>

                                      <div className=" ml-[20px] flex justify-between">
                                        <div className="">
                                          <div className="flex items-center ">
                                            <div className="flex gap-1 ">
                                              <div className="">
                                                <h4
                                                  style={{
                                                    color:
                                                      Number(
                                                        account?.owner_account_balance
                                                      ) >=
                                                      Number(account.amount)
                                                        ? "#4DDD37"
                                                        : "#FF5860",
                                                  }}
                                                >
                                                  {" "}
                                                  ₹{" "}
                                                  {
                                                    <FormatAmount
                                                      price={
                                                        account?.owner_account_balance
                                                      }
                                                    />
                                                  }
                                                </h4>
                                              </div>
                                              <div className=" relative">
                                                <p className="bg-white">
                                                  <AnimationUpAndDown
                                                    loader={
                                                      ownerAccBalanceLoading
                                                    }
                                                    currentId={
                                                      account?.owner_account_id
                                                    }
                                                    currentBalance={
                                                      account?.owner_account_balance
                                                    }
                                                    previousId={
                                                      refreshAnimation
                                                    }
                                                    previousBalance={
                                                      PrevBalance
                                                    }
                                                    refreshType={"single"}
                                                    classType={
                                                      "RequiredApproval"
                                                    }
                                                  />
                                                </p>
                                              </div>
                                              <div className=" ml-3">
                                                <p className="h-[26px] text-[#DADADA]">
                                                  {" "}
                                                  {"  "} |
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              onClick={(e) => {
                                                if (!filterOpen) {
                                                  SetrefreshId(account.txn_id);
                                                  handleRefresh(
                                                    e,
                                                    account.owner_account_id,
                                                    account?.owner_account_balance
                                                  );
                                                }
                                              }}
                                              className={`flex items-center  ml-[10px] gap-[5px] ${
                                                filterOpen
                                                  ? "cursor-default"
                                                  : services?.includes(
                                                      "VAccountBalance"
                                                    ) ||
                                                    services?.includes("Admin")
                                                  ? "cursor-pointer"
                                                  : "cursor-not-allowed opacity-50"
                                              }`}
                                            >
                                              {ownerAccBalanceLoading &&
                                              refreshId === account.txn_id ? (
                                                <BalanceLoader
                                                  loader={
                                                    ownerAccBalanceLoading
                                                  }
                                                  type={"createTxn"}
                                                />
                                              ) : !ownerAccBalanceLoading &&
                                                refreshId === account.txn_id ? (
                                                <BalanceLoader
                                                  loader={
                                                    ownerAccBalanceLoading
                                                  }
                                                  setAccId={SetrefreshId}
                                                  type={"createTxn"}
                                                />
                                              ) : (
                                                <Tooltip
                                                  componentsProps={{
                                                    popper: {
                                                      sx: {
                                                        [`& .${tooltipClasses.tooltip}`]:
                                                          {
                                                            backgroundColor: (
                                                              theme
                                                            ) =>
                                                              theme.palette
                                                                .common.black,
                                                          },
                                                      },
                                                    },
                                                  }}
                                                  title={"Refresh Balance"}
                                                >
                                                  <div className="flex gap-1">
                                                    <img
                                                      className="w-[12px] h-[12px] opacity-0.7"
                                                      src={ReloadWhite}
                                                      alt=""
                                                    />

                                                    <span className="w-[37px] h-[14px] text-left font-semibold text-[10px] leading-26 tracking-normal text-[#DADADA] opacity-100">
                                                      Refresh
                                                    </span>
                                                  </div>
                                                </Tooltip>
                                              )}
                                            </div>
                                          </div>

                                          <div className="w-[270px]  h-[17px] text-left font-normal text-[12px]  tracking-normal text-[#DADADA] opacity-100">
                                            Source account balance -&nbsp;
                                            {refreshId === account.txn_id
                                              ? account?.owner_account_balance_last_fetched_at_in_words
                                              : account?.owner_account_balance_last_fetched_at_in_words}
                                          </div>
                                        </div>
                                        <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] mt-4 opacity-[0.6]"></div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              {account.lifecycle_status === "rejected" &&
                                !loading[account.txn_id] && (
                                  <div className="BeneTxn_ApprovalAcdivFirst   bg-[#506994]">
                                    <div className="flex  items-center rounded-tl-[30px] justify-between bg-[#314D7E] text-white h-[80px] ">
                                      <div className="flex  flex-col gap-1 ml-[20px]">
                                        <p className="w-[140px] h-[22px]  font-semibold text-[15px] leading-22 text-[#4DDD37] opacity-100">
                                          <span
                                            style={{
                                              display: "inline-block",
                                              fontSize: "15px",
                                              paddingRight: "5px",
                                            }}
                                          >
                                            ₹
                                          </span>
                                          {
                                            <FormatAmount
                                              price={account.amount}
                                            />
                                          }
                                        </p>
                                        <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#DADADA] opacity-100">
                                          Transaction amount
                                        </span>
                                      </div>
                                      <div className="ml-[40px] flex flex-col gap-1">
                                        {account?.category &&
                                        account?.category.length >= 10 ? (
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={account?.category}
                                          >
                                            <p className="w-[94px] text-right overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                              {account?.category}
                                            </p>
                                          </Tooltip>
                                        ) : (
                                          <p className="w-[90px]  text-right overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                            {account?.category}
                                          </p>
                                        )}
                                        <span className="w-[99px] h-[17px] font-normal text-[11px] leading-17 tracking-normal text-[#DADADA] opacity-100">
                                          Category selected
                                        </span>
                                      </div>

                                      <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                    </div>

                                    <div className="bg-[#506994]   h-[140px] rounded-bl-[30px]">
                                      <div className="flex justify-between w-full h-[40px] pt-[20px] ">
                                        <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px]">
                                          <p className="w-[139px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                            {formatAccountNumber(
                                              account?.owner_account_bank_acc_no
                                            )}
                                          </p>
                                          <span className="w-[96px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#DADADA] opacity-100">
                                            Source account
                                          </span>
                                        </div>

                                        <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                          <img
                                            className="w-[79px] h-[20px] relative top-[8px] left-[11px]"
                                            src={require("../../../assets/IfscLogo/" +
                                              IfscCodeMapper(
                                                account?.owner_account_ifsc_code
                                              ))}
                                            alt="bank"
                                          />
                                        </div>
                                        <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                      </div>

                                      <div className="w-[341px] h-[20px] ml-2 mt-5 flex justify-end  items-end  relative z-20 "></div>

                                      <div className=" ml-[20px] flex justify-between">
                                        <div className="">
                                          <div className="flex items-center ">
                                            <div className="flex gap-1">
                                              <div>
                                                <h4
                                                  style={{
                                                    color:
                                                      Number(
                                                        account?.owner_account_balance
                                                      ) >=
                                                      Number(account.amount)
                                                        ? "#4DDD37"
                                                        : "#FF5860",
                                                  }}
                                                >
                                                  {" "}
                                                  ₹{" "}
                                                  {
                                                    <FormatAmount
                                                      price={
                                                        account?.owner_account_balance
                                                      }
                                                    />
                                                  }
                                                </h4>
                                              </div>
                                              <div className=" relative">
                                                <p className="">
                                                  <AnimationUpAndDown
                                                    loader={
                                                      ownerAccBalanceLoading
                                                    }
                                                    currentId={
                                                      account?.owner_account_id
                                                    }
                                                    currentBalance={
                                                      account?.owner_account_balance
                                                    }
                                                    previousId={
                                                      refreshAnimation
                                                    }
                                                    previousBalance={
                                                      PrevBalance
                                                    }
                                                    refreshType={"single"}
                                                    classType={
                                                      "RequiredApproval"
                                                    }
                                                  />
                                                </p>
                                              </div>
                                              <div className=" ml-3">
                                                <p className="h-[26px] text-[#DADADA]">
                                                  {" "}
                                                  {"  "} |
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              onClick={(e) => {
                                                if (!filterOpen) {
                                                  SetrefreshId(account.txn_id);
                                                  handleRefresh(
                                                    e,
                                                    account.owner_account_id,
                                                    account?.owner_account_balance
                                                  );
                                                }
                                              }}
                                              className={`flex items-center   ml-[10px] gap-[5px] ${
                                                filterOpen
                                                  ? "cursor-default"
                                                  : services?.includes(
                                                      "VAccountBalance"
                                                    ) ||
                                                    services?.includes("Admin")
                                                  ? "cursor-pointer"
                                                  : "cursor-not-allowed opacity-50"
                                              }`}
                                            >
                                              {ownerAccBalanceLoading &&
                                              refreshId === account.txn_id ? (
                                                <BalanceLoader
                                                  loader={
                                                    ownerAccBalanceLoading
                                                  }
                                                  type={"createTxn"}
                                                />
                                              ) : !ownerAccBalanceLoading &&
                                                refreshId === account.txn_id ? (
                                                <BalanceLoader
                                                  loader={
                                                    ownerAccBalanceLoading
                                                  }
                                                  setAccId={SetrefreshId}
                                                  type={"createTxn"}
                                                />
                                              ) : (
                                                <Tooltip
                                                  componentsProps={{
                                                    popper: {
                                                      sx: {
                                                        [`& .${tooltipClasses.tooltip}`]:
                                                          {
                                                            backgroundColor: (
                                                              theme
                                                            ) =>
                                                              theme.palette
                                                                .common.black,
                                                          },
                                                      },
                                                    },
                                                  }}
                                                  title={"Refresh Balance"}
                                                >
                                                  <div className="flex gap-1">
                                                    <img
                                                      className="w-[12px] h-[12px] opacity-0.7"
                                                      src={ReloadWhite}
                                                      alt=""
                                                    />

                                                    <span className="w-[37px] h-[14px] text-left font-semibold text-[10px] leading-26 tracking-normal text-[#DADADA] opacity-100">
                                                      Refresh
                                                    </span>
                                                  </div>
                                                </Tooltip>
                                              )}
                                            </div>
                                          </div>

                                          <div className="w-[270px]  h-[17px] text-left font-normal text-[12px]  tracking-normal text-[#DADADA] opacity-100">
                                            Source account balance -&nbsp;
                                            {refreshId === account.txn_id
                                              ? account?.owner_account_balance_last_fetched_at_in_words
                                              : account?.owner_account_balance_last_fetched_at_in_words}
                                          </div>
                                        </div>
                                        <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] mt-4 opacity-[0.6]"></div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {(account.lifecycle_status === "pending" ||
                                loading[account.txn_id]) &&
                                account.beneficiary_account_approval_status ===
                                  "pending" && (
                                  <div className="BeneTxn_ApprovalAcdivFirst   z-20 bg-black">
                                    <div className="flex  items-center rounded-tl-[30px] justify-between bg-[#314D7E] text-white h-[80px]  opacity-[0.4]">
                                      <div className="flex  flex-col gap-1 ml-[20px]">
                                        <p className="w-[140px] h-[22px]  font-semibold text-[15px] leading-22 text-[#4DDD37] opacity-100 hover:cursor-default">
                                          <span
                                            style={{
                                              display: "inline-block",
                                              fontSize: "15px",
                                              paddingRight: "5px",
                                            }}
                                          >
                                            ₹
                                          </span>
                                          {
                                            <FormatAmount
                                              price={account.amount}
                                            />
                                          }
                                        </p>
                                        <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#DADADA] opacity-100">
                                          Transaction amount
                                        </span>
                                      </div>
                                      <div className="ml-[40px] flex flex-col gap-1">
                                        {account?.category &&
                                        account?.category.length >= 10 ? (
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={account?.category}
                                          >
                                            <p className="w-[94px] text-right overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#F9F9F9] opacity-100 hover:cursor-default">
                                              {account?.category}
                                            </p>
                                          </Tooltip>
                                        ) : (
                                          <p className="w-[90px] text-right h-[19px] whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                            {account?.category}
                                          </p>
                                        )}
                                        <span className="w-[99px] h-[17px]  font-normal text-[11px] leading-17 tracking-normal text-[#DADADA] opacity-100">
                                          Category selected
                                        </span>
                                      </div>
                                      <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                    </div>

                                    <div className="bg-[#506994]   h-[140px] rounded-bl-[30px] opacity-[0.4] border-r border-[#1D3A6D]">
                                      <div className="flex justify-between w-full h-[40px] pt-[20px] ">
                                        <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px]">
                                          <p className="w-[139px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                            {formatAccountNumber(
                                              account?.owner_account_bank_acc_no
                                            )}
                                          </p>
                                          <span className="w-[96px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#DADADA] opacity-100">
                                            Source account
                                          </span>
                                        </div>

                                        <div className="ml-[40px] w-[104px] mr-[20px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                          <img
                                            className="w-[79px] h-[20px] relative top-[8px] left-[11px]"
                                            src={require("../../../assets/IfscLogo/" +
                                              IfscCodeMapper(
                                                account?.owner_account_ifsc_code
                                              ))}
                                            alt="bank"
                                          />
                                        </div>
                                        {/* <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] opacity-[0.6]"></div> */}
                                      </div>

                                      <div className="w-[341px] h-[20px] ml-2 mt-5 flex justify-end  items-end  relative z-20 "></div>

                                      <div className=" ml-[20px] flex justify-between">
                                        <div>
                                          <div className="flex items-center">
                                            <div className="flex gap-1">
                                              <div>
                                                <h4
                                                  style={{
                                                    color:
                                                      Number(
                                                        account?.owner_account_balance
                                                      ) >=
                                                      Number(account.amount)
                                                        ? "#4DDD37"
                                                        : "#FF5860",
                                                  }}
                                                >
                                                  {" "}
                                                  ₹{" "}
                                                  {
                                                    <FormatAmount
                                                      price={
                                                        account?.owner_account_balance
                                                      }
                                                    />
                                                  }
                                                </h4>
                                              </div>
                                              <div className=" relative">
                                                <p className="">
                                                  <AnimationUpAndDown
                                                    loader={
                                                      ownerAccBalanceLoading
                                                    }
                                                    currentId={
                                                      account?.owner_account_id
                                                    }
                                                    currentBalance={
                                                      account?.owner_account_balance
                                                    }
                                                    previousId={
                                                      refreshAnimation
                                                    }
                                                    previousBalance={
                                                      PrevBalance
                                                    }
                                                    refreshType={"single"}
                                                    classType={
                                                      "RequiredApproval"
                                                    }
                                                  />
                                                </p>
                                              </div>
                                              <div className=" ml-3">
                                                <p className="h-[26px] text-[#DADADA]">
                                                  {" "}
                                                  {"  "} |
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              onClick={(e) => {
                                                if (
                                                  account.beneficiary_account_approval_status !==
                                                  "pending"
                                                ) {
                                                  SetrefreshId(account.txn_id);
                                                  handleRefresh(
                                                    e,
                                                    account.owner_account_id,
                                                    account?.owner_account_balance
                                                  );
                                                }
                                              }}
                                              className={`flex items-cente ml-[10px] gap-[5px] ${
                                                filterOpen
                                                  ? "cursor-default"
                                                  : " cursor-pointer"
                                              }`}
                                            >
                                              {ownerAccBalanceLoading &&
                                              refreshId === account.txn_id ? (
                                                <BalanceLoader
                                                  loader={
                                                    ownerAccBalanceLoading
                                                  }
                                                  type={"createTxn"}
                                                />
                                              ) : !ownerAccBalanceLoading &&
                                                refreshId === account.txn_id ? (
                                                <BalanceLoader
                                                  loader={
                                                    ownerAccBalanceLoading
                                                  }
                                                  setAccId={SetrefreshId}
                                                  type={"createTxn"}
                                                />
                                              ) : (
                                                <div className="flex gap-1 cursor-default">
                                                  <img
                                                    className="w-[12px] h-[12px] opacity-0.7"
                                                    src={ReloadWhite}
                                                    alt=""
                                                  />

                                                  <span className="w-[37px] h-[14px]  text-left font-semibold text-[10px] leading-26 tracking-normal text-[#DADADA] opacity-100">
                                                    Refresh
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          </div>

                                          <div className="w-[270px]  h-[17px] text-left font-normal text-[12px]  tracking-normal text-[#DADADA] opacity-100">
                                            Source account balance -&nbsp;
                                            {refreshId === account.txn_id
                                              ? account?.owner_account_balance_last_fetched_at_in_words
                                              : account?.owner_account_balance_last_fetched_at_in_words}
                                          </div>
                                        </div>
                                        {/* <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] mt-4 opacity-[0.6]"></div> */}
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {account.lifecycle_status === "approved" &&
                                !loading[account.txn_id] && (
                                  <div className="BeneTxn_ApprovalAcdivFirst   bg-[#506994]">
                                    <div className="flex  items-center rounded-tl-[30px] justify-between bg-[#314D7E] text-white h-[80px] ">
                                      <div className="flex  flex-col gap-1 ml-[20px]">
                                        <p className="w-[140px] h-[22px]  font-semibold text-[15px] leading-22 text-[#4DDD37] opacity-100">
                                          <span
                                            style={{
                                              display: "inline-block",
                                              fontSize: "15px",
                                              paddingRight: "5px",
                                            }}
                                          >
                                            ₹
                                          </span>
                                          {
                                            <FormatAmount
                                              price={account.amount}
                                            />
                                          }
                                        </p>
                                        <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#DADADA] opacity-100">
                                          Transaction amount
                                        </span>
                                      </div>
                                      <div className="ml-[40px] flex flex-col gap-1">
                                        {account?.category &&
                                        account?.category.length >= 10 ? (
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={account?.category}
                                          >
                                            <p className="w-[94px] text-right overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                              {account?.category}
                                            </p>
                                          </Tooltip>
                                        ) : (
                                          <p className="[w-[90px]  text-right overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                            {account?.category}
                                          </p>
                                        )}
                                        <span className="w-[99px] h-[17px] font-normal text-[11px] leading-17 tracking-normal text-[#DADADA] opacity-100">
                                          Category selected
                                        </span>
                                      </div>

                                      <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                    </div>

                                    <div className="bg-[#506994]   h-[140px] rounded-bl-[30px]">
                                      <div className="flex justify-between w-full h-[40px] pt-[20px] ">
                                        <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px]">
                                          <p className="w-[139px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                            {formatAccountNumber(
                                              account?.owner_account_bank_acc_no
                                            )}
                                          </p>
                                          <span className="w-[96px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#DADADA] opacity-100">
                                            Source account
                                          </span>
                                        </div>

                                        <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                          <img
                                            className="w-[79px] h-[20px] relative top-[8px] left-[11px]"
                                            src={require("../../../assets/IfscLogo/" +
                                              IfscCodeMapper(
                                                account?.owner_account_ifsc_code
                                              ))}
                                            alt="bank"
                                          />
                                        </div>
                                        <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                      </div>

                                      <div className="w-[341px] h-[20px] ml-2 mt-5 flex justify-end  items-end  relative z-20 ">
                                        <img
                                          className="w-[20px] h-[20px] "
                                          src={ArrowLeft}
                                          alt=""
                                        />
                                      </div>

                                      <div className=" ml-[20px] flex justify-between">
                                        <div className="">
                                          <div className="flex items-center ">
                                            <div className="flex gap-1">
                                              <div>
                                                <h4
                                                  style={{
                                                    color:
                                                      Number(
                                                        account?.owner_account_balance
                                                      ) >=
                                                      Number(account.amount)
                                                        ? "#4DDD37"
                                                        : "#FF5860",
                                                  }}
                                                >
                                                  {" "}
                                                  ₹{" "}
                                                  {
                                                    <FormatAmount
                                                      price={
                                                        account?.owner_account_balance
                                                      }
                                                    />
                                                  }
                                                </h4>
                                              </div>
                                              <div className=" relative">
                                                <p className="">
                                                  <AnimationUpAndDown
                                                    loader={
                                                      ownerAccBalanceLoading
                                                    }
                                                    currentId={
                                                      account?.owner_account_id
                                                    }
                                                    currentBalance={
                                                      account?.owner_account_balance
                                                    }
                                                    previousId={
                                                      refreshAnimation
                                                    }
                                                    previousBalance={
                                                      PrevBalance
                                                    }
                                                    refreshType={"single"}
                                                    classType={
                                                      "RequiredApproval"
                                                    }
                                                  />
                                                </p>
                                              </div>
                                              <div className=" ml-3">
                                                <p className="h-[26px] text-[#DADADA]">
                                                  {" "}
                                                  {"  "} |
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              onClick={(e) => {
                                                if (!filterOpen) {
                                                  SetrefreshId(account.txn_id);
                                                  handleRefresh(
                                                    e,
                                                    account.owner_account_id,
                                                    account?.owner_account_balance
                                                  );
                                                }
                                              }}
                                              className={`flex items-center   ml-[10px] gap-[5px] ${
                                                filterOpen
                                                  ? "cursor-default"
                                                  : services?.includes(
                                                      "VAccountBalance"
                                                    ) ||
                                                    services?.includes("Admin")
                                                  ? "cursor-pointer"
                                                  : "cursor-not-allowed opacity-50"
                                              }`}
                                            >
                                              {ownerAccBalanceLoading &&
                                              refreshId === account.txn_id ? (
                                                <BalanceLoader
                                                  loader={
                                                    ownerAccBalanceLoading
                                                  }
                                                  type={"createTxn"}
                                                />
                                              ) : !ownerAccBalanceLoading &&
                                                refreshId === account.txn_id ? (
                                                <BalanceLoader
                                                  loader={
                                                    ownerAccBalanceLoading
                                                  }
                                                  setAccId={SetrefreshId}
                                                  type={"createTxn"}
                                                />
                                              ) : (
                                                <Tooltip
                                                  componentsProps={{
                                                    popper: {
                                                      sx: {
                                                        [`& .${tooltipClasses.tooltip}`]:
                                                          {
                                                            backgroundColor: (
                                                              theme
                                                            ) =>
                                                              theme.palette
                                                                .common.black,
                                                          },
                                                      },
                                                    },
                                                  }}
                                                  title={"Refresh Balance"}
                                                >
                                                  <div className="flex gap-1">
                                                    <img
                                                      className="w-[12px] h-[12px] opacity-0.7"
                                                      src={ReloadWhite}
                                                      alt=""
                                                    />

                                                    <span className="w-[37px] h-[14px] text-left font-semibold text-[10px] leading-26 tracking-normal text-[#DADADA] opacity-100">
                                                      Refresh
                                                    </span>
                                                  </div>
                                                </Tooltip>
                                              )}
                                            </div>
                                          </div>

                                          <div className="w-[270px]  h-[17px] text-left font-normal text-[12px]  tracking-normal text-[#DADADA] opacity-100">
                                            Source account balance -&nbsp;
                                            {refreshId === account.txn_id
                                              ? account?.owner_account_balance_last_fetched_at_in_words
                                              : account?.owner_account_balance_last_fetched_at_in_words}
                                          </div>
                                        </div>
                                        <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] mt-4 opacity-[0.6]"></div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {account.lifecycle_status === "error" &&
                                account.error === "true" &&
                                !loading[account.txn_id] && (
                                  <div className="BeneTxn_ApprovalAcdivFirst   bg-[#506994]">
                                    <div className="flex  items-center rounded-tl-[30px] justify-between bg-[#314D7E] text-white h-[80px] ">
                                      <div className="flex  flex-col gap-1 ml-[20px]">
                                        <p className="w-[140px] h-[22px]  font-semibold text-[15px] leading-22 text-[#4DDD37] opacity-100">
                                          <span
                                            style={{
                                              display: "inline-block",
                                              fontSize: "15px",
                                              paddingRight: "5px",
                                            }}
                                          >
                                            ₹
                                          </span>
                                          {
                                            <FormatAmount
                                              price={account.amount}
                                            />
                                          }
                                        </p>
                                        <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#DADADA] opacity-100">
                                          Transaction amount
                                        </span>
                                      </div>
                                      <div className="ml-[40px] flex flex-col gap-1">
                                        {account?.category &&
                                        account?.category.length >= 10 ? (
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={account?.category}
                                          >
                                            <p className="w-[94px] text-right overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                              {account?.category}
                                            </p>
                                          </Tooltip>
                                        ) : (
                                          <p className="w-[90px]  text-right overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                            {account?.category}
                                          </p>
                                        )}
                                        <span className="w-[99px] h-[17px] font-normal text-[11px] leading-17 tracking-normal text-[#DADADA] opacity-100">
                                          Category selected
                                        </span>
                                      </div>

                                      <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                    </div>

                                    <div className="bg-[#506994]   h-[140px] rounded-bl-[30px]">
                                      <div className="flex justify-between w-full h-[40px] pt-[20px] ">
                                        <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px]">
                                          <p className="w-[139px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                            {formatAccountNumber(
                                              account?.owner_account_bank_acc_no
                                            )}
                                          </p>
                                          <span className="w-[96px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#DADADA] opacity-100">
                                            Source account
                                          </span>
                                        </div>

                                        <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                          <img
                                            className="w-[79px] h-[20px] relative top-[8px] left-[11px]"
                                            src={require("../../../assets/IfscLogo/" +
                                              IfscCodeMapper(
                                                account?.owner_account_ifsc_code
                                              ))}
                                            alt="bank"
                                          />
                                        </div>
                                        <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                      </div>

                                      <div className="w-[341px] h-[20px] ml-2 mt-5 flex justify-end  items-end  relative z-20 ">
                                        <img
                                          className="w-[20px] h-[20px] "
                                          src={ArrowLeft}
                                          alt=""
                                        />
                                      </div>

                                      <div className=" ml-[20px] flex justify-between">
                                        <div className="">
                                          <div className="flex items-center ">
                                            <div className="flex gap-1">
                                              <div>
                                                <h4
                                                  style={{
                                                    color:
                                                      Number(
                                                        account?.owner_account_balance
                                                      ) >=
                                                      Number(account.amount)
                                                        ? "#4DDD37"
                                                        : "#FF5860",
                                                  }}
                                                >
                                                  {" "}
                                                  ₹{" "}
                                                  {
                                                    <FormatAmount
                                                      price={
                                                        account?.owner_account_balance
                                                      }
                                                    />
                                                  }
                                                </h4>
                                              </div>
                                              <div className=" relative">
                                                <p className="">
                                                  <AnimationUpAndDown
                                                    loader={
                                                      ownerAccBalanceLoading
                                                    }
                                                    currentId={
                                                      account?.owner_account_id
                                                    }
                                                    currentBalance={
                                                      account?.owner_account_balance
                                                    }
                                                    previousId={
                                                      refreshAnimation
                                                    }
                                                    previousBalance={
                                                      PrevBalance
                                                    }
                                                    refreshType={"single"}
                                                    classType={
                                                      "RequiredApproval"
                                                    }
                                                  />
                                                </p>
                                              </div>
                                              <div className=" ml-3">
                                                <p className="h-[26px] text-[#DADADA]">
                                                  {" "}
                                                  {"  "} |
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              onClick={(e) => {
                                                if (!filterOpen) {
                                                  SetrefreshId(account.txn_id);
                                                  handleRefresh(
                                                    e,
                                                    account.owner_account_id,
                                                    account?.owner_account_balance
                                                  );
                                                }
                                              }}
                                              className={`flex items-center   ml-[4px] gap-[5px] ${
                                                filterOpen
                                                  ? "cursor-default"
                                                  : services?.includes(
                                                      "VAccountBalance"
                                                    ) ||
                                                    services?.includes("Admin")
                                                  ? "cursor-pointer"
                                                  : "cursor-not-allowed opacity-50"
                                              }`}
                                            >
                                              {ownerAccBalanceLoading &&
                                              refreshId === account.txn_id ? (
                                                <BalanceLoader
                                                  loader={
                                                    ownerAccBalanceLoading
                                                  }
                                                  type={"createTxn"}
                                                />
                                              ) : !ownerAccBalanceLoading &&
                                                refreshId === account.txn_id ? (
                                                <BalanceLoader
                                                  loader={
                                                    ownerAccBalanceLoading
                                                  }
                                                  setAccId={SetrefreshId}
                                                  type={"createTxn"}
                                                />
                                              ) : (
                                                <Tooltip
                                                  componentsProps={{
                                                    popper: {
                                                      sx: {
                                                        [`& .${tooltipClasses.tooltip}`]:
                                                          {
                                                            backgroundColor: (
                                                              theme
                                                            ) =>
                                                              theme.palette
                                                                .common.black,
                                                          },
                                                      },
                                                    },
                                                  }}
                                                  title={"Refresh Balance"}
                                                >
                                                  <div className="flex gap-1">
                                                    <img
                                                      className="w-[12px] h-[12px] opacity-0.7"
                                                      src={ReloadWhite}
                                                      alt=""
                                                    />

                                                    <span className="w-[37px] h-[14px] text-left font-semibold text-[10px] leading-26 tracking-normal text-[#DADADA] opacity-100">
                                                      Refresh
                                                    </span>
                                                  </div>
                                                </Tooltip>
                                              )}
                                            </div>
                                          </div>

                                          <div className="w-[270px]  h-[17px] text-left font-normal text-[12px]  tracking-normal text-[#DADADA] opacity-100">
                                            Source account balance -&nbsp;
                                            {refreshId === account.txn_id
                                              ? account?.owner_account_balance_last_fetched_at_in_words
                                              : account?.owner_account_balance_last_fetched_at_in_words}
                                          </div>
                                        </div>
                                        <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] mt-4 opacity-[0.6]"></div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {account.last_updated_user_id_check === "true" &&
                                account.lifecycle_status === "pendingOne" &&
                                account.error === "false" &&
                                !loading[account.txn_id] && (
                                  <div className="BeneTxn_ApprovalAcdivFirst   bg-[#506994]">
                                    <div className="flex  items-center rounded-tl-[30px] justify-between bg-[#314D7E] text-white h-[80px] ">
                                      <div className="flex  flex-col gap-1 ml-[20px]">
                                        <p className="w-[140px] h-[22px]  font-semibold text-[15px] leading-22 text-[#4DDD37] opacity-100">
                                          <span
                                            style={{
                                              display: "inline-block",
                                              fontSize: "15px",
                                              paddingRight: "5px",
                                            }}
                                          >
                                            ₹
                                          </span>
                                          {
                                            <FormatAmount
                                              price={account.amount}
                                            />
                                          }
                                        </p>
                                        <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#DADADA] opacity-100">
                                          Transaction amount
                                        </span>
                                      </div>
                                      <div className="ml-[40px] flex flex-col gap-1">
                                        {account?.category &&
                                        account?.category.length >= 10 ? (
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={account?.category}
                                          >
                                            <p className="w-[94px] text-right  overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                              {account?.category}
                                            </p>
                                          </Tooltip>
                                        ) : (
                                          <p className="w-[90px] text-right  overflow-hidden whitespace-nowrap overflow-ellipsis font-normal   text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                            {account?.category}
                                          </p>
                                        )}
                                        <span className="w-[99px] h-[17px] font-normal text-[11px] leading-17 tracking-normal text-[#DADADA] opacity-100">
                                          Category selected
                                        </span>
                                      </div>

                                      <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                    </div>

                                    <div className="bg-[#506994]   h-[140px] rounded-bl-[30px]">
                                      <div className="flex justify-between w-full h-[40px] pt-[20px] ">
                                        <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px]">
                                          <p className="w-[139px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                            {formatAccountNumber(
                                              account?.owner_account_bank_acc_no
                                            )}
                                          </p>
                                          <span className="w-[96px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#DADADA] opacity-100">
                                            Source account
                                          </span>
                                        </div>

                                        <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                          <img
                                            className="w-[79px] h-[20px] relative top-[8px] left-[11px]"
                                            src={require("../../../assets/IfscLogo/" +
                                              IfscCodeMapper(
                                                account?.owner_account_ifsc_code
                                              ))}
                                            alt="bank"
                                          />
                                        </div>
                                        <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                      </div>

                                      <div className="w-[341px] h-[20px] ml-2 mt-5 flex justify-end  items-end  relative z-20 ">
                                        <img
                                          className="w-[20px] h-[20px] "
                                          src={ArrowLeft}
                                          alt=""
                                        />
                                      </div>

                                      <div className=" ml-[20px] flex justify-between">
                                        <div className="">
                                          <div className="flex items-center ">
                                            <div className="flex gap-1">
                                              <div>
                                                <h4
                                                  style={{
                                                    color:
                                                      Number(
                                                        account?.owner_account_balance
                                                      ) >=
                                                      Number(account.amount)
                                                        ? "#4DDD37"
                                                        : "#FF5860",
                                                  }}
                                                >
                                                  {" "}
                                                  ₹{" "}
                                                  {
                                                    <FormatAmount
                                                      price={
                                                        account?.owner_account_balance
                                                      }
                                                    />
                                                  }
                                                </h4>
                                              </div>
                                              <div className=" relative">
                                                <p className="">
                                                  <AnimationUpAndDown
                                                    loader={
                                                      ownerAccBalanceLoading
                                                    }
                                                    currentId={
                                                      account?.owner_account_id
                                                    }
                                                    currentBalance={
                                                      account?.owner_account_balance
                                                    }
                                                    previousId={
                                                      refreshAnimation
                                                    }
                                                    previousBalance={
                                                      PrevBalance
                                                    }
                                                    refreshType={"single"}
                                                    classType={
                                                      "RequiredApproval"
                                                    }
                                                  />
                                                </p>
                                              </div>
                                              <div className=" ml-3">
                                                <p className="h-[26px] text-[#DADADA]">
                                                  {" "}
                                                  {"  "} |
                                                </p>
                                              </div>
                                            </div>
                                            <div
                                              onClick={(e) => {
                                                if (!filterOpen) {
                                                  SetrefreshId(account.txn_id);
                                                  handleRefresh(
                                                    e,
                                                    account.owner_account_id,
                                                    account?.owner_account_balance
                                                  );
                                                }
                                              }}
                                              className={`flex items-center   ml-[4px] gap-[5px] ${
                                                filterOpen
                                                  ? "cursor-default"
                                                  : services?.includes(
                                                      "VAccountBalance"
                                                    ) ||
                                                    services?.includes("Admin")
                                                  ? "cursor-pointer"
                                                  : "cursor-not-allowed opacity-50"
                                              }`}
                                            >
                                              {ownerAccBalanceLoading &&
                                              refreshId === account.txn_id ? (
                                                <BalanceLoader
                                                  loader={
                                                    ownerAccBalanceLoading
                                                  }
                                                  type={"createTxn"}
                                                />
                                              ) : !ownerAccBalanceLoading &&
                                                refreshId === account.txn_id ? (
                                                <BalanceLoader
                                                  loader={
                                                    ownerAccBalanceLoading
                                                  }
                                                  setAccId={SetrefreshId}
                                                  type={"createTxn"}
                                                />
                                              ) : (
                                                <Tooltip
                                                  componentsProps={{
                                                    popper: {
                                                      sx: {
                                                        [`& .${tooltipClasses.tooltip}`]:
                                                          {
                                                            backgroundColor: (
                                                              theme
                                                            ) =>
                                                              theme.palette
                                                                .common.black,
                                                          },
                                                      },
                                                    },
                                                  }}
                                                  title={"Refresh Balance"}
                                                >
                                                  <div className="flex gap-1">
                                                    <img
                                                      className="w-[12px] h-[12px] opacity-0.7"
                                                      src={ReloadWhite}
                                                      alt=""
                                                    />

                                                    <span className="w-[37px] h-[14px] text-left font-semibold text-[10px] leading-26 tracking-normal text-[#DADADA] opacity-100">
                                                      Refresh
                                                    </span>
                                                  </div>
                                                </Tooltip>
                                              )}
                                            </div>
                                          </div>

                                          <div className="w-[270px]  h-[17px] text-left font-normal text-[12px]  tracking-normal text-[#DADADA] opacity-100">
                                            Source account balance -&nbsp;
                                            {refreshId === account.txn_id
                                              ? account?.owner_account_balance_last_fetched_at_in_words
                                              : account?.owner_account_balance_last_fetched_at_in_words}
                                          </div>
                                        </div>
                                        <div className="w-[1.5px] h-[25px] bg-[#1D3A6D] mt-4 opacity-[0.6]"></div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              <div className="BeneTxn_ApprovalAcdivSecond">
                                {account.lifecycle_status === "rejected" &&
                                  account.error === "false" &&
                                  !loading[account.txn_id] && (
                                    <div className="flex justify-between w-[341px] h-[220px] bg-[#667CA3]">
                                      <div className="flex flex-col gap-[6px] mt-[15px] ml-[27px] overflow-hidden">
                                        <p className="w-[120px] h-[16px] font-normal text-[15px] leading-[16px] text-[#DADADA]">
                                          Rejected reason
                                        </p>

                                        {account.rejection_reason &&
                                        account.rejection_reason.length >
                                          300 ? (
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={account.rejection_reason}
                                          >
                                            <span
                                              className="w-[280px]   line-clamp-[9]   text-left text-[13px] font-normal  overflow-hidden   font-sans  text-[#F9F9F9] opacity-100"
                                              style={{
                                                letterSpacing: "0.5px",
                                                wordWrap: "break-word",
                                                // border: "1px solid red",
                                              }}
                                            >
                                              {account.rejection_reason}
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          <span
                                            className="w-[280px] h-[16px]  text-left text-[13px] font-normal  font-sans tracking-normal text-[#F9F9F9] opacity-100 overflow-ellipsis"
                                            style={{
                                              letterSpacing: "0.5px",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            {account.rejection_reason}
                                          </span>
                                        )}
                                      </div>
                                      <div className="w-[1.3px] h-[180px] bg-[#1D3A6D] opacity-[0.6] mt-[20px]"></div>
                                    </div>
                                  )}

                                {(account.lifecycle_status === "pending" ||
                                  loading[account.txn_id]) &&
                                  account.beneficiary_account_approval_status ===
                                    "approved" && (
                                    <>
                                      <div className="flex items-center justify-between bg-[#314D7E] text-white h-[80px] w-[342px] ">
                                        <div className="flex  flex-col gap-1 ml-[20px]">
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={account?.beneficiary_account_name
                                              .toLowerCase()
                                              .split(" ")
                                              .map(
                                                (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1)
                                              )
                                              .join(" ")}
                                          >
                                            <p className="w-[300px] lowercase  first-line:capitalize text-left  font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                              {account?.beneficiary_account_name
                                                .toLowerCase()
                                                .split(" ")
                                                .map(
                                                  (word) =>
                                                    word
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    word.slice(1)
                                                )
                                                .join(" ")}
                                            </p>
                                          </Tooltip>

                                          <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#DADADA] opacity-100">
                                            Beneficiary name
                                          </span>
                                        </div>
                                        <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                      </div>

                                      <div className=" w-[341px] bg-[#506994] h-[137px] ">
                                        <div className="flex  justify-between w-[341px] h-[40px] pt-[20px] ">
                                          <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px] ">
                                            {account?.beneficiary_account_bank_acc_no &&
                                            account
                                              ?.beneficiary_account_bank_acc_no
                                              .length >= 0 ? (
                                              <Tooltip
                                                componentsProps={{
                                                  popper: {
                                                    sx: {
                                                      [`& .${tooltipClasses.tooltip}`]:
                                                        {
                                                          backgroundColor: (
                                                            theme
                                                          ) =>
                                                            theme.palette.common
                                                              .black,
                                                        },
                                                    },
                                                  },
                                                }}
                                                title={
                                                  account?.beneficiary_account_bank_acc_no
                                                }
                                              >
                                                <p className="w-[190px]  text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                                  {formatAccountNumber(
                                                    account?.beneficiary_account_bank_acc_no
                                                  )}
                                                </p>
                                              </Tooltip>
                                            ) : (
                                              <p className="w-[190px]  text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                                {formatAccountNumber(
                                                  account?.beneficiary_account_bank_acc_no
                                                )}
                                              </p>
                                            )}
                                            <span className="w-[116px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#DADADA] opacity-100">
                                              Beneficiary account
                                            </span>
                                          </div>

                                          <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                            <img
                                              className="w-[79px] h-[20px] relative top-[8px] left-[11px]"
                                              src={require("../../../assets/IfscLogo/" +
                                                IfscCodeMapper(
                                                  account?.beneficiary_account_ifsc_code
                                                ))}
                                              alt="bank"
                                            />
                                          </div>
                                          <div className="w-[1.5px] h-[100px] bg-[#1D3A6D] opacity-[0.6] relative left-[1px]"></div>
                                        </div>

                                        <div className="w-[93px] h-[40px] mt-[42px] ml-[20px]">
                                          <p className="w-[93px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                            {
                                              account?.beneficiary_account_ifsc_code
                                            }
                                          </p>
                                          <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#DADADA] opacity-100">
                                            IFSC code
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                {(account.lifecycle_status === "pending" ||
                                  loading[account.txn_id]) &&
                                  account.beneficiary_account_approval_status ===
                                    "pending" && (
                                    <div className="bg-[#314D7E]">
                                      <div className="flex items-center justify-between bg-[#314D7E] text-white h-[80px] w-[342px] ">
                                        <div className="flex  flex-col gap-1 ml-[20px]">
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={account?.beneficiary_account_name
                                              .toLowerCase()
                                              .split(" ")
                                              .map(
                                                (word) =>
                                                  word.charAt(0).toUpperCase() +
                                                  word.slice(1)
                                              )
                                              .join(" ")}
                                          >
                                            <p className="w-[300px] lowercase first-line:capitalize text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                              {account?.beneficiary_account_name
                                                .toLowerCase()
                                                .split(" ")
                                                .map(
                                                  (word) =>
                                                    word
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    word.slice(1)
                                                )
                                                .join(" ")}
                                            </p>
                                          </Tooltip>

                                          <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#DADADA] opacity-100">
                                            Beneficiary name
                                          </span>
                                        </div>
                                        <div className="w-[1.5px] h-[180px] bg-[#1D3A6D] relative  top-[72px] opacity-[0.6]"></div>
                                      </div>

                                      <div className=" w-[341px]  h-[140px] bg-[#314D7E]">
                                        <div className="flex  justify-between w-[341px] h-[40px] pt-[20px] ">
                                          <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px] ">
                                            {account?.beneficiary_account_bank_acc_no &&
                                            account
                                              ?.beneficiary_account_bank_acc_no
                                              .length >= 0 ? (
                                              <Tooltip
                                                componentsProps={{
                                                  popper: {
                                                    sx: {
                                                      [`& .${tooltipClasses.tooltip}`]:
                                                        {
                                                          backgroundColor: (
                                                            theme
                                                          ) =>
                                                            theme.palette.common
                                                              .black,
                                                        },
                                                    },
                                                  },
                                                }}
                                                title={
                                                  account?.beneficiary_account_bank_acc_no
                                                }
                                              >
                                                <p className="w-[190px] text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                                  {formatAccountNumber(
                                                    account?.beneficiary_account_bank_acc_no
                                                  )}
                                                </p>
                                              </Tooltip>
                                            ) : (
                                              <p className="w-[190px]   text-left  font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                                {formatAccountNumber(
                                                  account?.beneficiary_account_bank_acc_no
                                                )}
                                              </p>
                                            )}
                                            <span className="w-[116px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#DADADA] opacity-100">
                                              Beneficiary account
                                            </span>
                                          </div>

                                          <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa]  relative left-[-20px] rounded-[22px] opacity-100">
                                            <img
                                              className="w-[79px] h-[20px] relative top-[9px] left-[11px]"
                                              src={require("../../../assets/IfscLogo/" +
                                                IfscCodeMapper(
                                                  account?.beneficiary_account_ifsc_code
                                                ))}
                                              alt="bank"
                                            />
                                          </div>
                                        </div>

                                        <div className="w-[93px] h-[40px] mt-[42px] ml-[20px]">
                                          <p className="w-[93px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                            {
                                              account?.beneficiary_account_ifsc_code
                                            }
                                          </p>
                                          <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#DADADA] opacity-100">
                                            IFSC code
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                {account.lifecycle_status === "error" &&
                                  account.error === "true" &&
                                  !loading[account.txn_id] && (
                                    <>
                                      <div className="flex items-center justify-between bg-[#314D7E] text-white h-[80px] w-[342px] ">
                                        <div className="flex  flex-col gap-1 ml-[20px]">
                                          {account?.beneficiary_account_name &&
                                          account?.beneficiary_account_name
                                            .length > 24 ? (
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              title={account?.beneficiary_account_name
                                                .toLowerCase()
                                                .split(" ")
                                                .map(
                                                  (word) =>
                                                    word
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    word.slice(1)
                                                )
                                                .join(" ")}
                                            >
                                              <p className="w-[300px] lowercase first-line:capitalize text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                                {account?.beneficiary_account_name
                                                  .toLowerCase()
                                                  .split(" ")
                                                  .map(
                                                    (word) =>
                                                      word
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                      word.slice(1)
                                                  )
                                                  .join(" ")}
                                              </p>
                                            </Tooltip>
                                          ) : (
                                            <p className="w-[198px] lowercase first-line:capitalize  text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                              {account?.beneficiary_account_name
                                                .toLowerCase()
                                                .split(" ")
                                                .map(
                                                  (word) =>
                                                    word
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    word.slice(1)
                                                )
                                                .join(" ")}
                                            </p>
                                          )}

                                          <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#DADADA] opacity-100">
                                            Beneficiary name
                                          </span>
                                        </div>
                                        <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                      </div>

                                      <div className=" w-[341px] bg-[#506994] h-[137px] ">
                                        <div className="flex  justify-between w-[341px] h-[40px] pt-[20px] ">
                                          <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px] ">
                                            {account?.beneficiary_account_bank_acc_no &&
                                            account
                                              ?.beneficiary_account_bank_acc_no
                                              .length >= 0 ? (
                                              <Tooltip
                                                componentsProps={{
                                                  popper: {
                                                    sx: {
                                                      [`& .${tooltipClasses.tooltip}`]:
                                                        {
                                                          backgroundColor: (
                                                            theme
                                                          ) =>
                                                            theme.palette.common
                                                              .black,
                                                        },
                                                    },
                                                  },
                                                }}
                                                title={
                                                  account?.beneficiary_account_bank_acc_no
                                                }
                                              >
                                                <p className="w-[190px]  text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                                  {formatAccountNumber(
                                                    account?.beneficiary_account_bank_acc_no
                                                  )}
                                                </p>
                                              </Tooltip>
                                            ) : (
                                              <p className="w-[190px]  text-left  font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                                {formatAccountNumber(
                                                  account?.beneficiary_account_bank_acc_no
                                                )}
                                              </p>
                                            )}
                                            <span className="w-[116px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#DADADA] opacity-100">
                                              Beneficiary account
                                            </span>
                                          </div>

                                          <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                            <img
                                              className="w-[79px] h-[20px] relative top-[9px] left-[11px]"
                                              src={require("../../../assets/IfscLogo/" +
                                                IfscCodeMapper(
                                                  account?.beneficiary_account_ifsc_code
                                                ))}
                                              alt="bank"
                                            />
                                          </div>
                                          <div className="w-[1.5px] h-[100px] bg-[#1D3A6D] opacity-[0.6] relative left-[1px]"></div>
                                        </div>

                                        <div className="w-[93px] h-[40px] mt-[42px] ml-[20px]">
                                          <p className="w-[93px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                            {
                                              account?.beneficiary_account_ifsc_code
                                            }
                                          </p>
                                          <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#DADADA] opacity-100">
                                            IFSC code
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                {account.lifecycle_status === "approved" &&
                                  account.error === "false" &&
                                  !loading[account.txn_id] && (
                                    <>
                                      <div className="flex items-center justify-between bg-[#314D7E] text-white h-[80px] w-[342px] ">
                                        <div className="flex  flex-col gap-1 ml-[20px]">
                                          {account?.beneficiary_account_name &&
                                          account?.beneficiary_account_name
                                            .length > 24 ? (
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              title={account?.beneficiary_account_name
                                                .toLowerCase()
                                                .split(" ")
                                                .map(
                                                  (word) =>
                                                    word
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    word.slice(1)
                                                )
                                                .join(" ")}
                                            >
                                              <p className="w-[300px] lowercase first-line:capitalize text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                                {account?.beneficiary_account_name
                                                  .toLowerCase()
                                                  .split(" ")
                                                  .map(
                                                    (word) =>
                                                      word
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                      word.slice(1)
                                                  )
                                                  .join(" ")}
                                              </p>
                                            </Tooltip>
                                          ) : (
                                            <p className="w-[198px] lowercase first-line:capitalize  text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                              {account?.beneficiary_account_name
                                                .toLowerCase()
                                                .split(" ")
                                                .map(
                                                  (word) =>
                                                    word
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    word.slice(1)
                                                )
                                                .join(" ")}
                                            </p>
                                          )}

                                          <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#DADADA] opacity-100">
                                            Beneficiary name
                                          </span>
                                        </div>
                                        <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                      </div>

                                      <div className=" w-[341px] bg-[#506994] h-[137px] ">
                                        <div className="flex  justify-between w-[341px] h-[40px] pt-[20px] ">
                                          <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px] ">
                                            {account?.beneficiary_account_bank_acc_no &&
                                            account
                                              ?.beneficiary_account_bank_acc_no
                                              .length > 0 ? (
                                              <Tooltip
                                                componentsProps={{
                                                  popper: {
                                                    sx: {
                                                      [`& .${tooltipClasses.tooltip}`]:
                                                        {
                                                          backgroundColor: (
                                                            theme
                                                          ) =>
                                                            theme.palette.common
                                                              .black,
                                                        },
                                                    },
                                                  },
                                                }}
                                                title={
                                                  account?.beneficiary_account_bank_acc_no
                                                }
                                              >
                                                <p className="w-[190px]  first-line:capitalize text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                                  {formatAccountNumber(
                                                    account?.beneficiary_account_bank_acc_no
                                                  )}
                                                </p>
                                              </Tooltip>
                                            ) : (
                                              <p className="w-[190px]   first-line:capitalize text-left  font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                                {formatAccountNumber(
                                                  account?.beneficiary_account_bank_acc_no
                                                )}
                                              </p>
                                            )}
                                            <span className="w-[116px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#DADADA] opacity-100">
                                              Beneficiary account
                                            </span>
                                          </div>

                                          <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                            <img
                                              className="w-[79px] h-[20px] relative top-[8px] left-[11px]"
                                              src={require("../../../assets/IfscLogo/" +
                                                IfscCodeMapper(
                                                  account?.beneficiary_account_ifsc_code
                                                ))}
                                              alt="bank"
                                            />
                                          </div>
                                          <div className="w-[1.5px] h-[100px] bg-[#1D3A6D] opacity-[0.6] relative left-[1px]"></div>
                                        </div>

                                        <div className="w-[93px] h-[40px] mt-[42px] ml-[20px]">
                                          <p className="w-[93px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                            {
                                              account?.beneficiary_account_ifsc_code
                                            }
                                          </p>
                                          <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#DADADA] opacity-100">
                                            IFSC code
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                {account.last_updated_user_id_check ===
                                  "true" &&
                                  account.lifecycle_status === "pendingOne" &&
                                  account.error === "false" &&
                                  !loading[account.txn_id] && (
                                    <>
                                      <div className="flex items-center justify-between bg-[#314D7E] text-white h-[80px] w-[342px] ">
                                        <div className="flex  flex-col gap-1 ml-[20px]">
                                          {account?.beneficiary_account_name &&
                                          account?.beneficiary_account_name
                                            .length > 24 ? (
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              title={account?.beneficiary_account_name
                                                .toLowerCase()
                                                .split(" ")
                                                .map(
                                                  (word) =>
                                                    word
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    word.slice(1)
                                                )
                                                .join(" ")}
                                            >
                                              <p className="w-[198px] lowercase first-line:capitalize text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                                {account?.beneficiary_account_name
                                                  .toLowerCase()
                                                  .split(" ")
                                                  .map(
                                                    (word) =>
                                                      word
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                      word.slice(1)
                                                  )
                                                  .join(" ")}
                                              </p>
                                            </Tooltip>
                                          ) : (
                                            <p className="w-[198px] lowercase first-line:capitalize  text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                              {account?.beneficiary_account_name
                                                .toLowerCase()
                                                .split(" ")
                                                .map(
                                                  (word) =>
                                                    word
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    word.slice(1)
                                                )
                                                .join(" ")}
                                            </p>
                                          )}

                                          <span className="w-[113px] h-[17px] text-left font-normal text-[11px] leading-17 tracking-normal text-[#DADADA] opacity-100">
                                            Beneficiary name
                                          </span>
                                        </div>
                                        <div className="w-[1.5px] h-[45px] bg-[#1D3A6D] opacity-[0.6]"></div>
                                      </div>

                                      <div className=" w-[341px] bg-[#506994] h-[137px] ">
                                        <div className="flex  justify-between w-[341px] h-[40px] pt-[20px] ">
                                          <div className="flex flex-col gap-[1px] w-[139px] h-[40px] ml-[20px] ">
                                            {account?.beneficiary_account_bank_acc_no &&
                                            account
                                              ?.beneficiary_account_bank_acc_no
                                              .length > 0 ? (
                                              <Tooltip
                                                componentsProps={{
                                                  popper: {
                                                    sx: {
                                                      [`& .${tooltipClasses.tooltip}`]:
                                                        {
                                                          backgroundColor: (
                                                            theme
                                                          ) =>
                                                            theme.palette.common
                                                              .black,
                                                        },
                                                    },
                                                  },
                                                }}
                                                title={
                                                  account?.beneficiary_account_bank_acc_no
                                                }
                                              >
                                                <p className="w-[190px] lowercase first-line:capitalize text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                                  {formatAccountNumber(
                                                    account?.beneficiary_account_bank_acc_no
                                                  )}
                                                </p>
                                              </Tooltip>
                                            ) : (
                                              <p className="w-[190px] lowercase  first-line:capitalize text-left  font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                                {formatAccountNumber(
                                                  account?.beneficiary_account_bank_acc_no
                                                )}
                                              </p>
                                            )}
                                            <span className="w-[116px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#DADADA] opacity-100">
                                              Beneficiary account
                                            </span>
                                          </div>

                                          <div className="ml-[40px] w-[104px] h-[35px] bg-[#fafafa] rounded-[22px] opacity-100">
                                            <img
                                              className="w-[79px] h-[20px] relative top-[9px] left-[11px]"
                                              src={require("../../../assets/IfscLogo/" +
                                                IfscCodeMapper(
                                                  account?.beneficiary_account_ifsc_code
                                                ))}
                                              alt="bank"
                                            />
                                          </div>
                                          <div className="w-[1.5px] h-[100px] bg-[#1D3A6D] opacity-[0.6] relative left-[1px]"></div>
                                        </div>

                                        <div className="w-[93px] h-[40px] mt-[42px] ml-[20px]">
                                          <p className="w-[93px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                            {
                                              account?.beneficiary_account_ifsc_code
                                            }
                                          </p>
                                          <span className="w-[54px] h-[17px] text-left font-normal text-[12px] leading-17 text-[#DADADA] opacity-100">
                                            IFSC code
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  )}
                              </div>

                              <div className="BeneTxn_ApprovalAcdivThird">
                                {(account.lifecycle_status === "pending" ||
                                  loading[account.txn_id]) &&
                                  account.beneficiary_account_approval_status ===
                                    "approved" && (
                                    <>
                                      <div className="flex  w-[341px] justify-between bg-[#314D7E] text-white h-[80px] rounded-tr-[30px] ">
                                        <div className="flex  justify-between w-[341px]">
                                          <div className="pt-[18px] ml-[20px]">
                                            <p className="w-[199px] h-[19px] text-left font-normal text-[14px] leading-19 text-[#F9F9F9] opacity-100">
                                              {BeneTxnDataFormat(
                                                account?.created_on
                                              )}
                                              ,&nbsp;
                                              {formatTime(
                                                account?.last_updated_timestamp
                                              )}
                                            </p>

                                            <span className="w-[79px] h-[17px] text-left font-normal text-[12px] leading-17 text-Primary_grey opacity-100">
                                              Initiated on
                                            </span>
                                          </div>

                                          <div
                                            onClick={() => {
                                              if (!filterOpen) {
                                                openBeneTxnDetailModal();
                                                setTxnId(account.txn_id);
                                              }
                                            }}
                                            className="w-[132px] flex items-center rounded-tr-[30px] rounded-bl-[30px] justify-center h-[49px] bg-[#F9F9F9] bg-no-repeat bg-cover opacity-100
                                            "
                                            style={{
                                              cursor: filterOpen
                                                ? "default"
                                                : "pointer",
                                            }}
                                          >
                                            <div className="flex items-center gap-[7px] justify-center">
                                              <p className="w-[auto] h-[17px] text-left font-semibold text-[12px] leading-17 text-[#1D3A6D] opacity-100">
                                                More Details
                                              </p>
                                              <img
                                                className="w-[12px] h-[12px] opacity-100"
                                                src={ArrowRight}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex items-end bg-[#506994]  w-[341px]   h-[137px] rounded-br-[30px] ">
                                        <div className="flex ml-[68px] bg-[#667CA3] items-center relative top-[3px] justify-between  w-[273px] rounded-br-[30px] h-[100px]  bg-cover rounded-tl-[30px]  opacity-100">
                                          {account?.lifecycle_status ===
                                            "pending" &&
                                            !loading[account.txn_id] && (
                                              <>
                                                <button
                                                  className={`flex flex-col justify-center items-center ml-[5px] mb-[1px] gap-[4px] w-[45%] ${
                                                    filterOpen
                                                      ? "cursor-default"
                                                      : " cursor-pointer"
                                                  } `}
                                                  onClick={() => {
                                                    if (!filterOpen) {
                                                      handleOpenPopup(
                                                        account?.txn_id
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <img
                                                    className="w-[40px] h-[40px] shadow-[5px -5px 11px #71717140]"
                                                    src={aprovaldenied}
                                                    alt=""
                                                  />
                                                  <p className="w-[34px] h-[14px] text-center text-[10px] leading-14 text-[#FFFFFF]">
                                                    Reject
                                                  </p>
                                                </button>

                                                <div className="w-0 h-[80px] border  border-[#1D3A6D] opacity-[0.6]"></div>
                                                <button
                                                  ref={filterRef}
                                                  className={`flex flex-col justify-center  items-center mr-[5px] w-[45%] gap-[4px] ${
                                                    filterOpen
                                                      ? "cursor-default"
                                                      : " cursor-pointer"
                                                  }`}
                                                  onClick={() => {
                                                    if (!filterOpen) {
                                                      handleTxnApproval(
                                                        account?.txn_id
                                                      );
                                                    }
                                                  }} // 1
                                                >
                                                  <img
                                                    className="w-[40px] h-[40px] shadow-[5px -5px 11px #71717140"
                                                    src={aprovalaccept}
                                                    alt=""
                                                  />
                                                  <p className="w-[40px] h-[14px] text-center text-[10px] leading-14 text-[#FFFFFF]">
                                                    Approve
                                                  </p>
                                                </button>
                                              </>
                                            )}

                                          {loading[account.txn_id] && (
                                            <div className="flex justify-between items-center w-[50px] h-[29px] ml-[110px] opacity-100">
                                              <Loader />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}

                                {account.lifecycle_status === "rejected" &&
                                  account.error === "false" &&
                                  !loading[account.txn_id] && (
                                    <div className=" flex w-[341px]  pl-[20px] h-[220px] gap-[15px] bg-[#667CA3] rounded-tr-[30px] rounded-br-[30px] ">
                                      <div className="w-[40px] h-[40px]">
                                        <img
                                          className=" mt-[42px]"
                                          src={DeclinedRed}
                                          alt=""
                                        />
                                      </div>
                                      <div className="w-[230px]  h-[131px] mt-[44px]">
                                        <div className="">
                                          <p className="w-[72px]  h-[22px] text-left font-semibold text-[#FFFFFF] text-[16px] leading-[22px]">
                                            Rejected!
                                          </p>
                                          <span className="w-[230px] h-[19px] text-left font-normal text-[14px] leading-[19px] text-[#FAFAFA]">
                                            This transaction has been Rejected!
                                          </span>
                                        </div>
                                        <div
                                          className="BeneTxn_ViewTransction"
                                          onClick={() =>
                                            viewTxnNavigate(account.txn_id)
                                          }
                                        >
                                          <p>View Transaction</p>
                                          <img src={WhiteArrowRight} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                {account.last_updated_user_id_check ===
                                  "true" &&
                                  account.lifecycle_status === "pendingOne" &&
                                  account.error === "false" &&
                                  !loading[account.txn_id] && (
                                    <>
                                      <div className="w-[340px] h-[220px] bg-[#667ca3] flex gap-[20px]  rounded-tr-[30px] rounded-br-[30px] pl-[20px]">
                                        <div className="BeneTxn_AwatigVefiyImgaes">
                                          <img src={pendingImages} alt="" />
                                        </div>
                                        <div className="BeneTxn_AwaitingVrfSecondDiv">
                                          <div className="BeneTxn_AwaitingVrfTxt">
                                            <p>Pending</p>
                                            <span>
                                              This transaction has been approved
                                              and requires another user's
                                              approval before distribution.
                                            </span>
                                          </div>
                                          <div
                                            className="BeneTxn_ViewTransction"
                                            onClick={() =>
                                              viewTxnNavigate(account.txn_id)
                                            }
                                          >
                                            <p>View Transaction</p>
                                            <img src={WhiteArrowRight} alt="" />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                {(account.lifecycle_status === "pending" ||
                                  loading[account.txn_id]) &&
                                  account.beneficiary_account_approval_status ===
                                    "pending" && (
                                    <div className="flex flex-col w-[342px] h-[220px] bg-[#314D7E] shadow-[5px 5px 11px #00000033] rounded-tr-[30px] rounded-br-[30px]">
                                      <div className="ml-[20px] mt-[34px]">
                                        <p className="w-[auto] h-[22px] text-left font-semibold text-[16px] text-[#DADADA]">
                                          Account Approval Pending
                                        </p>
                                      </div>
                                      <div className="ml-[20px] mt-[30px]">
                                        <div className="">
                                          <p className="w-[269px] h-[38px] text-left font-normal text-[13px] leading-16 text-[#31E5E5] opacity-100">
                                            Account is not approved, please
                                            approve account before approving
                                            transaction.
                                          </p>
                                        </div>
                                        <div
                                          onClick={() => {
                                            SideDrawerOpen(
                                              // "BeneTxnRequiredApproval",
                                              account?.beneficiary_account_id,
                                              account?.txn_id
                                            );
                                            // }
                                          }}
                                          className={`flex justify-between items-center gap-[4px]  w-[213px] h-[31px] bg-[#1d3a6d] rounded-[10px] opacity-100 mt-[20px] ${
                                            filterOpen
                                              ? "cursor-default"
                                              : " cursor-pointer"
                                          }`}
                                        >
                                          <p className="w-[105px] h-[19px] font-normal text-[13px] text-[#ffffff] opacity-100 ml-[10px]">
                                            Approve Account
                                          </p>
                                          <img
                                            className="w-[12px] h-[16px] opacity-100 mr-[10px]"
                                            src={WhiteArrowRight}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                {account.lifecycle_status === "approved" &&
                                  account.distribution_lifecycle_status ===
                                    "created" &&
                                  account.error === "false" &&
                                  !loading[account.txn_id] && (
                                    <div className="w-[340px] h-[220px] bg-[#667ca3] flex gap-[20px]  rounded-tr-[30px] rounded-br-[30px] pl-[20px]">
                                      <div className="BeneTxn_AwatigVefiyImgaes">
                                        <img src={BeneTxn_Queued} alt="" />
                                      </div>
                                      <div className="BeneTxn_AwaitingVrfSecondDiv">
                                        <div className="BeneTxn_DistributionTxt">
                                          <p>Distribution created</p>
                                          <span>
                                            This transaction has been approved
                                            and is set for distribution.
                                          </span>
                                        </div>
                                        <div
                                          className="BeneTxn_ViewTransction"
                                          onClick={() =>
                                            viewTxnNavigate(account.txn_id)
                                          }
                                        >
                                          <p>View Transaction</p>
                                          <img src={WhiteArrowRight} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                {account.lifecycle_status === "approved" &&
                                  account.distribution_lifecycle_status ===
                                    "distribution_complete" &&
                                  account.error === "false" &&
                                  !loading[account.txn_id] && (
                                    <div className="w-[340px] h-[220px] bg-[#667ca3] flex gap-[20px]  rounded-tr-[30px] rounded-br-[30px] pl-[20px]">
                                      <div className="BeneTxn_AwatigVefiyImgaes">
                                        <img src={Approvalgreen} alt="" />
                                      </div>
                                      <div className="BeneTxn_AwaitingVrfSecondDiv">
                                        <div className="BeneTxn_DistributionTxt">
                                          <p>Distribution completed!</p>
                                          <span>
                                            This transaction has been verified
                                            and settled.
                                          </span>
                                        </div>
                                        <div
                                          className="BeneTxn_ViewTransction"
                                          onClick={() =>
                                            viewTxnNavigate(account.txn_id)
                                          }
                                        >
                                          <p>View Transaction</p>
                                          <img src={WhiteArrowRight} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                {account.lifecycle_status === "error" &&
                                  account.error === "true" &&
                                  !loading[account.txn_id] && (
                                    <div className="flex  w-[342px] h-[220px] bg-[#314D7E] pl-[15px]  shadow-[5px 5px 11px #00000033] rounded-tr-[30px] rounded-br-[30px] gap-[15px]">
                                      <div className="w-[40px] h-[40px] ml-[20px] mt-[30px]">
                                        <img src={SkipVerification} alt="" />
                                      </div>
                                      <div className="w-[223px] h-[131px]">
                                        <div className="BeneTxn_Settledtext ">
                                          <p className="w-[227px] h-[22px] text-left font-semibold text-[#F9F9F9] text-[16px] leading-[22px]">
                                            Failed to take action!
                                          </p>
                                          <div className="w-[242px] h-[50px] line-clamp-2   text-[#FFFFFF]   whitespace-wrap overflow-ellipsis">
                                            <span className="     font-normal     text-[#FFFFFF] text-[14px] leading-[19px]">
                                              {account.message && (
                                                <Tooltip
                                                  componentsProps={{
                                                    popper: {
                                                      sx: {
                                                        [`& .${tooltipClasses.tooltip}`]:
                                                          {
                                                            backgroundColor: (
                                                              theme
                                                            ) =>
                                                              theme.palette
                                                                .common.black,
                                                            marginRight: "4rem",
                                                          },
                                                      },
                                                    },
                                                  }}
                                                  title={account?.message}
                                                >
                                                  <p className="w-[227px]   line-clamp-2   whitespace-wrap overflow-ellipsis   text-[14px] text-[#F9F9F9] opacity-100">
                                                    {account?.message}
                                                  </p>
                                                </Tooltip>
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                        <div
                                          className="BeneTxn_ViewTransction"
                                          onClick={() =>
                                            viewTxnNavigate(account.txn_id)
                                          }
                                        >
                                          <p>View Transaction</p>
                                          <img src={WhiteArrowRight} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </>
                        ))}
                      </InfiniteScroll>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div>
          {filterOpen ? (
            <div>
              <BeneTxnFilter
                setFilterOpen={setFilterOpen}
                data={AllBeneTxnAccounts}
                label={label}
                setlabel={setlabel}
                initCategory={initCategory}
                sendCategory={handleFilterCategory}
                filterOpen={filterOpen}
                setInitCategory={setInitCategory}
                transaction={true}
                setPage={setPage}
                setFilterCat={setFilterCat}
                filteredType={"singleApproveType"}
                handleClearCategory={handleClearCategory}
              />
            </div>
          ) : (
            <div>
              <div
                className="beneacc_approval_heder_containermv-mob"
                style={{ opacity: drawerOpen ? 0.3 : 1 }}
              >
                <div className="txnBeneapprovalheadertextv-mob bg-[#3A5686]">
                  <div
                    className="txnBeneapprovalheaderarrowvcv-mob"
                    onClick={() => navigate(-1)}
                  >
                    <img
                      src={LeftSign}
                      style={{
                        width: "16px",
                        height: "16px",
                        marginLeft: "10px",
                      }}
                      alt=""
                    />
                  </div>
                  <div className="txnBeneapprovalheadertextmv-mob">
                    <div className="headingAccountDetail">Beneficiary</div>
                    <div className="headertextbottomv_mob">
                      Transaction approval
                    </div>
                  </div>
                  <div className="txnBeneapprovalheaderfilterv-mob">
                    <div className="txnimgfmv-mob">
                      <div className="txnimgfv-mob">
                        <div className="mr-[-7px]">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title="Filters"
                          >
                            <div
                              className={`filter-main-mob`}
                              style={{
                                backgroundColor:
                                  currentCategory !== "" ? "#102C5D" : "",
                                height: currentCategory !== "" ? "32px" : "",
                                width: currentCategory !== "" ? "33px" : "",
                                borderRadius:
                                  currentCategory !== "" ? "5px" : "",
                                marginLeft: currentCategory !== "" ? "8px" : "",
                                opacity:
                                  AllBeneTxnAccounts.length === 0 ? "0.5" : "1",
                              }}
                              onClick={() => {
                                if (
                                  ShowFilterErrorPage &&
                                  AllBeneTxnAccounts.length === 0
                                ) {
                                  setFilterOpen(!filterOpen);
                                  setInitCategory(false);
                                } else if (AllBeneTxnAccounts.length === 0) {
                                  setFilterOpen(filterOpen);
                                  setInitCategory(false);
                                } else if (AllBeneTxnAccounts.length > 0) {
                                  setFilterOpen(!filterOpen);
                                  setInitCategory(false);
                                }
                              }}
                            >
                              <img
                                src={Filter}
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  marginTop:
                                    currentCategory !== "" ? "7px" : "",
                                }}
                                alt=""
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="txnimgmv-mob mr-[-7px]">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                zIndex: "2147483647 !important",
                              },
                            },
                          }}
                          title="List"
                        >
                          <div
                            className={`filter-main-mob`}
                            onClick={() => {
                              handleMultiViewClick();
                              setToggle(false);
                            }}
                          >
                            <img
                              src={WhiteGrid}
                              alt="List"
                              style={{ height: "16px", width: "16px" }}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {AllBeneTxnAccounts.length > 0 ||
                (AllBeneTxnAccounts.length === 0 && currentCategory !== "") ? (
                  <div className="txnBeneapprovaltextv-mob bg-[#3A5686] h-[50px] mt-[55px]">
                    <div className="flex justify-between">
                      <div className="ml-[15px] my-[10px]">
                        <p className="text-[14px] text-[#F9F9F9] text-semibold">
                          Post Approval Process
                        </p>
                        <div className="mt-[8px] leading-4">
                          <span className="text-[12px] text-[#DADADA]">
                            Once approved, transaction distribution will get
                            created and auto settled using selected source
                            account. If there are insufficient funds, the
                            transaction will settle once the source account is
                            refilled, and distribution will be initiated
                            thereafter.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {currentCategory !== "" && (
                  <div className="txnBeneapprovalfiltertextv-mob bg-[#314D7E] mt-[-2px]">
                    <div
                      className="w-[110px] h-[30px] rounded-[30px] ml-[10px] py-[9px] px-[9px] bg-[#3A5686]"
                      style={{ border: "2px solid #667CA3" }}
                    >
                      <div className="flex justify-between bene_txn_MultiApp_filter_body px-1 mt-[-6px] ">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={currentCategory}
                        >
                          <p className="text-[12px] text-[#FFFFFF] truncate w-[110px]">
                            {currentCategory}
                          </p>
                        </Tooltip>
                        <div
                          className="mt-[-2px]"
                          onClick={handleClearCategory}
                        >
                          {" "}
                          <img
                            src={CancelCircle}
                            alt="close-btn"
                            style={{
                              cursor: "pointer",
                              width: "25px",
                              height: "25px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="ml-[20px] mr-[20px]"
                  style={{
                    paddingTop: "8px",
                  }}
                >
                  <InfiniteScroll
                    style={{
                      width: "inherit",
                      scrollbarWidth: "none",

                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                    dataLength={AllBeneTxnAccounts.length || []}
                    next={fetchMoreData}
                    hasMore={
                      page <
                      Math.ceil(
                        BeneTxnRequireApprovalData?.total_count / pageSize
                      )
                    }
                    loader={
                      <div className="mx-auto mt-[75%] justify-center items-center flex">
                        <Loader />
                      </div>
                    }
                    scrollableTarget="txnViewallScrollContainerBar"
                    scrollThreshold={0.3}
                  >
                    {AllBeneTxnAccounts?.length > 0 &&
                    !allBeneTxnAccountsLoading ? (
                      AllBeneTxnAccounts?.map((account) => (
                        <div
                          className={`BeneTxn_ApprovedAcoutDetailsPage bg-[#314D7E] rounded-[18px] relative ${
                            filterOpen && "bg-[#000] opacity-10 z-[9998]"
                          }`}
                          key={account.txn_id}
                        >
                          <div
                            style={{
                              zIndex: "1",
                              position: "absolute",
                              height: "460px",
                              width: "100%",
                              display:
                                (account.lifecycle_status === "pending" ||
                                  loading[account.txn_id]) &&
                                account.beneficiary_account_approval_status ===
                                  "pending"
                                  ? "block"
                                  : "none",
                              backgroundColor:
                                (account.lifecycle_status === "pending" ||
                                  loading[account.txn_id]) &&
                                account.beneficiary_account_approval_status ===
                                  "pending"
                                  ? "#000"
                                  : "",
                              opacity:
                                (account.lifecycle_status === "pending" ||
                                  loading[account.txn_id]) &&
                                account.beneficiary_account_approval_status ===
                                  "pending"
                                  ? "0.5"
                                  : "1",
                              borderRadius: "16px 16px 0 0 ",
                            }}
                          ></div>
                          <div className="pl-[30px] pt-[30px] pr-[30px] bg-[#314D7E] rounded-[20px] mb-[30px]">
                            <div className="flex justify-between">
                              <div className="-m-2">
                                <p className="font-semibold text-[19px] leading-22 text-[#4DDD37] opacity-100">
                                  <span
                                    style={{
                                      display: "inline-block",
                                      fontSize: "17px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    ₹
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "19px",
                                    }}
                                  >
                                    {<FormatAmount price={account.amount} />}
                                  </span>
                                </p>

                                <span className="text-left font-normal text-[14px] leading-17 tracking-normal text-[#DADADA] opacity-100 -mt-2">
                                  Transaction amount
                                </span>
                              </div>
                              <div className="-m-1">
                                {account?.category &&
                                account?.category.length >= 10 ? (
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                        },
                                      },
                                    }}
                                    title={account?.category}
                                  >
                                    <p className="w-[120px]   overflow-hidden whitespace-nowrap overflow-ellipsis font-normal text-[16px] leading-19 text-[#F9F9F9] opacity-100 text-right">
                                      {account?.category}
                                    </p>
                                  </Tooltip>
                                ) : (
                                  <p className="w-[114px]   overflow-hidden whitespace-nowrap overflow-ellipsis font-normal text-[16px] leading-19 text-[#F9F9F9] opacity-100 text-right">
                                    {account?.category}
                                  </p>
                                )}
                                <p className="w-[120px] font-normal text-[14px] leading-17  text-[#DADADA] opacity-100 text-right mt-[2px]">
                                  Category selected
                                </p>
                              </div>
                            </div>
                            <div className="flex justify-between">
                              <div className="-ml-2 -mr-2 mt-5">
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  title={account?.beneficiary_account_name
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(" ")}
                                >
                                  <p className="w-[300px] lowercase  first-line:capitalize text-left  font-normal text-[16px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                    {account?.beneficiary_account_name
                                      .toLowerCase()
                                      .split(" ")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(" ")}
                                  </p>
                                </Tooltip>

                                <span className="w-[113px] h-[17px] text-left font-normal text-[14px] leading-17 tracking-normal text-[#DADADA] opacity-100 -mt-1">
                                  Beneficiary name
                                </span>
                              </div>
                            </div>
                            <div className="bg-[#506994] h-full ml-[-30px] mr-[-30px] mt-5 pb-4">
                              <div className="flex justify-between">
                                <div className="flex flex-col gap-[1px] h-[40px] ml-[20px] mt-[18px]">
                                  <p className="h-[19px] text-left font-normal text-[16px] leading-19 text-[#F9F9F9] opacity-100">
                                    {account?.owner_account_bank_acc_no &&
                                      formatAccountNumber(
                                        account?.owner_account_bank_acc_no
                                      )}
                                  </p>
                                  <span className="h-[17px] text-left font-normal text-[14px] leading-17 text-[#DADADA] opacity-100 mt-1">
                                    Source account
                                  </span>
                                </div>

                                <div className="w-[120px] h-[33.5px] bg-[#fafafa] rounded-tl-[20px] rounded-bl-[20px] opacity-100  mt-[18px]">
                                  <img
                                    className="w-[79px] h-[20px] relative top-[8px] left-[20px]"
                                    src={require("../../../assets/IfscLogo/" +
                                      IfscCodeMapper(
                                        account?.owner_account_ifsc_code
                                      ))}
                                    alt="bank"
                                  />
                                </div>
                              </div>
                              <div className=" ml-[20px] flex justify-between mt-6">
                                <div className="">
                                  <div className="flex items-center ">
                                    <div className="flex gap-1 text-[19px]">
                                      <div>
                                        <h4
                                          style={{
                                            color:
                                              Number(
                                                account?.owner_account_balance
                                              ) >= Number(account.amount)
                                                ? "#4DDD37"
                                                : "#FF5860",
                                          }}
                                        >
                                          {" "}
                                          ₹{" "}
                                          {
                                            <FormatAmount
                                              price={
                                                account?.owner_account_balance
                                              }
                                            />
                                          }
                                        </h4>
                                      </div>
                                      <div className=" relative">
                                        <p className="">
                                          <AnimationUpAndDown
                                            loader={ownerAccBalanceLoading}
                                            currentId={
                                              account?.owner_account_id
                                            }
                                            currentBalance={
                                              account?.owner_account_balance
                                            }
                                            previousId={refreshAnimation}
                                            previousBalance={PrevBalance}
                                            refreshType={"single"}
                                            classType={"RequiredApproval"}
                                          />
                                        </p>
                                      </div>
                                      <div className="ml-2">
                                        <div class="w-[1px] h-[10px] bg-[#DADADA] mt-[10px] mr-[8px]"></div>
                                      </div>
                                    </div>
                                    <div
                                      onClick={(e) => {
                                        SetrefreshId(account.txn_id);
                                        handleRefresh(
                                          e,
                                          account.owner_account_id,
                                          account?.owner_account_balance
                                        );
                                      }}
                                      className={`flex items-center  ml-[4px] gap-[5px] ${
                                        filterOpen
                                          ? "cursor-default"
                                          : " cursor-pointer"
                                      }`}
                                    >
                                      {ownerAccBalanceLoading &&
                                      refreshId === account.txn_id ? (
                                        <BalanceLoader
                                          loader={ownerAccBalanceLoading}
                                          type={"createTxn"}
                                        />
                                      ) : !ownerAccBalanceLoading &&
                                        refreshId === account.txn_id ? (
                                        <BalanceLoader
                                          loader={ownerAccBalanceLoading}
                                          setAccId={SetrefreshId}
                                          type={"createTxn"}
                                        />
                                      ) : (
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={"Refresh Balance"}
                                        >
                                          <div className="flex gap-1">
                                            <img
                                              className="w-[14px] h-[14px] opacity-0.7 mt-[3px]"
                                              src={ReloadWhite}
                                              alt=""
                                            />

                                            <span className="w-[37px] text-left font-semibold text-[14px] leading-26 text-[#DADADA] opacity-100 ml-1">
                                              Refresh
                                            </span>
                                          </div>
                                        </Tooltip>
                                      )}
                                    </div>
                                  </div>

                                  <div className="text-left font-normal text-[14px] text-[#DADADA] opacity-100">
                                    Source account balance -&nbsp;
                                    {refreshId === account.txn_id
                                      ? account?.owner_account_balance_last_fetched_at_in_words
                                      : account?.owner_account_balance_last_fetched_at_in_words}
                                  </div>
                                </div>
                              </div>
                              {account.lifecycle_status === "rejected" &&
                              account.error === "false" &&
                              !loading[account.txn_id] ? (
                                <>
                                  <div className="h-[full] pt-[15px] bg-[#667CA3] mt-[20px] mb-[-17px]">
                                    <div className="flex flex-col gap-[1px] h-[90px] ml-[20px] ">
                                      <p className="w-full h-[16px] font-normal text-[14px] text-[#DADADA]">
                                        Rejected reason
                                      </p>

                                      <div className="w-[280px] h-[90px] overflow-hidden overflow-ellipsis">
                                        {account.rejection_reason &&
                                        account.rejection_reason.length >
                                          300 ? (
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={account.rejection_reason}
                                          >
                                            <span
                                              className="w-[280px] h-[16px] text-left text-[16px] font-normal  font-sans tracking-normal text-[#F9F9F9] opacity-100 overflow-ellipsis"
                                              style={{
                                                letterSpacing: "0.5px",
                                                wordWrap: "break-word",
                                              }}
                                            >
                                              {account.rejection_reason}
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          <span
                                            className="w-[320px] h-[16px]  text-left text-[13px] font-normal  font-sans tracking-normal text-[#F9F9F9] opacity-100 overflow-ellipsis"
                                            style={{
                                              letterSpacing: "0.5px",
                                              wordWrap: "break-word",
                                            }}
                                          >
                                            {account.rejection_reason}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="w-full h-[1px] bg-[#1D3A6D] opacity-[0.6] mt-[20px]"></div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="ml-[20px] flex justify-between item-center mt-2">
                                    <div className="w-[110px] h-[1px] bg-[#1D3A6D] mt-4"></div>
                                    <div className="ml-[10px] mr-[10px] mt-[6px]">
                                      <img
                                        className="w-[20px] h-[20px]"
                                        src={ArrowDownCircle}
                                        alt=""
                                      />
                                    </div>
                                    <div className="w-[110px] h-[1px] bg-[#1D3A6D] mt-4 mr-[18px]"></div>
                                  </div>
                                  <div className="flex  justify-between h-[40px] pt-[15px] ">
                                    <div className="flex flex-col gap-[1px] w-[180px] h-[40px] ml-[20px] ">
                                      {account?.beneficiary_account_bank_acc_no &&
                                      account?.beneficiary_account_bank_acc_no
                                        .length >= 0 ? (
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={
                                            account?.beneficiary_account_bank_acc_no
                                          }
                                        >
                                          <p className="text-left font-normal text-[16px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                            {account?.beneficiary_account_bank_acc_no &&
                                              formatAccountNumber(
                                                account?.beneficiary_account_bank_acc_no
                                              )}
                                          </p>
                                        </Tooltip>
                                      ) : (
                                        <p className="text-left font-normal text-[17px] leading-19 text-[#F9F9F9] opacity-100 overflow-hidden whitespace-nowrap overflow-ellipsis">
                                          {account?.beneficiary_account_bank_acc_no &&
                                            formatAccountNumber(
                                              account?.beneficiary_account_bank_acc_no
                                            )}
                                        </p>
                                      )}
                                      <span className="h-[17px] text-left font-normal text-[14px] leading-17 text-[#DADADA] opacity-100">
                                        Beneficiary account
                                      </span>
                                    </div>

                                    <div className="w-[120px] h-[33.5px] bg-[#fafafa] rounded-tl-[22px] rounded-bl-[22px] opacity-100">
                                      <img
                                        className="w-[79px] h-[20px] relative top-[8px] left-[20px]"
                                        src={require("../../../assets/IfscLogo/" +
                                          IfscCodeMapper(
                                            account?.beneficiary_account_ifsc_code
                                          ))}
                                        alt="bank"
                                      />
                                    </div>
                                  </div>
                                  <div className=" h-[40px] mt-[42px] ml-[20px]">
                                    <p className="h-[19px] text-left font-normal text-[16px] leading-19 text-[#F9F9F9] opacity-100">
                                      {account?.beneficiary_account_ifsc_code}
                                    </p>
                                    <span className="h-[17px] text-left font-normal text-[14px] leading-17 text-[#DADADA] opacity-100">
                                      IFSC code
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                            <div>
                              {loading[account.txn_id] && (
                                <div>
                                  <div className="flex items-center justify-center relative h-[171px] ml-[-31px]  mr-[-31px] opacity-100 bg-[#667CA3] rounded-b-[20px]">
                                    <Loader />
                                  </div>
                                </div>
                              )}
                              {account.lifecycle_status === "pending" &&
                                !loading[account.txn_id] &&
                                account.beneficiary_account_approval_status ===
                                  "approved" && (
                                  <div>
                                    <div className=" flex justify-between text-white h-[80px] mt-3 ">
                                      <div className="pt-[18px] -m-3">
                                        <p className="h-[19px] text-left font-normal text-[16px] text-[#F9F9F9] opacity-100">
                                          {BeneTxnDataFormat(
                                            account?.created_on
                                          )}
                                          <span>,&nbsp;</span>
                                          {formatTime(
                                            account?.last_updated_timestamp
                                          )}
                                        </p>

                                        <span className="h-[17px] text-left font-normal text-[14px] text-[#DADADA] opacity-100">
                                          Initiated on
                                        </span>
                                      </div>

                                      <div
                                        onClick={() => {
                                          navigate(
                                            `/transaction/beneficiary_transaction/details/${account.txn_id}`,
                                            {
                                              state: {
                                                type: "txn_single_approval",
                                              },
                                            }
                                          );
                                        }}
                                        className={`flex items-center rounded-tl-[30px] rounded-bl-[30px] justify-center w-[120px] h-[33.5px] bg-[#F9F9F9] bg-no-repeat bg-cover mr-[-30px] mt-2 opacity-100 ${
                                          filterOpen
                                            ? "cursor-default"
                                            : " cursor-pointer"
                                        }`}
                                      >
                                        <div className="flex items-center gap-[7px] justify-center">
                                          <p className="w-[auto] h-[17px] text-left font-semibold text-[12px] leading-17 text-[#1D3A6D] opacity-100">
                                            More Details
                                          </p>
                                          <img
                                            className="w-[12px] h-[12px] opacity-100 ml-1 mt-1"
                                            src={ArrowRight}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex bg-[#667CA3] items-center relative justify-between rounded-b-[20px] rounded-t-[20px] h-[100px] bg-cover opacity-100 ml-[-31px]  mr-[-31px] mt-[-20px]">
                                      {account?.lifecycle_status ===
                                        "pending" &&
                                        !loading[account.txn_id] && (
                                          <>
                                            <button
                                              className={`flex flex-col justify-center items-center ml-[5px] mb-[1px] gap-[4px] w-[45%] ${
                                                filterOpen
                                                  ? "cursor-default"
                                                  : " cursor-pointer"
                                              } `}
                                              onClick={() => {
                                                if (!filterOpen) {
                                                  handleOpenPopup(
                                                    account?.txn_id
                                                  );
                                                }
                                              }}
                                            >
                                              <img
                                                className="w-[35px] h-[35px] shadow-[5px -5px 11px #71717140]"
                                                src={aprovaldenied}
                                                alt=""
                                              />
                                              <p className="w-[34px] h-[14px] text-center text-[12px] leading-14 text-[#F9F9F9]">
                                                Reject
                                              </p>
                                            </button>

                                            <div className="w-0 h-[80px] border  border-[#1D3A6D] opacity-[0.6]"></div>
                                            <button
                                              ref={filterRef}
                                              className={`flex flex-col justify-center  items-center mr-[5px] w-[45%] gap-[4px] ${
                                                filterOpen
                                                  ? "cursor-default"
                                                  : " cursor-pointer"
                                              }`}
                                              onClick={() => {
                                                if (!filterOpen) {
                                                  handleTxnApproval(
                                                    account?.txn_id
                                                  );
                                                }
                                              }}
                                            >
                                              <img
                                                className="w-[35px] h-[35px] shadow-[5px -5px 11px #71717140"
                                                src={aprovalaccept}
                                                alt=""
                                              />
                                              <p className="w-[40px] h-[14px] text-center text-[12px] leading-14 text-[#F9F9F9]">
                                                Approve
                                              </p>
                                            </button>
                                          </>
                                        )}
                                    </div>
                                  </div>
                                )}

                              {account.lifecycle_status === "pending" &&
                                !loading[account.txn_id] &&
                                account.beneficiary_account_approval_status ===
                                  "pending" && (
                                  <div className="text-white h-[175px] bg-[#667CA3] ml-[-30px] mr-[-30px] rounded-br-[20px] rounded-bl-[20px]">
                                    <div className="pt-[23px] ml-[20px]">
                                      <p className="h-[19px] text-left font-normal text-[18px] text-[#DADADA] opacity-100">
                                        Account Approval Pending
                                      </p>
                                    </div>
                                    <div className="pt-[18px] ml-[20px] mr-[20px]">
                                      <p className="h-[19px] text-left font-normal text-[14px] text-[#31E5E5] opacity-100">
                                        Account is not approved, please approve
                                        account before approving transaction.
                                      </p>
                                    </div>
                                    <div
                                      className=" h-[39px] pt-[18px] ml-[20px] mr-[20px] mt-[35px] bg-[#1D3A6D]  rounded-[8px] item-center"
                                      onClick={() => {
                                        navigate(
                                          `/accounts/beneficiary_account/details/${account.beneficiary_account_id}`,
                                          {
                                            state: {
                                              type: "txn_single_approval",
                                            },
                                          }
                                        );
                                      }}
                                    >
                                      <p className=" flex justify-between text-left font-normal text-[14px] text-[#F9F9F9] opacity-100 ml-[20px] mt-[-10px]">
                                        Approve Account
                                        <img
                                          src={WhiteArrowRightFilled}
                                          style={{
                                            width: "14px",
                                            height: "14px",
                                            marginTop: "5px",
                                            marginRight: "20px",
                                          }}
                                          alt="Right Arrow"
                                        />
                                      </p>
                                    </div>
                                  </div>
                                )}

                              {account.lifecycle_status === "approved" &&
                                account.distribution_lifecycle_status ===
                                  "created" &&
                                account.error === "false" &&
                                !loading[account.txn_id] && (
                                  <div>
                                    <div className="flex h-[157px] bg-[#667CA3] ml-[-30px] mr-[-30px] rounded-br-[20px]  rounded-bl-[20px]">
                                      <div className="BeneTxn_AwatigVefiyImgaes_mob ml-[18px]">
                                        <img
                                          src={BeneTxn_Queued}
                                          className="w-[41.25px] h-[40px]"
                                          alt=""
                                        />
                                      </div>
                                      <div className="BeneTxn_AwaitingVrfSecondDiv ml-[18px]">
                                        <div className="BeneTxn_DistributionTxt_mob">
                                          <p className="text-semibold">
                                            Distribution created
                                          </p>
                                          <span>
                                            This transaction has been approved
                                            and is set for distribution.
                                          </span>
                                        </div>
                                        <div
                                          className="BeneTxn_ViewTransction_mob"
                                          onClick={() =>
                                            viewTxnNavigate(account.txn_id)
                                          }
                                        >
                                          <p>View Transaction</p>
                                          <img src={WhiteArrowRight} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {account.last_updated_user_id_check === "true" &&
                                account.lifecycle_status === "pendingOne" &&
                                account.error === "false" &&
                                !loading[account.txn_id] && (
                                  <div>
                                    <div className="flex h-[175px] bg-[#667CA3] ml-[-30px] mr-[-30px] rounded-br-[20px]  rounded-bl-[20px]">
                                      <div className="BeneTxn_AwatigVefiyImgaes_mob ml-[18px]">
                                        <img
                                          src={pendingImages}
                                          className="w-[41.25px] h-[40px]"
                                          alt=""
                                        />
                                      </div>
                                      <div className="BeneTxn_AwaitingVrfSecondDiv ml-[18px]">
                                        <div className="BeneTxn_DistributionTxt_mob">
                                          <p className="text-semibold">
                                            Pending
                                          </p>
                                          <span className="BeneTxn_AwiatingTxt_mob">
                                            This transaction has been approved
                                            and requires another user's approval
                                            before distribution.
                                          </span>
                                        </div>
                                        <div
                                          className="BeneTxn_ViewTransctionAwaiting_mob"
                                          onClick={() =>
                                            viewTxnNavigate(account.txn_id)
                                          }
                                        >
                                          <p>View Transaction</p>
                                          <img src={WhiteArrowRight} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {account.lifecycle_status === "error" &&
                                account.error === "true" &&
                                !loading[account.txn_id] && (
                                  <div>
                                    <div className="flex h-[175px] bg-[#667CA3] ml-[-30px] mr-[-30px] rounded-br-[20px]  rounded-bl-[20px]">
                                      <div className="BeneTxn_RejectedImages_mob ml-[18px]">
                                        <img
                                          src={SkipVerification}
                                          className="w-[41.25px] h-[40px]"
                                          alt=""
                                        />
                                      </div>
                                      <div className="BeneTxn_AwaitingVrfSecondDiv ml-[18px]">
                                        <div className="BeneTxn_FailedTxt_mob">
                                          <p className="text-semibold">
                                            Failed to take action!
                                          </p>

                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  height: "20px",
                                                  width: "100%",
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={
                                              account.message &&
                                              account?.message
                                            }
                                          >
                                            <div className="BeneTxn_FailedTxt_mob_div">
                                              {account.message &&
                                                account?.message}
                                            </div>
                                          </Tooltip>
                                        </div>
                                        <div
                                          className="BeneTxn_ViewTransctionRejected_mob"
                                          onClick={() =>
                                            viewTxnNavigate(account.txn_id)
                                          }
                                        >
                                          <p>View Transaction</p>
                                          <img src={WhiteArrowRight} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {account.lifecycle_status === "rejected" &&
                                account.error === "false" &&
                                !loading[account.txn_id] && (
                                  <div>
                                    <div className=" flex h-[145px] bg-[#667CA3] ml-[-30px] mr-[-30px] rounded-br-[20px]  rounded-bl-[20px]">
                                      <div className="BeneTxn_AwatigVefiyImgaes_mob ml-[18px]">
                                        <img
                                          src={DeclinedRed}
                                          className="w-[41.25px] h-[40px]"
                                          alt=""
                                        />
                                      </div>
                                      <div className="BeneTxn_AwaitingVrfSecondDiv ml-[18px]">
                                        <div className="BeneTxn_ErrorTxt_mob">
                                          <p className="text-semibold">
                                            {" "}
                                            Rejected!
                                          </p>
                                          <span>
                                            This transaction has been Rejected!
                                          </span>
                                        </div>
                                        <div
                                          className="BeneTxn_ViewTransctionRejected_mob"
                                          onClick={() =>
                                            viewTxnNavigate(account.txn_id)
                                          }
                                        >
                                          <p>View Transaction</p>
                                          <img src={WhiteArrowRight} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <>
                        {!allBeneTxnAccountsLoading && (
                          <div className="m-[10%]">
                            <div
                              className="alignItems-center"
                              style={{
                                marginTop:
                                  AllBeneTxnAccounts.length > 0 ||
                                  (AllBeneTxnAccounts.length === 0 &&
                                    currentCategory !== "")
                                    ? "35%"
                                    : "100%",
                              }}
                            >
                              <img
                                src={
                                  ShowFilterErrorPage &&
                                  AllBeneTxnAccounts.length === 0 &&
                                  currentCategory !== ""
                                    ? FiterNoAccount
                                    : NoAccountFound
                                }
                                alt=""
                              />
                            </div>
                            <div className="text-center text-[16px] text-[#FFFFFF]">
                              {ShowFilterErrorPage &&
                              AllBeneTxnAccounts.length === 0 &&
                              currentCategory !== "" ? (
                                <span className="">
                                  No data found for the selected filter.
                                </span>
                              ) : (
                                <span className="">
                                  There are no more transactions that require
                                  your approval.
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BeneTxnRequireApproval;
