import React, { useRef, useEffect } from "react";
import "./index.css";
import { Button } from "@mui/material";
import ArrowDown from "../../../assets/ArrowsIcons/arrow_down_white_filled.svg";
import CheckIcon from "../../../assets/CheckmarksIcons/check_mark_white.svg";
import clear_filter from "../../../assets/ViewAllTransactions/Clear filter.png";

const ConnectedBankingDropdown = ({
  filterPayload,
  setFilterPayload,
  selectConnectedBanking,
  setSelectConnectedBanking,
  typepop,
  owner,
  flow,
}) => {
  const accountBalanceRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountBalanceRef.current &&
        !accountBalanceRef.current.contains(event.target)
      ) {
        setSelectConnectedBanking(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="owner_connected_bank_div">
      <p style={{ height: "22px" }}>Connected banking</p>
      <Button
        ref={accountBalanceRef}
        className="owner_connected_bank"
        onClick={() => {
          setSelectConnectedBanking(!selectConnectedBanking);
        }}
        sx={{
          opacity: typepop === "popup" && flow !== "internalTxn" ? "0.6" : "1",
          height: selectConnectedBanking ? "140px" : "37px",
          zIndex: "99999 !important",
          outline: selectConnectedBanking ? "1px solid #E25869" : "",
        }}
        disabled={typepop === "popup" && flow !== "internalTxn" ? true : false}
      >
        <div className="owner_connected_bank_select">
          <div
            className="owner_connected_bank_scroll_line"
            style={{ display: selectConnectedBanking ? "block" : "none" }}
          ></div>

          <span
            style={{
              fontSize: selectConnectedBanking ? "12px" : "14px",
              paddingLeft: "14px",
            }}
          >
            {typepop === "popup" && flow !== "internalTxn"
              ? "Active"
              : filterPayload.connectedBanking.length > 0
              ? filterPayload.connectedBanking
              : "Select status"}
          </span>
          <div className="flex flex-row items-center">
            <img
              src={clear_filter}
              alt="clear"
              className="w-[9px] h-[9px]"
              style={{
                visibility:
                  filterPayload.connectedBanking.length > 0
                    ? "visible"
                    : "hidden",
              }}
              onClick={(e) => {
                setFilterPayload((prev) => {
                  return { ...prev, connectedBanking: "" };
                });
                e.stopPropagation();
              }}
            />
            <div
              className="w-[1px] h-[16px] mx-[8px]"
              style={{
                backgroundColor: "var(--Blue_3)",
                visibility:
                  filterPayload.connectedBanking.length > 0
                    ? "visible"
                    : "hidden",
              }}
            ></div>
            <img
              src={ArrowDown}
              alt="down"
              className="w-[11px] h-[11px]"
              style={{
                transform: selectConnectedBanking
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
              }}
            />
          </div>
        </div>
        <div
          style={{ zIndex: "99999 !important" }}
          className="owner_connected_bank_scroll"
        >
          <Button
            sx={{ zIndex: "99999 !important" }}
            className="owner_connected_bank_scroll_com hover:bg-[#506994]"
            onClick={(e) => {
              e.stopPropagation();

              setFilterPayload({
                ...filterPayload,
                connectedBanking: "",
              });
              setSelectConnectedBanking(false);
            }}
          >
            None
          </Button>
          <Button
            className="owner_connected_bank_scroll_com hover:bg-[#506994]"
            sx={{
              zIndex: "99999 !important",
              backgroundColor:
                filterPayload?.connectedBanking === "Active" ? "#3A5686" : "",
            }}
            onClick={(e) => {
              e.stopPropagation();

              setFilterPayload({
                ...filterPayload,
                connectedBanking: "Active",
              });
              setSelectConnectedBanking(false);
            }}
          >
            Active
            <div
              style={{
                display:
                  filterPayload?.connectedBanking == "Active" ? "flex" : "none",
                textAlign: "right",
                justifyContent: "end",
                paddingRight: "1px",
              }}
            >
              <img
                style={{
                  height: "13px",
                  width: "11px",
                }}
                src={CheckIcon}
                alt="check"
              />
            </div>
          </Button>
          <Button
            className="owner_connected_bank_scroll_com hover:bg-[#506994]"
            sx={{
              zIndex: "99999 !important",
              backgroundColor:
                filterPayload?.connectedBanking === "Inactive" ? "#3A5686" : "",
            }}
            onClick={(e) => {
              e.stopPropagation();

              setFilterPayload({
                ...filterPayload,
                connectedBanking: "Inactive",
              });
              setSelectConnectedBanking(false);
            }}
          >
            Inactive
            <div
              style={{
                display:
                  filterPayload?.connectedBanking == "Inactive"
                    ? "flex"
                    : "none",
                textAlign: "right",
                justifyContent: "end",
                paddingRight: "1px",
              }}
            >
              <img
                style={{
                  height: "13px",
                  width: "11px",
                }}
                src={CheckIcon}
                alt="check"
              />
            </div>
          </Button>
        </div>
      </Button>
    </div>
  );
};

export default ConnectedBankingDropdown;
