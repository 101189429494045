const displayPlatform = (platform) => {
  try {
    if (platform === "web" || platform === "Web") return "web_default";
    else return "mobile";
  } catch (error) {
    return "default platform";
  }
};

const displayBrowser = (browser) => {
  try {
    if (browser === "Google Chrome") return "chrome";
    else if (browser === "Apple Safari") return "safari";
    else if (browser === "Mozilla Firefox") return "mozilla_firefox";
    else if (browser === "Microsoft Edge (Chromium)") return "microsoft_edge";
    else if (browser === "Internet Explorer") return "explorer";
    else return "default browser";
  } catch (error) {
    return "default browser";
  }
};

const displayOS = (OS_version) => {
  const os = OS_version.split(" ")?.[0];
  try {
    if (os === "macOS" || os === "Mac") return "mac_os";
    else if (os === "Android") return "android";
    else if (os === "Linux") return "Linux";
    else if (os === "Windows") return "Windows";
    else if (os === "iOS") return "ios";
    else return "unknown_os";
  } catch (error) {
    return "unknown_os";
  }
};

export { displayPlatform, displayBrowser, displayOS };
