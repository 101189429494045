import React from "react";
import BeneAccDetails from "../../../containers/Accounts/BeneficiaryAccount/BeneAccDetails";
import "./index.css";

function BeneAccDetailPagePopup({
  closeBeneAccountDetailModal,
  bene_account_id,
  type,
}) {
  return (
    <>
      <div
        className="beneAccountDetailPop_div fixed overflow-hidden rounded-[20px] z-[999] bg-[#1D3A6D]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="h-full w-full">
          <BeneAccDetails
            bene_account_id={bene_account_id}
            closeBeneAccountDetailModal={closeBeneAccountDetailModal}
            type={type}
            servicesType="BenAdmin"
          />
        </div>
      </div>
    </>
  );
}

export default BeneAccDetailPagePopup;
