import React from "react";

export const ConvertBefiDate = (timestampInSeconds) => {
  if (!timestampInSeconds || typeof timestampInSeconds !== "number") return "";

  const milliseconds = timestampInSeconds * 1000;

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateObj = new Date(milliseconds);

  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const year = dateObj.getFullYear();

  let daySuffix = "th";
  if (day === 1 || day === 21 || day === 31) {
    daySuffix = "st";
  } else if (day === 2 || day === 22) {
    daySuffix = "nd";
  } else if (day === 3 || day === 23) {
    daySuffix = "rd";
  } else {
    daySuffix = (
      <sup
        style={{ fontSize: "12px", top: "0px", bottom: "12px" }}
        className="suptext"
      >
        th
      </sup>
    );
  }

  let hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const seconds = dateObj.getSeconds();

  const period = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  if (hours === 0) hours = 12;

  const formattedTime = `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }:${seconds < 10 ? "0" + seconds : seconds} ${period}`;

  const formattedDate = (
    <div>
      {day} {months[monthIndex]} {year}, {formattedTime}
    </div>
  );

  return formattedDate;
};
