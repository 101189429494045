import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { env } from "../../../env";
import { toast } from "react-toastify";
import { toast_position } from "../Settings/Authorization";

const baseUrl = env.REACT_APP_BASE_URL;

export const internalTxnAccountDetail = createAsyncThunk(
  "internalTxnAccountDetail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseUrl}/transactions/internal/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  }
);

export const internalTxnRequireApprovalPage = createAsyncThunk(
  "internalTxnRequireApprovalPage",
  async (values) => {
    const { page_no, page_size, category, entityId } = values;
    try {
      const response = await axios.get(
        `${baseUrl}/transactions/internal/actionable`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
          params: {
            page_no: page_no ? page_no : undefined,
            page_size: page_size ? page_size : undefined,
            category: category !== undefined ? category : undefined,
            entity_id: entityId ? entityId : undefined,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const internalTxnApproval = createAsyncThunk(
  "internalTxnApproval",
  async (id) => {
    const data = {
      txn_id: [id],
    };
    const requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify(data),
    };

    return await fetch(
      `${baseUrl}/transactions/internal/approve?`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.log("error", error);
      });
  }
);

export const internalTxnDenied = createAsyncThunk(
  "internalTxnDenied",
  async ({ id, AccountIdData }) => {
    const data = {
      txn_id: [id],
      reason: AccountIdData,
    };
    const requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },

      body: JSON.stringify(data),
    };

    return await fetch(
      `${baseUrl}/transactions/internal/reject`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.log("error", error);
      });
  }
);

const internalTxnDataSlice = createSlice({
  name: "internalTxnViewData",
  initialState: {
    internalTxnDataLoading: null,
    internalTxnData: null,
    internalTxnDataError: null,

    internalTxnRequireApprovalData: null,
    internalTxnRequireApprovalLoading: null,
    internalTxnRequireApprovalError: null,

    internalTxnApprovalData: null,
    internalTxnApprovalLoading: null,
    internalTxnApprovalError: null,

    internalTxnDeniedData: null,
    internalTxnDeniedLoading: null,
    internalTxnDeniedError: null,
  },
  extraReducers: (builder) => {
    builder.addCase(internalTxnAccountDetail.pending, (state, action) => {
      state.internalTxnDataLoading = true;
    });
    builder.addCase(internalTxnAccountDetail.fulfilled, (state, action) => {
      state.internalTxnDataLoading = false;
      state.internalTxnData = action.payload;
    });
    builder.addCase(internalTxnAccountDetail.rejected, (state, action) => {
      state.internalTxnDataError = true;
    });
    builder.addCase(internalTxnRequireApprovalPage.pending, (state, action) => {
      state.internalTxnRequireApprovalLoading = true;
    });
    builder.addCase(
      internalTxnRequireApprovalPage.fulfilled,
      (state, action) => {
        state.internalTxnRequireApprovalLoading = false;
        state.internalTxnRequireApprovalData = action.payload;
      }
    );
    builder.addCase(
      internalTxnRequireApprovalPage.rejected,
      (state, action) => {
        state.internalTxnRequireApprovalError = true;
        state.internalTxnRequireApprovalData = null;
      }
    );
    builder.addCase(internalTxnApproval.pending, (state, action) => {
      state.internalTxnApprovalLoading = true;
    });
    builder.addCase(internalTxnApproval.fulfilled, (state, action) => {
      state.internalTxnApprovalLoading = false;
      state.internalTxnApprovalData = action.payload;
    });
    builder.addCase(internalTxnApproval.rejected, (state, action) => {
      state.internalTxnApprovalError = true;
      state.internalTxnApprovalData = null;
    });
    builder.addCase(internalTxnDenied.pending, (state, action) => {
      state.internalTxnDeniedLoading = true;
    });
    builder.addCase(internalTxnDenied.fulfilled, (state, action) => {
      state.internalTxnDeniedLoading = false;
      state.internalTxnDeniedData = action.payload;
    });
    builder.addCase(internalTxnDenied.rejected, (state, action) => {
      state.internalTxnDeniedError = true;
      state.internalTxnDeniedData = null;
      state.internalTxnDeniedLoading = false;
    });
  },
});

export default internalTxnDataSlice.reducer;
