const FormatAmount = ({ price }) => {
  if (
    price == "xxxx.xx" ||
    isNaN(price) ||
    price === null ||
    price === undefined
  ) {
    return "Redacted";
  }
  const numberPrice = Number(price);

  const formattedPrice = Intl.NumberFormat("en-IN", {
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numberPrice);

  const [integerPart, decimalPart] = formattedPrice.split(".");
  const paddedIntegerPart = integerPart.padStart(2, "");

  return `${paddedIntegerPart}.${decimalPart}`;
};

export default FormatAmount;
