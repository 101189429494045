import React, { useEffect, useRef, useState } from "react";
import "./RejectingTransactionPopup.css";
import xpentraloader from "../../../../../assets/Beneficiary Icons/lodemore.gif";
import dataLoading from "../../../../../assets/Beneficiary Icons/inputloder.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import OwnerTransactionViewAllCard from "../../../../../components/Accounts/OwnerAccounts/OwnerTransactionViewAllCard";
import {
  beneTxnMultiDeniedApproval,
  beneTxnViewAllAcc,
} from "../../../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";

import { checkUsertoken } from "../../../../../redux/features/login/login";
import BeneViewAllCard from "../../BeneViewAllCard";
import PendingTransactionAllCards from "../PendingTransactionAllCards/PendingTransactionAllCards.jsx";
import { toast } from "react-toastify";
import { IconButton, Tooltip, tooltipClasses } from "@mui/material";
import { Close } from "@mui/icons-material";
import RefreshToast from "../../../../../components/_utils/RefreshToast/index.jsx";
import { useNavigate } from "react-router-dom";
import { internalTxnViewAll } from "../../../../../redux/features/internalTransaction/internalTransactionViewAll.js";
import { internalTxnMultiRejectApproval } from "../../../../../redux/features/internalTransaction/internalTransactionSingleApproval.js";

const RejectingTransactionPopup = (props) => {
  const [loading, setLoading] = useState(true);
  const [pendingTxn, setPendingTxn] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [showCancleBtn, setShowCancleBtn] = useState(true);
  const services = useSelector((state) => state.refreshToken.services);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(
    window.innerWidth >= 3840 ? 192 : window.innerWidth >= 2560 ? 102 : 50
  );

  const {
    beneTxnViewAllAccLoading,
    beneTxnViewAllAccData,
    beneTxnViewAllAccError,
  } = useSelector((state) => state?.beneTxnViewAccData);

  const { internalTxnViewAllAccData, internalTxnViewAllAccLoading } =
    useSelector((state) => state?.internalTxnViewall);

  const fetchMoreData = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleCloseRejectingPopup = () => {
    props.setShowRejectAllTxnReson(true);
    props.setRejectTxnPopup(false);
    if (props?.type != "ArchiveAccount") {
      props.setRejectTxnPopup(false);
    }
    setShowLoader(true);

    if (props.flow === "edit") {
      props.setIsDennied(false);
      props.setIsPending(false);
      props.setIsApproved(false);
      props.setIsArchived(false);
      props.setShutViewAll(false);
    }
  };

  const handleRejectAllTransaction = async () => {
    setShowCancleBtn(false);
    setShowLoader(true);
    let AccountIdData = props?.declineReason;
    let id = [];
    if (
      props?.type === "internalAccount" ||
      props?.type === "Internal_AccountApproval" ||
      props?.type === "Internal_Account_Detail"
    ) {
      id =
        !internalTxnViewAllAccLoading &&
        internalTxnViewAllAccData?.transactions?.length > 0
          ? internalTxnViewAllAccData?.transactions.map((data) => {
              return data?.txn_id;
            })
          : [];
    } else {
      id =
        !beneTxnViewAllAccLoading &&
        beneTxnViewAllAccData?.transactions?.length > 0
          ? beneTxnViewAllAccData?.transactions.map((data) => {
              return data?.txn_id;
            })
          : [];
    }

    dispatch(checkUsertoken());
    RefreshToast();

    let data = [];
    if (
      props?.type === "internalAccount" ||
      props?.type === "Internal_AccountApproval" ||
      props?.type === "Internal_Account_Detail"
    ) {
      data = await dispatch(
        internalTxnMultiRejectApproval({ id, AccountIdData })
      );
    } else {
      data = await dispatch(beneTxnMultiDeniedApproval({ id, AccountIdData }));
    }

    if (data?.payload?.errors?.user_unauthorized?.length > 0) {
      if (
        props?.type === "internalAccount" ||
        props?.type === "Internal_AccountApproval" ||
        props?.type === "Internal_Account_Detail"
      ) {
        navigate("/feature_not_assigned", {
          state: {
            unavailableService: "AInternalTxn",
          },
        });
        return;
      } else {
        navigate("/feature_not_assigned", {
          state: {
            unavailableService: "ABeneficiaryTxn",
          },
        });
      }
    }

    await new Promise((resolve) => setTimeout(resolve, 2000));

    if (data?.payload?.status === false) {
      const error_msg = data?.payload?.errors?.user_unauthorized
        ? data?.payload?.errors?.user_unauthorized[0]
        : Array.isArray(data?.payload?.errors?.base) &&
          Array.isArray(data?.payload?.errors?.base[0]?.errors)
        ? data.payload.errors.base[0].errors[0]
        : data?.payload?.errors?.base[0] || "Unknown error occurred";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      setShowCancleBtn(true);
      setShowLoader(false);
    } else if (data?.payload?.status) {
      if (
        props?.type === "internalAccount" ||
        props?.type === "Internal_AccountApproval" ||
        props?.type === "Internal_Account_Detail"
      ) {
        toast.success("Transaction Rejected successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          props.handleInternalAccountreject(
            props.accountId,
            props.declineReason
          );
        }, 500);
      } else {
        toast.success("Transaction Rejected successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          props.handleConfirm(props.accountId, props.declineReason);
        }, 500);
      }
    } else if (data?.payload == undefined) {
      toast.error("An error occured while taking action on this account", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => {
        props.setRejectTxnPopup(false);
      }, 1000);
    }
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        if (!showLoader) {
          props?.type == "ArchiveAccount"
            ? handleCloseRejectingPopup()
            : handleRejectAllTransaction();
        }
      }

      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [showLoader]);

  useEffect(() => {
    async function fetchData() {
      try {
        if (currentPage === 1) {
          setLoading(true);
        }

        dispatch(checkUsertoken());
        let response = [];
        if (
          props?.type === "internalAccount" ||
          props?.type === "Internal_AccountApproval" ||
          props?.type === "Internal_Account_Detail"
        ) {
          let queryParams = {
            page_no: 1,
            page_item_count: 50,
            lifecycle_status: "pending",
            internal_account_id: props?.accountId,
          };
          response = await dispatch(internalTxnViewAll(queryParams));
        } else {
          let queryParams = {
            page_no: 1,
            page_item_count: 50,
            lifecycle_status: "pending",
            beneficiary_account_id: props?.accountId,
          };
          response = await dispatch(beneTxnViewAllAcc(queryParams));
        }

        const newData =
          props?.type === "internalAccount" ||
          props?.type === "Internal_AccountApproval" ||
          props?.type === "Internal_Account_Detail"
            ? internalTxnViewAllAccData?.transactions
            : response?.payload?.transactions || [];
        setPendingTxn((prevData) =>
          currentPage === 1 ? newData : [...prevData, ...newData]
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
    fetchData();
  }, [dispatch, currentPage]);

  return (
    <div className="reject-txn-popup">
      <div
        className={
          services?.find(
            (service) =>
              service === "ABeneficiaryTxn" ||
              service === "Admin" ||
              service === "AInternalTxn"
          )
            ? "reject-txn-popup-title"
            : "reject-txn-popup-title mb-[-12px]"
        }
      >
        <div className="reject-txn-popup-title-main-div">
          <h2
            className={
              services?.find(
                (service) =>
                  service === "ABeneficiaryTxn" ||
                  service === "Admin" ||
                  service === "AInternalTxn"
              )
                ? "reject-txn-popup-title-head mt-[30px]"
                : "reject-txn-popup-title-head"
            }
          >
            Associated transactions found
          </h2>
          {services?.find(
            (service) =>
              service === "ABeneficiaryTxn" ||
              service === "Admin" ||
              service === "AInternalTxn"
          ) ? null : (
            <div className="ml-[390px] bene_reject_close_div">
              <div className="w-9 h-9 hover:bg-[#667CA3] bg-[#031F4F] rounded-[5px]">
                <IconButton
                  onClick={() => props?.handleClosePopup()}
                  style={{
                    borderRadius: "5px",
                    height: "36px",
                    width: "36px",
                  }}
                >
                  <Close className="text-white hover:rounded-lg " />
                </IconButton>
              </div>
            </div>
          )}
        </div>
        <div className="reject-txn-popup-title-subhead">
          {services?.find(
            (service) =>
              service === "ABeneficiaryTxn" ||
              service === "Admin" ||
              service === "AInternalTxn"
          ) === undefined ? (
            "The following transactions are linked and need to be rejected before archiving this account. To reject the transactions, please contact the authorised person who has the feature to reject the transactions."
          ) : props?.type == "ArchiveAccount" ? (
            `The following transactions are linked to this account. To archive the account, please reject the transactions listed below by clicking the “Reject all” button.`
          ) : (
            <>
              The following transactions are linked to this account. To reject
              the account, please reject the transactions listed below by
              clicking the “Reject all” button. The transactions will get
              rejected with the reason{" "}
              <span className="font-semibold">
                '
                <Tooltip
                  title={props.declineReason}
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                        [`& .${tooltipClasses.arrow}`]: {
                          color: (theme) => theme.palette.common.black,
                        },
                        zIndex: 2147483648,
                      },
                    },
                  }}
                  arrow
                  PopperProps={{
                    style: {
                      zIndex: 2147483648,
                    },
                  }}
                >
                  {props?.declineReason?.length > 270 && window.innerWidth > 397
                    ? props?.declineReason?.substr(0, 279) + "..."
                    : window.innerWidth < 397 &&
                      props?.declineReason?.length > 65
                    ? props?.declineReason?.substr(0, 65) + "..."
                    : props?.declineReason}
                </Tooltip>
                '
              </span>
              .
            </>
          )}
        </div>
      </div>
      <div id="infinityScrollContainerAccountTransaction">
        {loading ? (
          <div className="loader-container m-auto justify-center items-center flex mt-[165px]">
            <img src={dataLoading} alt="loading..." />
          </div>
        ) : pendingTxn?.length > 0 ? (
          <div className="scroll w-full flex flex-col items-center justify-center m-auto">
            <InfiniteScroll
              style={{ height: "inherit" }}
              dataLength={pendingTxn.length || []}
              next={fetchMoreData}
              hasMore={
                currentPage <
                Math.ceil(
                  props?.type === "internalAccount" ||
                    props?.type === "Internal_AccountApproval" ||
                    props?.type === "Internal_Account_Detail"
                    ? internalTxnViewAllAccData?.total_count / pageSize
                    : beneTxnViewAllAccData?.total_count / pageSize
                )
              }
              loader={
                <div className="loader-container m-auto justify-center items-center flex bg-black">
                  <img src={dataLoading} alt="loading..." />
                </div>
              }
              scrollableTarget="infinityScrollContainerAccountTransaction"
              scrollThreshold={0.3}
            >
              <PendingTransactionAllCards pendingTxn={pendingTxn} />
            </InfiniteScroll>
          </div>
        ) : null}
      </div>
      {services?.find(
        (service) =>
          service === "ABeneficiaryTxn" ||
          service === "Admin" ||
          service === "AInternalTxn"
      ) ? (
        <div className="reject-txn-popup-btn-container">
          {showCancleBtn && (
            <button
              id="cancelBtn"
              className={`reject-txn-popup-cancleBtn`}
              onClick={(event) => {
                event.stopPropagation();
                props?.handleClosePopup();
              }}
            >
              Cancel
            </button>
          )}
          {showLoader ? (
            <button className="reject-txn-expentraLoaderclass">
              <img src={xpentraloader} alt="Loading..." />
            </button>
          ) : (
            <button
              className="reject-txn-popup-confirmBtn"
              // onClick={(e) => {
              //   e.stopPropagation();
              //   props?.type == "ArchiveAccount"
              //     ? handleCloseRejectingPopup()
              //     : handleRejectAllTransaction();
              // }}
              onClick={(e) => {
                if (
                  services?.find(
                    (service) =>
                      service === "ABeneficiaryTxn" ||
                      service === "Admin" ||
                      service === "AInternalTxn"
                  ) !== undefined
                ) {
                  e.stopPropagation();
                  props?.method == "ArchiveAccount"
                    ? handleCloseRejectingPopup()
                    : handleRejectAllTransaction();
                } else {
                  navigate("/feature_not_assigned", {
                    state: {
                      unavailableService: "ABeneficiaryTxn" || "AInternalTxn",
                    },
                  });
                }
              }}
            >
              Reject all
            </button>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default RejectingTransactionPopup;
